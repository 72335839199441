// styles
export const settingsStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

export const roiStatusMenu = [
  {
    fieldName: 'names',
    name: 'Campaign',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'roi_is_active',
    name: 'ROI',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'lead_types.name',
    name: 'Lead Types',
    mobile: true,
    sort: true,
  },
  // {
  //   fieldName: 'type.name',
  //   name: 'Type',
  //   mobile: false,
  //   sort: true,
  // },
]

export const roiCyclesMenu = [
  {
    fieldName: 'start_date',
    name: 'Start Date',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'roi_is_active',
    name: 'ROI',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'total_return',
    name: 'Value',
    mobile: true,
    sort: true,
  },
]
