import { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Radio from '../Radio'
import Input from '../Input'
import Dropdown from '../Dropdown'
import {
  IAdvertiserInDb,
  ICampaignUpliftInDb,
  ICampaignInDb,
} from '../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  advertisers?: IAdvertiserInDb[]
  upliftDetails?: ICampaignUpliftInDb
  setAdvertiserId?: Dispatch<SetStateAction<number>>
  setCampaignId?: Dispatch<SetStateAction<number>>
  campaigns?: ICampaignInDb[]
}

const UpliftDetailsForm:React.FC<Props> = ({
  showButton,
  errors,
  advertisers,
  upliftDetails,
  setAdvertiserId,
  setCampaignId,
  campaigns,
}) => {
  const methods = useFormContext()
  const name = methods.getValues('advertiser.name')
  const watchAdvertiser = methods.watch('advertiser.id')
  const watchCampaign = methods.watch('campaign.id')

  useEffect(() => {
    if (setAdvertiserId) {
      setAdvertiserId(watchAdvertiser)
    }
  }, [watchAdvertiser, setAdvertiserId])

  useEffect(() => {
    if (setCampaignId) {
      setCampaignId(watchCampaign)
    }
  }, [watchCampaign, setCampaignId])

  const campaignName = () => {
    if (upliftDetails?.secondary_name && !upliftDetails.specific?.name) {
      return `${upliftDetails?.secondary_name} | ${upliftDetails?.type.short_name}`
    }
    if (!upliftDetails?.secondary_name && upliftDetails?.specific?.name) {
      return `${upliftDetails?.specific?.name} | ${upliftDetails?.type?.short_name}`
    }
    if (upliftDetails?.secondary_name && upliftDetails?.specific?.name) {
      return `${upliftDetails?.secondary_name} - ${upliftDetails?.specific?.name} | ${upliftDetails?.type?.short_name}`
    }
    return upliftDetails?.type?.short_name
  }
  // TODO: fix so id is related to advertiser id, not id of uplift

  return (
    <>
      {(advertisers?.length) ? (
        <Controller
          name="advertiser.id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              // placeholder="Select..."
              placeholder={watchAdvertiser > 0 ? undefined : 'Select...'}
              label="Advertiser"
              variant="admin"
              value={methods.getValues('advertiser.id')}
              list={advertisers}
              error={errors.advertiser?.id && <span className="form-error">This field is required</span>}
              toolTipInfo="If you can’t find what you’re looking for, you may need to create the advertiser first, then refresh the page."
              required
            />
          )}
        />
      ) : (
        <Input
          type="text"
          label="Advertiser"
          disabled
          {...methods.register('advertiser.name')}
        />
      )}
      {campaigns?.length ? (
        <Controller
          name="campaign.id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={watchAdvertiser === undefined || campaigns?.length === 0}
              hookFormField={field}
              placeholder="Select..."
              label="Campaign"
              variant="admin"
              campaign
              value={methods.getValues('campaign.id')}
              list={campaigns?.length ? campaigns : [{ name: '', id: '' }]}
              error={errors.campaign_id && <span className="form-error">This field is required</span>}
              toolTipInfo={(
                <span>
                  Select the campaign you want to apply uplift to.
                  <br />
                  <br />
                  If you are creating an uplift and do not see your campaign, an uplift may have already been created. Check the “uplift” page and search for the campaign.
                  <br />
                  <br />
                  If you are editing an uplift and don’t see your campaign, you may need to create an uplift first.
                </span>
              )}
              required
            />
          )}
        />
      ) : (
        <Input
          type="text"
          label="Campaign"
          disabled
          value={campaignName()}
          name="campaign name"
          toolTipInfo="This field will remain disabled until an advertiser is selected that has a campaign with no uplift, or uplift set to zero."
        />
      )}
      <Input
        disabled={!showButton}
        type="text"
        label="Uplift"
        {...methods.register('uplift_base', {
          required: true,
        })}
        className="percent-split"
        toolTipInfo={(
          !upliftDetails?.id ? (
            <span>
              This uplift amount will auto-renew and apply to upcoming cycles. If you need to change a specific cycle&apos;s uplift, please select &quot;Edit Uplift&quot; and change the specific cycle there.
              <br />
              <br />
              Enter the percentage as a whole number or, if needed, a decimal, ex. enter 25 for 25% or enter 25.5 for 25.5%.
              <br />
              <br />
              The uplift is not related to the commission.
            </span>
          ) : (
            <span>
              This uplift amount will auto-renew and apply to upcoming cycles. If you need to change a specific cycle&apos;s uplift, please edit it in the &quot;cycle uplift details&quot; table below.
              <br />
              <br />
              Enter the percentage as a whole number or, if needed, a decimal, ex. enter 25 for 25% or enter 25.5 for 25.5%.
              <br />
              <br />
              The uplift is not related to the commission.
            </span>
          )
        )}
      />
      {upliftDetails?.type?.id !== 2 && (
        <Input
          disabled={!showButton}
          type="text"
          label="Budget"
          {...methods.register('budget_base', {
            required: true,
          })}
          className="dollar-split"
          toolTipInfo={(
            !upliftDetails?.id ? (
              <span>
                This budget amount will auto-renew and apply to upcoming cycles. If you need to change a specific cycle&apos;s budget, please select &quot;Edit Uplift&quot; and change the specific cycle there.
                <br />
                <br />
                This is the client facing budget. Please put in the budget you&apos;sd want the client to see when visiting their report page. This will only impact the budget at the top of the report.
              </span>
            ) : (
              <span>
                This budget amount will auto-renew and apply to upcoming cycles. If you need to change a specific cycle&apos;s budget, please select &quot;Edit Uplift&quot; and change the specific cycle there.
                <br />
                <br />
                This is the client facing budget. Please put in the budget you&apos;sd want the client to see when visiting their report page. This will only impact the budget at the top of the report.
              </span>
            )
          )}
        />
      )}
    </>
  )
}

export default UpliftDetailsForm
