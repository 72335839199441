const buttonStyles = {
  base: 'font-normal font-display uppercase tracking-wide leading-3 inline-flex align-top rounded justify-center px-3 items-center py-1.5 border focus:outline-none global-animation disabled:hover:bg-primary disabled:cursor-not-allowed disabled:border-grey-light disabled:text-disabled',
  block: 'flex justify-center w-full',
  disabled: 'bg-transparent border border-grey-light',
  pill: 'rounded',
  size: {
    admin: 'px-4 py-2 w-full max-w-btn-lg h-full min-h-btn text-xs mb-6',
    xs: 'px-2 py-0 text-xs min-h-0 max-w-0',
    sm: 'px-2 py-0 text-xs min-h-0 max-w-0 w-full max-w-btn-sm min-w-btn mb-6',
    md: 'px-4 py-2 w-full max-w-btn-md h-full min-h-btn text-xs mb-6',
    lg: 'px-2 py-0 text-xs min-h-btn w-full min-w-btn max-w-btn full mb-6',
  },
  variant: {
    primary: 'text-light bg-primary border-primary hover:bg-secondary hover:border-secondary hover:text-light focus:bg-primary focus:border-primary active:text-light active:bg-tertiary active:border-tertiary focus:outline-none disabled:text-default disabled:text-opacity-60 disabled:bg-opacity-0 disabled:border-grey-light disabled:cursor-not-allowed',
    secondary: 'text-default bg-opacity-0 inline-flex border-primary rounded hover:bg-secondary hover:border-secondary hover:text-light active:bg-teritary active:border-tertiary focus:bg-tertiary focus:border-tertiary focus:text-light disabled:text-default disabled:text-opacity-60 disabled:border-grey-light disabled:bg-opacity-0 disabled:cursor-not-allowed',
    tertiary: 'text-default bg-grey-lightest inline-flex border-grey-light rounded hover:bg-secondary hover:border-secondary hover:text-light active:bg-tertiary active:text-light active:border-tertiary disabled:bg-opacity-0 disabled:cursor-not-allowed',
    danger: 'bg-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white',
  },
}

export default buttonStyles
