import { 
  useContext, useState, useEffect, useRef,
} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { IDisplayDetail, ISemDetail } from '../../api/imi/interfaces'
import { fixDateObj, commaNumber } from '../../helpers/utils'
import BadgeDot from '../badge/BadgeDot'
import BadgeInfo from '../badge/BadgeInfo'
import Content from '../layouts/Content'
import LoadingSpinner from '../LoadingSpinner'
import DoubleLineGraph from '../charts/DoubleLineGraph'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: IDisplayDetail
}

interface SyncedChart{
  impressions: any
  activities: any
  cpas: any
  labels: any
}

const DisplayImpressionsActivitiesReport:React.FC<Props> = ({
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'
  const [syncedChart, setSyncedChart] = useState<SyncedChart | undefined>(undefined)
  const [reportData, setReportData] = useState<IDisplayDetail>([] as any)

  useEffect(() => (
    setReportData(data)
  ), [data])

  useEffect(() => {
    if (reportData) {
      let impressionsSeriesData
      let activitiesSeriesData
      let cpaSeriesData

      reportData.impressions_graph?.series?.forEach((s) => {
        if (s?.name === 'Impressions') {
          impressionsSeriesData = s?.data.sort((a, b) => (a.x > b.x ? 1 : -1))
        }
        if (s?.name === 'Activities') {
          activitiesSeriesData = s?.data.sort((a, b) => (a.x > b.x ? 1 : -1))
        }
        if (s?.name === 'CPA') {
          cpaSeriesData = s?.data.sort((a, b) => (a.x > b.x ? 1 : -1))
        }
      })

      setSyncedChart({ 
        labels: activitiesSeriesData?.map((c) => fixDateObj(c.x)), 
        impressions: impressionsSeriesData?.map((i) => i.y), 
        activities: activitiesSeriesData?.map((c) => c.y), 
        cpas: cpaSeriesData?.map((c) => c.y),
      })
    }
  }, [reportData])

  return (
    <Content title="Impressions vs. Activities">
      {!reportData?.impressions_graph?.series ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <>
          {(reportData?.impressions?.value || reportData?.activities?.value) ? (
            <div className="pb-6">
              <BadgeDot variant="dot" dotColor="bg-primary" label="Impressions" />
              <BadgeDot variant="dot" dotColor="bg-secondary" label="Activities" />
            </div>
          ) : null}
          <div id="chart-container-imp-activities">
            {(syncedChart && reportData.impressions?.value) || (syncedChart && reportData.activities?.value) ? (
              <DoubleLineGraph 
                labels={syncedChart.labels}
                graph1={{
                  id: 'impressions',
                  name: 'Impressions',
                  values: syncedChart.impressions,
                  tooltip() {
                    const { series, point } = this as any

                    const selectIndex = syncedChart.labels.findIndex((label) => label === point.category)
                    let activitiesValue = 0
                    let cpaValue = 0

                    if (selectIndex !== undefined) {
                      cpaValue = syncedChart.cpas[selectIndex]
                      activitiesValue = syncedChart.activities[selectIndex] as number
                    }
                    return `<span style="color: #fff; font-family: 'Nunito Sans'; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point.category}</span>
                            <span style="background-color: ${colors.primary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 0px 5px;">${series.name}: <b>${commaNumber(point.y)}</b></span><br>
                            <span style="background-color: ${colors.secondary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 10px 5px;">Activities: <b>${commaNumber(activitiesValue)}</b><span><br>
                            <span style="background-color: #a7b1ae; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 10px 5px;">CPA: <b>$${commaNumber(cpaValue.toFixed(2))}</b><span>`
                  },
                }}
                graph2={{
                  id: 'activities',
                  name: 'Activities',
                  values: syncedChart.activities,
                  tooltip() {
                    const { series, point } = this as any

                    const selectIndex = syncedChart.labels.findIndex((label) => label === point.category)
                    let impressionsValue = 0
                    let cpaValue = 0

                    if (selectIndex !== undefined) {
                      cpaValue = syncedChart.cpas[selectIndex]
                      impressionsValue = syncedChart.impressions[selectIndex] as number
                    }
                    return `<span style="color: #fff; font-family: 'Nunito Sans'; font-size: 14px; font-weight: bold; padding: 10px 5px 5px 5px; display: block;">${point.category}</span>
                            <span style="background-color: ${colors.secondary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 0px 5px;">${series.name}: <b>${commaNumber(point.y)}</b></span><br>
                            <span style="background-color: ${colors.primary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 0px 5px;">Impressions: <b>${commaNumber(impressionsValue)}</b><span><br>
                            <span style="background-color: #a7b1ae; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 10px 5px;">CPA: <b>$${commaNumber(cpaValue.toFixed(2))}</b><span>`
                  },
                }}
              />
            ) : (
              <p>{noDataMessage}</p>
            )}
          </div>
        </>
      )}
    </Content>
  )
}

export default DisplayImpressionsActivitiesReport
