// styles
export const settingsStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

export const callSettingsMenu = [
  {
    fieldName: 'name',
    name: 'Industry',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'categories',
    name: 'Categories',
    mobile: false,
    sort: false,
  },
]

export const callProcessingMenu = [
  {
    fieldName: 'name',
    name: 'Campaign',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'calls_total',
    name: '# of Calls',
    mobile: false,
    sort: true,
  },
]
export const flaggedCallSettingsMenu = [
  {
    fieldName: 'name',
    name: 'Campaign',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'calls_total',
    name: '# of Flagged Calls',
    mobile: false,
    sort: true,
  },
]

export const callInstructionsMenu = [
  {
    fieldName: 'names',
    name: 'Campaign',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'instructions',
    name: 'Instructions',
    mobile: false,
    sort: false,
  },
]

export const callProcessStatus = [
  {
    id: 0,
    name: 'Unprocessed',
    value: false,
  },
  {
    id: 1,
    name: 'Processed',
    value: true,
  },
]

export const callStatus = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]

export const callStatusMenu = [
  {
    fieldName: 'names',
    name: 'Campaign',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'call_processing_is_active',
    name: 'Call Processing',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'category.name',
    name: 'Call Industry',
    mobile: true,
    sort: true,
  },
  // {
  //   fieldName: 'type.name',
  //   name: 'Type',
  //   mobile: false,
  //   sort: true,
  // },
]

export const callIndustryColors = [
  {
    id: 1,
    name: 'Blue Green',
    color: '#40a4bc',
  },
  {
    id: 2,
    name: 'Dark Green Blue',
    color: '#0a5e61',
  },
  {
    id: 3,
    name: 'Dark Blue Green',
    color: '#1c4968',
  },
  {
    id: 4,
    name: 'Lavender',
    color: '#85619b',
  },
  {
    id: 5,
    name: 'Orange',
    color: '#e37226',
  },
  {
    id: 6,
    name: 'Magenta',
    color: '#bb297e',
  },
  {
    id: 7,
    name: 'Green',
    color: '#316c39',
  },
  {
    id: 8,
    name: 'Blue Gray',
    color: '#526271',
  },
  {
    id: 9,
    name: 'Burgundy',
    color: '#7c011f',
  },
  {
    id: 10,
    name: 'Navy',
    color: '#21255f',
  },
  {
    id: 11,
    name: 'Indigo',
    color: '#4f429d',
  },
  {
    id: 12,
    name: 'Blue',
    color: '#3b7ac0',
  },
  {
    id: 13,
    name: 'Brown',
    color: '#795647',
  },
  {
    id: 14,
    name: 'Dusty Rose',
    color: '#b4747d',
  },
  {
    id: 15,
    name: 'Olive Green',
    color: '#787c1f',
  },
  {
    id: 16,
    name: 'Gold',
    color: '#cca936',
  },
  {
    id: 17,
    name: 'Burnt Orange',
    color: '#c2522c',
  },
  {
    id: 18,
    name: 'Violet',
    color: '#9034a4',
  },
  {
    id: 19,
    name: 'Sage',
    color: '#75865e',
  },
  {
    id: 20,
    name: 'Salmon',
    color: '#ea6e65',
  },
]
