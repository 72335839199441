import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { IMIApi } from '../../../api/imi/api'
import Padding from '../../../components/layouts/Padding'
import {
  ICallInstruction,
  ICampaignInDb,
} from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import CreateCallInstructions from '../../../components/adminTools/calls/instructions/CreateCallInstructions'
import EditCallInstructions from '../../../components/adminTools/calls/instructions/EditCallInstructions'

const CreateCallInstructionsPage:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [finalLoad, setFinalLoad] = useState(true)
  const [allCampaigns, setAllCampaigns] = useState<ICampaignInDb []>([] as any)
  const [assignedCampaigns, setAssignedCampaigns] = useState<ICampaignInDb []>([] as any)
  const [instructionId, setInstructionId] = useState<number>(0)
  const [instructions, setInstructions] = useState<ICallInstruction>({} as any)
  const [campaignsWithInstructions, setCampaignsWithInstructions] = useState<number []>([])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController1 = new AbortController()
    const abortController2 = new AbortController()
    if (instructionId === 0) {
      const fetchAssignedCampaigns = async () => {
        const response = await IMIApi.GetAllCampaigns(false, abortController1.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        // filter campaigns that only have call processing active.
        setAllCampaigns(response?.data.data.filter((campaign) => campaign.call_processing_is_active))
        return setLoading(false)
      }
      const fetchAllCallInstructions = async () => {
        const response = await IMIApi.GetCallInstructions(abortController2.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON.parse(response)
          setError({ errorMsg: parse.detail })
          return setLoading(false)
        }
        setCampaignsWithInstructions(response.data?.data?.map((camp) => camp?.campaign.id))
        // return setLoading(false)
      }
      fetchAllCallInstructions()
      fetchAssignedCampaigns()
    }
    return () => {
      abortController1.abort()
      abortController2.abort()
    }
  }, [instructionId, setError])

  // filter to get the campaigns that don't have call instructions yet
  const withoutInstructions = useMemo(() => {
    const instructionless = allCampaigns.filter((campaign) => (
      !campaignsWithInstructions?.includes(campaign.id)
    ))
    return instructionless
  }, [allCampaigns, campaignsWithInstructions])

  useEffect(() => {
    setAssignedCampaigns(withoutInstructions)
  }, [withoutInstructions])

  useEffect(() => {
    const abortController = new AbortController()
    if (instructionId > 0) {
      const getInstructionsById = async () => {
        const response = await IMIApi.GetCallInstructionsById(instructionId, abortController.signal)
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setInstructions(response?.data)
        return setLoading(false)
      }
      getInstructionsById()
    }
    return () => abortController.abort()
  }, [instructionId, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          instructionId > 0 ? (
            <EditCallInstructions
              instructions={instructions}
              isLoading={loading}
              edit={false}
            />
          ) : (
            <CreateCallInstructions
              assignedCampaigns={assignedCampaigns}
              setInstructionId={setInstructionId}
              isLoading={loading}
            />
          )
        )}
      />
    </Padding>
  )
}

export default CreateCallInstructionsPage
