import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { handleFilters } from '../../../../helpers/utils'
import Search from '../../../form/Search'
import Content from '../../../layouts/Content'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Icon from '../../../Icon'
import { callSettingsMenu, settingsStyles } from '../Data'
import ToolTip from '../../../ToolTip'
import { ICampaignCategory } from '../../../../api/imi/interfaces'

interface Props {
  callCategories: ICampaignCategory[]
}

const CallCategorySettings:React.FC<Props> = ({
  callCategories,
}) => {
  const { setError } = useContext(AppContext)
  const [errorMsg, setErrorMsg] = useState('There are no call industries found.')
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
  })
  const [filteredIndustries, setFilteredIndustries] = useState<ICampaignCategory[]>()
  // true is descending (highest to lowest, z-a), false is ascending (lowest to highest, a-z)
  const [sortDirection, setSortDirection] = useState<boolean>(false)
  const [sortColumn, setSortColumn] = useState<string>('')

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = sortDirection ? filteredIndustries?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1)) : filteredIndustries?.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1))
    return { values }
  }

  const getCategories = (categories) => {
    const listCategories = categories.map((category, index) => {
      if (index !== categories.length - 1) {
        return `${category.name}; `
      }
      return category.name
    })
    return listCategories
  }

  const filters = useCallback(() => {
    const filterSearchValue = (callIndustryData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = callIndustryData.name.toLowerCase().includes(splitItems)
      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => callIndustryData.name.toLowerCase().includes(si))

        return nameFil
      }
      return nameFilter
    }
    const filterData = callCategories?.filter((industry) => filterSearchValue(industry))
    setFilteredIndustries(filterData)
  }, [selectedFilters, callCategories])

  useEffect(() => {
    filters()
  }, [callCategories, filters])

  return (
    <>
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            label="search"
            hideLabel
            placeholder="search..."
            className="w-full max-w-none"
            // className="mr-3 w-full"
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters))
            )}
          />
          {/* <CreateButtons
            createText="Industry"
            path="/admin-tools/call-category-settings/create-call-industry"
          /> */}
        </div>
      </div>
      <Content
        title="call industries & categories"
        padding={false}
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="call-category-info"
          >
            <span>
              View all call industries and their related categories here. To create or edit an industry or category, reach out to the developer.
            </span>
          </ToolTip>
        )}
      >
        <HorizontalGrid
          smGrid="grid-cols-2"
          mdGrid="md:grid-cols-2"
        >
          {callSettingsMenu.map((item) => (
            item.sort ? (
              <span
                aria-hidden="true"
                onClick={() => columnSortingInfo(item.fieldName)}
                key={item.name}
                className={(
                  `flex break-word group hover:text-secondary hover:cursor-pointer
                  ${item.mobile && settingsStyles.desktop}`
                )}
              >
                {item.name}
                <Icon
                  size="xxs"
                  className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                >
                  <ChevronUpIcon
                    className="-mb-1 text-default group-hover:text-secondary"
                  />
                  <ChevronDownIcon
                    className="text-default group-hover:text-secondary"
                  />
                </Icon>
              </span>
            ) : <span key={item.name}>{item.name}</span>
          ))}
        </HorizontalGrid>
        <div className="items-center">
          {filteredIndustries?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
            filteredIndustries?.map((industry) => (
              <HorizontalGrid
                key={industry.id}
                smGrid="grid-cols-2"
                mdGrid="md:grid-cols-2"
                backgroundColor
              >
                <p className="text-xxs pr-3">
                  {industry.name}
                  {/* <LinkWrapper
                    variant="defaultSm"
                    key={industry.id}
                    href={{
                      pathname: '/admin-tools/call-category-settings/add-call-category',
                      state: {
                        id: industry.id,
                      },
                    }}
                  >
                    {industry.name}
                  </LinkWrapper> */}
                </p>
                <p className="text-xxs pr-6">
                  {getCategories(industry.call_category)}
                </p>
                {/* <EditMenu
                  text="Add Category"
                  id={industry.id}
                  pathname="/admin-tools/call-category-settings/add-call-category"
                /> */}
              </HorizontalGrid>
            ))
          )}
        </div>
      </Content>
    </>
  )
}

export default CallCategorySettings
