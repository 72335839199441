import {
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import ToolTip from '../../../ToolTip'
import Content from '../../../layouts/Content'
import Icon from '../../../Icon'
import { editUpliftCycleDetails, campaignSettingsStyles } from './Data'
import { getFieldByPath } from '../../../../helpers/utils'
import LoadingSpinner from '../../../LoadingSpinner'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import EditUpliftCycleFormProvider from './EditUpliftCycleFormProvider'
import Pagination from '../../../Pagination'

interface Props {
  campaignUplift: any
  campaignType: number
  loading: boolean
  edit: boolean
  setRefresh: Dispatch<SetStateAction<number>>
}

const EditUpliftCycle:React.FC<Props> = ({
  campaignUplift,
  loading,
  campaignType,
  setRefresh,
}) => {
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('start_date')
  const [pageLimit, setPageLimit] = useState<number>(10)
  const backToTop = document.getElementById('uplift-details')

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? campaignUplift?.cycles?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1)) : campaignUplift?.cycles?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1))
    return { ...values }
  }
  const handleLoadMore = () => {
    setPageLimit(pageLimit + (campaignUplift?.cycles.length - pageLimit))
  }

  const handleCollapse = () => {
    setPageLimit(10)
    if (backToTop) {
      backToTop.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <Content
        title="cycle uplift details"
        padding={false}
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="cycle-uplift-details"
          >
            The uplift percent included in this section applies to specific cycles that are distinguished by their start date. If the percent is changed in this section, it will affect only that specific cycle.
            <br />
            <br />
            If a change applies to future cycles as well, it will need to be updated in the “campaign uplift details” section above as well.
          </ToolTip>
        )}
      >
        {loading ? (
          <span className="px-4 md:mx-6 block"><LoadingSpinner variant="primary" size="lg" /></span>
        ) : (
          <div id="uplift-details">
            <HorizontalGrid
              smGrid={campaignType === 2 ? 'grid-cols-4' : 'grid-cols-5'}
              mdGrid={campaignType === 2 ? 'grid-cols-4' : 'grid-cols-5'}
            >
              {editUpliftCycleDetails.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && campaignSettingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
              {campaignType !== 2 && (
                `${'Budget'}`
              )}
            </HorizontalGrid>
            <div className="items-center">
              {!campaignUplift?.cycles || campaignUplift?.cycles.length === 0 ? <p className="error px-6">Campaign has no previous cycles with uplift.</p> : (
                campaignUplift?.cycles?.filter((metric, amount) => (amount < pageLimit)).map((cycle) => (
                  <EditUpliftCycleFormProvider
                    campaignType={campaignType}
                    campaignUplift={cycle}
                    key={cycle.id}
                    campaignId={campaignUplift?.id}
                    setRefresh={setRefresh}
                  />
                ))
              )}
            </div>
            <div
              className={`${campaignUplift.cycles && campaignUplift.cycles.length > 0 ? 'block' : 'hidden'} px-4 pt-3 md:px-6`}
            >
              <Pagination
                pageLimit={pageLimit}
                adminLimit={pageLimit}
                data={campaignUplift?.cycles}
                offset={10}
                onClick={handleLoadMore}
                onClickCollapse={handleCollapse}
              />
            </div>
          </div>
        )}
      </Content>
    </>
  )
}

export default EditUpliftCycle
