interface Props {
  status?: string | boolean
  alert: string | number
}

const styles = {
  running: 'bg-green-default',
  ended: 'bg-red-default',
}

const BadgeAlert: React.FC<Props> = ({
  status,
  alert,
}) => (
  <span className={`${(status || status === 'running') ? styles.running : styles.ended} text-display inline-flex items-center px-2 py-0.5 rounded-lg text-sm text-light font-normal`}>
    {alert}
  </span>
)

export default BadgeAlert
