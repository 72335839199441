import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { UserContext } from '../../../../contexts/UserContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import ToolTip from '../../../ToolTip'
import DualListBox from '../../../DualListBox'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { IUserCampaigns } from '../../../../api/imi/interfaces'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import LoadingSpinner from '../../../LoadingSpinner'

// TODO: figure out the state change to enable/disable buttons

interface Props {
  edit: boolean
}

const AssignCampaigns:React.FC<Props> = ({ edit }) => {
  const {
    userAdIds,
    userInDb,
    userCampIds,
    setUserCampIds,
  } = useContext(UserContext)
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  // All available campaigns
  const [allCampaigns, setAllCampaigns] = useState<any>()
  // Advertisers unassigned to user
  const [campaigns, setCampaigns] = useState<any>()
  // Advertisers assigned to user
  const [assignedCampaigns, setAssignedCampaigns] = useState<any>([])
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(edit)
  const [allAvailableCampaigns, setAllAvailableCampaigns] = useState<any>([] as any)
  // This is to post for user specifically
  const [idArray, setIdArray] = useState<any>()
  // To see if field has been updated based on advertiser being added
  // or removed from user's list.
  const [dirtyField, setDirtyField] = useState<boolean>(false)
  const methods = useForm<IUserCampaigns>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  const campaignsAbortRef = useRef(new AbortController())
  const userCampaignsAbortRef = useRef(new AbortController())

  // TODO: if advertiser is removed, campaigns should also be updated?
  const fetchCampaigns = useCallback((async () => {
    const response = await IMIApi.GetAllCampaigns(false, campaignsAbortRef.current.signal)
    if (!response) {
      return
    }
    if (response.status !== 200) {
      const parse = JSON.parse(response)
      setError({ errorMsg: parse.detail })
      return setLoading(false)
    }
    setAllCampaigns(response.data.data)
    setLoading(false)
  }), [setError])

  const fetchUserCampIds = useCallback((async () => {
    const submitResponse = await IMIApi.GetUserCampaigns(userInDb.id, userCampaignsAbortRef.current.signal)
    if (!submitResponse) {
      return
    }
    if (submitResponse.status !== 200) {
      if (submitResponse.status === 0) {
        setIsButtonLoading(false)
        setError({ errorMsg: 'Internal server error.' })
        return setBtnText('Save')
      }
      const parse = JSON.parse(submitResponse)
      setIsButtonLoading(false)
      setError({ errorMsg: parse.detail })
      return setBtnText('Save')
    }
    setUserCampIds({ user_id: userInDb.id, campaign_ids: submitResponse.data })
    return setLoading(false)
  }), [setError, setUserCampIds, userInDb.id])

  // TODO: finish, find better way to comine
  const refresh = useCallback(() => {
    if (userInDb.user_type_id > 4 || userAdIds?.advertiser_ids) {
      fetchCampaigns()
      fetchUserCampIds()
    }
  }, [userInDb, fetchCampaigns, fetchUserCampIds, userAdIds?.advertiser_ids])

  useEffect(() => {
    if (userInDb.user_type_id > 4 || userAdIds?.advertiser_ids) {
      refresh()
    }
    return () => {
      setUserCampIds({ user_id: userInDb.id, campaign_ids: [] })
      setAllCampaigns([])
    }
  }, [refresh, userInDb, setUserCampIds, userAdIds?.advertiser_ids])
  // filter campaigns based on being unassigned or assigned to user
  const assigned = useMemo(() => (
    allCampaigns?.filter((advertiser) => (
      userCampIds?.campaign_ids.includes(advertiser.id)
    ))), [allCampaigns, userCampIds?.campaign_ids])

  const unassigned = useMemo(() => {
    const filterAdIds = allCampaigns?.filter((advertiser) => (
      userAdIds?.advertiser_ids.includes(advertiser.advertiser?.id)
    ))
    const filterCampIds = filterAdIds?.filter((advertiser) => (
      !userCampIds?.campaign_ids.includes(advertiser.id)
    ))
    return filterCampIds
  }, [allCampaigns, userAdIds?.advertiser_ids, userCampIds?.campaign_ids])

  // Don't want to update advertiser list
  const cancel = () => {
    setAssignedCampaigns(assigned)
    setCampaigns(unassigned)
    setIdArray(userCampIds?.campaign_ids)
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  // Set data for user after refresh and on first render
  useEffect(() => {
    if (userInDb.user_type_id > 4) {
      setAssignedCampaigns(assigned)
      setCampaigns(unassigned)
      setIdArray(userCampIds?.campaign_ids)
      if (assigned && unassigned) {
        return setAllAvailableCampaigns([...assigned, ...unassigned])
      }
      return setAllAvailableCampaigns(null)
    }
    const campaignsAbort = campaignsAbortRef.current
    const userCampaignsAbort = userCampaignsAbortRef.current
    return () => {
      campaignsAbort.abort()
      userCampaignsAbort.abort()
    }
  }, [assigned, unassigned, userInDb.user_type_id, userCampIds?.campaign_ids])

  const onAddAllClick = () => {
    setCampaigns([])
    setAssignedCampaigns(allAvailableCampaigns)
    const allAds = allAvailableCampaigns.map((item) => item.id)
    setIdArray(allAds)
    return setDirtyField(true)
  }

  const onRemoveAllClick = () => {
    setAssignedCampaigns([])
    return setCampaigns(allAvailableCampaigns)
  }

  const editUserAdvertiser = () => {
    setShowButton(true)
  }
  const onSubmit: SubmitHandler<IUserCampaigns> = async () => {
    const submit = {
      user_id: userInDb.id,
      campaign_ids: idArray,
    }
    const submitResponse = await IMIApi.PostUserCampaigns(submit)
    const authenticated = submitResponse.status === 200
    setIsButtonLoading(true)
    setBtnText('Saving...')
    setTimeout(() => {
      if (!authenticated) {
        const parse = JSON.parse(submitResponse)
        setError({ errorMsg: parse.detail })
        setIsButtonLoading(false)
        return setBtnText('Error')
      }
      setUserCampIds({ user_id: userInDb.id, campaign_ids: submitResponse.data })
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  return (
    <Content
      title="campaigns"
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="assign-campaign-info"
        >
          The “available list” of campaigns auto-generates based on the selected list of advertisers for the user. If no campaigns are appearing, or you can’t find what you’re looking for, double-check the user’s advertiser list. You may also need to create or assign the campaign, and then refresh this list.
        </ToolTip>
      )}
    >
      {loading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <DualListBox
              assignedListName="Campaign"
              disabled={!showButton}
              onAddAllClick={onAddAllClick}
              refresh={refresh}
              data={campaigns}
              assignedData={assignedCampaigns}
              idsArray={idArray}
              setIdArray={setIdArray}
              onRemoveAllClick={onRemoveAllClick}
              setDirtyField={setDirtyField}
            />
            <div className="mb-6">
              <LinkWrapper
                variant="defaultAdmin"
                href="/admin-tools/campaign-settings/create-campaign"
                className="inline-block sm:mr-6"
                target="_blank"
              >
                Create New Campaign
                <Icon
                  size="xxs"
                >
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
              <br className="sm:hidden" />
              <LinkWrapper
                variant="defaultAdmin"
                href="/admin-tools/campaign-settings"
                className="inline-block"
                target="_blank"
              >
                Assign Campaign
                <Icon
                  size="xxs"
                >
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
            </div>
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!dirtyField}
              disabledCancel={!dirtyField}
              showButton={showButton}
              onClickCancel={() => cancel()}
              onClickEdit={editUserAdvertiser}
              btnText={btnText}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default AssignCampaigns
