import {
  useContext,
} from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import Content from '../../../layouts/Content'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import { CampaignContext } from '../../../../contexts/CampaignContext'

const CallProcessingCTA:React.FC = () => {
  const { campaignInDb } = useContext(CampaignContext)

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  return (
    <>
      <Content
        title="call settings"
      >
        <p>As part of our SEM services, we offer call processing.  Whether or not we process calls can vary from cycle to cycle.  To view or edit the call settings for a campaign and its cycles, go to the &quot;call settings&ldquo; page.</p>
        <br />
        <LinkWrapper
          disabled={campaignInDb.id < 1}
          target="_blank"
          href="/admin-tools/call-settings/edit-call-settings"
          variant="defaultAdmin"
          className="mb-6 -mt-3 block"
          onClick={() => handleLocalStorage(campaignInDb.id)}
        >
          Go to Call Settings
          <Icon size="xxs" className={`${campaignInDb.id < 1}` ? 'cursor-not-allowed opacity-60' : ''}>
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper>
      </Content>
    </>
  )
}

export default CallProcessingCTA
