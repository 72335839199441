import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'
import { ReactComponent as PDFDownloadIcon } from '../../assets/icons/pdf-download-icon.svg'
import { IMIApi } from '../../api/imi/api'
import Icon from '../Icon'
import ToolTip from '../ToolTip'
import Breadcrumbs from '../navigation/Breadcrumbs'
import { AppContext } from '../../contexts/AppContext'
import { PdfContext } from '../../contexts/PdfContext'
import LoadingSpinner from '../LoadingSpinner'
import { getFileDescriptor } from '../../helpers/utils'
import { ReportContext } from '../../contexts/ReportContext'

const Masthead: React.FC = () => {
  // const [pageTitle, setPageTitle] = useState('')
  const appContext = useContext(AppContext)
  const pdfContext = useContext(PdfContext)
  const {
    currentPage,
    setError,
    campaignData,
    user,
    userType,
  } = appContext
  const [campId, setCampId] = useState<number>(0)
  const [creatingCSV, setCreatingCSV] = useState<boolean>(false)
  const [creatingPDF, setCreatingPDF] = useState<boolean>(false)

  // FIXME - ReportContext didn't want to mount on this component, so just consuming params directly.
  useEffect(() => {
    if (currentPage.pageTitle === 'search engine marketing') {
      setCampId(0)
      const getLocalItems = localStorage.getItem('params')
      if (getLocalItems) {
        const parseItems = JSON.parse(getLocalItems)
        return setCampId(parseItems.id)
      }
      const getSessionItems = sessionStorage.getItem('params')
      if (getSessionItems) {
        const parseItems = JSON.parse(getSessionItems)
        return setCampId(parseItems.id)
      }
    }
  }, [setCampId, currentPage])

  const handleDownload = useCallback(() => {
    const fetchCampaignSummaryDownload = async (id) => {
      setCreatingCSV(true)
      // console.log('called', campId)
      const response = await IMIApi.GetCampaignSummaryDownload(id)
      if (response.status !== 200) {
        setCreatingCSV(false)
        const parse = JSON?.parse(response)
        return setError({ errorMsg: parse?.detail })
      }
      if (!response.status) {
        setCreatingCSV(false)
        return setError({ errorMsg: 'Internal server error' })
      }

      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      const fileDesc = getFileDescriptor(response.headers)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(
        new Blob([response.data], { type: fileDesc.contentType }),
      )
      // Use download attribute to set desired file name
      a.setAttribute('download', fileDesc.filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
      return setCreatingCSV(false)
    }

    // const items = localStorage.getItem('campaign_data')
    // if (items) {
    //   sessionStorage.setItem('campaign_data', items)
    //   localStorage.removeItem('campaign_data')
    // }
    const getSessionItems = sessionStorage.getItem('params')

    if (getSessionItems) {
      const sessionItems = JSON.parse(getSessionItems)
      fetchCampaignSummaryDownload(sessionItems.id)
    }
  }, [setError])

  const handleCampaignCentralDownload = useCallback(() => {
    const fetchCampaignCentralDownload = async () => {
      setCreatingCSV(true)
      const response = await IMIApi.GetCampaignCentralDownload()
      if (response.status !== 200) {
        setCreatingCSV(false)
        const parse = JSON?.parse(response)
        return setError({ errorMsg: parse?.detail })
      }
      if (!response.status) {
        setCreatingCSV(false)
        return setError({ errorMsg: 'Internal server error' })
      }

      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        // eslint-disable-next-line no-bitwise
        for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xFF
        return buf
      }

      const fileDesc = getFileDescriptor(response.headers)
      if (fileDesc.contentType === 'application/excel') {
        // handle base 64 encoding
        const bin = atob(response.data)
        const ab = s2ab(bin)
        a.href = window.URL.createObjectURL(
          new Blob([ab], { type: fileDesc.contentType }),
        )
      } else {
        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
          new Blob([response.data], { type: fileDesc.contentType }),
        )
      }

      // Use download attribute to set desired file name
      a.setAttribute('download', fileDesc.filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
      return setCreatingCSV(false)
    }
    fetchCampaignCentralDownload()
  }, [setError])

  const checkParentheses = () => {
    if (currentPage.pageUrl === '/search-engine-marketing') {
      return 'search engine marketing (sem)'
    }
    if (currentPage.pageUrl === '/admin-tools/return-on-investment') {
      return 'return on investment (roi)'
    }
    if (currentPage.pageUrl === '/admin-tools/return-on-investment/edit-return-on-investment') {
      return 'edit return on investment (roi)'
    }
    return currentPage.pageTitle
  }

  return (
    <header className="masthead bg-tertiary mb-14">
      <div className="pb-10 pt-24 sm:py-10">
        <header>
          <div className="px-6 max-w-site mx-auto lg:px-0 inline-block sm:flex">
            {currentPage.pageTitle.toLowerCase() === 'advertiser dashboard' ? null : <Breadcrumbs route={currentPage} />}
          </div>
          <div className="px-6 max-w-site mx-auto lg:px-0 sm:flex justify-between items-center">
            <h1 className="pb-3">{checkParentheses()}</h1>
            {currentPage.pageUrl === '/search-engine-marketing' && (
              <div className="flex items-center">
                <ToolTip tipId="masthead" size="md" position=" relative inline-block">
                  Search Engine Marketing (SEM)—also
                  known as Pay-Per-Click (PPC)—displays your ad to consumers who are actively looking for your products
                  and services online.
                </ToolTip>
                {userType.isAnyExceptCallAnalyst() && (
                  <>
                    <Icon
                      size="lg"
                      colors="text-primary"
                      className="cursor-pointer group-hover:text-secondary ml-3 mt-3"
                    >
                      <DownloadIcon
                        className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                        onClick={() => handleDownload()}
                      />
                      {creatingCSV && (
                        <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                          <LoadingSpinner variant="primary" size="sm" noPadding />
                        </span>
                      )}
                    </Icon>
                    {!userType.isClient() && !userType.isPartner() && (
                      <Icon
                        size="lg"
                        colors="text-primary"
                        className="cursor-pointer group-hover:text-secondary ml-3 mt-1"
                      >
                        <PDFDownloadIcon
                          className={`text-primary hover:text-secondary w-5 h-5 ${creatingPDF ? 'hidden' : 'block'}`}
                          onClick={() => {
                            setCreatingPDF(true)
                            pdfContext.downloadPdf().then(() => {
                              setCreatingPDF(false)
                            })
                          }}
                        />
                        {creatingPDF && (
                          <span className={creatingPDF ? 'block w-full' : 'hidden'}>
                            <LoadingSpinner variant="primary" size="sm" noPadding />
                          </span>
                        )}
                      </Icon>
                    )}
                  </>
                )}
              </div>
            )}
            {currentPage.pageUrl === '/display-advertising' && (
              <div className="flex items-center">
                <ToolTip
                  tipId="masthead"
                  size="md"
                  position="relative inline-block"
                >
                  Display advertising combines the incredible engagement of multimedia content with precision targeting
                  and data collection. Display ads can be used to directly target individuals in various levels of the
                  sales funnel.
                </ToolTip>
                {userType.isAnyExceptCallAnalyst() && (
                  <>
                    <Icon
                      size="lg"
                      colors="text-primary"
                      className="cursor-pointer group-hover:text-secondary ml-3 mt-3"
                    >
                      <DownloadIcon
                        className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                        onClick={() => handleDownload()}
                      />
                      {creatingCSV && (
                        <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                          <LoadingSpinner variant="primary" size="sm" noPadding />
                        </span>
                      )}
                    </Icon>
                  </>
                )}
              </div>
            )}
            {currentPage.pageUrl === '/video-advertising' && (
              <div className="flex items-center">
                <ToolTip tipId="masthead" size="md" position=" relative inline-block">
                  Reach a broader and more targeted audience with video advertising. Videos 
                  are shared more than any other digital media format, with double the message 
                  recall of traditional TV and cable.
                </ToolTip>
                {userType.isAnyExceptCallAnalyst() && (
                  <>
                    <Icon
                      size="lg"
                      colors="text-primary"
                      className="cursor-pointer group-hover:text-secondary ml-3 mt-3"
                    >
                      <DownloadIcon
                        className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                        onClick={() => handleDownload()}
                      />
                      {creatingCSV && (
                        <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                          <LoadingSpinner variant="primary" size="sm" noPadding />
                        </span>
                      )}
                    </Icon>
                  </>
                )}
              </div>
            )}
            {currentPage.pageUrl === '/campaign-central' && (
              <div className="flex items-center">
                {(userType.isPartner()) && (
                  <Icon
                    size="lg"
                    colors="text-primary"
                    className="cursor-pointer group-hover:text-secondary ml-3 mt-3"
                  >
                    <DownloadIcon
                      className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                      onClick={() => handleCampaignCentralDownload()}
                    />
                    {creatingCSV && (
                      <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                        <LoadingSpinner variant="primary" size="sm" noPadding />
                      </span>
                    )}
                  </Icon>
                )}
              </div>
            )}
            {currentPage.pageUrl === '/admin-tools/email-templates' && (
              <div>
                <ToolTip tipId="masthead" size="md" position=" relative inline-block">
                  An email template determines the
                  branding and content of automatic campaign emails sent from IMI to a user.
                </ToolTip>
              </div>
            )}
            {currentPage.pageUrl === '/admin-tools/call-settings' && (
              <div>
                <ToolTip tipId="masthead" size="md" position=" relative inline-block">
                  Call settings are available for
                  SEM campaigns only. The information on this page reflects the current cycle (for active campaigns) or
                  most recent cycle (for inactive campaigns).
                </ToolTip>
              </div>
            )}
            {currentPage.pageUrl === '/admin-tools/dashboard-branding' && (
              <div>
                <ToolTip tipId="masthead" size="md" position=" relative inline-block">
                  In addition to a customized email
                  template, we also provide our partners with a version of our dashboard that is branded for them. Users
                  connected to a specific brand will see that branding when logging into the dashboard.
                </ToolTip>
              </div>
            )}
            {currentPage.pageUrl === '/admin-tools/return-on-investment' && (
              <div>
                <ToolTip tipId="masthead" size="md" position=" relative inline-block">
                  ROI data is available for SEM
                  campaigns only. The information on this page reflects the current cycle (for active campaigns) or most
                  recent cycle (for inactive campaigns). If ROI is “yes,” then the ROI section is included on the SEM
                  report for that cycle.
                </ToolTip>
              </div>
            )}
          </div>
        </header>
      </div>
    </header>
  )
}

export default Masthead
