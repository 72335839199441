import { useEffect, useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import Button from './Button'
import Icon from '../Icon'

const BackToTopButton:React.FC = () => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    visible ? (
      <Button
        variant="primary"
        submit="button"
        id="back-to-top"
        size="xs"
        className="hidden sm:block rounded-circle w-10 h-10 fixed right-10 bottom-10"
        onClick={scrollToTop}
      >
        <Icon
          colors="text-light"
        >
          <ChevronUpIcon
            className="inline hover:text-light"
          />
        </Icon>
      </Button>
    ) : null
  )
}

export default BackToTopButton
