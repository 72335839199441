import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignTypeInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import CampaignTypes from '../../../components/adminTools/campaigns/campaign_types/CampaignTypes'
import CreateCampaignType from '../../../components/adminTools/campaigns/campaign_types/CreateCampaignType'
import EditCampaignType from '../../../components/adminTools/campaigns/campaign_types/EditCampaignTypes'

const EditCampaignTypesPage:React.FC = () => {
  const location = useLocation()
  const [params, setParams] = useState<any>(location.state)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [campaignType, setCampaignType] = useState<ICampaignTypeInDb>({} as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchCampaignTypes = async () => {
      const response = await IMIApi.GetCampaignTypeById(params.id, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCampaignType(response.data)
      return setLoading(false)
    }
    fetchCampaignTypes()
    return () => abortController.abort()
  }, [params, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditCampaignType
            isLoading={loading}
            campaignType={campaignType}
            edit
          />
        )}
      />
    </Padding>
  )
}
export default EditCampaignTypesPage
