import {
  createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState,
} from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { newUserMe } from '../api/imi/factories'
import {
  IUserError,
  IUserMe,
  IUserPage,
  IAlert, ILogin,
} from '../api/imi/interfaces'
import {
  Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client, IUserType,
} from '../constants/UserTypes'
import { IMIApi, secureAxios } from '../api/imi/api'
import { ENV, PRODUCTION } from '../env'
import * as S from '../constants/Session'

interface AppContextData {
  user: IUserMe
  sessionExpires: Date
  error: IUserError,
  setError: Dispatch<SetStateAction<IUserError>>
  serverError: string,
  setServerError: Dispatch<SetStateAction<any>>
  attemptedUserLoad: boolean
  signOutReason: string | null
  setSignOutReason: Dispatch<SetStateAction<string | null>>
  currentPage: IUserPage
  setCurrentPage: Dispatch<SetStateAction<IUserPage>>
  alert: IAlert
  setAlert: Dispatch<SetStateAction<IAlert>>
  callProcess: any
  setCallProcess: Dispatch<SetStateAction<any>>
  campaignData: any
  setCampaignData: Dispatch<SetStateAction<any>>
  campaignName: any
  setCampaignName: Dispatch<SetStateAction<any>>
  // interfaces
  signIn: (req: ILogin) => void
  signInRefreshToken: () => void
  signOut: () => void
  signOutSessionExpired: () => void
  signOutUserClicked: () => void
  refreshUser: () => void
  isSignedIn: () => boolean
  userType: IUserType
}

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
  return JSON.parse(jsonPayload)
}

const SecondsToDate = (d: string | number): Date => {
  const r = new Date(0)
  r.setUTCSeconds(+d)
  return r
}

const isTokenExpired = (t: string | null): boolean => {
  if (t) {
    const now = new Date()
    const exp_token = SecondsToDate(+t)
    return !(exp_token > now)
  }
  return true
}

export function useAppContextFactory() {
  const [user, setUser] = useState<IUserMe>(newUserMe() as IUserMe)
  const [error, setError] = useState<IUserError>()
  const [serverError, setServerError] = useState<string>('')
  const [attemptedUserLoad, setAttemptedUserLoad] = useState<boolean>(false)
  const [signOutReason, setSignOutReason] = useState<string>(S.REASON_NONE)
  const [sessionExpires, setSessionExpires] = useState<Date>(S.DEFAULT_SESSION_EXPIRES)
  const [currentPage, setCurrentPage] = useState<IUserPage>()
  const [alert, setAlert] = useState<any>()
  const [callProcess, setCallProcess] = useState<any>({} as any)
  const [campaignData, setCampaignData] = useState<any>({} as any)
  const [campaignName, setCampaignName] = useState<string>('')

  // signOut signs out the user
  const signOut = (): void => {
    sessionStorage.clear()
    localStorage.clear()
    localStorage.setItem('access_token', '')
    localStorage.setItem('access_token_expires', '')
    localStorage.setItem('refresh_token', '')
    setSessionExpires(S.DEFAULT_SESSION_EXPIRES)
    setUser(newUserMe())
    setError({ errorMsg: null })
  }

  const signOutSessionExpired = (): void => {
    setSignOutReason(S.REASON_SESSION_EXPIRED)
    return signOut()
  }

  const signOutUserClicked = (): void => {
    setSignOutReason(S.REASON_USER_CLICKED)
    return signOut()
  }

  const signOutServiceOffline = useCallback(() => {
    setSignOutReason(S.REASON_SERVICE_OFFLINE)
    return signOut()
  }, [])

  // Handle SignOut effect on API Calls when a 403 response is returned
  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => (
      // console.log('↗ ️')
      response
    )
    const errInterceptor = (err: AxiosError) => {
      // console.log('↙️ ')
      // Unauthorized
      if (err.code === 'ERR_CANCELED') {
        return
      }
      if (err.response?.status === 403) {
        signOut()
        // return Promise.reject(err)
      }
      if (err.response?.status === 502) {
        // console.log('502 called.')
        signOutServiceOffline()
        // return Promise.reject(err)
      }
      if (typeof err.response === 'undefined') {
        // console.log('502 because of undefined called.')
        signOutServiceOffline()
      }
      return Promise.reject(err)
    }

    const interceptor = secureAxios.interceptors.response.use(resInterceptor, errInterceptor)
    return () => secureAxios.interceptors.response.eject(interceptor)
  }, [signOutServiceOffline])

  // isSignedIn returns true if the user is signed in and their token is unexpired
  const isSignedIn = (): boolean => {
    const token = localStorage.getItem('access_token')
    const token_user_id = localStorage.getItem('token_user_id')
    const u = user
    if (u && token && token_user_id) {
      const valid_user = u.id > 0
      const token_matches_user = u.id === +token_user_id
      if (valid_user && token_matches_user) {
        const exp = localStorage.getItem('access_token_expires') || ''
        if (!isTokenExpired(exp)) {
          return true
        }
        signOut()
      }
    }
    return false
  }

  // refreshUser updates user details if a valid access_token exists
  const refreshUser = async (): Promise<void> => {
    if (isSignedIn()) {
      return
    }
    const userToken = localStorage.getItem('access_token')
    if (userToken) {
      const x = localStorage.getItem('access_token_expires') || ''
      const foo = new Date(0)
      // console.log('exp=', exp)
      foo.setUTCSeconds(+x)
      // console.log('token_expires=', foo)
      // console.log('refreshUser.userToken=True')
      const response = await IMIApi.getMe()
      const authenticated = response && response.status === 200 && response.data.username
      if (authenticated) {
        // console.log('refreshUser.authenticated=True')
        if (sessionExpires.getTime() === S.DEFAULT_SESSION_EXPIRES.getTime()) {
          const exp = localStorage.getItem('access_token_expires')
          if (exp) {
            const te = SecondsToDate(exp)
            setSessionExpires(te)
          }
        }
        setUser(response.data as IUserMe)
      }
    }
    if (!attemptedUserLoad) {
      setAttemptedUserLoad(true)
    }
  }

  /**
   * SignIn using Credentials
   * @description Allows a user to sign-in to the IMI application with Username and
   * password combination.  Upon success, `user` and `sessionExpires` are updated
   * with the current session.
   */
  const signIn = async (creds: ILogin): Promise<void> => {
    // console.log('signIn() called')
    const loginResponse = await IMIApi.LogInGetToken(creds as ILogin)
    const authenticated = loginResponse.status === 200 && loginResponse.data.access_token

    // if (!loginResponse) {
    //   setSignOutReason(S.REASON_SERVICE_OFFLINE)
    //   signOut()
    // }
    if (!authenticated) {
      // console.log('signIn().authenticated=false')
      try {
        const parse = JSON.parse(loginResponse)
        setServerError(parse?.detail)
      } catch {
        // Probably an unprocessable response; backend is offline.
        return signOutServiceOffline()
      }
    }
    if (authenticated) {
      const tokenContents = parseJwt(loginResponse.data.access_token)
      const te = SecondsToDate(tokenContents.exp)
      setSessionExpires(te)

      if (ENV.appEnvironment !== PRODUCTION) {
        // Debug odd session expiration timer appearing on non production environments.
        // console.log('signIn SessionExpires=>', SecondsToDate(tokenContents.exp), te)
      }
      localStorage.setItem('access_token', loginResponse.data.access_token as string)
      localStorage.setItem('access_token_expires', tokenContents.exp)
      localStorage.setItem('token_user_id', tokenContents.sub) // user's id
      localStorage.setItem('refresh_token', loginResponse.data.refresh_token as string)
      localStorage.setItem('token_type', loginResponse.data.token_type as string) // unused
      localStorage.setItem('user_type_id', loginResponse.data.user_type_id as string) // unused
      setSignOutReason(S.REASON_NONE)
      await refreshUser()
    }
  }

  /**
   * SignIn using a Refresh Token
   * @description Allows a user to extend their session by attempting to login
   * again with a previously provided refresh_token.  Upon success, `user` and
   * `sessionExpires` are updated with the current session.
   */
  const signInRefreshToken = async (): Promise<void> => {
    const token = localStorage.getItem('refresh_token')
    // console.log('signInRefreshToken.refresh_token=', token)
    if (token) {
      const loginResponse = await IMIApi.LogInGetTokenFromRefreshToken(token)
      const authenticated = loginResponse.status === 200 && loginResponse.data.access_token
      if (!authenticated) {
        // console.log('signInRefreshToken.Auth=False')
        const parse = JSON.parse(loginResponse)
        setServerError(parse?.detail)
      }
      if (authenticated) {
        // console.log('signInRefreshToken.Auth=True')
        const tokenContents = parseJwt(loginResponse.data.access_token)
        const te = SecondsToDate(tokenContents.exp)
        if (ENV.appEnvironment !== PRODUCTION) {
          // Debug odd session expiration timer appearing on non production environments.
          // console.log('signInRefreshToken(new,old)', te, sessionExpires, te > sessionExpires)
        }
        setSessionExpires(S.DEFAULT_SESSION_EXPIRES)
        setSessionExpires(te)
        localStorage.setItem('access_token', loginResponse.data.access_token as string)
        localStorage.setItem('access_token_expires', tokenContents.exp)
        localStorage.setItem('token_user_id', tokenContents.sub) // user's id
        localStorage.setItem('refresh_token', loginResponse.data.refresh_token as string)
        localStorage.setItem('token_type', loginResponse.data.token_type as string) // unused
        localStorage.setItem('user_type_id', loginResponse.data.user_type_id as string) // unused
        // console.log('signIn().authenticated=true')
        setSignOutReason(S.REASON_NONE)
        await refreshUser()
      }
    }
  }

  // isSuperuser returns true if the user is a Superuser
  const isSuperUser = (): boolean => {
    const u = user
    if (u) {
      return isSignedIn() && u.is_superuser
    }
    return false
  }

  const userType = {
    // primitives
    isAdmin() { return user.user_type_id === Admin },
    isTeamViewer() { return user.user_type_id === TeamViewer },
    isCallManager() { return user.user_type_id === CallManager },
    isCallAnalyst() { return user.user_type_id === CallAnalyst },
    isPartner() { return user.user_type_id === Partner },
    isClient() { return user.user_type_id === Client },
    // compositions
    isAnyEncompass() { return [Admin, TeamViewer, CallAnalyst, CallManager].includes(user.user_type_id) },
    isAnyExceptCallAnalyst() { return [Admin, TeamViewer, CallManager, Partner, Client].includes(user.user_type_id) },
  }

  return {
    user,
    sessionExpires,
    signOutReason,
    setSignOutReason,
    attemptedUserLoad,
    error,
    setError,
    serverError,
    setServerError,
    currentPage,
    setCurrentPage,
    alert,
    setAlert,
    callProcess,
    setCallProcess,
    campaignData,
    setCampaignData,
    campaignName,
    setCampaignName,
    // interfaces
    signIn,
    signInRefreshToken,
    signOut,
    signOutSessionExpired,
    signOutUserClicked,
    refreshUser,
    isSignedIn,
    isSuperUser,
    userType,
  } as AppContextData
}

export const AppContext = createContext<AppContextData>({} as any)
