import {
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import ToolTip from '../../../ToolTip'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import {
  ICallSettingsChange,
  ICampaignCallSettings,
  ICampaignCategory,
} from '../../../../api/imi/interfaces'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import LoadingSpinner from '../../../LoadingSpinner'
import CallSettingsCycle from './CallSettingsCycle'
import Input from '../../../form/Input'
import CallSettingsForm from '../../../form/campaigns/campaign_settings/CallSettingsForm'

interface Props {
  campaign: ICampaignCallSettings
  callIndustries: ICampaignCategory[]
  loading: boolean
  edit: boolean
  setRefreshTodo: any
}

const EditCallSettings:React.FC<Props> = ({
  campaign,
  callIndustries,
  loading,
  edit,
  setRefreshTodo,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)
  const [showButton, setShowButton] = useState<boolean>(edit)
  const [formDefaults, setFormDefaults] = useState<ICallSettingsChange>({} as any)
  
  useEffect(() => {
    setFormDefaults({
      id: campaign.id,
      category_id: campaign.category?.id,
      call_processing_is_active: campaign.call_processing_is_active,
    })
  }, [campaign])

  const methods = useForm<ICallSettingsChange>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      id: campaign.id,
      category_id: campaign.category?.id,
      call_processing_is_active: campaign.call_processing_is_active,
    },
  })

  useEffect(() => {
    methods.reset({ ...formDefaults })
  }, [methods, formDefaults])

  const cancel = () => {
    methods.reset(formDefaults, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.SetCampaignCallSettingId(data)

    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setRefreshTodo(Math.random)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }
  const editTemplate = () => {
    setShowButton(true)
  }

  return (
    <>
      <Content
        title="call settings details"
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="white-label-info"
          >
            SEM campaigns typically have call processing unless restricted by HIPAA or at the client&lsquo;s request. If we are not processing calls, then all calls are automatically categorized as leads.
            <br />
            <br />
            Changes made here to the call processing settings impact current and future cycles only.
          </ToolTip>
        )}
      >
        {loading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              {(campaign?.id > 0) && (
              <>
                <Input
                  disabled
                  name="campaign"
                  label="Campaign"
                  type="text"
                  value={`${campaign.secondary_name ? `${campaign.name}: ${campaign.secondary_name}` : campaign.name} ${campaign?.specific?.name ? `- ${campaign?.specific?.name}` : ''}`}
                  required={false}
                />
                <CallSettingsForm
                  callIndustries={callIndustries}
                  errors={methods.formState.errors}
                  showButton={showButton}
                  campaign={campaign}
                />
              </>
              )}
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabled={!methods.formState.isValid || !methods.formState.isDirty}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                btnText={btnText}
                showButton={showButton}
                onClickEdit={editTemplate}
              />
            </form>
          </FormProvider>
        )}
      </Content>
      <CallSettingsCycle
        cycles={campaign.cycles}
        loading={loading}
        edit={edit}
      />
    </>
  )
}

export default EditCallSettings
