import {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IDisplayDetail } from '../../api/imi/interfaces'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'
import HorizontalGrid from '../layouts/HorizontalGrid'
import ToolTip from '../ToolTip'
import { placementBreakdownMenu, styles } from './Data'
import { commaNumber, getFileDescriptor } from '../../helpers/utils'
import Icon from '../Icon'
import Accordion from '../layouts/Accordion'
import { Admin } from '../../constants/UserTypes'
import { IMIApi } from '../../api/imi/api'
import LoadingSpinner from '../LoadingSpinner'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'

interface Props {
  data: IDisplayDetail
}

const DisplayPlacementBreakdown:React.FC<Props> = ({
  data,
}) => {
  const [reportData, setReportData] = useState<IDisplayDetail>([] as any)
  const { user, setError } = useContext(AppContext)
  const [creatingCSV, setCreatingCSV] = useState<boolean>(false)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('impressions')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')

  useEffect(() => {
    if (data.placements) {
      setReportData({
        ...data,
        placements: data.placements.sort((a, b) => b.impressions - a.impressions),
      })
    }
  }, [data])

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = !sortDirection ? reportData.placements.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : reportData.placements.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }

  const handleDownload = useCallback(() => {
    const fetchPlacementBreakdownDownload = async () => {
      setCreatingCSV(true)
      const response = await IMIApi.GetPlacementBreakdown({
        id: data.campaign.id,
        start_date: data.report_start_date,
        end_date: data.query_end_date,
      })
      if (response.status !== 200) {
        setCreatingCSV(false)
        const parse = JSON?.parse(response)
        return setError({ errorMsg: parse?.detail })
      }
      if (!response.status) {
        setCreatingCSV(false)
        return setError({ errorMsg: 'Internal server error' })
      }

      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      const fileDesc = getFileDescriptor(response.headers)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(
        new Blob([response.data], { type: fileDesc.contentType }),
      )

      // Use download attribute to set desired file name
      a.setAttribute('download', fileDesc.filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
      return setCreatingCSV(false)
    }
    fetchPlacementBreakdownDownload()
  }, [data, setCreatingCSV, setError])

  return (
    <Content
      title="Placement breakdown"
      padding={false}
      icon={(
        <div className="flex gap-3">
          <Icon>
            <Icon
              size="md"
              colors="text-primary"
              className="cursor-pointer group-hover:text-secondary mt-1.5"
            >
              <DownloadIcon
                className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                onClick={() => handleDownload()}
              />
              {creatingCSV && (
                <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                  <LoadingSpinner variant="primary" size="sm" noPadding />
                </span>
              )}
            </Icon>
          </Icon>
          <ToolTip
            tipId="placementbreakdown"
            size="sm"
            position="block relative pr-0"
          >
            Previous cycles will be listed below if they continue to have tracked website actions taken by users who were served your ad (activities).
          </ToolTip>
        </div>
      )}
    >
      <>
        <HorizontalGrid
          smGrid={`${user.user_type_id !== Admin ? 'grid-cols-placements-sm' : 'grid-cols-placements-admin-sm'}`}
          mdGrid={`${user.user_type_id !== Admin ? 'md:grid-cols-placements' : 'md:grid-cols-placements-admin'}`}
          header
        >
          {placementBreakdownMenu.map((item) => (
            item.forUserTypes.includes(user.user_type_id) ? (
              <div key={item.name} className={`break-all group flex center-items ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}>
                {item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                  >
                    <span className="inline lg:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                    <span className="hidden lg:inline">{item.name}</span>
                    <Icon
                      size="xxs"
                      className="text-default group sm:pr-0 ml-1 -mt-1"
                    >
                      <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                      <ChevronDownIcon className="text-default group-hover:text-secondary" />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>}
              </div>
            ) : null))}
        </HorizontalGrid>
        <div>
          {reportData?.placements?.length > 0 ? (
            reportData?.placements
              .map((placement) => (
                <div key={placement.pc_id} className="odd:bg-grey-lightest pt-6 pb-2 horizontal-grid">
                  <Accordion
                    header={(
                      <HorizontalGrid
                        smGrid={`${user.user_type_id !== Admin ? 'grid-cols-placements-sm' : 'grid-cols-placements-admin-sm'}`}
                        mdGrid={`${user.user_type_id !== Admin ? 'md:grid-cols-placements' : 'md:grid-cols-placements-admin'}`}
                      >
                        <p className="small pr-1.5">
                          {placement?.name}
                        </p>
                        <p className="small pr-1.5">
                          {commaNumber(placement?.impressions)}
                        </p>
                        <p className={`small pr-1.5 ${styles.desktop}`}>
                          {`${((placement?.percent_total_impressions) * 100).toFixed(0)}%`}
                        </p>
                        <p className="small pr-1.5">
                          {commaNumber(placement?.activities)}
                        </p>
                        <p className="small pr-1.5">
                          {`$${placement.cpa.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        </p>
                        {
                          user.user_type_id === Admin ? (
                            <p className={`small pr-1.5 ${styles.desktop}`}>
                              {`$${placement.spend.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                            </p>
                          ) : null
                        }
                      </HorizontalGrid>
                    )}
                    body={(
                      <div className="flex justify-between">
                        <p className="text-xxs text-gray-500">
                          <span className={styles.mobile}>
                            % of Total Impressions:
                            &nbsp;
                          </span>
                          {`${((placement?.percent_total_impressions) * 100).toFixed(0)}%`}
                        </p>
                        { user.user_type_id === Admin ? (
                          <p className="text-xxs text-gray-500">
                            <span className={styles.mobile}>
                              Budget:
                              &nbsp;
                            </span>
                            {`$${placement.spend.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })}`}
                          </p>
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              ))
          ) : (
            <p className="px-4 md:px-6">{!data.report_end_date && noDataMessage}</p>
          )}
        </div>
      </>
    </Content>
  )
}

export default DisplayPlacementBreakdown
