import { ICampaignInDb, IUserInDb, IUserMe } from './interfaces'

// User who is currently logged into dashboard
export function newUserMe(props: Partial<IUserMe> = {}) {
  return {
    id: 0,
    username: '',
    email: '',
    user_type_id: 0,
    first_name: '',
    white_label_id: 0,
    branding: false,
    is_active: true,
    is_superuser: false,
    created_at: new Date(),
    updated_at: new Date(),
    user_type: {
      name: '',
    },
    white_label: {
      email_template: {
        name: '',
        logo_url: '',
      },
      url_login: '',
      url_displayed: '',
      color_primary: '#40a4bc',
      color_primary_dark: '#3a7e99',
      color_secondary: '#29b48b',
      color_tertiary: '#1c4968',
    },
    advertisers: [] as any,
    campaigns: [] as any,
    ...props,
  }
}

// user who is being created or edited in the database
export function newUserInDb(props: Partial<IUserInDb> = {}) {
  return {
    username: '',
    email: '',
    first_name: '',
    user_type_id: 0,
    white_label_id: 1,
    is_active: true,
    is_superuser: false,
    email_for_all_reports: '',
    email_for_lead_reports: '',
    email_for_flagged_calls: '',
    id: 0,
    branding: false,
    user_type: {
      name: '',
    },
    white_label: {
      url_login: '',
      url_displayed: '',
    },
    created_at: new Date(),
    updated_at: new Date(),
    last_seen_at: new Date(),
    advertiser_set: [{
      id: 0,
      name: '',
    }] as any,
    campaign_set: [] as any,
    ...props,
  }
}

export function newBrand() {
  return {
    id: null,
    name: '',
  }
}

export function newTemplate() {
  return {
    name: '',
    logo_url: '',
    id: 0,
  }
}

export function newAdvertiser() {
  return {
    name: '',
    is_active: true,
    id: 0,
    created_at: new Date(),
    updated_at: new Date(),
  }
}

export function newCampaignInDb(props: Partial<ICampaignInDb> = {}) {
  return {
    is_active: true,
    name: '',
    secondary_name: '',
    roi_is_active: false,
    uplift_base: 0,
    campaign_assignment_to_parent: false,
    call_processing_is_active: false,
    id: 0,
    advertiser: {} as any,
    category: {} as any,
    type: {
      name: '',
      short_name: '',
      id: 0,
    },
    specific: {
      name: '',
      id: 0,
    },
    pcampaign: [] as any,
    lead_types: [] as any,
    cycles: [] as any,
    ...props,
  }
}
