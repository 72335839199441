import { RGB, LineCapStyle } from 'pdf-lib'
import { Pages } from './pages'
import { Colors } from './colors'
import { 
  BADGE_BORDER_RADIUS, 
  BORDER_RADIUS, 
  BORDER_SIZE, 
  BAR_BORDER_SIZE,
} from '../sizes'

const getBadgePath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w / 2},${y}`,
  `L ${x + w - BADGE_BORDER_RADIUS},${y}`,
  `Q ${x + w},${y} ${x + w},${y + BADGE_BORDER_RADIUS}`,
  `L ${x + w},${y + h - BADGE_BORDER_RADIUS}`,
  `Q ${x + w},${y + h} ${x + w - BADGE_BORDER_RADIUS},${y + h}`,
  `L ${x + BADGE_BORDER_RADIUS},${y + h}`,
  `Q ${x},${y + h} ${x},${y + h - BADGE_BORDER_RADIUS}`,
  `L ${x},${y + BADGE_BORDER_RADIUS}`,
  `Q ${x},${y} ${x + BADGE_BORDER_RADIUS},${y} z`,
].join(' ')

const getLinePath = (dx: number, dy: number, x: number = 0, y: number = 0) => [
  `M ${x},${y}`,
  `L ${dx},${dy}`,
].join(' ')

const getCirclePath = (r: number, x: number = 0, y: number = 0) => [
  `M ${x},${y}`,
  `M ${r}, 0`,
  `a ${r},${r} 0 1,0 ${-(r * 2)}, 0`,
  `a ${r},${r} 0 1,0  ${(r * 2)}, 0`,
].join(' ')

const getUpArrowPath = (r: number, x: number = 0, y: number = 0) => [
  `M ${x},${y}`,
  `M 0, ${r}`,
  `L 0, ${-r}`,
  `M ${r}, 0`,
  `L 0, ${-r}`,
  `M ${-r}, 0`,
  `L 0, ${-r}`,
].join(' ')

const getDownArrowPath = (r: number, x: number = 0, y: number = 0) => [
  `M ${x},${y}`,
  `M 0, ${-r}`,
  `L 0, ${r}`,
  `M ${r}, 0`,
  `L 0, ${r}`,
  `M ${-r}, 0`,
  `L 0, ${r}`,
].join(' ')

const getContainerPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w / 2},${y}`,
  `L ${x + w - BORDER_RADIUS},${y}`,
  `Q ${x + w},${y} ${x + w},${y + BORDER_RADIUS}`,
  `L ${x + w},${y + h - BORDER_RADIUS}`,
  `Q ${x + w},${y + h} ${x + w - BORDER_RADIUS},${y + h}`,
  `L ${x + BORDER_RADIUS},${y + h}`,
  `Q ${x},${y + h} ${x},${y + h - BORDER_RADIUS}`,
  `L ${x},${y + BORDER_RADIUS}`,
  `Q ${x},${y} ${x + BORDER_RADIUS},${y} z`,
].join(' ')

const getContainerHeaderPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w / 2},${y}`,
  `L ${x + w - BORDER_RADIUS},${y}`,
  `Q ${x + w},${y} ${x + w},${y + BORDER_RADIUS}`,
  `L ${x + w},${y + h}`,
  `L ${x},${y + h}`,
  `L ${x},${y + BORDER_RADIUS}`,
  `Q ${x},${y} ${x + BORDER_RADIUS},${y} z`,
].join(' ')

const getRectPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w / 2},${y}`,
  `L ${x + w},${y}`,
  `L ${x + w},${y + h}`,
  `L ${x},${y + h}`,
  `L ${x},${y} z`,
].join(' ')

const getContainerFooterPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w / 2},${y}`,
  `L ${x + w},${y}`,
  `L ${x + w},${y + h - BORDER_RADIUS}`,
  `Q ${x + w},${y + h} ${x + w - BORDER_RADIUS},${y + h}`,
  `L ${x + BORDER_RADIUS},${y + h}`,
  `Q ${x},${y + h} ${x},${y + h - BORDER_RADIUS}`,
  `L ${x},${y} z`,
].join(' ')

const getContainerFooterBorderPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w},${y}`,
  `L ${x + w},${y + h - BORDER_RADIUS}`,
  `Q ${x + w},${y + h} ${x + w - BORDER_RADIUS},${y + h}`,
  `L ${x + BORDER_RADIUS},${y + h}`,
  `Q ${x},${y + h} ${x},${y + h - BORDER_RADIUS}`,
  `L ${x},${y}`,
].join(' ')

const getContainerRowPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w / 2},${y}`,
  `L ${x + w},${y}`,
  `L ${x + w},${y + h}`,
  `L ${x},${y + h}`,
  `L ${x},${y} z`,
].join(' ')

const getContainerRowBorderPath = (w: number, h: number, x: number = 0, y: number = 0) => [
  `M ${x + w},${y - 1}`,
  `L ${x + w},${y + h + 1}`,
  `M ${x},${y - 1}`,
  `L ${x},${y + h + 1}`,
].join(' ')

const getLightBulbPath = () => [
  'M 14, 20',
  'v -5.25',
  'm 0, 0',
  'a 6.01, 6.01, 0, 0, 0, 1.5, -.189',
  'm -1.5, .189',
  'a 6.01, 6.01, 0, 0, 1, -1.5, -.189',
  'm 3.75, 7.478',
  'a 12.06, 12.06, 0, 0, 1, -4.5, 0', 
  'm 3.75, 2.383',
  'a 14.406, 14.406, 0, 0, 1, -3, 0',
  'M 16.25, 20',
  'v -.192',
  'c 0, -.983, .658, -1.823, 1.508, -2.316',
  'a 7.5, 7.5, 0, 1, 0, -7.517, 0',
  'c .85, .493, 1.509, 1.333, 1.509, 2.316',
  'V 18',
].join(' ')

export type Drawing = {
  drawFlaggedIcon: (radius: number) => void
  drawLightBulbIcon: (radius: number) => void
  drawContainer: (w: number, h: number) => void
  drawContainerHeader: (w: number, h: number) => void
  drawContainerRow: (w: number, h: number, color: RGB) => void
  drawContainerFooter: (w: number, h: number, color: RGB) => void
  drawBarGraph: (length: number, percent: number, color: RGB) => void
  drawBadge: (w: number, h: number, color: RGB) => void
  drawUpArrow: (radius: number) => void
  drawDownArrow: (radius: number) => void
  drawLine: (x: number, y: number, color: RGB) => void
  drawDashedLine: (x: number, y: number, color: RGB) => void
  drawCircle: (radius: number, color: RGB) => void
  drawRect: (width: number, height: number, color: RGB) => void
}

export const getDrawing = (pages: Pages, colors: Colors): Drawing => ({
  drawContainer: (w, h) => pages.currentPage.drawSvgPath(
    getContainerPath(w, h), {
      borderColor: colors.darkGrey,
      borderWidth: BORDER_SIZE, 
      color: colors.white,
    },
  ),
  drawContainerHeader: (w, h) => pages.currentPage.drawSvgPath(
    getContainerHeaderPath(w, h), {
      borderColor: colors.darkGrey,
      borderWidth: BORDER_SIZE, 
      color: colors.grey,
    },
  ),
  drawContainerRow: (w, h, color: RGB) => {
    pages.currentPage.drawSvgPath(
      getContainerRowPath(w, h), {
        color,
      },
    )
    pages.currentPage.drawSvgPath(
      getContainerRowBorderPath(w, h), {
        borderColor: colors.darkGrey,
        borderWidth: BORDER_SIZE,
      },
    )
  },
  drawContainerFooter: (w, h, color: RGB) => {
    pages.currentPage.drawSvgPath(
      getContainerFooterPath(w, h), {
        borderColor: color,
        borderWidth: BORDER_SIZE, 
        color,
      },
    )
    pages.currentPage.drawSvgPath(
      getContainerFooterBorderPath(w, h), {
        borderColor: colors.darkGrey,
        borderWidth: BORDER_SIZE,
      },
    )
  },
  drawBarGraph: (length: number, percent: number, color: RGB) => {
    pages.currentPage.drawSvgPath(
      getLinePath(length, 0), {
        borderColor: colors.darkGrey,
        borderWidth: BAR_BORDER_SIZE, 
        borderLineCap: LineCapStyle.Round,
        color: colors.darkGrey,
      },
    )
    pages.currentPage.drawSvgPath(
      getLinePath(length * percent, 0), {
        borderColor: color,
        borderWidth: BAR_BORDER_SIZE, 
        borderLineCap: LineCapStyle.Round,
        color,
      },
    )
  },
  drawBadge: (w: number, h: number, color: RGB) => pages.currentPage.drawSvgPath(
    getBadgePath(w, h), {
      borderColor: color,
      borderWidth: BORDER_SIZE, 
      color,
    },
  ),
  drawUpArrow: (radius: number) => {
    pages.currentPage.drawSvgPath(
      getCirclePath(radius), {
        color: colors.green,
      },
    )
    pages.currentPage.drawSvgPath(
      getUpArrowPath(radius / 3), {
        borderColor: colors.white,
        borderWidth: radius / 4, 
        borderLineCap: LineCapStyle.Round,
        color: colors.white,
      },
    )
  },
  drawDownArrow: (radius: number) => {
    pages.currentPage.drawSvgPath(
      getCirclePath(radius), {
        color: colors.red,
      },
    )
    pages.currentPage.drawSvgPath(
      getDownArrowPath(radius / 3), {
        borderColor: colors.white,
        borderWidth: radius / 4, 
        borderLineCap: LineCapStyle.Round,
        color: colors.white,
      },
    )
  },
  drawLine: (x: number, y: number, color: RGB) => pages.currentPage.drawSvgPath(
    getLinePath(x, y), {
      borderColor: color,
      borderWidth: 1,
    },
  ),
  drawDashedLine: (x: number, y: number, color: RGB) => pages.currentPage.drawSvgPath(
    getLinePath(x, y), {
      borderDashArray: [3],
      borderColor: color,
      borderWidth: 1,
    },
  ),
  drawCircle: (radius: number, color: RGB) => pages.currentPage.drawSvgPath(
    getCirclePath(radius), {
      borderColor: color,
      borderWidth: 1,
      color,
    },
  ),
  drawRect: (width: number, height: number, color: RGB) => pages.currentPage.drawSvgPath(
    getRectPath(width, height), {
      borderColor: color,
      borderWidth: 1,
      color,
    },
  ),
  drawFlaggedIcon: (radius: number) => {
    pages.currentPage.drawSvgPath('M22.5,0A22.5,22.5,0,0,0,6.58,38.4L38.4,6.58A22.45,22.45,0,0,0,22.5,0Z', { 
      color: colors.primary,
      scale: radius / 45,
    })
    pages.currentPage.drawSvgPath('M22.5,45A22.5,22.5,0,0,0,38.4,6.58L6.58,38.4A22.43,22.43,0,0,0,22.5,45Z', { 
      color: colors.primaryDark,
      scale: radius / 45,
    })
    pages.currentPage.drawSvgPath('M18,12.43a6,6,0,0,0-1.29.64,10.9,10.9,0,0,0-2.47,2.37.4.4,0,0,0-.07.44L17.91,27l.09.26.18-.21c.43-.47.83-1,1.28-1.4A6.57,6.57,0,0,1,21.93,24a5.83,5.83,0,0,1,2.84-.23c.86.14,1.72.31,2.58.47a5.41,5.41,0,0,0,5.07-1.54,15.16,15.16,0,0,0,2-2.61.47.47,0,0,0,0-.43l-1.86-5.5L30.77,8.6l-.09-.29a1.86,1.86,0,0,0-.14.21A15.44,15.44,0,0,1,28.62,11a5.82,5.82,0,0,1-2.27,1.49,5.27,5.27,0,0,1-2.64.19L21,12.22a6.07,6.07,0,0,0-3,.21Z', {
      color: colors.white,
      scale: radius / 45,
    })
    pages.currentPage.drawSvgPath(
      getLinePath(13, 12.5, 21, 34), {
        borderWidth: 2, 
        borderLineCap: LineCapStyle.Round,
        borderColor: colors.white,
        color: colors.white,
        scale: radius / 45,
      },
    )
    pages.currentPage.drawSvgPath(
      getCirclePath(1.5, 21, 34), {
        color: colors.white,
        scale: radius / 45,
      },
    )
    pages.currentPage.drawSvgPath(
      getCirclePath(1.5, 13, 12.5), {
        color: colors.white,
        scale: radius / 45,
      },
    )
  },
  drawLightBulbIcon: (radius: number) => {
    pages.currentPage.drawSvgPath('M22.5,0A22.5,22.5,0,0,0,6.58,38.4L38.4,6.58A22.45,22.45,0,0,0,22.5,0Z', { 
      color: colors.primary,
      scale: radius / 45,
    })
    pages.currentPage.drawSvgPath('M22.5,45A22.5,22.5,0,0,0,38.4,6.58L6.58,38.4A22.43,22.43,0,0,0,22.5,45Z', { 
      color: colors.primaryDark,
      scale: radius / 45,
    })
    pages.currentPage.drawSvgPath(getLightBulbPath(), {
      borderColor: colors.white,
      borderWidth: 1.5,
      borderLineCap: LineCapStyle.Round,
      scale: radius / 28,
    })
  },
})
