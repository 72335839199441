import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

interface Props {
  label: string
  options: any
  required?: boolean
  subheading?: string
  name: string
  disabled?: boolean
  error?: any
}

const Radio:React.FC<Props> = ({
  options,
  subheading,
  required = true,
  label,
  name,
  disabled,
  error,
}) => {
  const methods = useFormContext()
  const { register } = methods
  // console.log(methods.watch(name))
  return (
    <div className="mb-6">
      <label className="block heading-5 font-semibold">
        {label}
        {required ? <span className="text-primary">*</span> : null}
      </label>
      {subheading ? <p className="text-sm leading-5 text-gray-500">{subheading}</p> : null}
      <fieldset className="mt-2">
        <legend className="sr-only">{label}</legend>
        {options.length < 3 ? (
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
            {options.map((option) => (
              <div key={option.id} className="flex items-center">
                <input
                  {...register(name, { required, setValueAs: (value) => value?.toString() })}
                  type="radio"
                  name={name}
                  id={option.label}
                  disabled={disabled}
                  data-testid={option.label}
                  value={option.value}
                  className="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                />
                <label htmlFor={option.label} className="ml-3 block font-display text-xs text-default">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <div className="block">
            {options.map((option) => (
              <div key={option.id} className="flex items-center mb-3">
                <input
                  {...register(name, { required, setValueAs: (value) => value?.toString() })}
                  type="radio"
                  name={name}
                  id={option.label}
                  disabled={disabled}
                  data-testid={option.label}
                  value={option.value}
                  className="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                />
                <label htmlFor={option.label} className="ml-3 block font-display text-xs text-default">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
        {error && <p className="form-error">{error}</p>}
      </fieldset>
    </div>
  )
}

Radio.defaultProps = {
  required: true,
  subheading: undefined,
  disabled: false,
  error: undefined,
}

export default Radio
