import { PDF } from '../lib'
import * as sizes from '../sizes'

export const drawHeader = (pdf: PDF) => {
  pdf.pages.moveUp(sizes.PAGE_MARGIN)
  pdf.pages.moveLeft(sizes.PAGE_MARGIN)
  pdf.drawing.drawRect(
    pdf.pages.pageWidth + sizes.PAGE_MARGIN * 2, 
    sizes.PAGE_HEADER + sizes.PAGE_MARGIN,
    pdf.colors.white,
  )
  pdf.pages.moveDown(sizes.PAGE_MARGIN / 2)
  pdf.pages.moveRight(sizes.PAGE_MARGIN)
  pdf.images.draw(sizes.PAGE_HEADER)
  pdf.pages.moveDown(sizes.PAGE_HEADER + sizes.PAGE_MARGIN / 2)
}
