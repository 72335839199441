import LoadingSpinner from '../components/LoadingSpinner'

function LoadingCheck(Component) {
  return function WithLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return (<Component {...props} />)
    // if (error) return (<span>Error loading. Please refresh and try again</span>)
    return (<LoadingSpinner variant="primary" size="lg" />)
  }
}
export default LoadingCheck
