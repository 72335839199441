import {
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import { ICampaignType, ICampaignTypeInDb, ICampaignTypeMultiResponse } from '../../../../api/imi/interfaces'
import CampaignTypeForm from '../../../form/campaigns/CampaignTypeForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'

interface Props {
  isLoading: boolean
  campaignType: ICampaignTypeInDb
  edit: boolean
}

const EditCampaignType:React.FC<Props> = ({
  isLoading,
  campaignType,
  edit,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)

  const methods = useForm<ICampaignTypeInDb>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      name: campaignType.name,
      short_name: campaignType.short_name,
      id: campaignType.id,
      defaultValues: {
        specifics: [
          {
            name: '',
            id: 0,
          },
        ],
      },
    }), [campaignType]),
  })

  useEffect(() => {
    methods.reset({ ...campaignType })
  }, [campaignType, methods])

  const cancel = () => {
    methods.reset({ ...campaignType }, { keepErrors: false, keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<ICampaignTypeInDb> = async (data) => {
    const formData = {
      ...data,
      specifics: data.specifics.map((specific) => specific.name),
    }
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateCampaignTypeById(formData)
    setTimeout(() => {
      if (submitResponse && submitResponse.status !== 200) {
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      if (!submitResponse.status) {
        setIsButtonLoading(false)
        setError({ errorMsg: 'Internal server error.' })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
  }
  return (
    <Content
      title="campaign type details"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <CampaignTypeForm
            errors={methods.formState.errors}
            campaign={campaignType}
            showButton
          />
          <FormButtonGroup
            isButtonLoading={isButtonLoading}
            disabled={!methods.formState.isValid || !methods.formState.isDirty}
            disabledCancel={!methods.formState.isValid || !methods.formState.isDirty}
            onClickCancel={cancel}
            btnText={btnText}
            showButton
          />
        </form>
      </FormProvider>
    </Content>
  )
}

export default EditCampaignType
