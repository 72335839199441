interface Props {
  children?: React.ReactNode
  border?: boolean
  title: {}
}

const ReportHeader:React.FC<Props> = ({
  children,
  title,
  border = true,
}) => (
  <div className={border ? 'my-6' : 'my-0'}>
    <h2
      className={border ? 'border-b border-b-1 border-grey-dark border-dotted' : ''}
    >
      {title}
    </h2>
    {children && (
      <div className="block mt-6 sm:flex">
        {children}
      </div>
    )}
  </div>
)

export default ReportHeader
