import { useEffect, useMemo, useState } from 'react'
import {
  useFormContext,
  Controller,
  useFieldArray,
  useWatch,
} from 'react-hook-form'
import { ChevronRightIcon, TrashIcon } from '@heroicons/react/outline'
import Modal from '../../modal'
import Dropdown from '../Dropdown'
import Radio from '../Radio'
import Input from '../Input'
import { callIndustryColors } from '../../adminTools/calls/Data'
import { IAdvertiserInDb, ICampaignCategory } from '../../../api/imi/interfaces'
import Badge from '../../badge/Badge'
import Icon from '../../Icon'
import LinkWrapper from '../../Links'
import DropdownAdmin from '../DropdownAdmin'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  callIndustry?: ICampaignCategory
}

const CallIndustryForm:React.FC<Props> = ({
  showButton,
  errors,
  callIndustry,
}) => {
  const methods = useFormContext()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [btnText, setBtnText] = useState('Change')
  const [currentSelection, setCurrentSelection] = useState<any>()
  const [specificIds, setSpecificIds] = useState<any>([])
  const [colors3, setColors3] = useState<any>([])
  const { fields, append, remove } = useFieldArray({
    ...methods.control,
    name: 'call_category',
  })
  const id = 0
  // const fieldArrayName = 'array'
  // const Display = ({ control, index }) => {
  //   const data = useWatch({
  //     control,
  //     name: `${fieldArrayName}.${index}`,
  //   })
  // }

  const modal = (itemIndex) => {
    setBtnText('Remove')
    setCurrentSelection(itemIndex)
    setShowModal(true)
  }

  const removeItem = () => {
    remove(currentSelection)
    return setShowModal(false)
  }

  const callCategory = useWatch({
    ...methods.control,
    name: 'call_category',
  })
  // const specificIds = watchSpecifics?.map((specific) => specific.id)
  // useEffect(() => {
  //   setSpecificIds(callCategory?.map((specific) => specific.id))
  // }, [])

  useEffect(() => {
    const colors = callIndustryColors.map((color) => color.color)
    const test2 = callCategory?.map((specific) => specific.color)
    const test = colors.filter((color2) => (test2?.includes(color2)))

    // Now you have the colors, now get its id
    const colorObject = callIndustryColors.filter((colorId) => test.includes(colorId.color))
    setSpecificIds(colorObject.map((color) => color.id))
  }, [methods, callCategory])
  // const controlledFields = fields.map((field, index) => {
  //   const test = 1
  //   return {
  //     ...field,
  //     ...watchSpecifics[index].id,
  //   }
  // })
  // console.log(controlledFields)
  // console.log(idArray)
  const addNumber = (index) => {
    const number = index + 1
    if (number < 10) {
      if (number < 5 && callIndustry?.id === 0) {
        return `0${number} (standard)`
      }
      return `0${number}`
    }
    return number
  }
  return (
    <>
      <Modal
        // isButtonLoading={!showButton}
        show={showModal}
        title="Confirm Change"
        body={(
          `Remove Category ${currentSelection + 1}?`
        )}
        onSubmit={() => removeItem()}
        onClose={() => setShowModal(false)}
        closeText="Cancel"
        submitText={btnText}
      />
      <Input
        disabled={callIndustry ? true : !showButton}
        label={callIndustry?.id ? 'Industry name' : 'New industry name'}
        type="text"
        toolTipInfo={(
          <span>
            test
          </span>
        )}
        {...methods.register('name', { required: true })}
        error={errors.name && <span className="form-error">{errors.name.message || 'This field is required.'}</span>}
      />
      {callIndustry?.id && (
        <>
          <hr />
          <h5 className="mb-3">Current campaign specifics</h5>
          {callIndustry?.call_category.map((category) => (
            <Badge
              key={category.id}
              label={category.name}
              variant="dot"
              dotColor={`bg-[${category.color}]`}
            />
          ))}
        </>
      )}
      <ul>
        {fields.map((item, index) => (
          <li key={item.id}>
            <hr />
            <div className="flex justify-between items-baseline">
              <h4 className="pb-3">{`Category ${addNumber(index)}`}</h4>
              {index > 3 && (
                <LinkWrapper
                  href="#"
                  variant="defaultAdmin"
                  className="mb-6 inline-block"
                  onClick={() => modal(index)}
                >
                  <Icon size="sm" colors="text-primary">
                    <TrashIcon className="text-primary hover:text-secondary global-animation" />
                  </Icon>
                </LinkWrapper>
              )}

            </div>
            <Input
              label="Name"
              type="text"
              {...methods.register(`call_category.${index}.name` as const)}
            />
            <Controller
              name={`call_category.${index}.id` as const}
              control={methods.control}
              rules={{ required: true }}
              render={({
                field,
              }) => (
                <DropdownAdmin
                  disabled={!showButton}
                  hookFormField={field}
                  // placeholder="Select..."
                  disabledOptions={specificIds}
                  // placeholder={userInDb.id > 0 ? undefined : 'Select...'}
                  label="Color"
                  variant="admin"
                  value={methods.getValues(`call_category?.${index}.id`)}
                  list={callIndustryColors}
                  error={errors.color && <span className="form-error">This field is required</span>}
                  toolTipInfo="test"
                  required
                />
              )}
            />
          </li>
        ))}
      </ul>
      <LinkWrapper
        href="#"
        variant="defaultAdmin"
        className="mb-6 inline-block"
        onClick={() => (
          append({
            name: '',
            color: '',
            // id: null,
          })
        )}
      >
        Add Additional Category
        <Icon size="xxs">
          <ChevronRightIcon />
        </Icon>
      </LinkWrapper>
      <br />
      {/* <Input
        label="Name"
        name="category name"
        type="text"
        value="Closed"
      />
      <Controller
        name="color"
        control={methods.control}
        rules={{ required: true }}
        render={({
          field,
        }) => (
          <DropdownAdmin
            disabled={!showButton}
            hookFormField={field}
            placeholder="Select..."
            // placeholder={userInDb.id > 0 ? undefined : 'Select...'}
            label="Color"
            variant="admin"
            // value={methods.getValues('color')}
            list={callIndustryColors}
            error={errors.color && <span className="form-error">This field is required</span>}
            toolTipInfo="test"
            required
          />
        )}
      /> */}
    </>
  )
}

export default CallIndustryForm
