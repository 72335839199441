import {
  useState,
  useContext,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { ICallInstructionCreate, ICampaignInDb, ICampaignMultiResponse } from '../../../../api/imi/interfaces'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import CallInstructionsForm from '../../../form/calls/CallInstructionsForm'
import LoadingSpinner from '../../../LoadingSpinner'
import FormButtonGroup from '../../../button/FormButtonGroup'

interface Props {
  assignedCampaigns: ICampaignInDb[]
  setInstructionId: Dispatch<SetStateAction<number>>
  isLoading: boolean
}

const CreateCallInstructions:React.FC<Props> = ({
  assignedCampaigns,
  setInstructionId,
  isLoading,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)
  const [campaigns, setCampaigns] = useState<any>()

  useEffect(() => {
    setCampaigns(assignedCampaigns?.filter((camp) => camp.call_processing_is_active))
  }, [assignedCampaigns])

  const methods = useForm<ICallInstructionCreate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })

  const cancel = () => {
    methods.reset({}, { keepErrors: false, keepDirty: false })
    setBtnText('Save')
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  const onSubmit: SubmitHandler<ICallInstructionCreate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.CreateCallInstructions(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setInstructionId(submitResponse?.data?.id)
      setIsButtonLoading(false)
      setBtnText('Saved!')
    }, 1000)
  }
  return (
    <>
      <Content
        title="instruction details"
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <CallInstructionsForm
                errors={methods.formState.errors}
                assignedCampaigns={campaigns}
                showButton
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabled={!methods.formState.isValid}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                btnText={btnText}
                showButton
              />
            </form>
          </FormProvider>
        )}
      </Content>
    </>
  )
}

export default CreateCallInstructions
