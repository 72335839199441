import {
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import { 
  useForm, 
  SubmitHandler, 
  FormProvider, 
  Controller, 
  useFormContext, 
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../../contexts/AppContext'
import { CampaignContext } from '../../../contexts/CampaignContext'
import { IPlatform } from '../../../api/imi/interfaces'
import Content from '../../layouts/Content'
import FormButtonGroup from '../../button/FormButtonGroup'
import { IMIApi } from '../../../api/imi/api'
import LoadingSpinner from '../../LoadingSpinner'
import ApiSettingsStackAdaptForm from './ApiSettingsStackAdaptForm'
import Input from '../../form/Input'
import Dropdown from '../../form/Dropdown'

interface Props {
  platforms: IPlatform[]
  isLoading: boolean
}

const CreateApiSettings:React.FC<Props> = ({
  platforms,
  isLoading,
}) => {
  const { setError } = useContext(AppContext)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const history = useHistory()
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      platform_id: 0,
      rest: '',
      gql: '',
    }), []),
  })

  const platformId = methods.watch('platform_id')

  const cancel = () => {
    history.push('/admin-tools/api-settings')
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('platform_creds_id', JSON.stringify(id))
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')

    const submitResponse = await IMIApi.CreatePlatformCred(data) as any
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setBtnText('Saved!')

      handleLocalStorage(submitResponse.data.id)
      history.push('/admin-tools/api-settings/edit-api-settings')
    }, 1000)
  }

  return (
    <>
      <Content title="api settings">
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <Controller
                  name="platform_id"
                  control={methods.control}
                  rules={{ required: true }}
                  render={({
                    field,
                  }) => (
                    <Dropdown
                      hookFormField={field}
                      placeholder="Select..."
                      // placeholder={campaignInDb?.id > 0 ? methods.getValues('name') : 'Select...'}
                      value={field.value}
                      label="Platform"
                      variant="admin"
                      list={platforms}
                      error={methods.formState.errors.platform_id && <span className="form-error">This field is required</span>}
                      required
                      tipId="platform_id"
                    />
                  )}
                />
                {platformId === 2 && <ApiSettingsStackAdaptForm showButton={showButton} newKeys />}
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isValid || !methods.formState.isDirty}
                  disabledCancel={false}
                  onClickCancel={cancel}
                  btnText={btnText}
                  showButton
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default CreateApiSettings
