import {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {
  FormProvider,
  useForm,
  Controller,
} from 'react-hook-form'
import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/outline'
import 'bootstrap-daterangepicker/daterangepicker.css'
import {
  ICallProccessingSummary,
  ICallProcessByCampaign,
} from '../../../../api/imi/interfaces'
import Content from '../../../layouts/Content'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import LoadingSpinner from '../../../LoadingSpinner'
import Icon from '../../../Icon'
import Dropdown from '../../../form/Dropdown'
import ProcessCallsFormProvider from './ProcessCallsFormProvider'
import LinkWrapper from '../../../Links'
import Input from '../../../form/Input'
import { fixDateObjApi } from '../../../../helpers/utils'
import { AppContext } from '../../../../contexts/AppContext'

interface Props {
  calls: ICallProcessByCampaign
  isLoading: boolean
  callCategories: any
  campaignsWithCalls: ICallProccessingSummary
  callInstructions: any
  dateRange?: boolean
}

const ProcessCalls:React.FC<Props> = ({
  calls,
  isLoading,
  callCategories,
  campaignsWithCalls,
  callInstructions,
  dateRange,
}) => {
  const history = useHistory()
  const { callProcess, setCallProcess } = useContext(AppContext)
  const [errorMsg, setErrorMsg] = useState('There are no calls for the campaign found.')
  const [showButton, setShowButton] = useState(true)
  const [campaigns, setCampaigns] = useState<any>()
  const [truncateClass, setTruncateClass] = useState<boolean>(true)
  const dateRanges = useRef(callProcess)

  useEffect(() => {
    setCampaigns(campaignsWithCalls?.detail?.sort((a, b) => (a.advertiser?.name.localeCompare(b.advertiser?.name)) || (a?.secondary_name > b?.secondary_name ? 1 : -1)))
  }, [campaignsWithCalls])

  useEffect(() => {
    if (calls.rows_total < 1) {
      history.push('/call-processing')
    }
  }, [calls, history])

  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  // Switch out calls for specific campaign ID
  const watchCamp = methods.watch('id')

  // TODO: fix dependency issue with parameters and setParameters
  // Need to get date picker working to update date ranges....
  useEffect(() => {
    if (watchCamp !== undefined) {
      sessionStorage.setItem('call_process_id', watchCamp)
      if (dateRange) {
        dateRanges.current.setStartDate(moment(fixDateObjApi(new Date(new Date().setDate(new Date().getDate() - 30)))))
        dateRanges.current.setEndDate(moment(fixDateObjApi(new Date())))
      }
      return setCallProcess({ id: watchCamp, start_date: fixDateObjApi(new Date(new Date().setDate(new Date().getDate() - 30))), end_date: fixDateObjApi(new Date()) })
      // return window.location.reload()
    }
  }, [watchCamp, setCallProcess, dateRange])

  const toggleClass = () => {
    setTruncateClass(!truncateClass)
  }

  return (
    <>
      <AdminToolsMobileLink />
      {campaigns?.length > 0 && (
        <>
          <FormProvider {...methods}>
            <form
              id="switchCampaign"
              noValidate
            >
              <Controller
                name="id"
                control={methods.control}
                rules={{ required: false }}
                render={({
                  field,
                }) => (
                  <Dropdown
                    disabled={!showButton}
                    hookFormField={field}
                    // placeholder={parameters?.id > 0 ? undefined : 'Select...'}
                    label="Campaign"
                    variant="border_full"
                    value={callProcess?.id}
                    list={campaigns}
                    campaign
                    callTotal={!dateRange}
                  />
                )}
              />
            </form>
          </FormProvider>
          {dateRange && (
            <DateRangePicker
              initialSettings={{
                startDate: callProcess.start_date > 0 ? moment(callProcess.start_date).format('MM/DD/YYYY') : moment().subtract(30, 'days').format('MM/DD/YYYY'),
                endDate: callProcess.end_date > 0 ? moment(callProcess.end_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
                maxDate: moment(new Date()).format('MM/DD/YYYY'),
              }}
              ref={dateRanges}
              onCallback={(start, end) => setCallProcess({ ...callProcess, start_date: fixDateObjApi(start.format('MM/DD/YYYY')), end_date: fixDateObjApi(end.format('MM/DD/YYYY')) })}
            >
              <Input
                type=""
                name="date picker"
                label="date picker"
                hideLabel
                className="form-control font-bold font-display max-w-none"
                icon={<Icon><CalendarIcon /></Icon>}
              />
            </DateRangePicker>
          )}
          <Content
            title="processing instructions"
          >
            <p>Calls need to be processed within three days.</p>
            <p
              className={truncateClass ? 'clamp-text' : ''}
            >
              {callInstructions?.body}
            </p>
            {callInstructions?.body && (
              <LinkWrapper
                variant="defaultAdmin"
                className="group pb-6 inline-block"
                onClick={() => toggleClass()}
              >
                {truncateClass ? 'See Full Instructions' : 'Hide Full Instructions'}
                <Icon size="xxs" className="group-hover:text-secondary">
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
            )}
          </Content>
        </>
      )}
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <>
          {calls?.data?.length === 0 ? <p className="error">{errorMsg}</p> : (
            calls.data?.sort((a, b) => (a.occurred_at > b.occurred_at ? -1 : 1)).map((call, index) => (
              <ProcessCallsFormProvider
                key={call.id}
                call={call}
                index={index}
                callCategories={callCategories}
              />
            ))
          )}
        </>
      )}
    </>
  )
}

export default ProcessCalls
