import { cls } from '../helpers/utils'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const styles = {
  base: 'align-middle focus:outline-none inline-block z-10 leading-none global-animation',
  size: {
    xxs: 'h-2 w-2',
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6',
    xl: 'h-8 w-8',
    nav: 'h-7, w-7',
  },
  colors: {
    light: 'text-light',
    default: 'text-primary',
    grey: 'text-menuTitle',
    red: 'text-red',
    green: 'text-green',
  },
}

interface Props {
  children: React.ReactNode
  onClick?: any
  download?: string
  id?: string
  className?: string
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'nav'
  href?: string,
  colors?: string,
  text?: React.ReactNode
  disabled?: boolean
}

const Icon: React.FC<Props> = ({
  children,
  onClick,
  download,
  id,
  className,
  size = 'md',
  href,
  colors = 'default',
  text,
  disabled,
}) => (
  href ? (
    <a
      className={cls(`${styles.base} ${styles.colors[colors]} ${className} ${download} ${styles.colors[colors] === 'text-light' ? 'group group-hover:text-light block' : 'group group-hover:text-secondary'}`)}
      onClick={onClick}
      aria-hidden="true"
      id={id}
      href={href}
    >
      {text}
      <span className={`${styles.colors[colors] === 'text-light' && onClick ? 'group-hover:text-light block' : 'group-hover:text-secondary'} ${styles.size[size]} inline-block`}>
        {children}
      </span>
    </a>
  ) : (
    <div
      className={(
        classNames(
          styles.base,
          styles.colors[colors],
          download,
          `${onClick ? 'cursor-pointer' : ''}`,
          `${styles.colors[colors] === 'text-light' && onClick ? 'group' : ''}`,
          'global-animation',
          className,
        )
      )}
      aria-hidden="true"
      id={id}
      onClick={onClick}
    >
      {text}
      <span className={`${styles.colors[colors] === 'text-light' ? 'group-hover:text-light block' : 'group-hover:text-secondary'} ${styles.size[size]} inline-block`}>
        {children}
      </span>
    </div>
  )
)
Icon.defaultProps = {
  size: 'md',
}

export default Icon
