import {
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import {
  IAdvertiserInDb,
  ICampaignUpdate,
  ICampaignInDb,
  IPCampaign,
} from '../../../../api/imi/interfaces'
import Content from '../../../layouts/Content'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { IMIApi } from '../../../../api/imi/api'
import ToolTip from '../../../ToolTip'
import LoadingSpinner from '../../../LoadingSpinner'
import AssignAdvertiserForm from '../../../form/campaigns/campaign_settings/CreateAssignCampaignForm'

interface Props {
  allAdvertisers: IAdvertiserInDb[]
  isLoading: boolean
  createdCampaigns?: ICampaignInDb[]
}

const AdvertiserAssignment:React.FC<Props> = ({
  allAdvertisers,
  isLoading,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const methods = useForm<ICampaignUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      campaign_assignment_to_parent: campaignInDb?.type?.id !== 2,
      id: campaignInDb?.id,
      is_active: campaignInDb?.is_active,
      name: campaignInDb?.name,
      secondary_name: campaignInDb?.secondary_name,
      roi_is_active: campaignInDb?.roi_is_active,
      call_processing_is_active: campaignInDb?.call_processing_is_active,
      uplift_base: campaignInDb?.uplift_base,
      advertiser_id: campaignInDb?.advertiser?.id,
      category_id: campaignInDb?.category?.id,
      specific_id: campaignInDb?.specific?.id,
      type_id: campaignInDb?.type?.id,
    }), [campaignInDb]),
  })

  useEffect(() => {
    methods.reset(
      {
        campaign_assignment_to_parent: campaignInDb?.type?.id !== 2,
        id: campaignInDb?.id,
        is_active: campaignInDb?.is_active,
        name: campaignInDb?.name,
        secondary_name: campaignInDb?.secondary_name,
        roi_is_active: campaignInDb?.roi_is_active,
        call_processing_is_active: campaignInDb?.call_processing_is_active,
        uplift_base: campaignInDb?.uplift_base,
        advertiser_id: campaignInDb?.advertiser?.id,
        category_id: campaignInDb?.category?.id,
        specific_id: campaignInDb?.specific?.id,
        type_id: campaignInDb?.type?.id,
      },
    )
  }, [campaignInDb, methods])

  const cancel = () => {
    methods.reset(
      {
        campaign_assignment_to_parent: campaignInDb?.type?.id !== 2,
        id: campaignInDb?.id,
        is_active: campaignInDb?.is_active,
        name: campaignInDb?.name,
        secondary_name: campaignInDb?.secondary_name,
        roi_is_active: campaignInDb?.roi_is_active,
        call_processing_is_active: campaignInDb?.call_processing_is_active,
        uplift_base: campaignInDb?.uplift_base,
        advertiser_id: campaignInDb?.advertiser?.id,
        category_id: campaignInDb?.category?.id,
        specific_id: campaignInDb?.specific?.id,
        type_id: campaignInDb?.type?.id,
      },
    )
    setBtnText('Save')
    setShowButton(false)
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  // NOTE: take the campaignId and add the advertiser ID
  const onSubmit: SubmitHandler<ICampaignUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      id: campaignInDb.id,
      is_active: campaignInDb.is_active,
      name: data.name,
      secondary_name: campaignInDb.secondary_name,
      roi_is_active: campaignInDb.roi_is_active,
      advertiser_id: data.advertiser_id,
      call_processing_is_active: campaignInDb.call_processing_is_active,
      uplift_base: campaignInDb.uplift_base,
      category_id: campaignInDb.category?.id,
      specific_id: campaignInDb.specific?.id,
      type_id: campaignInDb.type?.id,
      // pcampaign_id: data.pcampaign_id,
    }

    const submitResponse = await IMIApi.UpdateCampaign(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setCampaignInDb(submitResponse.data)
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setShowButton(false)
      setBtnText('Save')
    }, 1000)
  }
  const edit = () => {
    setShowButton(true)
  }

  return (
    <>
      <Content
        title="campaign assignment"
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="campaign-assignment-info"
          >
            All video and display campaigns have a parent campaign (whether or not there is only one campaign, or multiple campaigns assigned to it).
            <br />
            <br />
            The “available campaigns” list displays created (parent) campaigns only. If no campaigns are appearing, or you can’t find what you’re looking for, you many need to create a new campaign first and then refresh this list.
          </ToolTip>
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <AssignAdvertiserForm
                allAdvertisers={allAdvertisers}
                errors={methods.formState.errors}
                showButton={showButton}
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                // disabled={!methods.formState.isDirty || !methods.formState.isValid || campaignInDb.id <= 0}
                disabled={!methods.formState.isDirty || !methods.formState.isValid}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                onClickEdit={edit}
                btnText={btnText}
                showButton={showButton}
              />
            </form>
          </FormProvider>
        )}
      </Content>
    </>
  )
}

export default AdvertiserAssignment
