import { PDF, FontStyle, Font } from '../lib'
import { Keyword } from '../data'
import * as sizes from '../sizes'

const COL_1_WIDTH = 6 / 16
const COL_2_WIDTH = 3 / 16
const COL_3_WIDTH = 2 / 16
const COL_4_WIDTH = 5 / 16

const getTable = (pdf: PDF, data: Keyword[]) => {
  const pageWidth = (pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 5)
  return {
    title: pdf.text.drawText('top performing keywords', pdf.colors.black, { style: FontStyle.Bold }),
    header: [
      pdf.text.drawText('Keywords', pdf.colors.black, { width: pageWidth * COL_1_WIDTH }),
      pdf.text.drawText('Impressions', pdf.colors.black, { width: pageWidth * COL_2_WIDTH }),
      pdf.text.drawText('Clicks', pdf.colors.black, { width: pageWidth * COL_3_WIDTH }),
      pdf.text.drawText('Click-Through Rate (CTR)', pdf.colors.black, { width: pageWidth * COL_4_WIDTH }),
    ],
    rows: data.map((email) => [
      pdf.text.drawText(email.keyword, pdf.colors.darkestGrey, { width: pageWidth * COL_1_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(email.impressions, pdf.colors.darkestGrey, { width: pageWidth * COL_2_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(email.clicks, pdf.colors.darkestGrey, { width: pageWidth * COL_3_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(email.ctr, pdf.colors.darkestGrey, { width: pageWidth * COL_4_WIDTH, font: Font.Heebo }),
    ]),
  }
}

const drawRow = (pdf: PDF, height: number, row: { width: number, height: number, render: () => void }[]) => {
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  row.forEach((col) => {
    col.render()
    pdf.pages.moveRight(col.width + sizes.CONTAINER_MARGIN)
  })
  pdf.pages.moveDown(height - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
}

export const drawTopPerformingKeywords = (pdf: PDF, data: Keyword[]) => {
  const table = getTable(pdf, data)
  const titleHeight = table.title.height + sizes.CONTAINER_MARGIN * 2
  const headerHeight = table.header[0].height + sizes.CONTAINER_MARGIN * 2

  if (titleHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerHeader(pdf.pages.pageWidth, titleHeight)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  table.title.render()
  pdf.pages.moveDown(titleHeight - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()

  if (headerHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerRow(pdf.pages.pageWidth, headerHeight, pdf.colors.white)
  drawRow(pdf, headerHeight, table.header)

  table.rows.forEach((row, i) => {
    const height = row[0].height + sizes.CONTAINER_MARGIN * 2

    if (height > pdf.pages.remainingHeight) {
      pdf.pages.nextPage()
    }

    if (i % 2 === 0) {
      if (i === data.length - 1) {
        pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, height, pdf.colors.grey)
      } else {
        pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.grey)
      }
    } else if (i === data.length - 1) {
      pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, height, pdf.colors.white)
    } else {
      pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.white)
    }

    drawRow(pdf, height, row)
  })

  pdf.pages.moveDown(sizes.SECTION_MARGIN)
}
