import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react'
import { ChevronDownIcon, ChevronUpIcon, VolumeUpIcon } from '@heroicons/react/solid'
import { AppContext } from '../../contexts/AppContext'
import Pagination from '../Pagination'
import Content from '../layouts/Content'
import Icon from '../Icon'
import Accordion from '../layouts/Accordion'
import ToolTip from '../ToolTip'
import HorizontalGrid from '../layouts/HorizontalGrid'
import Badge from '../badge/Badge'
import BadgeAlert from '../badge/BadgeAlert'
import { IMIApi } from '../../api/imi/api'
import { ReportContext } from '../../contexts/ReportContext'
import { PdfContext } from '../../contexts/PdfContext'
import { enhancedCallAnalyticsMenu, styles } from './Data'
import {
  fixDateTimeObj,
  fixHoursObj,
  getFieldByPath,
  getFileDescriptor,
  fixDateObjApi,
} from '../../helpers/utils'
import { ICallProcessingItem } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'

interface Props {
  filter?: number
  flaggedOnly?: boolean
  callProcessing?: boolean
  campaignName?: string
}

const EnhancedCallAnalyticsReport: React.FC<Props> = ({
  filter,
  flaggedOnly,
  callProcessing,
  campaignName,
}) => {
  const {
    user,
    userType,
    setError,
  } = useContext(AppContext)
  const {
    parameters,
    reportParameters,
  } = useContext(ReportContext)
  const pdf = useContext(PdfContext)
  // data coming in from SEM report page
  // different limits on number of items shown based on user type (admin or other)
  const [pageLimit, setPageLimit] = useState(0)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('occurred_at')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState(true)
  const [callsData, setCallsData] = useState<ICallProcessingItem[]>([] as any)
  // const [callsData, setCallsData] = useState<ICallsByCampaignAndStatus>({} as any)
  const [loading, setLoading] = useState<boolean>(true)
  const mediaMatch = window.matchMedia('(min-width: 767px)')
  // const mediaMatch = window.matchMedia('(min-width: 1024px)')
  const [matches, setMatches] = useState(mediaMatch.matches)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')
  const backToTop = document.getElementById('enhanced-calls')

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = sortDirection ? callsData?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1)) : callsData?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1))
    return { values }
  }

  useEffect(() => {
    const handler = (e) => (setMatches(e.matches))
    mediaMatch.addEventListener('change', handler)
    return () => mediaMatch.removeEventListener('change', handler)
  }, [mediaMatch])

  useEffect(() => {
    if (filter || flaggedOnly) {
      return setPageLimit(callsData.length)
    }
    return setPageLimit(7)
  }, [callsData, filter, flaggedOnly])

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (callsData.length - pageLimit))
    if (user.user_type_id < 4) {
      setPageLimit(pageLimit + (callsData.length - pageLimit))
    }
  }

  const handleCollapse = () => {
    if (user.user_type_id < 7) {
      setPageLimit(7)
    } else {
      setPageLimit(7)
    }
    if (backToTop) {
      backToTop.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const daysIn = useMemo(() => (start_date) => {
    const todaysDate = new Date()
    const startD = new Date(start_date)
    const difference = todaysDate.getTime() - startD.getTime()
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
    return TotalDays
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (parameters.id > 0) {
      setLoading(true)
      const fetchCallsData = async () => {
        const response = await IMIApi.GetCallsByCampaignAndStatus({
          ...parameters,
          only_flagged: flaggedOnly,
        }, abortController.signal)
        if (!response) {
          return
        }
        if (response.status && response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        if (filter && filter > 0) {
          const filterCallId = response.data.data.sort((a, b) => (a.occurred_at > b.occurred_at ? -1 : 1))
            .filter((categoryId) => categoryId.category?.id === filter)
          setCallsData(filterCallId)
          return setLoading(false)
        }
        setCallsData(response.data.data.sort((a, b) => (a.occurred_at > b.occurred_at ? -1 : 1)))
        pdf.setCallsData({ ...response.data, data: response.data.data.sort((a, b) => (a.occurred_at > b.occurred_at ? -1 : 1)) })
        return setLoading(false)
      }
      fetchCallsData()
    }
    return () => abortController.abort()
  }, [parameters, setError, flaggedOnly, filter, pdf, pdf.setCallsData])

  const callStatus = (status, flagged) => {
    if (flagged) {
      return 'Flagged'
    }
    if (status === 1) {
      return 'Pending'
    }
    if (status === 2 && !flagged) {
      return 'Processed'
    }
    if (status === 3 && !flagged) {
      return null
    }
    return 'Pending'
  }

  const callStatusColor = (status, flagged) => {
    if (status === 1 && !flagged) {
      return 'bg-yellow-default'
    }
    if (status === 2 && !flagged) {
      return 'bg-green-default'
    }
    if (status === 3 && !flagged) {
      return 'bg-transparent'
    }
    if (flagged) {
      return 'bg-red-default'
    }
    return 'bg-yellow-default'
  }

  const formatPhoneNumber = (pnumber) => {
    const phoneNumberString = pnumber?.toString()
    const cleaned = (` ${phoneNumberString}`).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = (match[1] ? '' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  const handleDownload = useCallback(() => {
    if (campaignName) {
      const fetchEnhancedCallAnalyticsDownload = async () => {
        const response = await IMIApi.GetEnhancedCallAnalyticsDownload({
          id: reportParameters.id,
          start_date: fixDateObjApi(reportParameters.query_start_date),
          end_date: fixDateObjApi(reportParameters.query_end_date),
        })
        // return response.config.url
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          return setError({ errorMsg: parse?.detail })
        }
        if (!response.status) {
          return setError({ errorMsg: 'Internal server error' })
        }

        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)

        const fileDesc = getFileDescriptor(response.headers)

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
          new Blob([response.data], { type: fileDesc.contentType }),
        )

        // Use download attribute to set set desired file name
        a.setAttribute('download', fileDesc.filename)

        // Trigger the download by simulating click
        a.click()

        // Cleanup
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
      }
      fetchEnhancedCallAnalyticsDownload()
    }
  }, [campaignName, setError, reportParameters.id, reportParameters.query_end_date, reportParameters.query_start_date])

  return (
    <Content
      title={flaggedOnly ? 'flagged calls from last 30 days' : 'enhanced call analytics'}
      padding={false}
      badge={(
        <>
          {flaggedOnly && (
            <BadgeAlert
              alert={callsData?.length}
            />
          )}
          {filter && (
            <BadgeAlert
              status="running"
              alert={callsData?.length}
            />
          )}
        </>
      )}
      icon={(
        (!flaggedOnly && filter === undefined) && (
          <Icon
            id="enhancedCallAnalyticsDownload"
            onClick={() => handleDownload()}
          >
            <Icon
              size="md"
              colors="text-primary"
              className="cursor-pointer group-hover:text-secondary mt-1.5"
            >
              <DownloadIcon
                className="text-primary hover:text-secondary"
              />
            </Icon>
          </Icon>
        )
      )}
    >
      {loading ? <div className="px-4 md:px-6 pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <div id="enhanced-calls">
          <HorizontalGrid
            smGrid="grid-cols-small"
            mdGrid="grid-cols-small"
            lgGrid={!flaggedOnly ? 'lg:grid-cols-callAnalytics' : 'lg:grid-cols-flaggedCallReport'}
          >
            {enhancedCallAnalyticsMenu.map((item) => (
              <div
                key={item.name}
                className={`break-all ${item.sort ? 'group' : ''} flex center-items pr-2 sm:pr-0 ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}
              >
                {item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={flaggedOnly && item.name.toLowerCase() === 'status' ? 'hidden' : 'block'}
                  >
                    <span className="inline md:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                    <span className="hidden md:inline">{item.name}</span>
                    <Icon
                      size="xxs"
                      className="text-default group sm:pr-0 ml-1 -mt-1.5"
                    >
                      <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                      <ChevronDownIcon className="text-default group-hover:text-secondary" />
                    </Icon>
                  </span>
                ) : (
                  <>
                    <span className="inline md:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                    <span className="hidden md:inline">{item.name}</span>
                  </>
                )}
                {item.info ? <ToolTip tipId={item.name} position="relative ml-0.5">{item.info}</ToolTip> : null}
              </div>
            ))}
          </HorizontalGrid>
          <div>
            {!callsData || callsData.length === 0 ? <p className="px-4 md:px-6">{noDataMessage}</p> : (
              callsData?.filter((metric, amount) => (amount < pageLimit))
                .map((metric) => (
                  <div key={metric.id} className="odd:bg-grey-lightest pt-6 pb-2">
                    <Accordion
                      variant="hideLg"
                      header={(
                        <HorizontalGrid
                          smGrid="grid-cols-small"
                          mdGrid="grid-cols-small"
                          lgGrid={!flaggedOnly ? 'lg:grid-cols-callAnalytics' : 'lg:grid-cols-flaggedCallReport'}
                          alignItems="items-start"
                        >
                          <p className={`text-xxs text-gray-500 ${styles.date}`}>
                            {fixDateTimeObj(metric.occurred_at)}
                            <br />
                            {fixHoursObj(metric.occurred_at)}
                          </p>
                          <p className={`text-xxs text-gray-500 ${styles.desktop}`}>
                            {metric.contact?.last_name ? (`${metric.contact.last_name}, `) : null}
                            {metric.contact?.first_name ? (`${metric.contact.first_name} \n `) : '—'}
                            <br />
                            {(metric.contact?.phone_work ? formatPhoneNumber(metric.contact.phone_work) : metric?.contact?.phone_home && formatPhoneNumber(metric.contact.phone_home)) || '—'}
                            <br />
                            {/* {metric.contact?.company ? (`${metric.contact.company} \n `) : ''}
                          {metric?.contact.address1 ? (`${metric.contact.address1} \n `) : ''} */}
                            {metric.contact?.city ? (`${metric.contact.city}, `) : ''}
                            {metric.contact?.state ? (`${metric.contact.state} `) : null}
                            {metric.contact?.postal}
                          </p>
                          <div className="text-xxs text-gray-500">
                            {callProcessing && metric.status_id !== 3 && (
                              daysIn(metric.occurred_at) < 31 ? (
                                <a target="_blank" rel="noreferrer" href={metric.recording_url} aria-label="Volume">
                                  <Icon className="hover:text-secondary">
                                    <VolumeUpIcon />
                                  </Icon>
                                </a>
                              ) : (
                                <Icon colors="grey" disabled>
                                  <VolumeUpIcon />
                                </Icon>
                              )
                            )}
                          </div>
                          <p className={`${styles.desktop} text-xxs`}>
                            {new Date(metric.duration * 1000).toISOString()
                              .substr(11, 8)}
                          </p>
                          <div>
                            <Badge
                              style={{ backgroundColor: matches ? metric.category?.color : 'transparent' }}
                              label={(
                                <span
                                  className="text-xxs text-default text-opacity-60 md:text-light font-base font-normal md:text-center"
                                >
                                  {metric?.category?.name}
                                </span>
)}
                              variant="badgeOnly"
                              size="textOnly"
                              badgeBackground="transparent"
                            />
                          </div>
                          <p className={`text-xxs text-gray-500 ${styles.desktop}`}>
                            {metric?.notes}
                          </p>
                          {!flaggedOnly && (
                            <>
                              <div className={styles.desktop}>
                                <Badge
                                  label={(
                                    <span
                                      className="text-light text-xxs font-base font-normal"
                                    >
                                      {callStatus(metric?.status_id, metric?.is_flagged)}
                                    </span>
)}
                                  variant="badgeOnly"
                                  size="sm"
                                  badgeBackground={callStatusColor(metric?.status_id, metric?.is_flagged)}
                                />
                              </div>
                              <div className={`text-xxs ${styles.mobile}`}>
                                <Badge
                                  label={<span className="hidden" />}
                                  variant="dot"
                                  size="sm"
                                  dotColor={callStatusColor(metric?.status_id, metric?.is_flagged)}
                                  badgeBackground="transparent"
                                />
                              </div>
                            </>
                          )}
                        </HorizontalGrid>
                      )}
                      body={(
                        <div>
                          <p className="text-xxs flex">
                            <span className={styles.mobile}>
                              Customer:
                              &nbsp;
                            </span>
                            <span>
                              {metric.contact?.last_name ? (`${metric.contact.last_name}, `) : null}
                              {metric.contact?.first_name ? (`${metric.contact.first_name}`) : '—'}
                              <br />
                              {metric?.contact?.phone_work !== null ? (
                                <div className="md:hidden">
                                  <a
                                    className="front-display global-animation text-xxs text-primary hover:text-secondary active:text-secondary"
                                    href={`tel:${metric?.contact?.phone_work}`}
                                  >
                                    {formatPhoneNumber(metric?.contact?.phone_work)}
                                  </a>
                                  <br />
                                </div>
                              ) : (
                                <a
                                  className="front-display global-animation text-xxs text-primary hover:text-secondary active:text-secondary"
                                  href={`tel:${metric?.contact?.phone_home}`}
                                >
                                  {formatPhoneNumber(metric?.contact?.phone_home)}
                                  <br />
                                </a>
                              )}
                              {/* {metric.contact?.company && (
                              <>
                                {metric.contact.company}
                                <br />
                              </>
                            )} */}
                              {/* {metric?.contact.address1 && (
                              <>
                                {metric.contact.address1}
                                <br />
                              </>
                            )} */}
                              {metric.contact?.city && (`${metric.contact.city}, `)}
                              {metric.contact?.state && (`${metric.contact.state} `)}
                              {metric.contact?.postal}
                            </span>
                          </p>
                          <p className="text-xxs text-gray-500">
                            <span className={styles.mobile}>
                              Notes:
                              &nbsp;
                            </span>
                            {metric?.notes}
                          </p>
                        </div>
                      )}
                    />
                  </div>
                ))
            )}
          </div>
          <div
            className={`${callsData && callsData.length > 0 && !flaggedOnly && !filter ? 'block' : 'hidden'} px-4 pt-3 md:px-6`}
          >
            <Pagination
              onClick={handleLoadMore}
              pageLimit={pageLimit}
              adminLimit={7}
              data={callsData}
              offset={10}
              onClickCollapse={handleCollapse}
            />
          </div>
        </div>
      )}
    </Content>
  )
}

export default EnhancedCallAnalyticsReport
