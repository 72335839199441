import {
  Fragment, 
  useState, 
  useEffect, 
  useRef, 
} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/solid'
import { handleToggle } from '../../helpers/utils'
import Icon from '../Icon'

// FIXME: Triangle svg, can't use css borders like you can to draw triangle

// NOTE: When using in parent, make sure you have a state with an object. Whatever
// you put inside the array of options, will default the checked value being passed.
// If you don't want all of them checked, you must update this state, as well as the
// defaultChecked prop to not include the id. This is because the checked value also
// corresponds with potential filters/other functions and the checked state is disconnected.
// See components/campaignCentral/CampaignCentral

interface Props {
  label: string
  position?: string,
  className?: string,
  list: any,
  handleFilters?: any,
  defaultCheck: number[],
  onClickSelect: (e) => void
  onClickDeselect: (e) => void
}

const DropdownCheckbox: React.FC<Props> = ({
  label,
  position,
  className,
  handleFilters,
  list,
  defaultCheck,
  onClickSelect,
  onClickDeselect,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [openT, setOpen] = useState(false)
  const [checkedArray, setCheckedArray] = useState<any []>(defaultCheck)
  const handleChange = (currentStatus) => (
    currentStatus === false ? setOpen(true) : setOpen(false)
  )

  const onChangeHandler = (checkboxId) => {
    const newState = handleToggle(checkboxId, checkedArray)
    setCheckedArray(newState)
    handleFilters((newState.map((id) => id)))
    // Update this checked information into Parent Component
    // handleFilters(newState.map((id) => list[id].value))
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <Listbox value={list} onChange={() => null}>
      <div ref={ref} className={`${className === 'special' ? '' : 'border p-2 rounded border-grey-light w-full'} relative inline-flex mb-6`}>
        <a onClick={() => handleChange(openT)} aria-hidden="true" className="inline-flex justify-between text-sm text-default font-bold relative w-full">
          <div className="heading-5 text-sm">
            {label}
          </div>
          <span className="relative">
            {openT ? <ChevronUpIcon className="h-5 w-5 text-default" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5 text-default" aria-hidden="true" />}
          </span>
        </a>
        <Transition
          show={openT}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="origin-bottom z-100 absolute w-full mt-8 right-0 px-3 py-2 rounded bg-grey-darkest bg-opacity-90 focus:outline-none">
            {/* select/deslect all */}
            <Listbox.Option
              className="font-bold pb-2 mb-3 text-xs py-1 border-b border-grey-dark relative text-left"
              // className={({ active }) => classNames(
              //   active ? 'text-white font-bold' : 'text-white font-normal', 'hover:font-bold pb-2 mb-3 text-xs py-1 hover:cursor-pointer border-b border-grey-dark relative text-left group hover:font-bold',
              // )}
              value={checkedArray?.length === list?.length ? 'Deselect All' : 'Select All'}
            >
              <div className="group-hover:font-bold" role="button" tabIndex={0} onKeyDown={checkedArray?.length === list?.length ? () => onClickDeselect(setCheckedArray([])) : () => onClickDeselect(setCheckedArray(defaultCheck))} onClick={checkedArray?.length === list?.length ? () => onClickDeselect(setCheckedArray([])) : () => onClickSelect(setCheckedArray(defaultCheck))}>
                <span className="font-base text-light group-hover:font-bold">{checkedArray?.length === list?.length ? 'Deselect All' : 'Select All'}</span>
                <Icon className="text-xs text-light group-hover:font-bold" size="xs" colors="light">
                  <ChevronRightIcon />
                </Icon>
              </div>
              {/* X icon */}
            </Listbox.Option>
            <Icon
              size="xs"
              colors="grey"
              className="absolute right-3 top-3"
              onClick={() => setOpen(false)}
            >
              <XIcon />
            </Icon>
            {list?.map((option) => (
              <Listbox.Option
                key={option.name}
                className="font-normal text-gray-700 py-1"
                // className={({ active }) => classNames(
                //   active ? 'text-white text-semibold' : 'text-white', 'text-xs py-1 hover:cursor-pointer',
                // )}
                value={option.name}
              >
                <span className="mx-1 py-1 text-light text-xs block truncate">
                  <input
                    id={`checkbox-${option.value}`}
                    name={option.name}
                    type="checkbox"
                    className="focus:ring-primary h-3 w-3 text-primary border-gray-300 rounded"
                    value={option.value}
                    onChange={() => onChangeHandler(option.id)}
                    checked={checkedArray.indexOf(option.id) !== -1}
                  />
                  <label
                    htmlFor={option?.short_name?.length > 0 ? option.short_name : option.name}
                    className={(
                      `ml-2 ${checkedArray.indexOf(option.id) !== -1 ? 'font-bold' : 'font-normal'} text-xs font-base`
                    )}
                  >
                    {option?.short_name?.length > 0 ? option.short_name : option.name}
                  </label>
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default DropdownCheckbox
