/* This example requires Tailwind CSS v2.0+ */
import React, {
  Fragment,
  useState,
  useEffect,
  forwardRef, useCallback, useMemo,
} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'
import { cls } from '../../helpers/utils'
import ToolTip from '../ToolTip'
import BadgeDot from '../badge/BadgeDot'

// FIXME: Triangle svg, can't use css borders like you can to draw triangle

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const styles = {
  base: 'relative font-display',
  disabled: 'bg-white cursor-not-allowed',
  variant: {
    default: 'inline-flex items-center',
    border: 'inline-flex items-center w-full justify-between',
    admin: 'w-full max-w-form border p-2 rounded border-grey-light inline-flex justify-between text-sm text-default font-bold relative',
  },
}

interface Props {
  label: string
  position?: string,
  className?: string,
  list?: any,
  handleFilters?: any,
  required?: boolean
  placeholder?: string
  variant: 'border' | 'default' | 'admin'
  error?: any
  hookFormField?: any // make more meaningful
  value?: any,
  disabled?: boolean
  toolTipInfo?: string | React.ReactNode
  campaign?: boolean
  disabledOptions: any[]
}

const DropdownAdmin: React.FC<Props> = ({
  label,
  position,
  className,
  list,
  handleFilters,
  required,
  placeholder,
  variant = 'default',
  error = null,
  hookFormField,
  value,
  disabled,
  toolTipInfo,
  campaign,
  disabledOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    id: list[0].id,
    name: list[0].name,
  })

  const onChangeHandler = useCallback((event) => {
    const updatedOption = list.find((obj) => obj.id === event)
    setSelectedOption(updatedOption)
    handleFilters?.(updatedOption.id)
  }, [handleFilters, list])

  useEffect(() => {
    if (placeholder) {
      return setSelectedOption({
        id: 0,
        name: placeholder,
      })
    }
    if (!value) {
      return setSelectedOption({
        id: list[0].id,
        name: placeholder || list[0].name,
      })
    }
  }, [placeholder, value, list])

  useEffect(() => {
    if (value) {
      return onChangeHandler(value)
    }
    return value
  }, [onChangeHandler, value])

  const disableMatching = () => {
    const disabledList = list?.filter((item) => (
      disabledOptions.includes(item.id)
    ))
    return disabledList.map((id) => id.id)
  }

  const admin = () => (
    <Listbox
      disabled={disabled}
      value={selectedOption?.name}
      onChange={(e) => {
        hookFormField?.onChange(e) // form hook
        onChangeHandler(e) // our stuff
      }}
    >
      {({ open }) => (
        <>
          <div className={`${variant === 'border' ? 'w-full mb-6 border p-2 rounded bg-grey-lightest border-grey-light inline-flex text-sm text-default font-bold relative' : 'w-full'} mb-6`}>
            <Listbox.Label className="heading-5 text-sm">
              {label}
              {required ? <span className="text-primary">*</span> : null}
              {/* {(variant === 'default' || 'border') && <span className="hidden md:inline">:&nbsp;</span>} */}
            </Listbox.Label>
            <div className="flex items-center">
              <Listbox.Button
                className={cls(`
                          ${styles.base}
                          ${disabled ? styles.disabled : 'bg-grey-lightest'}
                          ${styles.variant[variant]}
                          `)}
              >
                <span
                  className={placeholder === selectedOption?.name ? 'placeholder' : 'text-default font-semibold md:block'}
                >
                  <span className={disabled ? 'text-disabled' : ''}>
                    {placeholder !== selectedOption?.name ? (
                      <BadgeDot
                        variant="dot"
                        // dotColor={`bg-[${selectedOption.color}]`}
                        badgeBackground="dotOnly"
                        size="sm"
                      >
                        {selectedOption.name}
                      </BadgeDot>
                    ) : (
                      selectedOption.name
                    )}
                  </span>
                </span>
                <span className="relative">
                  {open ? <ChevronUpIcon className="h-5 w-5 text-default" aria-hidden="true" /> : <ChevronDownIcon className={`h-5 w-5 ${disabled ? 'text-disabled' : 'text-default'}`} aria-hidden="true" />}
                </span>
              </Listbox.Button>
              {toolTipInfo ? <ToolTip tipId={label} size="sm" position="relative ml-3">{toolTipInfo}</ToolTip> : <span className="pr-8" />}
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className={`${variant === 'admin' ? 'w-full mt-1 max-w-form' : 'min-w-150 mt-2'} font-base left-0 origin-bottom z-100 absolute right-0 px-3 py-2 rounded bg-white bg-opacity-100 focus:outline-none max-h-options overflow-y-scroll border border-grey-light col-count-3`}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="16" className="text-default fill-current absolute -inset-y-4">
                  <path className="fill-current text-opacity-90" d="M 120,5 250,170 5,160 z" />
                </svg> */}
                {list.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) => classNames(
                      active ? 'text-white text-semibold' : 'text-white', 'block text-xs py-1 hover:cursor-pointer',
                    )}
                    value={option.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            active ? 'font-semibold' : 'font-normal', 'mx-1 text-default text-xs block',
                            disableMatching().includes(option.id) ? 'text-disabled cursor-not-allowed' : '',
                          )}
                        >
                          <BadgeDot
                            variant="dot"
                            dotColor={`bg-[${option.color}]`}
                            badgeBackground="dotOnly"
                            size="sm"
                          >
                            {option.name}
                          </BadgeDot>
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {/* {error && <p className="form-error">This field is required</p>} */}
          </div>
        </>
      )}
    </Listbox>
  )
  const defaultBox = () => (
    <Listbox value={selectedOption?.name} onChange={(e) => onChangeHandler(e)}>
      {({ open }) => (
        <>
          <div className={`${variant === 'border' ? 'border p-2 rounded border-grey-light w-full mb-6' : null} relative inline-flex`}>
            <Listbox.Button
              className={cls(`
                        ${styles.base}
                        ${styles.variant[variant]}
                        `)}
            >
              <div>
                <Listbox.Label className="heading-5 text-sm font-semibold">
                  {label}
                  {required ? <span className="text-primary">*</span> : null}
                  {(variant === 'default' || variant === 'border') && <span className="hidden sm:inline">:&nbsp;</span>}
                </Listbox.Label>
                <span className="relative">
                  {(variant === 'default' || variant === 'border') ? <span className={`hidden sm:inline font-bold text-sm ${disabled ? 'text-opacity-60' : ''}`}>{selectedOption?.name}</span> : <span className={placeholder === selectedOption?.name ? 'placeholder' : 'text-default md:block'}>{selectedOption?.name}</span>}
                </span>
              </div>
              {open ? <ChevronUpIcon className="h-5 w-5 text-default" aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5 text-default" aria-hidden="true" />}
            </Listbox.Button>
            {toolTipInfo ? <ToolTip tipId={label} size="md" position="relative ml-3">{toolTipInfo}</ToolTip> : null}
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className="min-w-full mt-8 origin-bottom z-100 absolute right-0 px-3 py-2 rounded bg-grey-darkest bg-opacity-90 focus:outline-none font-base">
                {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="16" className="text-default fill-current absolute -inset-y-4">
                  <path className="fill-current text-opacity-90" d="M 120,5 250,170 5,160 z" />
                </svg> */}
                {list.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) => classNames(
                      active ? 'text-white text-semibold' : 'text-white', 'block text-xs py-1 hover:cursor-pointer',
                    )}
                    value={option.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selectedOption.name === option.name ? 'font-bold' : 'font-normal', 'mx-1 text-default text-xs block')}>
                          {option.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {error && <p className="form-error">This field is required</p>}
          </div>
        </>
      )}
    </Listbox>
  )
  return (
    variant === 'admin' ? admin() : defaultBox()
  )
}

export default DropdownAdmin
