export const campStatusData = [
  {
    id: 'all',
    name: 'All',
    label: 'All',
    value: 'all',
  },
  {
    id: 'paused',
    name: 'Paused',
    label: 'Paused',
    value: 'paused',
  },
  {
    id: 'running',
    name: 'Running',
    label: 'Running',
    value: 'running',
  },
]

export const campSortData = [
  {
    id: 0,
    name: 'Advertiser: A-Z',
    label: 'Advertiser: A-Z',
    value: 'advertiser: a-z',
  },
  {
    id: 1,
    name: 'Advertiser: Z-A',
    label: 'Advertiser: Z-A',
    value: 'advertiser: z-a',
  },
  {
    id: 2,
    name: 'Days Left: Lowest to Highest',
    label: 'Days Left: Lowest to Highest',
    value: 'days left: lowest to highest',
  },
  {
    id: 3,
    name: 'Days Left: Highest to Lowest',
    label: 'Days Left: Highest to Lowest',
    value: 'days left: highest to lowest',
  },
  {
    id: 4,
    name: 'Start Date: Newest to Oldest',
    label: 'Start Date: Newest to Oldest',
    value: 'start date: newest to oldest',
  },
  {
    id: 5,
    name: 'Start Date: Oldest to Newest',
    label: 'Start Date: Oldest to Newest',
    value: 'start date: oldest to newest',
  },
]

export const publicCampSortData = [
  {
    id: 0,
    name: 'Advertiser: A-Z',
    label: 'Advertiser: A-Z',
    value: 'advertiser: a-z',
  },
  {
    id: 1,
    name: 'Advertiser: Z-A',
    label: 'Advertiser: Z-A',
    value: 'advertiser: z-a',
  },
  {
    id: 4,
    name: 'Start Date: Newest to Oldest',
    label: 'Start Date: Newest to Oldest',
    value: 'start date: newest to oldest',
  },
  {
    id: 5,
    name: 'Start Date: Oldest to Newest',
    label: 'Start Date: Oldest to Newest',
    value: 'start date: oldest to newest',
  },
]
