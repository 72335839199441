import { 
  useContext, useState, useEffect, useCallback, useMemo,
} from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { commaNumber, fixDateObj } from '../../helpers/utils'
import BadgeDot from '../badge/BadgeDot'
import BadgeInfo from '../badge/BadgeInfo'
import Content from '../layouts/Content'
import ToolTip from '../ToolTip'
import { ISemDetail } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: ISemDetail
}

const SemClicksCallsWebEvents:React.FC<Props> = ({
  children,
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const [dailyActivity, setDailyActivity] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [chart, setChart] = useState<any>({})
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'

  const colorMap = useMemo(() => ({
    clicks: colors.primary,
    calls: colors.tertiary,
    'web events': colors.secondary,
  }), [colors])

  // eslint-enable
  const buildChart = useCallback((sd: any [], categories: any[]) => ({
    chart: {
      type: 'column',
      height: 300,
    },
    title: {
      text: '',
    },
    legend: {
      className: 'test',
      paddingBottome: 15,
      align: 'left',
      verticalAlign: 'top',
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter() {
        const { point } = this as any
        let clicks = 0
        let calls = 0
        let webEvents = 0
        const categoryIndex = categories.indexOf(point?.category)
        sd.forEach((c) => {
          if (c.name === 'Clicks') {
            clicks = c.data[categoryIndex]
          }
          if (c.name === 'Calls') {
            calls = c.data[categoryIndex]
          }
          if (c.name === 'Web Events') {
            webEvents = c.data[categoryIndex]
          }
        })

        const cte = clicks > 0 && webEvents > 0 ? (webEvents / clicks) * 100 : 0
        const ctc = clicks > 0 && calls > 0 ? (calls / clicks) * 100 : 0
        return (
          `<span style="color: #fff; font-family: Nunito Sans; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point?.category}</span>
           <span style="background-color: ${colorMap.clicks}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">Clicks: <b>${clicks}</b></span><br>
           <span style="background-color: ${colorMap.calls}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">Calls: <b>${calls}</b></span><br>
           <span style="background-color: ${colorMap['web events']}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">Web Events: <b>${webEvents}</b></span><br>
           <span style="background-color: #a7b1ae; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 10px 5px;">CTC: <b>${parseFloat(ctc.toFixed(2))}%</b><span><br>
           <span style="background-color: #a7b1ae; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 10px 5px;">CTE: <b>${parseFloat(cte.toFixed(2))}%</b><span><br>`
        )
      },
    },
    xAxis: {
      useHtml: true,
      lineColor: '#dfe5e2',
      categories,
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
    },
    yAxis: {
      min: 0,
      lineColor: '#dfe5e2',
      title: {
        text: '',
      },
      stackLabels: {
        enabled: false,
        labels: {
          style: {
            fontFamily: 'Heebo',
            color: 'rgba(40, 42, 57, 0.6)',
          },
        },
      },
    },
    series: sd,
    plotOptions: {
      series: {
        maxPointWidth: 10,
        // minPointLength: 5,
        states: {
          hover: {
            brightness: 0,
            opacity: 1,
          },
          inactive: {
            opacity: 0.7,
          },
        },
      },
      column: {
        allowOverlap: false,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
  }), [colorMap])

  useEffect(() => {
    setDailyActivity(data)
    setLoading(false)
  }, [data])

  useEffect(() => {
    if (dailyActivity?.clicks_calls_events_graph?.series) {
      const seriesData = dailyActivity?.clicks_calls_events_graph?.series
      const chartOpts: any[] = []
      seriesData.forEach((s: { name: string; data: any[] }) => {
        if (s?.name === 'Clicks' || s?.name === 'Calls' || s?.name === 'Web Events') {
          chartOpts.push({
            name: s?.name,
            data: s?.data?.sort((a, b) => (a.x > b.x ? 1 : -1)).map((d: { y: any }) => d.y),
            color: colorMap[s?.name.toLowerCase()],
            ctc: 'calls/clicks * 100 %',
            cte: 'web visits/clicks * 100 %',
            categories: s?.data?.sort((a, b) => (a.x > b.x ? 1 : -1)).map((d: { x: any }) => d.x),
          })
        }
      })
      setChart(buildChart(chartOpts, chartOpts?.[0]?.categories?.map((date: any) => fixDateObj(date))))
    }
  }, [colorMap, buildChart, dailyActivity?.clicks_calls_events_graph?.series])

  return (
    <Content
      title="clicks + calls + web events"
      icon={(
        <ToolTip
          tipId="imprclicksweb"
          size="sm"
          position="hidden md:block relative pr-0"
        >
          Web events include website page visits and submitted page forms.
        </ToolTip>
      )}
    >
      {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <>
          {dailyActivity?.clicks_calls_events_graph?.series?.length > 0 ? (
            <>
              <div className="pb-6">
                <BadgeDot variant="dot" dotColor="bg-primary" label="Clicks" />
                <BadgeDot variant="dot" dotColor="bg-tertiary" label="Calls" />
                <BadgeDot variant="dot" dotColor="bg-secondary" label="Web Events" />
              </div>
              <div id="calls_clicks_web_events">
                <HighchartsReact
                  id="calls_clicks_web_events"
                  highcharts={Highcharts}
                  options={chart}
                />
              </div>
              <BadgeInfo
                label="Total Clicks"
                calculation={dailyActivity?.clicks?.value ? commaNumber(dailyActivity?.clicks?.value) : 0}
                toolTipId="totalClicks"
              />
              <BadgeInfo
                label="Total Calls"
                calculation={dailyActivity?.clicks_calls_events_graph?.callouts[1]?.value ? commaNumber(dailyActivity?.clicks_calls_events_graph?.callouts[1]?.value) : 0}
                toolTipId="totalCalls"
              />
              <BadgeInfo
                label="Total Web Events"
                calculation={dailyActivity?.clicks_calls_events_graph?.callouts[2]?.value ? commaNumber(dailyActivity?.clicks_calls_events_graph?.callouts[2]?.value) : 0}
                toolTipId="totalWebEvents"
              />
              <BadgeInfo
                label="Average Click-To-Call Rate (CTC)"
                calculation={`${(dailyActivity?.clicks_calls_events_graph?.callouts[1]?.value && dailyActivity?.clicks?.value) ? ((dailyActivity?.clicks_calls_events_graph?.callouts[1]?.value / dailyActivity?.clicks?.value) * 100).toFixed(2) : 0}%`}
                toolTipId="averageCtcr"
                toolTipInfo="Click-to-call (CTC) is the percent of times a call is generated after a visit to the website from the campaign."
              />
              <BadgeInfo
                label="Average Click-To-Web-Event Rate (CTE)"
                // clicks/web events x 100%
                calculation={`${(dailyActivity?.clicks_calls_events_graph?.callouts[2]?.value && dailyActivity?.clicks?.value) ? ((dailyActivity?.clicks_calls_events_graph?.callouts[2]?.value / dailyActivity?.clicks?.value) * 100).toFixed(2) : 0}%`}
                toolTipId="averageCtwe"
                toolTipInfo="Click-to-web-event (CTE) is the percent of web events generated after a visit to the website from the campaign."
              />
            </>
          ) : (
            <p>{noDataMessage}</p>
          )}
        </>
      )}
    </Content>
  )
}

export default SemClicksCallsWebEvents
