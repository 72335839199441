import { PDFDocument } from 'pdf-lib'
import { Colors } from './colors'
import { Drawing } from './drawing'
import { Pages } from './pages'

const getImage = async (url: string, pdfDoc: PDFDocument) => {
  const address = new URL(url)
  const bytes = await fetch(address.pathname).then((res) => res.arrayBuffer())
  const image = await pdfDoc.embedPng(bytes)
  return image
}

export type Images = {
  draw: (height: number) => void
}

export const getImages = async (urls: string[], pages: Pages, drawing: Drawing, colors: Colors, pdfDoc: PDFDocument) => {
  const images = await Promise.all(urls.map((url) => getImage(url, pdfDoc)))

  return {
    draw: (height: number) => {
      let distance = 0
      pages.moveDown(height)
      images.forEach((image, i) => {
        const dims = image.scale(height / image.height)

        if (i !== 0) {
          pages.moveRight(10)
          drawing.drawLine(0, -height, colors.darkGrey)
          pages.moveRight(10)
          distance += 20
        }

        pages.currentPage.drawImage(image, {
          x: pages.currentX,
          y: pages.currentY,
          width: dims.width,
          height: dims.height,
        })
        pages.moveRight(dims.width)
        distance += dims.width
      })
      pages.moveUp(height)
      pages.moveLeft(distance)
    },
  }
}
