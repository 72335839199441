// styles
export const campaignSettingsStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

export const campaignUpliftMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'type.short_name',
    name: 'Type',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'uplift_base',
    name: 'Uplift',
    mobile: false,
    sort: false,
  },
  {
    fieldName: 'tbd',
    name: 'Budget',
    mobile: false,
    sort: false,
  },
]

export const campaignUpliftCycleDetailsMenu = [
  {
    fieldName: 'start_date',
    name: 'Start Date',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'cycle_uplift',
    name: 'Cycle Uplift',
    mobile: false,
    sort: false,
  },
  {
    fieldName: 'source',
    name: 'Source',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'uplifted',
    name: 'Uplifted',
    mobile: false,
    sort: true,
  },
  // {
  //   fieldName: 'budget',
  //   name: 'Budget',
  //   mobile: false,
  //   sort: true,
  // },
]

export const upliftStatus = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]

export const editUpliftCycleDetails = [
  {
    fieldName: 'start_date',
    name: 'Start Date',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'uplift',
    name: 'Cycle Uplift',
    mobile: false,
    sort: false,
  },
  // TODO: change 'budget' field to source in API response
  {
    fieldName: 'budget',
    name: 'Source',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'uplifted',
    name: 'Uplifted',
    mobile: false,
    sort: false,
  },
]
