import {
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import Content from '../../layouts/Content'
import {
  apiSettingsMenu,
  apiSettingsStyles,
  apiSettingsStatus,
} from './Data'
import { getFieldByPath } from '../../../helpers/utils'
import HorizontalGrid from '../../layouts/HorizontalGrid'
import EditMenu from '../EditMenu'
import Icon from '../../Icon'
import LinkWrapper from '../../Links'
import { IPlatformCredListItem } from '../../../api/imi/interfaces'
import LoadingSpinner from '../../LoadingSpinner'
import Dropdown from '../../form/Dropdown'

interface Props {
  platformsCreds: IPlatformCredListItem[]
  setPagination: Dispatch<SetStateAction<any>>
  filterByActive: (isActive: boolean) => void
  isLoading: boolean
  totalData: number
}

const ApiSettingsList:React.FC<Props> = ({
  platformsCreds,
  filterByActive,
  setPagination,
  isLoading,
  totalData,
}) => {
  const [errorMsg, setErrorMsg] = useState('There are no api settings found.')
  const [btnText, setBtnText] = useState('Change')
  const [cancelText, setCancelText] = useState('Cancel')
  const [filtered, setFiltered] = useState(platformsCreds)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState<string>('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? platformsCreds?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1)) : platformsCreds?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1))

    return { values }
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('platform_creds_id', JSON.stringify(id))
  }

  const statusToColor = {
    okay: 'secondary',
    pending: 'yellow',
    error: 'red',
  }

  return (
    <>
      <Content
        title="API Settings"
        padding={false}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={apiSettingsStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => filterByActive(e)
            )}
          />
        )}
      >
        {isLoading ? (
          <span className="mx-4 md:mx-6 block"><LoadingSpinner variant="primary" size="lg" /></span>
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-3"
              mdGrid="md:grid-cols-3"
            >
              {apiSettingsMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && apiSettingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {platformsCreds?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                platformsCreds?.map((cred) => (
                  <HorizontalGrid
                    key={cred.id}
                    smGrid="grid-cols-3"
                    mdGrid="md:grid-cols-3"
                    backgroundColor
                  >
                    <p className="text-xxs text-gray-500 pr-3">
                      <LinkWrapper
                        variant="defaultSm"
                        key={cred.id}
                        onClick={() => handleLocalStorage(cred.id)}
                        href={{
                          pathname: '/admin-tools/api-settings/edit-api-settings',
                        }}
                      >
                        {cred.advertisers}
                      </LinkWrapper>
                    </p>
                    <p className="text-xxs text-gray-500 pr-3">
                      {cred.platform}
                    </p>
                    <p className={`text-xxs text-${statusToColor[cred.status]} pr-3 uppercase`}>
                      {cred.status === 'okay' ? 'OK' : cred.status}
                    </p>
                    <EditMenu
                      text="Edit API Settings"
                      id={cred.id}
                      pathname="/admin-tools/api-settings/edit-api-settings"
                      onClick={() => handleLocalStorage(cred.id)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
            {/* <Pagination onClick={handleLoadMore} onClickBack={handleReturn} pageLimit={10} adminLimit={10} offset={currentOffset} total={totalData} data={filtered} /> */}
          </>
        )}
      </Content>
    </>
  )
}

export default ApiSettingsList
