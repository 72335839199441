import React, {
  useState, useContext, useEffect, useCallback, useRef, useMemo,
} from 'react'
import Modal from './modal'
import { AppContext } from '../contexts/AppContext'

interface Props {}

let timer: any
let countdownInterval: any

const SessionMonitor:React.FC<Props> = () => {
  // showAlertWhen is the number of seconds before session expires that the alert should be shown.
  const showAlertWhen = 1200

  const {
    user,
    sessionExpires,
    isSignedIn,
    signOutUserClicked,
    signOutSessionExpired,
    signInRefreshToken,
  } = useContext(AppContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [countdownSeconds, setCountdownSeconds] = useState<number>(showAlertWhen)
  const [sessionTimerStarted, setSessionTimerStarted] = useState<boolean>(false)

  const getTTESeconds = useCallback(() => Math.round((sessionExpires.getTime() - (new Date()).getTime()) / 1000), [sessionExpires])

  const startCountdownTimer = useCallback((startFrom: number = showAlertWhen) => {
    if (countdownInterval) {
      clearInterval(countdownInterval) // prevent multiple timers from running
    }

    setCountdownSeconds(startFrom)
    countdownInterval = setInterval(() => {
      setShowModal(true)
      setCountdownSeconds((prevState) => prevState - 1)
    }, 1000)

    timer = undefined

    // console.log('countdown timer started')
  }, [])

  const startSessionTimer = useCallback(() => {
    if (sessionExpires.getTime() === 0) {
      // console.log('bailed early')
      return
    }
    const tteSeconds = getTTESeconds()
    // console.log('tteSeconds', tteSeconds)
    if (tteSeconds <= showAlertWhen) {
      // console.log('failed because of tte seconds')
      return
    }
    const tteMS = (tteSeconds - showAlertWhen) * 1000

    if (timer) {
      clearTimeout(timer) // prevent multiple timers from running
    }

    timer = setTimeout(() => {
      clearTimeout(timer)
      startCountdownTimer()
    }, tteMS)

    setSessionTimerStarted(true)
    // console.log('session timer started', tteMS)
  }, [getTTESeconds, sessionExpires, startCountdownTimer])

  const handleSessionEvent = useCallback((extend: boolean) => {
    setShowModal(false)
    setSessionTimerStarted(false)

    clearInterval(countdownInterval)
    countdownInterval = undefined
    setCountdownSeconds(showAlertWhen) // reset

    if (extend) {
      startSessionTimer()
    } else {
      // console.log('session monitor logged you out')
      signOutSessionExpired()
    }
  }, [signOutSessionExpired, startSessionTimer])

  useEffect(() => {
    if (isSignedIn() && !sessionTimerStarted) {
      const tteSeconds = getTTESeconds()
      if (tteSeconds === 0) {
        // console.log('log off', tteSeconds)
        signOutSessionExpired()
      } else if (tteSeconds < showAlertWhen) {
        startCountdownTimer(tteSeconds)
      } else {
        startSessionTimer()
      }
    }
  }, [getTTESeconds, isSignedIn, sessionTimerStarted, signOutSessionExpired, startCountdownTimer, startSessionTimer])

  useEffect(() => {
    if (countdownSeconds === 0) {
      handleSessionEvent(false)
    }
  }, [countdownSeconds, handleSessionEvent, signOutSessionExpired])

  useEffect(() => () => {
    // clean up
    // console.log('unmount - cleanup')
    timer = undefined
    countdownInterval = undefined
  }, [])

  const formatSStoMMSS = (s: number | string) => {
    let S = +s
    if (+s <= 0) {
      S = 1
    }
    const MM = Math.floor(S / 60)
    const SS = S % 60
    return `${MM.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:${SS.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`
  }

  const onSubmitHandler = async (e: any) => {
    e.preventDefault()
    await signInRefreshToken()
    handleSessionEvent(isSignedIn())
  }

  return (
    <div>
      <Modal
        title="It's too soon to say goodbye."
        body={
          `If you're still here, let us know!  Otherwise, you'll be automatically signed out in ${
            formatSStoMMSS(countdownSeconds)
          } minutes.`
        }
        show={showModal}
        altButtonText="Sign Out"
        onAltButton={signOutUserClicked}
        submitText="I'm still here"
        onClose={onSubmitHandler}
        onSubmit={onSubmitHandler}
      />
    </div>
  )
}

export default SessionMonitor
