import {
  useContext,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../contexts/AppContext'
import { handleFilters, getFieldByPath } from '../../../helpers/utils'
import Search from '../../form/Search'
import Content from '../../layouts/Content'
import HorizontalGrid from '../../layouts/HorizontalGrid'
import AdminToolsMobileLink from '../AdminToolsMobileLink'
import Modal from '../../modal'
import Icon from '../../Icon'
import { callStatus, callStatusMenu, settingsStyles } from './Data'
import BadgeAlert from '../../badge/BadgeAlert'
import Dropdown from '../../form/Dropdown'
import { IMIApi } from '../../../api/imi/api'
import EditMenu from '../EditMenu'
import { ICampaignInDb, ICampaignInDbNameCombo, ICampaignMultiResponse } from '../../../api/imi/interfaces'
import LoadingSpinner from '../../LoadingSpinner'
import { campaignSettingsStatus } from '../campaigns/campaign_settings/Data'
import LinkWrapper from '../../Links'

interface Props {
  assignedCampaigns: ICampaignInDbNameCombo[]
  refresh: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
}

const CallSettings:React.FC<Props> = ({
  assignedCampaigns,
  refresh,
  isLoading,
}) => {
  const { setError } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState('There are no campaigns found.')
  const [btnText, setBtnText] = useState('Change')
  const [cancelText, setCancelText] = useState('Cancel')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [currentCampaign, setCurrentCampaign] = useState<ICampaignInDb>()
  const [filteredCampaigns, setFilteredCampaigns] = useState<ICampaignInDbNameCombo[]>([] as any)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
    status: 0,
  })
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  useEffect(() => {
    setFilteredCampaigns(assignedCampaigns)
  }, [assignedCampaigns])

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    const values = !sortDirection ? filteredCampaigns?.sort((a, b) => ((getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName)) ? 1 : -1)) : filteredCampaigns?.sort((a, b) => ((getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName)) ? -1 : 1))
    if (fieldName === 'category.name') {
      if (!sortDirection) {
        return values.sort((a, b) => (a.call_processing_is_active > b.call_processing_is_active ? -1 : 1))
      }
      return values.sort((a, b) => (a.call_processing_is_active < b.call_processing_is_active ? -1 : 1))
    }
    return { values }
  }

  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const campaignStatus = status.is_active
      if (campaignStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }
    const filterSearchValue = (callSettingsData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = callSettingsData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => callSettingsData.name.toLowerCase().includes(si))
        return nameFil
      }
      return nameFilter
    }

    const filterData = assignedCampaigns?.filter((cycle) => filterSearchValue(cycle) && filterStatus(cycle))
    setFilteredCampaigns(filterData)
  }, [selectedFilters, assignedCampaigns])

  useEffect(() => {
    filters()
  }, [assignedCampaigns, filters])

  const changeStatus = async (callProcessStatus, data) => {
    const formData = {
      ...data,
      advertiser_id: data.advertiser.id,
      category_id: data.category.id,
      specific_id: data.specific?.id || null,
      type_id: data.type.id,
      call_processing_is_active: !callProcessStatus,
    }
    setIsButtonLoading(true)
    setCancelText('')
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateCampaign(formData)
    setTimeout(() => {
      if (submitResponse.status === 200) {
        setIsButtonLoading(false)
        refresh(true)
        return setBtnText('Saved!')
      }
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      return setBtnText('Save')
    }, 1000)
    setTimeout(() => {
      setShowModal(false)
    }, 2000)
    refresh(false)
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Change"
        body={(
          `Change campaign ${currentCampaign?.name} ${currentCampaign?.secondary_name ? `: ${currentCampaign?.secondary_name}` : ''} ${currentCampaign?.specific?.name ? `- ${currentCampaign?.specific.name}` : ''} | ${currentCampaign?.type?.short_name} call processing status to
            ${currentCampaign?.call_processing_is_active ? 'inactive' : 'active'}?`
        )}
        onSubmit={() => changeStatus(currentCampaign?.call_processing_is_active, currentCampaign)}
        onClose={() => setShowModal(false)}
        closeText={cancelText}
        submitText={btnText}
      />
      <AdminToolsMobileLink />
      <Search
        label="search"
        hideLabel
        placeholder="search..."
        className="w-full max-w-none"
        value={selectedFilters.search}
        onChange={(
          (e) => setSelectedFilters(handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters))
        )}
      />
      <Content
        title={(
          selectedFilters.status === 0 ? 'active sem campaigns' : 'inactive sem campaigns'
        )}
        padding={false}
        badge={(
          <BadgeAlert
            alert={filteredCampaigns?.length || 0}
            status={selectedFilters.status === 0}
          />
        )}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={campaignSettingsStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-2"
              mdGrid="md:grid-cols-3"
            > 
              {callStatusMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && settingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredCampaigns?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filteredCampaigns?.map((campaign) => (
                  <HorizontalGrid
                    key={campaign.id}
                    smGrid="grid-cols-2"
                    mdGrid="md:grid-cols-3"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      <LinkWrapper
                        variant="defaultSm"
                        key={campaign.id}
                        onClick={() => handleLocalStorage(campaign.id)}
                        href={{
                          pathname: '/admin-tools/call-settings/edit-call-settings',
                        }}
                      >
                        {campaign.name}
                        {campaign.secondary_name && (
                          `: ${campaign.secondary_name}`
                        )}
                        {campaign.specific && (
                          `- ${campaign.specific.name}`
                        )}
                      </LinkWrapper>
                    </p>
                    <p className="text-xxs pr-3">
                      {campaign.call_processing_is_active ? <>Yes</> : <>No</> }
                    </p>
                    <p className="text-xxs pr-3">
                      {campaign.category?.name ? (
                        <>
                          {campaign.call_processing_is_active ? campaign.category.name : (<span>-</span>)}
                        </>
                      ) : <>-</> }
                    </p>
                    <EditMenu
                      text="Edit Call Settings"
                      id={campaign.id}
                      pathname="/admin-tools/call-settings/edit-call-settings"
                      onClick={() => handleLocalStorage(campaign.id)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default CallSettings
