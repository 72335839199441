import {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { ReactComponent as SemIcon } from '../../assets/icons/campaign-types/search-engine-marketing-icon.svg'
import { ReactComponent as DisplayIcon } from '../../assets/icons/campaign-types/display-advertising-icon.svg'
import { ReactComponent as VideoIcon } from '../../assets/icons/campaign-types/video-icon.svg'
import {
  commaNumber,
} from '../../helpers/utils'
import { ICampaignOverview } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import LinkWrapper from '../Links'
import Accordion from '../layouts/Accordion'
import Button from '../button/Button'
import ListingPageGridFlex from '../layouts/ListingPageGridFlex'
import { ReportContext } from '../../contexts/ReportContext'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  advertiserCampaigns: ICampaignOverview
  isLoading: boolean
}

const ActiveCampaignOverview:React.FC<Props> = ({
  advertiserCampaigns,
  isLoading,
}) => {
  const { colors } = useContext(ThemeContext)
  const [filteredAdvertiserCampaigns, setFilteredAdvertiserCampaigns] = useState<any>(advertiserCampaigns)
  const { setParamsNavigateNewest } = useContext(ReportContext)
  useEffect(() => {
    setFilteredAdvertiserCampaigns(advertiserCampaigns)
    // if (!advertiserCampaigns?.advertiser?.is_active) {
    //   return setSelectedFilters({ status: 1 })
    // }
    // setSelectedFilters({ status: 0 })
  }, [advertiserCampaigns])

  // TODO: move to util file once all charts are done
  const getDefaultChartOptions = useCallback(() => ({
    chart: {
      height: 65,
    },
    title: { text: '' },
    yAxis: {
      visible: false,
      labels: { enabled: false },
    },
    xAxis: {
      visible: false,
      labels: { enabled: false },
      // allowDecimals: false,
      offset: 2,
    },
    legend: {
      visible: false,
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    series: [{
      type: 'spline',
      crisp: true,
      clip: false,
      data: {},
      color: colors.primary,
    }],
    plotOptions: {
      series: {
        marker: false,
        lineWidth: 3,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  }), [colors])

  const buildChartOptionsForSeries = useCallback((series: any) => {
    if (!series) return [{}]
    const seriesData = series.data?.map((sd) => sd.y)
    const chartOptions = { ...getDefaultChartOptions() }
    chartOptions.series = [{
      // label: false,
      // data: seriesData,
      type: 'spline',
      crisp: true,
      clip: false,
      // hack to draw straight line if only one value is provided.
      data: seriesData.length === 1 ? [...seriesData, seriesData] : seriesData,
      color: colors.primary,
    }]
    return chartOptions
  }, [colors, getDefaultChartOptions])

  const determinePage = (adType) => {
    if (adType === 1) {
      return '/display-advertising'
    }
    if (adType === 2) {
      return '/search-engine-marketing'
    }
    if (adType === 3) {
      return '/video-advertising'
    }
    return null
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <>
          {/* Desktop */}
          {!filteredAdvertiserCampaigns?.length || !filteredAdvertiserCampaigns ? (
            <p className="pb-6">No active campaigns found, or your campaign might be in the process of renewing.</p>
          ) : (
            filteredAdvertiserCampaigns?.map((campaign) => (
              <div key={campaign.id}>
                <LinkWrapper
                  variant="default"
                  className="bg-grey-lightest overflow-hidden rounded border border-grey-light mb-4 hidden md:block group"
                  onClick={(
                    () => setParamsNavigateNewest(campaign.id)
                  )}
                  href={{
                    pathname: determinePage(campaign.type.id),
                  }}
                >
                  <ListingPageGridFlex>
                    <div className="block md:w-44 px-3 py-6 md:flex items-center relative justify-start">
                      <div className="inline-flex mb-4 md:flex md:mb-0 items-center justify-start pr-3">
                        {campaign.type.id === 1 && (
                          <DisplayIcon className="max-w-icon w-20" />
                        )}
                        {campaign.type.id === 2 && (
                          <SemIcon className="max-w-icon w-20" />
                        )}
                        {campaign.type.id === 3 && (
                          <VideoIcon className="max-w-icon w-20" />
                        )}
                      </div>
                      <div>
                        {campaign.type.short_name}
                        <p className="text-xxs mb-0">
                          {campaign.secondary_name}
                          {campaign.specific?.name && (
                            <>
                              &nbsp;
                              -
                              &nbsp;
                              {campaign.specific?.name}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className={`bg-white hidden sm:overflow-x-auto md:break-normal py-3 ${campaign.metrics.impressions.series?.length > 0 && campaign.type.id === 2 ? 'flex-2' : 'flex-1'} ${campaign.type.id === 1 && 'flex-3'} ${campaign.type.id === 3 && 'flex-3'} md:flex-row md:flex items-center justify-start border-l border-grey-light`}>
                      <div className="py-3 pl-6 pr-0 h-full flex flex-row items-center">
                        <div className="text-lg">
                          {commaNumber(campaign.metrics.impressions_total)}
                          <div className="small">impressions</div>
                        </div>
                      </div>
                      {campaign.metrics.impressions?.series.length > 0 ? (
                        <div className="p-3 flex flex-wrap flex-row flex-grow items-stretch overflow-hidden">
                          <div className="flex-grow overflow-hidden">
                            <HighchartsReact
                              id="impressions_graph"
                              highcharts={Highcharts}
                              options={buildChartOptionsForSeries(campaign.metrics.impressions.series?.find((s) => s.name === 'Impressions'))}
                            />
                          </div>
                        </div>
                      ) : (
                        null
                      )}
                    </div>
                    {campaign.type.id === 2 && (
                      <div className={`bg-white hidden sm:overflow-x-auto md:break-normal py-3 ${campaign.metrics.clicks?.series.length > 0 ? 'flex-2' : 'flex-1'} md:flex-row md:flex items-center justify-start`}>
                        <div className="py-3 pl-6 pr-0 h-full flex flex-row items-center border-l border-grey-light">
                          <div className="text-lg">
                            {commaNumber(campaign.metrics.clicks_total)}
                            <div className="small">clicks</div>
                          </div>
                        </div>
                        {campaign.metrics.clicks?.series.length > 0 ? (
                          <div className="p-3 flex flex-wrap flex-row flex-grow items-stretch overflow-hidden">
                            <div className="flex-grow overflow-hidden">
                              <HighchartsReact
                                id="clicks_graph"
                                highcharts={Highcharts}
                                options={buildChartOptionsForSeries(campaign.metrics.clicks.series?.find((s) => s.name === 'Clicks'))}
                              />
                            </div>
                          </div>
                        ) : (
                          null
                        )}
                      </div>
                    )}
                    <div className="bg-white hidden sm:overflow-x-auto md:break-normal py-3 flex-1.5 md:flex-row md:flex items-center justify-start">
                      <div className="p-3 h-full flex border-l border-grey-light  w-full justify-center">
                        <div className="py-3 px-2 h-full font-display font-semibold text-lg">
                          {campaign.type.id === 1 && (
                            <>
                              {commaNumber(campaign.metrics.activities)}
                              <div className="small">activities</div>
                            </>
                          )}
                          {campaign.type.id === 2 && (
                            <>
                              {commaNumber(campaign.metrics.conversions.value)}
                              <div className="small">conversions</div>
                            </>
                          )}
                          {campaign.type.id === 3 && (
                            <>
                              {`${commaNumber(campaign.metrics.completion_rate.toFixed(2))}%`}
                              <div className="small">completion rate</div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bg-white hidden sm:overflow-x-auto md:break-normal py-3 flex-1.5 md:flex-row md:flex items-center justify-start">
                      <div className="p-3 h-full flex border-l border-grey-light w-full justify-center">
                        <div className="py-3 px-2 h-full font-display font-semibold text-lg">
                          {campaign.type.id === 1 && (
                            <>
                              {`$${commaNumber(campaign.metrics.cpa.toFixed(2))}`}
                              <div className="small">CPA</div>
                            </>
                          )}
                          {campaign.type.id === 2 && (
                            <>
                              {commaNumber(campaign.metrics.leads.value)}
                              <div className="small">leads</div>
                            </>
                          )}
                          {campaign.type.id === 3 && (
                            <>
                              {`$${commaNumber(campaign.metrics.cpcv.toFixed(2))}`}
                              <div className="small">CPCV</div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bg-white hidden sm:overflow-x-auto md:break-normal p-3 sm:inline-flex items-center justify-around border-grey-light">
                      <Button
                        variant="primary"
                        id="see-details"
                        size="xs"
                        submit="button"
                        className="mb-0 h-10"
                      >
                        <ChevronRightIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  </ListingPageGridFlex>
                </LinkWrapper>
                {/* Mobile */}
                <div className="bg-grey-lightest overflow-hidden rounded border border-grey-light mb-4 block md:hidden">
                  <Accordion
                    position="top-10"
                    fullLine
                    header={(
                      <ListingPageGridFlex>
                        <div className="md:w-36 pl-3 pr-14 py-6 flex items-center relative">
                          <div className="inline-flex mb-4 md:flex md:mb-0 items-center justify-start pr-3">
                            {campaign.type.id === 1 && (
                              <DisplayIcon className="max-w-icon w-20" />
                            )}
                            {campaign.type.id === 2 && (
                              <SemIcon className="max-w-icon w-20" />
                            )}
                            {campaign.type.id === 3 && (
                              <VideoIcon className="max-w-icon w-20" />
                            )}
                          </div>
                          <div>
                            {campaign.type.short_name}
                            <p className="text-xxs mb-0">
                              {campaign.secondary_name}
                              {campaign.specific?.name && (
                                <>
                                  &nbsp;
                                  -
                                  &nbsp;
                                  {campaign.specific?.name}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </ListingPageGridFlex>
                    )}
                    bodyColor="bg-white"
                    body={(
                      <>
                        <div className="border-b border-grey-light mx-3 py-4">
                          <div className="flex justify-start sm:pr-0 items-center date text-default text-opacity-60">
                            <span className="date w-1/2">
                              impressions
                            </span>
                            <p className="heading-5">
                              {commaNumber(campaign.metrics.impressions_total)}
                            </p>
                          </div>
                          {campaign.metrics.impressions?.series.length > 0 ? (
                            <HighchartsReact
                              id="impressions_graph_mobile"
                              highcharts={Highcharts}
                              options={buildChartOptionsForSeries(campaign.metrics.impressions.series?.find((s) => s.name === 'Impressions'))}
                            />
                          ) : (
                            null
                          )}
                        </div>
                        {campaign.type.id === 2 && (
                          <div className="border-b border-grey-light mx-3 py-4">
                            <div className="flex justify-start sm:pr-0 items-center date text-default text-opacity-60">
                              <>
                                <span className="date w-1/2">
                                  clicks
                                </span>
                                <p className="heading-5">
                                  {commaNumber(campaign.metrics.clicks_total)}
                                </p>
                              </>
                            </div>
                            {campaign.metrics.clicks?.series.length > 0 && campaign.type.id === 2 ? (
                              <HighchartsReact
                                id="clicks_graph_mobile"
                                highcharts={Highcharts}
                                options={buildChartOptionsForSeries(campaign.metrics.clicks.series?.find((s) => s.name === 'Clicks'))}
                              />
                            ) : (
                              null
                            )}
                          </div>
                        )}
                        <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          {campaign.type.id === 1 && (
                            <>
                              <span className="date w-1/2">
                                activities
                              </span>
                              <p className="heading-5">
                                {commaNumber(campaign.metrics.activities)}
                              </p>
                            </>
                          )}
                          {campaign.type.id === 2 && (
                            <>
                              <span className="date w-1/2">
                                conversions
                              </span>
                              <p className="heading-5">
                                {commaNumber(campaign.metrics.conversions.value)}
                              </p>
                            </>
                          )}
                          {campaign.type.id === 3 && (
                            <>
                              <span className="date w-1/2">
                                completion rate
                              </span>
                              <p className="heading-5">
                                {`${commaNumber(campaign.metrics.completion_rate.toFixed(2))}%`}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="py-4 flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          {campaign.type.id === 1 && (
                            <>
                              <span className="date w-1/2">
                                CPA
                              </span>
                              <p className="heading-5">
                                {`$${commaNumber(campaign.metrics.cpa.toFixed(2))}`}
                              </p>
                            </>
                          )}
                          {campaign.type.id === 2 && (
                            <>
                              <span className="date w-1/2">
                                leads
                              </span>
                              <p className="heading-5">
                                {commaNumber(campaign.metrics.leads.value)}
                              </p>
                            </>
                          )}
                          {campaign.type.id === 3 && (
                            <>
                              <span className="date w-1/2">
                                CPCV
                              </span>
                              <p className="heading-5">
                                {`$${commaNumber(campaign.metrics.cpcv.toFixed(2))}`}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="mt-3 mx-3">
                          <LinkWrapper
                            variant="primary"
                            key={campaign.id}
                            target="_blank"
                            onClick={(
                              () => setParamsNavigateNewest(campaign.id)
                            )}
                            href={{
                              pathname: determinePage(campaign.type.id),
                            }}
                          >
                            <Button
                              variant="primary"
                              id={campaign.name}
                            >
                              See Details
                            </Button>
                          </LinkWrapper>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            ))
          )}
        </>
      )}
    </>
  )
}

export default ActiveCampaignOverview
