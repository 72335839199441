import {
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { AppContext } from '../../../../contexts/AppContext'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import CallIndustryForm from '../../../form/calls/CallIndustryForm'
import { ICallCategory, ICampaignCategory } from '../../../../api/imi/interfaces'

interface Props {
  edit: boolean
  isLoading: boolean
  callIndustry?: ICampaignCategory
}

const EditCallIndustry:React.FC<Props> = ({
  edit,
  isLoading,
  callIndustry,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const methods = useForm<ICampaignCategory>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      name: callIndustry?.name,
      id: callIndustry?.id,
      call_category: callIndustry?.call_category,
    }), [callIndustry]),
  })

  useEffect(() => {
    methods.reset(callIndustry)
  }, [callIndustry, methods])

  const cancel = () => {
    methods.reset({}, { keepErrors: false, keepDirty: false, keepDefaultValues: true })
    setBtnText('Save')
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  const onSubmit: SubmitHandler<ICampaignCategory> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    // const submitResponse = await IMIApi.CreateAdvertiser(data)
    // const authenticated = submitResponse.status === 200
    // setTimeout(() => {
    // if (submitResponse.status !== 200) {
    //   if (submitResponse.status === 0) {
    //     setIsButtonLoading(false)
    //     setError({ errorMsg: 'Internal server error.' })
    //     return setBtnText('Save')
    //   }
    //   const parse = JSON.parse(submitResponse)
    //   setIsButtonLoading(false)
    //   setError({ errorMsg: parse.detail })
    //   return setBtnText('Save')
    // }
    //   setAdvertiserId(submitResponse?.data?.id)
    //   setIsButtonLoading(false)
    //   setBtnText('Saved!')
    // }, 1000)
  }

  return (
    <Content
      title="industry details"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <CallIndustryForm
            callIndustry={callIndustry}
            errors={methods.formState.errors}
            showButton
          />
          <FormButtonGroup
            isButtonLoading={isButtonLoading}
            disabled={!methods.formState.isValid}
            disabledCancel={!methods.formState.isDirty}
            onClickCancel={cancel}
            btnText={btnText}
            showButton
          />
        </form>
      </FormProvider>
    </Content>
  )
}

export default EditCallIndustry
