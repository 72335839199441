import { useContext, useEffect, useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IVideoCreativePerformance, IVideoDetails } from '../../api/imi/interfaces'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'
import HorizontalGrid from '../layouts/HorizontalGrid'
import { videoCreativePerformanceMenu, placementBreakdownMenu, styles } from './Data'
import { commaNumber } from '../../helpers/utils'
import Icon from '../Icon'
import Accordion from '../layouts/Accordion'

interface Props {
  data: IVideoDetails
}

const VideoCreativePerformanceReport:React.FC<Props> = ({
  data,
}) => {
  const [reportData, setReportData] = useState<IVideoCreativePerformance[]>([] as any)
  const { user } = useContext(AppContext)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('impressions')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')

  useEffect(() => (
    // setReportData(data.creative_performance)
    setReportData(data.creative_performance)
  ), [data])

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = !sortDirection ? reportData.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : reportData.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }

  return (
    <Content
      title="creative performance"
      padding={false}
    >
      <HorizontalGrid
        smGrid="hidden"
        mdGrid="md:grid-cols-video-display-creative-md md:grid"
        lgGrid="lg:grid-cols-video-display-creative-md"
        header
      >
        {videoCreativePerformanceMenu.map((item) => (
          <div key={item.name} className={`break-all group flex center-items ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}>
            {item.sort ? (
              <span
                aria-hidden="true"
                onClick={() => columnSortingInfo(item.fieldName)}
                key={item.name}
              >
                <span className="inline lg:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                <span className="hidden lg:inline">{item.name}</span>
                <Icon
                  size="xxs"
                  className="text-default group sm:pr-0 ml-1 -mt-1"
                >
                  <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                  <ChevronDownIcon className="text-default group-hover:text-secondary" />
                </Icon>
              </span>
            ) : <span key={item.name}>{item.name}</span>}
          </div>
        ))}
      </HorizontalGrid>
      {reportData?.length > 0 ? (
        reportData?.map((placement) => (
          <div key={placement.label} className="odd:bg-grey-lightest md:odd:bg-white md:even:bg-grey-lightest pt-5 pb-1.5 horizontal-grid">
            <HorizontalGrid
              smGrid="block -mt-6 md:mt-0"
              mdGrid="md:grid-cols-video-display-creative-md md:grid"
              lgGrid="lg:grid-cols-video-display-creative-md"
            >
              <p className="heading-5 font-semibold pb-1.5 md:pb-0 md:text-xxs md:text-default md:font-base md:text-opacity-60 md:font-normal pr-1.5">
                {placement?.label}
              </p>
              <div className="grid grid-cols-2 md:block">
                <p className="text-xxs font-bold row-span-full pb-1.5 md:hidden">Started</p>
                <p className="small pr-1.5">
                  {commaNumber(placement?.started)}
                </p>
              </div>
              <div className="grid grid-cols-2 md:block">
                <p className="text-xxs font-bold row-span-full pb-1.5 md:hidden">Completed</p>
                <p className="small pr-1.5">
                  {commaNumber(placement?.completed)}
                </p>
              </div>
              <div className="grid grid-cols-2 md:block ">
                <p className="text-xxs font-bold row-span-full pb-1.5 md:hidden">Completion Rate</p>
                <p className="small pr-1.5">
                  {commaNumber(placement?.completion_rate * 100).toLocaleString('en', { useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                  %
                </p>
              </div>
              <div className="grid grid-cols-2 md:block">
                <p className="text-xxs font-bold row-span-full pb-1.5 md:hidden">Cost Per Completed View (CPCV)</p>
                <p className="small pr-1.5">
                  $
                  {commaNumber((placement?.cpcv).toLocaleString('en', { useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }))}
                </p>
              </div>
            </HorizontalGrid>
          </div>
        ))
      ) : (
        <p className="px-4 md:px-6">{!data.report_end_date && noDataMessage}</p>
      )}
    </Content>
  )
}

export default VideoCreativePerformanceReport
