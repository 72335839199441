import React from 'react'
import { cls } from '../../helpers/utils'

// Manually set the colors of the dot using variant.

// TODO: address color values for different variants, not just legend or graph

const styles = {
  style: {
    dotOnly: 'bg-transparent mr-0',
    badge: 'bg-grey-lightest px-3 py-2',
  },
  variant: {
    dot: 'rounded-circle flex items-center justify-center mr-2 h-3 w-3',
    badgeOnly: 'hidden',
  },
  size: {
    xs: 'h-1.5 w-1.5 min-w-1.5',
    sm: 'h-2 w-1.5 min-w-2',
    md: 'h-3 w-3',
  },
}

interface Props {
  variant: 'dot' | 'badgeOnly'
  label?: string
  dotColor?: string,
  badgeBackground?: 'dotOnly' | 'badge'
  onClick?: () => void
  children?: React.ReactNode
  size?: 'xs' | 'sm' | 'md'
  disabled?: boolean
  id?: string
}

const BadgeDot: React.FC<Props> = ({
  variant,
  dotColor,
  label,
  badgeBackground = 'bg-grey-lightest',
  onClick,
  children,
  size = 'sm',
  disabled,
  id,
}) => (
  label ? (
    <span onClick={onClick} id={id} aria-hidden="true" className={`inline-flex rounded-lg text-xs mb-3 items-center ${styles.style[badgeBackground]} font-semibold ${onClick ? 'hover:cursor-pointer' : 'cursor-default'} font-display text-default mr-3`}>
      <span className={cls(`
                      ${disabled ? `${dotColor} bg-opacity-60` : dotColor}
                      ${styles.variant[variant]}
                      `)}
      />
      {label}
    </span>
  ) : (
    <span onClick={onClick} id={id} aria-hidden="true" className={`flex rounded-lg items-baseline text-xs font-display ${styles.style[badgeBackground]} ${onClick ? 'hover:cursor-pointer' : 'cursor-default'}`}>
      <span className={cls(`
                      ${disabled ? `${dotColor} bg-opacity-60` : dotColor}
                      ${styles.variant[variant]}
                      ${styles.size[size]}
                      `)}
      />
      <span>{children}</span>
    </span>
  )
)

BadgeDot.defaultProps = {
  badgeBackground: 'badge',
  onClick: undefined,
  dotColor: 'bg-transparent',
  children: null,
  label: undefined,
  size: 'sm',
  disabled: false,
  id: undefined,
}

export default BadgeDot
