import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ENV } from '../env'
import { AppContext } from '../contexts/AppContext'
import { IRecoverPasswordStart, IBrandingLogin } from '../api/imi/interfaces'
import { IMIApi } from '../api/imi/api'
import LoadingSpinner from '../components/LoadingSpinner'
import FormButtonGroup from '../components/button/FormButtonGroup'
import Input from '../components/form/Input'

const RecoverPasswordPage:React.FC = () => {
  const {
    isSignedIn,
    user,
  } = useContext(AppContext)
  const [serverError, setServerError] = useState<string>('')
  const {
    register,
    handleSubmit,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
    },
  } = useForm<IRecoverPasswordStart>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  const [noLogin, setNoLogin] = useState<boolean>(false)
  const [brandingLogin, setBrandingLogin] = useState<IBrandingLogin>({} as any)
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const [btnText, setBtnText] = useState('Get Reset Link')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>(false)

  // useEffect(() => {
  //   const abortController = new AbortController()
  //   // TODO: get just the first part
  //   const url = window.location.hostname
  //   console.log(url)
  //   console.log(`https://${ENV.imiURL}`)
  //   if (url.toLowerCase().includes('localhost')) {
  //     setLoading(false)
  //     // return undefined
  //   } else if (url.toLowerCase() === (`https://${ENV.imiURL}`)) {
  //     setLoading(false)
  //     // return undefined
  //   } else {
  //     const checkBrandingUrl = async () => {
  //       const branding = await IMIApi.PostBrandingLogin(`https://${url}`)
  //       if (branding.status !== 200) {
  //         setLoading(false)
  //         return null
  //       }
  //       setBrandingLogin(branding.data)
  //       return setLoading(false)
  //     }
  //     checkBrandingUrl()
  //   }
  //   return () => abortController.abort()
  // }, [])

  const onSubmit: SubmitHandler<IRecoverPasswordStart> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Submitting...')
    const submitResponse = await IMIApi.recoverPasswordStart(data.username)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          return setBtnText('Get Reset Link')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setServerError(parse.detail)
        return setBtnText('Get Reset Link')
      }
    }, 1000)
    setIsButtonLoading(false)
    return setBtnText('Success!')
  }

  // if (!isSignedIn()) {
  //   return null
  // }

  return (
    <div className="flex items-center justify-between min-h-screen bg-login">
      <div className="w-full px-6 min-h-screen bg-white flex flex-1 items-center z-10">
        {loading ? <LoadingSpinner size="lg" variant="primary" /> : (
          <form onSubmit={handleSubmit(onSubmit)} className="md:pr-12 pt-6 pb-8 mb-4 flex-1 max-w-half-site my-0 mx-auto">
            <div
              className="heading-1 text-default block text-left py-2 mb-8 border-b border-grey-dark border-dotted md:max-w-login-form"
            >
              <h1 className="text-default">Forgot Password?</h1>
            </div>
            {/* <h2>Password Recovery</h2> */}
            <p className="small mb-4 md:max-w-login-form w-full">Please enter your user name below. We&apos;ll send a message to the associated email address with a link to reset the password.</p>
            <p className="small mb-4 md:max-w-login-form w-full">If you encounter another problem logging in or do not receive an email, please contact your account representative.</p>
            <div className="mb-4 md:max-w-login-form w-full">
              <Input
                type="text"
                label="User name"
                width="w-full"
                {...register('username', {
                  required: true,
                  pattern: {
                    value: /^[a-z0-9-]*$/,
                    message: 'Your username is not the correct format. Please only user lowercase letters, dashes, and numbers only.',
                  },
                })}
                error={errors.username && <span className="form-error">{errors.username?.message || 'This field is required.'}</span>}
              />
            </div>
            <p className="text-red">{serverError}</p>
            <div className="">
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabledCancel={!isDirty}
                disabled={!isValid || !isDirty}
                fullWidth
                btnText={btnText}
                showButton
              />
              <a
                className="text-primary global-animation hover:text-secondary block font-bold font-base text-xxs"
                href="/"
              >
                Return to Log In ›
              </a>
            </div>
          </form>
        )}
      </div>
      <div className="hidden md:block md:flex-1">
        <img src="images/dashboard-data-login-graph.png" alt="graph" className="hidden md:block xl:max-w-login-image relative p-24 ml-auto mr-auto" />
      </div>
    </div>
  )
}

export default RecoverPasswordPage
