import {
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../../contexts/AppContext'
import { CampaignContext } from '../../../../../contexts/CampaignContext'
import {
  ICampaignCreate,
  IAdvertiserInDb,
  ICampaignTypeInDb,
  ICampaignInDb,
} from '../../../../../api/imi/interfaces'
import Content from '../../../../layouts/Content'
import FormButtonGroup from '../../../../button/FormButtonGroup'
import { IMIApi } from '../../../../../api/imi/api'
import CreateCampaignForm from '../../../../form/campaigns/campaign_settings/CreateCampaignForm'
import CampaignAssignment from '../CampaignAssignment'
import LoadingSpinner from '../../../../LoadingSpinner'
import EditCampaignForm from '../../../../form/campaigns/campaign_settings/EditCampaignForm'

interface Props {
  allAdvertisers: IAdvertiserInDb[]
  allCampaignTypes: ICampaignTypeInDb[]
  isLoading: boolean
}

const CreateCampaign:React.FC<Props> = ({
  allAdvertisers,
  allCampaignTypes,
  isLoading,
}) => {
  const { setError } = useContext(AppContext)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const methods = useForm<ICampaignCreate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      secondary_name: '',
      type_id: 0,
      advertiser_id: 0,
      specific_id: null,
      is_active: true,
    }), []),
  })

  const cancel = () => {
    methods.reset()
    setBtnText('Save')
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  const onSubmit: SubmitHandler<ICampaignCreate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const createCamp = {
      ...data,
      is_active: true,
      uplift_base: 0,
      call_processing_is_active: false,
      specific_id: data.specific_id && data.specific_id > 0 ? data.specific_id : null,
    }

    const submitResponse = await IMIApi.CreateCampaign(createCamp)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setCampaignInDb(submitResponse.data)
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  return (
    <>
      <Content title="campaign details">
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <EditCampaignForm
                  errors={methods.formState.errors}
                  allAdvertisers={allAdvertisers}
                  allCampaignTypes={allCampaignTypes}
                  showButton
                />
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isValid}
                  disabledCancel={!methods.formState.isDirty}
                  onClickCancel={cancel}
                  btnText={btnText}
                  showButton
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default CreateCampaign
