import {
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { IUserInDb, IUserUpdate } from '../../../../api/imi/interfaces'
import Content from '../../../layouts/Content'
import AddDashboardBranding from './AddDashboardBranding'
import AssignAdvertisers from './AssignAdvertisers'
import Badge from '../../../badge/Badge'
import FormButtonGroup from '../../../button/FormButtonGroup'
import AccountUserForm from '../../../form/accounts/AccountUserForm'
import LoadingCheck from '../../../../hoc/LoadingCheck'
import { UserContext } from '../../../../contexts/UserContext'
import AssignCampaigns from './AssignCampaigns'
import { AppContext } from '../../../../contexts/AppContext'

interface Props {
  edit: boolean
  userTypes: []
}

const EditUser:React.FC<Props> = ({
  edit,
  userTypes,
}) => {
  const { userInDb, setUserInDb, userAdIds } = useContext(UserContext)
  const { user, setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState<string>('Save')
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
  const [showButton, setShowButton] = useState<boolean>(edit)

  const methods = useForm<IUserUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      username: userInDb.username,
      email: userInDb.email,
      first_name: userInDb.first_name,
      last_name: userInDb.last_name,
      user_type_id: userInDb.user_type_id,
      is_active: userInDb.is_active,
      is_superuser: userInDb.is_superuser,
      white_label_id: userInDb.white_label_id,
      email_for_all_reports: userInDb.email_for_all_reports,
      email_for_lead_reports: userInDb.email_for_lead_reports,
      email_for_flagged_calls: userInDb.email_for_flagged_calls,
      id: userInDb.id,
    }), [userInDb]),
  })

  useEffect(() => {
    methods.reset({ ...userInDb })
  }, [userInDb, methods])

  const cancel = () => {
    methods.reset({ ...userInDb }, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }
  // react-hook-form specific
  // check to see if password is entered to run confirm password validation
  // or if user type id changes to switch between superuser (boolean)
  const watchUserType = methods.watch('user_type_id')
  // Hack, have to keep this in here to make sure fields "dirty"
  const watchIsActive = methods.watch('is_active')

  const editUser = () => {
    setShowButton(true)
  }
  const onSubmit: SubmitHandler<IUserUpdate> = async (data) => {
    const userData = data
    // delete password field if user does not update this field
    if (!data.password) {
      delete userData.password
    }
    const formData = {
      ...userData,
      is_superuser: data.user_type_id === 1,
    }
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateUserById(formData)
    const authenticated = submitResponse.status === 200
    // const message = serverError.detail.msg
    setTimeout(() => {
      methods.setValue('password', '')
      methods.setValue('confirm_password', '')
      // if (!authenticated) {
      //   const parse = JSON.parse(submitResponse)
      //   setError({ errorMsg: parse.detail })
      //   methods.reset(userInDb)
      //   setIsButtonLoading(false)
      //   return setBtnText('Save')
      // }
      // methods.reset(userInDb)
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        cancel()
        return setBtnText('Save')
      }
      setUserInDb(submitResponse.data)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  const fixDateObj = (date) => {
    const dateObj = new Date(date)
    const newDateObj = {
      month: dateObj.getMonth() + 0o1,
      day: dateObj.getDate(),
      year: dateObj.getFullYear(),
    }
    if (newDateObj.year < 2022) {
      return 'User has never logged in.'
    }
    return `${newDateObj.month}/${newDateObj.day}/${newDateObj.year}`
  }

  return (
    <div>
      <Content title="User Details">
        <div className="mb-3">
          <Badge
            variant="badgeOnly"
            label={(
              <span>
                Joined:
                &nbsp;
                <span className="font-bold">
                  {fixDateObj(userInDb?.created_at)}
                </span>
              </span>
            )}
          />
          <Badge
            variant="badgeOnly"
            label={(
              <span>
                Last Login:
                &nbsp;
                <span className="font-bold">
                  {fixDateObj(userInDb?.last_seen_at)}
                </span>
              </span>
            )}
          />
          <Badge
            variant="badgeOnly"
            label={(
              <span>
                Last Updated:
                &nbsp;
                <span className="font-bold">
                  {fixDateObj(userInDb?.updated_at)}
                </span>
              </span>
            )}
          />
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <AccountUserForm
              accountTypesData={userTypes}
              showButton={showButton}
              errors={methods.formState.errors}
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={(!methods.formState.isDirty && methods.formState.isValid) || methods.formState.dirtyFields.white_label_id}
              disabledCancel={!methods.formState.isDirty || methods.formState.dirtyFields.white_label_id}
              showButton={showButton}
              onClickCancel={cancel}
              onClickEdit={editUser}
              btnText={btnText}
            />
          </form>
        </FormProvider>
      </Content>
      <AddDashboardBranding
        isLoading={!userInDb}
      />
      {(watchUserType > 4) && (
        <>
          <AssignAdvertisers edit />
          <AssignCampaigns edit />
        </>
      )}
    </div>
  )
}

export default LoadingCheck(EditUser)
