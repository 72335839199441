import { useState, useEffect, useContext } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { ENV } from '../../../../env'
import Content from '../../../layouts/Content'
import { IBrandingCreate, IEmailTemplateInDb } from '../../../../api/imi/interfaces'
import FormButtonGroup from '../../../button/FormButtonGroup'
import AccountDashboardBrandingForm from '../../../form/accounts/AccountDashboardBrandingForm'
import LoadingCheck from '../../../../hoc/LoadingCheck'
import { AppContext } from '../../../../contexts/AppContext'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  emailTemplate: IEmailTemplateInDb[]
  setTemplateId: (val: number) => void
  isLoading: boolean
}

const CreateDashboardBranding:React.FC<Props> = ({
  emailTemplate,
  setTemplateId,
  isLoading,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)

  const methods = useForm<IBrandingCreate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })

  const cancel = () => {
    methods.reset({}, { keepErrors: false })
    setIsButtonLoading(false)
    setBtnText('Save')
    methods.clearErrors()
  }

  const onSubmit: SubmitHandler<IBrandingCreate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const updatedTemplate = {
      ...data,
      url_login: `https://${data.url_login}.${ENV.imiURL}`,
    }
    const submitResponse = await IMIApi.BrandingCreate(updatedTemplate)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setTemplateId(submitResponse?.data?.id)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
  }

  return (
    <Content
      title="Dashboard Branding Details"
    >
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <AccountDashboardBrandingForm
              errors={methods.formState.errors}
              emailTemplate={emailTemplate}
              showButton
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!methods.formState.isValid}
              disabledCancel={!methods.formState.isDirty}
              onClickCancel={cancel}
              btnText={btnText}
              showButton
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default CreateDashboardBranding
