import { ISemDetail, ICallsOverview, ICallsByCampaignAndStatus } from '../api/imi/interfaces'
import { formatData } from './data'
import { initPdf, Theme } from './lib'
import { 
  drawHeader, 
  drawMast,
  drawTotals, 
  drawKeyMetrics,
  drawCalls,
  drawCallTotals,
  drawCallHighlights,
  drawWebEvents,
  drawWebEventTotals,
  drawEmailLeads,
  drawEmailLeadTotals,
  drawTopPerformingKeywords,
  drawDeviceTypes,
  drawRoi,
  drawRoiNotes,
  drawSummaryNotes,
  drawWantToKnowMore,
} from './sections'

export const createPdf = async (
  fileName: string,
  userType: number,
  sem: ISemDetail,
  overview: ICallsOverview | undefined, 
  calls: ICallsByCampaignAndStatus,
  theme: Theme,
  ...images: string[]
) => {
  const data = formatData(sem, overview, calls, userType)
  const pdf = await initPdf(images, theme)

  drawHeader(pdf)
  drawMast(pdf, data.campaign)
  drawTotals(pdf, data.totals)
  drawKeyMetrics(pdf, data.keyMetrics)
  if (data.calls) {
    drawCalls(pdf, data.calls)
  }
  if (data.callTotals) {
    drawCallTotals(pdf, data.callTotals)
  }
  if (data.callHighlights) {
    drawCallHighlights(pdf, data.callHighlights)
  }
  if (data.webEvents) {
    drawWebEvents(pdf, data.webEvents)
  }
  if (data.webEventTotals) {
    drawWebEventTotals(pdf, data.webEventTotals)
  }
  if (data.emailLeads) {
    drawEmailLeads(pdf, data.emailLeads)
  }
  if (data.emailLeadTotals) {
    drawEmailLeadTotals(pdf, data.emailLeadTotals)
  }
  if (data.keywords) {
    drawTopPerformingKeywords(pdf, data.keywords)
  }
  if (data.deviceTypes) {
    drawDeviceTypes(pdf, data.deviceTypes)
  }
  if (data.roi && data.roiTotals) {
    drawRoi(pdf, data.roi, data.roiTotals)
  }
  drawSummaryNotes(pdf)
  drawWantToKnowMore(pdf)

  await pdf.download(fileName)
}
