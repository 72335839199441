import {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { IMIApi } from '../../../../api/imi/api'
import Search from '../../../form/Search'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import { handleFilters } from '../../../../helpers/utils'
import Modal from '../../../modal'
import {
  ICampaignInDb,
  ICampaignSource,
  ICampaignMultiResponse,
} from '../../../../api/imi/interfaces'
import { AppContext } from '../../../../contexts/AppContext'
import AssignedCampaigns from './AssignedCampaigns'
import CreateButtons from '../../../button/CreateButtons'
import UnassignedCampaigns from './UnassignedCampaigns'

// TODO: fix double clicking on account type sorting
// TODO: campaign filter

interface Props {
  // unassignedCampaigns: ICampaignSourceMultiResponse
  unassignedCampaigns: ICampaignSource[]
  assignedCampaigns: ICampaignMultiResponse
  refresh: Dispatch<SetStateAction<boolean>>
  setPagination: Dispatch<SetStateAction<any>>
  loadingAssigned: boolean
  loadingUnassigned: boolean
}

const CampaignSettings:React.FC<Props> = ({
  unassignedCampaigns,
  refresh,
  assignedCampaigns,
  setPagination,
  loadingAssigned,
  loadingUnassigned,
}) => {
  const { setError } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [btnText, setBtnText] = useState('Change')
  const [cancelText, setCancelText] = useState('Cancel')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  // holds the state of which column is to be sorted, all independent
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [currentCampaign, setCurrentCampaign] = useState<ICampaignInDb>()
  const [assigned, setAssigned] = useState<ICampaignInDb[]>(assignedCampaigns.data)
  const [unassigned, setUnassigned] = useState<ICampaignSource[]>(unassignedCampaigns)
  // const [unassigned, setUnassigned] = useState<ICampaignSource[]>(unassignedCampaigns.data)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })
  // main filter to check campaign name name
  // based on status of campaign
  const filters = useCallback(() => {
    const filterSearchValue = (campaignData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const campaignFilter = campaignData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const campaignFil = splitItems.some((si) => campaignData.name.toLowerCase().includes(si))
        return campaignFil
      }
      return campaignFilter
    }
    const filterUnassignedData = unassignedCampaigns?.filter((campaign) => filterSearchValue(campaign))
    // const filterUnassignedData = unassignedCampaigns?.data?.filter((campaign) => filterSearchValue(campaign))
    const filterAssignedData = assignedCampaigns?.data?.filter((campaign) => filterSearchValue(campaign))
    setUnassigned(filterUnassignedData)
    setAssigned(filterAssignedData)
  }, [selectedFilters, unassignedCampaigns, assignedCampaigns])

  useEffect(() => {
    filters()
  }, [filters])

  const changeStatus = async (campaignStatus, data) => {
    const formData = {
      ...data,
      is_active: !campaignStatus,
    }
    setIsButtonLoading(true)
    setCancelText('')
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateAdvertiser(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      refresh(true)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setIsButtonLoading(false)
      return setShowModal(false)
    }, 2000)
    refresh(false)
  }
  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Change"
        body={(
          `Change campaign ${currentCampaign?.name}'s status to
            ${currentCampaign?.is_active ? 'inactive' : 'active'}?`
        )}
        onSubmit={() => changeStatus(currentCampaign?.is_active, currentCampaign)}
        onClose={() => setShowModal(false)}
        closeText={cancelText}
        submitText={btnText}
      />
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        {/* <Search
          value={selectedFilters.search}
          onChange={(
            (e) => setSelectedFilters(
              handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
            )
          )}
          label="search"
          hideLabel
          placeholder="search..."
          className="w-full max-w-none"
        /> */}
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Campaign"
            path="/admin-tools/campaign-settings/create-campaign"
          />
        </div>
      </div>
      {unassignedCampaigns && (
        <UnassignedCampaigns
          totalData={unassigned?.length}
          // totalData={unassignedCampaigns.rows_total}
          isLoading={loadingUnassigned}
          unassignedCampaigns={unassigned}
          setPagination={setPagination}
        />
      )}
      {assignedCampaigns && (
        <AssignedCampaigns
          totalData={assignedCampaigns.rows_total}
          isLoading={loadingAssigned}
          assignedCampaigns={assigned}
          setPagination={setPagination}
          setRefresh={refresh}
        />
      )}
    </>
  )
}

export default CampaignSettings
