import { PDF, FontSizes, FontStyle } from '../lib'
import { Campaign } from '../data'
import * as sizes from '../sizes'

export const drawMast = (pdf: PDF, data: Campaign) => {
  const header = pdf.text.drawText(
    'IMI DIVE Report',
    pdf.colors.white, 
    {
      size: FontSizes.XLarge,
    },
  )

  const row1Label = pdf.text.drawText(
    'Client/Campaign Name: ',
    pdf.colors.primary,
    {
      style: FontStyle.Bold,
    },
  )

  const row1Value = pdf.text.drawText(
    data.name,
    pdf.colors.white,
  )

  const row2Label = pdf.text.drawText(
    'Campaign Type: ',
    pdf.colors.primary,
    {
      style: FontStyle.Bold,
    },
  )
    
  const row2Value = pdf.text.drawText(
    data.type,
    pdf.colors.white,
  )

  const row3Label = pdf.text.drawText(
    'Campaign Dates: ',
    pdf.colors.primary,
    {
      style: FontStyle.Bold,
    },
  )
    
  const row3Value = pdf.text.drawText(
    `${data.startDate}-${data.endDate}`,
    pdf.colors.white,
  )

  pdf.pages.moveLeft(sizes.PAGE_MARGIN)
  pdf.drawing.drawRect(
    pdf.pages.pageWidth + sizes.PAGE_MARGIN * 2, 
    sizes.PAGE_MAST_MARGIN * (17 / 6)
      + header.height
      + row1Label.height + sizes.LINE_SPACE * 2
      + row2Label.height + sizes.LINE_SPACE * 2
      + row3Label.height,
    pdf.colors.tertiary,
  )
  pdf.pages.moveRight(sizes.PAGE_MARGIN)
  pdf.pages.moveDown(sizes.PAGE_MAST_MARGIN)
  header.render()
  pdf.pages.moveDown(header.height)
  pdf.pages.moveDown(sizes.PAGE_MAST_MARGIN / 3)
  pdf.drawing.drawDashedLine(pdf.pages.pageWidth, 0, pdf.colors.primary)
  pdf.pages.moveDown(sizes.PAGE_MAST_MARGIN / 2)

  row1Label.render()
  pdf.pages.moveRight(row1Label.width)
  row1Value.render()
  pdf.pages.moveLeft(row1Label.width)
  pdf.pages.moveDown(row1Label.height + sizes.LINE_SPACE * 2)

  row2Label.render()
  pdf.pages.moveRight(row2Label.width)
  row2Value.render()
  pdf.pages.moveLeft(row2Label.width)
  pdf.pages.moveDown(row2Label.height + sizes.LINE_SPACE * 2)

  row3Label.render()
  pdf.pages.moveRight(row3Label.width)
  row3Value.render()
  pdf.pages.moveLeft(row3Label.width)
  pdf.pages.moveDown(row3Label.height + sizes.PAGE_MAST_MARGIN * 2)
}
