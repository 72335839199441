import {
  useState,
  useContext,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import ToolTip from '../../../ToolTip'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import { ICampaignUpliftInDb } from '../../../../api/imi/interfaces'
import { getFieldByPath } from '../../../../helpers/utils'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import UpliftDetailsForm from '../../../form/campaigns/UpliftDetailsForm'
import LoadingSpinner from '../../../LoadingSpinner'
import EditUpliftCycle from './EditUpliftCycle'

interface Props {
  campaignUplift: any
  loading: boolean
  edit: boolean
  setRefresh: Dispatch<SetStateAction<number>>
}

const EditUplift:React.FC<Props> = ({
  campaignUplift,
  loading,
  edit,
  setRefresh,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)
  const [showButton, setShowButton] = useState<boolean>(edit)
  const [editUplift, setEditUplift] = useState<ICampaignUpliftInDb>({} as any)
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('')

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? editUplift?.cycles?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1)) : editUplift?.cycles?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1))
    return { ...values }
  }

  useEffect(() => {
    setEditUplift(campaignUplift)
  }, [campaignUplift])

  const methods = useForm<ICampaignUpliftInDb>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: editUplift?.id,
      name: editUplift?.name,
      secondary_name: editUplift?.secondary_name,
      is_active: editUplift?.is_active,
      cycle: {
        name: editUplift?.advertiser?.name,
        is_active: editUplift?.advertiser?.is_active,
      },
      uplift_base: editUplift?.uplift_base,
      cycles: editUplift?.cycles,
      type: {
        name: editUplift?.type?.name,
        short_name: editUplift?.type?.short_name,
        id: editUplift?.type?.id,
      },
    }), [editUplift]),
  })

  useEffect(() => {
    methods.reset({ ...editUplift })
  }, [methods, editUplift])

  const cancel = () => {
    methods.reset(editUplift, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      id: data.id,
      cycle_id: 0,
      uplift: data.uplift_base,
      budget: data.budget_base,
    }
    setEditUplift(data)
    const submitResponse = await IMIApi.SetCampaignUpliftById(formData)

    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }
  const editTemplate = () => {
    setShowButton(true)
  }

  return (
    <>
      <Content
        title="uplift details"
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="white-label-info"
          >
            The uplift percent included in this section applies to the overall campaign. If the percent is changed in this section, it will only affect all upcoming cycles and not a current cycle.
          </ToolTip>
        )}
      >
        {loading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <UpliftDetailsForm
                upliftDetails={editUplift}
                errors={methods.formState.errors}
                showButton={showButton}
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabled={!methods.formState.isValid || !methods.formState.isDirty}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                btnText={btnText}
                showButton={showButton}
                onClickEdit={editTemplate}
              />
            </form>
          </FormProvider>
        )}
      </Content>
      <EditUpliftCycle
        campaignUplift={campaignUplift}
        campaignType={campaignUplift?.type?.id}
        loading={loading}
        edit={edit}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default EditUplift
