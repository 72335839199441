import { useState, useEffect } from 'react'
import HorizontalBarChart from '../charts/HorizontalBarChart'
import { IDisplayImpressionsByCreative, IVideoDetails } from '../../api/imi/interfaces'
import Content from '../layouts/Content'

interface Props {
  data: IVideoDetails
}

const VideoImpressionsByCreativeReport:React.FC<Props> = ({
  data,
}) => {
  const [reportData, setReportData] = useState<IDisplayImpressionsByCreative>({ series: [], callouts: [] })
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'

  useEffect(() => {
    setReportData(data.impressions_by_creative)
  }, [data])

  return (
    <>
      {reportData?.series[0]?.data?.length > 0 ? (
        reportData?.series.map((report) => (
          <HorizontalBarChart
            key={report.name}
            title={report.name}
            items={report.data.map((d) => ({ label: d.x, value: d.y }))}
            totalImpressions={report.data.reduce((sum, d) => sum + d.y, 0)}
          />
        ))
      ) : (
        // NOTE: had to add in report end date to get the error message/no data
        // message. Don't like how messy this is but maybe we can split out the
        // no data message into its own component.
        <Content
          title="impressions by creative"
          padding={false}
        >
          <p className="mx-6">{!data.report_end_date && noDataMessage}</p>
        </Content>
      )}
    </>
  )
}

export default VideoImpressionsByCreativeReport
