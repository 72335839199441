import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../api/imi/api'
import AdminToolsMenu from '../../components/adminTools/AdminToolsMenu'
import Padding from '../../components/layouts/Padding'
import SidebarLeft from '../../components/layouts/SidebarLeft'
import { IAdvertiserInDb, IUserInDb } from '../../api/imi/interfaces'
import { AppContext } from '../../contexts/AppContext'
import AdvertiserSettings from '../../components/adminTools/advertisers/AdvertiserSettings'

const AdvertiserSettingsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [advertisers, setAdvertisers] = useState<IAdvertiserInDb[]>([] as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllAdvertisers = async () => {
      const response = await IMIApi.GetAllAdvertisers(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAdvertisers(response.data.data)
      return setLoading(false)
    }
    fetchAllAdvertisers()
    return () => abortController.abort()
  }, [refresh, setError])

  useEffect(() => {
    if (advertisers.length > 0) {
      setLoading(false)
    }
  }, [advertisers])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <AdvertiserSettings
            advertisers={advertisers}
            isLoading={loading}
            refresh={setRefresh}
          />
        )}
      />
    </Padding>
  )
}
export default AdvertiserSettingsPage
