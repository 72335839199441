import {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import Icon from '../../../Icon'
import LinkWrapper from '../../../Links'
import EditMenu from '../../EditMenu'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Search from '../../../form/Search'
import { handleFilters, commaNumber } from '../../../../helpers/utils'
import CreateButtons from '../../../button/CreateButtons'
import Content from '../../../layouts/Content'
import { ICampaignUplift } from '../../../../api/imi/interfaces'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import { campaignUpliftMenu, campaignSettingsStyles, upliftStatus } from './Data'
import LoadingSpinner from '../../../LoadingSpinner'
import BadgeAlert from '../../../badge/BadgeAlert'
import Dropdown from '../../../form/Dropdown'

interface Props {
  campaignUplift: ICampaignUplift[]
  loading: boolean
}

const Uplift:React.FC<Props> = ({ campaignUplift, loading }) => {
  const { setError } = useContext(AppContext)
  const [errorMsg, setErrorMsg] = useState('There are no campaign uplifts found.')
  const [filteredUplift, setFilteredUplift] = useState<ICampaignUplift[]>(campaignUplift)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<boolean>(false)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })
  const getFieldByPath = (obj: any, path: string, delimiter = '.') => {
    const paths = path.split(delimiter)
    let field = obj
    // eslint-disable-next-line no-restricted-syntax
    for (const p of paths) {
      field = field[p]
    }
    return field?.toLowerCase()
  }
  const sortBy = (a: string, b: string) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
  
    return 0
  }
  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)

    setSortColumn(fieldName)
    const values = sortDirection ? filteredUplift?.sort((a, b) => sortBy(getFieldByPath(a, fieldName), getFieldByPath(b, fieldName))) : filteredUplift?.sort((a, b) => sortBy(getFieldByPath(b, fieldName), getFieldByPath(a, fieldName)))
    return { values }
  }

  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const campaignStatus = status.is_active
      if (campaignStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status === 1
    }
    const filterSearchValue = (upliftData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = upliftData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => upliftData.name.toLowerCase().includes(si))

        return nameFil
      }
      return nameFilter
    }

    const filterData = campaignUplift?.filter((uplift) => filterSearchValue(uplift) && filterStatus(uplift))
    setFilteredUplift(filterData)
  }, [selectedFilters, campaignUplift])

  useEffect(() => {
    filters()
  }, [campaignUplift, filters])

  const handleLocalStorage = (id) => {
    localStorage.setItem('uplift_id', JSON.stringify(id))
  }

  return (
    <>
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Uplift"
            path="/admin-tools/uplift/create-uplift"
          />
        </div>
      </div>
      <Content
        title={selectedFilters.status === 0 ? 'active campaigns with uplift' : 'inactive campaigns with uplift'}
        padding={false}
        badge={(
          <BadgeAlert
            alert={filteredUplift.length}
            status={selectedFilters?.status === 0}
          />
        )}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={upliftStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
      >
        {loading ? <div className="pxsmall-4 pb-6 md:px-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-small-4"
              mdGrid="md:grid-cols-small-4"
            >
              {campaignUpliftMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && campaignSettingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredUplift?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filteredUplift?.map((uplift) => (
                  <HorizontalGrid
                    key={uplift.id}
                    smGrid="grid-cols-small-4"
                    mdGrid="md:grid-cols-small-4"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      <LinkWrapper
                        variant="defaultSm"
                        key={uplift.id}
                        onClick={() => handleLocalStorage(uplift.id)}
                        href={{
                          pathname: '/admin-tools/uplift/edit-uplift',
                        }}
                      >
                        {uplift.name}
                        {uplift.secondary_name && (
                          `: ${uplift.secondary_name}`
                        )}
                        {uplift.specific && (
                          `- ${uplift.specific.name}`
                        )}
                      </LinkWrapper>
                    </p>
                    <p className="text-xxs">
                      {uplift.type.short_name}
                    </p>
                    <p className="text-xxs pr-6">
                      {`${uplift.uplift_base}%`}
                    </p>
                    <p className="text-xxs pr-6">
                      {uplift.budget_base > 0 ? (
                        `$${commaNumber(uplift.budget_base)}`
                      ) : (
                        '—'
                      )}
                    </p>
                    <EditMenu
                      text="Edit Uplift"
                      id={uplift.id}
                      pathname="/admin-tools/uplift/edit-uplift"
                      onClick={() => handleLocalStorage(uplift.id)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default Uplift
