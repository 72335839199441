import {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import { useHistory } from 'react-router-dom'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignUpliftInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import EditUplift from '../../../components/adminTools/campaigns/uplift/EditUplift'

const EditUpliftPage:React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [campaignUplift, setCampaignUplift] = useState<ICampaignUpliftInDb>({} as any)
  const { setError } = useContext(AppContext)
  const [upliftId, setUpliftId] = useState<number>(0)
  const [refresh, setRefresh] = useState<number>(0)

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    }, [value])
    return ref.current || 0
  }

  const previousRefresh = usePrevious(refresh)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('uplift_id')
    if (items) {
      sessionStorage.setItem('uplift_id', items)
      localStorage.removeItem('uplift_id')
    }
    const getSessionItems = sessionStorage.getItem('uplift_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setUpliftId(parseItems)
      }
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if ((upliftId > 0) || (refresh > previousRefresh)) {
      const fetchCampaignUplift = async () => {
        const response = await IMIApi.GetCampaignUpliftById(upliftId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          setLoading(false)
          return history.push('/admin-tools/uplift')
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaignUplift(response.data)
        return setLoading(false)
      }
      fetchCampaignUplift()
    }
    return () => abortController.abort()
  }, [setError, upliftId, previousRefresh, refresh, history])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditUplift
            edit
            campaignUplift={campaignUplift}
            loading={loading}
            setRefresh={setRefresh}
          />
        )}
      />
    </Padding>
  )
}
export default EditUpliftPage
