import { PDFDocument, PDFPage } from 'pdf-lib'
import { Colors } from './colors'
import { Fonts } from './fonts'
import * as sizes from '../sizes'

export type Pages = {
  length: number
  currentPage: PDFPage
  currentX: number
  currentY: number
  remainingHeight: number
  pageWidth: number
  pageHeight: number
  nextPage: () => void
  moveTo: (x: number, y: number) => void
  moveToX: (x: number) => void
  moveDown: (val: number) => void
  moveUp: (val: number) => void
  moveRight: (val: number) => void
  moveLeft: (val: number) => void
  resetX: () => void
}

const drawPage = (page: PDFPage, colors: Colors, fonts: Fonts, number: string) => {
  page.drawRectangle({
    x: 0,
    y: 0,
    width: page.getWidth(),
    height: page.getHeight(),
    color: colors.grey,
  })
  page.drawText(number, {
    x: page.getWidth() - sizes.PAGE_MARGIN,
    y: sizes.PAGE_MARGIN * (3 / 4),
    size: sizes.FONT_SIZE_NORMAL,
    font: fonts.nunito,
    color: colors.darkestGrey,
  })
}

export const getPages = (pdfDoc: PDFDocument, colors: Colors, fonts: Fonts): Pages => {
  let page = pdfDoc.addPage()
  const pages = [page]
  const pageWidth = page.getWidth() - sizes.PAGE_MARGIN * 2
  const pageHeight = page.getHeight() - sizes.PAGE_MARGIN - sizes.PAGE_MARGIN_BOTTOM
  let currentX = sizes.PAGE_MARGIN
  let currentY = pageHeight + sizes.PAGE_MARGIN_BOTTOM
  page.moveTo(currentX, currentY)

  drawPage(page, colors, fonts, '1')

  return {
    get length() {
      return pages.length
    },
    get currentPage() {
      return page
    },
    get currentX() {
      return currentX
    },
    get currentY() {
      return currentY
    },
    get remainingHeight() {
      return currentY - sizes.PAGE_MARGIN_BOTTOM
    },
    get pageWidth() {
      return pageWidth
    },
    get pageHeight() {
      return pageHeight
    },
    nextPage() {
      pages.push(page)
      page = pdfDoc.addPage()
      drawPage(page, colors, fonts, pages.length.toString())
      currentX = sizes.PAGE_MARGIN
      currentY = pageHeight + sizes.PAGE_MARGIN_BOTTOM
      page.moveTo(currentX, currentY)
    },
    moveTo(x: number, y: number) {
      currentX = x
      currentY = y
      page.moveTo(currentX, currentY)
    },
    moveToX(x: number) {
      currentX = x
      page.moveTo(currentX, currentY)
    },
    moveDown(val: number) {
      currentY -= val
      page.moveTo(currentX, currentY)
    },
    moveUp(val: number) {
      currentY += val
      page.moveTo(currentX, currentY)
    },
    moveRight(val: number) {
      currentX += val
      page.moveTo(currentX, currentY)
    },
    moveLeft(val: number) {
      currentX -= val
      page.moveTo(currentX, currentY)
    },
    resetX() {
      currentX = sizes.PAGE_MARGIN
      page.moveTo(currentX, currentY)
    },
  }
}
