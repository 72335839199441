import {
  useEffect,
  useState,
  useContext,
} from 'react'
import { IMIApi } from '../../../api/imi/api'
import { CampaignContext } from '../../../contexts/CampaignContext'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import CampaignAssignment from '../../../components/adminTools/campaigns/campaign_settings/CampaignAssignment'
import CallSettingsCTA from '../../../components/adminTools/campaigns/campaign_settings/CallSettingsCTA'
import UpliftCTA from '../../../components/adminTools/campaigns/campaign_settings/UpliftCTA'
import SetCampaignStatus from '../../../components/form/campaigns/campaign_settings/SetCampaignStatus'
import { AppContext } from '../../../contexts/AppContext'
import {
  IAdvertiserInDb,
  ICampaignInDb,
  ICampaignTypeInDb,
} from '../../../api/imi/interfaces'
import EditCampaign from '../../../components/adminTools/campaigns/campaign_settings/EditCampaign'
import RoiSettingsCTA from '../../../components/adminTools/campaigns/campaign_settings/RoiSettingsCTA'
import ChildCampaignCTA from '../../../components/adminTools/campaigns/campaign_settings/create/ChildCampaignCTA'
import PCampaignDetails from '../../../components/adminTools/campaigns/campaign_settings/PCampaignDetails'
import AdvertiserAssignment from '../../../components/adminTools/campaigns/campaign_settings/AdvertiserAssignment'

const EditCampaignPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [allAdvertisers, setAllAdvertisers] = useState<IAdvertiserInDb[]>([])
  const [allCampaignTypes, setAllCampaignTypes] = useState<ICampaignTypeInDb[]>([])
  const [createdCampaigns, setCreatedCampaigns] = useState<ICampaignInDb[]>([] as any)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const { setError } = useContext(AppContext)
  const [campaignId, setCampaignId] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('campaign_id')
    if (items) {
      sessionStorage.setItem('campaign_id', items)
      localStorage.removeItem('campaign_id')
    }
    const getSessionItems = sessionStorage.getItem('campaign_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setCampaignId(parseItems)
      }
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllAdvertisers = async () => {
      const response = await IMIApi.GetAllAdvertisers(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAllAdvertisers(response.data.data)
      return setLoading(false)
    }
    fetchAllAdvertisers()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    if (campaignId > 0) {
      const fetchCampaignById = async () => {
        const response = await IMIApi.GetCampaignById(campaignId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaignInDb(response.data)
        return setLoading(false)
      }
      fetchCampaignById()
    }
    return () => abortController.abort()
  }, [setError, setCampaignInDb, campaignId])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllCampaignTypes = async () => {
      const response = await IMIApi.GetCampaignTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAllCampaignTypes(response.data.data)
      return setLoading(false)
    }
    fetchAllCampaignTypes()
    return () => abortController.abort()
  }, [setError])

  // Get campaigns that aren't SEM because they
  // should be the only ones with an enC created campaign
  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllCreatedCampaigns = async () => {
      const response = await IMIApi.GetAllCampaigns(false, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const error = JSON.parse(response)
        setError({ errorMsg: error.detail })
        return setLoading(false)
      }
      // TODO: loop through maybe, but SEM is type 2 now
      // and only one without a "created" campaign by enC.
      const created = response.data.data.filter((campaign) => (
        campaign.type.id !== 2
      ))
      setCreatedCampaigns(created)
      return setLoading(false)
    }
    fetchAllCreatedCampaigns()
    return () => abortController.abort()
  }, [setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <>
            <EditCampaign
              allAdvertisers={allAdvertisers}
              allCampaignTypes={allCampaignTypes}
              isLoading={loading}
              edit
            />
            {campaignInDb?.id > 0 && campaignInDb?.type?.id === 2 && (
              <AdvertiserAssignment
                allAdvertisers={allAdvertisers}
                createdCampaigns={createdCampaigns}
                isLoading={loading}
              />
            )}
            {((campaignInDb?.pcampaign?.length > 0)) && (
              <PCampaignDetails
                allCampaignTypes={allCampaignTypes}
              />
            )}
            <ChildCampaignCTA />
            {campaignInDb?.id > 0 && campaignInDb?.type?.id === 2 && (
              <>
                <SetCampaignStatus />
                <UpliftCTA />
                <CallSettingsCTA />
                <RoiSettingsCTA />
              </>
            )}
          </>
        )}
      />
    </Padding>
  )
}
export default EditCampaignPage
