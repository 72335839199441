import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { UserContext } from '../../../../contexts/UserContext'
import Content from '../../../layouts/Content'
import ToolTip from '../../../ToolTip'
import {
  IBrandingInDb,
  IUserUpdate,
} from '../../../../api/imi/interfaces'
import AccountUserDashboardBrandingForm from '../../../form/accounts/AccountUserDashboardBrandingForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import LoadingCheck from '../../../../hoc/LoadingCheck'
import { AppContext } from '../../../../contexts/AppContext'
import LoadingSpinner from '../../../LoadingSpinner'

interface IBranding {
  id: number
  name: string
}

const AddDashboardBranding:React.FC = () => {
  const { userInDb, setUserInDb } = useContext(UserContext)
  const [branding, setBranding] = useState<IBranding[]>([])
  const [brandingList, setBrandingList] = useState<IBranding[]>([{ id: 0, name: '' }])
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)

  const methods = useForm<IUserUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    delayError: undefined,
    defaultValues: useMemo(() => ({
      id: userInDb.id,
      white_label_id: userInDb.white_label_id,
      branding: userInDb.white_label_id > 1,
    }), [userInDb]),
  })

  useEffect(() => {
    methods.reset({ ...userInDb, branding: userInDb.white_label_id > 1 }, { keepDefaultValues: true })
  }, [userInDb, methods])

  const fetchBranding = useCallback(async (signal) => {
    const response = await IMIApi.GetAllBranding(signal)
    if (!response) {
      return
    }
    if (response.status !== 200) {
      const parse = JSON.parse(response)
      setError({ errorMsg: parse.detail })
      return setLoading(false)
    }
    const brandingData = response.data.data.map((d: IBrandingInDb) => (
      {
        id: d.id,
        name: d.email_template.name,
      }
    ))
    setBranding([...brandingData])
    setBrandingList([...brandingData.filter((data) => (data.id !== 1))])
    return setLoading(false)
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    fetchBranding(abortController.signal)
    return () => abortController.abort()
  }, [fetchBranding])

  const cancel = () => {
    methods.reset({ ...userInDb, branding: userInDb.white_label_id > 1 }, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const editUser = () => {
    setShowButton(true)
  }

  const onSubmit: SubmitHandler<IUserUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateUserById(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setUserInDb({ ...userInDb, white_label_id: data.white_label_id })
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  return (
    <Content
      title="Dashboard Branding"
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="white-label-info"
        >
          As a benefit to our partners, we offer them a version of our dashboard that uses their branding only. Users connected to a specific brand will see that branding when they log in.
          <br />
          <br />
          If this user is associated with a partner agency, choose “yes,” and then select the partner’s brand. By choosing a dashboard brand, you’ll also be automatically assigning a partner-branded version of the email template.
          <br />
          <br />
          For all other users, keep the default “no” option, which will show enCOMPASS branding.
        </ToolTip>
      )}
    >
      {loading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <AccountUserDashboardBrandingForm
              currentUser={userInDb}
              showButton={showButton}
              errors={methods.formState.errors}
              brandingData={brandingList}
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              // || !methods.formState.dirtyFields.white_label_id || userInDb.white_label_id !== 1
              disabled={!methods.formState.isValid || !methods.formState.isDirty || !methods.formState.dirtyFields.branding || userInDb.id <= 0}
              disabledCancel={!methods.formState.isDirty || !methods.formState.dirtyFields.branding}
              showButton={showButton}
              onClickCancel={cancel}
              onClickEdit={editUser}
              btnText={btnText}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default LoadingCheck(AddDashboardBranding)
