// NOTE: maybe should change this to RoiSettingsPage?

import { useEffect, useContext, useState } from 'react'
import { IRoiMultiResponse } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import { IMIApi } from '../../../api/imi/api'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import RoiSettings from '../../../components/adminTools/campaigns/roi/RoiSettings'

const RoiPage:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [assignedCampaigns, setAssignedCampaigns] = useState<IRoiMultiResponse[]>([] as any)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAssignedCampaigns = async () => {
      const response = await IMIApi.GetAllCampaignRois(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      // Concatted name field for sorting (in child component).
      for (let i = 0; i < response.data.data.length; i += 1) {
        response.data.data[i].names = `${response.data.data[i].name} ${response.data.data[i].secondary_name} ${response.data.data[i].specific}`
      }
      setAssignedCampaigns(response.data.data)
      return setLoading(false)
    }
    fetchAssignedCampaigns()
    return () => abortController.abort()
  }, [setError, refresh])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <RoiSettings
            assignedCampaigns={assignedCampaigns}
            refresh={setRefresh}
            isLoading={loading}
          />
        )}
      />
    </Padding>
  )
}

export default RoiPage
