import Content from '../layouts/Content'
import NavLinkWrapper from '../navigation/NavLink'
import BadgeAlert from '../badge/BadgeAlert'

const toolbar = [
  { title: 'Campaign Central', url: '/campaign-central', current: true },
]
interface Props {
  active: number
  isLoading: boolean
}

const ActiveCampaignsToolbar:React.FC<Props> = ({
  active,
  isLoading,
}) => (
  <Content
    title="Active Campaigns"
    badge={isLoading ? <BadgeAlert alert="..." status="running" /> : <BadgeAlert alert={active} status="running" />}
  >
    <ul>
      {toolbar.map((item) => (
        <li key={item.url}>
          <NavLinkWrapper
            variant="menu"
            to={item.url}
          >
            {item.title}
          </NavLinkWrapper>
        </li>
      ))}
    </ul>
  </Content>
)

export default ActiveCampaignsToolbar
