import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ENV } from '../env'
import { AppContext } from '../contexts/AppContext'
import { IRecoverPasswordStart, IBrandingLogin } from '../api/imi/interfaces'
import { IMIApi } from '../api/imi/api'
import LoadingSpinner from '../components/LoadingSpinner'
import FormButtonGroup from '../components/button/FormButtonGroup'
import Input from '../components/form/Input'

const buttonDefault = 'Get Recovery Email'

function debounce(func: Function, timeout = 750) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    // @ts-ignore
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

type Form = {
  email: string
}

const RequestUsernamePage:React.FC = () => {
  const {
    isSignedIn,
    user,
  } = useContext(AppContext)
  const [serverError, setServerError] = useState<string>('')
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: {
      errors,
      isValid,
      isDirty,
    },
  } = useForm<Form>({
    shouldFocusError: true,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const [btnText, setBtnText] = useState(buttonDefault)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const onSubmit: SubmitHandler<Form> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Submitting...')
    const submitResponse = await IMIApi.requestUsernameEmail(data.email)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          return setBtnText(buttonDefault)
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setServerError(parse.detail)
        return setBtnText(buttonDefault)
      }
    }, 1000)
    setIsButtonLoading(false)
    setDisabled(true)
    return setBtnText('Success!')
  }

  return (
    <div className="flex items-center justify-between min-h-screen bg-login">
      <div className="w-full px-6 min-h-screen bg-white flex flex-1 items-center z-10">
        {loading ? <LoadingSpinner size="lg" variant="primary" /> : (
          <form onSubmit={handleSubmit(onSubmit)} className="md:pr-12 pt-6 pb-8 mb-4 flex-1 max-w-half-site my-0 mx-auto">
            <div
              className="heading-1 text-default block text-left py-2 mb-8 border-b border-grey-dark border-dotted md:max-w-login-form"
            >
              <h1 className="text-default">Forgot User name?</h1>
            </div>
            {/* <h2>Password Recovery</h2> */}
            <p className="small mb-4 md:max-w-login-form w-full">If you have forgotten your user name, please reach out to your account representative.</p>
            {/* <p className="small mb-4 md:max-w-login-form w-full">If you encounter another problem logging in or do not receive an email, please contact your account representative.</p> */}
            {/* <div className="mb-4 md:max-w-login-form w-full">
              <Input
                type="text"
                label="Your email"
                disabled={disabled}
                width="w-full"
                {...register('email', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'The email you have entered is not a valid email address',
                  },
                  onChange: debounce(async () => {
                    await trigger('email')
                  }, 750),
                })}
                error={errors.email && <span className="form-error">{errors.email?.message}</span>}
              />
            </div> */}
            <p className="text-red">{serverError}</p>
            <div className="">
              {/* <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabledCancel={!isDirty}
                disabled={!isValid || !isDirty}
                fullWidth
                btnText={btnText}
                showButton
              /> */}
              <a
                className="text-primary global-animation hover:text-secondary block font-bold font-base text-xxs"
                href="/"
              >
                Return to Log In ›
              </a>
            </div>
          </form>
        )}
      </div>
      <div className="hidden md:block md:flex-1">
        <img src="images/dashboard-data-login-graph.png" alt="graph" className="hidden md:block xl:max-w-login-image relative p-24 ml-auto mr-auto" />
      </div>
    </div>
  )
}

export default RequestUsernamePage
