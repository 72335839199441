import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import {
  IAssignPCampaignToCampaign,
  ICampaignInDb,
  ICampaignTypeInDb,
  IPCampaign,
  IPCampaignUpdate,
} from '../../../../api/imi/interfaces'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import FormButtonGroup from '../../../button/FormButtonGroup'
import Pagination from '../../../Pagination'
import ToolTip from '../../../ToolTip'
import AssignCampaignForm from '../../../form/campaigns/campaign_settings/AssignCampaignForm'
import LoadingSpinner from '../../../LoadingSpinner'
import EditPCampaignForm from '../../../form/campaigns/campaign_settings/EditPCampaignForm'

interface Props {
  allCampaignTypes: ICampaignTypeInDb[]
}

const PCampaignDetails:React.FC<Props> = ({
  allCampaignTypes,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [pageLimit, setPageLimit] = useState(7)
  const formRef = useRef<any>()

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (campaignInDb?.pcampaign.length - pageLimit))
  }

  const handleCollapse = () => {
    setPageLimit(7)

    if (formRef.current) {
      formRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const methods = useForm<IPCampaignUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      pcampaign: campaignInDb?.pcampaign?.map((p) => ({
        ...p,
        secondary_name: p.secondary_name ? p.secondary_name : '',
      })),
    }), [campaignInDb]),
  })

  useEffect(() => {
    methods.reset(
      {
        pcampaign: campaignInDb?.pcampaign?.map((p) => ({
          ...p,
          secondary_name: p.secondary_name ? p.secondary_name : '',
        })),
      },
    )
  }, [campaignInDb, methods])

  const cancel = () => {
    methods.reset({
      pcampaign: campaignInDb?.pcampaign,
    })
    setBtnText('Save')
    setShowButton(false)
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  const onRemove = async (pcampaign_id) => {
    const submitResponse = await IMIApi.AssignPCampaignToCampaign({
      pcampaign_id,
      campaign_id: 0,
    })

    if (submitResponse.status !== 200) {
      if (submitResponse.status === 0) {
        setError({ errorMsg: 'Internal server error.' })
        return
      }
      const parse = JSON.parse(submitResponse)
      setError({ errorMsg: parse.detail })
      return
    }

    setCampaignInDb({
      ...campaignInDb,
      pcampaign: campaignInDb.pcampaign.filter((pc) => pc.id !== pcampaign_id),
    })
  }

  // NOTE: take the campaignId and add the advertiser ID
  const onSubmit: SubmitHandler<IPCampaignUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    
    const submitResponse = await IMIApi.UpdatePCampaigns(data.pcampaign.map((pc) => ({
      pcampaign_id: pc.id,
      specific_id: pc.specific_id,
      secondary_name: pc.secondary_name,
    })))

    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setCampaignInDb({
        ...campaignInDb,
        pcampaign: data.pcampaign,
      })
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }
  
  const edit = () => {
    setShowButton(true)
  }

  return (
    <>
      <div ref={formRef} />
      <Content
        title="child campaign details"
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="child-campaigns"
          >
            To delete a “child” campaign, click on the trash icon. A deleted child campaign will return to the “unassigned campaigns” list and revert back to its original settings.
          </ToolTip>
        )}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <EditPCampaignForm
              onRemove={onRemove}
              pageLimit={pageLimit}
              errors={methods.formState.errors}
              showButton={showButton}
              campaignTypes={allCampaignTypes}
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              // disabled={!methods.formState.isDirty || !methods.formState.isValid || campaignInDb.id <= 0}
              disabled={!methods.formState.isDirty || !methods.formState.isValid}
              disabledCancel={!methods.formState.isDirty}
              onClickCancel={cancel}
              onClickEdit={edit}
              btnText={btnText}
              showButton={showButton}
            />
            {campaignInDb?.pcampaign.length > 7 && (
              <Pagination
                onClick={handleLoadMore}
                pageLimit={pageLimit}
                adminLimit={7}
                data={campaignInDb?.pcampaign}
                offset={10}
                onClickCollapse={handleCollapse}
              />
            )}
          </form>
        </FormProvider>
      </Content>
    </>
  )
}

export default PCampaignDetails
