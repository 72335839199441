import {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import Icon from '../../../Icon'
import LinkWrapper from '../../../Links'
import EditMenu from '../../EditMenu'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Search from '../../../form/Search'
import { handleFilters } from '../../../../helpers/utils'
import CreateButtons from '../../../button/CreateButtons'
import Content from '../../../layouts/Content'
import { ICampaignTypeInDb } from '../../../../api/imi/interfaces'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import { campaignTypesMenu, campaignSettingsStyles } from '../campaign_settings/Data'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  campaignTypes: ICampaignTypeInDb []
  loading: boolean
}

const CampaignTypes:React.FC<Props> = ({ campaignTypes, loading }) => {
  const { setError } = useContext(AppContext)
  const [errorMsg, setErrorMsg] = useState('There are no campaign types found.')
  const [filteredCampaignTypes, setFilteredCampaignTypes] = useState<ICampaignTypeInDb []>(campaignTypes)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })
  const getFieldByPath = (obj: any, path: string, delimiter = '.') => {
    const paths = path.split(delimiter)
    let field = obj
    // eslint-disable-next-line no-restricted-syntax
    for (const p of paths) {
      field = field[p]
    }
    return field?.toLowerCase()
  }
  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? filteredCampaignTypes?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1)) : filteredCampaignTypes?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1))
    return { values }
  }

  // main filter to check username, email address, *advertisers*
  // based on status of user
  const filters = useCallback(() => {
    const filterSearchValue = (typeData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = typeData.name.toLowerCase().includes(splitItems)
      const shortNameFilter = typeData.short_name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => typeData.name.toLowerCase().includes(si))
        const shortNameFil = splitItems.some((si) => typeData.short_name.toLowerCase().includes(si))

        return nameFil || shortNameFil
      }
      return nameFilter || shortNameFilter
    }

    const filterData = campaignTypes?.filter((type) => filterSearchValue(type))
    setFilteredCampaignTypes(filterData)
  }, [selectedFilters, campaignTypes])

  useEffect(() => {
    filters()
  }, [campaignTypes, filters])

  const getSpecifics = (specifics) => {
    const listSpecifics = specifics.map((specific, index) => {
      if (index !== specifics.length - 1) {
        return `${specific.name}; `
      }
      return specific.name
    })
    return listSpecifics
  }

  return (
    <>
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="max-w-none w-full"
            // className="mr-3 w-full"
          />
          {/* <CreateButtons
            createText="Campaign Type"
            path="/admin-tools/campaign-types/create-campaign-type"
          /> */}
        </div>
      </div>
      <Content
        title="types"
        padding={false}
      >
        {loading ? <LoadingSpinner size="md" variant="primary" /> : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-3"
              mdGrid="md:grid-cols-3"
            >
              {campaignTypesMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && campaignSettingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredCampaignTypes?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filteredCampaignTypes?.map((type) => (
                  <HorizontalGrid
                    key={type.id}
                    smGrid="grid-cols-3"
                    mdGrid="md:grid-cols-3"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      {type.name}
                      {/* <LinkWrapper
                        variant="defaultSm"
                        key={type.id}
                        href={{
                          pathname: '/admin-tools/campaign-types/edit-campaign-type',
                          state: {
                            id: type.id,
                          },
                        }}
                      >
                        {type.name}
                      </LinkWrapper> */}
                    </p>
                    <p className="text-xxs text-gray-500 break-all pr-2">
                      {type.short_name}
                    </p>
                    <p className="text-xxs text-gray-500 break-word pr-4">
                      {getSpecifics(type.specifics)}
                    </p>
                    {/* <EditMenu
                      text="Edit Campaign Type"
                      id={type.id}
                      pathname="/admin-tools/campaign-types/edit-campaign-type"
                    /> */}
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default CampaignTypes
