import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext'
import { getDefaultHomePage } from '../helpers/utils'

interface Props {
  component: any
  userTypeIds?: number []
  [x:string]: any
}

const ProtectedRoute : React.FC<Props> = ({
  component: Component,
  userTypeIds,
  ...rest
}) => {
  const { user, isSignedIn } = useContext(AppContext)
  // const isLoggedIn = useIsLoggedIn()

  if (!isSignedIn()) {
    return <></>
  }
  // TODO: Refactor to userTypes constant.
  // 1: admin
  // 2: team viewer
  // 3: call manager
  // 4: call analyst
  // 5: partner
  // 6 client
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn()) {
          if ((!userTypeIds || userTypeIds.includes(user.user_type_id))) {
            return <Component {...props} />
          }
          return (
            <Redirect
              to={getDefaultHomePage(user)}
            />
          )
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }}
    />
  )
}

export default ProtectedRoute

ProtectedRoute.defaultProps = {
  userTypeIds: undefined,
}
