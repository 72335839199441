export const PAGE_MARGIN = 40
export const PAGE_MARGIN_BOTTOM = 50
export const BORDER_RADIUS = 4
export const BORDER_SIZE = 1
export const BADGE_BORDER_RADIUS = 10
export const BADGE_MARGIN = 7
export const BAR_BORDER_SIZE = 10
export const CONTAINER_MARGIN = 15
export const TOTALS_CONTAINER_MARGIN = 18
export const SECTION_MARGIN = 12
export const PAGE_HEADER = 35
export const PAGE_MAST = 200
export const PAGE_MAST_MARGIN = 50
export const TOTALS_CONTAINER_WIDTH = 1 / 3
export const TOTALS_CONTAINER_HEIGHT = 70
export const CONTAINER_HEADER = 40
export const ICON_SIZE = 40
export const ICON_SIZE_SMALL = 20
export const TABLE_ROW = 40
export const FONT_SIZE_NORMAL = 11
export const FONT_SIZE_LARGE = 17
export const LINE_SPACE = 4
