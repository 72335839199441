/* This example requires Tailwind CSS v2.0+ */
import { useState, useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { AppContext } from '../../contexts/AppContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import { PdfContext } from '../../contexts/PdfContext'
import Navigation from './Navigation'
import { getDefaultHomePage } from '../../helpers/utils'
import { IBrandingDetailInDb } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'

const NavBar = () => {
  const { user } = useContext(AppContext)
  const { setColors, colors } = useContext(ThemeContext)
  const { setPdfImages, setPdfTheme, setUserType } = useContext(PdfContext)
  const [branding, setBranding] = useState<IBrandingDetailInDb>({} as any)
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    setUserType(user.user_type_id)
    if (user?.white_label?.color_primary
      && user?.white_label?.color_primary_dark
      && user?.white_label?.color_secondary
      && user?.white_label?.color_tertiary
    ) {
      setColors({
        primary: user.white_label.color_primary,
        primaryDark: user.white_label.color_primary_dark,
        secondary: user.white_label.color_secondary,
        tertiary: user.white_label.color_tertiary,
      })
    }
  }, [
    setColors,
    setUserType,
    user.user_type_id,
    user.white_label.color_primary,
    user.white_label.color_primary_dark,
    user.white_label.color_secondary,
    user.white_label.color_tertiary,
  ])

  useEffect(() => {
    setPdfTheme({
      primary: colors.primary,
      primaryDark: colors.primaryDark,
      secondary: colors.secondary,
      tertiary: colors.tertiary,
    })
  }, [
    colors,
    setPdfTheme,
  ])

  useEffect(() => {
    if (user.white_label_id < 2) {
      setPdfImages(
        `${window.location.protocol}//${window.location.hostname}/images/logos/interactive-marketing-intelligence-logo.png`,
        `${window.location.protocol}//${window.location.hostname}/images/logos/encompass-advertising-agency-logo.png`,
      )
    } else if (user?.white_label?.email_template?.logo_url) {
      setPdfImages(user.white_label.email_template.logo_url)
    }
  }, [setPdfImages, user.white_label.email_template.logo_url, user.white_label_id])

  return (
    <>
      {user.username && (
        <Disclosure as="nav" className="bg-white shadow fixed sm:relative w-full z-100">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto">
                <div className="relative flex items-center justify-between h-16 px-6">
                  <div className="block max-w-full">
                    <div className="pr-2">
                      {loading ? (
                        <LoadingSpinner variant="primary" size="md" />
                      ) : (
                        <>
                          <NavLink
                            to={getDefaultHomePage(user).pathname}
                            className="md:flex items-center"
                          >
                            {/* TODO to update image if not other partner */}
                            {user.white_label_id < 2 ? (
                              <>
                                <img
                                  className="hidden md:block max-w-btn-md w-full"
                                  src="/images/logos/interactive-marketing-intelligence-with-icon.svg"
                                  alt={branding?.url_displayed}
                                />
                                {/* <span className="border border-r-2 border-grey-light pr-6 mr-6" /> */}
                                <img
                                  className="hidden md:block max-w-btn-md w-full border-l-2 border-grey-light pl-6 ml-6"
                                  src="/images/logos/encompass-advertising-agency-logo.svg"
                                  alt={branding?.url_displayed}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  className="block max-w-btn-md w-auto max-h-navbar"
                                  src={user.white_label.email_template.logo_url}
                                  alt={user.white_label.email_template.name}
                                />
                              </>
                            )}
                          </NavLink>
                          {user.white_label_id < 2 && (
                            <NavLink to="/advertiser-dashboard" className="flex items-center md:hidden">
                              <img
                                className="block max-w-btn-md max-h-logo w-full"
                                src="/images/logos/imi-icon.svg"
                                alt={branding?.url_displayed}
                              />
                            </NavLink>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="relative inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {/* Menu Dropdown */}
                    <Navigation />
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      )}
    </>
  )
}

export default NavBar
