import {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IMIApi } from '../../../../api/imi/api'
import {
  accountsUserSettingsMenu,
  accountsUserSettingsStatus,
  accountsSettingsStyles,
} from './Data'
import LoadingCheck from '../../../../hoc/LoadingCheck'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import Content from '../../../layouts/Content'
import Icon from '../../../Icon'
import Search from '../../../form/Search'
import LinkWrapper from '../../../Links'
import EditMenu from '../../EditMenu'
import Dropdown from '../../../form/Dropdown'
import BadgeAlert from '../../../badge/BadgeAlert'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import CreateButtons from '../../../button/CreateButtons'
import { handleFilters, getFieldByPath } from '../../../../helpers/utils'
import Modal from '../../../modal'
import { IUserInDb } from '../../../../api/imi/interfaces'
import { AppContext } from '../../../../contexts/AppContext'

// TODO: fix double clicking on account type sorting
// TODO: advertiser filter

interface Props {
  users: IUserInDb[]
  refresh: Dispatch<SetStateAction<boolean>>
}

const UserSettings:React.FC<Props> = ({ users, refresh }) => {
  const [showModal, setShowModal] = useState(false)
  const [btnText, setBtnText] = useState('Change')
  const [cancelText, setCancelText] = useState('Cancel')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState<IUserInDb[]>()
  const [errorMsg, setErrorMsg] = useState('There are no users found.')
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState<string>('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [test, setTest] = useState<boolean>(false)
  const [serverError, setServerError] = useState<any>()
  const [currentUser, setCurrentUser] = useState<IUserInDb>()
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })
  const { setError } = useContext(AppContext)

  const getAllAdvertisers = (advertisers) => (
    advertisers.map((advertiser) => (advertiser.name).toLowerCase())
  )
  // main filter to check username, email address, *advertisers*
  // based on status of user
  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const userStatus = status.is_active
      if (userStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }

    const filterSearchValue = (userData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const usernameFilter = userData.username.toLowerCase().includes(splitItems)
      const emailFilter = userData.email.toLowerCase().includes(splitItems)
      const filterAdvertiser = getAllAdvertisers(userData.advertiser_set).find((name) => name.toLowerCase().includes(splitItems))

      if (splitItems.length > 1) {
        const usernameFil = splitItems.some((si) => userData.username.toLowerCase().includes(si))
        const emailFil = splitItems.some((si) => userData.email.toLowerCase().includes(si))
        const filterAdv = splitItems.some((si) => getAllAdvertisers(userData.advertiser_set).find((name) => name.toLowerCase().includes(si)))

        return usernameFil || emailFil || filterAdv
      }
      return usernameFilter || emailFilter || filterAdvertiser
    }

    const filterData = users?.filter((user) => filterSearchValue(user) && filterStatus(user))
    setFilteredUsers(filterData.sort((a, b) => (a.username > b.username ? 1 : -1)))
  }, [selectedFilters, users])

  useEffect(() => {
    filters()
  }, [filters])

  // TODO: fix this sorting error
  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? filteredUsers?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1)) : filteredUsers?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1))
    return { values }
  }

  const modal = (user) => {
    setCancelText('Cancel')
    setBtnText('Change')
    setCurrentUser(user)
    setShowModal(true)
  }

  const changeStatus = async (userStatus, data) => {
    const formData = {
      ...data,
      is_active: !userStatus,
    }
    setIsButtonLoading(true)
    setCancelText('')
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateUserById(formData)
    setServerError(submitResponse)
    setTimeout(() => {
      if (submitResponse.status === 200) {
        setIsButtonLoading(false)
        refresh(true)
        return setBtnText('Saved!')
      }
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      return setBtnText('Save')
    }, 1000)
    setTimeout(() => {
      setShowModal(false)
    }, 2000)
    refresh(false)
  }

  const showLength = () => (
    setTest(!test)
  )
  // TODO: fix function to have the +N at the end if more than
  // six advertisers are assigned to user.
  const getAdvertisers = (advertisers) => {
    const listAdvertisers = advertisers.map((advertiser, index) => {
      if (index !== advertisers.length - 1) {
        return `${advertiser.name}; `
      }
      return advertiser.name
    })
    return (
      <span>
        {(!test && advertisers.length > 6) ? (
          <>
            {listAdvertisers.slice(0, 6)}
            <LinkWrapper
              variant="defaultSm"
              href="#"
              onClick={showLength}
            >
              {(!test && advertisers.length > 6) ? (
                ` +${advertisers.length - 6}`
              ) : (
                listAdvertisers
              )}

            </LinkWrapper>
          </>
        ) : (
          listAdvertisers
        )}
      </span>
    )
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('user_in_db', JSON.stringify(id))
  }

  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Change"
        body={(
          `Change user ${currentUser?.username}'s status to
            ${currentUser?.is_active ? 'inactive' : 'active'}?`
        )}
        onSubmit={() => changeStatus(currentUser?.is_active, currentUser)}
        onClose={() => setShowModal(false)}
        closeText={cancelText}
        submitText={btnText}
      />
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="User"
            path="/admin-tools/user-settings/create-user"
          />
        </div>
      </div>
      <Content
        title={(
          selectedFilters.status === 0 ? 'Active Users' : 'Inactive Users'
        )}
        padding={false}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={accountsUserSettingsStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
        badge={(
          <BadgeAlert
            alert={filteredUsers?.length || 0}
            status={selectedFilters.status === 0}
          />
        )}
      >
        <HorizontalGrid
          smGrid="grid-cols-3"
          mdGrid="md:grid-cols-users"
        >
          {accountsUserSettingsMenu.map((item) => (
            item.sort ? (
              <span
                aria-hidden="true"
                onClick={() => columnSortingInfo(item.fieldName)}
                key={item.name}
                className={(
                  `flex break-word group hover:text-secondary hover:cursor-pointer
                  ${item.mobile && accountsSettingsStyles.desktop}`
                )}
              >
                {item.name}
                <Icon
                  size="xxs"
                  className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                >
                  <ChevronUpIcon
                    className="-mb-1 text-default group-hover:text-secondary"
                  />
                  <ChevronDownIcon
                    className="text-default group-hover:text-secondary"
                  />
                </Icon>
              </span>
            ) : <span key={item.name}>{item.name}</span>
          ))}
        </HorizontalGrid>
        <div className="items-center">
          {filteredUsers?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
            filteredUsers?.map((user) => (
              <HorizontalGrid
                key={user.id}
                smGrid="grid-cols-3"
                mdGrid="md:grid-cols-users"
                backgroundColor
              >
                <p className="text-xxs pr-3 break-all">
                  <LinkWrapper
                    variant="defaultSm"
                    key={user.id}
                    onClick={() => handleLocalStorage(user.id)}
                    href={{
                      pathname: '/admin-tools/user-settings/edit-user',
                    }}
                  >
                    {user.username}
                  </LinkWrapper>
                </p>
                <p className="text-xxs break-all pr-3">
                  {user.email}
                </p>
                <p className="text-xxs break-word pr-6">
                  {user.user_type_id >= 3 ? (
                    getAdvertisers(user.advertiser_set)
                  ) : '-'}
                </p>
                <p className="hidden md:block text-xxs">
                  {user.user_type.name}
                </p>
                <EditMenu
                  text="Edit User"
                  id={user.id}
                  pathname="/admin-tools/user-settings/edit-user"
                  status={user.is_active}
                  changeStatus
                  onClickEdit={() => handleLocalStorage(user.id)}
                  onClick={() => modal(user)}
                />
              </HorizontalGrid>
            ))
          )}
        </div>
      </Content>
    </>
  )
}

export default LoadingCheck(UserSettings)
