import {
  useEffect, useState, useContext, useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import variablePie from 'highcharts/modules/variable-pie'
import ToolTip from '../ToolTip'
import { commaNumber, getFileDescriptor } from '../../helpers/utils'
import Content from '../layouts/Content'
import Icon from '../Icon'
import SidebarRight from '../layouts/SidebarRight'
import HorizontalGrid from '../layouts/HorizontalGrid'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'
import BadgeDot from '../badge/BadgeDot'
import { IDisplayDetail } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { ReportContext } from '../../contexts/ReportContext'
import { AppContext } from '../../contexts/AppContext'
import { IMIApi } from '../../api/imi/api'
import { ThemeContext } from '../../contexts/ThemeContext'

// TODO: how to handle only 1 item returned? breaks map not sure why

const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute right-6',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// "table" header for daily report details
const title = [
  {
    fieldName: 'keyword',
    name: 'Keyword',
    mobile: false,
  },
  {
    fieldName: 'impressions',
    name: 'Impressions',
    abbreviation: 'Impr',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'clicks',
    name: 'Clicks',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'ctr',
    name: 'Click-Through Rate (CTR)',
    abbreviation: 'CTR',
    mobile: false,
    sort: true,
  },
]

interface Props {
  data: IDisplayDetail
}

const DisplayDeviceTypeReport: React.FC<Props> = ({ data }) => {
  const { colors } = useContext(ThemeContext)
  const { parameters } = useContext(ReportContext)
  const { setError } = useContext(AppContext)
  const [creatingCSV, setCreatingCSV] = useState<boolean>(false)
  // data coming in from SEM report page
  const [impressions, setImpressions] = useState<any>([] as any)
  const [loading, setLoading] = useState<boolean>(true)
  const [chart, setChart] = useState<any>()
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')

  const handleDownload = useCallback(() => {
    const fetchActivityBreakdownDownload = async () => {
      setCreatingCSV(true)
      const response = await IMIApi.GetDeviceTypeBreakdownDownload({
        id: data.campaign.id,
        start_date: data.report_start_date,
        end_date: data.query_end_date,
      })
      if (response.status !== 200) {
        setCreatingCSV(false)
        const parse = JSON?.parse(response)
        return setError({ errorMsg: parse?.detail })
      }
      if (!response.status) {
        setCreatingCSV(false)
        return setError({ errorMsg: 'Internal server error' })
      }

      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      const fileDesc = getFileDescriptor(response.headers)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(
        new Blob([response.data], { type: fileDesc.contentType }),
      )

      // Use download attribute to set desired file name
      a.setAttribute('download', fileDesc.filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
      return setCreatingCSV(false)
    }
    fetchActivityBreakdownDownload()
  }, [data, setCreatingCSV, setError])

  useEffect(() => {
    if (parameters.end_date) {
      const endDateToString = new Date(parameters.end_date)
      const today = new Date(new Date().setUTCHours(0, 0, 0, 0))
      if (today > endDateToString) {
        return setNoDataMessage('')
      }
    }
  }, [parameters.end_date])

  variablePie(Highcharts)

  Highcharts.setOptions({
    lang: {
      printChart: 'Aaaa',
      thousandsSep: ',',
    },
  })
  const buildChart = useCallback((seriesData: any []) => ({
    chart: {
      type: 'pie',
      height: 300,
    },
    title: {
      text: '<span style="font-family: Nunito Sans; font-size: 14px; font-weight: 600;">Impressions</span>',
      y: 160,
      float: true,
    },
    tooltip: {
      useHtml: true,
      headerFormat: '<br>',
      pointFormat: `<span style="color:{point.color}; font-size: 22px; vertical-align:middle;">\u25CF</span>&nbsp;<span style="color: #ffffff; font-family: Nunito Sans;font-size: 14px; font-weight: bold; padding: 10px 5px 10px 5px; display: block;">{point.name}</span><br y="28"/>
                    <span style="color: #fff; font-family: Heebo; padding: 10px 0px 10px 5px; margin: 10px">Impressions: <b>{point.y}</b></span><br y="48"/>
                    <span style="color: #fff; font-family: Heebo; padding: 10px 0px 10px 5px;">% of Total: <b>{point.totalPercent}%</b></span><br/>`,
    },
    series: [{
      borderWidth: 2,
      minPointSize: 10,
      innerSize: '75%',
      slicedOffset: 0,
      size: '100%',
      zMin: 0,
      name: 'Device Types',
      dataLabels: {
        enabled: false,
        distance: '-13',
        useHtml: true,
        format: '{point.totalPercent}%',
        crop: true,
        allowOverlap: false,
        connectorWidth: 0,
        style: {
          textOutline: 'none',
          fontFamily: 'Heebo',
          fontSize: '11px',
          color: '#fff',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      point: {
        /* eslint-disable */
        events: {
          mouseOver: function () {
            // @ts-ignore
            this.graphic.attr({
              // @ts-ignore
              r: this.shapeArgs.r + 10
            })
          },
          mouseOut: function () {
            // @ts-ignore
            this.graphic.attr({
              // @ts-ignore
              r: this.shapeArgs.r
            })
          },
        },
        /* eslint-enable */
      },
      states: {
        hover: {
          brightness: 0,
          opacity: 1,
          borderWidth: 0,
          halo: {
            size: 0,
            // opacity: 1,
            // stroke: 1,
          },
        },
        inactive: {
          opacity: 0.7,
        },
      },
      data: seriesData,
    }],
  }), [])

  useEffect(() => {
    setImpressions(data?.device_impressions)
    // @ts-ignore
  }, [data])

  useEffect(() => {
    if (impressions?.series?.length) {
      setLoading(false)
      const colorMap = {
        Desktop: colors.primary,
        Mobile: colors.secondary,
        Tablet: colors.tertiary,
        Others: '#85619b',
      }
      const totalImpressions = impressions?.callouts?.map((value) => (value.name === 'Total Impressions' ? value.value : 0))
      const seriesData = impressions.series.map((s) => ({
        name: s.y,
        y: s.x,
        totalPercent: Math.round((s.x / totalImpressions) * 100),
        color: colorMap[s.y],
      }))
      setChart(buildChart(seriesData))
    }
  }, [colors, buildChart, impressions, setChart])

  return (
    <Content
      title="device type"
      icon={(
        <div className="flex gap-3">
          <Icon>
            <Icon
              size="md"
              colors="text-primary"
              className="cursor-pointer group-hover:text-secondary mt-1.5"
            >
              <DownloadIcon
                className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                onClick={() => handleDownload()}
              />
              {creatingCSV && (
                <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                  <LoadingSpinner variant="primary" size="sm" noPadding />
                </span>
              )}
            </Icon>
          </Icon>
          <ToolTip
            tipId="devicetype"
            size="sm"
            position="block relative pr-0"
          >
            Due to user privacy settings, device type information may not be available for all impressions.
          </ToolTip>
        </div>
      )}
    >
      {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <div>
          {impressions?.series?.length > 0 ? (
            <div className="grid grid-cols-1 gap-4 items-start md:grid-cols-device-type md:gap-6 mb-6">
              <div className="-mt-6">
                <HighchartsReact
                  id="device_report"
                  highcharts={Highcharts}
                  options={chart}
                />
              </div>
              <div>
                <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                  <div className="min-w-0 text-left heading-5 font-semibold m-0">
                    <div
                      className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3"
                    >
                      <BadgeDot
                        label={impressions?.series[0]?.y}
                        variant="dot"
                        badgeBackground="dotOnly"
                        dotColor="bg-primary"
                      />
                      <span className="text-right">{commaNumber(impressions?.series[0]?.x)}</span>
                    </div>
                  </div>
                </div>
                <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                  <div className="min-w-0 text-left heading-5 font-semibold m-0">
                    <div
                      className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3"
                    >
                      <BadgeDot
                        label={impressions?.series[1]?.y}
                        variant="dot"
                        badgeBackground="dotOnly"
                        dotColor="bg-secondary"
                      />
                      <span className="text-right">{commaNumber(impressions?.series[1]?.x)}</span>
                    </div>
                  </div>
                </div>
                <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                  <div className="min-w-0 text-left heading-5 font-semibold m-0">
                    <div
                      className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3"
                    >
                      <BadgeDot
                        label={impressions?.series[2]?.y}
                        variant="dot"
                        badgeBackground="dotOnly"
                        dotColor="bg-tertiary"
                      />
                      <span className="text-right">{commaNumber(impressions?.series[2]?.x)}</span>
                    </div>
                  </div>
                </div>
                <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                  <div className="min-w-0 text-left heading-5 font-semibold m-0">
                    <div
                      className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3"
                    >
                      <BadgeDot
                        label={impressions?.series[3]?.y}
                        variant="dot"
                        badgeBackground="dotOnly"
                        dotColor="bg-purple"
                      />
                      <span className="text-right">{commaNumber(impressions?.series[3]?.x)}</span>
                    </div>
                  </div>
                </div>
                <div className="text-center pb-1">
                  <span
                    aria-hidden="true"
                    className="block px-3 py-2 rounded-lg text-xs bg-grey-lightest text-default mb-3 font-display"
                  >
                    Total Impressions:&nbsp;
                    <span
                      className="font-bold"
                    >
                      {commaNumber(impressions?.callouts?.map((value) => (value.name === 'Total Impressions' ? value.value : 0)))}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <p>{noDataMessage}</p>
          )}
        </div>
      )}
    </Content>
  )
}
export default DisplayDeviceTypeReport
