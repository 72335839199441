// Layout with sidebar on the left

interface Props {
  mainContent: React.ReactNode
  sidebarContent: React.ReactNode
}

const SidebarLeft: React.FC<Props> = ({
  mainContent,
  sidebarContent,
}) => (
  <section className="pb-8">
    <div className="max-w-3xl mx-autosm:px-6 md:max-w-7xl">
      {/* Main 3 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start md:grid-cols-4 md:gap-6">
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 order-1 md:order-none">
          <section aria-labelledby="section-2-title">
            <div>{sidebarContent}</div>
          </section>
        </div>
        {/* Right column */}
        <div className="grid grid-cols-1 gap-4 md:col-span-3">
          <section aria-labelledby="section-1-title">
            <div className="">
              {mainContent}
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
)

export default SidebarLeft
