import { useContext, useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { IMIApi } from '../../../api/imi/api'
import Input from '../Input'
import Dropdown from '../Dropdown'
import ToolTip from '../../ToolTip'
import { AppContext } from '../../../contexts/AppContext'

interface Props {
  showButton?: boolean
  errors: any
  branding?: any
  emailTemplate?: any
}

const AccountDashboardBrandingForm:React.FC<Props> = ({
  showButton,
  errors,
  emailTemplate,
  branding,
}) => {
  const methods = useFormContext()
  const watchEmailId = methods.watch('email_template_id')
  const [loading, setLoading] = useState(true)
  const [currentEmailTemplate, setEmailTemplate] = useState<any>()
  const [serverResponse, setServerResponse] = useState<any>()
  const { setError } = useContext(AppContext)

  useEffect(() => {
    const abortController = new AbortController()
    if (watchEmailId > 0) {
      const fetchEmailTemplateByID = async () => {
        const submitResponse = await IMIApi.GetEmailTemplateByID(watchEmailId, abortController.signal)
        if (!submitResponse) {
          return
        }
        const authenticated = submitResponse.status === 200
        setServerResponse(submitResponse)
        if (!authenticated) {
          const parse = JSON.parse(submitResponse)
          setError({ errorMsg: parse.detail })
          return setLoading(false)
        }
        setEmailTemplate(submitResponse.data)
        return setLoading(false)
      }
      fetchEmailTemplateByID()
    }
    methods.setValue('url_displayed', currentEmailTemplate?.displayed_url)
    return () => abortController.abort()
  }, [watchEmailId, methods, currentEmailTemplate?.displayed_url, setError])

  return (
    <div>
      {branding ? (
        <Input
          name="name"
          label="Name"
          type="text"
          disabled
          toolTipInfo="The name shown here is pulled from the associated email template. To update the name, go to “Email Templates,” and edit as needed."
          value={branding.email_template?.name}
          required={false}
        />
      ) : (
        emailTemplate?.length && (
          <Controller
            name="email_template_id"
            control={methods.control}
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                required
                hookFormField={field}
                label="Name"
                variant="admin"
                placeholder="Select..."
                value={methods.getValues('email_template_id')}
                list={emailTemplate}
                error={errors.email_template_id && <span className="form-error">This field is required</span>}
                toolTipInfo="The options shown here reflects the list of available email templates. If you can’t find what you’re looking for, you may still need to create the email template."
              />
            )}
          />
        )
      )}
      <Input
        {...methods.register('url_login', {
          required: true,
          pattern: {
            value: /^[a-z0-9-]*$/,
            // value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            message: 'Your login URL is not the correct format. Please use lowercase letters, dashes, and numbers only. Example, entering in [encompass] becomes: https://[encompass].imidashboard.com',
          },
        })}
        name="url_login"
        label="Login URL"
        type="text"
        className="50-split"
        toolTipInfo={(
          <span>
            Some partner agencies will request a custom login URL as shown in the Displayed URL field; if that&apos;s the case, we still must create our login URL path here.
            &nbsp;
            Please insert the prefix of URL with the partner name only. Example:
            &nbsp;
            <span className="break-all">
              &quot;[partnername]&quot; will turn into the URL &quot;https://[partnername].imidashboard.com&quot;.
            </span>
          </span>
        )}
        disabled={!showButton}
        error={errors.url_login && <span className="form-error">{errors.url_login.message || 'This field is required.'}</span>}
      />
      {(branding || watchEmailId) && (
        <>
          <Input
            {...methods.register('url_displayed', { required: false })}
            name="url_displayed"
            label="Displayed URL"
            type="text"
            toolTipInfo="The displayed URL information is pulled from the associated email template. To update the URL, go to “Email Templates,” and edit as needed."
            disabled
            required={false}
          />
          <span className="heading-5 font-semibold">Logo</span>
          <span className="flex justify-center items-center">
            <div className="w-full max-w-form font-base text-xs text-default rounded border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none mb-6">
              <span className="block text-center sm:flex items-center justify-center">
                {branding ? (
                  <>
                    <img src={branding.email_template?.logo_url} alt={`${branding.email_template?.name} logo`} className="w-full max-w-btn-md" />
                    <span className="pl-3 break-all text-disabled">{branding.email_template?.logo_url}</span>
                  </>
                ) : (
                  <>
                    <img src={currentEmailTemplate?.logo_url} alt={`${currentEmailTemplate?.name} logo`} className="w-full max-w-btn-md" />
                    <span className="pl-3 break-all text-disabled">{currentEmailTemplate?.logo_url}</span>
                  </>
                )}
              </span>
            </div>
            <ToolTip
              tipId="logo-tip"
              size="sm"
              position="relative ml-3"
            >
              This logo information is pulled from the associated email template. To update the logo, go to “Email Templates,” and edit as needed.
            </ToolTip>
          </span>
        </>
      )}
      {branding?.email_template_id !== 1 && (
        <>
          <Input
            disabled={!showButton}
            label="Primary Color"
            type="text"
            toolTipInfo="The primary color is used in the header’s breadcrumbs, some icons, and some text links. Use a hex color code."
            {...methods.register('color_primary', {
              required: true,
            })}
            error={errors.color_primary && <span className="form-error">{errors.color_primary.message || 'This field is required.'}</span>}
          />
          <Input
            disabled={!showButton}
            label="Dark Primary Color"
            type="text"
            toolTipInfo="The dark primary color is used in the split-background icons. Choose a shade slightly darker than the primary color. Use a hex color code."
            {...methods.register('color_primary_dark', {
              required: true,
            })}
            error={errors.color_primary_dark && <span className="form-error">{errors.color_primary.message || 'This field is required.'}</span>}
          />
          <Input
            disabled={!showButton}
            label="Secondary Color"
            type="text"
            toolTipInfo="The secondary color is primarily the hover color. It’s also used in some graphs. Use a hex color code."
            {...methods.register('color_secondary', {
              required: true,
            })}
            error={errors.color_secondary && <span className="form-error">{errors.color_secondary.message || 'This field is required.'}</span>}
          />
          <Input
            disabled={!showButton}
            label="Tertiary Color"
            type="text"
            toolTipInfo="The tertiary color is used as the masthead’s background color. It’s also used as links on the advertiser dashboard and as the third color on the reports’ graphs. Use a hex color code."
            {...methods.register('color_tertiary', {
              required: true,
            })}
            error={errors.color_tertiary && <span className="form-error">{errors.color_tertiary.message || 'This field is required.'}</span>}
          />
        </>
      )}
    </div>
  )
}

export default AccountDashboardBrandingForm
