import {
  useState,
  useContext,
  useEffect,
} from 'react'
import { IMIApi } from '../../../../api/imi/api'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import { IGenericStatusResponse } from '../../../../api/imi/interfaces'
import EditCampaignPaused from '../../../adminTools/campaigns/campaign_settings/EditCampaignPaused'
import LoadingSpinner from '../../../LoadingSpinner'

const SetCampaignStatus: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [pausedStatus, setPausedStatus] = useState<string>('none')
  const { campaignInDb } = useContext(CampaignContext)

  const handleUpdateStatus = (req: IGenericStatusResponse) => {
    setPausedStatus(req.status)
  }

  useEffect(() => {
    const handleGetStatus = async () => {
      setLoading(true)
      if (campaignInDb.id > 0) {
        const response = await IMIApi.GetCampaignPausedStatus(campaignInDb.id)
        if (response.status === 200) {
          handleUpdateStatus(response.data)
        }
      }
      setLoading(false)
    }

    handleGetStatus()
  }, [campaignInDb.id])

  return (
    <>
      {!loading && campaignInDb.id > 0 && (
        <EditCampaignPaused
          updateMethod={handleUpdateStatus}
          status={pausedStatus}
          isLoading={loading}
        />
      )}
      {
        loading && (
          <LoadingSpinner variant="primary" size="lg" />
        )
      }
    </>
  )
}

export default SetCampaignStatus
