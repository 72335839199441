import {
  useEffect,
  useState,
  useContext,
} from 'react'
import { IMIApi } from '../../api/imi/api'
import { CampaignContext } from '../../contexts/CampaignContext'
import AdminToolsMenu from '../../components/adminTools/AdminToolsMenu'
import Padding from '../../components/layouts/Padding'
import SidebarLeft from '../../components/layouts/SidebarLeft'
import { AppContext } from '../../contexts/AppContext'
import { IPlatformCred, IPlatform } from '../../api/imi/interfaces'
import EditApiSettings from '../../components/adminTools/api/EditApiSettings'

const EditApiSettingsPage: React.FC = () => {
  const [platformsLoading, setPlatformsLoading] = useState(true)
  const [platformCredsLoading, setPlatformCredsLoading] = useState(true)
  const [platforms, setPlatforms] = useState<IPlatform[]>([])
  const [platformCreds, setPlatformCreds] = useState<IPlatformCred>()
  const { setError } = useContext(AppContext)
  const [platformCredsId, setPlatformCredsId] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('platform_creds_id')
    if (items) {
      sessionStorage.setItem('platform_creds_id', items)
      localStorage.removeItem('platform_creds_id')
    }
    const getSessionItems = sessionStorage.getItem('platform_creds_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setPlatformCredsId(parseItems)
      }
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    setPlatformsLoading(true)
    const fetchPlatforms = async () => {
      const response = await IMIApi.GetPlatforms(abortController.signal)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setPlatformsLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setPlatformsLoading(false)
      }
      setPlatforms(response.data.filter((p) => p.id !== 1))
      return setPlatformsLoading(false)
    }
    fetchPlatforms()
    return () => abortController.abort()
  }, [setError, setPlatforms, setPlatformsLoading])

  useEffect(() => {
    const abortController = new AbortController()
    if (platformCredsId) {
      setPlatformCredsLoading(true)
      const fetchPlatformCreds = async () => {
        const response = await IMIApi.GetPlatformCred(platformCredsId)
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setPlatformCredsLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setPlatformCredsLoading(false)
        }
        setPlatformCreds(response.data)
        return setPlatformCredsLoading(false)
      }
      fetchPlatformCreds()
    }
    return () => abortController.abort()
  }, [setError, setPlatformCreds, setPlatformCredsLoading, platformCredsId])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <>
            <EditApiSettings
              platforms={platforms}
              platformCreds={platformCreds}
              isLoading={platformCredsLoading || platformsLoading}
              updatePlatformCreds={(creds) => setPlatformCreds(creds)}
            />
          </>
        )}
      />
    </Padding>
  )
}
export default EditApiSettingsPage
