import {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { AppContext } from '../../contexts/AppContext'
import {
  fixDateObjApi,
  getFileDescriptor,
  rearrangeDate,
} from '../../helpers/utils'
import { IMIApi } from '../../api/imi/api'
import Content from '../layouts/Content'
import Icon from '../Icon'
import Pagination from '../Pagination'
import HorizontalGrid from '../layouts/HorizontalGrid'
import { ReportContext } from '../../contexts/ReportContext'
import { IDailyActivity, IDailyActivityMultiResponse } from '../../api/imi/interfaces'
import Accordion from '../layouts/Accordion'
import LoadingSpinner from '../LoadingSpinner'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'

// TODO: how to handle only 1 item returned? breaks map not sure why

const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// "table" header for daily report details
const title = [
  {
    fieldName: 'start_date',
    name: 'Date',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'spend',
    name: 'Spent',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'impressions',
    name: 'Impressions',
    abbreviation: 'Impr',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'clicks',
    name: 'Clicks',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'emails',
    name: 'Emails',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'calls',
    name: 'Calls',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'web_events',
    name: 'Web Events',
    mobile: true,
    sort: true,
  },
]

interface Props {
  campaignName: string
}
const DailyActivityReport: React.FC<Props> = ({
  campaignName,
}) => {
  const { user, setError } = useContext(AppContext)
  const { parameters, reportParameters } = useContext(ReportContext)
  const [loading, setLoading] = useState<boolean>(true)
  // data coming in from SEM report page
  const [dailyActivity, setDailyActivity] = useState<IDailyActivity []>([] as any)
  // different limits on number of items shown based on user type (admin or other)
  const [pageLimit, setPageLimit] = useState(0)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')
  const backToTop = document.getElementById('daily-activity')

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = sortDirection ? dailyActivity.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : dailyActivity.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }

  useEffect(() => {
    if (user.user_type_id < 4) {
      setPageLimit(30)
    } else {
      setPageLimit(7)
    }
  }, [user.user_type_id])

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (dailyActivity.length - pageLimit))
    if (user.user_type_id === 0) {
      setPageLimit(pageLimit + (dailyActivity.length - pageLimit))
    }
  }

  const handleCollapse = () => {
    if (backToTop) {
      backToTop.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
    if (user.user_type_id < 4) {
      return setPageLimit(30)
    }
    return setPageLimit(7)
  }

  useEffect(() => {
    if (reportParameters.query_end_date) {
      const today = new Date(new Date().setUTCHours(0, 0, 0, 0))
      if (today > reportParameters.query_end_date) {
        return setNoDataMessage('')
      }
    }
  }, [reportParameters])

  useEffect(() => {
    const abortController = new AbortController()
    if (parameters.id > 0) {
      const fetchDailyActivity = async () => {
        const response = await IMIApi.GetDailyActivity(parameters, abortController.signal)
        if (!response) {
          return
        }
        if (response.status && response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setDailyActivity(response.data.data.sort((a, b) => (a.start_date > b.start_date ? -1 : 1)))
        return setLoading(false)
      }
      fetchDailyActivity()
    }
    return () => abortController.abort()
  }, [parameters, setError])

  const handleDownload = useCallback(() => {
    if (campaignName) {
      const fetchDailyActivityDownload = async () => {
        const response = await IMIApi.GetDailyActivityDownload(
          {
            id: reportParameters.id,
            start_date: fixDateObjApi(reportParameters.query_start_date),
            end_date: fixDateObjApi(reportParameters.query_end_date),
          },
        )
        // return response.config.url
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          return setError({ errorMsg: parse?.detail })
        }
        if (!response.status) {
          return setError({ errorMsg: 'Internal server error' })
        }
        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)

        const fileDesc = getFileDescriptor(response.headers)

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(
          new Blob([response.data], { type: fileDesc.contentType }),
        )

        // Use download attribute to set set desired file name
        a.setAttribute('download', fileDesc.filename)

        // Trigger the download by simulating click
        a.click()

        // Cleanup
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
      }
      fetchDailyActivityDownload()
    }
  }, [campaignName, setError, reportParameters.id, reportParameters.query_start_date, reportParameters.query_end_date])

  return (
    <Content
      title="Daily Activity"
      padding={false}
      icon={(
        (dailyActivity && dailyActivity.length > 0) && (
          <Icon
            id="dailyActivityDownload"
            onClick={() => handleDownload()}
          >
            <Icon
              size="md"
              colors="text-primary"
              className="cursor-pointer group-hover:text-secondary mt-1.5"
            >
              <DownloadIcon
                className="text-primary hover:text-secondary"
              />
            </Icon>
          </Icon>
        )
      )}
    >
      {loading ? <div className="px-4 md:px-6 pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <div id="daily-activity">
          <HorizontalGrid
            smGrid="grid-cols-4"
            mdGrid="md:grid-cols-7"
          >
            {title.map((item) => (
              <div key={item.name} className={`break-all group flex center-items ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}>
                {item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                  >
                    <span className="inline md:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                    <span className="hidden md:inline">{item.name}</span>
                    <Icon
                      size="xxs"
                      className="text-default group ml-1 -mt-1.5"
                    >
                      <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                      <ChevronDownIcon className="text-default group-hover:text-secondary" />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>}
              </div>
            ))}
          </HorizontalGrid>
          <div>
            {!dailyActivity || dailyActivity.length === 0 ? <p className="px-4 md:px-6">{noDataMessage}</p> : (
              dailyActivity.filter((metric, amount) => amount < pageLimit).map((metric) => (
                <div key={metric.start_date} className="odd:bg-grey-lightest pt-6 pb-2">
                  <Accordion
                    header={(
                      <HorizontalGrid
                        smGrid="grid-cols-4"
                        mdGrid="md:grid-cols-7"
                      >
                        <p className={`text-xs text-gray-500 pr-3 ${styles.date}`}>
                          {metric.start_date && rearrangeDate(metric.start_date)}
                        </p>
                        <p className="text-xs text-gray-500 pr-3">
                          $
                          {metric.spend.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })}
                        </p>
                        <p className="text-xs text-gray-500 pr-3">
                          {metric.impressions.toLocaleString()}
                        </p>
                        <p className="text-xs text-gray-500 pr-8 md:pr-0">
                          {metric.clicks.toLocaleString()}
                        </p>
                        <p className={`text-xs text-gray-500 pr-3 ${styles.desktop}`}>
                          {metric.emails.toLocaleString()}
                        </p>
                        <p className={`text-xs text-gray-500 pr-3 ${styles.desktop}`}>
                          {metric.calls.toLocaleString()}
                        </p>
                        <p className={`text-xs text-gray-500 pr-3 ${styles.desktop}`}>
                          {metric.web_events.toLocaleString()}
                        </p>
                      </HorizontalGrid>
                    )}
                    body={(
                      <div className="flex justify-between">
                        <p className="text-xxs text-gray-500">
                          <span className={styles.mobile}>
                            Emails:
                            &nbsp;
                          </span>
                          {metric.emails.toLocaleString()}
                        </p>
                        <p className="text-xxs text-gray-500">
                          <span className={styles.mobile}>
                            Calls:
                            &nbsp;
                          </span>
                          {metric.calls.toLocaleString()}
                        </p>
                        <p className="text-xxs text-gray-500">
                          <span className={styles.mobile}>
                            Web Events:
                            &nbsp;
                          </span>
                          {metric.web_events.toLocaleString()}
                        </p>
                      </div>
                    )}
                  />
                </div>
              ))
            )}
            <div className={`${!dailyActivity || dailyActivity.length > 0 ? 'block' : 'hidden'} px-4 pt-3 md:px-6`}>
              <Pagination
                onClick={handleLoadMore}
                pageLimit={pageLimit}
                adminLimit={30}
                data={dailyActivity}
                offset={10}
                onClickCollapse={handleCollapse}
              />
            </div>
          </div>
        </div>
      )}
    </Content>
  )
}
export default DailyActivityReport
