import {
  Dispatch,
  useEffect,
  useState,
  SetStateAction,
  useCallback,
} from 'react'
import { ChevronDownIcon, ChevronRightIcon, RefreshIcon } from '@heroicons/react/outline'
import { handleFilters } from '../helpers/utils'
import Search from './form/Search'
import Icon from './Icon'
import LinkWrapper from './Links'
import BadgeDot from './badge/BadgeDot'
import Modal from './modal'

interface Props {
  onAddAllClick: () => void
  refresh: () => void
  data: any
  onRemoveAllClick: () => void
  setIdArray: Dispatch<SetStateAction<any>>
  assignedData: any
  setDirtyField: Dispatch<SetStateAction<boolean>>
  idsArray: number []
  disabled: boolean
  assignedListName: string
}

const DualListBox:React.FC<Props> = ({
  onAddAllClick,
  refresh,
  data,
  onRemoveAllClick,
  setIdArray,
  assignedData,
  setDirtyField,
  idsArray,
  disabled,
  assignedListName,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    assigned: '',
    unassigned: '',
  })
  const [unassigned, setUnassigned] = useState<any []>([])
  const [assigned, setAssignedData] = useState<any[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [btnText, setBtnText] = useState('Change')
  const [currentSelection, setCurrentSelection] = useState<any>()
  const [idArray, setIdsArray] = useState<number []>([])
  const [filteredAssigned, setFilteredAssigned] = useState<any[]>([])
  const [filteredUnassigned, setFilteredUnassigned] = useState<any[]>([])

  // Sort lists alphabetically
  const alphaData = (sortData) => sortData?.sort((a, b) => (((a.name || a.username) > (b.name || b.username) || a?.secondary_name > b?.secondary_name) ? 1 : -1))
  // Initial data for assigned/unassigned advertisers
  useEffect(() => {
    setUnassigned(alphaData(data))
    setAssignedData(alphaData(assignedData))
  }, [data, assignedData, disabled])

  // update idArray in this component to match
  // incoming list from parent component
  useEffect(() => {
    setIdsArray(idsArray)
  }, [idsArray])

  // Resused function to remove item from unassigned
  // or assigned advertiser list
  const removeAdvertiser = (item, dataList) => {
    if (!disabled) {
      const filter = dataList?.filter((el) => el.id !== item.id)
      setDirtyField(true)
      setUnassigned(filter)
      return setAssignedData(alphaData(filter))
    }
    return null
  }

  const addToUser = (item) => {
    if (!disabled) {
      removeAdvertiser(item, unassigned)
      setAssignedData(alphaData([...assigned, item]))
      return setIdArray([...idArray, item.id])
    }
    return null
  }
  // TODO: when posting also set the state for current account user's assigned advertisers
  const modal = (name) => {
    if (!disabled && assigned.length > 0) {
      setBtnText('Remove')
      setCurrentSelection(name)
      setShowModal(true)
    }
    return null
  }

  const removeFromUser = (item) => {
    if (!disabled) {
      removeAdvertiser(item, assigned)
      setUnassigned(alphaData([...unassigned, item]))
      if (item.length) {
        const multipleAdvertisers = item.map((advertiser) => advertiser.id)
        const filter = idArray?.filter((el) => el !== multipleAdvertisers)
        setDirtyField(true)
        setAssignedData(alphaData(filter))
        setShowModal(false)
        onRemoveAllClick()
        return setIdArray([])
      }
      const filter = idArray?.filter((el) => el !== item.id)
      setIdArray(filter)
      return setShowModal(false)
    }
    return null
  }

  // TODO: decide on how to handle multiple filters
  // is there a better way?
  const filters = useCallback(() => {
    const filterSearchAssigned = (searchItem) => {
      const splitItems = selectedFilters.assigned.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = searchItem?.name?.toLowerCase().includes(splitItems)
      const usernameFilter = searchItem?.username?.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => searchItem?.name?.toLowerCase().includes(si))
        const usernameFil = splitItems.some((si) => searchItem?.username?.toLowerCase().includes(si))
        return nameFil || usernameFil
      }
      return nameFilter || usernameFilter
    }
    const filterAssignedData = assigned?.filter((searchData) => filterSearchAssigned(searchData))

    const filterSearchUnassigned = (searchItem) => {
      const splitItems = selectedFilters.unassigned.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = searchItem.name?.toLowerCase().includes(splitItems)
      const usernameFilter = searchItem?.username?.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => searchItem.name?.toLowerCase().includes(si))
        const usernameFil = splitItems.some((si) => searchItem?.username?.toLowerCase().includes(si))
        return nameFil || usernameFil
      }
      return nameFilter || usernameFilter
    }
    const filterUnassignedData = unassigned?.filter((searchData) => filterSearchUnassigned(searchData))

    setFilteredAssigned(filterAssignedData)
    setFilteredUnassigned(filterUnassignedData)
  }, [selectedFilters, assigned, unassigned])

  useEffect(() => {
    filters()
  }, [filters])

  const onChangeUnassigned = (e) => {
    setSelectedFilters(handleFilters(e.target.value.toLowerCase(), 'unassigned', selectedFilters))
  }
  const onChangeAssigned = (e) => {
    setSelectedFilters(handleFilters(e.target.value.toLowerCase(), 'assigned', selectedFilters))
  }

  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Change"
        body={(
          `Remove ${currentSelection?.name || currentSelection?.username || 'all'} from ${assignedListName}'s list?`
        )}
        onSubmit={() => removeFromUser(currentSelection)}
        onClose={() => setShowModal(false)}
        closeText="Cancel"
        submitText={btnText}
      />
      <div className="dual-listbox">
        <div className="sm:flex sm:gap-6">
          <div className="flex-1 min-w-0">
            <h5 className="pt-3">Available list</h5>
            <div className="border rounded border-grey-light bg-grey-lightest px-3 mb-6 mt-1 h-96 overflow-hidden relative">
              <div className="flex items-center justify-between py-4 border-b border-grey-light mb-3">
                <LinkWrapper
                  variant="defaultAdmin"
                  onClick={onAddAllClick}
                  disabled={filteredUnassigned?.length < 1 || disabled}
                >
                  Add All
                  <Icon size="xxs">
                    <ChevronRightIcon />
                  </Icon>
                </LinkWrapper>
                <Icon
                  className="pl-2 -mb-1"
                  size="md"
                  onClick={refresh}
                  disabled={disabled}
                >
                  <RefreshIcon />
                </Icon>
              </div>
              <Search
                value={selectedFilters.unassigned}
                onChange={onChangeUnassigned}
                label="search"
                hideLabel
                placeholder="search..."
              />
              {filteredUnassigned?.length > 0 && (
                <ul className="overflow-y-scroll h-60 relative pr-6">
                  {filteredUnassigned.map((item) => (
                    <li
                      className={`${disabled ? 'text-disabled' : 'text-default hover:text-primary hover:cursor-pointer'} text-xs font-base mb-3`}
                      key={item.id}
                      onClick={() => addToUser(item)}
                      aria-hidden
                    >
                      <BadgeDot
                        badgeBackground="dotOnly"
                        variant="dot"
                        disabled={disabled}
                        dotColor={(
                          item.is_active ? 'bg-secondary' : 'bg-grey-light'
                        )}
                        size="xs"
                      >
                        {item.type ? (
                          <div>
                            {item.name}
                            {item.secondary_name ? `: ${item.secondary_name}` : null}
                            <div className="text-primary block">
                              {item.type?.short_name}
                              &nbsp;
                              |
                              &nbsp;
                              {item.id}
                            </div>
                          </div>
                        ) : item.name }
                        {item.username && (
                          <>
                            {item.username}
                            <div className="text-primary block">
                              {item.email}
                            </div>
                          </>
                        )}
                      </BadgeDot>
                    </li>
                  ))}
                </ul>
              )}
              <div className="text-center absolute w-full bottom-0">
                <Icon
                  className=""
                  size="sm"
                >
                  <ChevronDownIcon />
                </Icon>
              </div>
            </div>
          </div>
          <div className="flex-1 min-w-0">
            <h5 className="pt-3">
              {assignedListName}
              &apos;s
              list
            </h5>
            <div className="border rounded border-grey-light bg-grey-lightest px-3 mb-6 mt-1 h-96 overflow-hidden relative">
              <div>
                <div className="flex items-center justify-between py-4 border-b border-grey-light mb-3">
                  <LinkWrapper
                    variant="defaultAdmin"
                    onClick={() => modal(filteredAssigned)}
                    disabled={filteredAssigned?.length < 1 || disabled}
                  >
                    Remove All
                    <Icon size="xxs">
                      <ChevronRightIcon />
                    </Icon>
                  </LinkWrapper>
                </div>
                <Search
                  value={selectedFilters.assigned}
                  onChange={(e) => onChangeAssigned(e)}
                  label="search"
                  hideLabel
                  placeholder="search..."
                />
              </div>
              {filteredAssigned?.length > 0 && (
                <ul className="overflow-y-scroll h-60 bottom-0 pr-6">
                  {filteredAssigned.map((item) => (
                    <li
                      className={`${disabled ? 'text-disabled' : 'text-default hover:text-primary hover:cursor-pointer'} text-xs font-base mb-3`}
                      key={item.id}
                      onClick={() => modal(item)}
                      aria-hidden
                    >
                      <BadgeDot
                        badgeBackground="dotOnly"
                        variant="dot"
                        disabled={disabled}
                        dotColor={item.is_active ? 'bg-secondary' : 'bg-grey-light'}
                        size="xs"
                      >
                        {item.type ? (
                          <div>
                            {item.name}
                            {item.secondary_name ? `: ${item.secondary_name}` : null}
                            <div className="text-primary block">
                              {item.type?.short_name}
                              &nbsp;
                              |
                              &nbsp;
                              {item.id}
                            </div>
                          </div>
                        ) : item.name }
                        {item.username && (
                          <>
                            {item.username}
                            <div className="text-primary block">
                              {item.email}
                            </div>
                          </>
                        )}
                      </BadgeDot>
                    </li>
                  ))}
                </ul>
              )}
              <div className="text-center absolute w-full bottom-0">
                <Icon
                  className="mt-1"
                  size="sm"
                >
                  <ChevronDownIcon />
                </Icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DualListBox
