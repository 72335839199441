import { useState, useEffect } from 'react'
import MetricsGrid from '../layouts/MetricsGrid'
import { commaNumber } from '../../helpers/utils'
import Grid from '../layouts/Grid'
import { ReactComponent as ImpressionsIcon } from '../../assets/icons/impressions-icon.svg'
import { ReactComponent as ActivitiesIcon } from '../../assets/icons/activities-icon.svg'
import { ReactComponent as CpaIcon } from '../../assets/icons/cpa-icon.svg'
import { IDisplayDetail } from '../../api/imi/interfaces'

interface Props {
  data: IDisplayDetail
  // totalData: SemTotals
}

const DisplayTotalsReport:React.FC<Props> = ({ data }) => {
  const [totals, setTotals] = useState<IDisplayDetail>([] as any)
  useEffect(() => {
    setTotals(data)
  }, [data])

  return (
    <Grid>
      <MetricsGrid
        name="impressions"
        imageUrl={<ImpressionsIcon />}
        value={totals?.impressions?.value && commaNumber(totals?.impressions?.value)}
        tooltipInfo="The number of times your ad was served."
      />
      <MetricsGrid
        name="activities"
        imageUrl={<ActivitiesIcon />}
        value={totals?.activities?.value && commaNumber(totals?.activities?.value)}
        tooltipInfo="Tracked website actions taken by users who were served your ad."
      />
      <MetricsGrid
        name="cpa"
        imageUrl={<CpaIcon />}
        value={totals?.cpa?.value && `$${totals?.cpa?.value.toLocaleString('en', { useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}
        tooltipInfo="Cost per activity (CPA) is the average amount spent for a completed activity."
        // tooltipInfo={(
        //   <>
        //     A conversion occurs when a visitor to your website completes a desired goal.
        //     <br />
        //     <br />
        //     Phone Call Conversions:&nbsp;
        //     <span className="font-bold">{totals?.conversions?.details.map((callout) => (callout.name.toLowerCase() === 'calls' && commaNumber(callout.value))) || 0}</span>
        //     <br />
        //     Web Event Conversions:&nbsp;
        //     <span className="font-bold">{totals?.conversions?.details.map((callout) => (callout.name.toLowerCase() === 'web events' && commaNumber(callout.value))) || 0}</span>
        //     <br />
        //     Email Conversions:&nbsp;
        //     <span className="font-bold">{totals?.conversions?.details.map((callout) => (callout.name.toLowerCase() === 'emails' && callout.value)) || 0}</span>
        //   </>
        // )}
      />
    </Grid>
  )
}

export default DisplayTotalsReport
