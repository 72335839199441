import {
  useState,
  useContext,
  useEffect,
} from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { AppContext } from '../contexts/AppContext'
import { IResetPassword, IBrandingLogin } from '../api/imi/interfaces'
import { IMIApi } from '../api/imi/api'
import LoadingSpinner from '../components/LoadingSpinner'
import Button from '../components/button/Button'
import Input from '../components/form/Input'
import FormButtonGroup from '../components/button/FormButtonGroup'

const ResetPasswordPage:React.FC = () => {
  const {
    isSignedIn,
    user,
  } = useContext(AppContext)
  const [serverError, setServerError] = useState<string>('')
  const {
    register,
    handleSubmit,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
    },
  } = useForm<IResetPassword>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  const [brandingLogin, setBrandingLogin] = useState<IBrandingLogin>({} as any)
  const [loading, setLoading] = useState<boolean>(false)
  const watchFields = watch('password')
  const [token, setToken] = useState<string>('')
  const [btnText, setBtnText] = useState('Password Reset')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>(false)

  // useEffect(() => {
  //   const abortController = new AbortController()
  //   const url = window.location.hostname
  //   const checkBrandingUrl = async () => {
  //     if (url.toLowerCase() === 'localhost') {
  //       const encbranding = await IMIApi.PostBrandingLogin('https://imidashboard-dev.com')
  //       setBrandingLogin(encbranding.data)
  //       return setLoading(false)
  //     }
  //     const branding = await IMIApi.PostBrandingLogin(url)
  //     if (branding.status !== 200) {
  //       setLoading(false)
  //       return null
  //     }
  //     setBrandingLogin(branding.data)
  //     return setLoading(false)
  //   }
  //   checkBrandingUrl()
  //   return () => abortController.abort()
  // }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tokenString = urlParams.has('token')
    if (tokenString) {
      // @ts-ignore
      return setToken(urlParams.get('token'))
    }
    return setToken('')
  }, [])

  const onSubmit: SubmitHandler<IResetPassword> = async (data) => {
    const resetPassword = {
      ...data,
      token,
    }
    setIsButtonLoading(true)
    setBtnText('Submitting...')
    const submitResponse = await IMIApi.ResetPassword(resetPassword)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          return setBtnText('Password Reset')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setServerError(parse.detail)
        return setBtnText('Password Reset')
      }
      return setBtnText('Password Reset')
    }, 1000)
    if (submitResponse.status === 200) {
      setIsButtonLoading(false)
      return setBtnText('Success!')
    }
    return setIsButtonLoading(false)
  }

  return (
    <div className="flex items-center justify-between min-h-screen bg-login">
      <div className="w-full px-6 min-h-screen bg-white flex flex-1 items-center z-10">
        {loading ? <LoadingSpinner size="lg" variant="primary" /> : (
          <form onSubmit={handleSubmit(onSubmit)} className="md:pr-12 pt-6 pb-8 mb-4 flex-1 max-w-half-site my-0 mx-auto">
            <div
              className="heading-1 text-default block text-left py-2 mb-8 border-b border-grey-dark border-dotted md:max-w-login-form"
            >
              <h1 className="text-default">Password Reset</h1>
              {/* <div className="max-w-btn-lg py-3"><img src={brandingLogin?.email_template?.logo_url} alt={brandingLogin?.email_template?.name} /></div> */}
            </div>
            {/* <h2>Reset Your Password</h2> */}
            <p className="small mb-4 md:max-w-login-form w-full">Please enter in a new password for your account.</p>
            <div className="mb-6 md:max-w-login-form">
              <Input
                type="password"
                label="Password"
                data-testid="password-input"
                {...register('password', {
                  required: true,
                  minLength: {
                    value: 8,
                    message: 'Passwords must be at least 8 characters long.',
                  },
                  pattern: {
                    value: /^(?=.*?\d)[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g,
                    message: 'Passwords must have at least one number. Some special characters are allowed, commas, semicolons, and spaces are not allowed.',
                  },
                })}
                error={(
                  errors.password && (
                    <span className="form-error">
                      {errors.password.message || 'This field is required'}
                    </span>
                  )
                )}
              />
              <Input
                {...register('confirm_password', { required: true, validate: (value) => value === watchFields })}
                label="Confirm password"
                type="password"
                error={(
                  errors.confirm_password && (
                  <span className="form-error">Passwords do not match</span>
                  )
                )}
              />
            </div>
            <p className="text-red">{serverError}</p>
            <div className="">
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabledCancel={!isDirty}
                disabled={!isValid || !isDirty}
                fullWidth
                btnText={btnText}
                showButton
              />
              <a
                className="text-primary global-animation hover:text-secondary block font-bold font-base text-xxs"
                href="/"
              >
                Return to Log In ›
              </a>
            </div>
          </form>
        )}
      </div>
      <div className="hidden md:block md:flex-1">
        <img src="images/dashboard-data-login-graph.png" alt="graph" className="hidden md:block xl:max-w-login-image relative p-24 ml-auto mr-auto" />
      </div>
    </div>
  )
}

export default ResetPasswordPage
