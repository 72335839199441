import {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import { CalendarIcon } from '@heroicons/react/outline'
import ReportHeader from '../components/semReport/ReportHeader'
import SemTotalsReport from '../components/semReport/SemTotalsReport'
import Padding from '../components/layouts/Padding'
import { IMIApi } from '../api/imi/api'
import SemImpressionsClicks from '../components/semReport/SemImpressionsClicksReport'
import CallReport from '../components/semReport/CallReport'
import EnhancedCallAnalyticsReport from '../components/semReport/EnhancedCallAnalyticsReport'
import SemClicksCallsWebEvents from '../components/semReport/SemClicksCallsWebEventsReport'
import SemWebEventsReport from '../components/semReport/SemWebEventsReport'
import SemKeywords from '../components/semReport/SemKeywordsReport'
import { AppContext } from '../contexts/AppContext'
import { PdfContext } from '../contexts/PdfContext'
import EmailReport from '../components/semReport/EmailReport'
import { ISemDetail } from '../api/imi/interfaces'
import LoadingSpinner from '../components/LoadingSpinner'
import { ReportContext } from '../contexts/ReportContext'
import {
  commaNumber,
  fixDateObj,
} from '../helpers/utils'
import DailyActivityReport from '../components/semReport/DailyActivityReport'
import 'bootstrap-daterangepicker/daterangepicker.css'
import RoiReport from '../components/semReport/RoiReport'
import Icon from '../components/Icon'
import Input from '../components/form/Input'
import MobileReportHeader from '../components/navigation/StickyReportHeader'

const SemReportPage: React.FC = () => {
  const {
    setError,
    user,
  } = useContext(AppContext)
  const {
    // interfaces
    setParamsNavigateDatePicker,
    setReportParamsAndCycles,
    // values
    parameters,
    cyclesForDatePicker,
    reportParameters,
  } = useContext(ReportContext)
  const { setSemData } = useContext(PdfContext)

  const [semReportDetail, setSemReportDetail] = useState<ISemDetail>({} as any)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [minDate, setMinDate] = useState<any>()

  /** Load Report Data
   *  This useEffect triggers when on first load the report page and again whenever the
   *  `parameters` key in ReportContext changes to a new report target, it reloads the
   *  report data.
   *
   *  To navigate to a new report page, alter the `parameters` object in the Report Context
   *  using one of the interface methods below which guarantee a valid report request is
   *  generated.
   *
   *  reportContext.
   *    setParamsNavigateDates(start_date, end_date) - Navigate to these arbitrary dates on the current campaign
   *    setParamsNavigateCycle(cycle_id) - Navigate to this cycle on the current campaign
   *    setParamsNavigateNewest(campaign_id) - Navigate to the newest cycle of provided campaign id
   *    setParamsDatePicker - Navigate using the Date Picker
   */

  useEffect(() => {
    // todo add abort controller
    setIsLoading(true)
    const fetchCampaignDetails = async () => {
      const response = await IMIApi.GetSemReport(parameters)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setIsLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setIsLoading(false)
      }
      setReportParamsAndCycles(response.data)
      setSemReportDetail(response.data)
      setSemData(response.data)
      return setIsLoading(false)
    }
    fetchCampaignDetails()
  },
  [parameters, setError, setReportParamsAndCycles, setSemData])

  useEffect(() => {
    // setting a max date they can go back to in order to see campaign cycles
    // applies to enC clients and partners, unless they have branding other than enC
    if ((user.user_type_id === 6 && user.white_label_id === 1)) {
      return setMinDate(moment(new Date(2021, 0))
        .format('MM/DD/YYYY'))
    }
    return setMinDate(undefined)
  }, [user])

  return (
    <Padding>
      {isLoading || !semReportDetail || !cyclesForDatePicker
        ? <LoadingSpinner size="lg" variant="primary" /> : (
          <>
            <MobileReportHeader 
              title={`${reportParameters.fullName} | SEM`}
            >
              <div className="ml-auto hover:cursor-pointer md:min-w-300 bg-tertiary">
                {semReportDetail.cycles && (
                  <DateRangePicker
                    initialSettings={{
                      startDate: moment(reportParameters.report_start_date).format('MM/DD/YYYY'),
                      endDate: moment(reportParameters.report_end_date).format('MM/DD/YYYY'),
                      ranges: cyclesForDatePicker,
                      alwaysShowCalendars: true,
                      minDate,
                      maxDate: semReportDetail.report_end_date ? reportParameters.query_max_date : moment(new Date()).format('MM/DD/YYYY'),
                    }}
                    onCallback={(
                      (start, end, label) => {
                        setParamsNavigateDatePicker(label, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                      }
                    )}
                  >
                    <Input
                      type=""
                      name="date picker"
                      label="date picker"
                      hideLabel
                      className="form-control font-bold font-display bg-white rounded"
                      icon={<Icon><CalendarIcon /></Icon>}
                    />
                  </DateRangePicker>
                )}
              </div>
            </MobileReportHeader>
            <ReportHeader
              title={reportParameters.fullName}
            >
              <div className="heading-5 font-semibold mb-3 sm:mr-12">
                {fixDateObj(semReportDetail?.report_start_date)}
                <div className="small">
                  start date
                </div>
              </div>
              {(semReportDetail.report_end_date) && (
                <div className="heading-5 font-semibold mb-3 sm:mr-12">
                  {semReportDetail.report_end_date ? fixDateObj(semReportDetail.report_end_date) : '—'}
                  <div className="small">
                    end date
                  </div>
                </div>
              )}
              <div className="heading-5 font-semibold mb-3 sm:mr-12">
                {((semReportDetail?.budget && reportParameters.cycle_id && semReportDetail?.budget > 0)) ? `$${commaNumber(Math.round(semReportDetail.budget))}` : '—'}
                <div className="small">
                  budget
                </div>
              </div>
              <div className="heading-5 font-semibold mb-3 sm:mr-12">
                {(semReportDetail?.spend && reportParameters.cycle_id) ? `$${commaNumber(Math.round(semReportDetail.spend))}` : '—'}
                <div className="small">
                  spent
                </div>
              </div>
              <div className="ml-auto hover:cursor-pointer md:min-w-300">
                {semReportDetail.cycles && (
                  <DateRangePicker
                    initialSettings={{
                      startDate: moment(reportParameters.report_start_date).format('MM/DD/YYYY'),
                      endDate: moment(reportParameters.report_end_date).format('MM/DD/YYYY'),
                      ranges: cyclesForDatePicker,
                      alwaysShowCalendars: true,
                      minDate,
                      maxDate: semReportDetail.report_end_date ? reportParameters.query_max_date : moment(new Date()).format('MM/DD/YYYY'),
                    }}
                    onCallback={(
                      (start, end, label) => {
                        setParamsNavigateDatePicker(label, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                      }
                    )}
                  >
                    <Input
                      type=""
                      name="date picker"
                      label="date picker"
                      hideLabel
                      className="form-control font-bold font-display"
                      icon={<Icon><CalendarIcon /></Icon>}
                    />
                  </DateRangePicker>
                )}
              </div>
            </ReportHeader>
            <SemTotalsReport data={semReportDetail} />
            {/* utilizes the daily activity to list in table and have downloadable report */}
            <SemImpressionsClicks data={semReportDetail} />
            {semReportDetail?.call_processing_is_active && (
              <CallReport />
            )}
            <EnhancedCallAnalyticsReport
              callProcessing={semReportDetail?.call_processing_is_active}
              campaignName={reportParameters.fullName}
            />
            <SemClicksCallsWebEvents data={semReportDetail} />
            <SemWebEventsReport
              data={semReportDetail}
            />
            {/* Keywords only appear for cycles, not custom date ranges
              Let presence of keywords indicate that impressions values are also in the db.
          */}
            {!reportParameters.cycle_id || (reportParameters.query_start_date < new Date('2022-10-01')) ? null : (
              <SemKeywords data={semReportDetail} />
            )}
            {semReportDetail?.form_leads?.length > 0 && (
              <EmailReport data={semReportDetail} />
            )}
            <DailyActivityReport
              campaignName={reportParameters.fullName}
            />
            {semReportDetail?.roi?.data?.length > 0 && reportParameters?.cycle_id && (
              (semReportDetail?.report_end_date || user.user_type_id < 4) && (
                <RoiReport
                  roi={semReportDetail.roi}
                />
              )
            )}
          </>
        )}
    </Padding>
  )
}

export default SemReportPage
