import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import CampaignNavigation from './CampaignNavigation'
import AdvertiserNavigation from './AdvertiserNavigation'
import AccountNavigation from './AccountNavigation'
import Notification from './Notification'

// User types:
// Admin, Team Viewer, Call Manager, Call Analyst, Client, Partner
// TODO: add new specific components here or modify it in actual component to
// hide or show certain items.
const Navigation: React.FC = () => {
  const { currentPage } = useContext(AppContext)

  return (
    <div className="flex ml-3 items-start relative">
      {currentPage.pageUrl === '/campaign-overview' && (
        <>
          <AdvertiserNavigation />
        </>
      )}
      {((currentPage.pageUrl === '/search-engine-marketing' || currentPage.pageUrl === '/display-advertising') || currentPage.pageUrl === '/video-advertising') && (
        <>
          <CampaignNavigation />
          <AdvertiserNavigation />
        </>
      )}
      {/* <Notification user={user} /> */}
      <AccountNavigation />
    </div>
  )
}

export default Navigation
