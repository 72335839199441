import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'
import NavLinkWrapper from '../navigation/NavLink'
import { navigationMenu } from '../navigation/NavItems'
import LinkWrapper from '../Links'
import {
  Admin, CallManager, CallAnalyst, TeamViewer, Partner, Client, 
} from '../../constants/UserTypes'

// TODO: Refactot to use UserTypes constants.
const AdminToolsToolbar:React.FC = () => {
  const { user, signOutUserClicked } = useContext(AppContext)
  return (
    <>
      {([Admin, CallManager, TeamViewer].includes(user.user_type_id)) && (
        <Content title="Admin Tools">
          {navigationMenu.map((nav) => (
            <div key={nav.title}>
              {(user.user_type_id === 1) && (
                <>
                  {nav.accounts?.map((item) => (
                    <ul key={item.title}>
                      <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                      <ul>
                        {item.routes.map((route) => (
                          <li key={route.url}>
                            <NavLinkWrapper
                              variant="menu"
                              to={route.url}
                            >
                              {route.title}
                            </NavLinkWrapper>
                          </li>
                        ))}
                      </ul>
                    </ul>
                  ))}
                  {nav.advertisers?.map((item) => (
                    <ul key={item.title}>
                      <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                      <ul>
                        {item.routes.map((route) => (
                          <li key={route.url}>
                            <NavLinkWrapper
                              variant="menu"
                              to={route.url}
                            >
                              {route.title}
                            </NavLinkWrapper>
                          </li>
                        ))}
                      </ul>
                    </ul>
                  ))}
                  {nav.campaigns?.map((item) => (
                    <ul key={item.title}>
                      <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                      <ul>
                        {item.routes.map((route) => (
                          <li key={route.url}>
                            <NavLinkWrapper
                              variant="menu"
                              to={route.url}
                            >
                              {route.title}
                            </NavLinkWrapper>
                          </li>
                        ))}
                      </ul>
                    </ul>
                  ))}
                </>
              )}
              {nav.calls?.map((item) => (
                <ul key={item.title}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul>
                    {item.routes.map((route) => (
                      route.access.includes(user.user_type_id) && (
                        <li key={route.url}>
                          <NavLinkWrapper
                            variant="menu"
                            to={route.url}
                          >
                            {route.title}
                          </NavLinkWrapper>
                        </li>
                      )
                    ))}
                  </ul>
                </ul>
              ))}
              {nav.api?.map((item) => (
                <ul key={item.title}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul>
                    {item.routes.map((route) => (
                      <li key={route.url}>
                        <NavLinkWrapper
                          variant="menu"
                          to={route.url}
                        >
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    ))}
                  </ul>
                </ul>
              ))}
            </div>
          ))}
        </Content>
      )}
      <Content
        title="Account"
      >
        <LinkWrapper
          variant="menu"
          href="/"
          onClick={signOutUserClicked}
        >
          Log Out
        </LinkWrapper>
      </Content>
    </>
  )
}

export default AdminToolsToolbar
