import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { UserContext } from '../../../../contexts/UserContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import ToolTip from '../../../ToolTip'
import DualListBox from '../../../DualListBox'
import FormButtonGroup from '../../../button/FormButtonGroup'
import {
  IUserAdvertisers,
  IBrandingUsers,
  IUserInDb,
  IBrandingUsersInDb,
} from '../../../../api/imi/interfaces'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import LoadingSpinner from '../../../LoadingSpinner'

// TODO: figure out the state change to enable/disable buttons

interface Props {
  edit: boolean
  branding: any
}

const AssignUsers:React.FC<Props> = ({ edit, branding }) => {
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  // All available users
  const [allUsers, setAllUsers] = useState<IUserInDb[]>([] as any)
  // users unassigned to branding id
  const [users, setUsers] = useState<any>()
  // Advertisers assigned to user
  const [assignedUsers, setAssignedUsers] = useState<IBrandingUsers[]>([] as any)
  const [originalAssigned, setOriginalAssigned] = useState<IBrandingUsers[]>([] as any)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(edit)
  // This is to post for user specifically
  const [idArray, setIdArray] = useState<any>()
  // To see if field has been updated based on advertiser being added
  // or removed from user's list.
  const [dirtyField, setDirtyField] = useState<boolean>(false)

  const methods = useForm<IUserAdvertisers>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  const usersAbortRef = useRef(new AbortController())
  const assignedUsersAbortRef = useRef(new AbortController())
  // TODO: get all advertisers
  // get list of advertisers for user
  // Get all advertisers available
  const fetchUsers = useCallback((async () => {
    const response = await IMIApi.GetAllUsers(usersAbortRef.current.signal)
    if (!response) {
      return
    }
    if (response.status !== 200) {
      const parse = JSON.parse(response)
      setError({ errorMsg: parse.detail })
      return setLoading(false)
    }
    setAllUsers(response.data.data.filter((user) => user.user_type_id > 4))
    return setLoading(false)
  }), [setError])

  const fetchAssignedUsers = useCallback((async () => {
    const response = await IMIApi.GetBrandingUser(branding?.id, assignedUsersAbortRef.current.signal)
    if (!response) {
      return
    }
    if (response.status !== 200) {
      const parse = JSON.parse(response)
      setError({ errorMsg: parse.detail })
      return setLoading(false)
    }
    setOriginalAssigned(response.data)
    setIdArray(response.data.map((user) => user.id))
    return setLoading(false)
  }), [setError, branding.id])

  const refresh = useCallback(() => {
    fetchUsers()
    if (branding.id > 0) {
      fetchAssignedUsers()
    }
  }, [fetchUsers, fetchAssignedUsers, branding.id])

  useEffect(() => {
    const usersAbort = usersAbortRef.current
    const assignedUsersAbort = assignedUsersAbortRef.current
    refresh()
    return () => {
      usersAbort.abort()
      assignedUsersAbort.abort()
    }
  }, [refresh])

  // filter users based on being unassigned or assigned to branding
  const assigned = useMemo(() => (
    allUsers?.filter((user) => (
      idArray?.includes(user.id)
    ))), [allUsers, idArray])

  const unassigned = useMemo(() => (
    allUsers?.filter((user) => (
      !idArray?.includes(user.id)
    ))), [allUsers, idArray])

  // Don't want to update advertiser list
  const cancel = () => {
    setAssignedUsers(assigned)
    setUsers(unassigned)
    setIdArray(originalAssigned.map((user) => user.id))
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }
  // Set data for user after refresh and on first render
  useEffect(() => {
    setAssignedUsers(assigned)
    setUsers(unassigned)
    // setIdArray(assignedUsers.map((user) => user.id))
  }, [assigned, unassigned])

  const onAddAllClick = () => {
    setUsers([])
    setAssignedUsers(allUsers)
    setIdArray(allUsers.map((item) => item.id))
    return setDirtyField(true)
  }

  const onRemoveAllClick = () => {
    setAssignedUsers([])
    setUsers(allUsers)
    return setDirtyField(true)
  }

  const editUserAdvertiser = () => {
    setShowButton(true)
  }

  const onSubmit: SubmitHandler<IUserAdvertisers> = async () => {
    const submit = {
      branding_id: branding?.id,
      user_ids: idArray,
    }
    const submitResponse = await IMIApi.BrandingUserIdUpdate(submit)

    setIsButtonLoading(true)
    setBtnText('Saving...')
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      refresh()
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  return (
    <Content
      title="users"
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="assign-advertiser-info"
        >
          The &quot;available list&quot; of advertisers includes all active (green dot) and inactive (gray dot) advertisers. If you can’t find what you’re looking for, you may need to create the advertiser, and then refresh this list.
        </ToolTip>
      )}
    >
      {loading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <DualListBox
              assignedListName="Dashboard branding"
              disabled={!showButton}
              onAddAllClick={onAddAllClick}
              refresh={refresh}
              data={users}
              assignedData={assignedUsers}
              idsArray={idArray}
              setIdArray={setIdArray}
              onRemoveAllClick={onRemoveAllClick}
              setDirtyField={setDirtyField}
            />
            <div className="mb-6">
              <LinkWrapper
                variant="defaultAdmin"
                href="/admin-tools/user-settings/create-user"
                className="inline-block"
                target="_blank"
              >
                Create New User
                <Icon
                  size="xxs"
                >
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
            </div>
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!dirtyField}
              disabledCancel={!dirtyField}
              showButton={showButton}
              onClickCancel={() => cancel()}
              onClickEdit={editUserAdvertiser}
              btnText={btnText}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default AssignUsers
