import { useContext, useState, useEffect } from 'react'
import { AppContext } from '../contexts/AppContext'
import Button from './button/Button'

// TODO: need user level here to show different levels

interface Props {
  data: any,
  pageLimit: number,
  adminLimit: number,
  onClick: () => void,
  onClickBack?: () => void,
  onClickCollapse?: () => void,
  offset: number,
  total?: any,
}

const Pagination:React.FC<Props> = ({
  data,
  pageLimit,
  adminLimit,
  onClick,
  onClickBack,
  onClickCollapse,
  offset,
  total,
}) => {
  const { user } = useContext(AppContext)
  // need to know if more data coming in
  // const [currentOffset, setCurrentOffset] = useState(1)
  const [list, setList] = useState({
    start: 1,
    end: pageLimit,
  })
  // useEffect(() => {
  //   setList({
  //     start: offset + 1,
  //     end: pageLimit + offset,
  //   })
  // }, [offset, pageLimit])

  useEffect(() => {
    setList({
      start: 1,
      end: pageLimit + pageLimit,
    })
  }, [onClick, pageLimit])

  if (data && (data.length > pageLimit)) {
    return (
      <div className="heading-5 flex justify-between items-center pb-3">
        {`Showing ${data.length > 0 ? 1 : 0} - ${data.length > 0 ? pageLimit : 0} of ${data.length > 0 ? data.length : 0}`}
        <Button id="load-btn" onClick={onClick} type="button" variant="tertiary" size="xs" submit="button">See All</Button>
      </div>
    )
  }

  // return (
  //   <div className="heading-5 flex justify-between items-center py-3 px-6">
  //     {`Showing ${data.length > 0 ? list.start : 0} - ${list?.end > total ? total : list?.end} out of ${total || 0}`}
  //     <div>
  //       {offset > 0 && (
  //         <Button id="return-btn" onClick={onClickBack} type="button" variant="tertiary" size="xs">Back</Button>
  //       )}
  //       {(data.length > 0) && (list?.end < total) && (
  //         <Button id="load-btn" onClick={onClick} type="button" variant="tertiary" size="xs" className="ml-6">See More</Button>
  //       )}
  //     </div>
  //   </div>
  // )
  if (user.user_type_id === 1 && data && (data.length > adminLimit)) {
    return (
      <div className="heading-5 flex justify-between items-center pb-3">
        {`Showing ${data.length > 0 ? 1 : 0} - ${data.length > 0 ? data.length : 0} of ${data.length > 0 ? data.length : 0}`}
        {(data.length > adminLimit) && (
          <Button id="collapse" onClick={onClickCollapse} type="button" variant="tertiary" size="xs" submit="button">Collapse</Button>
        )}
      </div>
    )
  }
  if (data) {
    return (
      <div className="heading-5 flex justify-between items-center py-3">
        {`Showing ${data.length > 0 ? 1 : 0} - ${data.length > pageLimit ? pageLimit : data.length} of ${data.length > 0 ? data.length : 0}`}
        {(data.length === pageLimit) && (
          <Button id="collapse" onClick={onClickCollapse} type="button" variant="tertiary" size="xs" submit="button">Collapse</Button>
        )}
      </div>
    )
  }

  return null
}

// if (user.user_type === 'ADMIN' && data.length > adminLimit) {
//   return (
//     <div className="heading-5 flex justify-between items-center">
//       {`Showing ${data.length > 0 ? 1 : 0} - ${data.length > 0 ? data.length : 0} out of ${data.length > 0 ? data.length : 0}`}
//       <Button onClick={onClick} type="button" variant="tertiary" size="sm" submit="button">See More</Button>
//     </div>
//   )
// }

export default Pagination
