import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import moment from 'moment/moment'
import useSessionStorage from '../hooks/useSessionStorage'
import useLocalStorage from '../hooks/useLocalStorage'
import { AppContext } from './AppContext'
import { ICycle, IDisplayDetail, ISemDetail } from '../api/imi/interfaces'
import { fixDateObj, fixDateObjApi } from '../helpers/utils'

export interface IReportMeta {
  id: number
  report_start_date: any
  report_end_date: any
  query_start_date: Date
  query_end_date: Date
  query_max_date: string
  cycle_id: number
  call_processing_is_active: boolean
  roi_is_active: boolean
  fullName: string
}

interface ReportContextData {
  // Interface to set the navigation intent for a DetailReport.
  setParamsNavigateDates: (start_date: string, end_date: string) => void
  setParamsNavigateCycle: (cycle_id: string) => void
  setParamsNavigateNewest: (campaign_id: number) => void
  setParamsNavigateDatePicker: (dateOrLabel: string | undefined, start_date: string, end_date: string) => void

  // The navigation intent, aka `parameters` are used on the ReportDetail pages to load the
  // requested report. Do not modify the parameters directly, instead use one of the interface
  // methods above to set the parameters.
  // Do not use the parameters to populate portions of the report, or calculate report conditions.
  // The parameters represent only the requested report, not the actual report received. Instead,
  // use `reportParameters`.
  parameters: any

  cyclesForDatePicker: any // Array of cycle dates -> cycle_ids, used by the datepicker

  // interface to capture current report metadata
  reportParameters: IReportMeta
  setReportParamsAndCycles: (report: IDisplayDetail | ISemDetail) => void
}

export function useReportContextFactory() {
  const { user } = useContext(AppContext)

  const [globalParams, setGlobalParams] = useLocalStorage('params', {
    id: 0,
    start_date: '',
    end_date: '',
    cycle_id: '',
    roi_is_active: false,
  })
  const [parameters, setParameters] = useSessionStorage('params', {
    id: 0,
    start_date: '',
    end_date: '',
    cycle_id: '',
    roi_is_active: false,
  })

  // Context for Date Picker
  const [initialized, setInitialized] = useState<boolean>(false)
  const [cyclesForDatePicker, implCyclesForDatePicker] = useState<any>()
  const [reportParameters, setReportParameters] = useState<IReportMeta>({
    id: 0,
    fullName: '',
    report_end_date: new Date('1970-01-01'),
    report_start_date: new Date('1970-01-01'),
    query_end_date: new Date('1970-01-01'),
    query_start_date: new Date('1970-01-01'),
    query_max_date: '1970-01-01',
    cycle_id: 0,
    call_processing_is_active: false,
    roi_is_active: false,
  })

  // Initialize the sessionStorage if needed
  useEffect(() => {
    if (!initialized) {
      if (parameters.id === 0) {
        setParameters(globalParams)
      }
      setInitialized(true)
    }
  }, [parameters.id, globalParams, initialized, setParameters])

  /** Builds an array of start_date,end_date,cycle_id that when passed to the Date Picker's
   *  range key, enables report cycle navigation.
   */
  const loopFunction = useCallback((data) => {
    // Gets all cycle date objects from multiple campaigns (if Ccampaign has multiple pcampaigns)
    // Combines all the pcampaign cycles into one array
    // const combinedArray = [] as any
    // for (let i = 0; i < data?.pcampaign?.length; i += 1) {
    //   const arrayCycles = [...data.pcampaign[i].cycles]
    //   combinedArray.push(...arrayCycles)
    // }
    // Renames key to start date, keys have to be the same as the label :(
    const renameKeys = (obj, newKeys) => {
      // FIXME: TypeError: Cannot convert undefined or null to object
      // can't use SEM report detail object because null value, can't replace
      // null value in anything I've tried
      const keyValues = Object.keys(obj)
        .map((key, value) => {
          // Changes object's value into moment format for react-bootstrap-datepicker
          const transformValues = (dateValues) => {
            const start_date = moment(dateValues.start_date)
              .format('MM/DD/YYYY')
            const end_date = !dateValues.end_date ? moment()
              .format('MM/DD/YYYY') : moment(dateValues.end_date)
              .format('MM/DD/YYYY')
            const cycle_id = dateValues.id
            return [start_date, end_date, cycle_id]
          }
          const newKey = newKeys[key] || key
          // return { [newKey]: obj[key] }
          return { [newKey]: transformValues(obj[key]) }
        })
      return Object.assign({}, ...keyValues)
    }
    const sort = data?.sort((a, b) => (a.start_date > b.start_date ? -1 : 1))
      ?.map((start) => (moment(start.start_date)
        .format('MM/DD/YYYY')))

    const newCombinedArray = renameKeys(data, sort)
    // console.log('cycles=', newCombinedArray)
    return newCombinedArray
  }, [])

  const setCyclesForDatePicker = useCallback((cycles: ICycle[]) => {
    implCyclesForDatePicker(loopFunction(cycles))
  }, [loopFunction])

  /** Initializes the Report Context with the current report.  If this is not called,
   *  the date picker and reportContext will not work appropriately.
   */
  const setReportParamsAndCycles = useCallback((report: IDisplayDetail | ISemDetail) => {
    setCyclesForDatePicker(report.cycles)

    // Get the campaign full name
    let campName = report?.campaign?.name
    const secName = report?.campaign?.secondary_name
    const specific = report?.campaign?.specific?.name

    if (secName) {
      campName = `${campName}: ${secName}`
    }
    if (secName && specific) {
      campName = `${campName}: ${secName} - ${specific}`
    }
    if (!secName && specific) {
      campName = `${campName} - ${specific}`
    }

    const UTCDate = (date) => new Date(date)
    // Prepare the response
    const values: IReportMeta = {
      id: report.campaign.id,
      // For use on the date picker
      // now have to specify timezone, picking UTC
      // docs: https://momentjs.com/docs/#/parsing/
      report_end_date: report.report_end_date ? moment.utc(report.report_end_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
      report_start_date: moment.utc(report.report_start_date).format('MM/DD/YYYY'),
      // For use on queries
      query_end_date: UTCDate(report.query_end_date),
      query_start_date: UTCDate(report.query_start_date),
      query_max_date: moment.utc(report.query_max_date).format('MM/DD/YYYY'),
      cycle_id: report.report_cycle_id ? report.report_cycle_id : 0,
      call_processing_is_active: report.call_processing_is_active,
      roi_is_active: report.roi_is_active,
      fullName: campName,
    }
    setReportParameters(values)
  }, [setCyclesForDatePicker])

  /** Navigate to the specified start and end dates
   *
   * @param start_date a string formatted like '2023-06-30' <YYYY-MM-DD>
   * @param end_date a string formatted like '2023-11-01' <YYYY-MM-DD>
   */
  function setParamsNavigateDates(start_date: string, end_date: string) {
    const params = {
      ...parameters,
      cycle_id: '',
      end_date,
      start_date,
    }
    setParameters(params)
    setGlobalParams(params)
  }

  /** Navigate to the specified cycle_id
   *
   * @param cycle_id a report cycle_id
   */
  function setParamsNavigateCycle(cycle_id: string | number) {
    const params = {
      ...parameters,
      cycle_id: `${cycle_id}`,
      start_date: '',
      end_date: '',
    }
    setParameters(params)
    setGlobalParams(params)
  }

  /** Navigate to the DatePicker selection
   *
   * Accepts the DatePicker's inputs and calls the appropriate navigate function.
   *
   * @param dateOrLabel a string literal, either 'Custom Range' or a date like '06/30/2023'
   * @param start_date a date string formatted like '2023-06-30' <YYYY-MM-DD>
   * @param end_date a date string formatted like '2023-06-30 <YYYY-MM-DD>
   */
  const setParamsNavigateDatePicker = (dateOrLabel: string | undefined, start_date: string, end_date: string) => {
    // if dateOrLabel is a date literal, lookup the cycle and use that.
    if (dateOrLabel && dateOrLabel === 'Custom Range') {
      return setParamsNavigateDates(start_date, end_date)
    }
    const findKey = cyclesForDatePicker && Object.keys(cyclesForDatePicker)
      .find((k) => k === dateOrLabel)
    if (findKey) {
      const cid = cyclesForDatePicker[findKey][2]
      // console.log('findKey=', findKey, 'cid=', cid)
      return setParamsNavigateCycle(cid.toString())
    }
  }

  /** Navigate to the Campaign's newest cycle
   *
   * @param campaign_id a campaign id
   */
  const setParamsNavigateNewest = useCallback((campaign_id: number | string) => {
    // To load the newest report, pass only the Campaign id
    const params = {
      id: campaign_id,
      start_date: '',
      end_date: '',
      roi_is_active: false, // move to the `thisReportParams` callback
    }
    setGlobalParams(params)
    return setParameters(params)
  }, [setGlobalParams, setParameters])

  return {
    cyclesForDatePicker,
    setParamsNavigateNewest,
    setParamsNavigateDatePicker,
    setParamsNavigateDates,
    setParamsNavigateCycle,
    setReportParamsAndCycles,
    reportParameters,
    parameters,
  }
}

export const ReportContext = createContext<ReportContextData>({} as any)
