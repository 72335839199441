import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { ChevronRightIcon, TrashIcon } from '@heroicons/react/outline'
import Modal from '../../../modal'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import Input from '../../Input'
import Radio from '../../Radio'
import Dropdown from '../../Dropdown'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import DropdownAdmin from '../../DropdownAdmin'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  editPage?: boolean
}

const RoiForm:React.FC<Props> = ({
  showButton,
  errors,
  editPage,
}) => {
  const methods = useFormContext()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [btnText, setBtnText] = useState('Change')
  const [currentSelection, setCurrentSelection] = useState<any>()
  const watchRoi = methods.watch(('roi_is_active'), { shouldDirty: true })
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [disabledCall, setDisabledCall] = useState<any>()
  const [disabledOrder, setDisabledOrder] = useState<any>()

  const { fields, append, remove } = useFieldArray({
    ...methods.control,
    name: 'lead_types',
  })

  const watchFieldArray = methods.watch('lead_types')

  const checkCallDupes = (itemIndex) => {
    if (methods.getValues(`${itemIndex}.call_category_id`) === null || (methods.getValues(`${itemIndex}.call_category_id`) === undefined)) {
      return false
    }
    return campaignInDb.category.call_category.filter((itemId) => itemId.id === methods.getValues(`${itemIndex}.call_category_id`)).length === 0
  }
  const controlledFields = fields.map((field, index) => {
    // NOTE: have to leave this here to match RFH doc
    const throwAway = 1
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  const addNumber = (index) => {
    const number = index + 1
    if (number < 10) {
      return `0${number}`
    }
    return number
  }

  const modal = (itemIndex) => {
    setBtnText('Remove')
    setCurrentSelection(itemIndex)
    setShowModal(true)
  }

  const removeItem = () => {
    remove(currentSelection)
    return setShowModal(false)
  }

  const orderList = [
    {
      name: '01',
      id: 1,
      value: 1,
    },
    {
      name: '02',
      id: 2,
      value: 2,
    },
    {
      name: '03',
      id: 3,
      value: 3,
    },
    {
      name: '04',
      id: 4,
      value: 4,
    },
    {
      name: '05',
      id: 5,
      value: 5,
    },
    {
      name: '06',
      id: 6,
      value: 6,
    },
    {
      name: '07',
      id: 7,
      value: 7,
    },
    {
      name: '08',
      id: 8,
      value: 8,
    },
    {
      name: '09',
      id: 9,
      value: 9,
    },
    {
      name: '10',
      id: 10,
      value: 10,
    },
  ]

  useEffect(() => {
    if (campaignInDb?.category?.id > 0 && campaignInDb?.call_processing_is_active) {
      const currentCallId = watchFieldArray.map((item) => item.call_category_id).filter((callId) => callId !== null)
      const removeCallDupes = currentCallId.filter((item, index, inputArray) => inputArray.indexOf(item) === index)
      setDisabledCall(removeCallDupes)
    }

    const currentOrderId = watchFieldArray.map((item) => item.order).filter((orderId) => orderId !== null)
    const orderDupes = currentOrderId.filter((item, index, inputArray) => inputArray.indexOf(item) === index)
    setDisabledOrder(orderDupes)
  }, [watchFieldArray, methods, campaignInDb])

  return (
    <>
      <Modal
        isButtonLoading={!showButton}
        show={showModal}
        title="Confirm Change"
        body={(
          `Remove lead type ${addNumber(currentSelection)}?`
        )}
        onSubmit={() => removeItem()}
        onClose={() => setShowModal(false)}
        closeText="Cancel"
        submitText={btnText}
      />
      {!editPage && (
        <>
          <Radio
            name="roi_is_active"
            label="Should the report for this campaign’s current cycle include the ROI section?"
            disabled={!showButton || campaignInDb.id <= 0}
            options={[
              {
                id: 1,
                label: 'Yes',
                value: true,
              },
              {
                id: 0,
                label: 'No',
                value: false,
              },
            ]}
          />
        </>
      )}
      {((campaignInDb.id > 0 && watchRoi.toString() === 'true') || (campaignInDb.id > 0 && campaignInDb.roi_is_active && watchRoi.toString() === 'true')) && (
        <>
          <ul className="roi-form">
            <hr className={!editPage ? 'block' : 'hidden'} />
            {controlledFields?.map((item, index) => (
              <li key={fields[index].id}>
                {/* <hr /> */}
                <div className="flex justify-between items-baseline">
                  <h4 className="pb-3">{`lead type ${addNumber(index)}`}</h4>
                  <LinkWrapper
                    href="#"
                    variant="defaultAdmin"
                    className="mb-6 inline-block"
                    onClick={() => modal(index)}
                  >
                    <Icon size="md" colors="text-primary">
                      <TrashIcon className="text-primary hover:text-secondary global-animation" />
                    </Icon>
                  </LinkWrapper>
                </div>
                <Controller
                  name={`lead_types.${index}.order` as const}
                  control={methods.control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    field,
                  }) => (
                    <Dropdown
                      disabled={!showButton}
                      hookFormField={field}
                      placeholder={campaignInDb?.lead_types && campaignInDb?.lead_types[index]?.order > 0 ? undefined : 'Select...'}
                      tipId={`order-${addNumber(index)}`}
                      label="Order"
                      variant="admin"
                      disabledOptions={disabledOrder}
                      required
                      value={methods.getValues(`lead_types.${index}.order`)}
                      list={orderList}
                      error={(
                        errors.lead_types?.[index]?.order && (
                          <span className="form-error">
                            This order number is already in use. Please change the order on this field.
                          </span>
                        )
                      )}
                      toolTipInfo="The number here reflects the lead types’ ordering within the ROI section of the campaign report (01 appears first, 02 is next, and so on). A default order has been provided, but it can be changed."
                    />
                  )}
                />
                <Input
                  label="Display name"
                  tipId={`display-name-${addNumber(index)}`}
                  disabled={!showButton}
                  type="text"
                  toolTipInfo="This display name appears in the ROI section of the campaign report. A default may have been provided; it can be changed, as needed."
                  {...methods.register(`lead_types.${index}.name` as const, {
                    required: true,
                  })}
                />
                <Radio
                  name={`lead_types.${index}.calculation` as const}
                  required
                  label="What is this lead type related to?"
                  disabled={!showButton || !campaignInDb.id}
                  options={[
                    {
                      id: 1,
                      name: 'call',
                      label: 'Calls',
                      value: 'call',
                    },
                    {
                      id: 0,
                      name: 'web events',
                      label: 'Web Events',
                      value: 'webevent',
                    },
                    {
                      id: 3,
                      name: 'email',
                      label: 'Emails',
                      value: 'email',
                    },
                  ]}
                />
                {methods.watch(`lead_types.${index}.calculation`) === 'call' && (
                  campaignInDb?.call_processing_is_active ? (
                    <Controller
                      name={`lead_types.${index}.call_category_id` as const}
                      control={methods.control}
                      rules={{
                        required: item.calculation === 'call',
                        // validate: checkCallDupes(`lead_types.${index}`),
                      }}
                      render={({
                        field,
                      }) => (
                        <>
                          <Dropdown
                            disabled={!showButton}
                            hookFormField={field}
                            // @ts-ignore
                            placeholder={campaignInDb?.lead_types?.length > 0 && campaignInDb?.lead_types[index]?.call_category_id > 0 && campaignInDb?.category.id === methods.getValues(`lead_types.${index}.call_category.id`) ? undefined : 'Select...'}
                            tipId={`call-category-${addNumber(index)}`}
                            disabledOptions={disabledCall}
                            label="Call category"
                            variant="admin"
                            value={campaignInDb.category.call_category.filter((itemId) => itemId.id === methods.getValues(`lead_types.${index}.call_category_id`)).length > 0 ? methods.getValues(`lead_types.${index}.call_category_id`) : 0}
                            // value={methods.getValues(`lead_types.${index}.call_category_id`) || [{ id: null, name: undefined }]}
                            list={campaignInDb.id > 0 ? campaignInDb?.category?.call_category : [{ id: 0, name: 'Please select a call industry to see available categories.' }]}
                            error={(checkCallDupes(`lead_types.${index}`) && (
                              <span className="form-error">
                                The call industry has seemed to change and the assigned call category does not exist in this industry. Please select a new call category.
                              </span>
                            ))}
                            toolTipInfo="The campaign’s call industry determines the call categories that are listed here."
                            required={campaignInDb?.call_processing_is_active && methods.watch(`lead_types.${index}.calculation`) === 'call'}
                          />
                        </>
                      )}
                    />
                  ) : (
                    <>
                      <span className="form-error -mt-3">Call processing is not active. All calls will be counted in this lead type.</span>
                    </>
                  )
                )}
                <Input
                  label="Percent converted"
                  tipId={`Percent-converted-${addNumber(index)}`}
                  type="text"
                  required
                  disabled={!showButton}
                  className="percent-split"
                  toolTipInfo={'Enter the percentage as a whole number or, if needed, a decimal, ex. enter 25 for 25% or enter 25.5 for 25.5%. If no value is provided, you must input the number zero ex. "0".'}
                  // toolTipInfo="If you leave this blank, it will display as a dash on the report, and the return will not be calculated."
                  {...methods.register(`lead_types.${index}.converted`, { required: false })}
                />
                <Input
                  tipId={`average-gross-${addNumber(index)}`}
                  label="Average gross"
                  type="text"
                  required
                  disabled={!showButton}
                  className="dollar-split"
                  toolTipInfo={'If no value is provided, you must input the number zero ex. "0".'}
                  {...methods.register(`lead_types.${index}.avg_gross`, { required: false })}
                />
                <hr />
              </li>
            ))}
          </ul>
          <LinkWrapper
            href="#"
            variant="defaultAdmin"
            className="mb-6 inline-block"
            disabled={!showButton}
            onClick={() => (
              append({
                name: '',
                order: undefined,
                converted: 0,
                avg_gross: 0,
                call_category_id: null,
                campaign_id: campaignInDb.id,
                calculation: campaignInDb.call_processing_is_active ? 'call' : 'webevent',
                is_active: true,
              })
            )}
          >
            Add Additional Lead Type
            <Icon size="xxs">
              <ChevronRightIcon />
            </Icon>
          </LinkWrapper>
          <br />
        </>
      )}
    </>
  )
}

export default RoiForm
