// Layout with sidebar on right

interface Props {
  mainContent: React.ReactNode
  sidebarContent: React.ReactNode
}

const SidebarRight: React.FC<Props> = ({
  mainContent,
  sidebarContent,
}) => (
  <section>
    <div className="max-w-3xl mx-autosm:px-6 lg:max-w-7xl">
      {/* Main 3 column grid */}
      <div className="grid grid-cols-1 items-start lg:grid-cols-3 lg:gap-6">
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title">
            <div className="">
              {mainContent}
            </div>
          </section>
        </div>

        {/* Right column */}
        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <div>{sidebarContent}</div>
          </section>
        </div>
      </div>
    </div>
  </section>
)

export default SidebarRight
