import Button from './button/Button'

interface Props {
  show: boolean
  title: string
  body: string
  isButtonLoading?: boolean
  submitText?: string
  closeText?: string
  altButtonText?: string
  onSubmit: (e: any) => void
  onClose: (e?: any) => void
  onAltButton?: (e: any) => void
}

const Modal:React.FC<Props> = ({
  show,
  onSubmit,
  onClose,
  title,
  body,
  closeText,
  submitText,
  isButtonLoading,
  altButtonText,
  onAltButton,
}) => {
  if (!show) {
    return null
  }

  const handleOnAltButton = (event: any) => {
    onAltButton!(event)
  }

  const handleOnSubmit = (event: any) => {
    onSubmit(event)
  }

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="px-6 py-3 border-0 rounded shadow-lg relative flex flex-col max-w-modal bg-grey-darkest bg-opacity-90 outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-center justify-between pt-3 pb-1 border-b border-grey-dark">
              <h3 className="text-light">
                {title}
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-light absolute right-4 top-1.5 text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="bg-transparent text-menuTitle text-lg block outline-none focus:outline-none hover:text-light">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            { (body) && (
              <div className="relative flex-auto">
                <p className="text-light py-4 heading-3 text-sm mb-2">
                  {body}
                </p>
              </div>
            )}

            {/* footer */}
            <div className="flex">
              { (closeText) && (
                <Button
                  id="modal-close"
                  variant="secondary"
                  onClick={onClose}
                  className="mr-3 text-light"
                  size="sm"
                >
                  {closeText}
                </Button>
              )}
              { (altButtonText) && (
                <Button
                  id="modal-close"
                  variant="secondary"
                  onClick={onAltButton}
                  className="mr-3 text-light"
                  size="sm"
                >
                    {altButtonText}
                </Button>
              )}
              { (submitText) && (
                <Button
                  isLoading={isButtonLoading}
                  variant="primary"
                  onClick={handleOnSubmit}
                  id="modal-close"
                  // submit="button"
                  size={closeText ? 'sm' : 'md'}
                >
                  {submitText}
                </Button>
              )}

            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  )
}

export default Modal
