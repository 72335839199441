import { Disclosure, Transition } from '@headlessui/react'
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/outline'
import { cls } from '../../helpers/utils'

const styles = {
  mobile: '',
  desktop: 'hidden md:block',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'md:hidden',
}

const accordionStyles = {
  variant: {
    fullLine: {
      mobile: 'block',
      desktop: 'block',
      icon: 'absolute',
      expand: 'block',
      collapse: 'block',
    },
    hideMd: {
      mobile: '',
      desktop: 'hidden md:block',
      icon: 'md:hidden absolute',
      expand: 'block',
      collapse: 'md:hidden',
    },
    hideLg: {
      mobile: '',
      desktop: 'hidden lg:block',
      icon: 'lg:hidden absolute',
      expand: 'block',
      collapse: 'lg:hidden',
    },
  },
}

interface Props {
  header: React.ReactNode
  body: React.ReactNode
  bodyColor?: string
  variant?: 'hideMd' | 'hideLg' | 'fullLine'
  position?: string
  fullLine?: boolean
  showExpandButton?: boolean
}

const Accordion: React.FC<Props> = ({
  body,
  header,
  bodyColor,
  position,
  variant,
  fullLine,
  showExpandButton = true,
}) => (
  // top-4
  <Disclosure>
    {({ open }) => (
      <div className="relative w-full text-left">
        {header}
        {/* <Disclosure.Button onClick={() => open} className={`absolute top-0 right-0`}> */}
        <Disclosure.Button onClick={() => open} className={`${showExpandButton ? 'block' : 'hidden'} absolute top-0 right-0`}>
          <span className={cls(`
            absolute right-4 md:right-6 hover:cursor-pointer
            ${variant === 'fullLine' ? '' : 'lg:pointer-events-none group'}
            ${!position ? 'top-2' : position}
            ${variant ? accordionStyles.variant[variant].icon : styles.icon}
          `)}
          >
            {/* <span className={`absolute ${!position ? 'top-2' : position} right-4 md:right-6 hover:cursor-pointer lg:pointer-events-none group ${cls(`accordionStyles.variant[variant].icon`)}`}> */}
            {open ? <MinusCircleIcon className="h-5 w-5 text-primary group-hover:text-secondary" aria-hidden="true" /> : <PlusCircleIcon className="h-5 w-5 text-primary group-hover:text-secondary" aria-hidden="true" />}
          </span>
        </Disclosure.Button>
        <Transition
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
          show={open}
        >
          <Disclosure.Panel
            static
            className={cls(`
            ${fullLine ? '' : 'mx-4 md:mx-6'}
            ${variant === 'fullLine' ? '' : 'pt-3 pb-8 border-t border-grey-light'}
            ${bodyColor}
            ${variant ? accordionStyles.variant[variant].collapse : styles.collapse}
          `)}
          >
            {/* <Disclosure.Panel static className={`pt-3 pb-8 border-t border-grey-light ${bodyColor} ${styles.collapse}`}> */}
            {body}
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
)

export default Accordion
