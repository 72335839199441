import { PDFDocument, PDFFont } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'

export type Fonts = {
  nunito: PDFFont
  nunitoLight: PDFFont
  nunitoBold: PDFFont
  heebo: PDFFont
}

export const getFonts = async (pdfDoc: PDFDocument): Promise<Fonts> => {
  const nunitoUrl = 'https://fonts.gstatic.com/s/nunitosans/v1/qDS9UelBO44ppiSawKNcIKCWcynf_cDxXwCLxiixG1c.ttf'
  const nunitoLightUrl = 'https://fonts.gstatic.com/s/nunitosans/v1/XvilrNtBQKRMeiqSPzEFHS9-WlPSxbfiI49GsXo3q0g.ttf'
  const nunitoBoldUrl = 'https://fonts.gstatic.com/s/nunitosans/v1/XvilrNtBQKRMeiqSPzEFHXe1Pd76Vl7zRpE7NLJQ7XU.ttf'
  const heeboUrl = 'https://fonts.gstatic.com/s/heebo/v2/nyHCGMPliplPNqpssbDSIA.ttf'
  const nunitoFontBytes = await fetch(nunitoUrl).then((res) => res.arrayBuffer())
  const nunitoLightFontBytes = await fetch(nunitoLightUrl).then((res) => res.arrayBuffer())
  const nunitoBoldFontBytes = await fetch(nunitoBoldUrl).then((res) => res.arrayBuffer())
  const heeboFontBytes = await fetch(heeboUrl).then((res) => res.arrayBuffer())
  pdfDoc.registerFontkit(fontkit)
  const nunito = await pdfDoc.embedFont(nunitoFontBytes)
  const nunitoLight = await pdfDoc.embedFont(nunitoLightFontBytes)
  const nunitoBold = await pdfDoc.embedFont(nunitoBoldFontBytes)
  const heebo = await pdfDoc.embedFont(heeboFontBytes)

  return {
    nunito,
    nunitoLight,
    nunitoBold,
    heebo,
  }
}
