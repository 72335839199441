import { CheckIcon } from '@heroicons/react/outline'
import React, { forwardRef, useState, useEffect } from 'react'
import { cls } from '../../helpers/utils'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'
import buttonStyles from './ButtonStyles'

interface BtnPropsWithChildren { }

interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  BtnPropsWithChildren {
  block?: boolean
  children: React.ReactNode
  submit?: 'submit' | 'button'
  className?: string
  pill?: boolean
  disabled?: boolean
  variant: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'danger' | 'link'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'admin'
  id: string
  isLoading?: boolean
}

type ButtonRef = React.ForwardedRef<HTMLButtonElement>

const Button = forwardRef(
  (
    {
      isLoading = false,
      block,
      id,
      children,
      className,
      color,
      disabled = false,
      pill,
      size = 'lg',
      submit,
      variant,
      ...props
    }:Props, ref: ButtonRef,
  ) => {
    const [buttonLoading, setButtonLoading] = useState(isLoading)
    const [submitText, setSubmitText] = useState(children)
    useEffect(() => {
      setButtonLoading(isLoading)
      setSubmitText(children)
    }, [isLoading, children])
    return (
      <button
        data-testid={id.toLowerCase().replace(/\s+/g, '-')}
        ref={ref}
        {...props}
        type={submit ? 'submit' : 'button'}
        disabled={disabled}
        className={cls(`
                  ${buttonStyles.variant[variant] !== 'link' && buttonStyles.base}
                  ${!disabled && buttonStyles.variant[variant]}
                  ${buttonStyles.size[size]}
                  ${pill ? buttonStyles.pill : 'rounded-0'}
                  ${buttonLoading && children ? 'cursor-not-allowed text-light bg-primary border-primary' : ''}
                  ${(!buttonLoading && children === 'Saved!') ? 'text-light bg-primary border-primary' : ''}
                  ${className}
              `)}
        {...props}
      >
        <div className={`flex items-center justify-center w-full leading-5 ${size === 'xs' ? '' : 'mt-1'} ${((!buttonLoading && children === 'Saved!') || (!buttonLoading && children === 'Submitted!')) ? 'text-light' : ''} ${buttonLoading ? 'text-light' : ''}`}>
          {buttonLoading && <LoadingSpinner variant="light" size="sm" />}
          {((!buttonLoading && children === 'Saved!') || (!buttonLoading && children === 'Submitted!')) && <Icon colors="light" className="cursor-not-allowed pr-1" size="md"><CheckIcon /></Icon>}
          {submit === 'submit' ? submitText : children}
        </div>
      </button>
    )
  },
)

Button.defaultProps = {
  className: 'btn',
  block: false,
  pill: true,
  size: 'lg',
  disabled: false,
  isLoading: false,
  submit: undefined,
}

export default Button
