import { useState, useContext, useEffect } from 'react'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import { ICallProccessingSummary } from '../../../api/imi/interfaces'
import { IMIApi } from '../../../api/imi/api'
import { AppContext } from '../../../contexts/AppContext'
import FlaggedCalls from '../../../components/adminTools/calls/flagged/FlaggedCalls'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'

const FlaggedCallsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [calls, setCalls] = useState<ICallProccessingSummary>([] as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCallsForProcessing = async () => {
      const response = await IMIApi.GetAllCallProcessing(true, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCalls(response.data[0])
      return setLoading(false)
    }
    fetchAllCallsForProcessing()
    return () => abortController.abort()
  }, [setError])
  // calls[0] = unprocessed
  // calls[1] = processed

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <FlaggedCalls
            flaggedCalls={calls}
            isLoading={loading}
          />
        )}
      />
    </Padding>
  )
}

export default FlaggedCallsPage
