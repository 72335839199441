import { PDF, FontStyle, Font } from '../lib'
import { DeviceType } from '../data'
import * as sizes from '../sizes'

const COL_1_WIDTH = 3 / 16
const COL_2_WIDTH = 4 / 16
const COL_3_WIDTH = 9 / 16

const getTable = (pdf: PDF, data: DeviceType[]) => {
  const pageWidth = (pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 4)
  return {
    title: pdf.text.drawText('device type', pdf.colors.black, { style: FontStyle.Bold }),
    header: [
      pdf.text.drawText('Type', pdf.colors.black, { width: pageWidth * COL_1_WIDTH }),
      pdf.text.drawText('# of Impressions', pdf.colors.black, { width: pageWidth * COL_2_WIDTH }),
    ],
    rows: data.map((device, i) => [
      pdf.text.drawText(device.type, pdf.colors.darkestGrey, { width: pageWidth * COL_1_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(device.impressions, pdf.colors.darkestGrey, { width: pageWidth * COL_2_WIDTH, font: Font.Heebo }),
      {
        width: pageWidth * COL_3_WIDTH,
        height: 10,
        render: () => {
          pdf.pages.moveDown(5)
          pdf.drawing.drawBarGraph(
            pageWidth * COL_3_WIDTH - 5, 
            device.percent, 
            [
              pdf.colors.primary, 
              pdf.colors.secondary,
              pdf.colors.tertiary, 
            ][i],
          )
          pdf.pages.moveUp(5)
        },
      },
    ]),
  }
}

const drawRow = (pdf: PDF, height: number, row: { width: number, height: number, render: () => void }[]) => {
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  row.forEach((col) => {
    col.render()
    pdf.pages.moveRight(col.width + sizes.CONTAINER_MARGIN)
  })
  pdf.pages.moveDown(height - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
}

export const drawDeviceTypes = (pdf: PDF, data: DeviceType[]) => {
  const table = getTable(pdf, data)
  const titleHeight = table.title.height + sizes.CONTAINER_MARGIN * 2
  const headerHeight = table.header[0].height + sizes.CONTAINER_MARGIN * 2

  if (titleHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerHeader(pdf.pages.pageWidth, titleHeight)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  table.title.render()
  pdf.pages.moveDown(titleHeight - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()

  if (headerHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerRow(pdf.pages.pageWidth, headerHeight, pdf.colors.white)
  drawRow(pdf, headerHeight, table.header)

  table.rows.forEach((row, i) => {
    const height = row.reduce((h, col) => (
      col.height > h ? col.height : h
    ), 0) + sizes.CONTAINER_MARGIN * 2

    if (height > pdf.pages.remainingHeight) {
      pdf.pages.nextPage()
    }

    if (i % 2 === 0) {
      if (i === data.length - 1) {
        pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, height, pdf.colors.grey)
      } else {
        pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.grey)
      }
    } else if (i === data.length - 1) {
      pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, height, pdf.colors.white)
    } else {
      pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.white)
    }

    drawRow(pdf, height, row)
  })

  pdf.pages.moveDown(sizes.SECTION_MARGIN)
}
