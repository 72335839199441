import { useState, useEffect } from 'react'
import MetricsGrid from '../layouts/MetricsGrid'
import { commaNumber } from '../../helpers/utils'
import Grid from '../layouts/Grid'
import { ReactComponent as ImpressionsIcon } from '../../assets/icons/impressions-icon.svg'
import { ReactComponent as ClicksIcon } from '../../assets/icons/clicks-icon.svg'
import { ReactComponent as ConversionsIcon } from '../../assets/icons/conversions-icon.svg'
import { ReactComponent as LeadsIcon } from '../../assets/icons/leads-icon.svg'
import { ISemDetail } from '../../api/imi/interfaces'

interface Props {
  data: ISemDetail
  // totalData: SemTotals
}

const SemTotalsReport:React.FC<Props> = ({ data }) => {
  const [totals, setTotals] = useState<ISemDetail>([] as any)
  useEffect(() => {
    setTotals(data)
  }, [data])

  return (
    <Grid>
      <MetricsGrid
        name="impressions"
        imageUrl={<ImpressionsIcon />}
        value={totals?.impressions?.value && commaNumber(totals?.impressions?.value)}
        tooltipInfo="The number of times your ad was served."
      />
      <MetricsGrid
        name="clicks"
        imageUrl={<ClicksIcon />}
        value={totals?.clicks?.value && commaNumber(totals?.clicks?.value)}
        tooltipInfo="The number of times a user clicked on your ad."
      />
      <MetricsGrid
        name="conversions"
        imageUrl={<ConversionsIcon />}
        value={totals?.conversions?.value && commaNumber(totals?.conversions?.value)}
        tooltipInfo={(
          <>
            A conversion occurs when a visitor to your website completes a desired goal.
            <br />
            <br />
            Phone Call Conversions:&nbsp;
            <span className="font-bold">{totals?.conversions?.details.map((callout) => (callout.name.toLowerCase() === 'calls' && commaNumber(callout.value))) || 0}</span>
            <br />
            Web Event Conversions:&nbsp;
            <span className="font-bold">{totals?.conversions?.details.map((callout) => (callout.name.toLowerCase() === 'web events' && commaNumber(callout.value))) || 0}</span>
            <br />
            Email Conversions:&nbsp;
            <span className="font-bold">{totals?.conversions?.details.map((callout) => (callout.name.toLowerCase() === 'emails' && callout.value)) || 0}</span>
          </>
        )}
      />
      <MetricsGrid
        name="leads"
        imageUrl={<LeadsIcon />}
        value={totals?.leads?.value && totals?.leads?.value}
        tooltipInfo={(
          <>
            A lead refers to contact with a potential customer, also known as a &quot;prospect.&quot;
            <br />
            <br />
            Phone Call Leads*:&nbsp;
            <span className="font-bold">{totals?.leads?.details.map((callout) => (callout.name.toLowerCase() === 'calls' && callout.value)) || 0}</span>
            <br />
            Web Event Leads:&nbsp;
            <span className="font-bold">{totals?.leads?.details.map((callout) => (callout.name.toLowerCase() === 'web events' && commaNumber(callout.value))) || 0}</span>
            <br />
            Email Leads:&nbsp;
            <span className="font-bold">{totals?.leads?.details.map((callout) => (callout.name.toLowerCase() === 'emails' && callout.value)) || 0}</span>
            <br />
            <br />
            <span className="disclaimer">*If we are not processing your calls, we automatically categorize them as leads.</span>
          </>
        )}
      />
    </Grid>
  )
}

export default SemTotalsReport
