import {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { ChevronRightIcon, XIcon } from '@heroicons/react/solid'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Transition, Popover } from '@headlessui/react'
import { fixDateObjApi, handleFilters } from '../../helpers/utils'
import { IMIApi } from '../../api/imi/api'
import Icon from '../Icon'
import { ReactComponent as CampaignIcon } from '../../assets/icons/campaigns-icon.svg'
import Search from '../form/Search'
import { ICampaignFeedMultiResponse, ICampaignInDb } from '../../api/imi/interfaces'
import LinkWrapper from '../Links'
import { AppContext } from '../../contexts/AppContext'
import { ReportContext } from '../../contexts/ReportContext'
import useSessionStorage from '../../hooks/useSessionStorage'
import useLocalStorage from '../../hooks/useLocalStorage'

// User types:
// Admin, Team Viewer, Call Manager, Call Analyst, Client, Partner
const CampaignNavigation: React.FC = () => {
  const {
    setParamsNavigateNewest,
  } = useContext(ReportContext)

  const [allCampaigns, setAllCampaigns] = useState<ICampaignFeedMultiResponse[]>([] as any)
  const [loading, setLoading] = useState(true)
  const {
    setError,
    campaignData,
    setCampaignData,
  } = useContext(AppContext)
  const [filteredCampaigns, setFilteredCampaigns] = useState<ICampaignFeedMultiResponse[]>(allCampaigns)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
  })
  const [campId, setCampId] = useLocalStorage('params', {
    id: 0,
    start_date: '',
    end_date: '',
    cycle_id: '',
    roi_is_active: false,
  })

  useEffect(() => {
    const fetchCampaigns = async () => {
      const response = await IMIApi.GetCampaignFeed(true)
      // const response = await IMIApi.GetAllCampaignsPublic(true)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      // Total rows available as response.data.rows_total
      const activeCampaigns = response?.data
      setAllCampaigns(activeCampaigns)
      return setLoading(false)
    }
    fetchCampaigns()
  }, [setError])

  const determinePage = (adType) => {
    if (adType === 1) {
      return '/display-advertising'
    }
    if (adType === 2) {
      return '/search-engine-marketing'
    }
    if (adType === 3) {
      return '/video-advertising'
    }
    return null
  }

  const filters = useCallback(() => {
    const filterSearchValue = (campData) => {
      const splitItems = selectedFilters.search.split(';')
        .filter((si) => !!si)
        .map((item) => item.trim())
      const campaignFilter = campData.name.toLowerCase()
        .includes(splitItems)

      if (splitItems.length > 1) {
        const campaignFil = splitItems.some((si) => campData.name.toLowerCase()
          .includes(si))
        return campaignFil
      }
      return campaignFilter
    }

    const filterData = allCampaigns?.filter((campaign) => filterSearchValue(campaign))
    setFilteredCampaigns(filterData)
  }, [selectedFilters, allCampaigns])

  useEffect(() => {
    filters()
  }, [allCampaigns, filters])

  return (
    <Popover as="div" className="relative mr-5">
      {({ open }) => (
        <>
          <Popover.Button
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.stopPropagation()
              }
            }}
            data-testid="main-nav-adv-btn"
            className="text-primary bg-white rounded-full flex text-sm hover:text-default active:text-secondary focus:outline-none focus:text-secondary"
          >
            <span className="sr-only">Open campaign menu</span>
            <Icon
              size="lg"
              colors="text-primary"
              className="cursor-pointer group-hover:text-secondary"
            >
              <CampaignIcon
                className="text-primary hover:text-secondary"
                fill="none"
              />
            </Icon>
          </Popover.Button>
          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel
              className="nav-menu z-30 w-full sm:min-w-300 origin-top-right fixed sm:absolute right-0 mt-6 sm:w-auto border border-grey-light shadow-lg py-1 bg-white focus:outline-none h-96 overflow-hidden"
            >
              <div>
                <div className="flex">
                  <LinkWrapper
                    variant="menu"
                    href="/campaign-overview"
                    className="px-6 pt-4 group"
                  >
                    View All
                    <Icon size="xs" className="text-menu global-animation">
                      <ChevronRightIcon />
                    </Icon>
                  </LinkWrapper>
                  <Popover.Button>
                    <Icon size="sm" colors="grey" className="absolute right-0 top-0 px-3 py-3 group">
                      <XIcon />
                    </Icon>
                  </Popover.Button>
                </div>
                <span
                  className="border-t border-t-1 border-grey-light flex items-center font-base pl-6 pr-6 pt-6 text-sm text-default"
                >
                  <Search
                    value={selectedFilters.search}
                    onChange={(
                      (e) => setSelectedFilters(
                        handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
                      )
                    )}
                    label="search campaigns"
                    hideLabel
                    placeholder="search campaigns..."
                    className="max-w-none w-full"
                  />
                </span>
              </div>
              <ul className="px-6 pt-2 mr-3 overflow-y-scroll h-52 relative">
                {filteredCampaigns?.sort((a, b) => (a.name.localeCompare(b.name)) || (a?.secondary_name > b?.secondary_name ? 1 : -1))
                  .map((campaign) => (
                    <li key={campaign.id} className="-mb-0.5">
                      <div>
                        <Popover.Button className="text-left">
                          <LinkWrapper
                            variant="menu"
                            onClick={() => setParamsNavigateNewest(campaign.id)}
                            href={{
                              pathname: determinePage(campaign.type.id),
                            }}
                          >
                            {campaign.name}
                            {campaign?.secondary_name && (
                              `: ${campaign.secondary_name}`
                            )}
                            {campaign?.specific?.name && (
                              ` - ${campaign?.specific?.name}`
                            )}
                            {campaign?.type?.short_name && (
                              ` | ${campaign?.type?.short_name}`
                            )}
                          </LinkWrapper>
                        </Popover.Button>
                      </div>
                    </li>
                  ))}
              </ul>
              <div className="text-center absolute w-full bottom-0 bg-white">
                <Icon
                  className=""
                  size="sm"
                >
                  <ChevronDownIcon />
                </Icon>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default CampaignNavigation
