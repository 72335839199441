import { 
  useContext, 
  useState, 
  useEffect, 
  useCallback,
  useMemo,
} from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { commaNumber, fixDateObj } from '../../helpers/utils'
import BadgeDot from '../badge/BadgeDot'
import BadgeInfo from '../badge/BadgeInfo'
import Content from '../layouts/Content'
import ToolTip from '../ToolTip'
import { IDisplayDetail } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: IDisplayDetail
}

const DisplayActivityTrendsReport:React.FC<Props> = ({
  children,
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const [activityTrends, setActivityTrends] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [chart, setChart] = useState<any>({})
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'
  const colorMap = useMemo(() => ({
    'post-impressions': colors.primary,
    'post-clicks': colors.tertiary,
    activities: '#a7b1ae',
  }), [colors])
  // eslint-enable
  const buildChart = useCallback((sd: any [], categories: any[]) => ({
    chart: {
      type: 'column',
      height: 300,
    },
    title: {
      text: '',
    },
    legend: {
      className: 'test',
      paddingBottome: 15,
      align: 'left',
      verticalAlign: 'top',
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter() {
        const { point } = this as any
        let postImps = 0
        let postClicks = 0
        let activities = 0
        const categoryIndex = categories.indexOf(point?.category)
        sd.forEach((c) => {
          if (c.name === 'Post-Impressions') {
            postImps = c.data[categoryIndex]
          }
          if (c.name === 'Post-Clicks') {
            postClicks = c.data[categoryIndex]
          }
          if (c.name === 'Activities') {
            activities = c.data[categoryIndex]
          }
        })

        // const cte = clicks > 0 && webEvents > 0 ? (webEvents / clicks) * 100 : 0
        // const ctc = clicks > 0 && calls > 0 ? (calls / clicks) * 100 : 0
        return (
          `<span style="color: #fff; font-family: Nunito Sans; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point?.category}</span>
           <span style="background-color: ${colorMap['post-impressions']}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">Post-Impressions: <b>${postImps}</b></span><br>
           <span style="background-color: ${colorMap['post-clicks']}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">Post-Clicks: <b>${postClicks}</b></span><br>
           <span style="background-color: ${colorMap.activities}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">Activities: <b>${postClicks + postImps}</b></span><br>`
        )
      },
    },
    xAxis: {
      useHtml: true,
      lineColor: '#dfe5e2',
      categories,
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
    },
    yAxis: {
      min: 0,
      lineColor: '#dfe5e2',
      title: {
        text: 'Activities',
      },
      stackLabels: {
        enabled: false,
        labels: {
          style: {
            fontFamily: 'Heebo',
            fontSize: '12px',
            color: 'rgba(40, 42, 57, 0.6)',
          },
        },
      },
    },
    series: sd,
    plotOptions: {
      series: {
        maxPointWidth: 10,
        // minPointLength: 5,
        states: {
          hover: {
            brightness: 0,
            opacity: 1,
          },
          inactive: {
            opacity: 0.7,
          },
        },
      },
      column: {
        allowOverlap: false,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
  }), [colorMap])

  useEffect(() => {
    setActivityTrends(data)
    setLoading(false)
  }, [data])

  useEffect(() => {
    if (activityTrends?.activity_trends?.series) {
      const seriesData = activityTrends?.activity_trends?.series
      const chartOpts: any[] = []
      seriesData.forEach((s: { name: string; data: any[] }) => {
        if (s?.name === 'Post-Impressions' || s?.name === 'Post-Clicks') {
          chartOpts.push({
            name: s?.name,
            data: s?.data?.sort((a, b) => (a.x > b.x ? 1 : -1)).map((d: { y: any }) => d.y),
            color: colorMap[s?.name.toLowerCase()],
            categories: s?.data?.sort((a, b) => (a.x > b.x ? 1 : -1)).map((d: { x: any }) => d.x),
          })
        }
      })
      setChart(buildChart(chartOpts, chartOpts?.[0]?.categories?.map((date: any) => fixDateObj(date))))
    }
  }, [colorMap, buildChart, activityTrends?.activity_trends?.series])

  return (
    <Content
      title="activity trends"
      icon={(
        <ToolTip
          tipId="acttrends"
          size="sm"
          position="block relative pr-0"
        >
          Activities are made up of post-impressions and post-clicks. Post-impressions are website visits from a user who was served your ad and did not click, but then came back to your website at a later time. Post-clicks are conversion actions taken after a user clicks your ad.
        </ToolTip>
      )}
    >
      {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <>
          {activityTrends?.activity_trends?.series?.length > 0 ? (
            <>
              <div className="pb-6">
                <BadgeDot variant="dot" dotColor="bg-primary" label="Post-Impressions" />
                <BadgeDot variant="dot" dotColor="bg-tertiary" label="Post-Clicks" />
              </div>
              <div id="calls_clicks_web_events">
                <HighchartsReact
                  id="calls_clicks_web_events"
                  highcharts={Highcharts}
                  options={chart}
                />
              </div>
            </>
          ) : (
            <p>{noDataMessage}</p>
          )}
        </>
      )}
    </Content>
  )
}

export default DisplayActivityTrendsReport
