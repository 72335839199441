// NOTE: If campaign has been assigned/created, then this will direct them
// to the EditRoiPage where they can change settings

import {
  useContext,
} from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import Content from '../../../layouts/Content'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import { CampaignContext } from '../../../../contexts/CampaignContext'

const RoiSettingsCTA:React.FC = () => {
  const { campaignInDb } = useContext(CampaignContext)

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  return (
    <>
      <Content
        title="return on investment (roi)"
      >
        <p>In order to show the ROI profitability metric to our clients, our SEM reports can include a dedicated ROI section. Whether or not we include that section can vary from cycle to cycle. To view or edit the ROI settings for this campaign and its cycles, go to the &quot;return on investment&quot; page.</p>
        <br />
        <LinkWrapper
          disabled={campaignInDb.id < 1}
          target="_blank"
          href="/admin-tools/return-on-investment/edit-return-on-investment"
          variant="defaultAdmin"
          className="mb-6 -mt-3 block"
          onClick={() => handleLocalStorage(campaignInDb.id)}
        >
          Go to Return on Investment (ROI)
          <Icon size="xxs" className={`${campaignInDb.id < 1}` ? 'cursor-not-allowed opacity-60' : ''}>
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper>
      </Content>
    </>
  )
}

export default RoiSettingsCTA
