import { PDFFont, RGB } from 'pdf-lib'
import { Fonts } from './fonts'
import { Pages } from './pages'

const LINE_SPACE = 4

export enum Font {
  Nunito,
  Heebo,
}

export enum FontSizes {
  Normal = 10,
  Large = 17,
  XLarge = 27,
}

export enum FontAlignment {
  Center,
  Left,
}

export enum FontStyle {
  Regular,
  Light,
  Bold,
}

type FontOptions = {
  width?: number
  font?: Font
  size?: FontSizes
  alignment?: FontAlignment
  style?: FontStyle
}

const getTextLines = (text: string[], size: FontSizes, maxWidth: number, font: PDFFont) => {
  const lines: string[] = []

  text.forEach((line) => {
    const words = line.split(' ')

    words.forEach((word, i) => {
      if (i > 0) {
        const currentLine = `${lines[lines.length - 1]} ${word}`

        if (font.widthOfTextAtSize(currentLine, size) > maxWidth) {
          lines.push(word)
        } else {
          lines[lines.length - 1] = currentLine
        }
      } else {
        lines.push(word)
      }
    })
  })

  return lines
}

const drawText = (
  lines: string[], 
  height:number, 
  width: number, 
  alignment: FontAlignment, 
  size: FontSizes,
  color: RGB,
  font: PDFFont,
  pages: Pages,
) => {
  let distance = 0
  lines.forEach((line) => {
    const x = alignment === FontAlignment.Center
      ? width / 2 - font.widthOfTextAtSize(line, size) / 2
      : 0

    pages.moveRight(x)
    pages.moveDown(height)
    pages.currentPage.drawText(line, {
      x: pages.currentX,
      y: pages.currentY,
      size,
      font,
      color,
    })
    pages.moveLeft(x)
    pages.moveDown(LINE_SPACE)
    distance += height + LINE_SPACE
  })
  pages.moveUp(distance)
}

const getFont = (font: Font, style: FontStyle, fonts: Fonts) => {
  if (style === FontStyle.Regular) {
    if (font === Font.Nunito) {
      return fonts.nunito
    }
    return fonts.heebo
  }
  if (style === FontStyle.Light) {
    return fonts.nunitoLight
  }
  return fonts.nunitoBold
}

export type Text = {
  drawText: (text: string, color: RGB, options?: FontOptions) => ({ width: number, height: number, render: () => void })
  drawMultiLineText: (text: string[], color: RGB, options?: FontOptions) => ({ width: number, height: number, render: () => void })
}

export const getText = (
  fonts: Fonts, 
  pages: Pages,
): Text => ({
  drawText: (
    text: string, 
    color: RGB,
    { 
      width = 0,
      font = Font.Nunito,
      size = FontSizes.Normal,
      alignment = FontAlignment.Left,
      style = FontStyle.Regular,
    } = {
      width: 0,
      font: Font.Nunito,
      size: FontSizes.Normal,
      alignment: FontAlignment.Left,
      style: FontStyle.Regular,
    },
  ) => {
    const textFont = getFont(font, style, fonts)
    const height = textFont.heightAtSize(size) / 2
    const lines = width > 0 ? getTextLines([text], size, width, textFont) : [text]

    return {
      height: width > 0 ? lines.length * height + (lines.length - 1) * LINE_SPACE : height,
      width: width > 0 ? width : textFont.widthOfTextAtSize(text, size),
      render: () => drawText(
        lines, 
        height, 
        width, 
        alignment, 
        size, 
        color, 
        textFont, 
        pages,
      ),
    }
  },
  drawMultiLineText: (
    text: string[], 
    color: RGB,
    { 
      width = 0,
      font = Font.Nunito,
      size = FontSizes.Normal,
      alignment = FontAlignment.Left,
      style = FontStyle.Regular,
    } = {
      width: 0,
      font: Font.Nunito,
      size: FontSizes.Normal,
      alignment: FontAlignment.Left,
      style: FontStyle.Regular,
    },
  ) => {
    const textFont = getFont(font, style, fonts)
    const height = textFont.heightAtSize(size) / 2
    const lines = getTextLines(text, size, width, textFont)

    return {
      height: lines.length * height + (lines.length - 1) * LINE_SPACE,
      width,
      render: () => drawText(
        lines, 
        height, 
        width, 
        alignment, 
        size, 
        color, 
        textFont, 
        pages,
      ),
    }
  },
})
