import { LogoutIcon } from '@heroicons/react/outline'
import { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import LinkWrapper from '../Links'

const Logout = () => {
  const { user, signOutUserClicked } = useContext(AppContext)

  return (
    <LinkWrapper
      variant="menu"
      href="/"
      onClick={signOutUserClicked}
      className={`${user.user_type_id < 5 || user.advertisers.length > 1 ? 'border-t border-t-1 border-grey-light pt-6' : ''} group stroke-text-primary pl-6 pr-6 mt-6 flex items-center font-base px-4 text-sm text-default hover:text-secondary hover:font-normal`}
    >
      <LogoutIcon className="text-primary group-hover:text-secondary h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" />
      Log Out
    </LinkWrapper>
  )
}

export default Logout
