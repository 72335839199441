import { useHistory } from 'react-router-dom'
import Button from './Button'

interface Props {
  path: string
  createText: string
}

const CreateButtons:React.FC<Props> = ({
  path,
  createText,
}) => {
  const history = useHistory()
  const handleClick = () => {
    history.push(path)
  }
  return (
    <>
      <Button
        id="create-btn-mobile"
        size="sm"
        onClick={handleClick}
        submit="button"
        variant="primary"
        className="sm:hidden"
      >
        + Create
      </Button>
      <Button
        id="create-btn-desktop"
        size="admin"
        onClick={handleClick}
        submit="button"
        variant="primary"
        className="hidden sm:flex sm:flex-grow-0 w-full max-w-tooltip"
      >
        {`+ Create ${createText}`}
      </Button>
    </>
  )
}

export default CreateButtons
