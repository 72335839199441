/*
  UserTypes.ts
  Describes the UserTypes for the IMI Dashboard
  Created by Spence Wetjen on 2022-11-16 for Encompass Agency
  Copyright = 2022 Encompass Agency.  All rights reserved.
 */

export interface IUserType {
  isAdmin: () => boolean
  isTeamViewer: () => boolean
  isCallManager: () => boolean
  isCallAnalyst: () => boolean
  isPartner: () => boolean
  isClient: () => boolean
  isAnyEncompass: () => boolean
  isAnyExceptCallAnalyst: () => boolean
}

// User Type Constants
export const Admin = 1
export const TeamViewer = 2
export const CallManager = 3
export const CallAnalyst = 4
export const Partner = 5
export const Client = 6
