import {
  Admin, CallAnalyst, CallManager, Client, Partner, TeamViewer, 
} from '../../constants/UserTypes'

export const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// "table" header for daily report details
export const placementBreakdownMenu = [
  {
    fieldName: 'placements',
    name: 'Placement',
    mobile: false,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'impressions',
    name: 'Impressions',
    abbreviation: 'Imp',
    mobile: false,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'percent_total_impressions',
    name: '% of Total Impressions',
    abbreviation: '% of Total Impr',
    mobile: true,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'activities',
    name: 'Activities',
    abbreviation: 'Act',
    mobile: false,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'cpa',
    name: 'Cost Per Activity (CPA)',
    abbreviation: 'CPA',
    mobile: false,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'spend', // Field does not exist unless the user is an admin
    name: 'Spend',
    mobile: true,
    sort: true,
    forUserTypes: [Admin],
  },
]

export const activityBreakdownMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
  },
  {
    fieldName: 'post_impressions',
    name: 'Post-Impressions',
    abbreviation: 'Pst-Impr',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'post_clicks',
    name: 'Post-Clicks',
    abbreviation: 'Pst-Clks',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'activities',
    name: '# of Activities',
    abbreviation: '# of Act',
    mobile: false,
    sort: true,
  },
]

export const creativePerformanceMenu = [
  {
    fieldName: 'label',
    name: 'Creative Label',
    mobile: false,
  },
  {
    fieldName: 'impressions',
    name: 'Impressions',
    abbreviation: 'Impr',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'activities',
    name: 'Activities',
    abbreviation: 'Act',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'clicks',
    name: 'Clicks',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'ctr',
    name: 'Click-Through Rate (CTR)',
    abbreviation: 'CTR',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'cpa',
    name: 'Cost Per Activity (CPA)',
    abbreviation: 'CPA',
    mobile: true,
    sort: true,
  },
]
