import ReactTooltip from 'react-tooltip'
import { ReactComponent as InfoIcon } from '../assets/icons/info-icon.svg'
import Icon from './Icon'

interface Props {
  children?: React.ReactNode
  position: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  className?: string
  tipId: string
}

const ToolTip:React.FC<Props> = ({
  children,
  position,
  size = 'xs',
  className,
  tipId,
}) => (
  <div data-tip data-for={tipId} className={`absolute pt-0 max-w-200 ${position} inline tooltip`}>
    <Icon size={size} className="hover:cursor-help"><InfoIcon /></Icon>
    <ReactTooltip place="top" id={tipId} clickable effect="solid" className="py-3 leading-4.5 font-base font-normal break-normal" backgroundColor="rgb(34 28 34 / 90%)">
      {children}
    </ReactTooltip>
  </div>
)

export default ToolTip
