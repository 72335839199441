import {
  Dispatch, SetStateAction, useCallback, useContext, useEffect, useState, 
} from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { getFieldByPath, handleFilters } from '../../../../helpers/utils'
import CreateButtons from '../../../button/CreateButtons'
import Search from '../../../form/Search'
import Content from '../../../layouts/Content'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Icon from '../../../Icon'
import { callInstructionsMenu, settingsStyles } from '../Data'
import LoadingSpinner from '../../../LoadingSpinner'
import EditMenu from '../../EditMenu'
import LinkWrapper from '../../../Links'
import Modal from '../../../modal'
import { ICallInstruction, ICallInstructionsMultiResponse } from '../../../../api/imi/interfaces'
import { IMIApi } from '../../../../api/imi/api'

interface Props {
  instructions: ICallInstructionsMultiResponse
  isLoading: boolean
  setRefresh: Dispatch<SetStateAction<boolean>>
}

const CallInstructions: React.FC<Props> = ({
  instructions,
  isLoading,
  setRefresh,
}) => {
  const { setError } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [btnText, setBtnText] = useState('Delete')
  const [cancelText, setCancelText] = useState('Cancel')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [currentCampaign, setCurrentCampaign] = useState<any>()
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
  })
  const [errorMsg, setErrorMsg] = useState('There are no instructions found.')
  const [filteredInstructions, setFilteredInstructions] = useState<ICallInstruction[]>([] as any)

  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('')
  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = sortDirection ? filteredInstructions?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1)) : filteredInstructions?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1))
    return { values }
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('instruction_id', JSON.stringify(id))
  }
  const filters = useCallback(() => {
    const filterSearchValue = (instructionData) => {
      const splitItems = selectedFilters.search.split(';')
        .filter((si) => !!si)
        .map((item) => item.trim())
      const instructionFilter = instructionData?.campaign?.name?.toLowerCase()
        .includes(splitItems)

      if (splitItems.length > 1) {
        const advertiserFil = splitItems.some((si) => instructionData?.campaign?.name?.toLowerCase()
          .includes(si))
        return advertiserFil
      }
      return instructionFilter
    }

    const filterData = instructions?.data?.filter((instruction) => filterSearchValue(instruction))
    setFilteredInstructions(filterData)
  }, [selectedFilters, instructions])

  useEffect(() => {
    filters()
  }, [instructions, filters])

  const modal = (campaign) => {
    setCancelText('Cancel')
    setBtnText('Delete')
    setCurrentCampaign(campaign)
    setShowModal(true)
  }

  const deleteInstructions = async (currentId) => {
    setIsButtonLoading(true)
    setCancelText('')
    setBtnText('Saving...')
    const submitResponse = await IMIApi.DeleteCallInstructions(currentId)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setRefresh(true)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setIsButtonLoading(false)
      return setShowModal(false)
    }, 2000)
  }

  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Delete"
        body={(
          `Are you sure you want to delete instructions for ${currentCampaign?.campaign?.name}?`
        )}
        onSubmit={() => deleteInstructions(currentCampaign.id)}
        onClose={() => setShowModal(false)}
        closeText={cancelText}
        submitText={btnText}
      />
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Instructions"
            path="/admin-tools/call-processing-instructions/create-instructions"
          />
        </div>
      </div>
      <Content
        title="current instructions"
        padding={false}
      >
        <>
          {isLoading ? (
            <LoadingSpinner variant="primary" size="lg" />
          ) : (
            <>
              <HorizontalGrid
                smGrid="grid-cols-2"
                mdGrid="md:grid-cols-2"
              >
                {callInstructionsMenu.map((item) => (
                  item.sort ? (
                    <span
                      aria-hidden="true"
                      onClick={() => columnSortingInfo(item.fieldName)}
                      key={item.name}
                      className={(
                        `flex break-word group hover:text-secondary hover:cursor-pointer
                        ${item.mobile && settingsStyles.desktop}`
                      )}
                    >
                      {item.name}
                      <Icon
                        size="xxs"
                        className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                      >
                        <ChevronUpIcon
                          className="-mb-1 text-default group-hover:text-secondary"
                        />
                        <ChevronDownIcon
                          className="text-default group-hover:text-secondary"
                        />
                      </Icon>
                    </span>
                  ) : <span key={item.name}>{item.name}</span>
                ))}
              </HorizontalGrid>
              <div className="items-center">
                {filteredInstructions?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                  filteredInstructions?.map((instruction) => (
                    <HorizontalGrid
                      key={instruction.id}
                      smGrid="grid-cols-2"
                      mdGrid="md:grid-cols-2"
                      backgroundColor
                    >
                      <p className="text-xxs text-gray-500">
                        {instruction.campaign.name}
                        {instruction?.campaign.secondary_name && (
                          `: ${instruction?.campaign.secondary_name}`
                        )}
                        {instruction?.campaign.specific && (
                          ` - ${instruction?.campaign.specific}`
                        )}
                      </p>
                      <p className="text-xxs pr-6">
                        <LinkWrapper
                          variant="defaultSm"
                          key={instruction.id}
                          onClick={() => handleLocalStorage(instruction.id)}
                          href={{
                            pathname: '/admin-tools/call-processing-instructions/edit-instructions',
                            state: {
                              id: instruction.id,
                            },
                          }}
                        >
                          {instruction.body}
                        </LinkWrapper>
                      </p>
                      <EditMenu
                        text="Edit Instructions"
                        id={instruction.id}
                        pathname="/admin-tools/call-processing-instructions/edit-instructions"
                        status={instruction.id > 0}
                        onClickEdit={() => handleLocalStorage(instruction.id)}
                        onClick={() => modal(instruction)}
                        deleteText="Delete Instructions"
                      />
                    </HorizontalGrid>
                  ))
                )}
              </div>
            </>
          )}
        </>
      </Content>
    </>
  )
}

export default CallInstructions
