import { useFormContext } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import LinkWrapper from '../../Links'
import Icon from '../../Icon'
import Input from '../Input'
import { IEmailTemplateInDbDetail } from '../../../api/imi/interfaces'
import TextArea from '../TextArea'

interface Props {
  errors: any
  showButton?: boolean
  template?: IEmailTemplateInDbDetail
}

const AccountEmailTemplateForm:React.FC<Props> = ({
  showButton,
  errors,
  template,
}) => {
  const methods = useFormContext()
  const deleteGraphic = () => {
    methods.setValue('logo_url', '')
  }
  const watchLogo = methods.watch('logo_url')
  const logo = methods.getValues('logo_url')
  // const logoName = methods.getValues('name')
  return (
    <>
      <Input
        disabled={!showButton}
        label="Name"
        type="text"
        toolTipInfo="Email template name should match the advertiser's / partner's name identically. Do not include LLC or Inc. in the email template name."
        {...methods.register('name', { required: true })}
        error={errors.name && <span className="form-error">{errors.name.message || 'This field is required.'}</span>}
      />
      <Input
        disabled={!showButton}
        label="Logo"
        type="text"
        toolTipInfo="Please only use a PNG format with a max-width of 300px. If one is not available, please note that the logo might look different on emails utilizing dark mode."
        {...methods.register('logo_url', {
          required: true,
        })}
        error={errors.logo_url && <span className="form-error">{errors.logo_url.message || 'This field is required.'}</span>}
      />
      {watchLogo && (
        <>
          <div className="w-full max-w-form font-base text-xs text-default rounded border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none">
            <span className="block text-center sm:flex items-center justify-center">
              <img src={template?.id ? template.logo_url : logo} alt={`${template?.id && template.name} logo`} className="my-0 mx-auto sm:m-0 w-full max-w-btn-md" />
              <span className="sm:pl-3 break-all">{template?.id ? template.logo_url : logo}</span>
            </span>
          </div>
          <LinkWrapper
            disabled={!showButton}
            href="#"
            variant="defaultAdmin"
            onClick={deleteGraphic}
            className="mb-6 inline-block"
          >
            Delete Graphic
            <Icon size="xxs">
              <ChevronRightIcon />
            </Icon>
          </LinkWrapper>
        </>
      )}
      <Input
        disabled={!showButton}
        label="From email"
        type="email"
        toolTipInfo="The email you want displayed as the sender. For enCOMPASS, it is imi@encompassagency.com."
        {...methods.register('from_email', {
          required: true,
          pattern: {
            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: 'The email you have entered is not a valid email address',
          },
        })}
        error={errors.from_email && <span className="form-error">{errors.from_email.message || 'This field is required.'}</span>}
      />
      <Input
        disabled={!showButton}
        label="From name"
        type="text"
        toolTipInfo="The name appearing as the sender, usually the company name."
        {...methods.register('from_name', {
          required: true,
        })}
        error={errors.from_name && <span className="form-error">{errors.from_name.message || 'This field is required.'}</span>}
      />
      <Input
        disabled={!showButton}
        label="Call-to-action (CTA) text"
        type="text"
        toolTipInfo="Text that hyperlinks the displayed URL to direct users to log in."
        {...methods.register('cta_text', {
          required: true,
        })}
        error={errors.cta_text && <span className="form-error">{errors.cta_text.message || 'This field is required.'}</span>}
      />
      <Input
        disabled={!showButton}
        label="Displayed URL"
        type="text"
        toolTipInfo={(
          <span>
            Do not include &quot;www&quot; in the URL. The default URL structure is
            <span className="break-all">
              &nbsp;
              &quot;
              https://[partnername].imidashboard.com
              &quot;
              .
            </span>
            &nbsp;
            However, if our partners create a custom URL, that should be entered in here.
          </span>
        )}
        {...methods.register('displayed_url', {
          required: true,
          pattern: {
            value: /(https:\/\/((?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|((!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/((!www))[a-zA-Z0-9]+\.[^\s]{2,}|((!www)).[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            // value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
            message: 'Your website is not the correct format. It must be https, and show the full url path without www. Example: https://encompass.imidashboard.com',
          },
        })}
        error={errors.displayed_url && <span className="form-error">{errors.displayed_url.message || 'This field is required.'}</span>}
      />
      <hr />
      <h4 className="mb-3">weekly campaign summary report email</h4>
      <Input
        disabled={!showButton}
        label="Subject"
        type="text"
        tipId="lre_subject"
        toolTipInfo="{{ environment_if }} determines if the email is a test or not. If the email is a test, 'Dev' is included in the subject line because of this code. Please do not remove."
        {...methods.register('lre_subject', {
          required: true,
        })}
        error={errors.lre_subject && <span className="form-error">{errors.lre_subject.message || 'This field is required.'}</span>}
      />
      <TextArea
        disabled={!showButton}
        label="Content"
        tipId="lre_content"
        toolTipInfo="Please leave the formatting and text inside the double brackets as is. This is where data is inserted into reports from the database. Ex: {{ dashboard_login_link }}."
        {...methods.register('lre_content', {
          required: true,
        })}
        error={errors.lre_content && <span className="form-error">{errors.lre_content.message || 'This field is required.'}</span>}
      />
      <hr />
      <h4 className="mb-3">flagged call report email</h4>
      <Input
        disabled={!showButton}
        label="Subject"
        tipId="fcre_subject"
        type="text"
        toolTipInfo="{{ environment_if }} determines if the email is a test or not. If the email is a test, 'Dev' is included in the subject line because of this code. Please do not remove."
        {...methods.register('fcre_subject', {
          required: true,
        })}
        error={errors.fcre_subject && <span className="form-error">{errors.fcre_subject.message || 'This field is required.'}</span>}
      />
      <TextArea
        disabled={!showButton}
        label="Content"
        tipId="fcre_content"
        toolTipInfo="Please leave the formatting and text inside the double brackets as is. This is where data is inserted into reports from the database. Ex: {{ dashboard_login_link }}."
        {...methods.register('fcre_content', {
          required: true,
        })}
        error={errors.fcre_content && <span className="form-error">{errors.fcre_content.message || 'This field is required.'}</span>}
      />
    </>
  )
}

export default AccountEmailTemplateForm
