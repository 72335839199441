import {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IMIApi } from '../../../api/imi/api'
import {
  advertiserSettingsMenu,
  advertiserSettingsStatus,
  advertiserSettingsStyles,
} from './Data'
import HorizontalGrid from '../../layouts/HorizontalGrid'
import Content from '../../layouts/Content'
import Icon from '../../Icon'
import Search from '../../form/Search'
import LinkWrapper from '../../Links'
import EditMenu from '../EditMenu'
import Dropdown from '../../form/Dropdown'
import BadgeAlert from '../../badge/BadgeAlert'
import AdminToolsMobileLink from '../AdminToolsMobileLink'
import CreateButtons from '../../button/CreateButtons'
import { handleFilters } from '../../../helpers/utils'
import Modal from '../../modal'
import { IAdvertiserInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import LoadingSpinner from '../../LoadingSpinner'

// TODO: fix double clicking on account type sorting
// TODO: advertiser filter

interface Props {
  isLoading: boolean
  advertisers: IAdvertiserInDb []
  refresh: Dispatch<SetStateAction<boolean>>
}

const AdvertiserSettings:React.FC<Props> = ({
  isLoading,
  advertisers,
  refresh,
}) => {
  const { setError } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [btnText, setBtnText] = useState('Change')
  const [cancelText, setCancelText] = useState('Cancel')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [filteredAdvertisers, setFilteredAdvertisers] = useState<IAdvertiserInDb[]>(advertisers)
  const [errorMsg, setErrorMsg] = useState('There are no advertisers found.')
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState<string>('')
  // true is descending (highest to lowest, z-a), false is ascending (lowest to highest, a-z)
  const [sortDirection, setSortDirection] = useState<boolean>(false)
  const [currentAdvertiser, setCurrentAdvertiser] = useState<IAdvertiserInDb>()
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })

  const handleLocalStorage = (id) => {
    localStorage.setItem('advertiser_id', JSON.stringify(id))
  }

  // main filter to check advertiser name name
  // based on status of advertiser
  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const advertiserStatus = status.is_active
      if (advertiserStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }

    const filterSearchValue = (advertiserData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const advertiserFilter = advertiserData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const advertiserFil = splitItems.some((si) => advertiserData.name.toLowerCase().includes(si))
        return advertiserFil
      }
      return advertiserFilter
    }

    const filterData = advertisers?.filter((advertiser) => filterSearchValue(advertiser) && filterStatus(advertiser))
    setFilteredAdvertisers(filterData)
  }, [selectedFilters, advertisers])

  useEffect(() => {
    filters()
  }, [advertisers, filters])

  // TODO: fix this sorting error
  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = sortDirection ? filteredAdvertisers?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1)) : filteredAdvertisers?.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1))
    return { values }
  }

  const modal = (advertiser) => {
    setCancelText('Cancel')
    setBtnText('Change')
    setCurrentAdvertiser(advertiser)
    setShowModal(true)
  }

  const changeStatus = async (advertiserStatus, data) => {
    const formData = {
      ...data,
      is_active: !advertiserStatus,
    }
    setIsButtonLoading(true)
    setCancelText('')
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateAdvertiser(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      refresh(true)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setIsButtonLoading(false)
      return setShowModal(false)
    }, 2000)
    refresh(false)
  }
  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Change"
        body={(
          `Change advertiser ${currentAdvertiser?.name}'s status to
            ${currentAdvertiser?.is_active ? 'inactive' : 'active'}?`
        )}
        onSubmit={() => changeStatus(currentAdvertiser?.is_active, currentAdvertiser)}
        onClose={() => setShowModal(false)}
        closeText={cancelText}
        submitText={btnText}
      />
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Advertiser"
            path="/admin-tools/advertiser-settings/create-advertiser"
          />
        </div>
      </div>
      <Content
        title={(
          selectedFilters.status === 0 ? 'Active Advertisers' : 'Inactive Advertisers'
        )}
        padding={false}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={advertiserSettingsStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
        badge={(
          <BadgeAlert
            alert={filteredAdvertisers?.length || 0}
            status={selectedFilters.status === 0}
          />
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-1"
              mdGrid="md:grid-cols-1"
            >
              {advertiserSettingsMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && advertiserSettingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredAdvertisers?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filteredAdvertisers?.map((advertiser) => (
                  <HorizontalGrid
                    key={advertiser.id}
                    smGrid="grid-cols-1"
                    mdGrid="md:grid-cols-1"
                    backgroundColor
                  >
                    <p className="text-xxs pr-6">
                      <LinkWrapper
                        variant="defaultSm"
                        key={advertiser.id}
                        onClick={() => handleLocalStorage(advertiser.id)}
                        href={{
                          pathname: '/admin-tools/advertiser-settings/edit-advertiser',
                        }}
                      >
                        {advertiser.name}
                      </LinkWrapper>
                    </p>
                    <EditMenu
                      text="Edit Advertiser"
                      id={advertiser.id}
                      pathname="/admin-tools/advertiser-settings/edit-advertiser"
                      status={advertiser.is_active}
                      changeStatus
                      onClickEdit={() => handleLocalStorage(advertiser.id)}
                      onClick={() => modal(advertiser)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default AdvertiserSettings
