import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import Icon from '../Icon'
import LinkWrapper from '../Links'

interface Props {
  text: string
  id: number
  status?: boolean
  pathname?: string
  onClick?: any
  onClickEdit?: any
  onClickDelete?: () => void
  changeStatus?: boolean
  deleteText?: string
  children?: React.ReactNode
  target?: string
}

const EditMenu:React.FC<Props> = ({
  text,
  id,
  pathname,
  status,
  onClick,
  onClickDelete,
  deleteText,
  changeStatus,
  children,
  onClickEdit,
  target,
}) => (
  <Menu as="div" className="absolute right-3 md:right-6 inline-block text-left">
    <div>
      <Menu.Button>
        <span className="sr-only">Open options</span>
        <Icon size="sm"><DotsVerticalIcon aria-hidden="true" /></Icon>
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-44 px-6 py-2 rounded z-10 bg-grey-darkest bg-opacity-90 focus:outline-none">
        <div className={`${pathname && 'py-1'}`}>
          <Menu.Item>
            {!children ? (
              <LinkWrapper
                variant="dark"
                href={pathname}
                className={`${pathname ? 'block' : 'hidden'}`}
                target={target}
                onClick={changeStatus || onClickEdit ? onClickEdit : onClick}
              >
                {text}
              </LinkWrapper>
            ) : (
              children
            )}
          </Menu.Item>
        </div>
        {changeStatus && (
          <div className="py-1">
            <Menu.Item>
              <LinkWrapper
                href="#"
                variant="dark"
                onClick={onClick}
              >
                <p>{status}</p>
                Make
                {status ? ' Inactive' : ' Active'}
              </LinkWrapper>
            </Menu.Item>
          </div>
        )}
        {deleteText && (
          <div className="py-1">
            <Menu.Item>
              <LinkWrapper
                href="#"
                variant="dark"
                onClick={onClick}
              >
                {deleteText}
              </LinkWrapper>
            </Menu.Item>
          </div>
        )}
      </Menu.Items>
    </Transition>
  </Menu>
)

export default EditMenu
