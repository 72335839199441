import { ENV } from '../../env'

const Footer = () => (
  <footer>
    <div className="px-6 lg:px-0">
      <div className="py-8 text-sm text-gray-500 text-left">
        <p className="block sm:inline text-xxs">
          &copy;
          &nbsp;
          {(new Date().getFullYear())}
          &nbsp;
          Interactive Marketing Intelligence™. All rights reserved.
        </p>
        { ENV.appEnvironment !== 'production' && (
          <span className="block sm:inline text-xxs">
            {ENV.imiApiUrl}
            /documentation/
          </span>
        )}
      </div>
    </div>
  </footer>
)

export default Footer
