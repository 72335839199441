import { useState, useContext, useEffect } from 'react'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { ChevronRightIcon, TrashIcon } from '@heroicons/react/outline'
import Modal from '../../../modal'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import Input from '../../Input'
import Radio from '../../Radio'
import Dropdown from '../../Dropdown'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import { ICampaign, ICampaignCategory, ICampaignInDb } from '../../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  callIndustries: ICampaignCategory[]
  campaign: ICampaignInDb
}

const CallSettingsForm:React.FC<Props> = ({
  showButton,
  errors,
  callIndustries,
  campaign,
  // callCategories,
}) => {
  const methods = useFormContext()
  const watchCallProcess = methods.watch('call_processing_is_active', { shouldDirty: true })

  return (
    <>
      <Radio
        name="call_processing_is_active"
        label="Does this campaign's current cycle need call processing?"
        disabled={!showButton || campaign?.id <= 0}
        options={[
          {
            id: 1,
            label: 'Yes',
            value: true,
          },
          {
            id: 0,
            label: 'No',
            value: false,
          },
        ]}
      />
      {((watchCallProcess === 'true') || (campaign.call_processing_is_active)) && (
        <>
          {callIndustries?.length && (
            <Controller
              name="category_id"
              control={methods.control}
              rules={{ required: true }}
              render={({
                field,
              }) => (
                <>
                  <Dropdown
                    disabled={!showButton}
                    hookFormField={field}
                    placeholder={campaign.category?.id > 0 ? undefined : 'Select...'}
                    label="Call industry"
                    variant="admin"
                    defaultValue={methods.getValues('category_id')}
                    list={callIndustries}
                    error={errors.id && <span className="form-error">This field is required</span>}
                    toolTipInfo={(
                      <>
                        The call industry determines the categories that are available while processing calls. A selected industry applies to the whole campaign and cannot vary per cycle. To see a list of all call industries with their associated categories, refer to the &quot;Call Category Settings&quot; page.
                      </>
                  )}
                    required
                  />
                </>
              )}
            />
          )}
          <LinkWrapper
            disabled={!showButton}
            href="/admin-tools/call-category-settings"
            target="_blank"
            variant="defaultAdmin"
            className="mb-6 -mt-3 block"
          >
            See Call Categories
            <Icon size="xxs" className={`${!showButton}` ? 'cursor-not-allowed opacity-60' : ''}>
              <ChevronRightIcon />
            </Icon>
          </LinkWrapper>
        </>
      )}
    </>
  )
}

export default CallSettingsForm
