// When to use:
// use the horizontal grid inside the Layout component to
// achieve a horizontal layout. Map over to duplicate rows.
// On mobile collapses to two columns with description on
// left and the value on right.
// smGrid is mobile, mdGrid is desktop
//
// Must wrap div around body of backgroundColor horizontal grids
// because the "header" can serve as the odd color that
// breaks the pattern.

interface Props {
  children: React.ReactNode
  button?: React.ReactNode
  grid?: boolean
  smGrid?: number | string
  mdGrid?: number | string
  lgGrid?: number | string
  justifyContent?: 'justify-start' | 'justify-end' | 'justify-center' | 'justify-between' | 'justify-around' | 'justify-evenly'
  alignItems?: 'items-start' | 'items-end' | 'items-center' | 'items-baseline' | 'items-stretch'
  backgroundColor?: boolean
  border?: boolean
  header?: boolean
  padding?: boolean
  margin?: boolean
}

const HorizontalGrid:React.FC<Props> = ({
  children,
  button,
  smGrid,
  mdGrid,
  grid = true,
  justifyContent = 'justify-start',
  alignItems = 'items-center',
  backgroundColor = false,
  border = false,
  padding = true,
  lgGrid,
  margin = true,
}) => (
  <div className={`horizontal-grid relative ${backgroundColor ? 'odd:bg-grey-lightest py-2' : 'pb-0 -mt-3 mb-1'}`}>
    <div className="min-w-0 text-left heading-5 font-semibold m-0">
      {smGrid && mdGrid ? (
        <div className={`break-words ${padding ? 'px-4 md:px-6 ' : null} flex-1 md:flex-row min-w-0 grid ${smGrid} ${mdGrid} ${alignItems && alignItems} ${lgGrid && lgGrid} md:gap-4 mb-0 text-left heading-5 font-semibold py-2 ${border ? 'border-t border-t-1 border-grey-dark border-dotted py-6' : 'border-t-0'}`}>
          {children}
          {button}
        </div>
      ) : (
        <div className={`block sm:flex ${button && 'group'} ${padding ? 'px-4 md:px-6 ' : null} ${alignItems} ${justifyContent} ${margin ? 'mx-6' : 'mx-0'} min-w-0 text-left heading-5 font-semibold py-1 ${border ? 'border-t border-grey-light border-dotted pt-5 pb-5' : 'border-t-0'}`}>
          {children}
          {button}
        </div>
      )}
    </div>
  </div>
)

export default HorizontalGrid
