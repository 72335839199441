// styles
export const apiSettingsStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// data
export const apiSettingsMenu = [
  {
    fieldName: 'advertisers',
    name: 'Advertisers',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'platform',
    name: 'Platform',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'status',
    name: 'Status',
    mobile: false,
    sort: true,
  },
]

export const apiSettingsStatus = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]
