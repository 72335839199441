import { useState, useEffect } from 'react'
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@heroicons/react/solid'
import Content from '../layouts/Content'
import HorizontalGrid from '../layouts/HorizontalGrid'
import ToolTip from '../ToolTip'
import { ReactComponent as TotalCallLeadsIcon } from '../../assets/icons/call-leads-icon.svg'
import { ReactComponent as TotalWebLeadsIcon } from '../../assets/icons/online-form-leads-icon.svg'
import { ReactComponent as TotalEmailLeadsIcon } from '../../assets/icons/email-leads-icon.svg'
import BadgeInfo from '../badge/BadgeInfo'
import { commaNumber } from '../../helpers/utils'
import { IRoiSemReport } from '../../api/imi/interfaces'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'

interface Props {
  roi: IRoiSemReport
}

const RoiReport:React.FC<Props> = ({
  roi,
}) => {
  const [roiData, setRoiData] = useState(roi)
  const [roiTotal, setRoiTotal] = useState<any>(1)
  useEffect(() => (
    setRoiData(roi)
  ), [roi])

  useEffect(() => {
    setRoiTotal(Math.sign(roiData?.roi_percent))
  }, [roiData])

  return (
    <Content
      title="return on investment (roi)"
      icon={(
        <ToolTip
          tipId="roi"
          position="hidden md:block relative pr-0"
          size="sm"
        >
          Return on investment (ROI) is a metric used to evaluate a campaign’s profitability. If the ROI seems inaccurate, it may be due to missing or incorrect information. For a more accurate ROI, please provide us data for “% Converted” & “Avg. Gross,” or calculate the ROI using these equations:
          <br />
          <br />
          <span className="font-bold"># of Converted&nbsp;</span>
          = (# of Leads) x (% Converted)
          <br />
          <br />
          <span className="font-bold">Return&nbsp;</span>
          = (# of Converted) x (Avg. Gross)
          <br />
          <br />
          <span className="font-bold">ROI&nbsp;</span>
          (%) = (Total Return - Total Investment) ÷ (Total Investment) x 100
        </ToolTip>
      )}
    >
      {roiData?.data.map((item) => (
        <HorizontalGrid
          smGrid="grid-cols-1"
          mdGrid="md:grid-cols-roi-report"
          border
          padding={false}
          key={item.name}
          alignItems="items-center"
        >
          <div className="md:flex md:items-center">
            <div className="hidden md:flex md:flex-shrink-0 md:justify-center md:items-center md:mr-3">
              {item.name.toLowerCase().includes('call') && (
                <TotalCallLeadsIcon className="w-12" />
              )}
              {item.name.toLowerCase().includes('web') && (
                <TotalWebLeadsIcon className="w-12" />
              )}
              {item.name.toLowerCase().includes('email') && (
                <TotalEmailLeadsIcon className="w-12" />
              )}
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 flex-1 md:block capitalize">
              {item.name}
              <p className="text-date text-xxs row-span-full md:justify-self-start md:row-auto">Lead Type</p>
            </div>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto break-all">
            {item.leads_count}
            <p className="text-date text-xxs row-span-full"># of Leads</p>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto">
            {item.converted > 0 ? (
              `${(item.converted * 100).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 1 })}%`
            ) : (
              '-'
            )}
            <p className="text-date text-xxs row-span-full">% Converted</p>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto">
            {item.converted > 0 ? (
              (item.converted_count).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 3 })
            ) : (
              '-'
            )}
            <p className="text-date text-xxs row-span-full"># of Converted</p>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto">
            {item.avg_gross > 0 ? (
              `$${item.avg_gross.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 0 })}`
            ) : (
              '-'
            )}
            <p className="text-date text-xxs row-span-full">Avg. Gross</p>
          </div>
          <div className="w-full text-center grid mt-3 grid-cols-1 md:grid-cols-2 md:block md:justify-self-end md:row-auto">
            <BadgeInfo
              label="Return"
              calculation={(
                item.avg_gross > 0 && item.converted > 0 ? (
                  `$${item.lead_type_return.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })}`
                ) : (
                  ''
                )
              )}
              // calculation={`$${(item.converted_count * item.avg_gross).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })}`}
              toolTipId="1"
              fullWidth
            />
          </div>
        </HorizontalGrid>
      ))}
      {/* NOTE: this is pulled from HorizontalGrid component */}
      {/* Super unique situation to make badges full width at medium resolution */}
      <div className="block md:flex min-w-0 text-left heading-5 font-semibold py-1 border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3">
        <div className="text-center md:block md:justify-self-start md:row-auto">
          <BadgeInfo
            label="Total Leads"
            calculation={roiData ? roiData?.total_leads : 0}
            toolTipId="1"
            fullWidth
          />
        </div>
        <div className="text-center md:w-auto md:block md:justify-self-start md:row-auto">
          <BadgeInfo
            label="Total Investment"
            calculation={`$${Math.trunc(roiData?.total_investment).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 0 })}`}
            toolTipId="1"
            fullWidth
          />
        </div>
        <div className="text-center md:block md:justify-self-start md:row-auto">
          <BadgeInfo
            label="Total Return"
            calculation={`$${roiData?.total_return.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })}`}
            toolTipId="1"
            fullWidth
          />
        </div>
        <div className="text-center hidden md:block md:justify-self-start md:row-auto md:flex-1">&nbsp;</div>
        <div className="text-center w-full md:block md:justify-self-end md:row-auto md:max-w-roi-badge md:w-full">
          <BadgeInfo
            icon={(
              <>
                {roiTotal === 1 ? (
                  <Icon colors="green">
                    <ArrowCircleUpIcon />
                  </Icon>
                ) : (
                  <Icon colors="red">
                    <ArrowCircleDownIcon />
                  </Icon>
                )}
              </>
            )}
            label="ROI"
            calculation={roiData ? `${roiData.roi_percent.toFixed(2)}%` : 0}
            toolTipId="1"
            fullWidth
          />
        </div>
      </div>
    </Content>
  )
}

export default RoiReport
