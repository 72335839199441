// This is the main wrapper around all of the different sections.
// Style is the rounded box with the light grey title bar
// options for the titlebar include:
//    1. title
//    2. subheading (admin areas)
//    2. badge - badges/labels for more info
//    3. form - if there's a form field like a dropdown to filter, etc.
//    4. icon - icon in the title bar like info, or download
//    5. min height - if there is a sidebar, designer requests min height
//       to be set on main content area to match height sidebar content.

interface Props {
  children?: React.ReactNode
  title?: React.ReactNode
  subheading?: string
  badge?: {}
  form?:{}
  icon?:{}
  minHeight?: string
  padding?: boolean
  variant?: 'error' | 'success'
}

const Content: React.FC<Props> = ({
  children,
  title,
  badge,
  subheading,
  form,
  icon,
  minHeight = '',
  padding = true,
  variant,
}) => (
  <div className={`${minHeight} ${variant === 'error' ? 'bg-red-default bg-opacity-10' : 'bg-white'} border ${(variant === 'error' && 'border-red-default') || (variant === undefined && 'border-grey-light') || 'border-green-default'} rounded mb-6`}>
    {title && (
      <div className={`px-4 md:px-6 py-3 ${variant === 'error' ? 'border-red-default bg-red-default bg-opacity-60' : 'border-grey-light bg-grey-lightest'} ${variant === 'success' ? 'border-green-default' : 'border-grey-light'} relative xxs:flex-row flex align-center rounded-t justify-between`}>
        <h4 className={`${variant === 'error' && 'text-light'} inline-block`}>
          <span className="mr-2 pr-2">{title}</span>
          {badge}
        </h4>
        <div className="h-4">
          {form}
          {icon}
        </div>
      </div>
    )}
    <div className={`${title && 'border-t'} ${(variant === 'error' && 'border-red-default') || (variant === undefined && 'border-grey-light') || 'border-green-default'} ${padding ? 'px-4 md:px-6' : 'px-0'} rounded-b`}>
      {subheading ? <span className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mt-3">{subheading}</span> : []}
      <div className="relative block pt-6">{children}</div>
    </div>
  </div>
)

export default Content
