import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import Padding from '../../../components/layouts/Padding'
import { ICallInstruction } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import EditCallInstructions from '../../../components/adminTools/calls/instructions/EditCallInstructions'

const EditCallInstructionsPage:React.FC = () => {
  const [refresh, setRefresh] = useState(false)
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [instructions, setInstructions] = useState<ICallInstruction>({} as any)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const items = localStorage.getItem('instruction_id')
    if (items) {
      const parseItems = JSON.parse(items)
      const getInstructionsById = async () => {
        const response = await IMIApi.GetCallInstructionsById(parseItems, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setInstructions(response?.data)
        return setLoading(false)
      }
      getInstructionsById()
    }
    return () => abortController.abort()
  }, [setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditCallInstructions
            isLoading={loading}
            // setRefresh={setRefresh}
            instructions={instructions}
            edit
          />
        )}
      />
    </Padding>
  )
}

export default EditCallInstructionsPage
