import { useEffect, useState, useContext } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { IMIApi } from '../../api/imi/api'
import AdminToolsMenu from '../../components/adminTools/AdminToolsMenu'
import Padding from '../../components/layouts/Padding'
import SidebarLeft from '../../components/layouts/SidebarLeft'
import { AppContext } from '../../contexts/AppContext'
import CreateAdvertiser from '../../components/adminTools/advertisers/CreateAdvertiser'
import EditAdvertiser from '../../components/adminTools/advertisers/EditAdvertiser'
import { IAdvertiserInDb } from '../../api/imi/interfaces'
import Content from '../../components/layouts/Content'
import LinkWrapper from '../../components/Links'
import Icon from '../../components/Icon'
import { newAdvertiser } from '../../api/imi/factories'

const CreateAdvertiserPage:React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [advertiser, setAdvertiser] = useState<IAdvertiserInDb>(newAdvertiser())
  const [advertiserId, setAdvertiserId] = useState<number>(0)
  const { setError } = useContext(AppContext)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (advertiserId > 0) {
      const fetchAdvertiser = async () => {
        const response = await IMIApi.GetAdvertiserById(advertiserId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setAdvertiser(response.data)
        return setLoading(false)
      }
      fetchAdvertiser()
    }
    return () => abortController.abort()
  }, [advertiserId, setError, refresh])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          advertiserId > 0 ? (
            <>
              <EditAdvertiser
                advertiser={advertiser}
                isLoading={loading}
                edit={false}
              />
              <Content
                title="campaigns"
              >
                <p>To connect a campaign to this advertiser, save the details for this new advertiser. Then, go to “campaign settings” to create or assign a campaign, which will allow you to select an advertiser.</p>
                {/* <LinkWrapper
                  href="/admin-tools/campaign-settings/create-campaign"
                  variant="defaultAdmin"
                  className="mb-6 inline-block mr-3"
                  target="_blank"
                >
                  Create New Campaign
                  <Icon size="xxs">
                    <ChevronRightIcon />
                  </Icon>
                </LinkWrapper> */}
                <LinkWrapper
                  href="/admin-tools/campaign-settings"
                  variant="defaultAdmin"
                  className="mb-6 inline-block"
                  target="_blank"
                >
                  Assign Campaign
                  <Icon size="xxs">
                    <ChevronRightIcon />
                  </Icon>
                </LinkWrapper>
              </Content>
            </>
          ) : (
            <CreateAdvertiser
              isLoading={loading}
              setAdvertiserId={setAdvertiserId}
            />
          )
        )}
      />
    </Padding>
  )
}
export default CreateAdvertiserPage
