import {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { advertiserSettingsStatus } from '../adminTools/advertisers/Data'
import { handleFilters } from '../../helpers/utils'
import Content from '../layouts/Content'
import NavLinkWrapper from '../navigation/NavLink'
import BadgeAlert from '../badge/BadgeAlert'
import Dropdown from '../form/Dropdown'
import Search from '../form/Search'
import { IAdvertiserInDb, IAdvertiserMultiResponse } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { AppContext } from '../../contexts/AppContext'

// TODO: need api updated for advertisers to insert here instead of filler

// statuses are running, paused, ended
interface Props {
  data: IAdvertiserMultiResponse
  loading: boolean
}

const AdvertisersList:React.FC<Props> = ({
  data,
  loading,
}) => {
  const { user } = useContext(AppContext)
  const [filteredAdvertisers, setFilteredAdvertisers] = useState<any>()
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })

  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const advertiserStatus = status.is_active
      if (advertiserStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }
    const filterSearchValue = (advertiserData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const advertiserFilter = advertiserData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const advertiserFil = splitItems.some((si) => advertiserData.name.toLowerCase().includes(si))
        return advertiserFil
      }
      return advertiserFilter
    }

    const filterData = data?.data?.filter((advertiser) => filterSearchValue(advertiser) && filterStatus(advertiser))
    if (user.user_type_id > 4) {
      return setFilteredAdvertisers(user.advertisers.filter((advertiser) => filterSearchValue(advertiser) && filterStatus(advertiser)))
    }
    setFilteredAdvertisers(filterData)
  }, [selectedFilters, data, user.advertisers, user.user_type_id])

  useEffect(() => {
    filters()
  }, [data, filters])

  const handleLocalStorage = (id) => {
    localStorage.setItem('advertiser_id', JSON.stringify(id))
  }

  return (
    <Content
      title={(
        selectedFilters.status === 0 ? 'Active Advertisers' : 'Inactive Advertisers'
      )}
      form={(
        <Dropdown
          variant="default"
          label="Status"
          list={advertiserSettingsStatus}
          className="special"
          position="top-1.5 absolute right-0 top-0 bottom-0"
          handleFilters={(
            (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
          )}
        />
      )}
      badge={loading ? <BadgeAlert alert="..." status="running" /> : <BadgeAlert alert={filteredAdvertisers?.length > 0 ? filteredAdvertisers?.length : 0} status={selectedFilters.status === 0} />}
    >
      {data.rows_total >= 20 && (
        <Search
          value={selectedFilters.search}
          onChange={(
            (e) => setSelectedFilters(
              handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
            )
          )}
          label="search advertisers"
          hideLabel
          placeholder="search advertisers"
          className="max-w-none w-full"
        />
      )}
      {loading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <ul>
          {!filteredAdvertisers?.length ? (
            <li><p>No advertisers found</p></li>
          ) : (
            filteredAdvertisers?.map((advertiser) => (
              <li key={advertiser.id}>
                <NavLinkWrapper
                  variant="menu"
                  className="mb-0"
                  onClick={() => handleLocalStorage(advertiser.id)}
                  to={{
                    pathname: '/campaign-overview',
                  }}
                >
                  {advertiser.name}
                </NavLinkWrapper>
              </li>
            ))
          )}
        </ul>
      )}
    </Content>
  )
}

export default AdvertisersList
