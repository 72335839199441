import { useContext, useEffect, useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { getFieldByPath } from '../../../../helpers/utils'
import Content from '../../../layouts/Content'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Icon from '../../../Icon'
import { flaggedCallSettingsMenu, settingsStyles } from '../Data'
import BadgeAlert from '../../../badge/BadgeAlert'
import LinkWrapper from '../../../Links'
import { ICall, ICallProccessingSummary } from '../../../../api/imi/interfaces'
import LoadingSpinner from '../../../LoadingSpinner'
import EditMenu from '../../EditMenu'
import { ReportContext } from '../../../../contexts/ReportContext'

// TODO: change view calls button to link back to the flagged report SEM page

interface Props {
  flaggedCalls: ICallProccessingSummary
  isLoading: boolean
}

const FlaggedCalls:React.FC<Props> = ({
  flaggedCalls,
  isLoading,
}) => {
  const { setError } = useContext(AppContext)
  const { setParamsNavigateNewest } = useContext(ReportContext)
  const [errorMsg, setErrorMsg] = useState('There are no flagged calls for the last 30 days found.')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [processedFlaggedCalls, setProcessedFlaggedCalls] = useState<any>([] as any)

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    // FIXME: temporary sorting, would like to update
    const sortSecondaryName = flaggedCalls?.detail?.sort((a, b) => (a.advertiser.name.localeCompare(b.advertiser.name) || b.secondary_name.localeCompare(a.secondary_name)))
    const values = sortDirection ? sortSecondaryName?.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1)) : flaggedCalls?.detail?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { values }
  }

  useEffect(() => {
    setProcessedFlaggedCalls(flaggedCalls?.detail?.sort((a, b) => (a.name.localeCompare(b.name)) || (a.secondary_name > b.secondary_name ? 1 : -1)))
  }, [flaggedCalls])

  return (
    <>
      <AdminToolsMobileLink />
      <Content
        title="flagged calls from last 30 days"
        padding={false}
        badge={(
          <BadgeAlert
            alert={flaggedCalls?.calls_total || 0}
          />
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-2"
              mdGrid="sm:grid-cols-2"
            >
              {flaggedCallSettingsMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && settingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {processedFlaggedCalls?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                processedFlaggedCalls?.map((campaign) => (
                  <HorizontalGrid
                    key={campaign.id}
                    smGrid="grid-cols-2"
                    mdGrid="sm:grid-cols-2"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      {campaign.name}
                      {campaign.secondary_name && (
                        `: ${campaign.secondary_name}`
                      )}
                      {campaign.specific && (
                        `- ${campaign.specific.name}`
                      )}
                    </p>
                    <p className="text-xxs pr-6">
                      <LinkWrapper
                        variant="defaultSm"
                        key={campaign.id}
                        target="_blank"
                        onClick={() => setParamsNavigateNewest(campaign.id)}
                        href={{
                          pathname: '/admin-tools/flagged-calls/flagged-call-report',
                        }}
                      >
                        {campaign.calls_total}
                      </LinkWrapper>
                    </p>
                    <EditMenu
                      text="View Calls"
                      id={campaign.id}
                      onClick={() => setParamsNavigateNewest(campaign.id)}
                      // onClick={(
                      //   () => handlelocalStorage(
                      //     {
                      //       id: campaign.id,
                      //       cycle_id: 0,
                      //       start_date: 0,
                      //       end_date: 0,
                      //     },
                      //   )
                      // )}
                      target="_blank"
                      pathname="/admin-tools/flagged-calls/flagged-call-report"
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default FlaggedCalls
