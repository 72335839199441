import {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import { CalendarIcon } from '@heroicons/react/outline'
import ReportHeader from '../components/semReport/ReportHeader'
import Padding from '../components/layouts/Padding'
import { IMIApi } from '../api/imi/api'
import { AppContext } from '../contexts/AppContext'
import { IDisplayDetail } from '../api/imi/interfaces'
import LoadingSpinner from '../components/LoadingSpinner'
import { ReportContext } from '../contexts/ReportContext'
import {
  fixDateObj,
  commaNumber,
} from '../helpers/utils'
import 'bootstrap-daterangepicker/daterangepicker.css'
import Icon from '../components/Icon'
import Input from '../components/form/Input'
import DisplayTotalsReport from '../components/displayReport/DisplayTotalsReport'
import DisplayImpressionsActivitiesReport from '../components/displayReport/DisplayImpressionsActivitiesReport'
import DisplayPlacementBreakdown from '../components/displayReport/DisplayPlacementBreakdown'
import DisplayActivityBreakdown from '../components/displayReport/DisplayActivityBreakdown'
import DisplayImpressionsByCreativeReport from '../components/displayReport/DisplayImpressionsByCreativeReport'
import DisplayActivityTrendsReport from '../components/displayReport/DisplayActivityTrendsReport'
import DisplayDeviceTypeReport from '../components/displayReport/DisplayDeviceTypeReport'
import DisplayBudgetReport from '../components/displayReport/DisplayBudgetReport'
import DisplayCreativePerformanceReport from '../components/displayReport/DisplayCreativePerformanceReport'
import MobileReportHeader from '../components/navigation/StickyReportHeader'

const DisplayReportPage: React.FC = () => {
  const {
    setError,
    user,
  } = useContext(AppContext)
  const {
    // interfaces
    setReportParamsAndCycles,
    setParamsNavigateDatePicker,
    // values
    cyclesForDatePicker,
    parameters,
    reportParameters,
  } = useContext(ReportContext)

  const [displayReportDetail, setDisplayReportDetail] = useState<IDisplayDetail>({} as any)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /** Returns the current report's parameters for use in the report where needed.
   *  This is separated from the `parameters` object since its specific to the loaded report.
   */
  const thisReportParams = useCallback(() => {
    const cycleId = displayReportDetail?.report_cycle_id
    const startDate = displayReportDetail?.query_start_date
    const endDate = displayReportDetail?.query_end_date
    const campaignId = displayReportDetail?.campaign.id
    return {
      id: campaignId,
      cycle_id: cycleId,
      start_date: startDate,
      end_date: endDate,
    }
  }, [displayReportDetail])

  const fullName = useCallback(() => {
    const campName = displayReportDetail?.campaign?.name
    const secName = displayReportDetail?.campaign?.secondary_name
    const specific = displayReportDetail?.campaign?.specific?.name

    if (secName) {
      return `${campName}: ${secName}`
    }
    if (secName && specific) {
      return `${campName}: ${secName} - ${specific}`
    }
    if (!secName && specific) {
      return `${campName} - ${specific}`
    }
    return campName
  }, [displayReportDetail])

  /** Load Report Data
   *  This useEffect triggers when on first load the report page and again whenever the
   *  `parameters` key in ReportContext changes to a new report target, it reloads the
   *  report data.
   *
   *  To navigate to a new report page, alter the `parameters` object in the Report Context
   *  using one of the interface methods below which guarantee a valid report request is
   *  generated.
   *
   *  reportContext.
   *    setParamsNavigateDates(start_date, end_date) - Navigate to these arbitrary dates on the current campaign
   *    setParamsNavigateCycle(cycle_id) - Navigate to this cycle on the current campaign
   *    setParamsNavigateNewest(campaign_id) - Navigate to the newest cycle of provided campaign id
   *    setParamsDatePicker - Navigate using the Date Picker
   */

  useEffect(() => {
    setIsLoading(true)
    const fetchDisplayReport = async () => {
      const response = await IMIApi.GetDisplayReport(parameters)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setIsLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error' })
        return setIsLoading(false)
      }
      setReportParamsAndCycles(response.data)
      setDisplayReportDetail(response.data)
      return setIsLoading(false)
    }
    fetchDisplayReport()
  },
  [parameters, setError, setReportParamsAndCycles])

  return (
    <Padding>
      {isLoading || !displayReportDetail ? <LoadingSpinner size="lg" variant="primary" /> : (
        <>
          <MobileReportHeader
            title={`${reportParameters.fullName} | Display`}
          >
            <div className="ml-auto hover:cursor-pointer md:min-w-300 bg-tertiary">
              {displayReportDetail.cycles && (
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(reportParameters.report_start_date).format('MM/DD/YYYY'),
                    endDate: moment(reportParameters.report_end_date).format('MM/DD/YYYY'),
                    ranges: cyclesForDatePicker,
                    alwaysShowCalendars: true,
                    minDate: displayReportDetail.report_start_date,
                    maxDate: displayReportDetail.report_end_date ? reportParameters.query_max_date : moment(new Date()).format('MM/DD/YYYY'),
                  }}
                  onCallback={(
                    (start, end, label) => {
                      setParamsNavigateDatePicker(label, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    }
                  )}
                >
                  <Input
                    type=""
                    name="date picker"
                    label="date picker"
                    hideLabel
                    className="form-control font-bold font-display bg-white rounded"
                    icon={<Icon><CalendarIcon /></Icon>}
                  />
                </DateRangePicker>
              )}
            </div>
          </MobileReportHeader>
          <ReportHeader
            title={fullName()}
          >
            <div className="heading-5 font-semibold mb-3 sm:mr-12">
              {fixDateObj(displayReportDetail?.report_start_date)}
              <div className="small">
                start date
              </div>
            </div>
            {(displayReportDetail.report_end_date) && (
              <div className="heading-5 font-semibold mb-3 sm:mr-12">
                {thisReportParams().end_date ? fixDateObj(thisReportParams().end_date) : '—'}
                <div className="small">
                  end date
                </div>
              </div>
            )}
            <div className="heading-5 font-semibold mb-3 sm:mr-12">
              {((displayReportDetail?.budget)) ? `$${commaNumber(Math.round(displayReportDetail.budget))}` : '—'}
              <div className="small">
                budget
              </div>
            </div>
            <div className="ml-auto hover:cursor-pointer md:min-w-300">
              {displayReportDetail.cycles && (
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(reportParameters.report_start_date).format('MM/DD/YYYY'),
                    endDate: moment(reportParameters.report_end_date).format('MM/DD/YYYY'),
                    ranges: cyclesForDatePicker,
                    alwaysShowCalendars: true,
                    minDate: displayReportDetail.report_start_date,
                    maxDate: displayReportDetail.report_end_date ? reportParameters.query_max_date : moment(new Date()).format('MM/DD/YYYY'),
                  }}
                  onCallback={(
                    (start, end, label) => {
                      setParamsNavigateDatePicker(label, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    }
                  )}
                >
                  <Input
                    type=""
                    name="date picker"
                    label="date picker"
                    hideLabel
                    className="form-control font-bold font-display"
                    icon={<Icon><CalendarIcon /></Icon>}
                  />
                </DateRangePicker>
              )}
            </div>
          </ReportHeader>
          <DisplayTotalsReport data={displayReportDetail} />
          {/* utilizes the daily activity to list in table and have downloadable report */}
          <DisplayImpressionsActivitiesReport data={displayReportDetail} />
          <DisplayPlacementBreakdown data={displayReportDetail} />
          <DisplayActivityTrendsReport data={displayReportDetail} />
          {displayReportDetail.activity_breakdown && (
            <DisplayActivityBreakdown
              campaignId={thisReportParams().id}
              startDate={thisReportParams().start_date}
              endDate={thisReportParams().end_date}
              data={displayReportDetail.activity_breakdown}
            />
          )}
          <DisplayImpressionsByCreativeReport data={displayReportDetail} />
          <DisplayCreativePerformanceReport data={displayReportDetail} />
          {displayReportDetail.device_impressions && <DisplayDeviceTypeReport data={displayReportDetail} />}
          {user.user_type_id === 1 && (
            <DisplayBudgetReport data={displayReportDetail.budget_report} />
          )}
        </>
      )}
    </Padding>
  )
}

export default DisplayReportPage
