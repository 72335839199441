import {
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import { 
  useForm, 
  SubmitHandler, 
  FormProvider, 
  Controller, 
} from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../contexts/AppContext'
import {
  IPlatform,
  IPlatformCred,
} from '../../../api/imi/interfaces'
import Content from '../../layouts/Content'
import FormButtonGroup from '../../button/FormButtonGroup'
import { IMIApi } from '../../../api/imi/api'
import LoadingSpinner from '../../LoadingSpinner'
import Input from '../../form/Input'
import Dropdown from '../../form/Dropdown'
import LinkWrapper from '../../Links'
import ApiSettingsStackAdaptForm from './ApiSettingsStackAdaptForm'
import Icon from '../../Icon'

interface Props {
  platforms: IPlatform[]
  platformCreds: IPlatformCred | undefined
  isLoading: boolean
  updatePlatformCreds: (creds: IPlatformCred) => void
}

const EditApiSettings:React.FC<Props> = ({
  platforms,
  platformCreds,
  isLoading,
  updatePlatformCreds,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      platform_id: platformCreds?.platform.id,
      rest: '',
      gql: '',
    }), [platformCreds]),
  })

  const platformId = methods.watch('platform_id')

  const cancel = () => {
    methods.reset({
      platform_id: platformCreds?.platform.id,
      rest: '',
      gql: '',
    })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  useEffect(() => {
    methods.reset({
      platform_id: platformCreds?.platform.id,
      rest: '',
      gql: '',
    })
  }, [platformCreds, methods])

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (platformCreds) {
      setIsButtonLoading(true)
      setBtnText('Saving...')

      const submitResponse = await IMIApi.UpdatePlatformCred(platformCreds.id, data) as any
      setTimeout(() => {
        if (submitResponse.status !== 200) {
          if (submitResponse.status === 0) {
            setIsButtonLoading(false)
            setError({ errorMsg: 'Internal server error.' })
            return setBtnText('Save')
          }
          const parse = JSON.parse(submitResponse)
          setIsButtonLoading(false)
          setError({ errorMsg: parse.detail })
          return setBtnText('Save')
        }
        updatePlatformCreds({ ...platformCreds, errors: [] })
        setIsButtonLoading(false)
        return setBtnText('Saved!')
      }, 1000)
      setTimeout(() => {
        setShowButton(false)
        return setBtnText('Save')
      }, 2000)
    }
  }

  const editTemplate = () => {
    setShowButton(true)
  }

  return (
    <>
      <Content title="api details">
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <Input
              name="advertiser"
              label="Advertiser"
              type="text"
              disabled
              value={platformCreds?.p_advertisers.map((p) => p.name).join(', ')}
            />
            <Input
              name="campaigns"
              label="Campaigns"
              type="text"
              disabled
              value={platformCreds?.campaigns.map((c) => `${c.secondary_name || c.name}: ${c.type.name}${c.specific ? ` - ${c.specific}` : ''}`).join(', ')}
            />
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <Controller
                  name="platform_id"
                  control={methods.control}
                  rules={{ required: true }}
                  render={({
                    field,
                  }) => (
                    <Dropdown
                      hookFormField={field}
                      placeholder="Select..."
                      disabled
                      value={field.value}
                      label="Platform"
                      variant="admin"
                      list={platforms}
                      error={methods.formState.errors.platform_id && <span className="form-error">This field is required</span>}
                      tipId="platform_id"
                      required
                    />
                  )}
                />
                {platformId === 2 && <ApiSettingsStackAdaptForm showButton={showButton} newKeys={false} errors={platformCreds?.errors} />}
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isValid || !methods.formState.isDirty}
                  disabledCancel={!methods.formState.isDirty}
                  onClickCancel={cancel}
                  btnText={btnText}
                  showButton={showButton}
                  onClickEdit={editTemplate}
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default EditApiSettings
