import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignTypeInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import CampaignTypes from '../../../components/adminTools/campaigns/campaign_types/CampaignTypes'

const CampaignTypesPage:React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [refresh, setRefresh] = useState(false)
  const [campaignTypes, setCampaignTypes] = useState<ICampaignTypeInDb[]>([])
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCampaignTypes = async () => {
      const response = await IMIApi.GetCampaignTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCampaignTypes(response.data.data)
      return setLoading(false)
    }
    fetchAllCampaignTypes()
    return () => abortController.abort()
  }, [refresh, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <CampaignTypes
            campaignTypes={campaignTypes}
            loading={loading}
          />
        )}
      />
    </Padding>
  )
}
export default CampaignTypesPage
