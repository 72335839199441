import { 
  useState, 
  useEffect, 
  useRef, 
  useMemo, 
} from 'react'
import { useFormContext } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { IPlatformCredError } from '../../../api/imi/interfaces'
import Input from '../../form/Input'
import LinkWrapper from '../../Links'
import Icon from '../../Icon'

interface Props {
  newKeys: boolean
  showButton?: boolean
  errors?: IPlatformCredError[]
}

const ApiSettingsStackAdaptForm:React.FC<Props> = ({
  showButton,
  newKeys,
  errors = [],
}) => {
  const methods = useFormContext()
  const [editRest, setEditRest] = useState(false)
  const [editGql, setEditGql] = useState(false)
  const methodsRef = useRef(methods)

  const errorMap = useMemo(() => errors.reduce(
    (o, err) => ({
      ...o,
      [err.field]: err.message,
    }), 
    {} as any,
  ), [errors])

  useEffect(() => {
    methodsRef.current = methods
  }, [methods])
  
  useEffect(() => {
    setEditRest(false)
    setEditGql(false)
  }, [methods.formState.isSubmitSuccessful])

  // useEffect(() => {
  //   errors.forEach((error) => {
  //     methods.setError(error.field, { message: error.message, type: 'onChange' })
  //   })
  // }, [errors])

  const cancelRestEdit = () => {
    setEditRest(!editRest)
    methods.setValue('rest', '')
    methods.resetField('rest')
    methods.clearErrors()
  }

  const cancelGqlEdit = () => {
    setEditGql(!editGql)
    methods.setValue('gql', '')
    methods.resetField('gql')
    methods.clearErrors()
  }

  useEffect(() => {
    if (!showButton) {
      setEditRest(false)
      setEditGql(false)
      methodsRef.current.setValue('gql', '')
      methodsRef.current.resetField('gql')
      methodsRef.current.setValue('rest', '')
      methodsRef.current.resetField('rest')
      methodsRef.current.clearErrors()
    }
  }, [
    showButton, 
    setEditGql, 
    setEditRest,
  ])

  return (
    <>
      {!newKeys ? (
        <>
          <Input
            {...methods.register('rest', {
              required: editRest,
              minLength: {
                value: 32,
                message: 'REST keys must be 32 characters long',
              },
              maxLength: {
                value: 32,
                message: 'REST keys must be 32 characters long',
              },
            })}
            label="REST Key"
            type="text"
            toolTipInfo={(
              <span>
                REST keys must be 32 characters.
              </span>
            )}
            error={(methods.formState.errors.rest && <span className="form-error">{methods.formState.errors.rest.message || 'This field is required'}</span>)
              || (!editRest && errorMap.rest && <span className="form-error">{errorMap.rest}</span>)}
            disabled={!editRest || !showButton}
            placeholder={!editRest || !showButton ? '••••••••••••••••••••••••••••••••' : ''}
          >
            <LinkWrapper
              disabled={!showButton}
              href="#"
              variant="defaultAdmin"
              onClick={cancelRestEdit}
              className="mb-6"
            >
              {!editRest || !showButton ? 'Change REST Key' : 'Cancel'}
              <Icon size="xxs" className={`${!showButton}` ? 'cursor-not-allowed opacity-60' : ''}>
                <ChevronRightIcon />
              </Icon>
            </LinkWrapper>
          </Input>

          <Input
            {...methods.register('gql', {
              required: editGql,
              minLength: {
                value: 64,
                message: 'GQL keys must be 64 characters long',
              },
              maxLength: {
                value: 64,
                message: 'GQL keys must be 64 characters long',
              },
            })}
            label="GQL Key"
            type="text"
            toolTipInfo={(
              <span>
                GQL keys must be 64 characters.
              </span>
            )}
            error={(methods.formState.errors.gql && <span className="form-error">{methods.formState.errors.gql.message || 'This field is required'}</span>)
            || (!editGql && errorMap.gql && <span className="form-error">{errorMap.gql}</span>)}
            disabled={!editGql || !showButton}
            placeholder={!editGql || !showButton ? '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••' : ''}
          >
            <LinkWrapper
              disabled={!showButton}
              href="#"
              variant="defaultAdmin"
              onClick={cancelGqlEdit}
              className="mb-6"
            >
              {!editGql || !showButton ? 'Change GQL Key' : 'Cancel'}
              <Icon size="xxs" className={`${!showButton}` ? 'cursor-not-allowed opacity-60' : ''}>
                <ChevronRightIcon />
              </Icon>
            </LinkWrapper>
          </Input>
        </>
      ) : (
        <>
          <Input
            {...methods.register('rest', {
              required: true,
              minLength: {
                value: 32,
                message: 'REST keys must be 32 characters long',
              },
              maxLength: {
                value: 32,
                message: 'REST keys must be 32 characters long',
              },
            })}
            label="REST Key"
            type="text"
            toolTipInfo={(
              <span>
                REST keys must be 32 characters.
              </span>
            )}
            error={(
              methods.formState.errors.rest && (
                <span className="form-error">
                  {methods.formState.errors.rest.message || 'This field is required'}
                </span>
              )
            )}
          />

          <Input
            {...methods.register('gql', {
              required: true,
              minLength: {
                value: 64,
                message: 'GQL keys must be 64 characters long',
              },
              maxLength: {
                value: 64,
                message: 'GQL keys must be 64 characters long',
              },
            })}
            label="GQL Key"
            type="text"
            toolTipInfo={(
              <span>
                GQL keys must be 64 characters.
              </span>
            )}
            error={(
              methods.formState.errors.gql && (
                <span className="form-error">
                  {methods.formState.errors.gql.message || 'This field is required'}
                </span>
              )
            )}
          />
        </>
      )}
    </>
  )
}

export default ApiSettingsStackAdaptForm
