import { useContext, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import Dropdown from '../../Dropdown'
import { IAdvertiserInDb } from '../../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  allAdvertisers: IAdvertiserInDb[]
}

const AssignAdvertiserForm:React.FC<Props> = ({
  showButton,
  errors,
  allAdvertisers,
}) => {
  const methods = useFormContext()
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const advertiserInfo = 'Changing the advertiser in this field will also change it in the “Advertiser” field in the “campaign details” section.'
  const watchName = methods.watch('advertiser_id')

  useEffect(() => {
    allAdvertisers.filter((advertiser) => {
      if (advertiser.id === watchName) {
        return methods.setValue('name', advertiser.name)
      }
      return advertiser
    })
  }, [watchName, allAdvertisers, methods])
  // useEffect(() => {
  //   if (campaignInDb?.advertiser?.id > 0) {
  //     return methods.setValue('name', campaignInDb?.advertiser?.name)
  //   }
  // }, [campaignInDb, methods])
  return (
    <>
      {allAdvertisers?.length && (
        <Controller
          name="advertiser_id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              // placeholder="Select..."
              placeholder={campaignInDb?.advertiser?.id > 0 ? undefined : 'Select...'}
              label="Advertiser"
              variant="admin"
              value={methods.getValues('advertiser_id')}
              list={allAdvertisers}
              error={errors.id && <span className="form-error">This field is required</span>}
              toolTipInfo={advertiserInfo}
              required
            />
          )}
        />
      )}
    </>
  )
}

export default AssignAdvertiserForm
