import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignCategory, ICampaignCallSettings, ICampaignInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import EditCallSettings from '../../../components/adminTools/campaigns/call_settings/EditCallSettings'
import { CampaignContext } from '../../../contexts/CampaignContext'

const EditCallSettingsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [campaignData, setCampaignData] = useState<ICampaignCallSettings>({} as any)
  const { setError } = useContext(AppContext)
  const { setCampaignInDb } = useContext(CampaignContext)
  const [callSettingsId, setCallSettingsId] = useState<number>(0)
  const [callIndustries, setCallIndustries] = useState<ICampaignCategory[]>([] as any)
  const [refreshTodo, setRefreshTodo] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('campaign_id')
    if (items) {
      sessionStorage.setItem('campaign_id', items)
      localStorage.removeItem('campaign_id')
    }
    const getSessionItems = sessionStorage.getItem('campaign_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setCallSettingsId(parseItems)
      }
    }
  }, [])

  // campaign
  useEffect(() => {
    const abortController = new AbortController()
    if (callSettingsId > 0) {
      const fetchCampaignCallSettings = async () => {
        const response = await IMIApi.GetCampaignCallSettingId(callSettingsId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaignData(response.data)
        setCampaignInDb(response.data as ICampaignInDb)
        return setLoading(false)
      }
      fetchCampaignCallSettings()
    }
    return () => abortController.abort()
  }, [setError, callSettingsId, setCampaignInDb, refreshTodo])

  // call industries
  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCallIndustries = async () => {
      const response = await IMIApi.GetCallIndustries(abortController.signal)
      if (!response) {
        return
      }
      if (response.status && response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCallIndustries(response.data.data)
      return setLoading(false)
    }
    fetchAllCallIndustries()
    return () => abortController.abort()
  }, [setError])

  // call categories
  // useEffect(() => {
  //   const abortController = new AbortController()
  //   const fetchAllCallCategories = async () => {
  //     // const response = await IMIApi.GetCallIndustryById(campaignInDb?.category?.id)
  //     const response = await IMIApi.GetCallIndustries()
  //     if (response.status && response.status !== 200) {
  //       const parse = JSON?.parse(response)
  //       setError({ errorMsg: parse?.detail })
  //       return setLoading(false)
  //     }
  //     if (!response.status) {
  //       setError({ errorMsg: 'Internal server error.' })
  //       return setLoading(false)
  //     }
  //     setCallCategories(response.data.data)
  //     return setLoading(false)
  //   }
  //   fetchAllCallCategories()
  //   return () => abortController.abort()
  // }, [setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditCallSettings
            edit
            setRefreshTodo={setRefreshTodo}
            campaign={campaignData}
            loading={loading}
            callIndustries={callIndustries}
          />
        )}
      />
    </Padding>
  )
}
export default EditCallSettingsPage
