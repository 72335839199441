import { PDF, FontStyle, Font } from '../lib'
import { Roi, RoiTotals } from '../data'
import * as sizes from '../sizes'

const COL_1_WIDTH = 8 / 36
const COL_2_WIDTH = 5 / 36
const COL_3_WIDTH = 6 / 36
const COL_4_WIDTH = 6 / 36
const COL_5_WIDTH = 5 / 36
const COL_6_WIDTH = 6 / 36

const getTable = (pdf: PDF, data: Roi[]) => {
  const pageWidth = (pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 7)
  return {
    title: pdf.text.drawText('return on investment (roi)', pdf.colors.black, { style: FontStyle.Bold }),
    header: [
      pdf.text.drawText('Lead Type', pdf.colors.black, { width: pageWidth * COL_1_WIDTH }),
      pdf.text.drawText('# of Leads', pdf.colors.black, { width: pageWidth * COL_2_WIDTH }),
      pdf.text.drawText('% Converted', pdf.colors.black, { width: pageWidth * COL_3_WIDTH }),
      pdf.text.drawText('# of Converted', pdf.colors.black, { width: pageWidth * COL_4_WIDTH }),
      pdf.text.drawText('Avg. Gross', pdf.colors.black, { width: pageWidth * COL_5_WIDTH }),
      pdf.text.drawText('Return', pdf.colors.black, { width: pageWidth * COL_6_WIDTH }),
    ],
    rows: data.map((roi) => [
      pdf.text.drawText(roi.leadType, pdf.colors.darkestGrey, { width: pageWidth * COL_1_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(roi.leads, pdf.colors.darkestGrey, { width: pageWidth * COL_2_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(roi.percentConverted, pdf.colors.darkestGrey, { width: pageWidth * COL_3_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(roi.numberConverted, pdf.colors.darkestGrey, { width: pageWidth * COL_4_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(roi.gross, pdf.colors.darkestGrey, { width: pageWidth * COL_5_WIDTH, font: Font.Heebo }),
      pdf.text.drawText(roi.return, pdf.colors.darkestGrey, { width: pageWidth * COL_6_WIDTH, font: Font.Heebo }),
    ]),
  }
}

const getFooterBadge = (pdf: PDF, label: string, value: string, hasUpArrow: boolean = false, hasDownArrow: boolean = false) => {
  const badgeLabel = pdf.text.drawText(label, pdf.colors.black)
  const badgeValue = pdf.text.drawText(value, pdf.colors.black, { style: FontStyle.Bold })
  const width = badgeLabel.width + badgeValue.width + sizes.BADGE_MARGIN * 2 + (hasUpArrow || hasDownArrow ? 18 : 0)
  const height = badgeValue.height + sizes.BADGE_MARGIN * 2

  return {
    width,
    height,
    render: () => {
      const startX = pdf.pages.currentX
      const startY = pdf.pages.currentY

      pdf.drawing.drawBadge(width, height, pdf.colors.grey)
      pdf.pages.moveDown(sizes.BADGE_MARGIN)
      pdf.pages.moveRight(sizes.BADGE_MARGIN)
      if (hasUpArrow) {
        pdf.pages.moveDown(4)
        pdf.pages.moveRight(8)
        pdf.drawing.drawUpArrow(8)
        pdf.pages.moveUp(4)
        pdf.pages.moveLeft(8)
        pdf.pages.moveRight(18)
      } else if (hasDownArrow) {
        pdf.pages.moveDown(4)
        pdf.pages.moveRight(8)
        pdf.drawing.drawDownArrow(8)
        pdf.pages.moveUp(4)
        pdf.pages.moveLeft(8)
        pdf.pages.moveRight(18)
      }
      badgeLabel.render()
      pdf.pages.moveRight(badgeLabel.width)
      badgeValue.render()
      pdf.pages.moveTo(startX, startY)
    },
  }
}

const addFooterBadge = (pdf: PDF, badge: { width: number, height: number, render: () => void }, badges: { width: number, height: number, render: () => void }[][]) => {
  const containerWidth = pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 2
  const currentRow = badges[badges.length - 1]
  const currentWidth = currentRow.reduce((width, col) => (
    width + col.width + sizes.CONTAINER_MARGIN
  ), 0)
  if (currentWidth + badge.width > containerWidth) {
    badges.push([badge])
  } else {
    currentRow.push(badge)
  }
}

const getFooter = (pdf: PDF, data: RoiTotals) => {
  const leads = getFooterBadge(pdf, 'Total Leads: ', data.leads)
  const investments = getFooterBadge(pdf, 'Total Investment: ', data.investment)
  const returns = getFooterBadge(pdf, 'Total Return: ', data.return)
  const roi = getFooterBadge(pdf, 'ROI: ', data.roi, data.positive, !data.positive)
  const lines = [[leads]]

  addFooterBadge(pdf, investments, lines)
  addFooterBadge(pdf, returns, lines)
  addFooterBadge(pdf, roi, lines)

  const heights = lines.map((row) => {
    const rowHeight = row.reduce((h, col) => (
      col.height > h ? col.height : h
    ), 0)

    return rowHeight
  })

  const height = heights.reduce((h, rowHeight) => (
    h + rowHeight
  ), 0) + sizes.CONTAINER_MARGIN * (lines.length - 1)

  if (height > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  return {
    width: pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 2,
    height,
    render: () => {
      pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
      pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
      lines.forEach((row, i) => {
        row.forEach((col) => {
          col.render()
          pdf.pages.moveRight(col.width + sizes.CONTAINER_MARGIN)
        })
        pdf.pages.resetX()
        pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
        pdf.pages.moveDown(heights[i])
        pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
      })
      pdf.pages.resetX()
    },
  }
}

const drawRow = (pdf: PDF, height: number, row: { width: number, height: number, render: () => void }[]) => {
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  row.forEach((col) => {
    col.render()
    pdf.pages.moveRight(col.width + sizes.CONTAINER_MARGIN)
  })
  pdf.pages.moveDown(height - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
}

export const drawRoi = (pdf: PDF, data: Roi[], totals: RoiTotals) => {
  const table = getTable(pdf, data)
  const titleHeight = table.title.height + sizes.CONTAINER_MARGIN * 2
  const headerHeight = table.header[0].height + sizes.CONTAINER_MARGIN * 2

  if (titleHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerHeader(pdf.pages.pageWidth, titleHeight)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  table.title.render()
  pdf.pages.moveDown(titleHeight - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()

  if (headerHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerRow(pdf.pages.pageWidth, headerHeight, pdf.colors.white)
  drawRow(pdf, headerHeight, table.header)

  table.rows.forEach((row, i) => {
    const height = row.reduce((h, col) => (
      col.height > h ? col.height : h
    ), 0) + sizes.CONTAINER_MARGIN * 2

    if (height > pdf.pages.remainingHeight) {
      pdf.pages.nextPage()
    }

    if (i % 2 === 0) {
      pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.grey)
    } else {
      pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.white)
    }

    drawRow(pdf, height, row)
  })

  const footer = getFooter(pdf, totals)
  pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, footer.height + sizes.CONTAINER_MARGIN * 2, pdf.colors.white)
  footer.render()

  pdf.pages.moveDown(sizes.SECTION_MARGIN)
}
