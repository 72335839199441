import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import EditCallIndustry from '../../../components/adminTools/calls/category/EditCallIndustry'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { IMIApi } from '../../../api/imi/api'
import { AppContext } from '../../../contexts/AppContext'
import { ICampaignTypeInDb } from '../../../api/imi/interfaces'

const EditCallIndustryPage:React.FC = () => {
  const location = useLocation()
  const [params, setParams] = useState<any>(location.state)
  const [loading, setLoading] = useState(true)
  const [callIndustry, setCallIndustry] = useState<any>({} as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchCampaignIndustry = async () => {
      const response = await IMIApi.GetCallIndustryById(params.id, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCallIndustry(response.data)
      return setLoading(false)
    }
    fetchCampaignIndustry()
    return () => abortController.abort()
  }, [params, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditCallIndustry
            isLoading={loading}
            callIndustry={callIndustry}
            edit
          />
        )}
      />
    </Padding>
  )
}

export default EditCallIndustryPage
