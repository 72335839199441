import {
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import {
  IAdvertiserInDb,
  ICampaignInDb,
  ICampaignType,
} from '../../../../api/imi/interfaces'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import UpliftDetailsForm from '../../../form/campaigns/UpliftDetailsForm'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  advertisers: IAdvertiserInDb[]
  loading: boolean
  setAdvertiserId: Dispatch<SetStateAction<number>>
  setCampaignId: Dispatch<SetStateAction<number>>
  setUpliftId: Dispatch<SetStateAction<number>>
  campaigns: ICampaignInDb[]
}

const CreateUplift:React.FC<Props> = ({
  advertisers,
  loading,
  setAdvertiserId,
  setCampaignId,
  setUpliftId,
  campaigns,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)

  const methods = useForm<ICampaignType>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      specifics: [{ name: '' }],
    },
  })

  const cancel = () => {
    methods.reset({}, { keepErrors: false, keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    const formData = {
      id: data.campaign.id,
      cycle_id: 0,
      uplift: data.uplift_base,
      budget: data.budget_base,
    }
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.SetCampaignUpliftById(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setUpliftId(submitResponse.data.id)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
  }
  return (
    <>
      <Content
        title="uplift details"
      >
        {loading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <UpliftDetailsForm
                setAdvertiserId={setAdvertiserId}
                setCampaignId={setCampaignId}
                campaigns={campaigns}
                advertisers={advertisers}
                errors={methods.formState.errors}
                showButton
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabled={!methods.formState.isValid}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                btnText={btnText}
                showButton
              />
            </form>
          </FormProvider>
        )}
      </Content>
      <Content
        title="cycle uplift details"
      >
        <p>To confirm source and uplifted budgets, go to “edit uplift” page to see the calculations based on the uplift percent entered above. For new campaigns, the cycle must be started before you can see the source and uplifted budgets.</p>
        <LinkWrapper
          href="/admin-tools/uplift"
          variant="defaultAdmin"
          className="mb-6 inline-block"
        >
          Edit Uplift
          <Icon size="xxs">
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper>
      </Content>
    </>
  )
}

export default CreateUplift
