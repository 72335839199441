import {
  useState,
  useMemo,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { IAdvertiserInDb } from '../../../api/imi/interfaces'
import Icon from '../../Icon'
import LinkWrapper from '../../Links'
import Content from '../../layouts/Content'
import { IMIApi } from '../../../api/imi/api'
import FormButtonGroup from '../../button/FormButtonGroup'
import { AppContext } from '../../../contexts/AppContext'
import AdvertiserForm from '../../form/advertiser/AdvertiserForm'
import LoadingSpinner from '../../LoadingSpinner'

// TODO: form validation
// TODO: match user id to white label and other "sections"
// TODO: is_active is returning string of "true" instead of boolean true

interface Props {
  isLoading: boolean
  advertiser: any
  edit: boolean
}

const EditAdvertiser:React.FC<Props> = ({
  advertiser,
  edit,
  isLoading,
}) => {
  const [btnText, setBtnText] = useState<string>('Save')
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)
  const [showButton, setShowButton] = useState<boolean>(edit)
  const { setError } = useContext(AppContext)
  const [editAdvertiser, setEditAdvertiser] = useState<IAdvertiserInDb>()

  useEffect(() => {
    setEditAdvertiser(advertiser)
  }, [advertiser])

  const methods = useForm<IAdvertiserInDb>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      name: editAdvertiser?.name,
      is_active: editAdvertiser?.is_active,
      id: editAdvertiser?.id,
    }), [editAdvertiser]),
  })

  useEffect(() => {
    methods.reset({ ...editAdvertiser })
  }, [editAdvertiser, methods])

  const editTemplate = () => {
    setShowButton(true)
  }
  const cancel = () => {
    methods.reset(editAdvertiser, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<IAdvertiserInDb> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateAdvertiser(data)
    setEditAdvertiser(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }
  // TODO: for some reason this does trigger making
  // the form field dirty. Not sure why but it bug is
  // fixed when this is in the code.
  // eslint-disable-next-line no-console
  // console.log(methods.formState.isDirty)
  return (
    <Content title="Advertiser Details">
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <AdvertiserForm
              errors={methods.formState.errors}
              advertiser={editAdvertiser}
              showButton={showButton}
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!methods.formState.isValid || !methods.formState.isDirty}
              disabledCancel={!methods.formState.isValid || !methods.formState.isDirty}
              onClickCancel={cancel}
              btnText={btnText}
              showButton={showButton}
              onClickEdit={editTemplate}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default EditAdvertiser
