import { SearchIcon } from '@heroicons/react/solid'
import Input from './Input'

interface Props {
  placeholder: string | undefined,
  hideLabel: boolean,
  className?: string,
  label: string,
  onChange: any,
  value: any,
}

const Search:React.FC<Props> = ({
  className,
  label,
  hideLabel,
  placeholder,
  onChange,
  value,
}) => (
  <Input
    name={label}
    label={label}
    type="search"
    placeholder={placeholder}
    className={`${className} appearance-none`}
    hideLabel={hideLabel}
    required={false}
    onChange={onChange}
    value={value}
    autoComplete="off"
    icon={<SearchIcon className="h-4 w-4 z-30 text-menuTitle" aria-hidden="true" />}
  />
)

export default Search
