import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import ToolTip from '../../../ToolTip'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import CallSettingsForm from '../../../form/campaigns/campaign_settings/CallSettingsForm'
import { ICampaignUpdate } from '../../../../api/imi/interfaces'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  isLoading: boolean,
  callCategories: any,
  callIndustries: any,
}

const EditCallProcess:React.FC<Props> = ({
  isLoading,
  callCategories,
  callIndustries,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)

  const methods = useForm<ICampaignUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: campaignInDb?.id,
      is_active: campaignInDb?.is_active,
      name: campaignInDb?.name,
      secondary_name: campaignInDb?.secondary_name,
      roi_is_active: campaignInDb ? campaignInDb.roi_is_active : false,
      uplift_base: 0,
      call_processing_is_active: campaignInDb ? campaignInDb.call_processing_is_active : false,
      advertiser_id: 0,
      category_id: campaignInDb?.category?.id,
      specific_id: 0,
      // pcampaign_id: 0,
      type_id: campaignInDb?.type?.id,
      // rois: roi,
      // rois: campaignInDb.rois,
    }), [campaignInDb]),
  })

  useEffect(() => {
    methods.reset(
      {
        id: campaignInDb?.id,
        is_active: campaignInDb?.is_active,
        name: campaignInDb?.name,
        secondary_name: campaignInDb?.secondary_name,
        roi_is_active: campaignInDb ? campaignInDb.roi_is_active : false,
        uplift_base: campaignInDb?.uplift_base,
        call_processing_is_active: campaignInDb ? campaignInDb.call_processing_is_active : false,
        advertiser_id: campaignInDb?.advertiser?.id,
        category_id: campaignInDb?.category?.id,
        specific_id: campaignInDb?.specific?.id,
        // pcampaign_id: 0,
        type_id: campaignInDb?.type?.id,
        // rois: roi,
      },
    )
    setIsButtonLoading(false)
    setTimeout(() => {
      setBtnText('Save')
      return setShowButton(false)
    }, 10)
  }, [campaignInDb, methods])

  const edit = () => {
    setShowButton(true)
  }
  const cancel = () => {
    methods.reset(
      {
        is_active: campaignInDb?.is_active,
        name: campaignInDb?.name,
        secondary_name: campaignInDb?.secondary_name,
        roi_is_active: campaignInDb ? campaignInDb.roi_is_active : false,
        uplift_base: campaignInDb?.uplift_base,
        call_processing_is_active: campaignInDb ? campaignInDb.call_processing_is_active : false,
        advertiser_id: campaignInDb?.advertiser?.id,
        category_id: campaignInDb?.category?.id,
        specific_id: campaignInDb?.specific?.id,
        // pcampaign_id: 0,
        type_id: campaignInDb?.type?.id,
        // rois: roi,
      },
    )
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }
  const onSubmit: SubmitHandler<ICampaignUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      ...campaignInDb,
      call_processing_is_active: data.call_processing_is_active,
      roi_is_active: data.roi_is_active,
      advertiser_id: campaignInDb.advertiser?.id,
      category_id: data.category_id,
      specific_id: campaignInDb?.specific?.id,
      type_id: campaignInDb?.type?.id,
    }
    const submitResponse = await IMIApi.UpdateCampaign(formData)
    // TODO: chain multiple submit responses
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setCampaignInDb(submitResponse.data)
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setBtnText('Save')
      return setShowButton(false)
    }, 1000)
  }

  return (
    <Content
      title="call processing"
      // title="call processing & return on investment (roi)"
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="call-process-info"
        >
          Determine if the SEM campaign needs call processing. Only the SEM campaign type should have call processing unless restricted by HIPAA laws (set in the platform), or requested not to process calls by the client.
          <br />
          <br />
          If we are not processing calls for a campaign, then all calls are automatically categorized as leads.
        </ToolTip>
      )}
    >
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <CallSettingsForm
              callIndustries={callIndustries}
              // callCategories={callCategories}
              errors={methods.formState.errors}
              showButton={showButton}
              campaign={campaignInDb}
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!methods.formState.isValid || !methods.formState.isDirty}
              disabledCancel={!methods.formState.isDirty}
              onClickEdit={edit}
              onClickCancel={cancel}
              btnText={btnText}
              showButton={showButton}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default EditCallProcess
