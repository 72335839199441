import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import CreateUser from '../../../components/adminTools/accounts/user_settings/CreateUser'
import EditUser from '../../../components/adminTools/accounts/user_settings/EditUser'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { AppContext } from '../../../contexts/AppContext'

const CreateUserPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [userTypesData, setUserTypesData] = useState<any>([])
  const [userId, setUserId] = useState(0)
  const { setError } = useContext(AppContext)
  const { setUserInDb, setUserAdIds } = useContext(UserContext)
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  useEffect(() => {
    const abortController = new AbortController()
    const fetchUserTypes = async () => {
      const response = await IMIApi.GetAllUserTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setUserTypesData(response.data.data)
      return setLoading(false)
    }
    fetchUserTypes()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    if (userId > 0) {
      const fetchUser = async () => {
        const response = await IMIApi.GetUserById(userId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setUserInDb(response.data)
        return setLoading(false)
      }
      fetchUser()
    }
    return () => abortController.abort()
  }, [userId, setUserInDb, setError])

  useEffect(() => {
    setUserId(0)
    setUserAdIds({ user_id: 0, advertiser_ids: [] })
  }, [setUserAdIds])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          userId > 0 ? (
            <EditUser
              isLoading={loading}
              userTypes={userTypesData}
              edit={false}
            />
          ) : (
            <CreateUser
              isLoading={loading}
              setUserId={setUserId}
              accountTypes={userTypesData}
            />
          )
        )}
      />
    </Padding>
  )
}
export default CreateUserPage
