// NOTE: Create includes updating because whole array is passed in
// each time, so always a post request.
import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { AppContext } from '../../../../contexts/AppContext'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import ToolTip from '../../../ToolTip'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import LoadingSpinner from '../../../LoadingSpinner'
import RoiForm from '../../../form/campaigns/campaign_settings/RoiForm'
import { IRoi, IRoiUpdate } from '../../../../api/imi/interfaces'

interface Props {
  isLoading: boolean,
  editPage?: boolean,
  setRefreshTodo?: any
}

const CreateEditRoi:React.FC<Props> = ({
  isLoading,
  editPage,
  setRefreshTodo,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)

  // when creating a new ROI
  const roi = useMemo(() => {
    if (campaignInDb?.category?.id === 4 && campaignInDb?.call_processing_is_active) {
      return [
        {
          name: 'Sales Call Leads',
          order: 1,
          is_active: true,
          converted: 0,
          avg_gross: 0,
          // call_category_id: 0,
          calculation: 'call',
          campaign_id: campaignInDb?.id,
        },
        {
          name: 'Service Call Leads',
          order: 2,
          is_active: true,
          converted: 0,
          avg_gross: 0,
          // call_category_id: 0,
          calculation: 'call',
          campaign_id: campaignInDb?.id,
        },
        {
          name: 'Parts Call Leads',
          order: 3,
          is_active: true,
          converted: 0,
          avg_gross: 0,
          // call_category_id: 0,
          calculation: 'call',
          campaign_id: campaignInDb?.id,
        },
        {
          name: 'Web Event Leads',
          order: 4,
          is_active: true,
          converted: 0,
          avg_gross: 0,
          // call_category_id: 0,
          calculation: 'webevent',
          campaign_id: campaignInDb?.id,
        },
        {
          name: 'Email Leads',
          order: 5,
          is_active: true,
          converted: 0,
          avg_gross: 0,
          // call_category_id: 0,
          calculation: 'email',
          campaign_id: campaignInDb?.id,
        },
      ]
    }
    return [
      {
        name: 'Call Leads',
        order: 1,
        is_active: true,
        converted: 0,
        avg_gross: 0,
        // call_category_id: 0,
        calculation: 'call',
        campaign_id: campaignInDb?.id,
      },
      {
        name: 'Web Event Leads',
        order: 2,
        is_active: true,
        converted: 0,
        avg_gross: 0,
        // call_category_id: 0,
        calculation: 'webevent',
        campaign_id: campaignInDb?.id,
      },
      {
        name: 'Email Leads',
        order: 3,
        is_active: true,
        converted: 0,
        avg_gross: 0,
        // call_category_id: null,
        calculation: 'email',
        campaign_id: campaignInDb?.id,
      },
    ]
  }, [campaignInDb?.category?.id, campaignInDb?.id, campaignInDb?.call_processing_is_active])

  const methods = useForm<IRoiUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: campaignInDb?.id > 0 ? campaignInDb.id : 0,
      roi_is_active: campaignInDb?.id > 0 ? campaignInDb.roi_is_active : false,
      lead_types: campaignInDb?.lead_types?.length > 0 ? campaignInDb?.lead_types : roi,
    }), [campaignInDb, roi]),
  })

  useEffect(() => {
    methods.reset(
      {
        id: campaignInDb?.id > 0 ? campaignInDb.id : 0,
        roi_is_active: campaignInDb?.id > 0 ? campaignInDb.roi_is_active : false,
        lead_types: campaignInDb?.lead_types?.length > 0 ? campaignInDb?.lead_types : roi,
      },
    )
  }, [campaignInDb, methods, roi])

  const edit = () => {
    setShowButton(true)
  }

  const cancel = () => {
    methods.reset(
      {
        id: campaignInDb.id > 0 ? campaignInDb.id : 0,
        roi_is_active: campaignInDb ? campaignInDb.roi_is_active : false,
        lead_types: campaignInDb?.lead_types?.length > 0 ? campaignInDb?.lead_types : roi,
      },
    )
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<IRoiUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      id: data.id,
      roi_is_active: data.roi_is_active,
      lead_types: data.lead_types,
    }

    const submitResponse = await IMIApi.CreateCampaignRoi(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setCampaignInDb({
        ...campaignInDb,
        roi_is_active: data.roi_is_active,
        lead_types: data.lead_types,
      })
      setRefreshTodo(Math.random)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  return (
    <Content
      title={campaignInDb?.id > 0 ? 'lead types' : 'return on investment (roi)'}
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="roi-info"
        >
          Default information can be changed, as needed. Optional fields can be left blank. If left blank, they will display as a dash in the ROI section of the campaign report, and the return for that lead type will not be calculated.
          <br />
          <br />
          <span className="text-disclaimer">*If we are not processing calls, all calls are automatically categorized as leads.</span>
        </ToolTip>
      )}
    >
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <RoiForm
              editPage={editPage}
              // callIndustries={callIndustries}
              errors={methods.formState.errors}
              showButton={showButton}
            />
            <div />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!methods.formState.isValid || !methods.formState.isDirty || campaignInDb?.id <= 0}
              disabledCancel={!methods.formState.isDirty}
              onClickEdit={edit}
              onClickCancel={cancel}
              btnText={btnText}
              showButton={showButton}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default CreateEditRoi
