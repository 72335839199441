import {
  Admin, CallAnalyst, CallManager, Client, Partner, TeamViewer, 
} from '../../constants/UserTypes'

export const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// "table" header for daily report details
export const placementBreakdownMenu = [
  {
    fieldName: 'placements',
    name: 'Placement',
    mobile: false,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'started',
    name: 'Started',
    abbreviation: 'Started',
    mobile: false,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'completed',
    name: 'Completed',
    abbreviation: 'Completed',
    mobile: true,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'completion_rate',
    name: 'Completion Rate',
    abbreviation: 'Completion Rate',
    mobile: false,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'cpcv',
    name: 'Cost Per Completed View (CPCV)',
    abbreviation: 'CPCV',
    mobile: false,
    sort: true,
    forUserTypes: [Admin, TeamViewer, CallManager, CallAnalyst, Partner, Client],
  },
  {
    fieldName: 'spend', // Field does not exist unless the user is an admin
    name: 'Spend',
    mobile: true,
    sort: true,
    forUserTypes: [Admin, TeamViewer],
  },
]

export const activityBreakdownMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
  },
  {
    fieldName: 'post_impressions',
    name: 'Post-Impressions',
    abbreviation: 'Pst-Impr',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'post_clicks',
    name: 'Post-Clicks',
    abbreviation: 'Pst-Clks',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'activities',
    name: '# of Activities',
    abbreviation: '# of Act',
    mobile: false,
    sort: true,
  },
]

export const videoCreativePerformanceMenu = [
  {
    fieldName: 'label',
    name: 'Creative Label',
    mobile: false,
  },
  {
    fieldName: 'started',
    name: 'Started',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'completed',
    name: 'Completed',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'completion_rate',
    name: ' Completion Rate',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'cpcv',
    name: 'Cost Per Completed View (CPCV)',
    abbreviation: 'CTR',
    mobile: true,
    sort: true,
  },
]
