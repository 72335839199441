import { useEffect, useState, useContext } from 'react'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'
import Pagination from '../Pagination'
import HorizontalGrid from '../layouts/HorizontalGrid'
import { ReactComponent as CircleIcon } from '../../assets/icons/circle-bg.svg'
import { ISemDetail } from '../../api/imi/interfaces'
import { fixDateTimeObj, fixHoursObj } from '../../helpers/utils'

// SEM Email report section titled "email leads"
// shows list of all emails

interface Props {
  data: ISemDetail
}
// TODO: waiting on email data in reports
// TODO: add in loading spinner for emails

const EmailReport: React.FC<Props> = ({ data }) => {
  const { user } = useContext(AppContext)
  // data coming in from SEM report page
  const [dailyActivity, setDailyActivity] = useState<ISemDetail>([] as any)
  // different limits on number of items shown based on user type (admin or other)
  const [pageLimit, setPageLimit] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setPageLimit(7)
    setDailyActivity(data)
  }, [data, user.user_type_id])

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (dailyActivity.form_leads.length - pageLimit))
    if (user.user_type_id === 0) {
      setPageLimit(pageLimit + (dailyActivity.form_leads.length - pageLimit))
    }
  }

  const handleCollapse = () => {
    setPageLimit(7)
  }

  const addLeadingZero = (index) => (
    index < 10 ? `${index}` : index
  )

  return (
    <Content
      title="Email Leads"
    >
      {dailyActivity?.form_leads && dailyActivity?.form_leads.sort((a, b) => (a.occurred_at > b.occurred_at ? 1 : -1)).filter((metric, amount) => amount < pageLimit).map((email, index) => (
        <HorizontalGrid
          smGrid="grid-cols-1"
          mdGrid="md:grid-cols-4"
          border
          padding={false}
          key={email.id}
        >
          <div className="md:flex">
            <div className="hidden md:flex md:flex-shrink-0 md:justify-center md:items-center md:mr-3">
              <span className="absolute text-light text-xl">{addLeadingZero(index + 1)}</span>
              <CircleIcon className="w-12" />
            </div>
            <div className="grid grid-cols-2 flex-1 md:block capitalize break-all">
              {`${email.contact?.first_name ? email.contact?.first_name : ''} ${email.contact?.last_name ? email.contact?.last_name : ''}`}
              <p className="text-date text-xxs row-span-full md:justify-self-start md:row-auto">Customer</p>
            </div>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto lowercase break-all">
            {email.contact.email ? email.contact.email : '—'}
            <p className="text-date text-xxs row-span-full">Email Address</p>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto">
            {/* {formatDate(email.occurred_at).justDate} */}
            {fixDateTimeObj(email.occurred_at)}
            <p className="text-date text-xxs row-span-full">Date</p>
          </div>
          <div className="grid grid-cols-2 md:block md:justify-self-start md:row-auto">
            {fixHoursObj(email.occurred_at)}
            <p className="text-date text-xxs row-span-full">Time</p>
          </div>
        </HorizontalGrid>
      ))}
      {dailyActivity?.form_leads && (
        <div className={`${!dailyActivity?.form_leads || dailyActivity?.form_leads.length > 0 ? 'block' : 'hidden'}`}>
          <Pagination
            onClick={handleLoadMore}
            pageLimit={pageLimit}
            adminLimit={7}
            data={dailyActivity?.form_leads}
            offset={7}
            onClickCollapse={handleCollapse}
          />
        </div>
      )}
    </Content>
  )
}
export default EmailReport
