import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  useForm, SubmitHandler, Controller, FormProvider,
} from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import ToolTip from '../../../ToolTip'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import LoadingSpinner from '../../../LoadingSpinner'
import * as Interface from '../../../../api/imi/interfaces'
import Button from '../../../button/Button'
import { PAUSED, PCampaignStatus, RUNNING } from '../../../../constants/PCampaignStatus'
import Radio from '../../../form/Radio'
import { IGenericStatusResponse } from '../../../../api/imi/interfaces'
import FormButtonGroup from '../../../button/FormButtonGroup'

interface Props {
  updateMethod: (req: IGenericStatusResponse) => void,
  isLoading: boolean,
  status: string,
}

const EditCampaignPaused: React.FC<Props> = ({
  updateMethod,
  isLoading,
  status,
}) => {
  const { setError } = useContext(AppContext)
  const { campaignInDb } = useContext(CampaignContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(true)

  const form = useForm<{ id: number, value: boolean }>({
    defaultValues: useMemo(() => ({ id: campaignInDb?.id, value: (status !== RUNNING) }), [status, campaignInDb.id]),
  })

  useEffect(() => {
    form.reset({ id: campaignInDb?.id, value: status !== RUNNING })
  }, [status, campaignInDb?.id, form])

  const editCampaign = () => {
    setShowButton(true)
  }

  const cancel = () => {
    form.reset({ id: campaignInDb?.id, value: status !== RUNNING }, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<{ id: number, value: boolean }> = async (data: any) => {
    const formData = {
      id: data.id,
      value: data.value,
    }
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const response = await IMIApi.SetCampaignPausedStatus(campaignInDb?.id, formData.value)
    if (response.status === 200) {
      updateMethod(response.data)
      setIsButtonLoading(false)
      setBtnText('Saved!')
    }
    setShowButton(false)
    return setBtnText('Save')
  }

  const explainStatus = () => {
    if (status) {
      const statusController = new PCampaignStatus(status)
      if (statusController.isRunning()) {
        return (
          <>
            Campaign is
            <b> running</b>
            . It can be paused.
          </>
        )
      }
      if (statusController.isNotStarted()) {
        return (
          <>
            Campaign has no assigned child campaigns. Assign a child campaign before attempting to pause this campaign.
          </>
        )
      }
      if (statusController.isEnded()) {
        return (
          <>
            Campaign has
            <b> ended</b>
            . Its status cannot be changed.
          </>
        )
      }
      if (statusController.isPaused()) {
        return (
          <>
            Campaign is
            <b> paused</b>
            . Campaign data will still be loaded. It can be un-paused.
          </>
        )
      }
    }
    return (<>Unable to alter status of this Campaign.</>)
  }

  return (
    <Content
      title="campaign status"
      // title="call processing & return on investment (roi)"
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="status-info"
        >
          If a running campaign has been paused with a vendor, it needs to be manually updated to &quot;paused&quot; here in our dashboard as well. Once the campaign has been resumed with the vendor, this field needs to be updated accordingly.
        </ToolTip>
      )}
    >
      <p>{explainStatus()}</p>
      {!isLoading && status !== 'ended' && (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Radio
              name="value"
              label="Status"
              disabled={!showButton}
              options={[
                {
                  id: 1,
                  label: 'Running',
                  value: false,
                },
                {
                  id: 0,
                  label: 'Paused',
                  value: true,
                },
              ]}
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!form.formState.isValid || !form.formState.isDirty}
              disabledCancel={!form.formState.isDirty}
              onClickCancel={cancel}
              btnText={btnText}
              onClickEdit={editCampaign}
              showButton={showButton}
            />
            {isLoading && (
              <LoadingSpinner variant="primary" size="lg" />
            )}
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default EditCampaignPaused
