import { useEffect, useState, useContext } from 'react'
import { IMIApi } from '../../api/imi/api'
import AdminToolsMenu from '../../components/adminTools/AdminToolsMenu'
import Padding from '../../components/layouts/Padding'
import SidebarLeft from '../../components/layouts/SidebarLeft'
import { AppContext } from '../../contexts/AppContext'
import CreateApiSettings from '../../components/adminTools/api/CreateApiSettings'
import { IPlatform } from '../../api/imi/interfaces'

const CreateApiSettingsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [platforms, setPlatforms] = useState<IPlatform[]>([])
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchPlatforms = async () => {
      const response = await IMIApi.GetPlatforms(abortController.signal)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setPlatforms(response.data.filter((p) => p.id !== 1))
      return setLoading(false)
    }
    fetchPlatforms()
    return () => abortController.abort()
  }, [setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <CreateApiSettings
            platforms={platforms}
            isLoading={loading}
          />
        )}
      />
    </Padding>
  )
}
export default CreateApiSettingsPage
