import { useEffect, useState } from 'react'
import { useFormContext, useFieldArray, Controller } from 'react-hook-form'
import { ChevronRightIcon, TrashIcon } from '@heroicons/react/outline'
import Icon from '../../Icon'
import Input from '../Input'
import LinkWrapper from '../../Links'
import { ICampaignTypeInDb } from '../../../api/imi/interfaces'
import Badge from '../../badge/Badge'
import Modal from '../../modal'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  campaign?: ICampaignTypeInDb
}

const CampaignTypeForm:React.FC<Props> = ({
  showButton,
  errors,
  campaign,
}) => {
  const methods = useFormContext()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentSelection, setCurrentSelection] = useState<any>()
  const [btnText, setBtnText] = useState('Change')
  const { fields, append, remove } = useFieldArray({
    ...methods.control,
    name: 'specifics',
  })
  // const resetSpecifics = methods.setValue('specifics', [{ name: '' }])
  const watchSpecifics = methods.watch('specifics')

  // console.log(watchSpecifics)

  // useEffect(() => {
  //   const resetSpecifics = methods.setValue('specifics', [{ name: '' }])
  //   return resetSpecifics
  // }, [methods])

  // const controlledFields = fields.map((field, index) => {
  //   const test = 1
  //   return {
  //     ...field,
  //     ...watchSpecifics[index],
  //   }
  // })

  const addNumber = (index) => {
    const number = index + 1
    if (number < 10) {
      return `0${number}`
    }
    return number
  }

  const modal = (itemIndex) => {
    setBtnText('Remove')
    setCurrentSelection(itemIndex)
    setShowModal(true)
  }

  const removeItem = () => {
    remove(currentSelection)
    return setShowModal(false)
  }

  return (
    <>
      <Modal
        show={showModal}
        title="Confirm Change"
        body={(
          `Remove Category ${currentSelection + 1}?`
        )}
        onSubmit={() => removeItem()}
        onClose={() => setShowModal(false)}
        closeText="Cancel"
        submitText={btnText}
      />
      <Input
        disabled={!showButton}
        label="Name"
        type="text"
        toolTipInfo={(
          <span>
            This full name name is used in the masthead title for the report page. When adding a new campaign type, please confirm with a supervisor/management team about the proper name. This name should also match what verbiage is on the enCOMPASS website.
          </span>
        )}
        {...methods.register('name', {
          required: true,
        })}
        error={errors.name && <span className="form-error">{errors.name.message || 'This field is required.'}</span>}
      />
      <Input
        disabled={!showButton}
        label="Shortened Name"
        type="text"
        toolTipInfo={(
          <span>
            The “shortened name” value is used for campaign central and campaign overview. There is a limit of 8 characters.
          </span>
        )}
        {...methods.register('short_name', {
          required: true,
          maxLength: {
            value: 8,
            message: 'Name cannot be longer than 8 characters.',
          },
        })}
        error={errors.short_name && <span className="form-error">{errors.short_name.message || 'This field is required.'}</span>}
      />
      {/* TODO: add back in svg/image input */}
      {/* <Input
        disabled={!showButton}
        label="SVG graphic (HTML code)"
        type="file"
        toolTipInfo={(
          <span>
            {'Upload the code for your svg starting with <svg>'}
          </span>
        )}
        {...methods.register('test', {
          required: true,
        })}
        error={errors.short_name && <span className="form-error">{errors.short_name.message || 'This field is required.'}</span>}
      /> */}
      <hr />
      {campaign?.id && (
        <>
          <h5 className="mb-3">Current campaign specifics</h5>
          {campaign.specifics.map((specific) => (
            <Badge
              key={specific.id}
              label={specific?.name}
              variant="badgeOnly"
            />
          ))}
          <hr />
        </>
      )}
      <h4 className="pb-3">{campaign?.id ? 'Add New Campaign Specific' : 'Campaign Specifics'}</h4>
      <ul>
        {fields?.map((item, index) => (
          <li key={item.id}>
            <Input
              {...methods.register(`specifics.${index}.name` as const, {
                required: false,
              })}
              required={false}
              disabled={!showButton}
              label={`Name ${addNumber(index)}`}
              trashIcon={(
                <LinkWrapper
                  href="#"
                  variant="defaultAdmin"
                  className="inline-block"
                  onClick={() => modal(index)}
                >
                  <Icon size="sm" colors="text-primary">
                    <TrashIcon className="text-primary hover:text-secondary global-animation" />
                  </Icon>
                </LinkWrapper>
              )}
              type="text"
              toolTipInfo={(
                <span>
                  This is not a required field; it should be used in the instance where providing specifics about a campaign would help distinguish it from other campaigns.
                </span>
              )}
            />
          </li>
        ))}
      </ul>

      <LinkWrapper
        href="#"
        variant="defaultAdmin"
        className="mb-6 inline-block"
        onClick={() => (
          append({
            name: '',
            id: '',
          })
        )}
      >
        Add Additional Campaign Specific
        <Icon size="xxs">
          <ChevronRightIcon />
        </Icon>
      </LinkWrapper>
      <br />
    </>
  )
}

export default CampaignTypeForm
