import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { cls } from '../../helpers/utils'

const styles = {
  base: 'front-display global-animation block',
  variant: {
    menu: 'text-xs text-menu hover:text-secondary mb-4 font-display',
    breadcrumb: 'text-xxs text-light hover:text-secondary pb-2',
  },
  active: {
    menu: 'text-menu font-bold',
    breadcrumb: 'text-primary',
  },
  disabled: {
    menu: 'text-opacity-40',
    breadcrumb: 'cursor-not-allowed text-light text-xxs text-opacity-40',
  },
}
// text-opacity-40
interface Props {
  className?: string
  to: any
  variant: 'menu' | 'breadcrumb'
  disabled?: boolean
  onClick?: () => void
}

const NavLinkWrapper: React.FC<Props> = ({
  className,
  to,
  disabled = false,
  variant = 'menu',
  onClick,
  ...props
}) => (
  <NavLink
    to={to}
    onClick={onClick}
    activeClassName={variant === 'menu' ? styles.active.menu : ''}
    onContextMenu={onClick}
    className={cls(`
      ${styles.base}
      ${!disabled ? styles.variant[variant] : styles.disabled[variant]}
      ${className}
    `)}
    {...props}
  />
)

NavLinkWrapper.defaultProps = {
  className: '',
  disabled: false,
  onClick: undefined,
}

export default NavLinkWrapper
