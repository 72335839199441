import React from 'react'
import ToolTip from '../ToolTip'

const styles = {
  base: 'h-3 w-3 rounded-circle flex items-center justify-center mr-2',
  variant: {
    legend: 'bg-primary',
    graph: 'bg-secondary',
  },
}

interface Props {
  label: string
  calculation: string | number
  toolTipInfo?: string
  position?: string
  toolTipId: string
  onClick?: () => void
  icon?: React.ReactNode
  fullWidth?: boolean
}

const BadgeInfo: React.FC<Props> = ({
  label,
  calculation,
  toolTipInfo,
  position = 'relative',
  toolTipId,
  onClick,
  icon,
  fullWidth,
}) => (
  onClick ? (
    <span aria-hidden="true" className={`${position} inline-flex mr-3 items-center px-3 py-2 rounded-lg text-xs bg-grey-lightest text-default mb-3 font-display`}>
      {icon}
      {icon ? <>&nbsp;</> : ''}
      {label}
      :&nbsp;
      <span className="font-bold">{calculation}</span>
      {toolTipInfo ? <ToolTip tipId={toolTipId} size="xs" position="relative right-0 pt-0">{toolTipInfo}</ToolTip> : null}
    </span>
  ) : (
    <span aria-hidden="true" className={`${position} ${fullWidth ? 'block' : 'inline-flex'} font-display mr-3 items-center px-3 py-2 rounded-lg text-xs bg-grey-lightest text-default mb-3`}>
      {icon}
      {icon ? <>&nbsp;</> : ''}
      {label}
      :&nbsp;
      <span className="font-bold">{calculation}</span>
      {toolTipInfo ? <sup><ToolTip tipId={toolTipId} size="xs" position="relative right-0 ml-0.5 pt-0"><span className="leading-4">{toolTipInfo}</span></ToolTip></sup> : null}
    </span>
  )
)
BadgeInfo.defaultProps = {
  position: 'relative',
  toolTipInfo: undefined,
  onClick: undefined,
  icon: null,
  fullWidth: false,
}
export default BadgeInfo
