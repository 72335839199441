import {
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import {
  campaignSettingsStyles,
  unassignedCampaignsMenu,
} from './Data'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import Content from '../../../layouts/Content'
import Icon from '../../../Icon'
import LinkWrapper from '../../../Links'
import EditMenu from '../../EditMenu'
import BadgeAlert from '../../../badge/BadgeAlert'
import ToolTip from '../../../ToolTip'
import { ICampaignSource } from '../../../../api/imi/interfaces'
import { getFieldByPath } from '../../../../helpers/utils'
import { AppContext } from '../../../../contexts/AppContext'
import LoadingSpinner from '../../../LoadingSpinner'

// TODO: fix double clicking on account type sorting
// TODO: campaign filter

interface Props {
  totalData: number
  unassignedCampaigns: ICampaignSource[]
  setPagination: Dispatch<SetStateAction<any>>
  // refresh: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
}

const CampaignSettings:React.FC<Props> = ({
  unassignedCampaigns,
  // refresh,
  setPagination,
  isLoading,
  totalData,
}) => {
  const [errorMsg, setErrorMsg] = useState('There are no campaigns found.')
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState<string>('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [currentOffset, setCurrentOffset] = useState(0)
  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = sortDirection ? unassignedCampaigns?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1)) : unassignedCampaigns?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1))
    return { values }
  }

  const handleLoadMore = () => {
    setPagination({
      offset: currentOffset + 10,
      limit: 10,
    })
    setCurrentOffset(currentOffset + 10)
  }
  const handleReturn = () => {
    if (currentOffset > 0) {
      setPagination({
        offset: currentOffset - 10,
        limit: 10,
      })
      setCurrentOffset(currentOffset - 10)
    }
  }

  // TODO: fix for sorting my name not numbers
  const platformCheck = (platformId) => {
    if (platformId === 1) {
      return 'RL'
    }
    if (platformId === 2) {
      return 'SA'
    }
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('p_campaign_id', JSON.stringify(id))
  }

  return (
    <Content
      title="Unassigned Campaigns"
      padding={false}
      badge={(
        <BadgeAlert
          alert={unassignedCampaigns?.length || 0}
          status={false}
        />
      )}
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="white-label-info"
        >
          <span>
            Unassigned campaigns have been set up in the vendor platform, but they have not been created or assigned in our dashboard.
          </span>
        </ToolTip>
      )}
    >
      {isLoading ? (
        <span className="mx-4 md:mx-6 block"><LoadingSpinner variant="primary" size="lg" /></span>
      ) : (
        <>
          <HorizontalGrid
            smGrid="grid-cols-3"
            mdGrid="md:grid-cols-4"
          >
            {unassignedCampaignsMenu.map((item) => (
              item.sort ? (
                <span
                  aria-hidden="true"
                  onClick={() => columnSortingInfo(item.fieldName)}
                  key={item.name}
                  className={(
                    `flex break-word group hover:text-secondary hover:cursor-pointer
                    ${item.mobile && campaignSettingsStyles.desktop}`
                  )}
                >
                  {item.name}
                  <Icon
                    size="xxs"
                    className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                  >
                    <ChevronUpIcon
                      className="-mb-1 text-default group-hover:text-secondary"
                    />
                    <ChevronDownIcon
                      className="text-default group-hover:text-secondary"
                    />
                  </Icon>
                </span>
              ) : <span key={item.name}>{item.name}</span>
            ))}
          </HorizontalGrid>
          <div className="items-center">
            {unassignedCampaigns?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
              unassignedCampaigns?.map((campaign) => (
                <HorizontalGrid
                  key={campaign.id}
                  smGrid="grid-cols-3"
                  mdGrid="md:grid-cols-4"
                  backgroundColor
                >
                  <p className="text-xxs text-gray-500 pr-3">
                    <LinkWrapper
                      variant="defaultSm"
                      key={campaign.id}
                      onClick={() => handleLocalStorage(campaign.id)}
                      href={{
                        pathname: '/admin-tools/campaign-settings/assign-campaign',
                      }}
                    >
                      {campaign.name}
                    </LinkWrapper>
                  </p>
                  <p className="text-xxs text-gray-500">
                    {platformCheck(campaign.platform_id)}
                  </p>
                  <p className="text-xxs text-gray-500 hidden sm:block">
                    {campaign.api_id}
                  </p>
                  <p className="text-xxs text-gray-500 capitalize">
                    {campaign.api_type}
                  </p>
                  <EditMenu
                    text="Assign Campaign"
                    id={campaign.id}
                    pathname="/admin-tools/campaign-settings/assign-campaign"
                    onClick={() => handleLocalStorage(campaign.id)}
                  />
                </HorizontalGrid>
              ))
            )}
          </div>
          {/* <Pagination onClick={handleLoadMore} onClickBack={handleReturn} pageLimit={10} adminLimit={10} offset={currentOffset} total={totalData} data={unassignedCampaigns} /> */}
        </>
      )}
    </Content>
  )
}

export default CampaignSettings
