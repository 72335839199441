import { useState, useContext, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { UserContext } from '../../../contexts/UserContext'
import Input from '../Input'
import Radio from '../Radio'
import Dropdown from '../Dropdown'
import Icon from '../../Icon'
import { splitComma } from '../../../helpers/utils'
import LinkWrapper from '../../Links'
import { AppContext } from '../../../contexts/AppContext'

interface Props {
  disabled?: boolean
  showButton?: boolean
  accountTypesData: []
  errors: any
}

const AccountUserForm:React.FC<Props> = ({
  showButton,
  accountTypesData,
  errors,
}) => {
  const methods = useFormContext()
  const { user } = useContext(AppContext)
  const { userInDb } = useContext(UserContext)
  const watchFields = methods.watch('password')
  const [editPassword, setEditPassword] = useState(false)
  const [accountTypeInfo, setAccountTypeInfo] = useState('Choose an account type then hover back over this icon to learn more about each type.')
  useEffect(() => {
    setEditPassword(false)
  }, [methods.formState.isSubmitSuccessful])

  const watchUserType = methods.watch('user_type_id')

  const cancelPassword = () => {
    setEditPassword(!editPassword)
    methods.setValue('password', '')
    methods.setValue('confirm_password', '')
    methods.clearErrors()
  }

  useEffect(() => {
    const userTypeInfoBox = () => {
      // admin
      if (watchUserType === 1) {
        return setAccountTypeInfo('Full access to create, edit, and delete in the "admin tools"')
      }
      // team viewer
      if (watchUserType === 2) {
        return setAccountTypeInfo('Ability to view all reports, flagged calls, and, if applicable, the ROI section during a cycle (enCOMPASS employees)')
      }
      // call manager
      if (watchUserType === 3) {
        return setAccountTypeInfo('Ability to process calls, view all reports and flagged call reports, create instructions for Call Analysts')
      }
      // call processor
      if (watchUserType === 4) {
        return setAccountTypeInfo('Ability to process calls')
      }
      // partner
      if (watchUserType === 5) {
        return setAccountTypeInfo('Inherits all client privileges, has the ability to access “campaign central” with all of their campaigns, will have custom email template, dashboard branding, and .csv exports. Minimum spend of $30,000/month and must have supervisor approval')
      }
      // client
      if (watchUserType === 6) {
        return setAccountTypeInfo('Default login for any user, ability to view assigned campaign reports, can apply to clients that have 1 or more advertisers')
      }
      return setAccountTypeInfo('Choose an account type then hover back over this icon to learn more about each type.')
    }
    userTypeInfoBox()
  }, [watchUserType])

  return (
    <>
      <Input
        disabled={!showButton}
        label="User name"
        type="text"
        toolTipInfo={(
          <span>
            The user name will always be the advertiser&apos;s domain name.
            All advertisers and all campaigns associated with this advertiser will use
            this login. If specific access to advertisers or campaigns, additional users,
            or additional login names are requested, use the same user name, but add a
            dash and a number - starting with 1 and incrementing up. (-01, -02).
            <br />
            <br />
            User names must be unique, lowercase only, and no spacing. Numbers and dashes are allowed.
          </span>
        )}
        {...methods.register('username', {
          required: true,
          minLength: {
            value: 5,
            message: 'Usernames must be at least 5 characters long',
          },
          pattern: {
            value: /^[a-z0-9-]*$/,
            message: 'Your username is not the correct format. Please only user lowercase letters, dashes, and numbers only.',
          },
        })}
        error={errors.username && <span className="form-error">{errors.username?.message || 'This field is required.'}</span>}
      />
      <Input
        disabled={!showButton}
        {...methods.register('first_name')}
        label="First name"
        type="text"
        required={false}
      />
      <Input
        disabled={!showButton}
        {...methods.register('last_name')}
        label="Last name"
        type="text"
        required={false}
      />
      <Input
        disabled={!showButton}
        {...methods.register('email', {
          required: true,
          pattern: {
            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: 'The email you have entered is not a valid email address',
          },
        })}
        label="Email"
        type="email"
        toolTipInfo={(
          <span>
            The email address provided here should be unique and is the primary email address associated with the user&apos;s
            account. The &quot;forgot password&quot; email will be sent to this email address. Reports will NOT
            be sent to this email address; if this email needs to receive reports too, add the email address
            to one of the 3 report email fields below.
          </span>
        )}
        error={errors.email && <span className="form-error">{errors.email?.message || 'This field is required.' }</span>}
      />
      {userInDb.id > 0 ? (
        <>
          <Input
            required={false}
            {...methods.register('password', {
              minLength: {
                value: 8,
                message: 'Passwords must be at least 8 characters long',
              },
              pattern: {
                value: /^(?=.*?\d)[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g,
                message: 'Passwords must have at least one number. Some special characters are allowed, commas, semicolons, and spaces are not allowed.',
              },
            })}
            label="Password"
            type="text"
            toolTipInfo={(
              <span>
                The password is the user name (can be a shortened version) with &quot;1m1&quot; at the end.
                For multiple users needed for one advertiser (that have a dash and number at the end), remove the dash, and keep the number, and add &quot;1m1&quot;.
                <br />
                <br />
                Minimum of 8 characters and must include one number. No spaces. Special characters allowed. Case sensitive.
              </span>
            )}
            error={errors.password && <span className="form-error">{errors.password.message || 'This field is required'}</span>}
            disabled={!editPassword || !showButton}
            placeholder={!editPassword || !showButton ? '•••••••••' : ''}
          >
            <LinkWrapper
              disabled={!showButton}
              href="#"
              variant="defaultAdmin"
              onClick={cancelPassword}
              className="mb-6"
            >
              {!editPassword || !showButton ? 'Change Password' : 'Cancel'}
              <Icon size="xxs" className={`${!showButton}` ? 'cursor-not-allowed opacity-60' : ''}>
                <ChevronRightIcon />
              </Icon>
            </LinkWrapper>
          </Input>
          {(editPassword && showButton) && (
            <Input
              {...methods.register('confirm_password', { validate: (value) => value === watchFields })}
              label="Confirm password"
              type="text"
              error={errors.confirm_password && <span className="form-error">Passwords do not match</span>}
            />
          )}
        </>
      ) : (
        <>
          <Input
            {...methods.register('password', {
              required: true,
              minLength: {
                value: 8,
                message: 'Passwords must be at least 8 characters long and include one number',
              },
              pattern: {
                value: /^(?=.*?\d)[a-zA-Z0-9!@#$%^&*)(+=._-]+$/g,
                message: 'Passwords must have at least one number. Some special characters are allowed, commas, semicolons, and spaces are not allowed.',
              },
            })}
            label="Password"
            type="text"
            toolTipInfo={(
              <span>
                The password is the user name (can be a shortened version) with &quot;1m1&quot; at the end.
                For multiple users needed for one advertiser (that have a dash and number at the end), remove the dash, and keep the number, and add &quot;1m1&quot;.
                <br />
                <br />
                Minimum of 8 characters and must include one number. No spaces. Special characters allowed. Case sensitive.
              </span>
            )}
            error={(
              errors.password && (
                <span className="form-error">
                  {errors.password.message || 'This field is required'}
                </span>
              )
            )}
          />
          <Input
            {...methods.register('confirm_password', { required: true, validate: (value) => value === watchFields })}
            label="Confirm password"
            type="text"
            error={(
              errors.confirm_password && (
              <span className="form-error">Passwords do not match</span>
              )
            )}
          />
        </>
      )}
      <Radio
        name="is_active"
        label="Status"
        disabled={!showButton || user.id === userInDb.id}
        options={[
          {
            id: 1,
            label: 'Active',
            value: true,
          },
          {
            id: 0,
            label: 'Inactive',
            value: false,
          },
        ]}
      />
      {accountTypesData.length && (
        <Controller
          name="user_type_id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              placeholder={userInDb.id > 0 ? undefined : 'Select...'}
              label="Account type"
              variant="admin"
              value={methods.getValues('user_type_id')}
              list={accountTypesData}
              error={errors.user_type_id && <span className="form-error">This field is required</span>}
              toolTipInfo={accountTypeInfo}
              required
            />
          )}
        />
      )}
      {watchUserType >= 5 && (
        <>
          <Input
            {...methods.register('email_for_all_reports', { pattern: /^(([a-zA-Z0-9_]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*/ })}
            disabled={!showButton}
            type="email"
            toolTipInfo={(
              <span>
                &quot;All reports&quot; includes both the weekly summary reports and flagged call reports.
                If an email address should get both of these reports, the email address should
                ONLY be placed in this field; it should not be duplicated in the other &quot;report&quot;
                fields. Based on the system&apos;s character limitations, you can only include 2-3
                email addresses.
              </span>
            )}
            label="Email for all reports"
            required={false}
            onChange={(e) => splitComma(e.target.value)}
          />
          <Input
            {...methods.register('email_for_lead_reports', { pattern: /^(([a-zA-Z0-9_]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*/ })}
            disabled={!showButton}
            type="email"
            toolTipInfo={(
              <span>
                An email address should be included here if it should receive the weekly campaign summary reports
                ONLY. If it needs to receive weekly summary reports and flagged call reports, add it to
                the &quot;all reports&quot; field instead. Based on the system&apos;s character
                limitations, you can only include 2-3 email addresses.
              </span>
            )}
            label="Email for weekly campaign summary reports (only)"
            required={false}
            onChange={(e) => splitComma(e.target.value)}
          />
          <Input
            {...methods.register('email_for_flagged_calls', { pattern: /^(([a-zA-Z0-9_]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*/ })}
            disabled={!showButton}
            type="email"
            toolTipInfo={(
              <span>
                An email address should be included here if it should receive the flagged call
                reports ONLY. If it needs to receive weekly summary reports and flagged call reports, add
                it to the &quot;all reports&quot; field instead. Based on the system&apos;s character
                limitations, you can only include 2-3 email addresses.
              </span>
            )}
            label="Email for flagged call reports (only)"
            required={false}
            onChange={(e) => splitComma(e.target.value)}
          />
        </>
      )}
    </>
  )
}

export default AccountUserForm
