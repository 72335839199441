import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Radio from '../Radio'
import Input from '../Input'
import { IAdvertiserInDb } from '../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  advertiser?: IAdvertiserInDb
}

const AdvertiserForm:React.FC<Props> = ({
  showButton,
  errors,
  advertiser,
}) => {
  const methods = useFormContext()
  // const test = methods.watch(('is_active'), { shouldDirty: true })
  const test = methods.watch('is_active', { shouldDirty: true })
  // console.log(test)

  return (
    <>
      <Input
        disabled={!showButton}
        label="Name"
        type="text"
        toolTipInfo={(
          <span>
            Please be sure to confirm the proper spelling and formatting of the advertiser&apos;s name, and match it to what has been determined to be the full, official business name (what is on the enCOMPASS server).
          </span>
        )}
        {...methods.register('name', { required: true })}
        error={errors.name && <span className="form-error">{errors.name.message || 'This field is required.'}</span>}
      />
      <Radio
        label="Status"
        name="is_active"
        // {...methods.register('is_active', { required: true })}
        disabled={!showButton}
        options={[
          {
            id: 1,
            label: 'Active',
            value: true,
          },
          {
            id: 0,
            label: 'Inactive',
            value: false,
          },
        ]}
      />
    </>
  )
}

export default AdvertiserForm
