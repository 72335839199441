import { useState, useEffect, useContext } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ISemDetail } from '../../api/imi/interfaces'
import { fixDateObj, commaNumber } from '../../helpers/utils'
import BadgeDot from '../badge/BadgeDot'
import BadgeInfo from '../badge/BadgeInfo'
import Content from '../layouts/Content'
import LoadingSpinner from '../LoadingSpinner'
import DoubleLineGraph from '../charts/DoubleLineGraph'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: ISemDetail
}

interface SyncedChart{
  impressions: any
  clicks: any
  labels: any
}

const SemImpressionsClicks:React.FC<Props> = ({
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'
  const [syncedChart, setSyncedChart] = useState<SyncedChart | undefined>(undefined)
  const [reportData, setReportData] = useState<ISemDetail>([] as any)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setReportData(data)
    setLoading(false)
  }, [data])
  
  useEffect(() => {
    if (reportData) {
      let impressionsSeriesData
      let clicksSeriesData

      reportData.impressions_graph?.series?.forEach((s) => {
        if (s[0]?.name === 'Impressions') {
          impressionsSeriesData = s[0].data.sort((a, b) => (a.x > b.x ? 1 : -1))
        }
        if (s[0]?.name === 'Clicks') {
          clicksSeriesData = s[0].data.sort((a, b) => (a.x > b.x ? 1 : -1))
        }
      })

      setSyncedChart({ 
        labels: clicksSeriesData?.map((c) => fixDateObj(c.x)),
        impressions: impressionsSeriesData?.map((i) => i.y), 
        clicks: clicksSeriesData?.map((c) => c.y), 
      })
    }
  }, [reportData])

  const clickThruRate = () => {
    const metric = ((reportData?.clicks?.value / reportData?.impressions?.value) * 100)
    if (metric > 0) {
      return metric.toFixed(2)
    }
    return 0
  }

  return (
    <Content title="Impressions vs. Clicks">
      {!reportData?.impressions_graph?.series ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <>
          {(reportData?.impressions?.value || reportData?.clicks?.value) ? (
            <div className="pb-6">
              <BadgeDot variant="dot" dotColor="bg-primary" label="Impressions" />
              <BadgeDot variant="dot" dotColor="bg-secondary" label="Clicks" />
              <BadgeInfo
                label="Average Click-Through Rate (CTR)"
                // clicks/impressions x 100%
                calculation={`${clickThruRate()}%`}
                toolTipId="imprvsclicks"
                toolTipInfo="The click-through rate (CTR) is the percent of times a served ad was clicked."
                position="md:absolute right-0"
              />
            </div>
          ) : null}
          <div id="chart-container-imp-clicks">
            {(syncedChart && reportData.impressions?.value) || (syncedChart && reportData.clicks?.value) ? (
              <DoubleLineGraph 
                labels={syncedChart.labels}
                graph1={{
                  id: 'impressions',
                  name: 'Impressions',
                  values: syncedChart.impressions,
                  tooltip() {
                    const { series, point } = this as any
                    const selectIndex = syncedChart.labels.findIndex((label) => label === point.category)

                    let clicksValue = 0
                    if (selectIndex !== undefined) {
                      clicksValue = syncedChart.clicks[selectIndex] as number
                    }

                    return `<span style="color: #fff; font-family: 'Nunito Sans'; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point.category}</span>
                            <span style="background-color: ${colors.primary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 0px 5px;">${series.name}: <b>${commaNumber(point.y)}</b></span><br>
                            <span style="background-color: ${colors.secondary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 10px 5px;">Clicks: <b>${commaNumber(clicksValue)}</b><span><br>
                            <span style="background-color: #a7b1ae; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 10px 5px;">CTR: <b>${parseFloat(((clicksValue === 0 || point.y === 0 ? 0 : clicksValue / point.y) * 100).toFixed(2))}%</b><span>`
                  },
                }}
                graph2={{
                  id: 'clicks',
                  name: 'Clicks',
                  values: syncedChart.clicks,
                  tooltip() {
                    const { series, point } = this as any
                    const selectIndex = syncedChart.labels.findIndex((label) => label === point.category)

                    let impressionsValue = 0
                    if (selectIndex !== undefined) {
                      impressionsValue = syncedChart.impressions[selectIndex] as number
                    }

                    return `<span style="color: #fff; font-family: 'Nunito Sans'; font-size: 14px; font-weight: bold; padding: 10px 5px 5px 5px; display: block;">${point.category}</span>
                            <span style="background-color: ${colors.secondary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 0px 5px;">${series.name}: <b>${commaNumber(point.y)}</b></span><br>
                            <span style="background-color: ${colors.primary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 0px 5px;">Impressions: <b>${commaNumber(impressionsValue)}</b><span><br>
                            <span style="background-color: #a7b1ae; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 0px 5px 10px 5px;">CTR: <b>${parseFloat(((impressionsValue === 0 || point.y === 0 ? 0 : point.y / impressionsValue) * 100).toFixed(2))}%</b><span>`
                  },
                }}
              />
            ) : (
              <p>{noDataMessage}</p>
            )}
          </div>
        </>
      )}
    </Content>
  )
}

export default SemImpressionsClicks
