// styles
export const campaignSettingsStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// data
export const campaignSettingsMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'type.short_name',
    name: 'Type',
    mobile: false,
    sort: true,
  },
  // {
  //   fieldName: 'platform_id',
  //   name: 'Platform',
  //   mobile: false,
  //   sort: true,
  // },
  {
    fieldName: 'id',
    name: 'IMI ID',
    mobile: false,
    sort: false,
  },
]

export const unassignedCampaignsMenu = [
  {
    fieldName: 'name',
    name: 'Source Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'platform_id',
    name: 'Platform',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'api_id',
    name: 'Platform ID',
    mobile: true,
    sort: true,
  },
  {
    fieldName: 'api_type',
    name: 'Source Type',
    mobile: false,
    sort: true,
  },
]

export const campaignSettingsStatus = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]

export const campaignTypesMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'short_name',
    name: 'Shortened Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'campaign_specifics',
    name: 'Campaign Specifics',
    mobile: false,
    sort: false,
  },
]

export const callCyclesMenu = [
  {
    fieldName: 'start_date',
    name: 'Start Date',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'call_processing_is_active',
    name: 'Call processing',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'num_calls',
    name: '# of Calls',
    mobile: false,
    sort: true,
  },
]
