import { 
  useContext, useState, useEffect, useCallback, useMemo,
} from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { commaNumber, fixDateObj } from '../../helpers/utils'
import BadgeDot from '../badge/BadgeDot'
import BadgeInfo from '../badge/BadgeInfo'
import Content from '../layouts/Content'
import ToolTip from '../ToolTip'
import { IVideoDetails } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: IVideoDetails
}

const VideoPlacementPerformanceReport:React.FC<Props> = ({
  children,
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const [placementPerformance, setPlacementPerformance] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [chart, setChart] = useState<any>({})
  const [chartData, setChartData] = useState<any>({
    dates: [],
    sd: [],
  })
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'

  const colorOptions = useMemo(() => [
    [colors.primary, 'bg-primary'],
    [colors.tertiary, 'bg-tertiary'],
    ['#368f74', 'bg-green-dark'],
    ['#85619b', 'bg-purple'],
    ['#4a474d', 'bg-grey-darker'],
    ['#be2727', 'bg-red'],
    ['#e19922', 'bg-yellow'],
    ['#a7b1ae', 'bg-grey'],
  ], [colors])

  // eslint-enable
  const buildChart = useCallback((sd: any [], dates: any[]) => ({
    chart: {
      type: 'column',
      height: 300,
    },
    title: {
      text: '',
    },
    legend: {
      className: 'test',
      paddingBottom: 15,
      align: 'left',
      verticalAlign: 'top',
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter() {
        const { point } = this as any
        // Handle the callout
        const dateIndex = dates.indexOf(point?.category)
        return (
          sd.map((c) => (
            `<span style="background-color: ${colorOptions[c.colorIdx][0]}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">${c.name}: <b>${c.data[dateIndex]}</b></span><br>`
          )))
      },
    },
    xAxis: {
      useHtml: true,
      lineColor: '#dfe5e2',
      categories: dates,
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
    },
    yAxis: {
      min: 0,
      lineColor: '#dfe5e2',
      title: {
        text: 'Activities',
      },
      stackLabels: {
        enabled: false,
        labels: {
          style: {
            fontFamily: 'Heebo',
            fontSize: '12px',
            color: 'rgba(40, 42, 57, 0.6)',
          },
        },
      },
    },
    series: sd
      .filter((e) => e.name !== 'Activities')
      .map((graph, i) => ({
        ...graph,
        color: colorOptions[i][0],
      })),
    plotOptions: {
      series: {
        maxPointWidth: 10,
        // minPointLength: 5,
        states: {
          hover: {
            brightness: 0,
            opacity: 1,
          },
          inactive: {
            opacity: 0.7,
          },
        },
      },
      column: {
        allowOverlap: false,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
  }), [colorOptions])

  useEffect(() => {
    setPlacementPerformance(data)
    setLoading(false)
  }, [data])

  useEffect(() => {
    if (placementPerformance?.placement_performance?.series) {
      const seriesData = placementPerformance?.placement_performance?.series
      const chartOpts: any[] = []

      if (seriesData.length) {
        const dates = seriesData[seriesData.length - 1].data

        seriesData.forEach((s: { name: string; data: any[] }, i) => {
          if (s?.name !== 'dates') {
            // console.log(s?.name, s?.data, s?.data.reduce((subTotal, a) => subTotal + a, 0))
            chartOpts.push({
              name: s?.name,
              data: s?.data,
              colorIdx: s?.name === 'Activities' ? colorOptions.length - 1 : i || 0,
            })
          }
        })

        setChartData({ sd: chartOpts, dates })
        setChart(buildChart(chartOpts, dates.map((date: any) => fixDateObj(date))))
      }
    }
  }, [colorOptions, buildChart, placementPerformance?.placement_performance?.series])

  return (
    <Content
      title="placement performance"
      // icon={(
      //   <ToolTip
      //     tipId="placementperformance"
      //     size="sm"
      //     position="block relative pr-0"
      //   >
      //     Activities are made up of post-impressions and post-clicks. Post-impressions are website visits from a user who was served your ad and did not click, but then came back to your website at a later time. Post-clicks are conversion actions taken after a user clicks your ad.
      //   </ToolTip>
      // )}
    >
      {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <>
          {chartData.sd.length > 0 ? (
            <>
              <div className="pb-6">
                {chartData.sd.filter((c) => c.name !== 'Activities').map((c) => (
                  <BadgeDot variant="dot" dotColor={colorOptions[c.colorIdx][1]} label={c.name} />
                ))}
              </div>
              <div id="placement_performance">
                <HighchartsReact
                  id="placement_performance"
                  highcharts={Highcharts}
                  options={chart}
                />
              </div>
            </>
          ) : (
            <p>{noDataMessage}</p>
          )}
        </>
      )}
    </Content>
  )
}

export default VideoPlacementPerformanceReport
