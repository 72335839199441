/*
  PCampaignStatus.ts
  Describes the UserTypes for the IMI Dashboard
  Created by Spence Wetjen on 2022-11-16 for Encompass Agency
  Copyright = 2022 Encompass Agency.  All rights reserved.
 */

export const ENDED = 'ended'
export const RUNNING = 'running'
export const PAUSED = 'paused'
export const NOT_STARTED = 'not started'

export class PCampaignStatus {
  public status: string

  private ended = ENDED

  private running = RUNNING

  private paused = PAUSED

  private not_started = NOT_STARTED

  constructor(status: string) {
    this.status = status.toLowerCase()
  }

  // primitives
  isRunning() {
    return this.status === this.running
  }

  isEnded() {
    return this.status === this.ended
  }

  isPaused() {
    return this.status === this.paused
  }

  isNotStarted() {
    return this.status === this.not_started
  }

  getAll() {
    return [this.ended, this.running, this.paused, this.not_started]
  }

  // compositions
  canTogglePaused() {
    const x = [this.running, this.paused].includes(this.status)
    return x
  }
}
