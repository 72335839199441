import {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'
import { ChevronRightIcon, XIcon } from '@heroicons/react/solid'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Popover, Transition } from '@headlessui/react'
import { handleFilters } from '../../helpers/utils'
import { IMIApi } from '../../api/imi/api'
import Icon from '../Icon'
import { ReactComponent as AdvertiserIcon } from '../../assets/icons/advertisers-icon.svg'
import Search from '../form/Search'
import { IAdvertiserInDb } from '../../api/imi/interfaces'
import LinkWrapper from '../Links'
import { AppContext } from '../../contexts/AppContext'

// User types:
// Admin, Team Viewer, Call Manager, Call Analyst, Client, Partner
const AdvertiserNavigation: React.FC = () => {
  const [allAdvertisers, setAllAdvertisers] = useState<any[]>([] as any)
  const [loading, setLoading] = useState(true)
  const { user, setError } = useContext(AppContext)
  const location = useLocation()
  const [filteredAdvertisers, setFilteredAdvertisers] = useState<IAdvertiserInDb[]>(allAdvertisers)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
  })

  const handleLocalStorage = ({
    advertiser_id,
  }) => {
    const advertiserParameters = {
      advertiser_id,
    }
    localStorage.setItem('advertiser_id', JSON.stringify(advertiserParameters.advertiser_id))
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (user.user_type_id <= 3) {
      const fetchAdvertisers = async () => {
        const response = await IMIApi.GetAllAdvertisers(abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        // Total rows available as response.data.rows_total
        const activeAdvertisers = response?.data?.data?.filter((advertiser) => advertiser.is_active)
        setAllAdvertisers(activeAdvertisers)
        return setLoading(false)
      }
      fetchAdvertisers()
    }
    // FIXME: need user id returned in read user me
    if (user.user_type_id > 3) {
      setAllAdvertisers(user.advertisers.filter((advertiser) => advertiser.is_active))
      // const fetchAdvertisers = async () => {
      //   const response = await IMIApi.GetUserAdvertisers(3)
      //   if (response.status !== 200) {
      //     const parse = JSON?.parse(response)
      //     setError({ errorMsg: parse?.detail })
      //     return setLoading(false)
      //   }
      //   if (!response.status) {
      //     setError({ errorMsg: 'Internal server error.' })
      //     return setLoading(false)
      //   }
      //   // Total rows available as response.data.rows_total
      //   const activeAdvertisers = response?.data?.data?.filter((advertiser) => advertiser.is_active)
      //   setAllAdvertisers(activeAdvertisers)
      //   return setLoading(false)
      // }
      // fetchAdvertisers()
    }
    return () => abortController.abort()
  }, [setError, user.user_type_id, user.advertisers])

  const filters = useCallback(() => {
    const filterSearchValue = (advertiserData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const advertiserFilter = advertiserData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const advertiserFil = splitItems.some((si) => advertiserData.name.toLowerCase().includes(si))
        return advertiserFil
      }
      return advertiserFilter
    }

    const filterData = allAdvertisers?.filter((advertiser) => filterSearchValue(advertiser))
    setFilteredAdvertisers(filterData)
  }, [selectedFilters, allAdvertisers])

  useEffect(() => {
    filters()
  }, [allAdvertisers, filters])

  return (
    <Popover as="div" className="relative mr-5">
      {({ open }) => (
        <>
          <Popover.Button onKeyDown={(e) => { if (e.code === 'Space') { e.stopPropagation() } }} data-testid="main-nav-adv-btn" className="text-primary bg-white rounded-full flex text-sm hover:text-default active:text-secondary focus:outline-none focus:text-secondary">
            <span className="sr-only">Open advertiser menu</span>
            {(location.pathname !== '/') && (location.pathname !== '/campaign-central') ? <Icon size="xl" colors="text-primary" className="cursor-pointer group-hover:text-secondary"><AdvertiserIcon className="text-primary hover:text-secondary" /></Icon> : null}
          </Popover.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel static className="z-30 w-full sm:min-w-300 origin-top-right fixed sm:absolute right-0 mt-4 sm:w-auto border border-grey-light shadow-lg py-1 bg-white focus:outline-none h-96 overflow-hidden">
              <div>
                <div className="flex">
                  <LinkWrapper
                    variant="menu"
                    href="/"
                    className="px-6 pt-4 group"
                  >
                    View All
                    <Icon size="xs" className="text-menu global-animation group">
                      <ChevronRightIcon />
                    </Icon>
                  </LinkWrapper>
                  <Popover.Button>
                    <Icon size="sm" colors="grey" className="absolute right-0 top-0 px-3 py-3 group">
                      <XIcon />
                    </Icon>
                  </Popover.Button>
                </div>
                <span
                  className="border-t border-t-1 border-grey-light flex items-center font-base pl-6 pr-6 pt-6 text-sm text-default"
                >
                  <Search
                    value={selectedFilters.search}
                    onChange={(
                      (e) => setSelectedFilters(
                        handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
                      )
                    )}
                    label="search advertisers"
                    hideLabel
                    placeholder={selectedFilters.search.length ? undefined : 'search advertisers...'}
                    className="max-w-none w-full"
                  />
                </span>
              </div>
              <ul className="px-6 pt-2 mr-3 overflow-y-scroll h-52 relative">
                {filteredAdvertisers?.map((advertiser) => (
                  <li key={advertiser.id} className="-mb-0.5">
                    <Popover.Button className="text-left">
                      <LinkWrapper
                        variant="menu"
                        onClick={(
                          () => handleLocalStorage(
                            {
                              advertiser_id: advertiser.id,
                            },
                          )
                        )}
                        href={{
                          pathname: '/campaign-overview',
                        }}
                      >
                        {advertiser.name}
                      </LinkWrapper>
                    </Popover.Button>
                  </li>
                ))}
              </ul>
              <div className="text-center absolute w-full bottom-0 bg-white">
                <Icon
                  className=""
                  size="sm"
                >
                  <ChevronDownIcon />
                </Icon>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default AdvertiserNavigation
