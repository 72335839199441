import { useEffect, useState, useContext } from 'react'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import { IMIApi } from '../../../api/imi/api'
import CallInstructions from '../../../components/adminTools/calls/instructions/CallInstructions'
import Padding from '../../../components/layouts/Padding'
import { AppContext } from '../../../contexts/AppContext'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICallInstructionsMultiResponse } from '../../../api/imi/interfaces'

const CallInstructionsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [instructions, setInstructions] = useState<ICallInstructionsMultiResponse>([] as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCallInstructions = async () => {
      const response = await IMIApi.GetCallInstructions(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      // Concatted name field for sorting (in child component).
      for (let i = 0; i < response.data.length; i += 1) {
        response.data[i].data.campaign.names = `${response.data[i].data.campaign.name} ${response.data.data[i].campaign.secondary_name}`
      }
      const combineNames = response?.data.data.map((item) => (
        {
          ...item,
          names: `${item?.campaign.name} ${item?.campaign?.secondary_name && item?.campaign?.secondary_name} ${item?.campaign?.specific && item?.campaign?.specific}`,
        }
      ))
      setInstructions({
        ...response.data,
        data: combineNames.sort((a, b) => (a.names > b.names ? 1 : -1)),
      })
      return setLoading(false)
    }
    fetchAllCallInstructions()
    return () => abortController.abort()
  }, [refresh, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <CallInstructions
            instructions={instructions}
            isLoading={loading}
            setRefresh={setRefresh}
          />
        )}
      />
    </Padding>
  )
}

export default CallInstructionsPage
