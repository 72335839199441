import { 
  useContext, 
  useEffect, 
  useState, 
  useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IDisplayActivities } from '../../api/imi/interfaces'
import { IMIApi } from '../../api/imi/api'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'
import HorizontalGrid from '../layouts/HorizontalGrid'
import MetricsGrid from '../layouts/MetricsGrid'
import { ReactComponent as ImpressionsIcon } from '../../assets/icons/total-post-impressions-icon.svg'
import { ReactComponent as ClicksIcon } from '../../assets/icons/total-post-clicks-icon.svg'
import { ReactComponent as ActivitiesIcon } from '../../assets/icons/activities-icon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'
import { activityBreakdownMenu, styles } from './Data'
import { commaNumber, fixDateObjApi, getFileDescriptor } from '../../helpers/utils'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'
import SidebarRight from '../layouts/SidebarRight'
import Pagination from '../Pagination'
import { ReportContext } from '../../contexts/ReportContext'

interface Props {
  campaignId: number,
  startDate: string,
  endDate: string,
  data: IDisplayActivities
}

const DisplayActivityBreakdown:React.FC<Props> = ({
  campaignId,
  startDate,
  endDate,
  data,
}) => {
  const [reportData, setReportData] = useState<IDisplayActivities>({ rows: [], callouts: [] })
  const [creatingCSV, setCreatingCSV] = useState<boolean>(false)
  const { user, setError } = useContext(AppContext)
  const { reportParameters, parameters } = useContext(ReportContext)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(false)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')
  const backToTop = document.getElementById('daily-activity')
  const [pageLimit, setPageLimit] = useState(20)

  const handleDownload = useCallback(() => {
    const fetchActivityBreakdownDownload = async () => {
      setCreatingCSV(true)
      const response = await IMIApi.GetActivityBreakdownDownload({
        id: campaignId,
        start_date: startDate,
        end_date: endDate,
      })
      if (response.status !== 200) {
        setCreatingCSV(false)
        const parse = JSON?.parse(response)
        return setError({ errorMsg: parse?.detail })
      }
      if (!response.status) {
        setCreatingCSV(false)
        return setError({ errorMsg: 'Internal server error' })
      }

      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      const fileDesc = getFileDescriptor(response.headers)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(
        new Blob([response.data], { type: fileDesc.contentType }),
      )

      // Use download attribute to set desired file name
      a.setAttribute('download', fileDesc.filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
      return setCreatingCSV(false)
    }
    fetchActivityBreakdownDownload()
  }, [campaignId, startDate, endDate, setCreatingCSV, setError])

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = sortDirection ? reportData.rows.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : reportData.rows.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }

  useEffect(() => {
    if (data?.rows) {
      setReportData({
        ...data,
        rows: data.rows.sort((a, b) => (a?.activities > b?.activities ? -1 : 1)),
      })
    }
  }, [data])

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (reportData.rows.length - pageLimit))
  }

  const handleCollapse = () => {
    if (backToTop) {
      backToTop.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
    return setPageLimit(20)
  }

  return (
    <SidebarRight
      mainContent={(
        <Content
          title="activity breakdown"
          padding={false}
          minHeight="lg:min-h-metric-sidebar-3"
          icon={(
            <Icon>
              <Icon
                size="md"
                colors="text-primary"
                className="cursor-pointer group-hover:text-secondary mt-1.5"
              >
                <DownloadIcon
                  className={`text-primary hover:text-secondary ${creatingCSV ? 'hidden' : 'block'}`}
                  onClick={() => handleDownload()}
                />
                {creatingCSV && (
                  <span className={creatingCSV ? 'block w-full' : 'hidden'}>
                    <LoadingSpinner variant="primary" size="sm" noPadding />
                  </span>
                )}
              </Icon>
            </Icon>
          )}
        >
          <>
            <HorizontalGrid
              smGrid="grid-cols-activities-sm"
              mdGrid="lg:grid-cols-activities"
              header
            >
              {activityBreakdownMenu.map((item) => (
                <div key={item.name} className={`break-all group flex center-items ${item.name.toLowerCase() === 'budget' && user.user_type_id !== 1 ? 'hidden' : ''} ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}>
                  {item.sort ? (
                    <span
                      aria-hidden="true"
                      onClick={() => columnSortingInfo(item.fieldName)}
                      key={item.name}
                    >
                      <span className="inline lg:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                      <span className="hidden lg:inline">{item.name}</span>
                      <Icon
                        size="xxs"
                        className="text-default group sm:pr-0 ml-1 -mt-1"
                      >
                        <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                        <ChevronDownIcon className="text-default group-hover:text-secondary" />
                      </Icon>
                    </span>
                  ) : <span key={item.name}>{item.name}</span>}
                </div>
              ))}
            </HorizontalGrid>
            <div id="daily-activity">
              {reportData?.rows?.length > 0 ? (
                reportData?.rows?.filter((metric, amount) => amount < pageLimit).map((activities) => (
                  <HorizontalGrid
                    key={activities.name}
                    smGrid="grid-cols-activities-sm"
                    mdGrid="lg:grid-cols-activities"
                    backgroundColor
                  >
                    <p className="small pr-1.5">
                      {activities?.name}
                    </p>
                    <p className="small pr-1.5">
                      {commaNumber(activities?.post_impressions)}
                    </p>
                    <p className="small pr-1.5">
                      {commaNumber(activities?.post_clicks)}
                    </p>
                    <p className="small pr-1.5">
                      {commaNumber(activities?.activities)}
                    </p>
                  </HorizontalGrid>
                ))
              ) : (
                <p className="px-4 md:px-6">{noDataMessage}</p>
              )}
            </div>
          </>
          <div className={`${!reportData || reportData.rows.length > 20 ? 'block' : 'hidden'} px-4 pt-3 md:px-6`}>
            <Pagination
              onClick={handleLoadMore}
              pageLimit={pageLimit}
              adminLimit={20}
              data={reportData.rows}
              offset={10}
              onClickCollapse={handleCollapse}
            />
          </div>
        </Content>
      )}
      sidebarContent={(
        <div className="grid grid-cols-auto grid-rows-auto gap-5 mb-6">
          {reportData?.callouts.length > 0 ? (
            reportData?.callouts.map((total, index) => (
              <MetricsGrid
                key={total.name}
                name={total.name}
                imageUrl={[
                  <ImpressionsIcon />,
                  <ClicksIcon />,
                  <ActivitiesIcon />,
                ][index]}
                value={commaNumber(total.value)}
              />
            ))
          ) : (
            <>
              <MetricsGrid
                name="total post-impressions"
                imageUrl={
                  <ImpressionsIcon />
                }
                value={0}
              />
              <MetricsGrid
                name="total post-clicks"
                imageUrl={
                  <ClicksIcon />
                }
                value={0}
              />
              <MetricsGrid
                name="total activities"
                imageUrl={
                  <ActivitiesIcon />
                }
                value={0}
              />
            </>
          )}
        </div>
      )}
    />
  )
}

export default DisplayActivityBreakdown
