import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from 'react-router-dom'
import { useEffect } from 'react'
import { AppContext, useAppContextFactory } from './contexts/AppContext'
import { UserContext, useUserContextFactory } from './contexts/UserContext'
import { ThemeContext, useThemeContextFactory } from './contexts/ThemeContext'
import { PdfContext, usePdfContextFactory } from './contexts/PdfContext'
import Layout from './components/layouts/Layout'
import AuthPage from './pages/AuthPage'
import CampaignCentralPage from './pages/CampaignCentralPage'
import AdvertiserDashboardPage from './pages/AdvertiserDashboardPage'
import SemReportPage from './pages/SemReportPage'
import AdminToolsPage from './pages/AdminToolsPage'
import { getDefaultHomePage } from './helpers/utils'
import ProtectedRoute from './hoc/ProtectedRoute'
import UserSettingsPage from './pages/accounts/user_settings/UserSettingsPage'
import CreateUserPage from './pages/accounts/user_settings/CreateUserPage'
import EditUserPage from './pages/accounts/user_settings/EditUserPage'
import CampaignOverviewPage from './pages/CampaignOverviewPage'
import EmailTemplatePage from './pages/accounts/email_template/EmailTemplatesPage'
import DashboardBrandingPage from './pages/accounts/dashboard_branding/DashboardBrandingPage'
import EditDashboardBrandingPage from './pages/accounts/dashboard_branding/EditDashboardBrandingPage'
import EditEmailTemplatePage from './pages/accounts/email_template/EditEmailTemplatePage'
import CreateEmailTempaltePage from './pages/accounts/email_template/CreateEmailTemplatePage'
import CreateDashboardBrandingPage from './pages/accounts/dashboard_branding/CreateDashboardBrandingPage'
import AdvertiserSettingsPage from './pages/advertisers/AdvertiserSettingsPage'
import CreateAdvertiserPage from './pages/advertisers/CreateAdvertiserPage'
import EditAdvertiserPage from './pages/advertisers/EditAdvertiserPage'
import CampaignSettingsPage from './pages/campaigns/campaign_settings/CampaignSettingsPage'
import CampaignTypesPage from './pages/campaigns/campaign_types/CampaignTypesPage'
import CreateCampaignPage from './pages/campaigns/campaign_settings/CreateCampaignPage'
import CreateCampaignTypesPage from './pages/campaigns/campaign_types/CreateCampaignTypesPage'
import CampaignUpliftPage from './pages/campaigns/campaign_uplift/CampaignUpliftPage'
import CreateUpliftPage from './pages/campaigns/campaign_uplift/CreateUpliftPage'
import EditCampaignTypesPage from './pages/campaigns/campaign_types/EditCampaignTypesPage'
import EditUpliftPage from './pages/campaigns/campaign_uplift/EditUpliftPage'
import AssignCampaignPage from './pages/campaigns/campaign_settings/AssignCampaignPage'
import CallCategorySettingsPage from './pages/calls/category/CallCategorySettingsPage'
import CreateCallIndustryPage from './pages/calls/category/CreateCallIndustryPage'
import CallProcessingPage from './pages/calls/process/CallProcessingPage'
import CallInstructionsPage from './pages/calls/instructions/CallProcessingInstructionsPage'
import FlaggedCallsPage from './pages/calls/flagged/FlaggedCallsPage'
import CallSettingsPage from './pages/campaigns/campaign_call_settings/CallSettingsPage'
import EditCallSettingsPage from './pages/campaigns/campaign_call_settings/EditCallSettingsPage'
import ErrorPage from './pages/ErrorPage'
import EditCallIndustryPage from './pages/calls/category/EditCallIndustryPage'
import ProcessCallsPage from './pages/calls/process/ProcessCallsPage'
import CreateCallInstructionsPage from './pages/calls/instructions/CreateCallInstructionsPage'
import EditCallInstructionsPage from './pages/calls/instructions/EditCallInstructionsPage'
import EditCampaignPage from './pages/campaigns/campaign_settings/EditCampaignPage'
import { CampaignContext, useCampaignContextFactory } from './contexts/CampaignContext'
import ViewProcessCallsPage from './pages/calls/process/ViewProcessedCallsPage'
import { ReportContext, useReportContextFactory } from './contexts/ReportContext'
import SemCallReportPage from './pages/SemCallReportPage'
import FlaggedCallsReportPage from './pages/calls/flagged/FlaggedCallsReportPage'
import ErrorPage404 from './pages/ErrorPage404'
import RecoverPasswordPage from './pages/RecoverPasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import ProtectedContextRoute from './hoc/ProtectedContextRoute'
import {
  Admin, TeamViewer, CallAnalyst, CallManager, Partner, Client, 
} from './constants/UserTypes'

// import IdleMonitor from './components/IdleMonitor'
import SessionMonitor from './components/SessionMonitor'
import { ENV, PRODUCTION } from './env'
import RequestUsernamePage from './pages/RequestUsernamePage'
import ScheduledMaintenancePage from './pages/ScheduledMaintenancePage'
import RoiPage from './pages/campaigns/campaign_roi/RoiPage'
import EditRoiPage from './pages/campaigns/campaign_roi/EditRoiPage'
import DisplayReportPage from './pages/DisplayReportPage'
import VideoReportPage from './pages/VideoReportPage'
import ApiSettingsPage from './pages/api/ApiSettingsPage'
import EditApiSettingsPage from './pages/api/EditApiSettingsPage'
import CreateApiSettingsPage from './pages/api/CreateApiSettingsPage'

function App() {
  // Who YOU currently are when logging in
  const appState = useAppContextFactory()
  const {
    user,
    isSignedIn,
    refreshUser,
    attemptedUserLoad,
    campaignData,
  } = appState

  // user state for when creating a NEW or EXISTING
  // account to access dashboard.
  const userState = useUserContextFactory()
  const {
    userInDb, setUserInDb,
  } = userState

  const campaignState = useCampaignContextFactory()
  const {
    campaignInDb, setCampaignInDb,
  } = campaignState

  const reportState = useReportContextFactory()

  const themeState = useThemeContextFactory()

  const pdfState = usePdfContextFactory()

  useEffect(() => {
    refreshUser()
  }, [user, refreshUser])

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={themeState}>
        <AppContext.Provider value={appState}>
          <ReportContext.Provider value={reportState}>
            <PdfContext.Provider value={pdfState}>
              <Layout>
                { isSignedIn() && (
                  <SessionMonitor />
                ) }
                {attemptedUserLoad && !isSignedIn() && (
                <Switch>
                  <Route path="/" exact component={AuthPage} />
                  <Route path="/login" exact component={AuthPage} />
                  <Route path="/forgot-password" exact component={RecoverPasswordPage} />
                  <Route path="/forgot-username" exact component={RequestUsernamePage} />
                  <Route path="/password_reset" exact component={ResetPasswordPage} />
                  <Route path="/scheduled-maintenance" exact component={ScheduledMaintenancePage} />
                  <Route path="*">
                    <Redirect to="/login" />
                    {/* <Redirect to="/scheduled-maintenance" /> */}
                  </Route>
                </Switch>
                )}

                {isSignedIn() && (
                <Switch>
                  <Redirect to={getDefaultHomePage(user)} path="/" exact />
                  {/* <Route path="/error" exact component={ErrorPage} /> */}

                  <ProtectedRoute path="/advertiser-dashboard" exact component={AdvertiserDashboardPage} userTypeIds={user?.advertisers?.length < 2 && user?.user_type_id === 6 ? [1, 2, 3, 5] : [1, 2, 3, 5, 6]} />
                  <ProtectedRoute path="/admin-tools" exact component={AdminToolsPage} userTypeIds={[1, 3]} />
                  <ProtectedRoute path="/admin-tools/email-templates" exact component={EmailTemplatePage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/email-templates/create-email-template" exact component={CreateEmailTempaltePage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/email-templates/edit-email-template" exact component={EditEmailTemplatePage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/dashboard-branding" exact component={DashboardBrandingPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/dashboard-branding/edit-dashboard-branding" exact component={EditDashboardBrandingPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/dashboard-branding/create-dashboard-branding" exact component={CreateDashboardBrandingPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/advertiser-settings" exact component={AdvertiserSettingsPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/advertiser-settings/create-advertiser" exact component={CreateAdvertiserPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/advertiser-settings/edit-advertiser" exact component={EditAdvertiserPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/campaign-types/" exact component={CampaignTypesPage} />
                  <ProtectedRoute path="/admin-tools/campaign-types/create-campaign-type" exact component={CreateCampaignTypesPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/campaign-types/edit-campaign-type" exact component={EditCampaignTypesPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/uplift" exact component={CampaignUpliftPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/uplift/create-uplift" exact component={CreateUpliftPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/uplift/edit-uplift" exact component={EditUpliftPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/call-category-settings" exact component={CallCategorySettingsPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/call-category-settings/create-call-industry" exact component={CreateCallIndustryPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/call-category-settings/add-call-category" exact component={EditCallIndustryPage} userTypeIds={[1]} />
                  <ProtectedRoute path="/admin-tools/call-processing" exact component={CallProcessingPage} userTypeIds={[1, 3, 4]} />
                  <ProtectedRoute path="/admin-tools/call-processing/process-calls" exact component={ProcessCallsPage} userTypeIds={[1, 3, 4]} />
                  <ProtectedRoute path="/admin-tools/call-processing/view-processed-calls" exact component={ViewProcessCallsPage} userTypeIds={[1, 3, 4]} />
                  <ProtectedRoute path="/admin-tools/call-processing-instructions" exact component={CallInstructionsPage} userTypeIds={[1, 3]} />
                  <ProtectedRoute path="/admin-tools/call-processing-instructions/create-instructions" exact component={CreateCallInstructionsPage} userTypeIds={[1, 3]} />
                  <ProtectedRoute path="/admin-tools/call-processing-instructions/edit-instructions" exact component={EditCallInstructionsPage} userTypeIds={[1, 3]} />
                  <ProtectedContextRoute contextComponent={UserContext} contextState={userState} path="/admin-tools/user-settings" exact component={UserSettingsPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={UserContext} contextState={userState} path="/admin-tools/user-settings/create-user" exact component={CreateUserPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={UserContext} contextState={userState} path="/admin-tools/user-settings/edit-user" exact component={EditUserPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/campaign-settings" exact component={CampaignSettingsPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/campaign-settings/create-campaign" exact component={CreateCampaignPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/campaign-settings/assign-campaign" exact component={AssignCampaignPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/campaign-settings/edit-campaign" exact component={EditCampaignPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/call-settings" exact component={CallSettingsPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/call-settings/edit-call-settings" exact component={EditCallSettingsPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/return-on-investment" exact component={RoiPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/return-on-investment/edit-return-on-investment" exact component={EditRoiPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/api-settings" exact component={ApiSettingsPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/api-settings/create-api-settings" exact component={CreateApiSettingsPage} userTypeIds={[1]} />
                  <ProtectedContextRoute contextComponent={CampaignContext} contextState={campaignState} path="/admin-tools/api-settings/edit-api-settings" exact component={EditApiSettingsPage} userTypeIds={[1]} />
                  
                  <ProtectedRoute path="/campaign-central" exact component={CampaignCentralPage} userTypeIds={[1, 2, 3, 5]} />
                  <ProtectedRoute path="/campaign-overview" exact component={CampaignOverviewPage} userTypeIds={[1, 2, 3, 5, 6]} />
                  <ProtectedRoute path="/search-engine-marketing" exact component={SemReportPage} userTypeIds={[1, 2, 3, 5, 6]} />
                  <ProtectedRoute path="/search-engine-marketing/call-report" exact component={SemCallReportPage} userTypeIds={[1, 2, 3, 5, 6]} />
                  <ProtectedRoute path="/display-advertising" exact component={DisplayReportPage} userTypeIds={[1, 2, 3, 5, 6]} />
                  <ProtectedRoute path="/video-advertising" exact component={VideoReportPage} userTypeIds={[1, 2, 3, 5, 6]} />
                  <ProtectedRoute path="/admin-tools/flagged-calls" exact component={FlaggedCallsPage} userTypeIds={[Admin, CallManager, CallAnalyst, TeamViewer]} />
                  <ProtectedRoute path="/admin-tools/flagged-calls/flagged-call-report" exact component={FlaggedCallsReportPage} userTypeIds={[1, 2, 3]} />
                  {/* <Route>
                  <Switch>
                    {!isLoggedIn() && (
                      <Redirect to="/" exact />
                    )}
                  </Switch>
                </Route> */}
                  <Route path="*">
                    <Redirect to={getDefaultHomePage(user)} path="/" exact />
                  </Route>
                </Switch>
                )}

              </Layout>
            </PdfContext.Provider>
          </ReportContext.Provider>
        </AppContext.Provider>
      </ThemeContext.Provider>
    </BrowserRouter>
  )
}

export default App
