// Autofills to max-width of site. Use this as a wrapper
// around any of the other grid components (ex. MetricsGrid)
// Does not need to be used for HorizontalGrid because that's just
// too specialized and based on specific column numbers.

interface Props {
  children: React.ReactNode
}

const Grid:React.FC<Props> = ({
  children,
}) => (
  <div className="grid grid-cols-auto grid-rows-auto gap-5 mb-6">
    {children}
  </div>
)

export default Grid
