import { useEffect, useState, useContext } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../contexts/AppContext'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import CreateEmailTemplate from '../../../components/adminTools/accounts/email_template/CreateEmailTemplate'
import { IEmailTemplateInDbDetail } from '../../../api/imi/interfaces'
import EditEmailTemplate from '../../../components/adminTools/accounts/email_template/EditEmailTemplate'
import LinkWrapper from '../../../components/Links'
import Icon from '../../../components/Icon'
import Content from '../../../components/layouts/Content'

const CreateTemplatePage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const { setError } = useContext(AppContext)
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplateInDbDetail>({} as any)
  const [templateId, setTemplateId] = useState(0)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (templateId > 0) {
      const fetchEmailTemplate = async () => {
        const response = await IMIApi.GetEmailTemplateByID(templateId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setEmailTemplate(response.data)
        return setLoading(false)
      }
      fetchEmailTemplate()
    }
    return () => abortController.abort()
  }, [templateId, refresh, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          templateId > 0 ? (
            <>
              <EditEmailTemplate
                isLoading={loading}
                template={emailTemplate}
                setRefresh={setRefresh}
                edit={false}
              />
              <Content
                title="Dashboard branding"
              >
                <p>For a consistent experience, each email template should be connected to version of our dashboard that is branded for our partner. To do this, first save the details of this new email template, then create a new dashboard brand. There, you’ll be able to select this email template’s name, tying the two together.</p>
                <LinkWrapper
                  href="/admin-tools/dashboard-branding/create-dashboard-branding"
                  variant="defaultAdmin"
                  className="mb-6 inline-block"
                  target="_blank"
                >
                  Create New Dashboard Branding
                  <Icon size="xxs">
                    <ChevronRightIcon />
                  </Icon>
                </LinkWrapper>
              </Content>
            </>
          ) : (
            <CreateEmailTemplate
              setTemplateId={setTemplateId}
            />
          )
        )}
      />
    </Padding>
  )
}

export default CreateTemplatePage
