import { 
  PDF, 
  FontStyle, 
  FontAlignment, 
  Font, 
} from '../lib'
import { CallHighlight } from '../data'
import * as sizes from '../sizes'

const COL_1_WIDTH = 2 / 16
const COL_2_WIDTH = 4 / 16
const COL_3_WIDTH = 2 / 16
const COL_4_WIDTH = 3 / 16
const COL_5_WIDTH = 5 / 16

const getTable = (pdf: PDF, data: CallHighlight[]) => {
  const pageWidth = (pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 6)
  return {
    title: pdf.text.drawText('call highlights', pdf.colors.black, { style: FontStyle.Bold }),
    header: [
      pdf.text.drawText('Date/Time', pdf.colors.black, { width: pageWidth * COL_1_WIDTH }),
      pdf.text.drawText('Customer', pdf.colors.black, { width: pageWidth * COL_2_WIDTH }),
      pdf.text.drawText('Duration', pdf.colors.black, { width: pageWidth * COL_3_WIDTH }),
      pdf.text.drawText('Category', pdf.colors.black, { width: pageWidth * COL_4_WIDTH }),
      pdf.text.drawText('Notes', pdf.colors.black, { width: pageWidth * COL_5_WIDTH }),
    ],
    rows: data.map((call) => {
      const text = pdf.text.drawText(
        call.category, 
        pdf.colors.white, 
        { 
          width: pageWidth * COL_4_WIDTH - sizes.BADGE_MARGIN * 2,
          alignment: FontAlignment.Center,
          font: Font.Heebo,
        },
      )
        
      return [
        pdf.text.drawMultiLineText(call.dateTime, pdf.colors.darkestGrey, { width: pageWidth * COL_1_WIDTH, font: Font.Heebo }),
        pdf.text.drawMultiLineText(call.customer, pdf.colors.darkestGrey, { width: pageWidth * COL_2_WIDTH, font: Font.Heebo }),
        pdf.text.drawText(call.duration, pdf.colors.darkestGrey, { width: pageWidth * COL_3_WIDTH, font: Font.Heebo }),
        {
          width: pageWidth * COL_4_WIDTH,
          height: call.category ? text.height + sizes.BADGE_MARGIN * 2 : 0,
          render: () => {
            if (call.category) {
              pdf.drawing.drawBadge(pageWidth * COL_4_WIDTH, text.height + sizes.BADGE_MARGIN * 2, pdf.colors.hexToRgb(call.color))
              pdf.pages.moveDown(sizes.BADGE_MARGIN)
              pdf.pages.moveRight(sizes.BADGE_MARGIN)
              text.render()
              pdf.pages.moveUp(sizes.BADGE_MARGIN)
              pdf.pages.moveLeft(sizes.BADGE_MARGIN)
            }
          },
        },
        pdf.text.drawText(call.notes, pdf.colors.darkestGrey, { width: pageWidth * COL_5_WIDTH, font: Font.Heebo }),
      ]
    }),
  }
}

const drawRow = (pdf: PDF, height: number, row: { width: number, height: number, render: () => void }[]) => {
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  row.forEach((col) => {
    col.render()
    pdf.pages.moveRight(col.width + sizes.CONTAINER_MARGIN)
  })
  pdf.pages.moveDown(height - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
}

export const drawCallHighlights = (pdf: PDF, data: CallHighlight[]) => {
  const table = getTable(pdf, data)
  const titleHeight = table.title.height + sizes.CONTAINER_MARGIN * 2
  const headerHeight = table.header[0].height + sizes.CONTAINER_MARGIN * 2

  if (titleHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerHeader(pdf.pages.pageWidth, titleHeight)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  table.title.render()
  pdf.pages.moveDown(titleHeight - sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()

  if (headerHeight > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  pdf.drawing.drawContainerRow(pdf.pages.pageWidth, headerHeight, pdf.colors.white)
  drawRow(pdf, headerHeight, table.header)

  table.rows.forEach((row, i) => {
    const height = row.reduce((h, col) => (
      col.height > h ? col.height : h
    ), 0) + sizes.CONTAINER_MARGIN * 2

    if (height > pdf.pages.remainingHeight) {
      pdf.pages.nextPage()
    }

    if (i % 2 === 0) {
      if (i === data.length - 1) {
        pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, height, pdf.colors.grey)
      } else {
        pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.grey)
      }
    } else if (i === data.length - 1) {
      pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, height, pdf.colors.white)
    } else {
      pdf.drawing.drawContainerRow(pdf.pages.pageWidth, height, pdf.colors.white)
    }

    drawRow(pdf, height, row)
  })

  pdf.pages.moveDown(sizes.SECTION_MARGIN)
}
