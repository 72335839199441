import axios from 'axios'
import { ENV } from '../../env'
import * as Interface from './interfaces'
import { ICampaignTypes } from '../../constants/CampaignTypes'
import { ICallSettingsChange } from './interfaces'

export const secureAxios = axios.create() // -> AppContext handles 403 => signOut() effect.
const publicAxios = axios.create()
secureAxios.interceptors.request.use(async (config) => {
  const c = config
  c.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
  return c
})

// s01.00 AUTHENTICATION
// ----------------------------------------------------------
//      s01.01 User Access Token
//      s01.02 Access Token Check
//      s01.03 Read Current User
//      s01.04 Reset Password
//
//
// s02.00 USERS
// ----------------------------------------------------------
//      s02.01 MultiResponse User Routes
//      s02.02 CRUD User
//      s02.03 User's Relationship to Advertiser/Campaigns
//
//
// s03.00 EMAIL TEMPLATES
// ----------------------------------------------------------
//      s03.01 MultiResponse Email Templates
//      s03.02 CRUD Email Template
//
//
// s04.00 BRANDING (FORMERLY KNOWN AS WHITE LABEL)
// ----------------------------------------------------------
//      s04.01 MultiResponse Branding
//      s04.02 CRUD Branding
//      s04.03 RU Branding for User Id
//
//
// s05.00 ADVERTISERS
// ----------------------------------------------------------
//      s05.01 MultiResponse Advertisers
//      s05.02 CRUD Advertiser
//
//
// s06.00 CAMPAIGNS
// ----------------------------------------------------------
//      s06.01 MultiResponse Campaigns
//      s06.02 CRU Campaigns
//      s06.03 CRU PCampaigns (unassigned/direct from platform data)
//      s06.04 CRU Campaign Types
//      s06.05 RU Campaign Uplift
//      s06.05 Misc
//
//
// s07.00 ROI - WIP Will move into campaigns when done?
// ----------------------------------------------------------
//      s07.01 MultiResponse ROIs
//      s07.02 CRU ROI
//
//
// s08.00 CALLS
// ----------------------------------------------------------
//      s08.01 Call Industries
//      s08.02 Call Categories
//      s08.03 Call Processing
//      s08.04 Call Instructions
//
//
// s09.00 REPORTS
// ----------------------------------------------------------
//      s09.01 Campaign Central
//      s09.02 Campaign Overview
//      s09.03 SEM
//      s09.04 Daily Activity
//      s09.05 CSV Report Downloads
//      s09.06 Display
//
//
// s010.00 MISC
// ----------------------------------------------------------
//      s10.01 Platforms
//
//
export const IMIApi = {
  // ---------------------------------------------------------
  // s01.00 AUTHENTICATION
  // ---------------------------------------------------------

  // s01.01 User Access Token
  // ---------------------------------------------------------
  async LogInGetToken(payload: Interface.ILogin) {
    const params = new URLSearchParams()
    params.append('username', payload.username)
    params.append('password', payload.password)

    try {
      return await publicAxios.post<Interface.IAuthToken>(`${ENV.imiApiUrl}/login/access-token`, params)
    } catch (res: any) {
      return res.request.response
    }
  },

  async LogInGetTokenFromRefreshToken(refresh_token: string) {
    try {
      return await secureAxios.post<Interface.IAuthToken>(`${ENV.imiApiUrl}/login/refresh-token`, { refresh_token })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s01.02 Access Token Check
  // ---------------------------------------------------------
  async CheckToken() {
    try {
      return await secureAxios.post<Interface.IUser>(`${ENV.imiApiUrl}/login/test-token`)
    } catch (res: any) {
      return res.request.response
    }
  },
  //
  // s01.03 Read Current User
  // ---------------------------------------------------------
  // NOTE: Data related to user that is currently logged in.
  // TODO:
  //    1. include logo path for white_label to display correct logo when logging in
  //    2. decide how to handle the returned advertiser_id and campaign_id arrays
  //        a. should we include more data so we can use this to display advertiser dashboard,
  //           campaign central, report pages and switch between campaigns/advertisers explicitly
  //           assigned to user? Does this go elsewhere?
  async getMe() {
    return secureAxios.get<Interface.IUserMe>(`${ENV.imiApiUrl}/user/me/`)
  },

  // s01.04 Reset Password & Recover Username
  // ---------------------------------------------------------
  async requestUsernameEmail(email: string) {
    try {
      return await secureAxios.post<Interface.IGenericStatusResponse>(`${ENV.imiApiUrl}/login/recover-username/${email}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  async recoverPasswordStart(username: string) {
    try {
      return await secureAxios.post<Interface.IRecoverPasswordStart>(`${ENV.imiApiUrl}/login/recover-password/${username}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  async ResetPassword(params: Interface.IResetPassword) {
    try {
      return await secureAxios.post<Interface.IResetPassword>(`${ENV.imiApiUrl}/login/reset-password`, params)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s02.00 USERS
  // ---------------------------------------------------------

  // s02.01 MultiResponse User Routes
  // ---------------------------------------------------------
  // ACCESS: admin only access for all ✔️
  async GetAllUserTypes(signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IUserTypeMultiResponse>(`${ENV.imiApiUrl}/user/types`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetAllUsers(signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IUserMultiResponse>(`${ENV.imiApiUrl}/user/`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s02.02 CRUD User
  // ---------------------------------------------------------
  // ACCESS: admin only access for all ✔️
  async CreateNewUser(user: Interface.IUserCreate) {
    try {
      return await secureAxios.post<Interface.IUserCreate>(`${ENV.imiApiUrl}/user/`, user)
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetUserById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IUserInDb>(`${ENV.imiApiUrl}/user/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  async UpdateUserById(user: Interface.IUserUpdate) {
    try {
      return await secureAxios.put<Interface.IUserUpdate>(`${ENV.imiApiUrl}/user/${user.id}`, user)
    } catch (res: any) {
      return res.request.response
    }
  },
  async UserDelete(user_id: number) {
    try {
      return await secureAxios.delete<Interface.GenericResponse>(`${ENV.imiApiUrl}/user/${user_id}`)
    } catch (res: any) {
      return res.request.response
    }
  },

  // s02.03 User's Relationship to Advertiser/Campaigns
  // ---------------------------------------------------------
  // ACCESS: all access except call analyst, they will never see outside of call processing section ✔️
  // @Christine -- I've put this as Admin only.  I think the updated getMe route handles this nicely
  // where only that user's assigned advertisers and campaigns are returned.
  async GetUserAdvertisers(user_id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<number []>(`${ENV.imiApiUrl}/user/${user_id}/advertisers/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only ✔️
  async PostUserAdvertisers(user: Interface.IUserAdvertisers) {
    try {
      return await secureAxios.post<number []>(`${ENV.imiApiUrl}/user/${user.user_id}/advertisers/`, user.advertiser_ids)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: all access except call analyst, they will never see outside of call processing section
  // @Christine -- I've put this as Admin only.  I think the updated getMe route handles this nicely
  // where only that user's assigned advertisers and campaigns are returned.
  async GetUserCampaigns(user_id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<number []>(`${ENV.imiApiUrl}/user/${user_id}/campaigns/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only ✔
  async PostUserCampaigns(user: Interface.IUserCampaigns) {
    try {
      return await secureAxios.post<number []>(`${ENV.imiApiUrl}/user/${user.user_id}/campaigns/`, user.campaign_ids)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s03.00 EMAIL TEMPLATES
  // ---------------------------------------------------------
  // ACCESS: admin only for all email template    ✔️

  // s03.01 MultiResponse Email Templates
  // ---------------------------------------------------------
  async GetAllEmailTemplates(signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IEmailTemplateMultiResponse>(`${ENV.imiApiUrl}/email/`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s03.02 CRUD Email Template
  // ---------------------------------------------------------
  async CreateNewEmailTemplate(template: Interface.IEmailTemplateCreate) {
    try {
      return await secureAxios.post<Interface.IEmailTemplateCreate>(`${ENV.imiApiUrl}/email/`, template)
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetEmailTemplateByID(emailtemplate_id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IEmailTemplateInDbDetail>(`${ENV.imiApiUrl}/email/${emailtemplate_id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  async UpdateEmailTemplate(emailtemplate: Interface.IEmailTemplateUpdate) {
    try {
      return await secureAxios.put<Interface.IEmailTemplateUpdate>(`${ENV.imiApiUrl}/email/${emailtemplate.id}`, emailtemplate)
    } catch (res: any) {
      return res.request.response
    }
  },
  // TODO: Delete Email Template

  // ---------------------------------------------------------
  // s04.00 BRANDING (FORMERLY KNOWN AS WHITE LABEL)
  // ---------------------------------------------------------
  // ACCESS: Admin only access ✔, depending upon how we deal with the logo we want to display to users
  // based on their branding id and based on the page they're directed to. Might need get branding by ID route?
  // (ex. bahakeldigital.imidashboard.com vs. encompass.imidashboard.com)
  // @Christine -- see my note to you in slack about this.

  // s04.01 MultiResponse Branding
  // ---------------------------------------------------------
  async GetAllBranding(signal) {
    try {
      return await secureAxios.get<Interface.IBrandingMultiResponse>(`${ENV.imiApiUrl}/whitelabel/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  async PostBrandingLogin(hostname, signal) {
    try {
      return await secureAxios.post<Interface.IBrandingLoginPost>(`${ENV.imiApiUrl}/whitelabel/url/`, { hostname, signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s04.02 CRUD Branding
  // ---------------------------------------------------------
  async BrandingCreate(brand: Interface.IBrandingCreate) {
    try {
      return await secureAxios.post<Interface.IBrandingCreate>(`${ENV.imiApiUrl}/whitelabel/`, brand)
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetBrandingById(whitelabel_id: number, signal: AbortSignal) {
    try {
      const response = await secureAxios.get<Interface.IBrandingDetailInDb>(`${ENV.imiApiUrl}/whitelabel/${whitelabel_id}`, { signal })
      if (response.status === 200) {
        if (response.data.url_login) {
          // Format the url_login field to include just the prefix
          response.data.url_login = response.data.url_login?.replace('https://', '')
            .replace('.imidashboard.com', '')
            .replace('.imidashboard-dev.com', '')
            .replace(ENV.imiURL, '')
            .replace('staging', '')
            .replace('.', '') || ''
        }
        return response
      }
    } catch (res: any) {
      return res.request.response
    }
  },
  async BrandingUpdate(brand: Interface.IBrandingUpdate) {
    try {
      return await secureAxios.put<Interface.IBrandingUpdate>(`${ENV.imiApiUrl}/whitelabel/${brand.id}`, brand)
    } catch (res: any) {
      return res.request.response
    }
  },
  // TODO: delete branding

  // s04.03 RU Branding for User Id
  // ---------------------------------------------------------
  async GetBrandingUser(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IBrandingUsers>(`${ENV.imiApiUrl}/whitelabel/${id}/users/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  async BrandingUserIdUpdate(branding: Interface.IBrandingUsersInDb) {
    try {
      return await secureAxios.put<number []>(`${ENV.imiApiUrl}/whitelabel/${branding.branding_id}/users/`, branding.user_ids)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s05.00 ADVERTISERS
  // ---------------------------------------------------------

  // s05.01 MultiResponse Advertisers
  // ---------------------------------------------------------
  // ACCESS: depending upon how we decide to give users access to their assigned advertisers, this may
  // or may not need to be for all user types. Admin, Call Managers, call analysts, and Team viewers
  // need all advertisers returned to them.
  // ✔ Was accessible by admin only, now accessible by any Encompass role.

  async GetAllAdvertisers(signal) {
    try {
      return await secureAxios.get<Interface.IAdvertiserMultiResponse>(`${ENV.imiApiUrl}/advertiser/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // s05.02 CRUD Advertiser
  // ---------------------------------------------------------
  // ACCESS: admin only ✔
  async CreateAdvertiser(advertiser: Interface.IAdvertiser) {
    try {
      return await secureAxios.post<Interface.IAdvertiser>(`${ENV.imiApiUrl}/advertiser/`, advertiser)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: all user types- unless we decide on a better path for partners/clients to access their specific advertisers
  // @Christine -- leaving this as Admin for now since getMe now returns assigned campaigns and advertisers.  The /Report
  // urls I think handle the rest of the customer facing views.
  async GetAdvertiserById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IAdvertiserInDb>(`${ENV.imiApiUrl}/advertiser/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only ✔
  async UpdateAdvertiser(advertiser: Interface.IAdvertiserUpdate) {
    try {
      return await secureAxios.put<Interface.IAdvertiserUpdate>(`${ENV.imiApiUrl}/advertiser/${advertiser.id}`, advertiser)
    } catch (res: any) {
      return res.request.response
    }
  },
  // TODO: delete advertiser, admin access only.

  // ---------------------------------------------------------
  // s06.00 CAMPAIGNS
  // ---------------------------------------------------------

  // s06.01 MultiResponse Campaigns
  // ---------------------------------------------------------
  // ACCESS: all enc types only
  async GetAllCampaigns(active: boolean, signal: AbortSignal, only_type?: ICampaignTypes) {
    try {
      return await secureAxios.get<Interface.ICampaignMultiResponse>(`${ENV.imiApiUrl}/campaign/?only_active=${active}${only_type ? `&only_type=${only_type}` : ''}`, {
        signal,
      })
      // return await secureAxios.get<ICampaignMultiResponse>(`${ENV.imiApiUrl}/campaign/?offset=${offset}&limit=${limit}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: all enc user types, as well as partners and clients. See swagger for specifics.
  // TODO: turn this into legacy and swap out for campaign/feed
  async GetAllCampaignsPublic(active: boolean, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignMultiResponse>(`${ENV.imiApiUrl}/campaign/public?only_active=${active}`, {
        signal,
      })
      // return await secureAxios.get<ICampaignMultiResponse>(`${ENV.imiApiUrl}/campaign/?offset=${offset}&limit=${limit}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetCampaignFeed(active: boolean) {
    try {
      return await secureAxios.get<Interface.ICampaignFeedMultiResponse[]>(`${ENV.imiApiUrl}/campaign/feed`)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: I think admin only since we're only considering this "unassigned" campaigns initially on this endpoint,
  // or at least how I'm treating them. Should this be any different?
  // ✔ - Makes sense to me.
  async GetAllPCampaigns(offset, limit, only_unassigned, signal) {
    const controller = new AbortController()
    try {
      return await secureAxios.get<Interface.ICampaignSourceMultiResponse>(`${ENV.imiApiUrl}/pcampaign/?offset=${offset}&limit=${limit}&only_unassigned=${only_unassigned}`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only
  // @Christine -- I made the get route CurrentActiveUser since it could make sense at some point.  Create, Update
  // etc are all admin only.
  async GetCampaignTypes(signal) {
    try {
      return await secureAxios.get<Interface.ICampaignTypeMultiResponse>(`${ENV.imiApiUrl}/campaign/type/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only ✔
  async GetCampaignUplift(signal) {
    try {
      return await secureAxios.get<Interface.ICampaignTypeMultiResponse>(`${ENV.imiApiUrl}/uplift/`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetCampaignPausedStatus(id: number) {
    try {
      return await secureAxios.get<Interface.IGenericStatusResponse>(`${ENV.imiApiUrl}/campaign/paused/${id}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  async SetCampaignPausedStatus(id: number, paused: boolean) {
    try {
      return await secureAxios.post<Interface.IGenericStatusResponse>(`${ENV.imiApiUrl}/campaign/paused/${id}?paused=${paused}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  // s06.02 CRU Campaigns
  // ---------------------------------------------------------
  // ACCESS: admin only ✔
  async CreateCampaign(campaign: Interface.ICampaignCreate) {
    try {
      return await secureAxios.post<Interface.ICampaignCreate>(`${ENV.imiApiUrl}/campaign/`, campaign)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: Enc user types only, extra data. See swagger for more details.
  async GetCampaignById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignInDb>(`${ENV.imiApiUrl}/campaign/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: Enc user types, clients, partner. Reduced data like no uplift base or API id's/source.
  // See swagger for documentation.
  async GetCampaignByIdPublic(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignInDb>(`${ENV.imiApiUrl}/campaign/public/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only ✔
  async UpdateCampaign(campaign: Interface.ICampaignUpdate) {
    try {
      return await secureAxios.put<Interface.ICampaignUpdate>(`${ENV.imiApiUrl}/campaign/${campaign.id}`, campaign)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ACCESS: admin only ✔
  async DeleteCampaign(id: number) {
    try {
      return await secureAxios.delete<Interface.GenericResponse>(`${ENV.imiApiUrl}/campaign/${id}`)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ACCESS: enc user types ✔
  async GetCampaignCount(status: boolean, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignCount>(`${ENV.imiApiUrl}/campaign/count?only_active=${status}`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s06.03 PCampaigns (unassigned/direct from platform data)
  // ---------------------------------------------------------
  // ACCESS: I think all are only admin access ✔
  async GetPCampaignById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IPCampaign>(`${ENV.imiApiUrl}/pcampaign/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  async AssignPCampaignToCampaign(id) {
    try {
      return await secureAxios.put<Interface.IAssignPCampaignToCampaign>(`${ENV.imiApiUrl}/pcampaign/${id.pcampaign_id}/set/${id.campaign_id}`)
    } catch (res: any) {
      return res.request.response
    }
  },
  async UpdatePCampaign(pcampaign) {
    try {
      return await secureAxios.put<Interface.IPCampaignUpdate>(`${ENV.imiApiUrl}/pcampaign/${pcampaign.id}`, pcampaign)
    } catch (res: any) {
      return res.request.response
    }
  },

  async UpdatePCampaigns(pcampaigns) {
    try {
      return await secureAxios.put<Interface.IPCampaignUpdate>(`${ENV.imiApiUrl}/pcampaign`, pcampaigns)
    } catch (res: any) {
      return res.request.response
    }
  },

  // s06.04 CRU Campaign Types
  // ---------------------------------------------------------
  // ACCESS: admin only for all ✔
  async CreateNewCampaignType(campaignType: Interface.ICampaignType) {
    try {
      return await secureAxios.post<Interface.ICampaignType>(`${ENV.imiApiUrl}/campaign/type/`, campaignType)
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetCampaignTypeById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignTypeInDb>(`${ENV.imiApiUrl}/campaign/type/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  async UpdateCampaignTypeById(campaignType: Interface.ICampaignTypeInDb) {
    try {
      return await secureAxios.put<Interface.ICampaignTypeInDb>(`${ENV.imiApiUrl}/campaign/type/${campaignType.id}`, campaignType)
    } catch (res: any) {
      return res.request.response
    }
  },

  // s06.05 RU Campaign Uplift
  // ---------------------------------------------------------
  // ACCESS: admin only for all ✔
  async GetCampaignUpliftById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignUpliftInDb>(`${ENV.imiApiUrl}/uplift/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // using campaign id
  // pass in cycle_id if specific to cycle
  // pass in cycle_id: 0 if uplift needs to be updated for whole campaign and subsequent cycles
  async SetCampaignUpliftById(uplift: Interface.ISetCampaignUplift) {
    try {
      return await secureAxios.post<Interface.ISetCampaignUplift>(`${ENV.imiApiUrl}/uplift/${uplift.id}`, uplift)
    } catch (res: any) {
      return res.request.response
    }
  },

  // s06.06 Misc
  // ---------------------------------------------------------
  // ACCESS: admin only ✔, or at least that's what I'm using it now for
  // TBD on how we handle the specific users campaigns/advertisers.
  async GetCampaignsByAdvertiserId(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignInDb>(`${ENV.imiApiUrl}/campaign/advertiser/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // Campaign Call Settings
  async GetCampaignCallSettingId(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignCallSettings>(`${ENV.imiApiUrl}/callsettings/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  async SetCampaignCallSettingId(query: ICallSettingsChange) {
    try {
      return await secureAxios.post<Interface.ICallSettingsChangeResponse>(`${ENV.imiApiUrl}/callsettings/`, query)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s07.00 ROI
  // ---------------------------------------------------------
  // ACCESS: as of phase one, only admin ✔... depending upon how we give the ROI to the users
  // I have not implemented this since I don't think we'll be launching with ROI.
  // s07.01 MultiResponse ROIs
  // ---------------------------------------------------------
  async GetAllCampaignRois(signal) {
    // MultiUpdated
    try {
      return await secureAxios.get<Interface.IRoiMultiResponse[]>(`${ENV.imiApiUrl}/campaign/roi/`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s07.02 CRUD ROI
  // ---------------------------------------------------------
  // NOTE: always a post, even when updating
  // backend handles updating array of lead_types
  async CreateCampaignRoi(roi: Interface.IRoiUpdate) {
    try {
      return await secureAxios.post<Interface.IRoiUpdate>(`${ENV.imiApiUrl}/campaign/roi/${roi.id}`, roi)
    } catch (res: any) {
      return res.request.response
    }
  },
  // by campaign ID
  async GetCampaignRoiById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.IRoi>(`${ENV.imiApiUrl}/campaign/roi/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s08.00 CALLS
  // ---------------------------------------------------------

  // s08.01 Call Industries
  // ---------------------------------------------------------
  // ACCESS: admin only ✔
  async GetCallIndustries(signal) {
    try {
      return await secureAxios.get<Interface.ICallCategoryMultiResponse>(`${ENV.imiApiUrl}/campaign/category/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin only
  async GetCallIndustryById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignCategory>(`${ENV.imiApiUrl}/campaign/category/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s08.02 Call Categories
  // ---------------------------------------------------------
  // ACCESS: admin only  ✔
  async GetCallCategories() {
    try {
      return await secureAxios.get<Interface.ICallCategory[]>(`${ENV.imiApiUrl}/calls/category/`)
    } catch (res: any) {
      return res.request.response
    }
  },
  // TODO: additional items for phase 2
  // 1. get call category by id
  // 2. update call category by id
  // 3. delete call category by id

  // s08.03 Call Processing
  // ---------------------------------------------------------
  // ACCESS: admin, call manager, call analyst  ✔
  async GetAllCallProcessing(flagged: boolean, signal: AbortSignal) {
    // Call processing summary for call analysts and call manager
    let query_partial = ''
    if (flagged) {
      // Only get flagged calls going back 30 days
      // @Christine -- This method could be updated to accept a start and end date from the frontend, allowing
      //               users to set the date range for flagged calls or other call processing.
      const start_date = new Date()
      start_date.setDate(start_date.getDate() - 30)
      const end_date = new Date()
      query_partial = `?only_flagged=true&start_date=${start_date.toISOString().split('T')[0]}&end_date=${end_date.toISOString().split('T')[0]}`
    }
    try {
      return await secureAxios.get<Interface.ICallProccessingSummary>(`${ENV.imiApiUrl}/calls/processing/${query_partial}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: all access except call analysts, but don't want partners/clients to access campaign_id's reports
  // they're not assigned to.
  // @Christine -- I was a little unclear, so I rewrote this below.  Is this right?  If so, it's implemented.
  // ACCESS: All users except call analysts.  Partners and Clients can only see calls that belong to their assigned Campaign(s).
  async GetCallsOverview(data: Interface.DetailReportParameters, signal: AbortSignal) {
    // NOTE: status is either processed or unprocessed
    // NOTE: used in SEM report callreport.tsx
    // FIXME: add in optional parameter for campaign ID, if there's a start/end date cycle is ignored and vice versa?
    try {
      return await secureAxios.get<any>(`${ENV.imiApiUrl}/calls/overview/?campaign_id=${data.id}${data.start_date ? `&start_date=${data.start_date}` : ''}${data.end_date ? `&end_date=${data.end_date}` : ''}`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: all access, but don't want partners/clients to access campaign_id's reports
  // they're not assigned to
  // @Christine -- Implemented the description below:
  // ACCESS: All users can access.  Partners and Clients can only see calls that belong to their assigned Campaign(s).
  async GetCallsByCampaignAndStatus(data: Interface.CallsByCampaignAndStatusParameters, signal: AbortSignal) {
    const params = {
      campaign_id: data.id,
      cycle_id: data.cycle_id?.length ? data.cycle_id : null,
      start_date: data.start_date?.length ? data.start_date : null,
      end_date: data.end_date?.length ? data.end_date : null,
      only_flagged: data.only_flagged,
      status: data.status?.length ? data.status : null,
    }
    try {
      return await secureAxios.get<Interface.ICallsByCampaignAndStatus>(`${ENV.imiApiUrl}/calls/`, { params, signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: all access, but don't want partners/clients to access campaign_id's reports
  // they're not assigned to
  // async GetCallsByCampaignId(id: number, only_flagged?: boolean, after?: string, before?: string) {
  //   try {
  //     // FIXME: update for flagged calls to get flagged call report WIP
  //     // return await secureAxios.get<Interface.ICallProcessByCampaign>(`${ENV.imiApiUrl}/calls/processing/campaign/?campaign_id=${id}{&after=${after}}&before=${before}&only_flagged=${only_flagged}`)
  //     return await secureAxios.get<Interface.ICallProcessByCampaign>(`${ENV.imiApiUrl}/calls/processing/campaign/?campaign_id=${id}${after ? `&after=${after}` : ''}${before ? `&after=${before}` : ''}${only_flagged ? `&only_flagged=${only_flagged}` : '&only_flagged=false'}`)
  //   } catch (res: any) {
  //     return res.request.response
  //   }
  // },
  // ACCESS: admin, call analyst, call manager
  async UpdateCallById(call: Interface.ICallProcessingUpdateByCallId) {
    try {
      return await secureAxios.put<Interface.ICallProcessingUpdateByCallId>(`${ENV.imiApiUrl}/calls/processing/${call.id}`, call)
    } catch (res: any) {
      return res.request.response
    }
  },

  // s08.04 Call Instructions
  // ---------------------------------------------------------
  // ACCESS: admin, call analyst, call manager  ✔
  async GetCallInstructions(signal) {
    try {
      return await secureAxios.get<Interface.ICallInstructionsMultiResponse>(`${ENV.imiApiUrl}/calls/instruction/`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin, call manager
  async CreateCallInstructions(instructions: Interface.ICallInstructionCreate) {
    try {
      return await secureAxios.post<Interface.ICallInstructionCreate>(`${ENV.imiApiUrl}/calls/instruction/`, instructions)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin, call manager
  async GetCallInstructionsById(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICallInstructionCreate>(`${ENV.imiApiUrl}/calls/instruction/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin, call analyst, call manager
  async GetCallInstructionsByCampaignId(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICallInstruction>(`${ENV.imiApiUrl}/calls/instruction/campaign/${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin, call manager
  async UpdateCallInstructions(instructions: Interface.ICallInstructionUpdate) {
    try {
      return await secureAxios.put<Interface.ICallInstructionUpdate>(`${ENV.imiApiUrl}/calls/instruction/${instructions.id}`, instructions)
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: admin, call manager
  async DeleteCallInstructions(id: number) {
    try {
      return await secureAxios.delete<number>(`${ENV.imiApiUrl}/calls/instruction/${id}`)
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s09.00 REPORTS
  // ---------------------------------------------------------

  // s09.01 Campaign Central ✔
  // ---------------------------------------------------------
  // ACCESS: admin, team viewer, partner
  async GetCampaignCentral(signal) {
    try {
      return await secureAxios.get<Interface.ICampaignCentralMultiResponse>(`${ENV.imiApiUrl}/reports/central/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },
  // ACCESS: partner, admin
  // Gets every campaign assigned to user type and generates a CSV with current and historical campaign totals from the
  // start of the campaigns. Similar to the GetCampaignOverview but for all campaigns and with two different fields.
  async GetCampaignCentralDownload() {
    try {
      return await secureAxios.get(`${ENV.imiApiUrl}/reports/download/campaign-central/`)
    } catch (res: any) {
      return res.request.response
    }
  },

  // s09.02 Campaign Overview  ✔
  // ---------------------------------------------------------
  // ACCESS: admin, team viewer, partner, client
  // @Christine -- Also limited if user is Partner or Client, only for their assigned campaign(s).
  // NOTE: if a client has only ONE advertiser, they're directed here as their homepage
  //       if a client has more than one advertiser, they're directed to the "advertiser dashboard" as their homepage
  //       partners are always directed first to the "advertiser dashboard" as their homepage.
  async GetCampaignOverview(id: number, signal: AbortSignal) {
    try {
      return await secureAxios.get<Interface.ICampaignOverview>(`${ENV.imiApiUrl}/reports/overview/?advertiser_id=${id}`, { signal })
    } catch (res: any) {
      return res.request.response
    }
  },

  // s09.03 SEM
  // ---------------------------------------------------------
  // ACCESS: all except for call analysts  ✔
  // @Christine -- Also limited if user is Partner or Client, only for their assigned campaign(s).
  async GetSemReport(data: Interface.DetailReportParameters) {
    // ${data.cycle_id ? `&cycle_id=${data.cycle_id}` : ''}
    // FIXME: adding in the cycle_id as an optional parameter
    //
    try {
      return await secureAxios.get<Interface.ISemDetail>(
        `${ENV.imiApiUrl}/reports/overview/sem/?campaign_id=${data.id}${(data.start_date && !data.cycle_id) ? `&start_date=${data.start_date}` : ''}${(data.end_date && !data.cycle_id) ? `&end_date=${data.end_date}` : ''}${(data.cycle_id) ? `&cycle_id=${data.cycle_id}` : ''}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },

  async GetSemRoiReport(data: Interface.SemRoiParameters) {
    try {
      return await secureAxios.get<Interface.IRoiSemReport>(
        `${ENV.imiApiUrl}/reports/overview/sem/roi/?campaign_id=${data.id}&cycle_id=${data.cycle_id}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },

  // s09.04 Daily Activity
  // ---------------------------------------------------------
  // ACCESS: all but call analyst  ✔
  // @Christine -- Also limited if user is Partner or Client, only for their assigned campaign(s).
  async GetDailyActivity(data: Interface.DetailReportParameters, signal: AbortSignal) {
    const params = {
      campaign_id: data.id,
      start_date: data.start_date?.length ? data.start_date : null,
      end_date: data.end_date?.length ? data.end_date : null,
      cycle_id: data.cycle_id?.length ? data.cycle_id : null,
    }
    try {
      return await secureAxios.get<Interface.IDailyActivityMultiResponse>(
        `${ENV.imiApiUrl}/daily/`, { params, signal },
      )
    } catch (res: any) {
      return res.request.response
    }
  },

  // s09.05 CSV Report Downloads
  // ---------------------------------------------------------
  async GetWebEventsDownload(data: Interface.CsvDownload) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/web-events/?campaign_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetCampaignSummaryDownload(id: number) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/campaign-summary/?campaign_id=${id}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetDailyActivityDownload(data: Interface.CsvDownload) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/daily-search-metrics/?campaign_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetEnhancedCallAnalyticsDownload(data: Interface.CsvDownload) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/call-analytics/?campaign_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetActivityBreakdownDownload(data: Interface.CsvDownload) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/activity-breakdown/?campaign_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetDeviceTypeBreakdownDownload(data: Interface.CsvDownload) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/device-type/?campaign_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },

  // s09.06 Display
  // ---------------------------------------------------------
  // ACCESS: all except for call analysts  ✔
  // @Christine -- Also limited if user is Partner or Client, only for their assigned campaign(s).
  async GetDisplayReport(data: Interface.DetailReportParameters) {
    // ${data.cycle_id ? `&cycle_id=${data.cycle_id}` : ''}
    // FIXME: adding in the cycle_id as an optional parameter
    try {
      // console.log(data)
      return await secureAxios.get<Interface.IDisplayDetail>(
        `${ENV.imiApiUrl}/reports/overview/display/?campaign_id=${data.id}${(data.start_date && !data.cycle_id) ? `&start_date=${data.start_date}` : ''}${(data.end_date && !data.cycle_id) ? `&end_date=${data.end_date}` : ''}${(data.cycle_id) ? `&cycle_id=${data.cycle_id}` : ''}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },
  async GetPlacementBreakdown(data: Interface.CsvDownload) {
    try {
      return await secureAxios.get<any>(
        `${ENV.imiApiUrl}/reports/download/placements/?campaign_id=${data.id}&start_date=${data.start_date}&end_date=${data.end_date}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },

  // Video
  // ---------------------------------------------------------
  // ACCESS: all except for call analysts  ✔
  // @Christine -- Also limited if user is Partner or Client, only for their assigned campaign(s).
  async GetVideoReport(data: Interface.DetailReportParameters) {
    try {
      return await secureAxios.get<Interface.IVideoDetails>(
        `${ENV.imiApiUrl}/reports/overview/video/?campaign_id=${data.id}${(data.start_date && !data.cycle_id) ? `&start_date=${data.start_date}` : ''}${(data.end_date && !data.cycle_id) ? `&end_date=${data.end_date}` : ''}${(data.cycle_id) ? `&cycle_id=${data.cycle_id}` : ''}`,
      )
    } catch (res: any) {
      return res.request.response
    }
  },

  // ---------------------------------------------------------
  // s10.00 MISC
  // ---------------------------------------------------------

  // s10.01 Platforms
  // ---------------------------------------------------------
  // ACCESS: admin only  ✔
  async GetPlatforms(signal) {
    try {
      return await secureAxios.get<Interface.IPlatform[]>(`${ENV.imiApiUrl}/platform/`, {
        signal,
      })
    } catch (res: any) {
      return res.request.response
    }
  },

  async GetPlatformCreds() {
    try {
      return await secureAxios.get<Interface.IPlatformCred[]>(`${ENV.imiApiUrl}/platform/creds`)
    } catch (res: any) {
      return res.request.response
    }
  },

  async GetPlatformCred(credId: number) {
    try {
      return await secureAxios.get<Interface.IPlatformCred[]>(`${ENV.imiApiUrl}/platform/creds/${credId}`)
    } catch (res: any) {
      return res.request.response
    }
  },

  async GetPlatformCredStatus() {
    try {
      return await secureAxios.get<Interface.IPlatformCredStatus>(`${ENV.imiApiUrl}/platform/creds/status/`)
    } catch (res: any) {
      return res.request.response
    }
  },

  async CreatePlatformCred(cred: Interface.IPlatformCredPost) {
    try {
      return await secureAxios.post<Interface.IPlatformCred>(`${ENV.imiApiUrl}/platform/creds`, cred)
    } catch (res: any) {
      return res.request.response
    }
  },

  async UpdatePlatformCred(credId: number, cred: Interface.IPlatformCredPost) {
    try {
      return await secureAxios.put<Interface.IPlatformCred>(`${ENV.imiApiUrl}/platform/creds/${credId}`, cred)
    } catch (res: any) {
      return res.request.response
    }
  },
}
