import {
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import {
  IAdvertiserInDb,
  ICampaignTypeInDb,
  ICampaignInDb,
  ICampaignUpdate,
  ICampaignMultiResponse,
  IPCampaign,
} from '../../../../api/imi/interfaces'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { IMIApi } from '../../../../api/imi/api'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import CampaignAssignment from './AdvertiserAssignment'
import LoadingSpinner from '../../../LoadingSpinner'
import CallProcessRoi from './CallProcess'
import EditCampaignForm from '../../../form/campaigns/campaign_settings/EditCampaignForm'

interface Props {
  allAdvertisers: IAdvertiserInDb[]
  allCampaignTypes: ICampaignTypeInDb[]
  isLoading: boolean
  edit: boolean
  disabled?: boolean,
  pCampaign?: IPCampaign
}

const EditCampaign:React.FC<Props> = ({
  allAdvertisers,
  allCampaignTypes,
  isLoading,
  edit,
  disabled,
  pCampaign,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [campaignId, setCampaignId] = useState(0)
  const [showButton, setShowButton] = useState<boolean>(edit)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)

  const methods = useForm<ICampaignUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      ...campaignInDb,
      advertiser_id: campaignInDb?.advertiser?.id,
      category_id: campaignInDb?.category?.id,
      specific_id: campaignInDb?.specific?.id,
      type_id: campaignInDb?.type?.id,
    }), [campaignInDb]),
  })

  useEffect(() => {
    methods.reset({
      ...campaignInDb,
      advertiser_id: campaignInDb?.advertiser?.id,
      name: campaignInDb?.name,
      category_id: campaignInDb?.category?.id,
      specific_id: campaignInDb?.specific?.id,
      type_id: campaignInDb?.type?.id,
    })
  }, [campaignInDb, methods])

  const cancel = () => {
    methods.reset({
      ...campaignInDb,
      advertiser_id: campaignInDb?.advertiser?.id,
      name: campaignInDb?.name,
      category_id: campaignInDb?.category?.id,
      specific_id: campaignInDb?.specific?.id,
      type_id: campaignInDb?.type?.id,
    }, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }
  const editCampaign = () => {
    setShowButton(true)
  }

  const onSubmit: SubmitHandler<ICampaignUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      id: campaignInDb.id,
      is_active: campaignInDb.is_active,
      name: campaignInDb.advertiser.name,
      secondary_name: data.secondary_name,
      roi_is_active: campaignInDb.roi_is_active,
      call_processing_is_active: campaignInDb.call_processing_is_active,
      uplift_base: campaignInDb.uplift_base,
      advertiser_id: campaignInDb.advertiser?.id,
      category_id: data.category_id,
      specific_id: data.specific_id,
      type_id: data.type_id,
      // pcampaign_id: campaignInDb.pcampaign,
    }

    const submitResponse = await IMIApi.UpdateCampaign(formData)

    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setCampaignInDb(submitResponse.data)
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  // FIXME: why is rhf so buggy? have to have this to prove
  // to itself that it's valid.
  // console.log(methods.formState.isValid)
  return (
    <>
      <Content title="campaign details">
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <EditCampaignForm
                  errors={methods.formState.errors}
                  allAdvertisers={allAdvertisers}
                  allCampaignTypes={allCampaignTypes}
                  pCampaign={pCampaign}
                  showButton={showButton}
                />
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isDirty && methods.formState.isValid}
                  disabledCancel={!methods.formState.isDirty}
                  onClickCancel={cancel}
                  onClickEdit={editCampaign}
                  btnText={btnText}
                  showButton={showButton}
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default EditCampaign
