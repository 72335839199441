import {
  useState,
  useEffect,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import Content from '../../../layouts/Content'
import Icon from '../../../Icon'
import { fixDateObj } from '../../../../helpers/utils'
import LoadingSpinner from '../../../LoadingSpinner'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import { ICycleCallSettings } from '../../../../api/imi/interfaces'
import { campaignSettingsStyles, callCyclesMenu } from '../campaign_settings/Data'
import Pagination from '../../../Pagination'

interface Props {
  cycles: ICycleCallSettings[]
  loading: boolean
  edit: boolean
}

const CallSettingsCycle: React.FC<Props> = ({
  cycles,
  loading,
}) => {
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [pageLimit, setPageLimit] = useState(30)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('start_date')
  const [cycleCallSettings, setCycleCallSettings] = useState<ICycleCallSettings[]>([] as any)

  useEffect(() => {
    const campaignCycles = cycles?.sort((a, b) => ((a.start_date > b.start_date) ? -1 : 1))
    setCycleCallSettings(campaignCycles)
  }, [cycles])

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? cycleCallSettings.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1)) : cycleCallSettings?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { ...values }
  }

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (cycleCallSettings.length - pageLimit))
  }

  const handleCollapse = () => {
    setPageLimit(7)
  }

  return (
    <>
      <Content
        title="cycle's call settings"
        padding={false}
      >
        {loading ? (
          <span className="px-4 md:mx-6 block"><LoadingSpinner variant="primary" size="lg" /></span>
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-3"
              mdGrid="md:grid-cols-3"
            >
              {callCyclesMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                                  `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && campaignSettingsStyles.desktop}`
                              )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {cycleCallSettings?.length === 0 ? (
                <p className="error px-6">Campaign no previous cycles with call processing.</p>
              ) : (
                cycleCallSettings?.filter((cycle, i) => (i < pageLimit)).map((cycle) => (
                  <HorizontalGrid
                    key={cycle.id}
                    smGrid="grid-cols-3"
                    mdGrid="md:grid-cols-3"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      {fixDateObj(cycle?.start_date)}
                    </p>

                    <p className="text-xxs pr-3">
                      {cycle?.call_processing_is_active ? 'Yes' : 'No'}
                    </p>
                    <p className="text-xxs pr-3">
                      {cycle?.num_calls}
                    </p>

                  </HorizontalGrid>
                ))
              )}
            </div>
            {cycleCallSettings && cycleCallSettings?.length > 0 && (
              <div className="block px-4 pt-3 md:px-6">
                <Pagination
                  pageLimit={pageLimit}
                  adminLimit={pageLimit}
                  data={cycleCallSettings}
                  offset={10}
                  onClick={handleLoadMore}
                  onClickCollapse={handleCollapse}
                />
              </div>
            )}
          </>
        )}
      </Content>
    </>
  )
}

export default CallSettingsCycle
