// TODO: error state
// TODO: update label styles after design reveal
import React, { forwardRef } from 'react'
import { cls } from '../../helpers/utils'
import ToolTip from '../ToolTip'

const styles = {
  base: 'w-full max-w-form font-base text-xs text-default rounded border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none',
  special: 'w-full max-w-form font-base text-xs text-default rounded-l border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none',
  icon: 'pl-10',
  enabled: 'focus:ring-1 focus:ring-primary focus:border-transparent bg-grey-lightest',
  disabled: 'bg-white cursor-not-allowed text-disabled focus:ring-0 focus:ring-transparent',
}

// NOTE: add class name 50-split to input if you want styling to look like the dashboard
// branding page form for "Login URL" input field.

type TextAreaRef = React.ForwardedRef<HTMLTextAreaElement>

interface Props {
  label: string
  name: string
  placeholder?: string
  className?: string
  width?: string
  hideLabel?: boolean
  toolTipInfo?: string | React.ReactNode
  options?: any[]
  icon?: React.ReactNode
  required?: boolean
  onChange?: (e) => void
  // React-hook-forms specific
  rest?: any
  error?: any
  ref?: any
  onBlur?: any
  disabled?: boolean
  children?: React.ReactNode
  value?: string | []
  tipId?: string
}

const TextArea:React.FC<Props> = forwardRef(
  (
    {
      label,
      icon,
      placeholder,
      className,
      width,
      toolTipInfo,
      hideLabel = false,
      options,
      name,
      required,
      rest,
      error,
      onChange,
      onBlur,
      disabled,
      children,
      value,
      tipId,
    }:Props, ref:TextAreaRef,
  ) => (
    <div className={`mb-6 md:min-w-auto ${className}`}>
      <label htmlFor={label} className={`heading-5 font-semibold mb-1 ${hideLabel ? 'hidden' : 'block'}`}>
        {label}
        {required ? <span className="text-primary">*</span> : null}
      </label>
      <div className="relative">
        {icon ? <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">{icon}</div> : ''}
        <div className="flex items-center">
          <textarea
            disabled={disabled}
            onBlur={onBlur}
            ref={ref}
            onChange={onChange}
            name={name}
            value={value}
            rows={5}
            data-testid={label.toLowerCase().replace(/\s+/g, '-')}
            placeholder={placeholder}
            className={cls(`
                      ${styles.base}
                      ${icon ? styles.icon : ''}
                      ${disabled ? styles.disabled : styles.enabled}
                      ${className}
                      `)}
            {...rest}
          />
          {toolTipInfo ? <ToolTip tipId={!tipId ? label : tipId} size="sm" position="relative ml-3">{toolTipInfo}</ToolTip> : null}
          {(!toolTipInfo) && (
            <span className="pr-8" />
          )}
        </div>
        {children}
        {error && <p className="form-error">{error}</p>}
      </div>
    </div>
  ),
)

TextArea.defaultProps = {
  tipId: undefined,
  icon: undefined,
  className: 'input',
  width: undefined,
  hideLabel: false,
  required: true,
  placeholder: undefined,
  toolTipInfo: undefined,
  options: undefined,
  onChange: undefined,
  rest: null,
  error: null,
  ref: null,
  onBlur: null,
  disabled: false,
  children: null,
  value: undefined,
}

export default TextArea
