import { useContext, useState, useEffect } from 'react'
import { ReportGraphDetail } from '../../api/imi/interfaces'
import { commaNumber, fixDateObj } from '../../helpers/utils'
import Content from '../layouts/Content'
import LoadingSpinner from '../LoadingSpinner'
import LineGraph from '../charts/LineGraph'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: ReportGraphDetail
}

const DisplayBudgetReport:React.FC<Props> = ({
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'
  const [reportData, setReportData] = useState([] as any)

  useEffect(() => {
    if (data && data.series.length) {
      setReportData(data.series[0].data.sort((a, b) => (a.x > b.x ? 1 : -1)))
    }
  }, [data])

  function tooltip(this: any) {
    const { point } = this as any

    return `<span style="color: #fff; font-family: 'Nunito Sans'; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point.category}</span>
        <span style="background-color: ${colors.primary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 0px 5px;">Spend: <b>$${commaNumber(point.y)}</b></span><br>`
  }

  return (
    <Content title="budget report">
      {!reportData ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <div id="chart-container-budget">
          {reportData?.length ? (
            <div id="budget-graph" className="pb-6">
              <LineGraph
                id="budget"
                name="Budget"
                labels={reportData?.map((d) => fixDateObj(d.x))}
                values={reportData?.map((d) => d.y)}
                tooltip={(point) => `<span style="color: #fff; font-family: 'Nunito Sans'; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point.category}</span>
                  <span style="background-color: ${colors.primary}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span style="color: #fff; font-family: 'Heebo'; padding: 10px 5px 0px 5px;">Spend: <b>$${commaNumber(point.y)}</b></span><br>`}
              />
            </div>
          ) : (
            <p>{noDataMessage}</p>
          )}
        </div>
      )}
    </Content>
  )
}

export default DisplayBudgetReport
