import { useContext, useEffect, useState } from 'react'
import Footer from '../navigation/Footer'
import NavBar from '../navigation/navbar'
import MainMasthead from '../masthead/MainMasthead'
import BackToTopButton from '../button/BackToTopButton'
import { AppContext } from '../../contexts/AppContext'

// Main layout component that renders nav and masthead outside of all content

const Layout: React.FC = ({ children }) => {
  const { user } = useContext(AppContext)
  const [userScrolled, setUserScrolled] = useState(false)
  // @ts-ignore
  // const navBars = document.querySelector('.nav-menu')

  // const test = () => {
  //   if (navBars) {
  //     navBars.setAttribute('class', 'hidden')
  //   }
  // }

  // console.log(userScrolled)

  // useEffect(() => {
  //   // @ts-ignore
  //   if (navBars) {
  //     window.addEventListener('scroll', test)
  //     setUserScrolled(true)
  //   }
  // }, [test, navBars])

  return (
    <>
      <NavBar />
      <MainMasthead />
      <main className={user.user_type_id ? '' : 'login-page'}>
        {children}
        <BackToTopButton />
      </main>
      {user.user_type_id ? (
        <Footer />
      ) : null}
    </>
  )
}

export default Layout
