import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import UserSettings from '../../../components/adminTools/accounts/user_settings/UserSettings'
import { IUserInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'

const UserSettingsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [users, setUsers] = useState<IUserInDb[]>([] as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllUsers = async () => {
      const response = await IMIApi.GetAllUsers(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setUsers(response.data.data)
      return setLoading(false)
    }
    fetchAllUsers()
    return () => abortController.abort()
  }, [refresh, setError])

  useEffect(() => {
    if (users.length > 0) {
      setLoading(false)
    }
  }, [users])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <UserSettings
            users={users}
            isLoading={loading}
            refresh={setRefresh}
          />
        )}
      />
    </Padding>
  )
}
export default UserSettingsPage
