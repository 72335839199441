import { PDF, FontSizes, FontStyle } from '../lib'
import { Totals } from '../data'
import * as sizes from '../sizes'

const CONTAINER_WIDTH = 1 / 3
const CONTAINER_HEIGHT = 60
const CONTAINER_MARGIN = 15
const ICON_GAP = 10
const ICON_SIZE = 30
const LINE_SPACE = 8

const getText = (pdf: PDF, data: Totals) => ({
  impressions: [
    pdf.text.drawText('impressions', pdf.colors.black, { style: FontStyle.Bold }),
    pdf.text.drawText(data.impressions, pdf.colors.black, { size: FontSizes.Large, style: FontStyle.Light }),
  ],
  clicks: [
    pdf.text.drawText('clicks', pdf.colors.black, { style: FontStyle.Bold }),
    pdf.text.drawText(data.clicks, pdf.colors.black, { size: FontSizes.Large, style: FontStyle.Light }),
  ],
  calls: [
    pdf.text.drawText('calls', pdf.colors.black, { style: FontStyle.Bold }),
    pdf.text.drawText(data.calls, pdf.colors.black, { size: FontSizes.Large, style: FontStyle.Light }),
  ],
  webEvents: [
    pdf.text.drawText('web events', pdf.colors.black, { style: FontStyle.Bold }),
    pdf.text.drawText(data.webEvents, pdf.colors.black, { size: FontSizes.Large, style: FontStyle.Light }),
  ],
  conversions: [
    pdf.text.drawText('conversions', pdf.colors.black, { style: FontStyle.Bold }),
    pdf.text.drawText(data.conversions, pdf.colors.black, { size: FontSizes.Large, style: FontStyle.Light }),
  ],
  leads: [
    pdf.text.drawText('leads', pdf.colors.black, { style: FontStyle.Bold }),
    pdf.text.drawText(data.leads, pdf.colors.black, { size: FontSizes.Large, style: FontStyle.Light }),
  ],
})

const drawTotal = (pdf: PDF, renderIcon: () => void, text: { width: number, height: number, render: () => void }[]) => {
  const containerWidth = (pdf.pages.pageWidth - sizes.SECTION_MARGIN * 2) * CONTAINER_WIDTH

  pdf.drawing.drawContainer(containerWidth, CONTAINER_HEIGHT)
  pdf.pages.moveRight(CONTAINER_MARGIN)
  pdf.pages.moveDown(CONTAINER_MARGIN)
  renderIcon()
  pdf.pages.moveRight(ICON_GAP + ICON_SIZE)
  text[0].render()
  pdf.pages.moveDown(text[0].height + LINE_SPACE)
  text[1].render()
}

export const drawTotals = (pdf: PDF, data: Totals) => {
  const text = getText(pdf, data)
  const containerWidth = (pdf.pages.pageWidth - sizes.SECTION_MARGIN * 2) * CONTAINER_WIDTH
  const row1 = pdf.pages.currentY
  const row2 = pdf.pages.currentY - CONTAINER_HEIGHT - sizes.SECTION_MARGIN
  const col1 = pdf.pages.currentX
  const col2 = pdf.pages.currentX + containerWidth + sizes.SECTION_MARGIN
  const col3 = pdf.pages.currentX + containerWidth * 2 + sizes.SECTION_MARGIN * 2
  
  drawTotal(pdf, () => pdf.icons.drawImpressionsIcon(ICON_SIZE), text.impressions)

  pdf.pages.moveTo(col2, row1)
  drawTotal(pdf, () => pdf.icons.drawClicksIcon(ICON_SIZE), text.clicks)

  pdf.pages.moveTo(col3, row1)
  drawTotal(pdf, () => pdf.icons.drawTotalCallsIcon(ICON_SIZE), text.calls)

  pdf.pages.moveTo(col1, row2)
  drawTotal(pdf, () => pdf.icons.drawTotalWebEventsIcon(ICON_SIZE), text.webEvents)

  pdf.pages.moveTo(col2, row2)
  drawTotal(pdf, () => pdf.icons.drawConversionsIcon(ICON_SIZE), text.conversions)

  pdf.pages.moveTo(col3, row2)
  drawTotal(pdf, () => pdf.icons.drawLeadsIcon(ICON_SIZE), text.leads)

  pdf.pages.moveTo(col1, row2)
  pdf.pages.moveDown(CONTAINER_HEIGHT + sizes.SECTION_MARGIN)
}
