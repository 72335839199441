import {
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react'
import {
  useForm,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form'
import { FlagIcon, BookmarkIcon } from '@heroicons/react/solid'
import ProcessCallsForm from '../../../form/calls/ProcessCallsForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import Icon from '../../../Icon'
import Badge from '../../../badge/Badge'
import { ICall } from '../../../../api/imi/interfaces'

interface Props{
  call: ICall
  callCategories: any
  index: number
}

const ProcessCallsFormProvider: React.FC<Props> = ({
  call,
  callCategories,
  index,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const { user, setError } = useContext(AppContext)
  const [processCall, setProcessCall] = useState<any>({} as any)
  const [showFlag, setShowFlag] = useState<boolean>(false)
  const [showHighlighted, setShowHighlighted] = useState<boolean>(false)

  useEffect(() => {
    setProcessCall(call)
  }, [call])

  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      is_highlighted: processCall?.is_highlighted,
      is_flagged: processCall?.is_flagged,
      category_id: processCall?.category_id,
      notes: processCall?.notes,
      id: processCall?.id,
      status_id: processCall?.status_id,
      category: {
        id: processCall?.category_id,
      },
    }), [processCall]),
  })

  useEffect(() => {
    methods.reset(processCall)
  }, [processCall, methods])

  const editUser = () => {
    setShowButton(true)
  }

  const fixDateObj = (date) => {
    const dateObj = new Date(date)
    const newDateObj = {
      month: dateObj.getMonth() + 0o1,
      day: dateObj.getDate(),
      year: dateObj.getFullYear(),
      hours: dateObj.getHours(),
      minutes: dateObj.getMinutes(),
    }
    return `${newDateObj.month}/${newDateObj.day}/${newDateObj.year}`
  }

  const formatHHMMSS = (s: number) => {
    let val = 0
    if (s) {
      val = s
    }
    return new Date(val * 1000).toISOString().substring(14, 19)
  }

  const fixTimeObj = (date) => {
    const dateObj = new Date(date)
    return `${dateObj.toLocaleTimeString('en-US')}`
  }

  // TODO: add back in cancel if on another page...
  const cancel = () => {
    methods.reset({ ...processCall })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const numberCalls = (indexOfCall) => {
    if (indexOfCall < 10) {
      return `0${indexOfCall + 0o1}`
    }
    return indexOfCall + 0o1
  }

  const onSubmit: SubmitHandler<any> = async (callData) => {
    const stringToBoolFlag = (callData.is_flagged === 'true')
    const stringToBoolHighlighted = (callData.is_highlighted === 'true')
    const formData = {
      ...callData,
      is_highlighted: user.user_type_id !== 4 ? stringToBoolHighlighted : callData.is_highlighted,
      is_flagged: stringToBoolFlag,
      notes: !callData.notes ? '' : callData.notes,
      status_id: 2,
      category_id: callData.category_id > 0 ? callData.category_id : callData.category.id,
    }
    setProcessCall(formData)
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.UpdateCallById(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        const parse = JSON.parse(submitResponse)
        setError({ errorMsg: parse.detail })
        setIsButtonLoading(false)
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  const daysIn = useMemo(() => (start_date) => {
    const todaysDate = new Date()
    const startD = new Date(start_date)
    const difference = todaysDate.getTime() - startD.getTime()
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
    return TotalDays
  }, [])
  // FIXME: again have to do this to prove the form state is valid to
  // trigger save button. WHY
  // console.log(methods.formState.isValid)

  useEffect(() => {
    // console.log(processCall.is_flagged)
    if (processCall.is_flagged) {
      setShowFlag(true)
    }
    setShowFlag(false)
  }, [processCall.is_flagged])

  useEffect(() => {
    if (processCall.is_highlighted) {
      setShowHighlighted(true)
    }
    setShowHighlighted(false)
  }, [processCall.is_highlighted])

  const chooseIcons = () => {
    let icon = ''
    if (processCall.is_flagged && !processCall.is_highlighted) {
      // @ts-ignore
      icon = (
        <Icon colors="red">
          <FlagIcon color="text-red" />
        </Icon>
      )
    } else if (processCall.is_highlighted && !processCall.is_flagged) {
      // @ts-ignore
      icon = (
        <Icon colors="primary">
          {/* NOTE: unsure why tailwinds won't accept correct color.
              this is brand-primary.
          */}
          <BookmarkIcon color="#40a4bc" />
        </Icon>
      )
    } else if (processCall.is_flagged && processCall.is_highlighted) {
      // @ts-ignore
      icon = (
        <>
          <Icon colors="primary">
            {/* NOTE: unsure why tailwinds won't accept correct color.
                this is brand-primary.
            */}
            <BookmarkIcon color="#40a4bc" />
          </Icon>
          <Icon colors="red">
            <FlagIcon color="text-red" />
          </Icon>
        </>
      )
    }
    // @ts-ignore
    return icon
  }

  return (
    <Content
      title={`call ${numberCalls(index)}`}
      variant={!showButton && (processCall.category_id > 0) ? 'success' : undefined}
      icon={chooseIcons()}
      key={processCall.id}
    >
      <Badge
        label={(
          <span>
            Date:
            &nbsp;
            <span className="font-bold">
              {fixDateObj(processCall.occurred_at)}
            </span>
          </span>
        )}
        variant="badgeOnly"
      />
      <Badge
        label={(
          <span>
            Occurred At:
            &nbsp;
            <span className="font-bold">
              {fixTimeObj(processCall.occurred_at)}
            </span>
          </span>
        )}
        variant="badgeOnly"
      />
      {(processCall?.contact?.phone_home || processCall.contact?.phone_work) && (
        <Badge
          label={(
            <span>
              Phone:
              &nbsp;
              <span className="font-bold">
                {`${processCall?.contact?.phone_work === null ? processCall?.contact?.phone_home : processCall?.contact?.phone_work}`}
              </span>
            </span>
          )}
          variant="badgeOnly"
        />
      )}
      <Badge
        label={(
          <span>
            Customer:
            &nbsp;
            <span className="font-bold">
              {(processCall?.contact?.last_name && processCall?.contact?.first_name) ? (
                `${processCall?.contact?.last_name}, ${processCall?.contact?.first_name}`
              ) : (
                processCall?.contact?.first_name || 'Unknown'
              )}
            </span>
          </span>
        )}
        variant="badgeOnly"
      />
      <Badge
        label={(
          <span>
            Duration:
            &nbsp;
            <span className="font-bold">
              {formatHHMMSS(processCall?.duration)}
            </span>
          </span>
        )}
        variant="badgeOnly"
      />
      {daysIn(processCall.occurred_at) < 91 && (
        <div className="flex items-center">
          <audio preload="none" controls className="my-6 w-full max-w-form">
            <source src={processCall?.recording_url} />
            <track
              default
              kind="captions"
              src=""
            />
          </audio>
          <span className="pr-8" />
        </div>
      )}
      <FormProvider {...methods} key={processCall?.id}>
        <form
          key={processCall?.id}
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <ProcessCallsForm
            errors={methods.formState.errors}
            callCategories={callCategories}
            showButton={showButton}
            callId={processCall}
          />
          <FormButtonGroup
            key={processCall.id}
            isButtonLoading={isButtonLoading}
            disabled={!methods.formState.isDirty && methods.formState.isValid}
            disabledCancel={!methods.formState.isDirty}
            showButton={showButton}
            onClickCancel={cancel}
            onClickEdit={editUser}
            btnText={btnText}
          />
        </form>
      </FormProvider>
    </Content>
  )
}

export default ProcessCallsFormProvider
