import { PDF, FontStyle, Font } from '../lib'
import * as sizes from '../sizes'

const CONTAINER_WIDTH = 1
const CONTAINER_HEIGHT = 60
const CONTAINER_MARGIN = 15
const ICON_GAP = 10
const ICON_SIZE = 30
const LINE_SPACE = 8

const getText = (pdf: PDF) => [
  pdf.text.drawText('want to know more?', pdf.colors.black, { style: FontStyle.Bold }),
  pdf.text.drawText('Log into the IMI Dashboard for additional metrics, interactive graphs, term definitions, and more!', pdf.colors.darkestGrey, { font: Font.Heebo }),
]

const drawTotal = (pdf: PDF, renderIcon: () => void, text: { width: number, height: number, render: () => void }[]) => {
  const containerWidth = pdf.pages.pageWidth * CONTAINER_WIDTH

  pdf.drawing.drawContainer(containerWidth, CONTAINER_HEIGHT)
  pdf.pages.moveRight(CONTAINER_MARGIN)
  pdf.pages.moveDown(CONTAINER_MARGIN)
  renderIcon()
  pdf.pages.moveRight(ICON_GAP + ICON_SIZE)
  text[0].render()
  pdf.pages.moveDown(text[0].height + LINE_SPACE)
  text[1].render()
}

export const drawWantToKnowMore = (pdf: PDF) => {
  if (CONTAINER_HEIGHT > pdf.pages.remainingHeight) {
    pdf.pages.nextPage()
  }

  const text = getText(pdf)
  const row1 = pdf.pages.currentY
  const col1 = pdf.pages.currentX

  drawTotal(pdf, () => pdf.drawing.drawLightBulbIcon(ICON_SIZE), text)
  
  pdf.pages.moveTo(col1, row1)
  pdf.pages.moveDown(CONTAINER_HEIGHT + sizes.SECTION_MARGIN)
}
