import { createContext, useMemo, useState } from 'react'

export type Colors = {
  primary: string
  primaryDark: string
  secondary: string
  tertiary: string
}

interface IThemeContext {
  colors: Colors
  setColors: (colors: Colors) => void
}

export function useThemeContextFactory() {
  const [colors, setColors] = useState<Colors>({
    primary: '#40a4bc',
    primaryDark: '#3a7e99',
    secondary: '#29b48b',
    tertiary: '#1c4968',
  })

  if (colors.primary) {
    document.documentElement.style.setProperty('--color-primary', colors.primary)
  }

  if (colors.primaryDark) {
    document.documentElement.style.setProperty('--color-primary-dark', colors.primaryDark)
  }

  if (colors.secondary) {
    document.documentElement.style.setProperty('--color-secondary', colors.secondary)
  }

  if (colors.tertiary) {
    document.documentElement.style.setProperty('--color-tertiary', colors.tertiary)
  }

  return {
    colors,
    setColors,
  } as IThemeContext
}

export const ThemeContext = createContext<IThemeContext>({} as any)
