import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../contexts/UserContext'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import CreateUser from '../../../components/adminTools/accounts/user_settings/CreateUser'
import EditUser from '../../../components/adminTools/accounts/user_settings/EditUser'
import Padding from '../../../components/layouts/Padding'
import { newCampaignInDb } from '../../../api/imi/factories'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { AppContext } from '../../../contexts/AppContext'
import CreateCampaign from '../../../components/adminTools/campaigns/campaign_settings/create/CreateCampaign'
import { IAdvertiserInDb, ICampaignTypeInDb, ICampaignInDb } from '../../../api/imi/interfaces'
import { CampaignContext } from '../../../contexts/CampaignContext'
import EditCampaign from '../../../components/adminTools/campaigns/campaign_settings/EditCampaign'
import ChildCampaignCTA from '../../../components/adminTools/campaigns/campaign_settings/create/ChildCampaignCTA'

// NOTE: this page is for creating a new campaign without necessarily having pcampaigns inside.
// NOTE: Phase 2 implementation

const CreateCampaignPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [allAdvertisers, setAllAdvertisers] = useState<IAdvertiserInDb[]>([])
  const [allCampaignTypes, setAllCampaignTypes] = useState<ICampaignTypeInDb[]>([])
  const [campaignId, setCampaignId] = useState(null)
  const [serverResponse, setServerResponse] = useState<any>([])
  const { setError } = useContext(AppContext)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)

  useEffect(() => {
    const newCampaign = setCampaignInDb(newCampaignInDb() as ICampaignInDb)
    return newCampaign
  }, [setCampaignInDb])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllAdvertisers = async () => {
      const response = await IMIApi.GetAllAdvertisers(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAllAdvertisers(response.data.data)
      return setLoading(false)
    }
    fetchAllAdvertisers()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllCampaignTypes = async () => {
      const response = await IMIApi.GetCampaignTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      console.log(response.data.data)
      setAllCampaignTypes(response.data.data)
      return setLoading(false)
    }
    fetchAllCampaignTypes()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    if (campaignInDb?.category?.id > 0) {
      setLoading(true)
      const fetchAllCampaignTypes = async () => {
        const response = await IMIApi.GetCampaignTypeById(campaignInDb?.category?.id, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setAllCampaignTypes(response.data)
        return setLoading(false)
      }
      fetchAllCampaignTypes()
    }
    return () => abortController.abort()
  }, [setError, campaignInDb?.category?.id])

  // useEffect(() => {
  //   if (campaignId !== null) {
  //     const fetchUser = async () => {
  //       const response = await IMIApi.GetUserById(campaignId)
  //       setServerResponse(response)
  //       if (response.status !== 200) {
  //         const parse = JSON.parse(response)
  //         setError({ errorMsg: parse.detail })
  //         return setLoading(false)
  //       }
  //       return setLoading(false)
  //     }
  //     fetchUser()
  //   }
  // }, [campaignId, setError])

  useEffect(() => {
    setCampaignId(null)
  }, [])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <>
            {campaignInDb?.id > 0 ? (
              <>
                <EditCampaign
                  allAdvertisers={allAdvertisers}
                  allCampaignTypes={allCampaignTypes}
                  isLoading={loading}
                  edit={false}
                />
                {campaignInDb.type.id !== 2 && (
                  <ChildCampaignCTA />
                )}
              </>
            ) : (
              <>
                <CreateCampaign
                  allAdvertisers={allAdvertisers}
                  allCampaignTypes={allCampaignTypes}
                  isLoading={loading}
                />
                <ChildCampaignCTA />
              </>
            )}
          </>
        )}
      />
    </Padding>
  )
}
export default CreateCampaignPage
