import {
  useEffect, useState, useContext, useCallback,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import variablePie from 'highcharts/modules/variable-pie'
import ToolTip from '../ToolTip'
import { commaNumber } from '../../helpers/utils'
import Content from '../layouts/Content'
import Icon from '../Icon'
import SidebarRight from '../layouts/SidebarRight'
import HorizontalGrid from '../layouts/HorizontalGrid'
import BadgeDot from '../badge/BadgeDot'
import { ISemDetail } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import { ReportContext } from '../../contexts/ReportContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// TODO: how to handle only 1 item returned? breaks map not sure why

const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute right-6',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// "table" header for daily report details
const title = [
  {
    fieldName: 'keyword',
    name: 'Keyword',
    mobile: false,
  },
  {
    fieldName: 'impressions',
    name: 'Impressions',
    abbreviation: 'Impr',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'clicks',
    name: 'Clicks',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'ctr',
    name: 'Click-Through Rate (CTR)',
    abbreviation: 'CTR',
    mobile: false,
    sort: true,
  },
]

interface Props {
  data: ISemDetail
}

const SemKeywords: React.FC<Props> = ({ data }) => {
  const { colors } = useContext(ThemeContext)
  const { parameters } = useContext(ReportContext)
  // data coming in from SEM report page
  const [impressions, setImpressions] = useState<any>([] as any)
  const [keywords, setKeywords] = useState<any>([] as any)
  const [loading, setLoading] = useState<boolean>(true)
  // different limits on number of items shown based on user type (admin or other)
  const [pageLimit, setPageLimit] = useState(0)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('clicks')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [chart, setChart] = useState<any>()
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')

  useEffect(() => {
    if (parameters.end_date) {
      const endDateToString = new Date(parameters.end_date)
      const today = new Date(new Date().setUTCHours(0, 0, 0, 0))
      if (today > endDateToString) {
        return setNoDataMessage('')
      }
    }
  }, [parameters.end_date])

  variablePie(Highcharts)

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = sortDirection ? keywords.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : keywords.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }
  Highcharts.setOptions({
    lang: {
      printChart: 'Aaaa',
      thousandsSep: ',',
    },
  })
  const buildChart = useCallback((seriesData: any []) => ({
    chart: {
      type: 'pie',
      height: 300,
    },
    title: {
      text: '<span style="font-family: Nunito Sans; font-size: 14px; font-weight: 600;">Impressions</span>',
      y: 160,
      float: true,
    },
    tooltip: {
      useHtml: true,
      headerFormat: '<br>',
      pointFormat: `<span style="color:{point.color}; font-size: 22px; vertical-align:middle;">\u25CF</span>&nbsp;<span style="color: #ffffff; font-family: Nunito Sans;font-size: 14px; font-weight: bold; padding: 10px 5px 10px 5px; display: block;">{point.name}</span><br y="28"/>
                    <span style="color: #fff; font-family: Heebo; padding: 10px 0px 10px 5px; margin: 10px">Impressions: <b>{point.y}</b></span><br y="48"/>
                    <span style="color: #fff; font-family: Heebo; padding: 10px 0px 10px 5px;">% of Total: <b>{point.totalPercent}%</b></span><br/>`,
    },
    series: [{
      borderWidth: 2,
      minPointSize: 10,
      innerSize: '75%',
      slicedOffset: 0,
      size: '100%',
      zMin: 0,
      name: 'Device Types',
      dataLabels: {
        enabled: false,
        distance: '-13',
        useHtml: true,
        format: '{point.totalPercent}%',
        crop: true,
        allowOverlap: false,
        connectorWidth: 0,
        style: {
          textOutline: 'none',
          fontFamily: 'Heebo',
          fontSize: '11px',
          color: '#fff',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      point: {
        /* eslint-disable */
        events: {
          mouseOver: function() {
            // @ts-ignore
            this.graphic.attr({
              // @ts-ignore
              r: this.shapeArgs.r + 10
            })
          },
          mouseOut: function() {
            // @ts-ignore
            this.graphic.attr({
              // @ts-ignore
              r: this.shapeArgs.r
            })
          },
        },
        /* eslint-enable */
      },
      states: {
        hover: {
          brightness: 0,
          opacity: 1,
          borderWidth: 0,
          halo: {
            size: 0,
            // opacity: 1,
            // stroke: 1,
          },
        },
        inactive: {
          opacity: 0.7,
        },
      },
      data: seriesData,
    }],
  }), [])

  useEffect(() => {
    setImpressions(data?.device_impressions)
    // @ts-ignore
    setKeywords(data?.keywords)
    // @ts-ignore
    setKeywords(data?.keywords?.rows?.sort((a, b) => (a.clicks > b.clicks ? -1 : 1)).slice(0, 10))
  }, [data])

  useEffect(() => {
    if (impressions?.series?.length) {
      setLoading(false)
      const colorMap = {
        Desktop: colors.primary,
        Mobile: colors.secondary,
        Tablet: colors.tertiary,
      }
      const totalImpressions = impressions?.callouts?.map((value) => (value.name === 'Total Impressions' ? value.value : 0))

      const seriesData = impressions.series.map((s) => ({
        name: s.y,
        y: s.x,
        totalPercent: Math.round((s.x / totalImpressions) * 100),
        color: colorMap[s.y],
      }))
      setChart(buildChart(seriesData))
    }
  }, [colors, buildChart, impressions, setChart])

  // const handleLoadMore = () => {
  //   setPageLimit(pageLimit + (impressions.length - pageLimit))
  //   if (user.user_type_id === 'ADMIN') {
  //     setPageLimit(pageLimit + (impressions.length - pageLimit))
  //   }
  // }

  return (
    <SidebarRight
      mainContent={(
        <Content
          title="Top Performing Keywords"
          padding={false}
          minHeight="lg:min-h-metric-sidebar-3"
          icon={(
            <ToolTip
              tipId="topkeywords"
              size="sm"
              position="block relative pr-0"
            >
              Top performing keywords include data from all publishers.
            </ToolTip>
          )}
        >
          {loading ? <div className="px-4 md:px-6 pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
            <>
              <HorizontalGrid
                smGrid="grid-cols-small-4"
                mdGrid="md:grid-cols-keywords"
                header
              >
                {title.map((item) => (
                  <div key={item.name} className={`break-all group flex center-items ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}>
                    {item.sort ? (
                      <span
                        aria-hidden="true"
                        onClick={() => columnSortingInfo(item.fieldName)}
                        key={item.name}
                      >
                        <span className="inline md:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                        <span className="hidden md:inline">{item.name}</span>
                        <Icon
                          size="xxs"
                          className="text-default group sm:pr-0 ml-1 -mt-1"
                        >
                          <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                          <ChevronDownIcon className="text-default group-hover:text-secondary" />
                        </Icon>
                      </span>
                    ) : <span key={item.name}>{item.name}</span>}
                  </div>
                ))}
              </HorizontalGrid>
              <div>
                {keywords.length > 0 ? (
                  keywords.map((keyword) => (
                    <HorizontalGrid
                      key={keyword.keyword + keyword.impressions}
                      smGrid="grid-cols-small-4"
                      mdGrid="md:grid-cols-keywords"
                      backgroundColor
                    >
                      <p className="small">
                        {keyword.keyword}
                      </p>
                      <p className="small">
                        {commaNumber(keyword.impressions)}
                      </p>
                      <p className="small">
                        {commaNumber(keyword.clicks)}
                      </p>
                      <p className="small">
                        {keyword.ctr}
                        %
                      </p>
                    </HorizontalGrid>
                  ))
                ) : (
                  <p className="px-4 md:px-6">{noDataMessage}</p>
                )}
              </div>
            </>
          )}
        </Content>
      )}
      sidebarContent={(
        <Content
          title="device type"
          icon={(
            <ToolTip
              tipId="devicetype"
              size="sm"
              position="block relative pr-0"
            >
              Due to user privacy settings, device type information may not be available for all impressions.
            </ToolTip>
          )}
        >
          {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
            <>
              {impressions?.series?.length > 0 ? (
                <>
                  <div className="-mt-6">
                    <HighchartsReact
                      id="device_report"
                      highcharts={Highcharts}
                      options={chart}
                    />
                  </div>
                  <div>
                    {/* <HorizontalGrid
                      smGrid="grid-cols-2"
                      mdGrid="md:grid-cols-2"
                      border
                      padding={false}
                    >
                      <BadgeDot
                        label={impressions?.series[0]?.y}
                        variant="dot"
                        badgeBackground="dotOnly"
                        dotColor="bg-primary"
                      />
                      <span className="text-right">{commaNumber(impressions?.series[0]?.x)}</span>
                    </HorizontalGrid> */}
                    <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                      <div className="min-w-0 text-left heading-5 font-semibold m-0">
                        <div className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3">
                          <BadgeDot
                            label={impressions?.series[0]?.y}
                            variant="dot"
                            badgeBackground="dotOnly"
                            dotColor="bg-primary"
                          />
                          <span className="text-right">{commaNumber(impressions?.series[0]?.x)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                      <div className="min-w-0 text-left heading-5 font-semibold m-0">
                        <div className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3">
                          <BadgeDot
                            label={impressions?.series[1]?.y}
                            variant="dot"
                            badgeBackground="dotOnly"
                            dotColor="bg-secondary"
                          />
                          <span className="text-right">{commaNumber(impressions?.series[1]?.x)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="horizontal-grid relative pb-0 -mt-3 mb-1">
                      <div className="min-w-0 text-left heading-5 font-semibold m-0">
                        <div className="break-words flex-1 md:flex-row min-w-0 grid grid-cols-2 md:grid-cols-2 md:gap-4 mb-0 text-left heading-5 font-semibold border-t border-t-1 border-grey-dark border-dotted pt-6 pb-3">
                          <BadgeDot
                            label={impressions?.series[2]?.y}
                            variant="dot"
                            badgeBackground="dotOnly"
                            dotColor="bg-tertiary"
                          />
                          <span className="text-right">{commaNumber(impressions?.series[2]?.x)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center pb-1">
                    <span aria-hidden="true" className="block px-3 py-2 rounded-lg text-xs bg-grey-lightest text-default mb-3 font-display">
                      Total Impressions:&nbsp;
                      <span className="font-bold">{commaNumber(impressions?.callouts?.map((value) => (value.name === 'Total Impressions' ? value.value : 0)))}</span>
                    </span>
                  </div>
                </>
              ) : (
                <p>{noDataMessage}</p>
              )}
            </>
          )}
        </Content>
      )}
    />
  )
}
export default SemKeywords
