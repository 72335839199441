import { useContext, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Content from '../layouts/Content'
import ToolTip from '../ToolTip'
import { commaNumber } from '../../helpers/utils'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  title: string,
  totalImpressions: number,
  items: {
    label: string,
    value: number,
  }[],
}

const HorizontalBarChart: React.FC<Props> = ({
  title,
  items,
  totalImpressions,
}) => {
  const { colors } = useContext(ThemeContext)
  const [hoveredRow, setHoveredRow] = useState(-1)

  return (
    <Content
      title={title}
      padding={false}
    >
      <div className="flex flex-col divide-y divide-dashed -mt-6">
        {items
          .filter((item) => item.value > 0)
          .sort((a, b) => b.value - a.value)
          .map((item, i, sorted) => (
            <div
              data-tip
              data-for={item.label}
              key={item.label}
              className="flex-col flex-1 border-grey-light mx-4 md:mx-6 py-3"
              onMouseEnter={() => setHoveredRow(i)}
              onMouseLeave={() => setHoveredRow(-1)}
              style={{
                opacity: hoveredRow < 0 || hoveredRow === i ? '1' : '0.7',
              }}
            >
              <ReactTooltip 
                place="top" 
                id={item.label} 
                clickable 
                effect="solid" 
                className="leading-4.5 font-base font-normal break-normal" 
                backgroundColor="rgb(34 28 34 / 90%)"
                offset={{ top: -50 }}
              >
                <span style={{ 
                  color: '#fff', fontFamily: 'Nunito Sans', fontSize: '14px', fontWeight: 'bold', padding: '0px 0px 5px 0px', display: 'block',
                }}
                >
                  {item.label}
                </span>

                <span style={{ 
                  backgroundColor: colors.secondary, height: '10px', width: '10px', borderRadius: '50%', padding: '0px 0px 5px 0px', display: 'inline-block',
                }}
                />

                <span style={{ color: '#fff', fontFamily: 'Heebo', padding: '0px 5px 0px 5px' }}>
                  Impressions:
                  <b>
                    &nbsp;
                    {commaNumber(item.value)}
                  </b>
                </span>
                <br />

                <span style={{ 
                  backgroundColor: '#a7b1ae', height: '10px', width: '10px', borderRadius: '50%', display: 'inline-block',
                }}
                />
                <span style={{ color: '#fff', fontFamily: 'Heebo', padding: '0px 5px 0px 5px' }}>
                  % of Total:
                  <b>
                    &nbsp;
                    {(((item.value) / totalImpressions) * 100).toFixed(0)}
                    %
                  </b>
                </span>
              </ReactTooltip>
              <span className="heading-5">
                {item.label}
              </span>
              <div className="flex gap-1">
                {item.value > 0 && (
                  <div
                    // data-tip
                    // data-for={item.label}
                    style={{
                      width: `${((item.value / sorted[0].value) * 100) < 1 ? 1 : ((item.value / sorted[0].value) * 100)}%`,
                    }}
                    className="self-center bg-secondary h-2 rounded-sm w-1/8 inline-block mr-1 relative"
                  />
                )}
                <span className="small">
                  {commaNumber(item.value)}
                </span>
              </div>
            </div>
          ))}
      </div>
    </Content>
  )
}

export default HorizontalBarChart
