import { useContext, useState, useEffect } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { ReactComponent as BackgroundIcon } from '../assets/icons/circle-bg.svg'
import { navigationMenu } from '../components/navigation/NavItems'
import Button from '../components/button/Button'
import Icon from '../components/Icon'
import Content from '../components/layouts/Content'
import HorizontalGrid from '../components/layouts/HorizontalGrid'
import Padding from '../components/layouts/Padding'
import NavLinkWrapper from '../components/Links'
import { IMIApi } from '../api/imi/api'
import { AppContext } from '../contexts/AppContext'

const AdminToolsPage:React.FC = () => {
  const { user, setError } = useContext(AppContext)
  const [loadingUnassigned, setLoadingUnassigned] = useState(true)
  const [unassignedCampaigns, setUnassignedCampaigns] = useState<any[]>([] as any)

  useEffect(() => {
    const abortController = new AbortController()
    if (user.user_type_id === 1) {
      const fetchUnassignedCampaigns = async () => {
        const response = await IMIApi.GetAllPCampaigns(0, 0, true, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoadingUnassigned(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoadingUnassigned(false)
        }
        // NOTE: should update this when getting new campaign types
        const onlySemUnassignedCampaigns = response?.data?.data.filter((campType) => campType.type_id === 2)
        setUnassignedCampaigns(onlySemUnassignedCampaigns)
        // setUnassignedCampaigns(response.data)
        return setLoadingUnassigned(false)
      }
      fetchUnassignedCampaigns()
    }
    return () => abortController.abort()
  }, [user, setError])

  return (
    <Padding>
      {user.user_type_id === 1 && (
        <>
          <Content title="accounts" padding={false}>
            {navigationMenu.map((nav) => (
              nav.accounts?.map((item) => (
                <div key={item.url}>
                  <ul className="">
                    {item.routes.map((route) => (
                      <HorizontalGrid
                        key={route.url}
                        border
                        padding={false}
                        justifyContent="justify-between"
                        button={(
                          <NavLinkWrapper variant="default" href={route.url} className="block pb-6">
                            <Button id={item.title} variant="primary" size="xs" submit="button" className="h-10"><ChevronRightIcon fill="white" className="h-4 w-4" /></Button>
                          </NavLinkWrapper>
                        )}
                      >
                        <div className="block md:flex md:pr-6 items-start relative">
                          <div className="inline-flex mb-4 md:flex items-center justify-center">
                            <BackgroundIcon className="max-w-icon w-20" />
                            <Icon size="nav" colors="light" className="absolute">
                              {route.icon}
                            </Icon>
                          </div>
                          <div className="mb-4 md:pl-6 sm:pr-6">
                            <h5 className="mb-3">{route.title}</h5>
                            <p className="text-xxs">{route.description}</p>
                          </div>
                        </div>
                      </HorizontalGrid>
                    ))}
                  </ul>
                </div>
              ))
            ))}
          </Content>
          <Content title="Advertisers" padding={false}>
            {navigationMenu.map((nav) => (
              nav.advertisers?.map((item) => (
                item.routes.map((route) => (
                  <HorizontalGrid
                    key={route.url}
                    border
                    padding={false}
                    justifyContent="justify-between"
                    button={(
                      <NavLinkWrapper variant="default" href={route.url} className="block pb-6">
                        <Button id={item.title} variant="primary" size="xs" submit="button" className="h-10"><ChevronRightIcon fill="white" className="h-4 w-4" /></Button>
                      </NavLinkWrapper>
                    )}
                  >
                    <div className="block md:flex md:pr-6 items-start relative">
                      <div className="inline-flex mb-4 md:flex items-center justify-center">
                        <BackgroundIcon className="max-w-icon w-20" />
                        <Icon size="nav" colors="light" className="absolute">
                          {route.icon}
                        </Icon>
                      </div>
                      <div className="mb-4 md:pl-6 sm:pr-6">
                        <h5 className="mb-3">{route.title}</h5>
                        <p className="text-xxs">{route.description}</p>
                      </div>
                    </div>
                  </HorizontalGrid>
                ))
              ))
            ))}
          </Content>
          <Content title="Campaigns" padding={false}>
            {navigationMenu.map((nav) => (
              nav.campaigns?.map((item) => (
                item.routes.map((route) => (
                  <HorizontalGrid
                    key={route.url}
                    border
                    padding={false}
                    justifyContent="justify-between"
                    button={(
                      <NavLinkWrapper variant="default" href={route.url} className="block pb-6">
                        <Button id={item.title} variant="primary" size="xs" submit="button" className="h-10"><ChevronRightIcon fill="white" className="h-4 w-4" /></Button>
                      </NavLinkWrapper>
                    )}
                  >
                    <div className="block md:flex md:pr-6 items-start relative">
                      <div className="inline-flex mb-4 md:flex items-center justify-center">
                        <BackgroundIcon className="max-w-icon w-20" />
                        <Icon size="nav" colors="light" className="absolute">
                          {(unassignedCampaigns.length > 0 && route?.icon_alert2) ? route?.icon_alert2 : route.icon}
                        </Icon>
                      </div>
                      <div className="mb-4 md:pl-6 sm:pr-6">
                        <h5 className="mb-3">{route.title}</h5>
                        <p className="text-xxs">{route.description}</p>
                      </div>
                    </div>
                  </HorizontalGrid>
                ))
              ))
            ))}
          </Content>
        </>
      )}
      <Content title="Calls" padding={false}>
        {navigationMenu.map((nav) => (
          nav.calls?.map((item) => (
            item.routes.map((route) => (
              !route.access.includes(user.user_type_id) ? (
                null
              ) : (
                <HorizontalGrid
                  key={route.url}
                  border
                  padding={false}
                  justifyContent="justify-between"
                  button={(
                    <NavLinkWrapper variant="default" href={route.url} className="block pb-6">
                      <Button id={item.title} variant="primary" size="xs" submit="button" className="h-10"><ChevronRightIcon fill="white" className="h-4 w-4" /></Button>
                    </NavLinkWrapper>
                  )}
                >
                  <div className="block md:flex md:pr-6 items-start relative">
                    <div className="inline-flex mb-4 md:flex items-center justify-center">
                      <BackgroundIcon className="max-w-icon w-20" />
                      <Icon size="nav" colors="light" className="absolute">
                        {route.icon}
                      </Icon>
                    </div>
                    <div className="mb-4 md:pl-6 sm:pr-6">
                      <h5 className="mb-3">{route.title}</h5>
                      <p className="text-xxs">{route.description}</p>
                    </div>
                  </div>
                </HorizontalGrid>
              )
            ))
          ))
        ))}
      </Content>
      {user.user_type_id === 1 && (
        <>
          <Content title="API" padding={false}>
            {navigationMenu.map((nav) => (
              nav.api?.map((item) => (
                item.routes.map((route) => (
                  <HorizontalGrid
                    key={route.url}
                    border
                    padding={false}
                    justifyContent="justify-between"
                    button={(
                      <NavLinkWrapper variant="default" href={route.url} className="block pb-6">
                        <Button id={item.title} variant="primary" size="xs" submit="button" className="h-10"><ChevronRightIcon fill="white" className="h-4 w-4" /></Button>
                      </NavLinkWrapper>
                    )}
                  >
                    <div className="block md:flex md:pr-6 items-start relative">
                      <div className="inline-flex mb-4 md:flex items-center justify-center">
                        <BackgroundIcon className="max-w-icon w-20" />
                        <Icon size="nav" colors="light" className="absolute">
                          {route.icon}
                        </Icon>
                      </div>
                      <div className="mb-4 md:pl-6 sm:pr-6">
                        <h5 className="mb-3">{route.title}</h5>
                        <p className="text-xxs">{route.description}</p>
                      </div>
                    </div>
                  </HorizontalGrid>
                ))
              ))
            ))}
          </Content>
          {/* <Content title="Other" padding={false}>
            {navigationMenu.map((nav) => (
              nav.other?.map((item) => (
                item.routes.map((route) => (
                  <HorizontalGrid
                    key={route.url}
                    border
                    padding={false}
                    justifyContent="justify-between"
                    button={(
                      <NavLinkWrapper variant="default" href={route.url} className="block pb-6">
                        <Button id={item.title} variant="primary" size="xs" submit="button" className="h-10"><ChevronRightIcon fill="white" className="h-4 w-4" /></Button>
                      </NavLinkWrapper>
                    )}
                  >
                    <div className="block md:flex md:pr-6 items-start relative">
                      <div className="inline-flex mb-4 md:flex items-center justify-center">
                        <BackgroundIcon className="max-w-icon w-20" />
                        <Icon size="nav" colors="light" className="absolute">
                          {route.icon}
                        </Icon>
                      </div>
                      <div className="mb-4 md:pl-6 sm:pr-6">
                        <h5 className="mb-3">{route.title}</h5>
                        <p className="text-xxs">{route.description}</p>
                      </div>
                    </div>
                  </HorizontalGrid>
                ))
              ))
            ))}
          </Content> */}
        </>
      )}
    </Padding>
  )
}

export default AdminToolsPage
