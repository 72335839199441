import {
  useState,
  useContext,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import { ICampaignType, ICampaignTypeInDb } from '../../../../api/imi/interfaces'
import CampaignTypeForm from '../../../form/campaigns/CampaignTypeForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'

interface Props {
  setCampaignTypeId: Dispatch<SetStateAction<number>>
}

const CreateCampaignType:React.FC<Props> = ({ setCampaignTypeId }) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)

  const methods = useForm<ICampaignType>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      specifics: [
        {
          name: '',
          id: 0,
        },
      ],
    }), []),
  })

  const cancel = () => {
    methods.reset()
    setTimeout(() => {
      setIsButtonLoading(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<ICampaignType> = async (data) => {
    const formData = {
      ...data,
      specifics: data.specifics.map((specific) => specific.name),
    }
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.CreateNewCampaignType(formData)
    setTimeout(() => {
      if (submitResponse.status && submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setCampaignTypeId(submitResponse?.data?.id)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
  }
  return (
    <Content
      title="campaign type details"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <CampaignTypeForm
            errors={methods.formState.errors}
            showButton
          />
          <FormButtonGroup
            isButtonLoading={isButtonLoading}
            disabled={!methods.formState.isValid}
            disabledCancel={!methods.formState.isDirty}
            onClickCancel={cancel}
            btnText={btnText}
            showButton
          />
        </form>
      </FormProvider>
    </Content>
  )
}

export default CreateCampaignType
