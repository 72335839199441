import { cls } from '../../helpers/utils'
import Button from './Button'

// TODO: implement this so it's easier to pass in vaules
// for form submission and keep edit/save/cancel buttons in one place

interface Props {
  showButton?: boolean
  btnText: string
  disabled: any
  isButtonLoading: boolean
  onClickEdit?: () => void
  onClickCancel?: any
  disabledCancel: any
  disabledEdit?: boolean
  fullWidth?: boolean
}

const FormButtonGroup:React.FC<Props> = ({
  showButton,
  btnText,
  disabled,
  isButtonLoading,
  onClickEdit,
  onClickCancel,
  disabledCancel,
  fullWidth,
  disabledEdit,
}) => (
  !showButton && onClickEdit ? (
    <Button
      id="edit-user"
      size="sm"
      variant="primary"
      disabled={disabledEdit}
      onClick={onClickEdit}
    >
      Edit
    </Button>
  ) : (
    <>
      {(onClickCancel && showButton) && (
        <Button
          disabled={disabledCancel}
          id="cancel-btn"
          size="sm"
          variant="secondary"
          className="mr-6"
          onClick={onClickCancel}
        >
          Cancel
        </Button>
      )}
      {showButton && (
        <Button
          disabled={disabled}
          id="save-btn"
          submit="submit"
          size="sm"
          variant="primary"
          isLoading={isButtonLoading}
          className={cls(`
            ${isButtonLoading ? 'cursor-not-allowed' : ''}
            ${fullWidth ? 'md:max-w-login-form' : ''}
          `)}
        >
          {btnText}
        </Button>
      )}
    </>
  )
)

export default FormButtonGroup
