import { useState, useEffect, useCallback } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IMIApi } from '../../../../api/imi/api'
import Search from '../../../form/Search'
import Content from '../../../layouts/Content'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import LinkWrapper from '../../../Links'
import EditMenu from '../../EditMenu'
import Icon from '../../../Icon'
import BadgeAlert from '../../../badge/BadgeAlert'
import { emailTemplateMenu, accountsSettingsStyles } from '../user_settings/Data'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import CreateButtons from '../../../button/CreateButtons'
import { handleFilters } from '../../../../helpers/utils'
import { IEmailTemplateInDb, IEmailTemplateMultiResponse } from '../../../../api/imi/interfaces'
import LoadingCheck from '../../../../hoc/LoadingCheck'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  isLoading: boolean
  emailTemplates: IEmailTemplateMultiResponse
}

const EmailTemplates:React.FC<Props> = ({
  emailTemplates,
  isLoading,
}) => {
  const [error, setError] = useState('There are no email templates found.')
  const [filteredEmailTemplates, setFilteredEmailTemplates] = useState<IEmailTemplateInDb []>(emailTemplates.data)
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
  })

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = !sortDirection ? filteredEmailTemplates.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : filteredEmailTemplates.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }

  const handleLocalStorage = (id) => {
    localStorage.setItem('email_template_id', JSON.stringify(id))
  }

  const filters = useCallback(() => {
    const filterSearchValue = (templateData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const emailTemplateNameFilter = templateData.name.toLowerCase().includes(splitItems)
      if (splitItems.length > 1) {
        const emailTemplateNameMultipleFilter = splitItems.some((si) => templateData.name.toLowerCase().includes(si))
        return emailTemplateNameMultipleFilter
      }
      return emailTemplateNameFilter
    }
    const filterData = emailTemplates?.data?.filter((template) => filterSearchValue(template))
    setFilteredEmailTemplates(filterData)
  }, [selectedFilters, emailTemplates])

  useEffect(() => {
    setFilteredEmailTemplates(emailTemplates.data?.sort((a, b) => (b.name > a.name ? 1 : -1)))
    filters()
  }, [emailTemplates, filters])

  return (
    <>
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search"
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Template"
            path="/admin-tools/email-templates/create-email-template"
          />
        </div>
      </div>
      <Content
        title="Email Templates"
        padding={false}
        badge={(
          <BadgeAlert
            alert={filteredEmailTemplates?.length}
            status="running"
          />
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-2"
              mdGrid="md:grid-cols-2"
            >
              {emailTemplateMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(`
                      flex break-word group hover:text-secondary 
                      hover:cursor-pointer${item.mobile ? accountsSettingsStyles.desktop : ''}
                    `)}
                  >
                    <span>
                      {item.name}
                    </span>
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : item.name
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredEmailTemplates.length === 0 ? <p className="error px-6">{error}</p> : (
                filteredEmailTemplates.map((template) => (
                  <HorizontalGrid
                    key={template.id}
                    smGrid="grid-cols-2"
                    mdGrid="md:grid-cols-2"
                    backgroundColor
                  >
                    <p className="text-xxs text-gray-500">
                      <LinkWrapper
                        variant="defaultSm"
                        onClick={() => handleLocalStorage(template.id)}
                        href={{
                          pathname: '/admin-tools/email-templates/edit-email-template',
                        }}
                      >
                        {template.name}
                      </LinkWrapper>
                    </p>
                    <img
                      src={template.logo_url}
                      alt={`${template.name} logo`}
                      className="max-w-btn-md w-full pr-6"
                    />
                    <EditMenu
                      text="Edit Template"
                      pathname="/admin-tools/email-templates/edit-email-template"
                      id={template.id}
                      status={false}
                      onClick={() => handleLocalStorage(template.id)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default EmailTemplates
