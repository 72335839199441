import { useState, useEffect, useCallback } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import Search from '../../../form/Search'
import Content from '../../../layouts/Content'
import BadgeAlert from '../../../badge/BadgeAlert'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import Icon from '../../../Icon'
import LinkWrapper from '../../../Links'
import { dashboardBrandingTemplateMenu, accountsSettingsStyles } from '../user_settings/Data'
import EditMenu from '../../EditMenu'
import { handleFilters } from '../../../../helpers/utils'
import CreateButtons from '../../../button/CreateButtons'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import { IBrandingDetailInDb, IBrandingMultiResponse } from '../../../../api/imi/interfaces'
import LoadingCheck from '../../../../hoc/LoadingCheck'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  branding: IBrandingMultiResponse
  isLoading: boolean
}

const DashboardBranding:React.FC<Props> = ({
  branding,
  isLoading,
}) => {
  const [filteredBranding, setFilteredBranding] = useState<IBrandingDetailInDb[]>(branding.data)
  const [error, setError] = useState('There is no branding found.')
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
  })

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = sortDirection ? filteredBranding.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1)) : filteredBranding.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }
  const filters = useCallback(() => {
    const filterSearchValue = (templateData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const brandingNameFilter = templateData.email_template.name.toLowerCase().includes(splitItems)
      if (splitItems.length > 1) {
        const brandingMultipleFilter = splitItems.some((si) => templateData.email_template.name.toLowerCase().includes(si))
        return brandingMultipleFilter
      }
      return brandingNameFilter
    }
    const filterData = branding?.data?.filter((template) => filterSearchValue(template))
    setFilteredBranding(filterData)
  }, [selectedFilters, branding])

  useEffect(() => {
    setFilteredBranding(branding.data)
    filters()
  }, [branding, filters])

  const handleLocalStorage = (id) => {
    localStorage.setItem('branding_id', JSON.stringify(id))
  }

  return (
    <>
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex-col xs:flex-row flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search"
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Branding"
            path="/admin-tools/dashboard-branding/create-dashboard-branding"
          />
        </div>
      </div>
      <Content
        title="Dashboard Branding"
        badge={(
          <BadgeAlert
            alert={filteredBranding?.length}
            status="active"
          />
        )}
        padding={false}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-2"
              mdGrid="md:grid-cols-2"
            >
              {dashboardBrandingTemplateMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={`flex break-word group hover:text-secondary hover:cursor-pointer ${item.mobile ? accountsSettingsStyles.desktop : ''}`}
                  >
                    <span>
                      {item.name}
                    </span>
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredBranding.length === 0 ? <p className="error px-6">{error}</p> : (
                filteredBranding.map((brand) => (
                  <HorizontalGrid
                    key={brand.id}
                    smGrid="grid-cols-2"
                    mdGrid="md:grid-cols-2"
                    backgroundColor
                  >
                    <p className="text-xxs text-gray-500">
                      <LinkWrapper
                        variant="defaultSm"
                        onClick={() => handleLocalStorage(brand.id)}
                        href={{
                          pathname: '/admin-tools/dashboard-branding/edit-dashboard-branding',
                        }}
                      >
                        {brand.email_template.name}
                      </LinkWrapper>
                    </p>
                    <div>
                      <img
                        src={brand.email_template.logo_url}
                        alt={`${brand.email_template.name} logo`}
                        className="max-w-btn-md w-full pr-6"
                      />
                    </div>
                    <EditMenu
                      text="Edit Dashboard Branding"
                      pathname="/admin-tools/dashboard-branding/edit-dashboard-branding"
                      id={brand.id}
                      onClick={() => handleLocalStorage(brand.id)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default DashboardBranding
