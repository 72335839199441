import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import EditDashboardBranding from '../../../components/adminTools/accounts/dashboard_branding/EditDashboardBranding'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { IBrandingDetailInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'

const EditDashboardBrandingPage:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [branding, setBranding] = useState<IBrandingDetailInDb>({} as any)
  const [refresh, setRefresh] = useState(false)
  const [brandingId, setBrandingId] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('branding_id')
    if (items) {
      sessionStorage.setItem('branding_id', items)
      localStorage.removeItem('branding_id')
    }
    const getSessionItems = sessionStorage.getItem('branding_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setBrandingId(parseItems)
      }
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (brandingId > 0) {
      const fetchDashboardBrand = async () => {
        const response = await IMIApi.GetBrandingById(brandingId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setBranding(response.data)
        return setLoading(false)
      }
      fetchDashboardBrand()
    }
    return () => abortController.abort()
  }, [setError, refresh, brandingId])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditDashboardBranding
            branding={branding}
            isLoading={loading}
            setRefresh={setRefresh}
            edit
          />
        )}
      />
    </Padding>
  )
}

export default EditDashboardBrandingPage
