import {
  useCallback, useContext, useEffect, useState,
} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import { CalendarIcon } from '@heroicons/react/outline'
import ReportHeader from '../components/semReport/ReportHeader'
import Padding from '../components/layouts/Padding'
import { IMIApi } from '../api/imi/api'
import { AppContext } from '../contexts/AppContext'
import { IVideoDetails } from '../api/imi/interfaces'
import LoadingSpinner from '../components/LoadingSpinner'
import { ReportContext } from '../contexts/ReportContext'
import { commaNumber, fixDateObj } from '../helpers/utils'
import 'bootstrap-daterangepicker/daterangepicker.css'
import Icon from '../components/Icon'
import Input from '../components/form/Input'
import VideoTotalsReport from '../components/videoReport/VideoTotalsReport'
import VideoPlacementBreakdown from '../components/videoReport/VideoPlacementBreakdown'
import VideoActivityBreakdown from '../components/videoReport/VideoActivityBreakdown'
import VideoImpressionsByCreativeReport from '../components/videoReport/VideoImpressionsByCreativeReport'
import VideoCreativePerformanceReport from '../components/videoReport/VideoCreativePerformanceReport'
import VideoCompletionQuartilesReport from '../components/videoReport/VideoCompletionQuartilesReport'
import VideoActivityTrendsReport from '../components/videoReport/VideoActivityTrendsReport'
import VideoPlacementPerformanceReport from '../components/videoReport/VideoPlacementPerformanceReport'
import VideoDeviceTypeReport from '../components/videoReport/VideoDeviceTypeReport'
import VideoBudgetReport from '../components/videoReport/VideoBudgetReport'
import MobileReportHeader from '../components/navigation/StickyReportHeader'

const VideoReportPage: React.FC = () => {
  const {
    setError,
    user,
  } = useContext(AppContext)
  const {
    // interfaces
    setReportParamsAndCycles,
    setParamsNavigateDatePicker,
    // values
    cyclesForDatePicker,
    parameters,
    reportParameters,
  } = useContext(ReportContext)

  // TODO: update interfaces

  const [videoReportDetail, setVideoReportDetail] = useState<IVideoDetails>({} as any)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /** Returns the current report's parameters for use in the report where needed.
   *  This is separated from the `parameters` object since its specific to the loaded report.
   */
  const thisReportParams = useCallback(() => {
    const cycleId = videoReportDetail?.report_cycle_id
    const startDate = videoReportDetail?.query_start_date
    const endDate = videoReportDetail?.query_end_date
    const campaignId = videoReportDetail?.campaign.id
    return {
      id: campaignId,
      cycle_id: cycleId,
      start_date: startDate,
      end_date: endDate,
    }
  }, [videoReportDetail])

  const showLimitedReport = useCallback(() => videoReportDetail?.platforms.length === 1 && videoReportDetail.platforms[0] === 1, [videoReportDetail])

  const fullName = useCallback(() => {
    const campName = videoReportDetail?.campaign?.name
    const secName = videoReportDetail?.campaign?.secondary_name
    const specific = videoReportDetail?.campaign?.specific?.name

    if (secName) {
      return `${campName}: ${secName}`
    }
    if (secName && specific) {
      return `${campName}: ${secName} - ${specific}`
    }
    if (!secName && specific) {
      return `${campName} - ${specific}`
    }
    return campName
  }, [videoReportDetail])

  /** Load Report Data
   *  This useEffect triggers when on first load the report page and again whenever the
   *  `parameters` key in ReportContext changes to a new report target, it reloads the
   *  report data.
   *
   *  To navigate to a new report page, alter the `parameters` object in the Report Context
   *  using one of the interface methods below which guarantee a valid report request is
   *  generated.
   *
   *  reportContext.
   *    setParamsNavigateDates(start_date, end_date) - Navigate to these arbitrary dates on the current campaign
   *    setParamsNavigateCycle(cycle_id) - Navigate to this cycle on the current campaign
   *    setParamsNavigateNewest(campaign_id) - Navigate to the newest cycle of provided campaign id
   *    setParamsDatePicker - Navigate using the Date Picker
   */

  useEffect(() => {
    setIsLoading(true)
    const fetchVideoReport = async () => {
      const response = await IMIApi.GetVideoReport(parameters)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setIsLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error' })
        return setIsLoading(false)
      }
      setReportParamsAndCycles(response.data)
      setVideoReportDetail(response.data)
      return setIsLoading(false)
    }
    fetchVideoReport()
  },
  [parameters, setError, setReportParamsAndCycles])

  return (
    <Padding>
      {isLoading || !videoReportDetail ? <LoadingSpinner size="lg" variant="primary" /> : (
        <>
          <MobileReportHeader
            title={`${reportParameters.fullName} | Video`}
          >
            <div className="ml-auto hover:cursor-pointer md:min-w-300 bg-tertiary">
              {videoReportDetail.cycles && (
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(reportParameters.report_start_date)
                      .format('MM/DD/YYYY'),
                    endDate: moment(reportParameters.report_end_date)
                      .format('MM/DD/YYYY'),
                    ranges: cyclesForDatePicker,
                    alwaysShowCalendars: true,
                    maxDate: videoReportDetail.report_end_date ? reportParameters.query_max_date : moment(new Date())
                      .format('MM/DD/YYYY'),
                    minDate: videoReportDetail.report_start_date,
                  }}
                  onCallback={(
                    (start, end, label) => {
                      setParamsNavigateDatePicker(label, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    }
                  )}
                >
                  <Input
                    type=""
                    name="date picker"
                    label="date picker"
                    hideLabel
                    className="form-control font-bold font-display bg-white rounded"
                    icon={<Icon><CalendarIcon /></Icon>}
                  />
                </DateRangePicker>
              )}
            </div>
          </MobileReportHeader>
          <ReportHeader
            title={fullName()}
          >
            <div className="heading-5 font-semibold mb-3 sm:mr-12">
              {fixDateObj(videoReportDetail?.report_start_date)}
              <div className="small">
                start date
              </div>
            </div>
            {(videoReportDetail.report_end_date) && (
              <div className="heading-5 font-semibold mb-3 sm:mr-12">
                {thisReportParams().end_date ? fixDateObj(thisReportParams().end_date) : '—'}
                <div className="small">
                  end date
                </div>
              </div>
            )}
            <div className="heading-5 font-semibold mb-3 sm:mr-12">
              {((videoReportDetail?.budget > 0)) ? `$${commaNumber(Math.round(videoReportDetail.budget))}` : '—'}
              <div className="small">
                budget
              </div>
            </div>
            <div className="ml-auto hover:cursor-pointer md:min-w-300">
              {videoReportDetail.cycles && (
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(reportParameters.report_start_date)
                      .format('MM/DD/YYYY'),
                    endDate: moment(reportParameters.report_end_date)
                      .format('MM/DD/YYYY'),
                    ranges: cyclesForDatePicker,
                    alwaysShowCalendars: true,
                    maxDate: videoReportDetail.report_end_date ? reportParameters.query_max_date : moment(new Date())
                      .format('MM/DD/YYYY'),
                    minDate: videoReportDetail.report_start_date,
                  }}
                  onCallback={(
                    (start, end, label) => {
                      setParamsNavigateDatePicker(label, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    }
                  )}
                >
                  <Input
                    type=""
                    name="date picker"
                    label="date picker"
                    hideLabel
                    className="form-control font-bold font-display"
                    icon={<Icon><CalendarIcon /></Icon>}
                  />
                </DateRangePicker>
              )}
            </div>
          </ReportHeader>

          <VideoTotalsReport data={videoReportDetail} />
          {/* utilizes the daily activity to list in table and have downloadable report */}
          {
            showLimitedReport() ? (
              <>
                <VideoCompletionQuartilesReport data={videoReportDetail.quartiles_graph} />
                <VideoPlacementBreakdown data={videoReportDetail} />
                {videoReportDetail.device_impressions && <VideoDeviceTypeReport data={videoReportDetail} />}
                {user.user_type_id === 1 && (
                  <VideoBudgetReport data={videoReportDetail.budget_report} />
                )}
              </>
            ) : (
              <>
                <VideoCompletionQuartilesReport data={videoReportDetail.quartiles_graph} />
                <VideoPlacementPerformanceReport data={videoReportDetail} />
                <VideoPlacementBreakdown data={videoReportDetail} />
                <VideoActivityTrendsReport data={videoReportDetail} />
                {videoReportDetail.activity_breakdown && (
                  <VideoActivityBreakdown
                    campaignId={thisReportParams().id}
                    startDate={thisReportParams().start_date}
                    endDate={thisReportParams().end_date}
                    data={videoReportDetail.activity_breakdown}
                  />
                )}
                <VideoImpressionsByCreativeReport data={videoReportDetail} />
                <VideoCreativePerformanceReport data={videoReportDetail} />
                {videoReportDetail.device_impressions && <VideoDeviceTypeReport data={videoReportDetail} />}
                {user.user_type_id === 1 && (
                  <VideoBudgetReport data={videoReportDetail.budget_report} />
                )}
              </>
            )
          }
        </>
      )}
    </Padding>
  )
}

export default VideoReportPage
