import {
  useContext,
} from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import Content from '../../../../layouts/Content'
import LinkWrapper from '../../../../Links'
import Icon from '../../../../Icon'
import { CampaignContext } from '../../../../../contexts/CampaignContext'

const ChildCampaignCTA:React.FC = () => {
  const { campaignInDb } = useContext(CampaignContext)

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  return (
    <>
      {campaignInDb?.id > 0 && (
        <Content
          title="child campaigns"
        >
          {campaignInDb?.pcampaign?.length > 0 ? (
            <p>To assign a “child” campaign to this “parent” campaign, return to the “campaign settings” page, and find the child within the “unassigned campaigns” section.</p>
          ) : (
            <p>
              There are currently&nbsp;
              <span
                className="font-bold"
              >
                no child campaigns assigned&nbsp;
              </span>
              to this campaign. To assign a “child” campaign to this “parent” campaign, return to the “campaign settings” page, and find the child within the “unassigned campaigns” section.
            </p>
          )}
          <br />
          <LinkWrapper
            target="_blank"
            href="/admin-tools/campaign-settings"
            variant="defaultAdmin"
            className="mb-6 -mt-3 block"
          >
            Go to Campaign Settings
            <Icon size="xxs">
              <ChevronRightIcon />
            </Icon>
          </LinkWrapper>
        </Content>
      )}
    </>
  )
}

export default ChildCampaignCTA
