import { useContext, useEffect, useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { IVideoDetails } from '../../api/imi/interfaces'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'
import HorizontalGrid from '../layouts/HorizontalGrid'
import ToolTip from '../ToolTip'
import { placementBreakdownMenu, styles } from './Data'
import { commaNumber } from '../../helpers/utils'
import Icon from '../Icon'
import Accordion from '../layouts/Accordion'
import { Admin } from '../../constants/UserTypes'

interface Props {
  data: IVideoDetails
}

const DisplayPlacementBreakdown:React.FC<Props> = ({
  data,
}) => {
  const [reportData, setReportData] = useState<IVideoDetails>([] as any)
  const { user } = useContext(AppContext)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState('completed')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')

  useEffect(() => {
    if (data.placements) {
      setReportData({
        ...data,
        placements: data.placements.sort((a, b) => b.completed - a.completed),
      })
    }
  }, [data])

  const columnSortingInfo = (fieldName) => {
    setSortColumn(fieldName)
    const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
    const values = !sortDirection ? reportData.placements.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : reportData.placements.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { sorting, values }
  }

  // revert line 65 & 100 once Spend/Budget work is finished.
  //           mdGrid={`${user.user_type_id !== Admin ? 'md:grid-cols-placements-video' : 'md:grid-cols-placements-video-admin'}`}

  return (
    <Content
      title="Placement breakdown"
      padding={false}
      icon={(
        <ToolTip
          tipId="placementbreakdown"
          size="sm"
          position="block relative pr-0"
        >
          Previous cycles will be listed below if they continue to have tracked website actions taken by users who were served your ad (activities).
        </ToolTip>
      )}
    >
      <>
        <HorizontalGrid
          smGrid={`${user.user_type_id !== Admin ? 'grid-cols-placements-video-sm' : 'grid-cols-placements-video-admin-sm'}`}
          mdGrid={`${user.user_type_id !== Admin ? 'md:grid-cols-placements-video' : 'md:grid-cols-placements-video-admin'}`}
          header
        >
          {placementBreakdownMenu.map((item) => (
            item.forUserTypes.includes(user.user_type_id) ? (
              <div key={item.name} className={`break-word group flex center-items ${item.sort ? 'hover:text-secondary hover:cursor-pointer' : ''} ${item.mobile ? styles.desktop : ''}`}>
                {item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                  >
                    <span className="inline break-word md:hidden">{item.abbreviation ? item.abbreviation : item.name}</span>
                    <span className="hidden break-word md:inline break-normal">{item.name}</span>
                    <Icon
                      size="xxs"
                      className="text-default group sm:pr-0 ml-1 -mt-1"
                    >
                      <ChevronUpIcon className="-mb-1 text-default group-hover:text-secondary" />
                      <ChevronDownIcon className="text-default group-hover:text-secondary" />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>}
              </div>
            ) : null))}
        </HorizontalGrid>
        <div>
          {reportData?.placements?.length > 0 ? (
            reportData?.placements
              .map((placement) => (
                <div key={placement.pc_id} className="odd:bg-grey-lightest pt-6 pb-2 horizontal-grid">
                  <Accordion
                    header={(
                      <HorizontalGrid
                        smGrid={`${user.user_type_id !== Admin ? 'grid-cols-placements-video-sm' : 'grid-cols-placements-video-admin-sm'}`}
                        mdGrid={`${user.user_type_id !== Admin ? 'md:grid-cols-placements-video' : 'md:grid-cols-placements-video-admin'}`}
                      >
                        <p className="small pr-1.5">
                          {placement?.name}
                        </p>
                        <p className="small pr-1.5">
                          {placement.started > 0 ? commaNumber(placement?.started) : 0}
                        </p>
                        <p className={`small pr-1.5 ${styles.desktop}`}>
                          {placement.completed > 0 ? commaNumber(placement?.completed) : 0}
                        </p>
                        <p className="small pr-1.5">
                          {placement.completion_rate > 0 ? `${(placement?.completion_rate).toFixed(2)}%` : '0%'}
                        </p>
                        <p className="small pr-1.5">
                          {placement.cpcv > 0 ? `$${placement.cpcv.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '$0'}
                        </p>
                        {
                          user.user_type_id === Admin ? (
                            <p className={`small pr-1.5 ${styles.desktop}`}>
                              {placement.spend > 0 ? `$${placement.spend.toLocaleString('en', {
                                useGrouping: true,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}` : '$0'}
                            </p>
                          ) : null
                        }
                      </HorizontalGrid>
                    )}
                    body={(
                      <div className="flex justify-between">
                        <p className="text-xxs text-gray-500">
                          <span className={styles.mobile}>
                            Cost Per Completed View (CPCV):
                            &nbsp;
                          </span>
                          {placement.cpcv > 0 ? `$${placement.cpcv.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '$0'}
                        </p>
                        { user.user_type_id === Admin ? (
                          <p className="text-xxs text-gray-500">
                            <span className={styles.mobile}>
                              Budget:
                              &nbsp;
                            </span>
                            {placement.budget > 0 ? `$${placement.budget.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })}` : '$0'}
                          </p>
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              ))
          ) : (
            <p className="px-4 md:px-6">{!data.report_end_date && noDataMessage}</p>
          )}
        </div>
      </>
    </Content>
  )
}

export default DisplayPlacementBreakdown
