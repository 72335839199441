import {
  useState,
  useEffect,
  useContext,
} from 'react'
import {
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline'
import HorizontalGrid from '../layouts/HorizontalGrid'
import {
  fixDateObj,
} from '../../helpers/utils'
import Icon from '../Icon'
import { ICampaignOverview } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import LinkWrapper from '../Links'
import Content from '../layouts/Content'
import { ReportContext } from '../../contexts/ReportContext'

const options = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]

const inactiveMenu = [
  {
    fieldName: ['name', 'secondary_name'],
    name: 'Campaign',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'end_date',
    name: 'End Date',
    mobile: false,
    sort: true,
  },
]

const inactiveStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

interface Props {
  advertiserCampaigns: ICampaignOverview
  isLoading: boolean
}

const InactiveCampaignOverview:React.FC<Props> = ({
  advertiserCampaigns,
  isLoading,
}) => {
  const { setParamsNavigateNewest } = useContext(ReportContext)
  const [filteredAdvertiserCampaigns, setFilteredAdvertiserCampaigns] = useState<any>(advertiserCampaigns)
  const [sortColumn, setSortColumn] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [errorMsg, setErrorMsg] = useState('No inactive campaigns found.')

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = sortDirection ? filteredAdvertiserCampaigns?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1)) : filteredAdvertiserCampaigns?.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1))
    return { values }
  }

  useEffect(() => {
    setFilteredAdvertiserCampaigns(advertiserCampaigns)
  }, [advertiserCampaigns])

  const handleLocalStorage = ({
    id,
    cycle_id,
    start_date,
    end_date,
  }) => {
    const campParameters = {
      id,
      cycle_id,
      start_date,
      end_date,
    }
    localStorage.setItem('campaign_data', JSON.stringify(campParameters))
  }

  const determinePage = (adType) => {
    if (adType === 1) {
      return '/display-advertising'
    }
    if (adType === 2) {
      return '/search-engine-marketing'
    }
    if (adType === 3) {
      return '/video-advertising'
    }
    return null
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <>
          {filteredAdvertiserCampaigns.length > 0 ? (
            <Content
              padding={false}
            >
              <HorizontalGrid
                smGrid="grid-cols-2"
                mdGrid="md:grid-cols-2"
              >
                {inactiveMenu.map((item) => (
                  item.sort ? (
                    <span
                      aria-hidden="true"
                      onClick={() => columnSortingInfo(item.fieldName)}
                      key={item.name}
                      className={(
                        `flex break-word group hover:text-secondary hover:cursor-pointer
                        ${item.mobile && inactiveStyles.desktop}`
                      )}
                    >
                      {item.name}
                      <Icon
                        size="xxs"
                        className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                      >
                        <ChevronUpIcon
                          className="-mb-1 text-default group-hover:text-secondary"
                        />
                        <ChevronDownIcon
                          className="text-default group-hover:text-secondary"
                        />
                      </Icon>
                    </span>
                  ) : <span key={item.name}>{item.name}</span>
                ))}
              </HorizontalGrid>
              <div className="items-center">
                {filteredAdvertiserCampaigns?.map((campaign) => (
                  <HorizontalGrid
                    key={campaign.id}
                    smGrid="grid-cols-2"
                    mdGrid="md:grid-cols-2"
                    backgroundColor
                  >
                    <p className="text-xxs text-gray-500">
                      <LinkWrapper
                        variant="defaultSm"
                        key={campaign.id}
                        onClick={(
                          () => setParamsNavigateNewest(campaign.id)
                        )}
                        href={{
                          pathname: determinePage(campaign.type.id),
                        }}
                      >
                        {campaign.type.short_name}
                        {campaign.secondary_name && (
                          <>
                            &nbsp;|&nbsp;
                            {campaign.secondary_name}
                          </>
                        )}
                        {campaign.specific?.name && (
                          <>
                            &nbsp;
                            -
                            &nbsp;
                            {campaign.specific?.name}
                          </>
                        )}
                      </LinkWrapper>
                    </p>
                    <p>
                      {fixDateObj(campaign.end_date)}
                      {/* {fixDateObj(campaign.newest_campaign.end_date)} */}
                    </p>
                  </HorizontalGrid>
                ))}
              </div>
            </Content>
          ) : (
            <p className="error pb-6">{errorMsg}</p>
          )}
        </>
      )}
    </>
  )
}

export default InactiveCampaignOverview
