import {
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { ENV } from '../../../../env'
import Content from '../../../layouts/Content'
import { IBrandingDetailInDb, IBrandingUpdate } from '../../../../api/imi/interfaces'
import AccountDashboardBrandingForm from '../../../form/accounts/AccountDashboardBrandingForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import AssignUsers from './AssignUsers'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  isLoading: boolean
  branding: IBrandingDetailInDb
  edit: boolean
  setRefresh: Dispatch<SetStateAction<boolean>>
}

const EditDashboardBranding:React.FC<Props> = ({
  isLoading,
  branding,
  edit,
  setRefresh,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(edit)
  const { setError } = useContext(AppContext)

  const methods = useForm<IBrandingUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: branding?.id,
      email_template_id: branding?.email_template_id,
      url_login: branding?.url_login,
    }), [branding]),
  })
  useEffect(() => {
    methods.reset({
      ...branding,
    })
  }, [branding, methods])

  const onSubmit: SubmitHandler<IBrandingUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const updatedTemplate: IBrandingUpdate = {
      email_template_id: data.email_template_id,
      id: data.id,
      url_login: `https://${data.url_login}.${ENV.imiURL}`,
      color_primary: data.color_primary,
      color_primary_dark: data.color_primary_dark,
      color_secondary: data.color_secondary,
      color_tertiary: data.color_tertiary,
    }

    const submitResponse = await IMIApi.BrandingUpdate(updatedTemplate)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setRefresh(true)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
    setRefresh(false)
  }

  const editBranding = () => {
    setShowButton(true)
  }

  const cancel = () => {
    methods.reset(branding, { keepErrors: false, keepDefaultValues: true })
    setIsButtonLoading(false)
    setShowButton(false)
    setBtnText('Save')
    methods.clearErrors()
  }

  return (
    <>
      <Content
        title="Dashboard Branding Details"
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <AccountDashboardBrandingForm
                showButton={showButton}
                errors={methods.formState.errors}
                branding={branding}
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabled={!methods.formState.isValid}
                disabledCancel={!methods.formState.isValid}
                showButton={showButton}
                onClickCancel={cancel}
                onClickEdit={editBranding}
                btnText={btnText}
              />
            </form>
          </FormProvider>
        )}
      </Content>
      <AssignUsers
        branding={branding}
        edit
      />
    </>
  )
}

export default EditDashboardBranding
