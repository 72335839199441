import { useEffect, useState, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChevronRightIcon, RefreshIcon } from '@heroicons/react/solid'
import Dropdown from '../Dropdown'
import Radio from '../Radio'
import LinkWrapper from '../../Links'
import Icon from '../../Icon'
import { UserContext } from '../../../contexts/UserContext'

interface Props {
  showButton?: boolean
  brandingData: any
  currentUser?: any
  errors: any
}

const AccountUserDashboardBrandingForm:React.FC<Props> = ({
  showButton,
  brandingData,
  currentUser,
  errors,
}) => {
  const methods = useFormContext()
  const { userInDb } = useContext(UserContext)
  const [whiteLabel, setWhiteLabel] = useState(userInDb.white_label_id > 1)
  const branding = methods.watch('branding')
  useEffect(() => {
    if (branding === 'false' || branding === false) {
      methods.setValue('white_label_id', 1)
    }
  }, [branding, methods])
  // eslint-disable-next-line no-console
  // console.log(methods.formState.isValid)
  return (
    <>
      <Radio
        // {...methods.register('branding', { required: true, shouldUnregister: true })}
        // {...methods.setValue('branding', userInDb.white_label_id > 1)}
        name="branding"
        disabled={!showButton || currentUser.id <= 0}
        label="Should the user see a partner-branded version of the dashboard?"
        options={[
          {
            id: 0,
            label: 'Yes',
            value: true,
          },
          {
            id: 1,
            label: 'No',
            value: false,
          },
        ]}
        // defaultValue={userInDb ? userInDb.white_label_id > 1 : 'false'}
        // onChange={(e) => changeRadio(e)}
      />
      {(branding === 'true') && (
        <div>
          {brandingData.length && (
            <Controller
              name="white_label_id"
              control={methods.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Dropdown
                  required
                  disabled={!showButton}
                  hookFormField={field}
                  placeholder={userInDb.white_label_id > 1 ? undefined : 'Select...'}
                  label="Dashboard branding name"
                  variant="admin"
                  value={userInDb.white_label_id > 1 && userInDb.white_label_id}
                  list={brandingData}
                  error={errors.branding_id && <span className="form-error">This field is required</span>}
                />
              )}
            />
          )}
        </div>
      )}
    </>
  )
}

export default AccountUserDashboardBrandingForm
