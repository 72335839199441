import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import CreateDashboardBranding from '../../../components/adminTools/accounts/dashboard_branding/CreateDashboardBranding'
import { IBrandingDetailInDb, IEmailTemplateInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import EditDashboardBranding from '../../../components/adminTools/accounts/dashboard_branding/EditDashboardBranding'

const CreateDashboardBrandingPage:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [emailTemplate, setEmailTemplates] = useState<IEmailTemplateInDb[]>([] as any)
  const [branding, setBranding] = useState<IBrandingDetailInDb>({} as any)
  const [templateId, setTemplateId] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [serverResponse, setServerResponse] = useState<any>([])

  // TODO: fix error messages here

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchEmailTemplates = async () => {
      const response = await IMIApi.GetAllEmailTemplates(abortController.signal)
      if (!response) {
        return
      }
      setServerResponse(response)
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setEmailTemplates(response.data.data)
      setLoading(false)
    }
    fetchEmailTemplates()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    if (templateId > 0) {
      const fetchDashboardBrand = async () => {
        const submitResponse = await IMIApi.GetBrandingById(templateId, abortController.signal)
        if (!submitResponse) {
          return
        }
        const authenticated = submitResponse.status === 200
        setServerResponse(submitResponse)
        if (!authenticated) {
          const parse = JSON.parse(submitResponse)
          setError({ errorMsg: parse.detail })
          return setLoading(false)
        }
        setBranding(submitResponse.data)
        return setLoading(false)
      }
      fetchDashboardBrand()
    }
    return () => abortController.abort()
  }, [templateId, refresh, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          templateId > 0 ? (
            <EditDashboardBranding
              isLoading={loading}
              branding={branding}
              setRefresh={setRefresh}
              edit={false}
            />
          ) : (
            <CreateDashboardBranding
              isLoading={loading}
              emailTemplate={emailTemplate}
              setTemplateId={setTemplateId}
            />
          )
        )}
      />
    </Padding>
  )
}

export default CreateDashboardBrandingPage
