import { useContext, useEffect, useState } from 'react'
import Icon from '../Icon'
import { IMIApi } from '../../api/imi/api'
import NavLinkWrapper from '../navigation/NavLink'
import { navigationMenu } from '../navigation/NavItems'
import { AppContext } from '../../contexts/AppContext'
import {
  Admin, CallManager, TeamViewer, CallAnalyst, Partner, Client, 
} from '../../constants/UserTypes'
// <span className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mt-3">Accounts</span>
// TODO: check url path to determine if parent is active

const AdminToolsMenu:React.FC = () => {
  const { user, setError } = useContext(AppContext)
  const [loadingUnassigned, setLoadingUnassigned] = useState(true)
  const [unassignedCampaigns, setUnassignedCampaigns] = useState<any[]>([] as any)
  const [loadingApiSettingsErrors, setLoadingApiSettingsErrors] = useState(true)
  const [apiSettingsErrors, setApiSettingsErrors] = useState({
    total_rows: 0,
    last_update: new Date(),
  })
  // TODO: make more efficient

  useEffect(() => {
    const abortController = new AbortController()
    if (user.user_type_id === 1) {
      const fetchUnassignedCampaigns = async () => {
        const response = await IMIApi.GetAllPCampaigns(0, 0, true, abortController.signal)
        if (!response) {
          return
        }
        if (response.status && response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoadingUnassigned(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoadingUnassigned(false)
        }
        setUnassignedCampaigns(response?.data?.data)
        return setLoadingUnassigned(false)
      }
      fetchUnassignedCampaigns()
    }
    return () => abortController.abort()
  }, [user, setError])

  useEffect(() => {
    if (user.user_type_id === 1) {
      const abortController = new AbortController()
      const fetchApiSettingsErrors = async () => {
        const response = await IMIApi.GetPlatformCredStatus()
        if (response.status && response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoadingApiSettingsErrors(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoadingApiSettingsErrors(false)
        }
        setApiSettingsErrors(response.data)
        return setLoadingApiSettingsErrors(false)
      }
      fetchApiSettingsErrors()
      return () => abortController.abort()
    }
  }, [user, setError])

  return (
    <div id="admin-tools-menu">
      {navigationMenu.map((nav) => (
        <div key={nav.url}>
          {user.user_type_id === 1 && (
            <>
              {nav.accounts?.map((item) => (
                <div key={item.url}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul className="mb-6 border-b border-b-1 border-grey-dark border-dotted pb-3">
                    {item.routes.map((route) => (
                      <li key={route.url}>
                        <NavLinkWrapper variant="menu" to={route.url} className="flex items-start mb-3 group">
                          <Icon
                            colors="grey"
                            className="mr-1"
                          >
                            {route.icon}
                          </Icon>
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {nav.advertisers?.map((item) => (
                <div key={item.url}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul className="mb-6 border-b border-b-1 border-grey-dark border-dotted pb-3">
                    {item.routes.map((route) => (
                      <li key={route.url}>
                        <NavLinkWrapper variant="menu" to={route.url} className="flex items-start mb-3 group">
                          <Icon
                            colors="grey"
                            className="mr-1"
                          >
                            {route.icon}
                          </Icon>
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {nav.campaigns?.map((item) => (
                <div key={item.url}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul className="mb-6 border-b border-b-1 border-grey-dark border-dotted pb-3">
                    {item.routes.map((route) => (
                      <li key={route.url}>
                        <NavLinkWrapper variant="menu" to={route.url} className="flex items-start mb-3 group">
                          {/* <span className={`${alert ? 'bg-red-default h-2 w-2 rounded-circle flex items-center justify-center mr-2 absolute z-100' : ''}`} /> */}
                          <Icon
                            colors="grey"
                            className="mr-1"
                          >
                            {unassignedCampaigns.length > 0 ? route?.icon_alert : route.icon}
                          </Icon>
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          )}
          {([Admin, TeamViewer, CallManager, CallAnalyst].includes(user.user_type_id)) && (
            nav.calls?.map((item) => (
              <div key={item.url}>
                <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                <ul className="mb-6 border-b border-b-1 border-grey-dark border-dotted pb-3">
                  {item.routes.map((route) => (
                    !route.access.includes(user.user_type_id) ? (
                      null
                    ) : (
                      <li key={route.url}>
                        <NavLinkWrapper variant="menu" to={route.url} className="flex items-start mb-3 group">
                          <Icon
                            colors="grey"
                            className="mr-1"
                          >
                            {route.icon}
                          </Icon>
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    )
                  ))}
                </ul>
              </div>
            ))
          )}
          {user.user_type_id === 1 && (
            <>
              {nav.api?.map((item) => (
                <div key={item.url}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul className="mb-6 border-b border-b-1 border-grey-dark border-dotted pb-3">
                    {item.routes.map((route) => (
                      <li key={route.url}>
                        <NavLinkWrapper variant="menu" to={route.url} className="flex items-start mb-3 group">
                          <Icon
                            colors="grey"
                            className="mr-1"
                          >
                            {apiSettingsErrors.total_rows > 0 ? route?.icon_alert : route.icon}
                          </Icon>
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {/* {nav.other?.map((item) => (
                <div key={item.url}>
                  <p className="text-display block pt-1 text-menuTitle text-xs font-bold uppercase mb-3 font-display">{item.title}</p>
                  <ul className="mb-6 pb-3">
                    {item.routes.map((route) => (
                      <li key={route.url}>
                        <NavLinkWrapper variant="menu" to={route.url} className="flex items-start mb-3 group">
                          <Icon
                            colors="grey"
                            className="mr-1"
                          >
                            {route.icon}
                          </Icon>
                          {route.title}
                        </NavLinkWrapper>
                      </li>
                    ))}
                  </ul>
                </div>
              ))} */}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default AdminToolsMenu
