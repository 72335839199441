import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignTypeInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import CampaignTypes from '../../../components/adminTools/campaigns/campaign_types/CampaignTypes'
import CreateCampaignType from '../../../components/adminTools/campaigns/campaign_types/CreateCampaignType'
import EditCampaignType from '../../../components/adminTools/campaigns/campaign_types/EditCampaignTypes'

const CreateCampaignTypesPage:React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [campaignTypeId, setCampaignTypeId] = useState(0)
  const [campaignType, setCampaignType] = useState<ICampaignTypeInDb>({} as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (campaignTypeId > 0) {
      const fetchCampaignTypes = async () => {
        const response = await IMIApi.GetCampaignTypeById(campaignTypeId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaignType(response.data)
        return setLoading(false)
      }
      fetchCampaignTypes()
    }
    return () => abortController.abort()
  }, [campaignTypeId, setError, refresh])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          campaignTypeId > 0 ? (
            <EditCampaignType
              isLoading={loading}
              campaignType={campaignType}
              edit={false}
            />
          ) : (
            <CreateCampaignType
              setCampaignTypeId={setCampaignTypeId}
            />
          )
        )}
      />
    </Padding>
  )
}
export default CreateCampaignTypesPage
