import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignInDb } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import { CampaignContext } from '../../../contexts/CampaignContext'
import EditRoiCampaignDetails from '../../../components/adminTools/campaigns/roi/EditRoiCampaignDetails'
import RoiCycleDetails from '../../../components/adminTools/campaigns/roi/RoiCycleDetails'
import CreateRoi from '../../../components/adminTools/campaigns/roi/CreateEditRoi'

const EditRoiPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const { setError } = useContext(AppContext)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [campaignRoiId, setCampaignRoiId] = useState<number>(0)
  const [refreshTodo, setRefreshTodo] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('campaign_id')
    if (items) {
      sessionStorage.setItem('campaign_id', items)
      localStorage.removeItem('campaign_id')
    }
    const getSessionItems = sessionStorage.getItem('campaign_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setCampaignRoiId(parseItems)
      }
    }
  }, [])

  // campaign
  useEffect(() => {
    const abortController = new AbortController()
    if (campaignRoiId > 0) {
      const fetchCampaignRoi = async () => {
        const response = await IMIApi.GetCampaignRoiById(campaignRoiId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaignInDb(response.data as ICampaignInDb)
        return setLoading(false)
      }
      fetchCampaignRoi()
    }
    return () => abortController.abort()
  }, [setError, campaignRoiId, setCampaignInDb, refreshTodo])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <>
            <EditRoiCampaignDetails
              edit
              setRefreshTodo={setRefreshTodo}
              roiId={campaignRoiId}
              loading={loading}
            />
            {campaignInDb?.roi_is_active && (
              <CreateRoi
                isLoading={loading}
                setRefreshTodo={setRefreshTodo}
                editPage
              />
            )}
            <RoiCycleDetails
              loading={loading}
            />
          </>
        )}
      />
    </Padding>
  )
}
export default EditRoiPage
