import {
  useState,
  useEffect,
  useCallback,
} from 'react'
import ReportHeader from '../semReport/ReportHeader'
import Dropdown from '../form/Dropdown'
import {
  handleFilters,
  fixDateTimeObj,
  fixHoursObj,
} from '../../helpers/utils'
import { ICampaignOverview } from '../../api/imi/interfaces'
import LoadingSpinner from '../LoadingSpinner'
import ActiveCampaignOverview from './ActiveCampaignOverview'
import InactiveCampaignOverview from './InactiveCampaignOverview'

const options = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]

interface Props {
  advertiserCampaigns: ICampaignOverview
  isLoading: boolean
}

const CampaignOverview:React.FC<Props> = ({
  advertiserCampaigns,
  isLoading,
}) => {
  const [filteredAdvertiserCampaigns, setFilteredAdvertiserCampaigns] = useState<any>(advertiserCampaigns)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
  })

  useEffect(() => {
    setFilteredAdvertiserCampaigns(advertiserCampaigns?.campaigns?.sort((a, b) => (a.secondary_name > b.secondary_name ? 1 : -1)))
    if (!advertiserCampaigns?.advertiser?.is_active) {
      return setSelectedFilters({ status: 1 })
    }
    setSelectedFilters({ status: 0 })
  }, [advertiserCampaigns])

  const filters = useCallback(() => {
    const filterStatus = (campaign) => {
      const campaignStatus = campaign.end_date
      if (campaignStatus === null) {
        // active campaigns
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }
    const filterData = advertiserCampaigns?.campaigns?.filter((item) => filterStatus(item))
    setFilteredAdvertiserCampaigns(filterData)
  }, [selectedFilters, advertiserCampaigns])

  useEffect(() => {
    filters()
  }, [filters])

  // TODO: better last updated values for multiple campaigns. I thought we'd have a global last_updated no matter how many campaigns...?
  return (
    <>
      {isLoading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <>
          <ReportHeader
            title={advertiserCampaigns?.advertiser?.name}
          >
            <div className="md:flex justify-between w-full">
              <div>
                <p className="text-xxs uppercase text-opacity-60">
                  {selectedFilters.status < 1 ? 'Total Active Campaigns: ' : 'Total Inactive Campaigns: '}
                  <span className="font-semibold">{selectedFilters.status < 1 ? advertiserCampaigns?.total_active_campaigns : (advertiserCampaigns?.campaigns?.filter((campaign) => campaign.end_date !== null)?.length)}</span>
                </p>
                <p className="text-xxs uppercase text-opacity-60 mb-6">
                  Last Updated:&nbsp;
                  <span className="font-semibold">{advertiserCampaigns ? `${fixDateTimeObj(advertiserCampaigns.last_updated)}, ${fixHoursObj(advertiserCampaigns.last_updated)} est` : 'N/A'}</span>
                </p>
              </div>
              <Dropdown
                variant="default_border"
                label="Status"
                list={options}
                defaultValue={selectedFilters.status}
                position="top-1.5 absolute right-0 top-0 bottom-0"
                handleFilters={(e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))}
                className="max-w-dropdown"
              />
            </div>
          </ReportHeader>
          {selectedFilters.status === 0 ? (
            <ActiveCampaignOverview isLoading={isLoading} advertiserCampaigns={filteredAdvertiserCampaigns.sort((a, b) => a.type.id - b.type.id)} />
          ) : (
            <InactiveCampaignOverview isLoading={isLoading} advertiserCampaigns={filteredAdvertiserCampaigns.sort((a, b) => a.type.id - b.type.id)} />
          )}
        </>
      )}
    </>
  )
}

export default CampaignOverview
