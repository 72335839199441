// General overview of calls, category, and flagged #
import { useState, useEffect, useContext } from 'react'
import Content from '../layouts/Content'
import ToolTip from '../ToolTip'
import SidebarRight from '../layouts/SidebarRight'
import LinkWrapper from '../Links'
import MetricsGrid from '../layouts/MetricsGrid'
import HorizontalGrid from '../layouts/HorizontalGrid'
import { ReactComponent as TotalCallsIcon } from '../../assets/icons/total-calls-icon.svg'
import { ReactComponent as TotalCallsLeadsIcon } from '../../assets/icons/total-call-leads-icon.svg'
import { ReactComponent as TotalFlaggedCallsIcon } from '../../assets/icons/total-flagged-calls-icon.svg'
import { ICallsOverview } from '../../api/imi/interfaces'
import { IMIApi } from '../../api/imi/api'
import { AppContext } from '../../contexts/AppContext'
import { PdfContext } from '../../contexts/PdfContext'
import { ReportContext } from '../../contexts/ReportContext'
import LoadingSpinner from '../LoadingSpinner'
import { fixDateObj, fixDateObjApi } from '../../helpers/utils'

const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute right-6',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

const title = [
  {
    fieldName: 'category',
    name: 'Category',
    mobile: false,
    info: 'Click on a category to view detailed analytics for all calls in that category.',
  },
  {
    fieldName: 'calls',
    name: '# of Calls',
    mobile: false,
  },
  {
    fieldName: 'flagged',
    name: '# Flagged',
    mobile: false,
    info: 'Flagged calls are missed opportunities. Refer to “enhanced call analytics” for more details on each call.',
  },
]

const CallReport:React.FC = () => {
  const [callsData, setCallsData] = useState<ICallsOverview>({} as any)
  const [loading, setLoading] = useState<boolean>(true)
  const { setError } = useContext(AppContext)
  const { setOverviewData } = useContext(PdfContext)
  const { reportParameters, parameters, setParamsNavigateDates } = useContext(ReportContext)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')
  const [filteredCategories, setFilteredCategories] = useState<any>()

  useEffect(() => {
    const abortController = new AbortController()
    if (reportParameters.id > 0) {
      setLoading(true)
      const fetchCallsData = async () => {
        const response = await IMIApi.GetCallsOverview({ id: reportParameters.id, start_date: fixDateObjApi(reportParameters.query_start_date), end_date: fixDateObjApi(reportParameters.query_end_date) }, abortController.signal)
        if (!response) {
          return
        }
        if (response.status && response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCallsData(response.data)
        setOverviewData(response.data)
        return setLoading(false)
      }
      fetchCallsData()
      setLoading(false)
    }
    return () => {
      setLoading(false)
      abortController.abort()
    }
  }, [setError, setOverviewData, reportParameters.id, reportParameters.query_end_date, reportParameters.query_start_date])

  useEffect(() => {
    if (callsData) {
      const filterCategories = callsData?.categories?.filter((category) => category.category_name !== '')
      return setFilteredCategories(filterCategories)
    }
  }, [callsData])

  return (
    <SidebarRight
      mainContent={(
        <Content
          title="calls"
          minHeight="lg:min-h-metric-sidebar-3"
          padding={false}
        >
          {loading && !callsData ? <div className="px-4 pb-6 md:px-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
            <>
              <HorizontalGrid
                smGrid="grid-cols-3"
                mdGrid="md:grid-cols-callReport"
              >
                {title.map((item) => (
                  <div key={item.name} className={`break-all group flex center-items ${item.mobile ? styles.desktop : ''}`}>
                    <span key={item.name}>{item.name}</span>
                    {item.info ? <ToolTip tipId={item.name} position="relative ml-0.5">{item.info}</ToolTip> : null}
                  </div>
                ))}
              </HorizontalGrid>
              <div>
                {!filteredCategories || filteredCategories?.length === 0 ? <p className="px-4 md:px-6">{noDataMessage}</p> : (
                  callsData?.categories?.filter((category) => category.category_name !== '').sort((a, b) => (a.total > b.total ? -1 : 1)).sort((a, b) => (a.category_name.toLowerCase().includes('lead') > b.category_name.toLowerCase().includes('lead') ? -1 : 11)).map((call) => (
                    <HorizontalGrid
                      smGrid="grid-cols-3"
                      mdGrid="md:grid-cols-callReport"
                      backgroundColor
                      key={call.category_name}
                    >
                      <LinkWrapper
                        variant="defaultSm"
                        className="font-base text-xxs font-normal"
                        href="/search-engine-marketing/call-report"
                        onClick={() => {
                          localStorage.setItem('call_report_id', JSON.stringify(call.category_id))
                          // setParamsNavigateDates(parameters.start_date, parameters.end_date)
                          // localStorage.setItem('campaign_data', JSON.stringify(parameters))
                        }}
                        target="_blank"
                      >
                        {call.category_name}
                      </LinkWrapper>
                      <p className="small">
                        {call.total}
                      </p>
                      <p className="small">
                        {call.flagged}
                      </p>
                      <div className="hidden md:block">
                        <div className="bg-grey-lighter w-full h-2 rounded-sm overflow-hidden">
                          <div style={{ width: `${(call.total / callsData.total) * 100}%`, backgroundColor: call.color }} className="h-2 rounded-sm w-1/8" />
                        </div>
                      </div>
                    </HorizontalGrid>
                  ))
                )}
              </div>
            </>
          )}
        </Content>
      )}
      sidebarContent={(
        <div className="grid grid-cols-auto grid-rows-auto gap-5 mb-6">
          {loading ? <LoadingSpinner size="lg" variant="primary" /> : (
            <>
              <MetricsGrid
                name="total calls"
                tooltipInfo="The total number of calls includes both processed calls and unprocessed calls, when applicable."
                imageUrl={<TotalCallsIcon />}
                value={callsData.total || 0}
              />
              <MetricsGrid
                name="total call leads"
                imageUrl={<TotalCallsLeadsIcon />}
                value={callsData.total_leads || 0}
              />
              <MetricsGrid
                name="total flagged"
                imageUrl={<TotalFlaggedCallsIcon />}
                value={callsData.total_flagged || 0}
              />
            </>
          )}
        </div>
      )}
    />
  )
}

export default CallReport
