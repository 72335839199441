import {
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ENV } from '../../../../env'
import { IEmailTemplateInDbDetail, IEmailTemplateUpdate } from '../../../../api/imi/interfaces'
import AccountEmailTemplateForm from '../../../form/accounts/AccountEmailTemplateForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import Badge from '../../../badge/Badge'
import { AppContext } from '../../../../contexts/AppContext'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  isLoading: boolean
  template: IEmailTemplateInDbDetail
  edit: boolean
  setRefresh: Dispatch<SetStateAction<boolean>>
}

const EditEmailTemplate:React.FC<Props> = ({
  template,
  edit,
  setRefresh,
  isLoading,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(edit)
  const { setError } = useContext(AppContext)

  const methods = useForm<IEmailTemplateUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: template?.id,
      name: template?.name,
      logo_url: template?.logo_url,
      from_email: template?.from_email,
      from_name: template?.from_name,
      cta_text: template?.cta_text,
      displayed_url: template?.displayed_url,
      lre_subject: template?.lre_subject,
      lre_content: template?.lre_content,
      fcre_subject: template?.fcre_subject,
      fcre_content: template?.fcre_content,
    }), [template]),
  })

  useEffect(() => {
    methods.reset(template)
  }, [template, methods])
  // useEffect(() => {
  //   methods.reset({
  //     ...template,
  //     logo_url: template?.logo_url?.replace(/\s|[/]|https:|\/\/|staging|images|logos|imidashboard-dev.com|/g, ''),
  //   })
  // }, [template, methods])

  const fixDateObj = (date) => {
    const dateObj = new Date(date)
    const newDateObj = {
      month: dateObj.getMonth() + 0o1,
      day: dateObj.getDate(),
      year: dateObj.getFullYear(),
    }
    return `${newDateObj.month}/${newDateObj.day}/${newDateObj.year}`
  }

  const cancel = () => {
    methods.reset({}, { keepErrors: false, keepDefaultValues: true })
    setIsButtonLoading(false)
    setShowButton(false)
    setBtnText('Save')
    methods.clearErrors()
  }

  const editTemplate = () => {
    setShowButton(true)
  }
  const onSubmit: SubmitHandler<IEmailTemplateUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    // const updateLogoPath = {
    //   ...data,
    //   logo_url: ENV.appEnvironment === 'staging' ? `https://staging.${ENV.imiURL}/images/logos/${data.logo_url}` : `https://${ENV.imiURL}/images/logos/${data.logo_url}`,
    // }
    const submitResponse = await IMIApi.UpdateEmailTemplate(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setRefresh(true)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
    setRefresh(false)
  }

  return (
    <>
      <Content
        title="Template Details"
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <div className="mb-6">
              <Badge
                variant="badgeOnly"
                label={(
                  <span>
                    Created at:
                    <span className="font-bold">
                      &nbsp;
                      {fixDateObj(template?.created_at)}
                    </span>
                  </span>
                )}
              />
              <Badge
                variant="badgeOnly"
                label={(
                  <span>
                    Updated at:
                    <span className="font-bold">
                      &nbsp;
                      {fixDateObj(template?.updated_at)}
                    </span>
                  </span>
                )}
              />
            </div>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <AccountEmailTemplateForm
                  template={template}
                  errors={methods.formState.errors}
                  showButton={showButton}
                />
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isValid || !methods.formState.isDirty}
                  disabledCancel={!methods.formState.isValid || !methods.formState.isDirty}
                  showButton={showButton}
                  onClickCancel={cancel}
                  onClickEdit={editTemplate}
                  btnText={btnText}
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default EditEmailTemplate
