const AdminToolsMobileLink:React.FC = () => (
  <div className="sm:hidden mb-6 w-full">
    <a
      className="text-primary hover:text-secondary text-xs"
      href="#admin-tools-menu"
    >
      Go to Admin Tools Menu &gt;
    </a>
  </div>
)

export default AdminToolsMobileLink
