import {
  useState,
  useContext,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import Content from '../../../layouts/Content'
import Icon from '../../../Icon'
import { fixDateObj } from '../../../../helpers/utils'
import LoadingSpinner from '../../../LoadingSpinner'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import { campaignSettingsStyles } from '../campaign_settings/Data'
import Pagination from '../../../Pagination'
import { roiCyclesMenu } from './Data'
import ToolTip from '../../../ToolTip'
import { CampaignContext } from '../../../../contexts/CampaignContext'

interface Props {
  loading: boolean
}

const RoiCycleDetails: React.FC<Props> = ({
  loading,
}) => {
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [pageLimit, setPageLimit] = useState(30)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('start_date')
  const { campaignInDb } = useContext(CampaignContext)

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? campaignInDb?.cycles.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1)) : campaignInDb?.cycles.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { ...values }
  }

  const handleLoadMore = () => {
    setPageLimit(pageLimit + (campaignInDb?.cycles.length - pageLimit))
  }

  const handleCollapse = () => {
    setPageLimit(7)
  }

  return (
    <>
      <Content
        title="cycle's roi details"
        padding={false}
        // icon={(
        //   <ToolTip
        //     size="sm"
        //     position="relative"
        //     tipId="roi-cycle-info"
        //   >
        //     Default information can be changed, as needed. Optional fields can be left blank. If left blank, they will display as a dash in the ROI section of the campaign report, and the return for that lead type will not be calculated.
        //     <br />
        //     <span className="disclaimer">*If we are not processing calls, all calls are automatically categorized as leads.</span>
        //   </ToolTip>
        // )}
      >
        {loading ? (
          <span className="px-4 md:mx-6 block"><LoadingSpinner variant="primary" size="lg" /></span>
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-3"
              mdGrid="md:grid-cols-3"
            >
              {roiCyclesMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                                  `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && campaignSettingsStyles.desktop}`
                              )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {campaignInDb?.cycles?.length <= 0 ? (
                <p className="error px-6">Campaign has no previous cycles with ROI.</p>
              ) : (
                campaignInDb?.cycles?.filter((cycle, i) => (i < pageLimit)).map((cycle) => (
                  <HorizontalGrid
                    key={cycle.id}
                    smGrid="grid-cols-3"
                    mdGrid="md:grid-cols-3"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      {fixDateObj(cycle?.start_date)}
                    </p>

                    <p className="text-xxs pr-3">
                      {cycle?.roi_is_active ? 'Yes' : 'No'}
                    </p>
                    <p className="text-xxs pr-3">
                      {(cycle?.total_return && cycle?.roi_is_active) ? (
                        `${cycle?.total_return.toFixed(2)}%`
                      ) : (
                        '-'
                      )}
                    </p>
                  </HorizontalGrid>
                ))
              )}
            </div>
            {campaignInDb.cycles && campaignInDb.cycles.length > 0 && (
              <div className="block px-4 pt-3 md:px-6">
                <Pagination
                  pageLimit={pageLimit}
                  adminLimit={pageLimit}
                  data={campaignInDb.cycles}
                  offset={10}
                  onClick={handleLoadMore}
                  onClickCollapse={handleCollapse}
                />
              </div>
            )}
          </>
        )}
      </Content>
    </>
  )
}

export default RoiCycleDetails
