import {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import Search from '../../form/Search'
import AdminToolsMobileLink from '../AdminToolsMobileLink'
import { handleFilters } from '../../../helpers/utils'
import { IPlatformCredListItem } from '../../../api/imi/interfaces'
import CreateButtons from '../../button/CreateButtons'
import ApiSettingsList from './ApiSettingsList'

// TODO: fix double clicking on account type sorting
// TODO: campaign filter

interface Props {
  platformCreds: IPlatformCredListItem[]
  setPagination: Dispatch<SetStateAction<any>>
  loadingApiSettings: boolean
}

const ApiSettings: React.FC<Props> = ({
  platformCreds,
  setPagination,
  loadingApiSettings,
}) => {
  const [filteredPlatformCreds, setFilteredPlatformCreds] = useState(platformCreds)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })
  // main filter to check advertiser name
  const filters = useCallback(() => {
    const filterStatus = (settings) => {
      const campaignStatus = settings.isActive
      if (campaignStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }
    const filterSearchValue = (platform) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = platform.advertisers?.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        return splitItems.some((si) => platform.advertisers?.toLowerCase().includes(si))
      }
      return nameFilter
    }
    const filterData = platformCreds?.filter((settings) => filterSearchValue(settings) && filterStatus(settings))

    setFilteredPlatformCreds(filterData)
  }, [selectedFilters, platformCreds, setFilteredPlatformCreds])

  useEffect(() => {
    filters()
  }, [filters])

  return (
    <>
      <div className="sm:flex justify-between items-center">
        <AdminToolsMobileLink />
        <div className="flex justify-between w-full">
          <Search
            value={selectedFilters.search}
            onChange={(
              (e) => setSelectedFilters(
                handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
              )
            )}
            label="search"
            hideLabel
            placeholder="search..."
            className="mr-3 w-full"
          />
          <CreateButtons
            createText="Api Key"
            path="/admin-tools/api-settings/create-api-settings"
          />
        </div>
      </div>
      {platformCreds && (
        <ApiSettingsList
          totalData={filteredPlatformCreds.length}
          isLoading={loadingApiSettings}
          platformsCreds={filteredPlatformCreds}
          setPagination={setPagination}
          filterByActive={(isActive) => setSelectedFilters(handleFilters(isActive, 'status', selectedFilters))}
        />
      )}
    </>
  )
}

export default ApiSettings
