import {
  useContext,
} from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import Content from '../../../layouts/Content'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import { CampaignContext } from '../../../../contexts/CampaignContext'

const UpliftCTA:React.FC = () => {
  const { campaignInDb } = useContext(CampaignContext)

  const handleLocalStorage = (id) => {
    localStorage.setItem('uplift_id', JSON.stringify(id))
  }

  return (
    <>
      <Content
        title="uplift"
      >
        <p>Uplifts adjust a campaign&apos;s budget to include our fees. To create a new uplift or edit an existing uplift, go to the &quot;uplift&quot; page.</p>
        <br />
        <LinkWrapper
          disabled={campaignInDb.id < 1}
          target="_blank"
          href="/admin-tools/uplift/edit-uplift"
          variant="defaultAdmin"
          className="mb-6 -mt-3 block"
          onClick={() => handleLocalStorage(campaignInDb.id)}
        >
          Go to Uplift
          <Icon size="xxs" className={`${campaignInDb.id < 1}` ? 'cursor-not-allowed opacity-60' : ''}>
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper>
      </Content>
    </>
  )
}

export default UpliftCTA
