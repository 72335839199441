import React from 'react'
import { cls } from '../../helpers/utils'

// Manually set the colors of the dot using variant.

// TODO: address color values for different variants, not just legend or graph

const styles = {
  size: {
    textOnly: 'md:pb-0.5 md:pt-1 md:px-2',
    default: 'py-2 px-6',
    sm: 'pb-0.5 pt-1 px-2',
  },
  style: {
    dot: 'bg-transparent mr-0 text-default',
    badgeOnly: 'bg-grey-lightest',
  },
  variant: {
    dot: 'h-3 w-3 rounded-circle flex items-center justify-center mr-2',
    badgeOnly: 'hidden text-default',
  },
}

interface Props {
  size?: 'default' | 'sm' | 'textOnly'
  variant: 'dot' | 'badgeOnly'
  label: React.ReactNode
  dotColor?: string,
  badgeBackground?: string
  onClick?: (e) => void
  style?: any
}

const Badge: React.FC<Props> = ({
  variant,
  dotColor,
  label,
  badgeBackground,
  onClick,
  size,
  style,
}) => (
  <>
    <span onClick={onClick} aria-hidden="true" style={style} className={`font-display inline-flex mb-3 mr-3 items-center rounded-lg text-sm ${size && styles.size[size]} ${badgeBackground} ${onClick ? 'hover:cursor-pointer' : ''}`}>
      <span className={cls(`
                      ${dotColor}
                      ${styles.variant[variant]}
                      `)}
      />
      {label}
    </span>
  </>
)

Badge.defaultProps = {
  size: 'default',
  onClick: undefined,
  dotColor: 'bg-transparent',
  badgeBackground: 'bg-grey-lightest',
  style: undefined,
}

export default Badge
