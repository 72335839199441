import React, { useState, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ENV } from '../../env'
import { AppContext } from '../../contexts/AppContext'
import { IMIApi } from '../../api/imi/api'
import { ILogin, IAuthToken, IBrandingLogin } from '../../api/imi/interfaces'
import Button from '../button/Button'
import LoadingSpinner from '../LoadingSpinner'
import { REASON_SESSION_EXPIRED, REASON_SERVICE_OFFLINE } from '../../constants/Session'

const AuthForm: React.FC = () => {
  const {
    isSignedIn,
    serverError,
    signOutReason,
    signIn,
  } = useContext(AppContext)
  const { register, handleSubmit, formState: { errors } } = useForm<ILogin>()
  const [brandingLogin, setBrandingLogin] = useState<IBrandingLogin>({} as any)
  const [loading, setLoading] = useState<boolean>(true)

  // check if branding needs to be applied.
  useEffect(() => {
    const abortController = new AbortController()
    // TODO: get just the first part
    const url = window.location.hostname
    // console.log(url)
    // console.log(`https://${ENV.imiURL}`)
    if (url.toLowerCase().includes('localhost') || ENV.appEnvironment === 'staging') {
      setLoading(false)
      // return undefined
    } else if (url.toLowerCase() === (`${ENV.imiURL}`)) {
      setLoading(false)
      // return undefined
    } else {
      const checkBrandingUrl = async () => {
        const branding = await IMIApi.PostBrandingLogin(`https://${url}`, abortController.signal)
        if (!branding) {
          return
        }
        if (branding.status !== 200) {
          setLoading(false)
          return null
        }
        setBrandingLogin(branding.data)
        return setLoading(false)
      }
      checkBrandingUrl()
    }
    return () => abortController.abort()
  }, [])

  if (isSignedIn()) {
    return null
  }

  return (
    <div className="flex items-center justify-between min-h-screen bg-login bg-white">
      <div className="w-full px-6 min-h-screen bg-white flex flex-1 items-center z-10">
        {loading ? <LoadingSpinner size="lg" variant="primary" /> : (
          <form onSubmit={handleSubmit(signIn)} className="md:pr-12 pt-6 pb-8 mb-4 flex-1 max-w-half-site my-0 mx-auto">
            {brandingLogin?.email_template?.logo_url ? (
              <div
                className="heading-1 text-default block text-center py-2 mb-8 border-b border-grey-dark border-dotted md:max-w-login-form"
              >
                <div className="max-w-btn-lg py-3">
                  <img
                    src={brandingLogin?.email_template?.logo_url}
                    alt={brandingLogin?.email_template?.name}
                  />
                </div>
              </div>
            ) : (
              <div
                className="heading-1 text-default block text-center py-2 mb-8 border-b border-grey-dark border-dotted md:max-w-login-form"
              >
                <div className="max-w-btn-lg py-3">
                  <img
                    src={`https://${ENV.imiURL}/images/logos/interactive-marketing-intelligence-with-icon.svg`}
                    alt={brandingLogin?.email_template?.name}
                  />
                </div>
              </div>
            )}
            <div className="mb-4 md:max-w-login-form w-full">
              <label
                className="heading-5 font-semibold mb-2 block"
                htmlFor="username"
              >
                User name
                <span className="text-primary">*</span>
              </label>
              <input
                id="username"
                className="w-full block font-base text-xs text-default rounded border bg-grey-lightest border-grey-light px-3 py-2 min-h-btn focus:outline-none"
                type="username"
                // placeholder="Username"
                data-testid="username-input"
                {...register('username', { required: true })}
              />
              {errors.username && <span className="form-error">This field is required</span>}
            </div>
            <div className="mb-6 md:max-w-login-form">
              <label
                className="heading-5 font-semibold mb-2 block"
                htmlFor="password"
              >
                Password
                <span className="text-primary">*</span>
              </label>
              <input
                id="password"
                className="w-full block font-base text-xs text-default rounded border border-grey-light px-3 py-2 min-h-btn focus:outline-none bg-grey-lightest"
                type="password"
                // placeholder="Password"
                autoComplete="current-password"
                data-testid="password-input"
                {...register('password', { required: true })}
              />
              {errors.password && <span className="form-error">This field is required</span>}
            </div>
            {signOutReason === REASON_SESSION_EXPIRED && (
              <p className="text-red">
                My, how time flies. Due to inactivity, you’ve been signed out. Please sign in again.
              </p>
            )}
            {signOutReason === REASON_SERVICE_OFFLINE && (
              // <p className="text-red">
              //   We are updating our website.  Please try again in a few minutes.
              // </p>
              <Redirect to="/scheduled-maintenance" />
            )}
            <p className="text-red">{serverError}</p>
            <div className="">
              <Button
                variant="primary"
                id="submit-btn"
                type="submit"
                className="md:max-w-login-form"
              >
                Sign In
              </Button>
            </div>
            <div className="space-y-4">
              <a
                className="text-primary global-animation hover:text-secondary block font-bold font-base text-xxs"
                href="/forgot-password"
              >
                Forgot Password? ›
              </a>
              <a
                className="text-primary global-animation hover:text-secondary block font-bold font-base text-xxs"
                href="/forgot-username"
              >
                Forgot User Name? ›
              </a>
            </div>
          </form>
        )}
      </div>
      <div className="hidden md:block md:flex-1">
        <img
          src="images/dashboard-data-login-graph.png"
          alt="graph"
          className="hidden md:block xl:max-w-login-image relative p-24 ml-auto mr-auto"
        />
      </div>
    </div>
  )
}

export default AuthForm
