import { PDFDocument } from 'pdf-lib'
import download from 'downloadjs'
import { Theme, getColors, Colors } from './colors'
import { getPages, Pages } from './pages'
import { getFonts, Fonts } from './fonts'
import { getDrawing, Drawing } from './drawing'
import { getIcons, Icons } from './icons'
import { getImages, Images } from './images'
import { getText, Text } from './text'

export type PDF = {
  document: PDFDocument
  fonts: Fonts
  icons: Icons
  images: Images
  colors: Colors
  pages: Pages
  drawing: Drawing
  text: Text
  toBase64: () => Promise<string>
  download: (fileName: string) => Promise<void>
}

export const initPdf = async (
  imageUrls: string[],
  theme: Theme = {
    primary: '#40a4bc',
    primaryDark: '#3a7e99',
    secondary: '#29b48b',
    tertiary: '#1c4968',
  },
): Promise<PDF> => {
  const document = await PDFDocument.create()
  const fonts = await getFonts(document)
  const colors = getColors(theme)
  const pages = getPages(document, colors, fonts)
  const text = getText(fonts, pages)
  const drawing = getDrawing(pages, colors)
  const icons = getIcons(pages, colors)
  const images = await getImages(imageUrls, pages, drawing, colors, document)
  
  return {
    document,
    fonts,
    icons,
    images,
    colors,
    pages,
    drawing,
    text,
    toBase64: () => document.saveAsBase64({ dataUri: true }),
    download: async (fileName: string) => {
      const pdfBytes = await document.save()
      download(pdfBytes, fileName, 'application/pdf')
    },
  }
}
