import {
  createContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { Theme } from '../pdf/lib'
import { createPdf } from '../pdf'
import { 
  ISemDetail,
  ICallsOverview, 
  ICallsByCampaignAndStatus,
} from '../api/imi/interfaces'

interface IPdfData {
  sem?: ISemDetail
  overview?: ICallsOverview
  calls?: ICallsByCampaignAndStatus
  images?: string[]
  theme?: Theme
  userType?: number
}

interface IPdfContext {
  downloadPdf: () => Promise<void>
  setPdfImages: (...images: string[]) => void
  setPdfTheme: (theme: Theme) => void
  setSemData: (sem: ISemDetail) => void
  setOverviewData: (overview: ICallsOverview) => void
  setCallsData: (calls: ICallsByCampaignAndStatus) => void
  setUserType: (type: number) => void
}

export function usePdfContextFactory() {
  const dataRef = useRef<IPdfData>({})
  const setSemData = useCallback((sem: ISemDetail) => {
    dataRef.current.sem = sem
  }, [])

  const setOverviewData = useCallback((overview: ICallsOverview) => {
    dataRef.current.overview = overview
  }, [])

  const setCallsData = useCallback((calls: ICallsByCampaignAndStatus) => {
    dataRef.current.calls = calls
  }, [])

  const setPdfImages = useCallback((...images: string[]) => {
    dataRef.current.images = images
  }, [])

  const setPdfTheme = useCallback((theme: Theme) => {
    dataRef.current.theme = theme
  }, [])

  const setUserType = useCallback((type: number) => {
    dataRef.current.userType = type
  }, [])

  const downloadPdf = useCallback(async () => {
    // Get the campaign full name
    let campName = dataRef.current.sem?.campaign?.name
    const secName = dataRef.current.sem?.campaign?.secondary_name
    const specific = dataRef.current.sem?.campaign?.specific?.name

    if (secName) {
      campName = `${campName}: ${secName}`
    }
    if (secName && specific) {
      campName = `${campName}: ${secName} - ${specific}`
    }
    if (!secName && specific) {
      campName = `${campName} - ${specific}`
    }
    
    if (dataRef.current.sem 
      && (dataRef.current.overview || !dataRef.current.sem.call_processing_is_active)
      && dataRef.current.calls
      && dataRef.current.images
      && dataRef.current.theme
      && dataRef.current.userType !== undefined
    ) {
      await createPdf(
        `${campName} IMI DIVE Report`,
        dataRef.current.userType, 
        dataRef.current.sem,
        dataRef.current.overview,
        dataRef.current.calls,
        dataRef.current.theme,
        ...dataRef.current.images,
      )
    }
  }, [])

  return {
    setSemData,
    setPdfImages,
    setPdfTheme,
    setOverviewData,
    setCallsData,
    setUserType,
    downloadPdf,
  } as IPdfContext
}

export const PdfContext = createContext<IPdfContext>({} as any)
