import {
  useMemo,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  FormProvider,
  useForm,
  SubmitHandler,
} from 'react-hook-form'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import { ICampaignUpliftInDb } from '../../../../api/imi/interfaces'
import EditMenu from '../../EditMenu'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import CycleUpliftForm from '../../../form/campaigns/CycleUpliftForm'
import { fixDateObj, fixDateObjApi } from '../../../../helpers/utils'
import CycleBudgetForm from '../../../form/campaigns/CycleBudgetForm'

interface Props{
  campaignUplift: any
  campaignId: number
  campaignType: number
  setRefresh: Dispatch<SetStateAction<number>>
}

const EditUpliftCycleFormProvider:React.FC<Props> = ({
  campaignUplift,
  campaignId,
  campaignType,
  setRefresh,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const { setError } = useContext(AppContext)
  const [editUplift, setEditUplift] = useState<any>()
  const [edit, setEdit] = useState<boolean>(true)

  useEffect(() => {
    setEditUplift(campaignUplift)
  }, [campaignUplift])

  const methods = useForm<ICampaignUpliftInDb>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: editUplift?.id,
      uplift: editUplift?.uplift,
      spend: editUplift?.spend,
      budget: editUplift?.budget,
      start_date: editUplift?.start_date,
      end_date: editUplift?.end_date,
    }), [editUplift]),
  })
  // TODO: refetch editUplift values when uplift/budget is changed
  // in order to get new pcampaign id's

  useEffect(() => {
    methods.reset({ ...editUplift })
  }, [methods, editUplift])

  const cancel = () => {
    methods.reset(editUplift, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }
  const editUser = () => {
    setEdit(true)
    setShowButton(true)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    setEditUplift(data)
    const formData = {
      id: campaignId,
      uplift: data.uplift,
      cycle_id: data.id,
      budget: data.uplift_budget,
    }
    const submitResponse = await IMIApi.SetCampaignUpliftById(formData)

    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setRefresh((prevState) => prevState + 1)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  const internationalNumberFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })

  return (
    <FormProvider {...methods} key={editUplift?.id}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        className="odd:bg-grey-lightest pt-6 pb-2 horizontal-grid"
      >
        <HorizontalGrid
          smGrid={campaignType === 2 ? 'grid-cols-4' : 'grid-cols-5'}
          mdGrid={campaignType === 2 ? 'grid-cols-4' : 'grid-cols-5'}
        >
          <p className="text-xxs pr-3">
            {fixDateObj(editUplift?.start_date)}
          </p>
          <CycleUpliftForm
            cycle={editUplift}
            errors={methods.formState.errors}
            edit={showButton}
          />
          {/* Source */}
          <p className="text-xxs pr-3">
            $
            {internationalNumberFormat.format(editUplift?.budget)}
          </p>
          {/* uplifted budget from source */}
          <p className="text-xxs pr-3">
            $
            {internationalNumberFormat.format(Math.round(((editUplift?.uplift / 100) * (editUplift?.budget)) + editUplift?.budget))}
          </p>
          {/* pinned/set budget */}
          {campaignType !== 2 && (
            <CycleBudgetForm
              cycle={editUplift}
              errors={methods.formState.errors}
              edit={showButton}
            />
          )}
          <FormButtonGroup
            key={editUplift?.id}
            isButtonLoading={isButtonLoading}
            disabled={!methods.formState.isDirty || !methods.formState.isValid}
            disabledCancel={false}
            showButton={showButton}
            onClickCancel={cancel}
            btnText={btnText}
          />
          <EditMenu
            text="Edit Uplift"
            id={editUplift?.id}
          >
            <a
              key={editUplift?.id}
              onClick={editUser}
              role="button"
              onKeyDown={editUser}
              tabIndex={0}
              className="front-display global-animation text-xs text-opacity-80 text-light hover:text-opacity-100 cursor-pointer hover:font-bold"
            >
              Edit
            </a>
          </EditMenu>
        </HorizontalGrid>
      </form>
    </FormProvider>
  )
}

export default EditUpliftCycleFormProvider
