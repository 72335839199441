import { 
  useState, 
  useRef, 
  useEffect, 
  useContext,
  useMemo,
} from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { commaNumber, fixDateObj } from '../../helpers/utils'
import BadgeDot from '../badge/BadgeDot'
import BadgeInfo from '../badge/BadgeInfo'
import Content from '../layouts/Content'
import Dropdown from '../form/Dropdown'
import ToolTip from '../ToolTip'
import { IVideoQuartilesGraph } from '../../api/imi/interfaces'
import LineAndBarGraph from '../charts/LineAndBarGraph'
import LoadingSpinner from '../LoadingSpinner'
import { ThemeContext } from '../../contexts/ThemeContext'

interface Props {
  data: IVideoQuartilesGraph
}

const typeMap = {
  TWENTYFIVE: '25% Complete',
  FIFTY: '50% Complete',
  SEVENTYFIVE: '75% Complete',
  ONEHUNDRED: '100% Complete',
}

const options = [
  {
    id: 0,
    name: 'All',
    value: 0,
  },
  {
    id: typeMap.TWENTYFIVE,
    name: typeMap.TWENTYFIVE,
    value: typeMap.TWENTYFIVE,
  },
  {
    id: typeMap.FIFTY,
    name: typeMap.FIFTY,
    value: typeMap.FIFTY,
  },
  {
    id: typeMap.SEVENTYFIVE,
    name: typeMap.SEVENTYFIVE,
    value: typeMap.SEVENTYFIVE,
  },
  {
    id: typeMap.ONEHUNDRED,
    name: typeMap.ONEHUNDRED,
    value: typeMap.ONEHUNDRED,
  },
]

const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'

const VideoCompletionQuartilesReport:React.FC<Props> = ({
  data,
}) => {
  const { colors } = useContext(ThemeContext)
  const [reportData, setReportData] = useState<any>()
  const [typeFilter, setTypeFilter] = useState<any>(0)
  const chartRef = useRef<HighchartsReact.RefObject>(null)

  const colorMap = useMemo(() => ({
    Completion25: colors.primary,
    Completion50: colors.tertiary,
    Completion75: '#85619b',
    Completion100: colors.secondary,
  }), [colors])
  
  // useEffect(() => {
  //   if (chartRef.current) {
  //     console.log('hit')
  //     while (chartRef.current.chart.series.length > 0) {
  //       chartRef.current.chart.series[0].remove(false)
  //     }
  //     chartRef.current.chart.redraw()
  //   }
  // }, [typeFilter])

  useEffect(() => {
    if (data.series?.length) {
      setReportData({
        lines: [
          {
            type: typeMap.TWENTYFIVE,
            name: data.series[0].name,
            data: data.series[0].data,
            color: colorMap.Completion25,
          },
          {
            type: typeMap.FIFTY,
            name: data.series[1].name,
            data: data.series[1].data,
            color: colorMap.Completion50,
          },
          {
            type: typeMap.SEVENTYFIVE,
            name: data.series[2].name,
            data: data.series[2].data,
            color: colorMap.Completion75,
          },
        ],
        bars: [
          {
            type: typeMap.ONEHUNDRED,
            name: data.series[3].name,
            data: data.series[3].data,
            color: colorMap.Completion100,
          },
        ],
        labels: data.series[4].data?.map((d) => fixDateObj(d)),
      })
    }
  }, [colorMap, data])

  return (
    <Content 
      title="completion quartiles"
      icon={(
        <div className="flex gap-3">
          <Dropdown
            variant="default"
            label="Quartiles"
            list={options}
            defaultValue={typeFilter}
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(e) => {
              if (chartRef.current && typeFilter !== e) {
                while (chartRef.current.chart.series.length > 0) {
                  chartRef.current.chart.series[0].remove(false)
                }
              }
              setTypeFilter(e)
            }}
            className="max-w-dropdown"
          />
        </div>
      )}
    >
      {!data ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
        <div id="chart-container-quartiles">
          {reportData?.lines?.length ? (
            <>
              <div className="pb-6">
                <BadgeDot variant="dot" dotColor="bg-primary" label="25% Complete" />
                <BadgeDot variant="dot" dotColor="bg-tertiary" label="50% Complete" />
                <BadgeDot variant="dot" dotColor="bg-purple" label="75% Complete" />
                <BadgeDot variant="dot" dotColor="bg-secondary" label="100% Complete" />
              </div>

              <div id="quartiles-graph" className="pb-6">
                <LineAndBarGraph
                  id="quartiles"
                  chartRef={chartRef}
                  lines={typeFilter ? reportData.lines.filter((l: any) => l.type === typeFilter) : reportData.lines}
                  bars={typeFilter ? reportData.bars.filter((b: any) => b.type === typeFilter) : reportData.bars}
                  labels={reportData.labels}
                  tooltip={(point) => {
                    const index = reportData.labels.findIndex((label) => label === point.category)

                    return `<span style="color: #fff; font-family: Nunito Sans; font-size: 14px; padding: 10px 5px 5px 5px; font-weight: bold; display: block;">${point?.category}</span>
                    <span style="background-color: ${colorMap.Completion25}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">25% Complete: <b>${((commaNumber(reportData.lines[0].data[index]) * 100)).toFixed(2)}%</b></span><br>
                    <span style="background-color: ${colorMap.Completion50}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">50% Complete: <b>${((commaNumber(reportData.lines[1].data[index]) * 100)).toFixed(2)}%</b></span><br>
                    <span style="background-color: ${colorMap.Completion75}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">75% Complete: <b>${((commaNumber(reportData.lines[2].data[index]) * 100)).toFixed(2)}%</b></span><br>
                    <span style="background-color: ${colorMap.Completion100}; height: 10px; width:10px; border-radius: 50%; display: inline-block"></span><span span style="color: #fff; font-family: Heebo; padding: 10px 5px 10px 5px;">100% Complete: <b>${((commaNumber(reportData.bars[0].data[index]) * 100)).toFixed(2)}%</b></span><br>`
                  }}
                />
              </div>
            </>
          ) : (
            <p>{noDataMessage}</p>
          )}
        </div>
      )}
    </Content>
  )
}

export default VideoCompletionQuartilesReport
