import { Dispatch, SetStateAction, useEffect } from 'react'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { UserContext } from '../../../contexts/UserContext'
import Input from '../Input'
import Radio from '../Radio'
import Dropdown from '../Dropdown'
import Icon from '../../Icon'
import { splitComma } from '../../../helpers/utils'
import LinkWrapper from '../../Links'
import TextArea from '../TextArea'
import { ICampaignUpliftInDb } from '../../../api/imi/interfaces'

interface Props {
  cycle: any
  errors: any
  edit: boolean
}

const CycleUpliftForm:React.FC<Props> = ({
  cycle,
  errors,
  edit,
}) => {
  const methods = useFormContext()

  return (
    <>
      {edit ? (
        <Input
          label="cycle uplift"
          hideLabel
          type="text"
          className="percent-split"
          {...methods.register('uplift', {
            pattern: {
              value: /^-?[0-9]\d*(\.\d+)?$/,
              message: 'Uplift value is not the correct format. Use numbers and/or decimal value only. If entering in a decimal, please add in at least one digit following decimal.',
            },
          })}
          error={errors.uplift && <span className="form-error mt-3">{errors.uplift?.message || 'This field is required.'}</span>}
        />
      ) : (
        <p className="text-xxs text-gray-500">
          {`${cycle?.uplift}%`}
        </p>
      )}
      <input type="number" value={cycle?.cycle_id} readOnly {...methods.register('cycle_id')} hidden />
      <input type="number" value={cycle?.id} readOnly {...methods.register('id')} hidden />
    </>
  )
}

export default CycleUpliftForm
