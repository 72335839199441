import { VolumeUpIcon } from '@heroicons/react/solid'
import Icon from '../Icon'

export const styles = {
  mobile: 'font-bold lg:hidden',
  desktop: 'hidden lg:block',
  icon: 'lg:hidden absolute right-6',
  expand: 'block',
  collapse: 'hidden',
  date: '',
}

export const enhancedCallAnalyticsMenu = [
  {
    fieldName: 'occurred_at',
    name: 'Date/Time',
    mobile: false,
    sort: true,
    abbreviation: 'D/T',
  },
  {
    fieldName: ['first_name', 'last_name', 'phone_work', 'company', 'address1', 'city', 'state', 'postal'],
    name: 'Customer',
    mobile: true,
  },
  {
    fieldName: 'recording_url',
    name: 'Audio',
    mobile: false,
    abbreviation: 'Aud',
    // abbreviation: <Icon className="hover:text-primary"><VolumeUpIcon /></Icon>,
    info: 'Call recordings are available for 30 days. To save a recording to your computer, right click on the audio icon and select "Save Target As…” or “Save Link As…” On mobile devices, click and hold on the audio icon and select "Download Linked File."',
  },
  {
    fieldName: 'duration',
    name: 'Duration',
    mobile: true,
  },
  {
    fieldName: 'category.name',
    name: 'Category',
    mobile: false,
    sort: true,
    abbreviation: 'Cat',
  },
  {
    fieldName: 'notes',
    name: 'Notes',
    mobile: true,
    sort: false,
  },
  {
    fieldName: 'is_flagged',
    name: 'Status',
    mobile: false,
    sort: true,
    abbreviation: 'Sts',
  },
]
