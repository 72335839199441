import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../../contexts/AppContext'
import { UserContext } from '../../../contexts/UserContext'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import EditUser from '../../../components/adminTools/accounts/user_settings/EditUser'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { newUserInDb } from '../../../api/imi/factories'

const EditUserPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [userTypesData, setUserTypesData] = useState<any>([])
  const { setError } = useContext(AppContext)
  const { userInDb, setUserInDb } = useContext(UserContext)
  const [userId, setUserId] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchUserTypes = async () => {
      const response = await IMIApi.GetAllUserTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setUserTypesData(response.data.data)
      return setLoading(false)
    }
    fetchUserTypes()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const items = localStorage.getItem('user_in_db')
    if (items) {
      sessionStorage.setItem('user_in_db', items)
      localStorage.removeItem('user_in_db')
    }
    const getSessionItems = sessionStorage.getItem('user_in_db')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setUserId(parseItems)
      }
    }
  }, [setUserId])

  useEffect(() => {
    setUserInDb(newUserInDb())
    const abortController = new AbortController()
    if (userId > 0) {
      const fetchUser = async () => {
        const response = await IMIApi.GetUserById(userId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON.parse(response)
          setError({ errorMsg: parse.detail })
          return setLoading(false)
        }
        setUserInDb(response.data)
        return setLoading(false)
      }
      fetchUser()
    }
    return () => abortController.abort()
  }, [setError, setUserInDb, userId])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <EditUser
            isLoading={loading}
            userTypes={userTypesData}
            edit
          />
        )}
      />
    </Padding>
  )
}
export default EditUserPage
