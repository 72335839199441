import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../contexts/AppContext'
import ErrorNotification from './notification/ErrorNotification'
import { ThemeContext } from '../contexts/ThemeContext'

interface Props {
  children?: React.ReactNode
  variant?: string
  size?: 'sm' | 'md' | 'lg'
  noPadding? : boolean
}

const LoadingSpinner:React.FC<Props> = ({
  children,
  variant,
  size,
  noPadding,
}) => {
  const { colors } = useContext(ThemeContext)
  const [hide, setHide] = useState<boolean>(false)
  const { error, setError } = useContext(AppContext)

  const styles = {
    variant: {
      primary: colors.primary,
      light: '#fff',
    },
    size: {
      sm: 20,
      md: 27,
      lg: 50,
    },
  }
  // If response takes longer than 10 seconds, throws an error
  // automatically.
  useEffect(() => {      
    const timeout = setTimeout(() => {
      setHide(true)
    }, 100000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (hide) {
      setError({
        errorMsg: 'Something went wrong. Please try refreshing the page.',
      })
    }
  }, [hide, setError])

  return (
    <span className="block">
      {!hide && (
        <div className={noPadding ? '' : 'px-2'}>
          <svg
            className="spinner text-center w-full"
            xmlns="http://www.w3.org/2000/svg"
            width={size && styles.size[size]}
            height={size && styles.size[size]}
            x="0"
            y="0"
            enableBackground="new 0 0 50 50"
            viewBox="136 136 27 27"
          >
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M149.029 136c-.654 0-1.185.53-1.185 1.184v4.738a1.185 1.185 0 002.37 0v-4.738c0-.654-.531-1.184-1.185-1.184z"
            >
              <animate
                attributeName="opacity"
                begin="0.1s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M154.892 144.841l3.35-3.35a1.185 1.185 0 00-1.675-1.674l-3.35 3.35a1.185 1.185 0 001.675 1.674z"
            >
              <animate
                attributeName="opacity"
                begin="0.2s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M160.873 147.844h-4.738a1.185 1.185 0 000 2.37h4.738a1.184 1.184 0 000-2.37z"
            >
              <animate
                attributeName="opacity"
                begin="0.3s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M154.892 153.217a1.185 1.185 0 00-1.675 1.674l3.35 3.35a1.184 1.184 0 101.674-1.675l-3.35-3.35z"
            >
              <animate
                attributeName="opacity"
                begin="0.4s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M149.029 154.95c-.654 0-1.185.531-1.185 1.185v4.738a1.185 1.185 0 002.37 0v-4.738c0-.654-.531-1.184-1.185-1.184z"
            >
              <animate
                attributeName="opacity"
                begin="0.5s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M143.166 153.217l-3.35 3.35a1.185 1.185 0 001.675 1.675l3.35-3.35a1.184 1.184 0 10-1.675-1.675z"
            >
              <animate
                attributeName="opacity"
                begin="0.6s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M143.107 149.029c0-.654-.53-1.185-1.185-1.185h-4.738a1.184 1.184 0 000 2.37h4.738c.654 0 1.185-.531 1.185-1.185z"
            >
              <animate
                attributeName="opacity"
                begin="0.7s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
            <path
              fill={variant && styles.variant[variant]}
              fillRule="evenodd"
              stroke="none"
              d="M141.491 139.816a1.184 1.184 0 10-1.675 1.675l3.35 3.35a1.185 1.185 0 001.675-1.675l-3.35-3.35z"
            >
              <animate
                attributeName="opacity"
                begin="0.8s"
                dur="0.8s"
                from="1"
                repeatCount="indefinite"
                to="0"
              />
            </path>
          </svg>
          {children}
        </div>
      )}
    </span>
  )
}

export default LoadingSpinner
