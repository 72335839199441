import { renderToString } from 'react-dom/server'
import { ReactComponent as CircleIcon } from '../../assets/icons/circle-bg.svg'
import { ReactComponent as EmailLeadsIcon } from '../../assets/icons/email-leads-icon.svg'
import { ReactComponent as ImpressionsIcon } from '../../assets/icons/impressions-icon.svg'
import { ReactComponent as ClicksIcon } from '../../assets/icons/clicks-icon.svg'
import { ReactComponent as ConversionsIcon } from '../../assets/icons/conversions-icon.svg'
import { ReactComponent as LeadsIcon } from '../../assets/icons/leads-icon.svg'
import { ReactComponent as TotalCallLeadsIcon } from '../../assets/icons/total-call-leads-icon.svg'
import { ReactComponent as TotalCallsIcon } from '../../assets/icons/total-calls-icon.svg'
import { ReactComponent as TotalFlaggedCallsIcon } from '../../assets/icons/total-flagged-calls-icon.svg'
import { ReactComponent as TotalPostClicksIcon } from '../../assets/icons/total-post-clicks-icon.svg'
import { ReactComponent as TotalPostImpressionsIcon } from '../../assets/icons/total-post-impressions-icon.svg'
import { ReactComponent as TotalWebEventLeadsIcon } from '../../assets/icons/total-web-event-leads-icon.svg'
import { ReactComponent as TotalWebEventsIcon } from '../../assets/icons/total-web-events-icon.svg'
import { Pages } from './pages'
import { Colors } from './colors'

const getSvgPaths = (SVG: React.FunctionComponent) => {
  const svgString = renderToString(<SVG />)
  const container = document.createElement('div')
  container.innerHTML = svgString
  const paths = container.getElementsByTagName('path')
  return Array.from(paths).map((elem) => elem.getAttribute('d') as string)
}

const drawIcon = (pages: Pages, colors: Colors, radius: number, paths: string[]) => {
  const [circlePath1, circlePath2, ...iconPaths] = paths
  pages.currentPage.drawSvgPath(circlePath1, { 
    color: colors.primary,
    scale: radius / 45,
  })

  pages.currentPage.drawSvgPath(circlePath2, { 
    color: colors.primaryDark,
    scale: radius / 45,
  })

  iconPaths.forEach((p: string) => {
    pages.currentPage.drawSvgPath(p, { 
      color: colors.white,
      scale: radius / 45,
    })
  })
}

export type Icons = {
  drawEmailLeadsIcon: (radius: number) => void
  drawImpressionsIcon: (radius: number) => void
  drawClicksIcon: (radius: number) => void
  drawConversionsIcon: (radius: number) => void
  drawLeadsIcon: (radius: number) => void
  drawTotalCallLeadsIcon: (radius: number) => void
  drawTotalCallsIcon: (radius: number) => void
  drawTotalFlaggedCallsIcon: (radius: number) => void
  drawTotalPostClicksIcon: (radius: number) => void
  drawTotalPostImpressionsIcon: (radius: number) => void
  drawTotalWebEventLeadsIcon: (radius: number) => void
  drawTotalWebEventsIcon: (radius: number) => void
}

// all icons are 45x45
export const getIcons = (pages: Pages, colors: Colors): Icons => {
  const impressionsPaths = getSvgPaths(ImpressionsIcon)
  const clicksPaths = getSvgPaths(ClicksIcon)
  const conversionsPaths = getSvgPaths(ConversionsIcon)
  const leadsPaths = getSvgPaths(LeadsIcon)
  const totalCallLeadsPaths = getSvgPaths(TotalCallLeadsIcon)
  const totalCallsPaths = getSvgPaths(TotalCallsIcon)
  const totalFlaggedCallsPaths = getSvgPaths(TotalFlaggedCallsIcon)
  const totalPostClicksPaths = getSvgPaths(TotalPostClicksIcon)
  const totalPostImpressionsPaths = getSvgPaths(TotalPostImpressionsIcon)
  const totalWebEventLeadsPaths = getSvgPaths(TotalWebEventLeadsIcon)
  const totalWebEventsPaths = getSvgPaths(TotalWebEventsIcon)
  const emailLeadsPaths = getSvgPaths(EmailLeadsIcon)
  
  return {
    drawEmailLeadsIcon: (radius: number) => drawIcon(pages, colors, radius, emailLeadsPaths),
    drawImpressionsIcon: (radius: number) => drawIcon(pages, colors, radius, impressionsPaths),
    drawClicksIcon: (radius: number) => drawIcon(pages, colors, radius, clicksPaths),
    drawConversionsIcon: (radius: number) => drawIcon(pages, colors, radius, conversionsPaths),
    drawLeadsIcon: (radius: number) => drawIcon(pages, colors, radius, leadsPaths),
    drawTotalCallLeadsIcon: (radius: number) => drawIcon(pages, colors, radius, totalCallLeadsPaths),
    drawTotalCallsIcon: (radius: number) => drawIcon(pages, colors, radius, totalCallsPaths),
    drawTotalFlaggedCallsIcon: (radius: number) => drawIcon(pages, colors, radius, totalFlaggedCallsPaths),
    drawTotalPostClicksIcon: (radius: number) => drawIcon(pages, colors, radius, totalPostClicksPaths),
    drawTotalPostImpressionsIcon: (radius: number) => drawIcon(pages, colors, radius, totalPostImpressionsPaths),
    drawTotalWebEventLeadsIcon: (radius: number) => drawIcon(pages, colors, radius, totalWebEventLeadsPaths),
    drawTotalWebEventsIcon: (radius: number) => drawIcon(pages, colors, radius, totalWebEventsPaths),
  }
}
