import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext'
import { newUserMe } from '../api/imi/factories'
import { IUserMe } from '../api/imi/interfaces'

export const cls = (input) => input
  .replace(/\s+/gm, ' ')
  .split(' ')
  .filter((cond) => typeof cond === 'string')
  .join(' ')
  .trim()

// returns dd-mm-yyyy format into mm/dd/yyyy
export const rearrangeDate = (dateString) => {
  const numbers = dateString.substring(0, 4)
  const slash = '/'
  return dateString.substring(5)
    .replace(/-/g, '/') + slash + numbers
}

// gets data object and turns into comma delimited file
export const csvDownload = (info, id, filename, slice?) => {
  const items = info
  const replacer = (key, value) => (value === null ? '' : value)
  const header = Object.keys(items[0])
  if (slice) {
    const removeDataHeader = header.slice(0, slice)
    const csv = [
      removeDataHeader.join(','),
      ...items.map((row) => removeDataHeader.map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')),
    ].join('\r\n')
    // Create download link element
    const a2 = (document.getElementById(id) as any)
    const downloadLink = document.createElement('a')
    a2.appendChild(downloadLink)
    // a2.download = filename
    a2.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
    // change the name of the downloaded file
    // window.URL.revokeObjectURL ???
    a2.download = filename
    return
  }
  const csv = [
    header.join(','),
    ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')),
  ].join('\r\n')
  // Create download link element
  const a2 = (document.getElementById(id) as any)
  const downloadLink = document.createElement('a')
  a2.appendChild(downloadLink)
  // a2.download = filename
  a2.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
  // change the name of the downloaded file
  // window.URL.revokeObjectURL ???
  a2.download = filename
}

export const columnSortingInfo = (data, fieldName, setSortColumn, sortDirection, setSortDirection) => {
  setSortColumn(fieldName)
  const sorting = sortDirection ? setSortDirection(false) : setSortDirection(true)
  const values = sortDirection ? data.search_intervals.sort((a, b) => (b[fieldName] > a[fieldName] ? 1 : -1)) : data.search_intervals.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
  return {
    sorting,
    values,
  }
}

export const sum = (data, metric: string) => (data.reduce((value1, value2) => value1 + +value2[metric], 0))

// convert dates to date format to get difference in days accurately
export const dateDiff = (startDate, endDate) => {
  const campStart = new Date(startDate)
  // const campEnd = new Date(endDate)
  const today = new Date()
  const difference = today.getTime() - campStart.getTime()
  const differenceInDays = difference / (1000 * 3600 * 24)
  // if (endDate) {
  //   const campEndDate = campEnd.getTime() - campStart.getTime()
  //   const campEndDifferenceInDays = campEndDate / (1000 * 3600 * 24)
  //   return Math.round(campEndDifferenceInDays)
  // }
  return Math.round(differenceInDays)
}

// days left is the budget - spent, then divided by actual daily spend
export const daysLeft = (startDate, endDate, budget, spent) => {
  const left = dateDiff(startDate, endDate)
  const actSpend = (spent / Number(left)).toLocaleString(undefined, { maximumFractionDigits: 2 })
  return (Math.abs((budget - spent) / Number(actSpend))
    .toFixed(1))
}

// looks at start and ent date of campaign
// calculates total actual spend and divide it by
export const actDailySpend = (startDate, endDate, spent) => {
  const left = dateDiff(startDate, endDate)
  return Math.round(spent / Number(left))
    .toLocaleString(undefined, { maximumFractionDigits: 2 })
}

// days it will actually be running based on actual spend
export const daysTotal = (startDate, endDate, budget, spent) => {
  const spend = actDailySpend(startDate, endDate, spent)
  return (budget / Number(spend)).toFixed()
}

export const handleToggle = (checkboxId, previousState) => {
  const currentIndex = previousState.indexOf(checkboxId)
  const newCheckedArray = [...previousState]
  if (currentIndex === -1) {
    newCheckedArray.push(checkboxId)
  } else {
    newCheckedArray.splice(currentIndex, 1)
  }
  return newCheckedArray.sort((a, b) => a - b)
}

// split on comma for react-hook-form submission
export const splitComma = (value) => (
  value.split(/[,]+/)
)

// set filters that take in multiple parameters.
// see ActiveUsers.tsx for example.
export const handleFilters = (filterState, key, selectedFilters) => {
  const newFilters = { ...selectedFilters }
  newFilters[key] = filterState
  return newFilters
}

export const getFieldByPath = (obj: any, path: string, delimiter = '.') => {
  const paths = path.split(delimiter)
  let field = obj
  // eslint-disable-next-line no-restricted-syntax
  for (const p of paths) {
    if (field[p]) {
      field = field[p]
    }
  }
  return field?.toString()
    .toLowerCase()
}

export interface IPlatform {
  id: number
  name: string
}

export const getPlatformNames = (platforms: IPlatform[]) => {
  const namesSet = new Set(platforms.map((p) => p.name))
  const nameMap = [
    ['StackAdapt', 'SA'],
    ['ReachLocal', 'RL'],
  ]

  return nameMap.map((pair) => {
    if (namesSet.has(pair[0])) {
      return pair[1]
    }
    return null
  })
}

export const fixDateObj = (date) => {
  const dateObj = new Date(date?.toLocaleString())
  const newDateObj = {
    month: dateObj.getUTCMonth() + 0o1,
    day: dateObj.getUTCDate(),
    year: dateObj.getUTCFullYear(),
  }
  const fixMonth = newDateObj.month < 10 ? (`0${newDateObj.month}`) : newDateObj.month
  const fixDay = newDateObj.day < 10 ? (`0${newDateObj.day}`) : newDateObj.day
  const fixYear = newDateObj.year.toString()
    .slice(2)
  return `${fixMonth}/${fixDay}/${fixYear}`
}

export const fixDateTimeObj = (date) => {
  const dateObj = new Date(date)
  return dateObj.toLocaleString('en-US', {
    month: '2-digit',
    year: '2-digit',
    day: '2-digit',
  })
    .toString()

  // @Christine - The commented code block was resulting in the issue on the ticket https://trello.com/c/VB2ZGLGM.
  // I've implemented the change above; tagging you in case there's a scenario this function covers that I'm unaware of.

  // const newDateObj = {
  //   month: dateObj.getMonth() + 0o1,
  //   day: dateObj.getDate(),
  //   year: dateObj.getFullYear(),
  // }
  // const fixMonth = newDateObj.month < 10 ? (`0${newDateObj.month}`) : newDateObj.month
  // const fixDay = newDateObj.day < 10 ? (`0${newDateObj.day}`) : newDateObj.day
  // const fixYear = newDateObj.year.toString()
  //   .slice(2)
  // return `${fixMonth}/${fixDay}/${fixYear}`
}

export const fixHoursObj = (date) => {
  const dateObj = new Date(date)
  const newHoursObj = {
    hours: dateObj.getHours(),
    minutes: dateObj.getMinutes(),
    seconds: dateObj.getSeconds(),
  }
  const fixHours = newHoursObj.hours < 10 ? (`0${newHoursObj.hours}`) : newHoursObj.hours
  const fixMin = newHoursObj.minutes < 10 ? (`0${newHoursObj.minutes}`) : newHoursObj.minutes
  const fixSec = newHoursObj.seconds < 10 ? (`0${newHoursObj.seconds}`) : newHoursObj.seconds
  return `${fixHours}:${fixMin}:${fixSec}`
}

export const fixDateObjApi = (date) => {
  const dateObj = new Date(date)
  const newDateObj = {
    month: dateObj.getUTCMonth() + 0o1,
    day: dateObj.getUTCDate(),
    year: dateObj.getUTCFullYear(),
  }
  // const newDateObj = {
  //   month: dateObj.getMonth() + 0o1,
  //   day: dateObj.getDate(),
  //   year: dateObj.getFullYear(),
  // }
  const fixMonth = newDateObj.month < 10 ? (`0${newDateObj.month}`) : newDateObj.month
  const fixDay = newDateObj.day < 10 ? (`0${newDateObj.day}`) : newDateObj.day
  const fixYear = newDateObj.year.toString()
  return `${fixYear}-${fixMonth}-${fixDay}`
}

export const commaNumber = (number) => (
  // new Intl.NumberFormat('en-IN').format(number)
  number.toLocaleString()
)

export const getDefaultHomePage = (user: IUserMe) => {
  const result = {
    pathname: '/advertiser-dashboard',
    state: {},
  }
  const {
    user_type_id: userType,
    advertisers,
  } = user
  // const { user_type_id: userType, advertisers } = user
  // const advertiser_ids = advertisers.map((a) => a.id)
  // TODO: Refactor to user type constants
  // 1: admin
  // 2: team viewer
  // 3: call manager
  // 4: call analyst
  // 5: partner
  // 6 client
  if (userType === 4) {
    result.pathname = '/admin-tools/call-processing'
  }

  if (userType === 6) {
    if (advertisers.length < 2) {
      result.pathname = '/campaign-overview'
    }
    return result
  }

  if (userType === 5 || userType === 3 || userType === 2) {
    result.pathname = '/advertiser-dashboard'
  }

  return result
}

interface IFileDescriptor {
  contentType: string
  filename: string
}

/**
 * Returns the content-type value from an axios header object.
 * @param headers, an axios header object
 */
export const getFileDescriptor = (headers: any): IFileDescriptor => {
  if (typeof headers === 'object') {
    const ct = headers['content-type'].split(';')[0]
    const regex = /filename="(.+)"/
    const matches = headers['content-disposition'].match(regex)
    const fileName = matches[1]
    // const fn = headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '')
    return {
      contentType: ct,
      filename: fileName,
    }
  }

  // todo handle parsing content type from the filename; other edge cases.
  return {
    contentType: 'text/csv',
    filename: 'download.csv',
  }
}
