import { useContext, useEffect, useState } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import DashboardBranding from '../../../components/adminTools/accounts/dashboard_branding/DashboardBranding'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { AppContext } from '../../../contexts/AppContext'
import { IBrandingMultiResponse } from '../../../api/imi/interfaces'

const DashboardBrandingPage:React.FC = () => {
  const [branding, setBranding] = useState<IBrandingMultiResponse>([] as any)
  const [loading, setLoading] = useState(true)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllBranding = async () => {
      const response = await IMIApi.GetAllBranding(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setBranding(response.data)
      setLoading(false)
    }
    fetchAllBranding()
    return () => abortController.abort()
  }, [setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <DashboardBranding
            isLoading={loading}
            branding={branding}
          />
        )}
      />
    </Padding>
  )
}

export default DashboardBrandingPage
