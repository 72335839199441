import { useContext, useMemo, useState } from 'react'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { IMIApi } from '../../../../api/imi/api'
import { AppContext } from '../../../../contexts/AppContext'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import CallIndustryForm from '../../../form/calls/CallIndustryForm'
import { ICallCategory } from '../../../../api/imi/interfaces'

const CreateCallIndustry:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      call_category: [
        {
          name: 'Closed',
          id: 5,
          color: '#e37226',
        },
        {
          name: 'Hang Up',
          id: 6,
          color: '#bb297e',
        },
        {
          name: 'Other',
          id: 4,
          color: '#85619b',
        },
        {
          name: 'Voicemail',
          id: 7,
          color: '#316c39',
        },
      ],
    }), []),
  })

  const cancel = () => {
    methods.reset()
    setTimeout(() => {
      setIsButtonLoading(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<ICallCategory> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    // const submitResponse = await IMIApi.CreateAdvertiser(data)
    // const authenticated = submitResponse.status === 200
    // setTimeout(() => {
    // if (submitResponse.status !== 200) {
    //   if (submitResponse.status === 0) {
    //     setIsButtonLoading(false)
    //     setError({ errorMsg: 'Internal server error.' })
    //     return setBtnText('Save')
    //   }
    //   const parse = JSON.parse(submitResponse)
    //   setIsButtonLoading(false)
    //   setError({ errorMsg: parse.detail })
    //   return setBtnText('Save')
    // }
    //   setAdvertiserId(submitResponse?.data?.id)
    //   setIsButtonLoading(false)
    //   setBtnText('Saved!')
    // }, 1000)
  }

  return (
    <Content
      title="industry details"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <CallIndustryForm
            errors={methods.formState.errors}
            showButton
          />
          <FormButtonGroup
            isButtonLoading={isButtonLoading}
            disabled={!methods.formState.isValid}
            disabledCancel={!methods.formState.isDirty}
            onClickCancel={cancel}
            btnText={btnText}
            showButton
          />
        </form>
      </FormProvider>
    </Content>
  )
}

export default CreateCallIndustry
