import {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react'
import { useLocation } from 'react-router-dom'
import ReportHeader from '../components/semReport/ReportHeader'
import Padding from '../components/layouts/Padding'
import { IMIApi } from '../api/imi/api'
import EnhancedCallAnalyticsReport from '../components/semReport/EnhancedCallAnalyticsReport'
import { AppContext } from '../contexts/AppContext'
import { ICampaignInDb, ISemDetail } from '../api/imi/interfaces'
import { ReportContext } from '../contexts/ReportContext'

// TODO: gets campaign name and cycle details
const SemCallReportPage: React.FC = () => {
  const { user, setError } = useContext(AppContext)
  const {
    parameters,
    reportParameters,
  } = useContext(ReportContext)
  // daily information
  const [loading, setLoading] = useState<boolean>(true)
  const [filterId, setFilterId] = useState<number>()
  const [campaign, setCampaign] = useState<ICampaignInDb>({} as any)

  useEffect(() => {
    const abortController = new AbortController()
    if (parameters.id > 0) {
      const fetchSemReport = async () => {
        const response = await IMIApi.GetCampaignByIdPublic(parameters.id, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaign(response.data)
        return setLoading(false)
      }
      fetchSemReport()
    }
    return () => abortController.abort()
  }, [setError, parameters.id])

  const fullName = () => {
    const campName = campaign?.name
    const secName = campaign?.secondary_name
    const specific = campaign?.specific?.name

    if (secName) {
      return `${campName}: ${secName}`
    }
    if (secName && specific) {
      return `${campName}: ${secName} - ${specific}`
    }
    if (!secName && specific) {
      return `${campName} - ${specific}`
    }
    return campName
  }

  useEffect(() => {
    const items = localStorage.getItem('call_report_id')
    if (items) {
      const parse = JSON.parse(items)
      sessionStorage.setItem('call_report_id', JSON.stringify(parse))
      setFilterId(parse)
      localStorage.removeItem('call_report_id')
    }
    const getSessionItems = sessionStorage.getItem('call_report_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      return setFilterId(parseItems)
    }
  }, [])

  return (
    <Padding>
      <ReportHeader
        title={fullName()}
        border={false}
      />
      {filterId && filterId > 0 && (
        <EnhancedCallAnalyticsReport
          filter={filterId}
          callProcessing={campaign.call_processing_is_active}
        />
      )}
    </Padding>
  )
}

export default SemCallReportPage
