import { 
  ISemDetail,
  ICallsOverview, 
  ICallsByCampaignAndStatus,
} from '../api/imi/interfaces'
import {
  fixDateTimeObj,
  fixHoursObj,
} from '../helpers/utils'

export type Campaign = {
  name: string
  type: string
  startDate: string
  endDate: string
}

export type Totals = {
  impressions: string
  clicks: string
  calls: string
  webEvents: string
  conversions: string
  leads: string
}

export type KeyMetrics = {
  ctr: string
  ctc: string
  cte: string
  spent: string
  budget: string
}

export type Call = {
  category: string
  calls: string
  flagged: string
  color: string
  percent: number
}

export type CallTotals = {
  calls: string
  leads: string
  flagged: string
}

export type CallHighlight = {
  dateTime: string[]
  customer: string[]
  duration: string
  category: string
  color: string
  notes: string
}

export type WebEvent = {
  name: string
  events: string
  percent: number
}

export type WebEventTotals = {
  events: string
  leads: string
}

export type EmailLead = {
  customer: string
  email: string
  date: string
  time: string
}

export type EmailLeadTotals = {
  leads: string
}

export type Keyword = {
  keyword: string
  impressions: string
  clicks: string
  ctr: string
}

export type DeviceType = {
  type: string
  impressions: string
  percent: number
}

export type Roi = {
  leadType: string
  leads: string
  percentConverted: string
  numberConverted: string
  gross: string
  return: string
}

export type RoiTotals = {
  leads: string
  investment: string
  return: string
  roi: string
  positive: boolean
}

export type PdfData = {
  campaign: Campaign
  totals: Totals
  keyMetrics: KeyMetrics
  calls?: Call[]
  callTotals?: CallTotals
  callHighlights?: CallHighlight[]
  webEvents?: WebEvent[]
  webEventTotals?: WebEventTotals
  emailLeads?: EmailLead[]
  emailLeadTotals?: EmailLeadTotals
  keywords?: Keyword[]
  deviceTypes?: DeviceType[]
  roi?: Roi[]
  roiTotals?: RoiTotals
}

const formatPhoneNumber = (pnumber: string) => {
  const cleaned = (` ${pnumber}`).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = (match[1] ? '' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() 
      + text.substring(1),
  )
}

export const formatData = (
  sem: ISemDetail,
  overview: ICallsOverview | undefined, 
  calls: ICallsByCampaignAndStatus,
  userType: number,
): PdfData => {
  const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  const commaFormat = new Intl.NumberFormat('en-US')
  const onePlaceFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 })
  const twoPlaceFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
  const threePlacesFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 3 })
  const callHighlights = calls.data ? calls.data.filter((call) => call.is_highlighted) : []
  const filteredCalls = overview?.categories 
    ? overview.categories
      .filter((cat) => cat.category_name)
      .sort((a, b) => {
        if (a.category_name.toLowerCase().includes('lead')) {
          if (b.category_name.toLowerCase().includes('lead')) {
            return b.total - a.total
          }
          return -1
        }
        if (b.category_name.toLowerCase().includes('lead')) {
          return 1
        }
        return b.total - a.total
      })
    : []
  const showRoi = sem.roi && sem.roi.data && sem.roi.data.length > 0 
    && sem.report_cycle_id && (sem.report_end_date || userType < 4)

  let campName = sem?.campaign?.name
  const secName = sem?.campaign?.secondary_name
  const specific = sem?.campaign?.specific?.name

  if (secName) {
    campName = `${campName}: ${secName}`
  }
  if (secName && specific) {
    campName = `${campName}: ${secName} - ${specific}`
  }
  if (!secName && specific) {
    campName = `${campName} - ${specific}`
  }

  const ctc = sem?.clicks_calls_events_graph?.callouts[1]?.value
    && sem?.clicks?.value ? ((sem?.clicks_calls_events_graph?.callouts[1].value / sem?.clicks?.value) * 100).toFixed(2) : 0
  
  const cte = sem?.clicks_calls_events_graph?.callouts[2]?.value
    && sem?.clicks?.value ? ((sem?.clicks_calls_events_graph?.callouts[2]?.value / sem?.clicks?.value) * 100).toFixed(2) : 0

  return {
    campaign: {
      name: campName,
      type: sem.campaign.type.name,
      startDate: fixDateTimeObj(`${sem.query_start_date}T12:00:00`),
      endDate: fixDateTimeObj(`${sem.query_end_date}T12:00:00`),
    },
    totals: {
      impressions: commaFormat.format(Math.round(sem.impressions.value)),
      clicks: commaFormat.format(Math.round(sem.clicks.value)),
      calls: commaFormat.format(Math.round(sem.clicks_calls_events_graph.callouts[1].value)),
      webEvents: commaFormat.format(sem.web_events.total),
      conversions: commaFormat.format(Math.round(sem.conversions.value)),
      leads: commaFormat.format(Math.round(sem.leads.value)),
    },
    keyMetrics: {
      ctr: `${twoPlaceFormat.format(Math.round((sem.clicks.value / sem.impressions.value) * 10000) / 100)}%`,
      ctc: `${ctc}%`,
      cte: `${cte}%`,
      spent: `$${commaFormat.format(Math.round(sem.spend))}`,
      budget: `$${commaFormat.format(Math.round(sem.budget))}`,
    },
    calls: overview && filteredCalls.length > 0 ? filteredCalls
      .map((cat) => ({
        category: cat.category_name,
        calls: commaFormat.format(cat.total),
        flagged: commaFormat.format(cat.flagged),
        color: cat.color,
        percent: cat.total / overview.total,
      })) : undefined,
    callTotals: overview ? {
      calls: commaFormat.format(overview.total),
      leads: commaFormat.format(overview.total_leads),
      flagged: commaFormat.format(overview.total_flagged),
    } : undefined,
    callHighlights: callHighlights.length > 0 ? callHighlights.map((call) => ({
      dateTime: [
        fixDateTimeObj(call.occurred_at), 
        fixHoursObj(call.occurred_at),
      ],
      customer: [
        (call.contact?.last_name ? `${call.contact?.last_name}, ` : '')
        + (call.contact?.first_name ? `${call.contact?.first_name}` : '-'),
        (call.contact?.phone_work && formatPhoneNumber(call.contact?.phone_work))
          || (call.contact?.phone_home && formatPhoneNumber(call.contact?.phone_home))
          || '-',
        (call.contact?.city ? `${call.contact?.city}, ` : '')
        + (call.contact?.state ? `${call.contact?.state} ` : '')
        + (call.contact?.postal ? `${call.contact?.postal}` : ''),
      ],
      duration: new Date(call.duration * 1000).toISOString().substr(11, 8),
      category: call.category ? call.category?.name : '',
      color: call.category ? call.category?.color : '',
      notes: call.notes ? call.notes : '',
    })) : undefined,
    webEvents: sem.web_events && sem.web_events.data && sem.web_events.data.length > 0 ? sem.web_events.data.map((event) => ({
      name: event.name,
      events: commaFormat.format(event.non_leads),
      percent: event.non_leads / sem.web_events.total,
    })) : undefined,
    webEventTotals: sem.web_events ? {
      events: commaFormat.format(sem.web_events.total),
      leads: commaFormat.format(sem.web_events.leads),
    } : undefined,
    emailLeads: sem.form_leads && sem.form_leads.length > 0 ? sem.form_leads.map((lead) => ({
      customer: `${lead.contact.first_name ? toTitleCase(lead.contact.first_name) : ''} ${lead.contact.last_name ? toTitleCase(lead.contact.last_name) : ''}`,
      email: lead.contact.email,
      date: fixDateTimeObj(lead.occurred_at),
      time: fixHoursObj(lead.occurred_at),
    })) : undefined,
    emailLeadTotals: sem.form_leads && sem.form_leads.length > 0 ? {
      leads: commaFormat.format(sem.form_leads.length),
    } : undefined,
    keywords: sem.keywords && sem.keywords.rows && sem.keywords.rows.length > 0 ? sem.keywords.rows.slice(0, 10).map((row: any) => ({
      keyword: row.keyword,
      impressions: commaFormat.format(row.impressions),
      clicks: commaFormat.format(row.clicks),
      ctr: `${twoPlaceFormat.format(row.ctr)}%`,
    })) : undefined,
    deviceTypes: sem.device_impressions && sem.device_impressions.series && sem.device_impressions.series.length > 0 ? sem.device_impressions.series.map((device) => ({
      type: device.y,
      impressions: commaFormat.format(device.x) as string,
      percent: device.x / sem.device_impressions.callouts[0].value,
    })) : undefined,
    roi: showRoi ? sem.roi.data.map((data) => ({
      leadType: data.name,
      leads: commaFormat.format(data.leads_count),
      percentConverted: `${onePlaceFormat.format(Math.round(data.converted * 10000) / 100)}%`,
      numberConverted: threePlacesFormat.format(data.converted_count),
      gross: currencyFormat.format(data.avg_gross),
      return: currencyFormat.format(data.lead_type_return),
    })) : undefined,
    roiTotals: showRoi ? {
      leads: commaFormat.format(sem.roi.total_leads),
      investment: `$${commaFormat.format(Math.floor(sem.roi.total_investment))}`,
      return: currencyFormat.format(sem.roi.total_return),
      roi: `${commaFormat.format(Math.round(sem.roi.roi_percent * 100) / 100)}%`,
      positive: sem.roi.roi_percent > 0,
    } : undefined,
  }
}
