import { useEffect } from 'react'
import ToolTip from '../ToolTip'
import LoadingSpinner from '../LoadingSpinner'

// These are the rounded boxes with metrics inside of them.
// Contain individual data pieces, not to be confused with the horizontal grid.
// see SEM page at the top to see boxes below page title

interface Props {
  name: string,
  value: number | string,
  imageUrl: React.ReactNode,
  tooltipInfo?: React.ReactNode
}

const MetricsGrid:React.FC<Props> = ({
  name,
  value,
  imageUrl,
  tooltipInfo,
}) => (
  <div
    className="relative whitespace-break-spaces rounded border border-grey-light bg-white px-5 py-5 flex items-center space-x-3"
  >
    <div className="flex-shrink-0 w-12">
      {/* <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" /> */}
      {imageUrl}
    </div>
    <div className="flex-1 min-w-0">
      <div>
        <span className="absolute inset-0" aria-hidden="true" />
        <h4>{name}</h4>
        <p className="font-display text-xl text-default font-light">{(value !== undefined || value !== null || value !== 0) ? value : 0}</p>
      </div>
    </div>
    {tooltipInfo ? <ToolTip tipId={name} size="xs" position="pr-2 right-0 top-0">{tooltipInfo}</ToolTip> : null}
  </div>
)

export default MetricsGrid
