import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { UserContext } from '../../../../contexts/UserContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import ToolTip from '../../../ToolTip'
import DualListBox from '../../../DualListBox'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { IUserAdvertisers, IAdvertiserInDb, IUser } from '../../../../api/imi/interfaces'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import LoadingSpinner from '../../../LoadingSpinner'

// TODO: figure out the state change to enable/disable buttons

interface Props {
  edit: boolean
}

const AssignAdvertisers:React.FC<Props> = ({ edit }) => {
  const {
    userInDb,
    setUserInDb,
    userAdIds,
    setUserAdIds,
  } = useContext(UserContext)
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  // All available advertisers
  const [allAdvertisers, setAllAdvertisers] = useState<any>()
  // Advertisers unassigned to user
  const [advertisers, setAdvertisers] = useState<any>([] as any)
  // Advertisers assigned to user
  const [assignedAdvertisers, setAssignedAdvertisers] = useState<IAdvertiserInDb[]>([] as any)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState(edit)
  // This is to post for user specifically
  const [idArray, setIdArray] = useState<any>()
  // To see if field has been updated based on advertiser being added
  // or removed from user's list.
  const [dirtyField, setDirtyField] = useState<boolean>(false)
  const advertisersAbortRef = useRef(new AbortController())
  const usersAbortRef = useRef(new AbortController())

  const methods = useForm<IUserAdvertisers>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  // TODO: get all advertisers
  // get list of advertisers for user
  // Get all advertisers available
  const fetchAdvertisers = useCallback((async () => {
    const response = await IMIApi.GetAllAdvertisers(advertisersAbortRef.current.signal)
    if (!response) {
      return
    }
    if (response.status !== 200) {
      const parse = JSON.parse(response)
      setError({ errorMsg: parse.detail })
      return setLoading(false)
    }
    setAllAdvertisers(response.data.data)
    return setLoading(false)
  }), [setError])

  const fetchUserAdIds = useCallback((async () => {
    const response = await IMIApi.GetUserAdvertisers(userInDb.id, usersAbortRef.current.signal)
    if (!response) {
      return
    }
    if (response.status !== 200) {
      const parse = JSON.parse(response)
      setError({ errorMsg: parse.detail })
      return setLoading(false)
    }
    setUserAdIds({ user_id: userInDb.id, advertiser_ids: response.data })
    return setLoading(false)
  }), [setError, setUserAdIds, userInDb.id])

  // TODO: finish, find better way to comine
  const refresh = useCallback(() => {
    if (userInDb.user_type_id > 4) {
      fetchAdvertisers()
      fetchUserAdIds()
    }
  }, [userInDb.user_type_id, fetchAdvertisers, fetchUserAdIds])

  useEffect(() => {
    if (userInDb.user_type_id > 4) {
      return refresh()
    }
    if (userInDb.user_type_id < 5) {
      setLoading(false)
      return setAdvertisers([])
    }
    return () => {
      setUserAdIds({ user_id: userInDb.id, advertiser_ids: [] })
      setAllAdvertisers([])
    }
  }, [refresh, userInDb, setUserAdIds])

  useEffect(() => () => {
    advertisersAbortRef.current.abort()
    usersAbortRef.current.abort()
  }, [])

  // filter advertisers based on being unassigned or assigned to user
  const assigned = useMemo(() => (
    allAdvertisers?.filter((advertiser) => (
      userAdIds?.advertiser_ids.includes(advertiser.id)
    ))), [allAdvertisers, userAdIds?.advertiser_ids])

  const unassigned = useMemo(() => (
    allAdvertisers?.filter((advertiser) => (
      !userAdIds?.advertiser_ids.includes(advertiser.id)
    ))), [allAdvertisers, userAdIds?.advertiser_ids])

  // Don't want to update advertiser list
  const cancel = () => {
    setAssignedAdvertisers(assigned)
    setAdvertisers(unassigned)
    setIdArray(userAdIds?.advertiser_ids)
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  // Set data for user after refresh and on first render
  useEffect(() => {
    setAssignedAdvertisers(assigned)
    setAdvertisers(unassigned)
    setIdArray(userAdIds?.advertiser_ids)
  }, [assigned, unassigned, userInDb.id, userAdIds?.advertiser_ids])

  const onAddAllClick = () => {
    setAdvertisers([])
    setAssignedAdvertisers(allAdvertisers)
    const allAds = allAdvertisers.map((item) => item.id)
    setIdArray(allAds)
    return setDirtyField(true)
  }

  const onRemoveAllClick = () => {
    setAssignedAdvertisers([])
    return setAdvertisers(allAdvertisers)
  }

  const editUserAdvertiser = () => {
    setShowButton(true)
  }

  const onSubmit: SubmitHandler<IUserAdvertisers> = async () => {
    const submit = {
      user_id: userInDb.id,
      advertiser_ids: idArray,
    }
    const submitResponse = await IMIApi.PostUserAdvertisers(submit)
    setIsButtonLoading(true)
    setBtnText('Saving...')
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      // setUserInDb(({ ...userInDb, advertiser_set: submitResponse.data }))
      setUserAdIds({ user_id: userInDb.id, advertiser_ids: submitResponse.data })
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  return (
    <Content
      title="advertisers"
      icon={(
        <ToolTip
          size="sm"
          position="relative"
          tipId="assign-advertiser-info"
        >
          The &quot;available list&quot; of advertisers includes all active (green dot) and inactive (gray dot) advertisers. If you can’t find what you’re looking for, you may need to create the advertiser, and then refresh this list.
        </ToolTip>
      )}
    >
      {loading ? (
        <LoadingSpinner variant="primary" size="lg" />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <DualListBox
              assignedListName="User"
              disabled={!showButton}
              onAddAllClick={onAddAllClick}
              refresh={refresh}
              data={advertisers}
              assignedData={assignedAdvertisers}
              idsArray={idArray}
              setIdArray={setIdArray}
              onRemoveAllClick={onRemoveAllClick}
              setDirtyField={setDirtyField}
            />
            <div className="mb-6">
              <LinkWrapper
                variant="defaultAdmin"
                href="/admin-tools/advertiser-settings/create-advertiser"
                className="inline-block"
                target="_blank"
              >
                Create New Advertiser
                <Icon
                  size="xxs"
                >
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
            </div>
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!dirtyField}
              disabledCancel={!dirtyField}
              showButton={showButton}
              onClickCancel={() => cancel()}
              onClickEdit={editUserAdvertiser}
              btnText={btnText}
            />
          </form>
        </FormProvider>
      )}
    </Content>
  )
}

export default AssignAdvertisers
