import { Link } from 'react-router-dom'
import { cls } from '../helpers/utils'

// TODO: fix hover on disabled issue

const styles = {
  base: 'front-display global-animation',
  disabled: 'cursor-not-allowed text-default text-opacity-40 hover:text-opacity-40 hover:brand-primary',
  active: {
    default: 'active:text-secondary',
    menu: 'active:text-tertiary active:font-bold',
  },
  variant: {
    dark: 'text-xs text-opacity-80 text-light hover:text-opacity-100 hover:font-bold',
    default: 'text-base text-primary hover:text-secondary active:text-secondary',
    menu: 'block text-xs text-menu hover:text-secondary active:text-tertiary active:font-bold mb-5',
    defaultSm: 'text-xxs text-primary hover:text-secondary active:text-secondary',
    defaultAdmin: 'font-base text-xxs font-bold text-primary hover:text-secondary active:text-secondary',
    heading: 'font-display font-bold text-xs text-primary hover:text-secondary active:text-secondary',
  },
}

interface Props {
  children?: React.ReactNode
  className?: string
  variant: 'default' | 'menu' | 'defaultSm' | 'dark' | 'primary' | 'secondary' | 'tertiary' | 'danger' | 'defaultAdmin' | 'heading'
  active?: boolean
  disabled?: boolean
  href?: any
  onClick?: () => void
  id?: string
  download?: string
  target?: string
  size?: 'sm' | 'md' | 'lg'
}

const LinkWrapper: React.FC<Props> = ({
  children,
  className,
  variant,
  active,
  disabled,
  href,
  id,
  size = 'lg',
  onClick,
  download,
  target,
  ...props
}) => {
  if (variant === 'primary') {
    return (
      <Link
        target={target}
        id={id}
        onClick={onClick}
        to={href}
        download={download}
        onContextMenu={onClick}
        className={cls(`
                  ${styles.base}
                  ${styles.variant[variant]}
                  ${active ? styles.active[variant] : ''}
                  ${className}
                `)}
        {...props}
      >
        {children}
      </Link>
    )
  }
  return (
    href ? (
      <Link
        rel="noopener noreferrer"
        target={target}
        id={id}
        onClick={onClick}
        href={href}
        to={href}
        download={download}
        onContextMenu={onClick}
        className={cls(`
                  ${styles.base}
                  ${styles.variant[variant]}
                  ${active ? styles.variant[variant] : ''}
                  ${disabled ? styles.disabled : ''}
                  ${className}
                `)}
        {...props}
      >
        {children}
      </Link>
    ) : (
      <span
        role="button"
        id={id}
        onKeyDown={onClick}
        onClick={onClick}
        aria-disabled
        onContextMenu={onClick}
        className={cls(`
                  ${styles.base}
                  ${styles.variant[variant]}
                  ${active ? styles.variant[variant] : ''}
                  ${disabled ? styles.disabled : ''}
                  ${className}
                `)}
        {...props}
      >
        {children}
      </span>
    )
  )
}
LinkWrapper.defaultProps = {
  className: '',
}

export default LinkWrapper
