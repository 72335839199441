import {
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { ICallInstruction, ICallInstructionUpdate } from '../../../../api/imi/interfaces'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import LoadingSpinner from '../../../LoadingSpinner'
import CallInstructionsForm from '../../../form/calls/CallInstructionsForm'
import FormButtonGroup from '../../../button/FormButtonGroup'
import Badge from '../../../badge/Badge'

interface Props {
  instructions: ICallInstruction
  isLoading: boolean
  edit: boolean
}

const EditCallInstructions:React.FC<Props> = ({
  instructions,
  isLoading,
  edit,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)
  const [campaigns, setCampaigns] = useState<any>()
  const [showButton, setShowButton] = useState<boolean>(edit)
  const [editInstruction, setEditInstruction] = useState<ICallInstruction>()

  useEffect(() => {
    setEditInstruction(instructions)
  }, [instructions])

  const methods = useForm<ICallInstruction>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      name: editInstruction?.campaign?.name,
      body: editInstruction?.body,
      id: editInstruction?.id,
      created_at: editInstruction?.created_at,
      updated_at: editInstruction?.updated_at,
    }), [editInstruction]),
  })

  useEffect(() => {
    methods.reset(editInstruction)
  }, [editInstruction, methods])

  const cancel = () => {
    methods.reset(editInstruction, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const editTemplate = () => {
    setShowButton(true)
  }

  const fixDateObj = (date) => {
    const dateObj = new Date(date)
    const newDateObj = {
      month: dateObj.getMonth() + 0o1,
      day: dateObj.getDate(),
      year: dateObj.getFullYear(),
    }
    if (newDateObj.year < 2022) {
      return 'User has never logged in.'
    }
    return `${newDateObj.month}/${newDateObj.day}/${newDateObj.year}`
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      id: data.id,
      campaign_id: data.campaign.id,
      body: data.body,
    }
    const submitResponse = await IMIApi.UpdateCallInstructions(formData)
    setEditInstruction(submitResponse?.data)
    const authenticated = submitResponse.status === 200
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }
  return (
    <>
      <Content
        title="instruction details"
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <div className="mb-3">
              <Badge
                variant="badgeOnly"
                label={(
                  <span>
                    Created:
                    &nbsp;
                    <span className="font-bold">
                      {fixDateObj(editInstruction?.created_at)}
                    </span>
                  </span>
                )}
              />
              <Badge
                variant="badgeOnly"
                label={(
                  <span>
                    Last Updated:
                    &nbsp;
                    <span className="font-bold">
                      {fixDateObj(editInstruction?.updated_at)}
                    </span>
                  </span>
                )}
              />
            </div>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <CallInstructionsForm
                  errors={methods.formState.errors}
                  assignedCampaigns={campaigns}
                  showButton={showButton}
                />
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isValid || !methods.formState.isDirty}
                  disabledCancel={!methods.formState.isDirty}
                  onClickCancel={cancel}
                  btnText={btnText}
                  showButton={showButton}
                  onClickEdit={editTemplate}
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default EditCallInstructions
