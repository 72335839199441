import {
  useState,
  useRef,
  useEffect,
  useMemo,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { IUserCreate, IUserInDb } from '../../../../api/imi/interfaces'
import Content from '../../../layouts/Content'
import AddDashboardBranding from './AddDashboardBranding'
import { IMIApi } from '../../../../api/imi/api'
import FormButtonGroup from '../../../button/FormButtonGroup'
import AccountUserForm from '../../../form/accounts/AccountUserForm'
import LoadingSpinner from '../../../LoadingSpinner'
import { newUserInDb } from '../../../../api/imi/factories'
import { UserContext } from '../../../../contexts/UserContext'
import { AppContext } from '../../../../contexts/AppContext'

// TODO: form validation
// TODO: match user id to white label and other "sections"
// TODO: is_active is returning string of "true" instead of boolean true

interface Props {
  accountTypes: []
  setUserId:(val: number) => void
  isLoading: boolean
}

const CreateUser:React.FC<Props> = ({ accountTypes, setUserId, isLoading }) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setUserInDb } = useContext(UserContext)
  const { setError } = useContext(AppContext)

  const methods = useForm<IUserCreate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      is_active: true,
      is_superuser: false,
      white_label_id: 1,
    }), []),
  })

  const cancel = () => {
    methods.reset({}, {
      keepDefaultValues: true,
      keepErrors: false,
    })
    methods.reset({}, {
      keepDirty: false,
    })
    setBtnText('Save')
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  useEffect(() => {
    const newUser = setUserInDb(newUserInDb() as IUserInDb)
    return newUser
  }, [setUserInDb])

  const onSubmit: SubmitHandler<IUserCreate> = async (data) => {
    const formData = {
      ...data,
      is_superuser: data.user_type_id === 1,
    }
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.CreateNewUser(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      // methods.reset({})
      setUserId(submitResponse?.data?.id)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
  }

  return (
    <>
      <Content title="User Details">
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <AccountUserForm
                errors={methods.formState.errors}
                accountTypesData={accountTypes}
                showButton
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabledCancel={!methods.formState.isDirty}
                disabled={!methods.formState.isValid || !methods.formState.isDirty}
                onClickCancel={cancel}
                btnText={btnText}
                showButton
              />
            </form>
          </FormProvider>
        )}
      </Content>
      <AddDashboardBranding
        isLoading={!accountTypes}
      />
    </>
  )
}

export default CreateUser
