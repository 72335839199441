import {
  useState,
  useContext,
  useEffect,
} from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { AppContext } from '../contexts/AppContext'
import { IResetPassword, IBrandingLogin } from '../api/imi/interfaces'
import { IMIApi } from '../api/imi/api'
import LoadingSpinner from '../components/LoadingSpinner'
import Button from '../components/button/Button'
import Input from '../components/form/Input'
import FormButtonGroup from '../components/button/FormButtonGroup'

const ScheduledMaintenancePage:React.FC = () => {
  const {
    isSignedIn,
    user,
  } = useContext(AppContext)
  const [serverError, setServerError] = useState<string>('')
  const {
    register,
    handleSubmit,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
    },
  } = useForm<IResetPassword>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })
  const [brandingLogin, setBrandingLogin] = useState<IBrandingLogin>({} as any)
  const [loading, setLoading] = useState<boolean>(false)
  const watchFields = watch('password')
  const [token, setToken] = useState<string>('')
  const [btnText, setBtnText] = useState('Password Reset')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>(false)

  // useEffect(() => {
  //   const abortController = new AbortController()
  //   const url = window.location.hostname
  //   const checkBrandingUrl = async () => {
  //     if (url.toLowerCase() === 'localhost') {
  //       const encbranding = await IMIApi.PostBrandingLogin('https://imidashboard-dev.com')
  //       setBrandingLogin(encbranding.data)
  //       return setLoading(false)
  //     }
  //     const branding = await IMIApi.PostBrandingLogin(url)
  //     if (branding.status !== 200) {
  //       setLoading(false)
  //       return null
  //     }
  //     setBrandingLogin(branding.data)
  //     return setLoading(false)
  //   }
  //   checkBrandingUrl()
  //   return () => abortController.abort()
  // }, [])

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search)
  //   const tokenString = urlParams.has('token')
  //   if (tokenString) {
  //     // @ts-ignore
  //     return setToken(urlParams.get('token'))
  //   }
  //   return setToken('')
  // }, [])

  // const onSubmit: SubmitHandler<IResetPassword> = async (data) => {
  //   const resetPassword = {
  //     ...data,
  //     token,
  //   }
  //   setIsButtonLoading(true)
  //   setBtnText('Submitting...')
  //   const submitResponse = await IMIApi.ResetPassword(resetPassword)
  //   setTimeout(() => {
  //     if (submitResponse.status !== 200) {
  //       if (submitResponse.status === 0) {
  //         setIsButtonLoading(false)
  //         return setBtnText('Password Reset')
  //       }
  //       const parse = JSON.parse(submitResponse)
  //       setIsButtonLoading(false)
  //       setServerError(parse.detail)
  //       return setBtnText('Password Reset')
  //     }
  //     return setBtnText('Password Reset')
  //   }, 1000)
  //   if (submitResponse.status === 200) {
  //     setIsButtonLoading(false)
  //     return setBtnText('Success!')
  //   }
  //   return setIsButtonLoading(false)
  // }

  return (
    <div className="flex items-center justify-between min-h-screen bg-login">
      <div className="w-full px-6 min-h-screen bg-white flex flex-1 items-center z-10">
        {loading ? <LoadingSpinner size="lg" variant="primary" /> : (
          <div className="md:pr-12 pt-6 pb-8 mb-4 flex-1 max-w-half-site my-0 mx-auto">
            <div
              className="heading-1 text-default block text-left py-2 mb-8 border-b border-grey-dark border-dotted md:max-w-login-form"
            >
              <h1 className="text-default">shhh…. it’s nap time.</h1>
              {/* <div className="max-w-btn-lg py-3"><img src={brandingLogin?.email_template?.logo_url} alt={brandingLogin?.email_template?.name} /></div> */}
            </div>
            {/* <h2>Reset Your Password</h2> */}
            <p className="mb-4 md:max-w-login-form w-full">The dashboard has been working really hard to collect and analyze data. It’s resting temporarily for scheduled maintenance. Please check back again soon!</p>
            <p className="text-red">{serverError}</p>
          </div>
        )}
      </div>
      <div className="hidden md:block md:flex-1">
        <img src="images/scheduled-maintenance.png" alt="maintenance" className="hidden md:block xl:max-w-login-image relative pl-24 pr-24 pb-24 ml-auto mr-auto" />
      </div>
    </div>
  )
}

export default ScheduledMaintenancePage
