import {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import { AppContext } from '../../contexts/AppContext'
import { ReportContext } from '../../contexts/ReportContext'
import Padding from '../layouts/Padding'
import Accordion from '../layouts/Accordion'
import ListingPageGridFlex from '../layouts/ListingPageGridFlex'
import Dropdown from '../form/Dropdown'
import DropdownCheckbox from '../form/DropdownCheckbox'
import Search from '../form/Search'
import LinkWrapper from '../Links'
import {
  handleFilters,
  fixDateObj,
  fixHoursObj,
  commaNumber,
  fixDateTimeObj, getPlatformNames,
} from '../../helpers/utils'
import HorizontalGrid from '../layouts/HorizontalGrid'
import {
  ICampaignCentralMultiResponse,
  ICampaignType,
} from '../../api/imi/interfaces'
import {
  campStatusData,
  campSortData,
  publicCampSortData,
} from './CampaignCentralSorting'
import LoadingSpinner from '../LoadingSpinner'
import Button from '../button/Button'
// TODO: Need list of all advertisers, inactive and active. Should match
// AdvertisersList.tsx file call as well.
// TODO: filter based on platform when that's added to serializer/view

interface Props {
  campaigns: ICampaignCentralMultiResponse
  isLoading: boolean
  campaignTypes: ICampaignType[]
  platforms: any
}

const CampaignCentral:React.FC<Props> = ({
  campaigns,
  isLoading,
  campaignTypes,
  platforms,
}) => {
  const { user, setCampaignData } = useContext(AppContext)
  const { setParamsNavigateNewest } = useContext(ReportContext)
  const [allCampaigns, setAllCampaigns] = useState<any>()
  const [filteredCampaigns, setFilteredCampaigns] = useState<any>()
  const [platformData, setPlatformData] = useState<any>([] as any)
  const [selectedFilters, setSelectedFilters] = useState({
    types: [2],
    platforms: [1, 2],
    status: 'all',
    sort: 0,
    search: '',
  })

  useEffect(() => {
    setAllCampaigns(campaigns?.data?.sort((a, b) => (a.campaign?.name.localeCompare(b.campaign?.name)) || (a.campaign?.secondary_name > b.campaign?.secondary_name ? 1 : -1)))
  }, [campaigns])

  useEffect(() => {
    if (platforms) {
      setPlatformData([
        {
          name: `${platforms[0]?.name} (RL)`,
          id: platforms[0]?.id,
        },
        {
          name: `${platforms[1]?.name} (SA)`,
          id: platforms[1]?.id,
        },
      ])
    }
  }, [platforms])

  const statusCheck = (daysLeft, status?) => {
    if (Math.round(daysLeft) <= 7) {
      return {
        border: 'border-red-default border-t-4 md:border-l-4 md:border-t-0',
      }
    }
    if (status === 'paused') {
      return {
        border: 'border-yellow-default border-t-4 md:border-l-4 md:border-t-0',
        textColor: 'text-yellow',
      }
    }
    return {
      border: 'border-green-default border-t-4 md:border-l-4 md:border-t-0',
      textColor: 'text-green',
    }
  }
  const determinePage = (adType) => {
    if (adType === 2) {
      return '/search-engine-marketing'
    }
    if (adType === 1) {
      return '/display-advertising'
    }
    if (adType === 3) {
      return '/video-advertising'
    }
    return null
  }

  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const campaignStatus = status.cycle[0]?.status
      if (selectedFilters.status === 'all') {
        return allCampaigns
      }
      return campaignStatus === selectedFilters.status
    }

    const filterCampaignTypes = (campData) => (
      selectedFilters.types.includes(campData.campaign.type?.id)
    )

    const filterPlatform = (campData) => (
      selectedFilters.platforms.includes(campData.platforms[0]?.id)
    )

    const filterSearchValue = (campaignData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const campaignFilter = campaignData.campaign.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const campaignFil = splitItems.some((si) => campaignData.campaign.name.toLowerCase().includes(si))
        return campaignFil
      }
      return campaignFilter
    }

    const filterUnassignedData = allCampaigns?.filter((campaign) => filterCampaignTypes(campaign) && filterSearchValue(campaign) && filterPlatform(campaign) && filterStatus(campaign))
    setFilteredCampaigns(filterUnassignedData)
  }, [selectedFilters, allCampaigns])

  const handleSort = useCallback(() => {
    if (selectedFilters?.sort === 0) {
      const sortedZA = [...allCampaigns.sort((a, b) => (a.campaign?.name.localeCompare(b.campaign?.name)) || (a.campaign?.secondary_name > b.campaign?.secondary_name ? 1 : -1))]
      return setFilteredCampaigns([...sortedZA])
    }
    if (selectedFilters?.sort === 1) {
      const sortedZA = [...allCampaigns.sort((a, b) => (b.campaign?.name.localeCompare(a.campaign?.name)) || (b.campaign?.secondary_name > a.campaign?.secondary_name ? 1 : -1))]
      return setFilteredCampaigns(sortedZA)
    }
    if (selectedFilters?.sort === 2) {
      const sortedDaysLeftLowHigh = [...allCampaigns?.sort((a, b) => ((a?.days_left > b?.days_left) || (Number.isNaN(a.days_left) > Number.isNaN(b.days_left)) ? 1 : -1))]
      return setFilteredCampaigns(sortedDaysLeftLowHigh)
    }
    if (selectedFilters?.sort === 3) {
      const sortedDaysLeftHighLow = [...allCampaigns?.sort((a, b) => ((a?.days_left > b?.days_left) || (Number.isNaN(a.days_left) > Number.isNaN(b.days_left)) ? -1 : 1))]
      return setFilteredCampaigns(sortedDaysLeftHighLow)
    }
    if (selectedFilters?.sort === 4) {
      const sortedStartDateNewest = [...allCampaigns?.sort((a, b) => (a.cycle[0]?.start_date > b.cycle[0]?.start_date ? -1 : 1))]
      return setFilteredCampaigns(sortedStartDateNewest)
    }
    if (selectedFilters?.sort === 5) {
      const sortedStartDateOldest = [...allCampaigns?.sort((a, b) => (a.cycle[0]?.start_date > b.cycle[0]?.start_date ? 1 : -1))]
      return setFilteredCampaigns(sortedStartDateOldest)
    }
  }, [selectedFilters.sort, allCampaigns])

  useEffect(() => {
    if (allCampaigns) {
      handleSort()
      return filters()
    }
  }, [filters, handleSort, allCampaigns])

  return (
    isLoading ? (
      <LoadingSpinner variant="primary" size="lg" />
    ) : (
      <Padding>
        <div className="md:flex justify-between">
          <div className="mb-6">
            <p className="text-xxs uppercase text-opacity-60 mb-0">
              Total Active Campaigns:&nbsp;
              <span className="font-semibold">{filteredCampaigns?.length || 0}</span>
            </p>
            <p className="text-xxs uppercase text-opacity-60">
              Last Updated:&nbsp;
              <span className="font-semibold">{allCampaigns ? `${fixDateTimeObj(campaigns?.last_updated)}, ${fixHoursObj(campaigns?.last_updated)} est` : 0}</span>
            </p>
          </div>
          {user.user_type_id < 5 && (
            <Search
              label="Search"
              hideLabel
              className="md:max-w-btn w-full"
              placeholder="Search Campaigns..."
              value={selectedFilters.search}
              onChange={(
                (e) => setSelectedFilters(
                  handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
                )
              )}
            />
          )}
        </div>
        <HorizontalGrid
          smGrid="grid-cols-1"
          mdGrid="md:grid-cols-4"
          padding={false}
        >
          <DropdownCheckbox
            label="Campaign Type"
            list={campaignTypes}
            defaultCheck={[2]}
            onClickDeselect={(
              () => setSelectedFilters({ ...selectedFilters, types: [] })
            )}
            onClickSelect={(
              () => setSelectedFilters({ ...selectedFilters, types: [1, 2, 3] })
            )}
            position="top-0 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(
                handleFilters(e, 'types', selectedFilters),
              )
            )}
          />
          {user.user_type_id < 5 && (
            <>
              <DropdownCheckbox
                label="Platform"
                list={platformData}
                onClickDeselect={(
                  () => setSelectedFilters({ ...selectedFilters, platforms: [] })
                )}
                onClickSelect={(
                  () => setSelectedFilters({ ...selectedFilters, platforms: [1, 2] })
                )}
                defaultCheck={[1, 2]}
                position="top-0 absolute right-0 top-0 bottom-0"
                handleFilters={(
                  (e) => setSelectedFilters(
                    handleFilters(e, 'platforms', selectedFilters),
                  )
                )}
              />
              <Dropdown
                variant="border"
                label="Status"
                list={campStatusData}
                position="top-0 absolute right-0 top-0 bottom-0"
                handleFilters={(
                  (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
                )}
              />
            </>
          )}
          <Dropdown
            variant="border"
            label="Sort By"
            list={user.user_type_id < 5 ? campSortData : publicCampSortData}
            position="top-0 absolute right-0 top-0 bottom-0 mb-6"
            handleFilters={(
              (e) => setSelectedFilters(
                handleFilters(e, 'sort', selectedFilters),
              )
            )}
          />
          {user.user_type_id > 4 && (
            <>
              <div className="hidden sm:block">&nbsp;</div>
              <Search
                label="Search"
                hideLabel
                className=" max-w-btn right-0"
                placeholder="Search Campaigns..."
                value={selectedFilters.search}
                onChange={(
                  (e) => setSelectedFilters(
                    handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters),
                  )
                )}
              />
            </>
          )}
        </HorizontalGrid>
        {filteredCampaigns?.length === 0 && (
          <p className="pb-6">No campaigns found. If you&apos;re using the filtering options, try adjusting your selections. For the search bar, check your spelling or enter part of the full word. If searching for multiple advertisers, use a semicolon to separate names.</p>
        )}
        {/* Desktop */}
        {filteredCampaigns?.map((currentCampaign) => (
          <div key={currentCampaign.campaign.id}>
            <LinkWrapper
              variant="default"
              className="bg-grey-lightest overflow-hidden rounded border border-grey-light mb-4 hidden md:block group"
              target="_blank"
              onClick={(
                () => setParamsNavigateNewest(currentCampaign.campaign.id)
              )}
              href={{
                pathname: determinePage(currentCampaign.campaign.type.id),
              }}
            >
              <ListingPageGridFlex>
                <div className={`${user.user_type_id < 5 ? 'md:w-36 px-3' : 'md:w-1/4 px-8'} py-6 ${user.user_type_id < 5 && statusCheck(currentCampaign.days_left, currentCampaign.cycle[0]?.status).border}`}>
                  {currentCampaign.campaign.name}
                  <div>
                    <span className="text-xxs text-primary font-base font-normal group-hover:text-secondary">
                      {currentCampaign.campaign.type.short_name}
                      {user.user_type_id < 5 && currentCampaign.api_ids.length > 0 && (
                        <>
                          &nbsp;|&nbsp;
                          {currentCampaign.api_ids.map((id) => id.slice(4)).join(', ')}
                        </>
                      )}
                    </span>
                  </div>
                  <p className="text-xxs mb-0">
                    {currentCampaign.campaign.secondary_name}
                    {currentCampaign.campaign.specific?.name && currentCampaign.campaign.secondary_name ? (
                      <>
                        &nbsp;
                        -
                        &nbsp;
                        {currentCampaign.campaign.specific?.name}
                      </>
                    ) : (
                      currentCampaign.campaign.specific?.name && (currentCampaign.campaign.specific?.name)
                    )}
                  </p>
                </div>
                <div className="bg-white hidden sm:overflow-x-auto md:break-normal py-3 flex-2 md:flex-row md:flex md:items-center justify-start border-l border-grey-light">
                  <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-1 items-center">
                    <div>
                      {(currentCampaign?.cycle.length > 0 ? fixDateObj(currentCampaign?.cycle[0]?.start_date) : '-')}
                      <div className="small">start date</div>
                    </div>
                    {/* FIXME: talk with Spence about this, combines the most current cycles of both cycles */}
                    {/* <div>
                      {(currentCampaign?.cycle.length > 0 ? fixDateObj(currentCampaign?.cycle[currentCampaign?.cycle.length - 1]?.start_date) : '-')}
                      <div className="small">start date</div>
                    </div> */}
                  </div>
                  {user.user_type_id < 5 && (
                    <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-1">
                      {getPlatformNames(currentCampaign.platforms)}
                      <div className="small">platform</div>
                    </div>
                  )}
                  {/* {currentCampaign?.campaign?.type?.id === 2 && user.user_type_id === 5 && (
                    <div className={`border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col ${user.user_type_id > 4 ? 'flex-1' : 'flex-3'} items-center`}>
                      <div>
                        {currentCampaign?.cycle.length > 0 ? (
                          `$${commaNumber(Math.round(currentCampaign.cycle[0]?.spend))}`
                        ) : (
                          '-'
                        )}
                        <div className="small">spent</div>
                      </div>
                    </div>
                  )} */}
                  <div className={`border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col ${user.user_type_id > 4 ? 'flex-1' : 'flex-3'} items-center`}>
                    <div>
                      {currentCampaign?.cycle.length > 0 ? (
                        `$${commaNumber(Math.round(currentCampaign.cycle[0]?.spend))}`
                      ) : (
                        '-'
                      )}
                      <div className="small">spent</div>
                    </div>
                  </div>
                  <div className={`${user.user_type_id < 5 && 'border-r border-grey-light'} py-3 px-3 h-full flex justify-center flex-col ${user.user_type_id > 4 ? 'flex-1' : 'flex-3'} items-center`}>
                    <div>
                      {currentCampaign?.cycle.length > 0 ? (
                        `$${commaNumber(Math.round(currentCampaign.cycle[0].budget))}`
                      ) : (
                        '-'
                      )}
                      <div className="small">budget</div>
                    </div>
                  </div>
                  {user.user_type_id < 5 && (
                    <>
                      <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-4.5 items-center">
                        <div>
                          {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                            `$${commaNumber(currentCampaign.daily_spend_target)}`
                          ) : (
                            '-'
                          )}
                          <div className="small">tgt daily spend</div>
                        </div>
                      </div>
                      <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-4.5 items-center">
                        <div>
                          {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                            `$${commaNumber(currentCampaign.daily_spend_actual)}`
                          ) : (
                            '-'
                          )}
                          <div className="small">act daily spend</div>
                        </div>
                      </div>
                      <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-2 items-center max-w-column-xs">
                        <div>
                          {(currentCampaign.cycle.length && currentCampaign.campaign.type?.id === 2 ? currentCampaign.days_in : '-')}
                          <div className="small">days in</div>
                        </div>
                      </div>
                      <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-2.5 items-center">
                        <div>
                          <span className={currentCampaign.days_left < 8 && currentCampaign.campaign.type?.id === 2 ? 'text-red' : 'text-default'}>
                            {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                              `~${currentCampaign.days_left}`
                            ) : (
                              '-'
                            )}
                          </span>
                          <div className="small">days left</div>
                        </div>
                      </div>
                      <div className="border-r border-grey-light py-3 px-3 h-full flex justify-center flex-col flex-3 items-center max-w-column-sm">
                        <div>
                          {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                            `~${currentCampaign.days_total}`
                          ) : (
                            '-'
                          )}
                          <div className="small">total days</div>
                        </div>
                      </div>
                      <div className="py-3 px-3 h-full flex justify-center flex-col flex-2 items-center">
                        <div>
                          {(currentCampaign.cycle[0]?.status === 'running' || currentCampaign.cycle[0]?.status === 'active') && (
                            <span className="text-green">{currentCampaign.cycle[0]?.status}</span>
                          )}
                          {currentCampaign.cycle[0]?.status === 'paused' && (
                            <span className="text-yellow">{currentCampaign.cycle[0]?.status}</span>
                          )}
                          <div className="small">status</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {user.user_type_id > 4 && (
                  <div className="bg-white hidden sm:overflow-x-auto md:break-normal pl-3 pr-8 py-3 sm:inline-flex items-center justify-around border-grey-light">
                    <Button
                      variant="primary"
                      size="xs"
                      id={`see-details-${filteredCampaigns.id}`}
                      className="mb-0 h-10 min-w-150"
                    >
                      SEE DETAILS
                    </Button>
                  </div>
                )}
              </ListingPageGridFlex>
            </LinkWrapper>

            {/* // Mobile */}
            <div key={currentCampaign.campaign.id} className="bg-grey-lightest overflow-hidden rounded border border-grey-light mb-4 block md:hidden">
              <Accordion
                position="top-6"
                fullLine
                header={(
                  <ListingPageGridFlex>
                    <div className={`bg-grey-lightest pl-3 pr-14 py-4 ${user.user_type_id < 5 && statusCheck(currentCampaign.days_left, currentCampaign.cycle[0]?.status).border}`}>
                      {currentCampaign.campaign.name}
                      <div>
                        <span className="text-xxs text-primary font-base font-normal">
                          {currentCampaign.campaign.type.short_name}
                          {user.user_type_id < 5 && (
                            <>
                              &nbsp;|&nbsp;
                              {currentCampaign.api_ids.map((id) => id.slice(4)).join(', ')}
                            </>
                          )}
                        </span>
                      </div>
                      <p className="text-xxs mb-0">
                        {currentCampaign.campaign.secondary_name}
                        {currentCampaign.campaign.specific?.name && (
                          <>
                            &nbsp;
                            -
                            &nbsp;
                            {currentCampaign?.specific?.name}
                          </>
                        )}
                      </p>
                    </div>
                  </ListingPageGridFlex>
                )}
                bodyColor="bg-white"
                body={(
                  <>
                    <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                      <span className="date w-1/2">
                        start date
                      </span>
                      <p className="heading-5">
                        {(currentCampaign?.cycle.length > 0 ? fixDateObj(currentCampaign?.cycle[0]?.start_date) : '-')}
                      </p>
                    </div>
                    {user.user_type_id < 5 && (
                      <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                        <span className="date w-1/2">
                          platform
                        </span>
                        <p className="heading-5">RL</p>
                      </div>
                    )}
                    <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                      <span className="date w-1/2">
                        spent
                      </span>
                      <p className="heading-5">
                        {currentCampaign?.cycle.length > 0 ? (
                          `$${commaNumber(Math.round(currentCampaign.cycle[0]?.spend))}`
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                    <div className={`py-4 ${user.user_type_id < 5 && 'border-b'} border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60`}>
                      <span className="date w-1/2">
                        budget
                      </span>
                      <p className="heading-5">
                        {currentCampaign?.cycle.length > 0 ? (
                          `$${commaNumber(Math.round(currentCampaign.cycle[0].budget))}`
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                    {user.user_type_id < 5 && (
                      <>
                        <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          <span className="date w-1/2">
                            tgt daily spend
                          </span>
                          <p className="heading-5">
                            {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                              `$${commaNumber(currentCampaign.daily_spend_target)}`
                            ) : (
                              '-'
                            )}
                          </p>
                        </div>
                        <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          <span className="date w-1/2">
                            act daily spend
                          </span>
                          <p className="heading-5">
                            {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                              `$${commaNumber(currentCampaign.daily_spend_actual)}`
                            ) : (
                              '-'
                            )}
                          </p>
                        </div>
                        <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          <span className="date w-1/2">
                            days in
                          </span>
                          <p className="heading-5">
                            {(currentCampaign.cycle.length && currentCampaign.campaign.type?.id === 2 ? currentCampaign.days_in : '-')}
                          </p>
                        </div>
                        <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          <span className="date w-1/2">
                            days left
                          </span>
                          <p className={`heading-5 ${currentCampaign.days_left < 8 ? 'text-red' : 'text-default'}`}>
                            {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                              `~${currentCampaign.days_left}`
                            ) : (
                              '-'
                            )}
                          </p>
                        </div>
                        <div className="py-4 border-b border-grey-light flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          <span className="date w-1/2">
                            total days
                          </span>
                          <p className="heading-5">
                            {currentCampaign?.cycle.length > 0 && currentCampaign.campaign.type?.id === 2 ? (
                              `~${currentCampaign.days_total}`
                            ) : (
                              '-'
                            )}
                          </p>
                        </div>
                        <div className="py-4 flex justify-start mx-3 sm:pr-0 items-center date text-default text-opacity-60">
                          <span className="date w-1/2">
                            status
                          </span>
                          <p className={`heading-5 ${statusCheck(10, currentCampaign.cycle[0]?.status).textColor}`}>{currentCampaign.cycle[0]?.status}</p>
                        </div>
                      </>
                    )}
                    <div className="mt-3 mx-3 pb-4">
                      <LinkWrapper
                        variant="primary"
                        key={currentCampaign.campaign.id}
                        target="_blank"
                        onClick={(
                          () => setParamsNavigateNewest(currentCampaign.campaign.id)
                        )}
                        href={{
                          pathname: determinePage(currentCampaign.campaign.type.id),
                        }}
                      >
                        <Button
                          variant="primary"
                          id={currentCampaign.campaign.name}
                        >
                          See Details
                        </Button>
                      </LinkWrapper>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
        ))}
      </Padding>
    )
  )
}

export default CampaignCentral
