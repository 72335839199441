import {
  useCallback,
  useEffect,
  useContext,
  useState,
} from 'react'
import { IMIApi } from '../api/imi/api'
import { IAdvertiserMultiResponse, ICampaignMultiResponse } from '../api/imi/interfaces'
import Padding from '../components/layouts/Padding'
import AdvertisersList from '../components/advertiserDashboard/AdvertisersList'
import { AppContext } from '../contexts/AppContext'
import ActiveCampaignsToolbar from '../components/toolbar/ActiveCampaignsToolbar'
import AdminToolsToolbar from '../components/toolbar/AdminTools'
import SidebarRight from '../components/layouts/SidebarRight'

const AdvertiserDashboardPage:React.FC = () => {
  const [allAdvertisers, setAllAdvertisers] = useState<IAdvertiserMultiResponse>({} as any)
  const { user, setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [assignedCampaigns, setAssignedCampaigns] = useState<any>()
  const [loadingAssigned, setLoadingAssigned] = useState(true)

  useEffect(() => {
    const abortController = new AbortController()
    if (user.user_type_id < 5) {
      const fetchAdvertisers = async () => {
        const response = await IMIApi.GetAllAdvertisers(abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        // Total rows available as response.data.rows_total
        setAllAdvertisers(response.data)
        return setLoading(false)
      }
      fetchAdvertisers()
    }
    setLoading(false)
    return () => abortController.abort()
  }, [user.user_type_id, setError])

  // TODO: update this api call for non enc users - still not working
  useEffect(() => {
    const abortController = new AbortController()
    if (user.user_type_id > 1) {
      const fetchAssignedCampaigns = async () => {
        // const response = await IMIApi.GetAllCampaigns(pagination.offset, pagination.limit)
        const response = await IMIApi.GetAllCampaignsPublic(true, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON.parse(response)
          setError({ errorMsg: parse.detail })
          return setLoadingAssigned(false)
        }
        setAssignedCampaigns(response.data)
        return setLoadingAssigned(false)
      }
      fetchAssignedCampaigns()
    }
    if (user.user_type_id < 2) {
      const fetchAssignedCampaigns = async () => {
        // const response = await IMIApi.GetAllCampaigns(pagination.offset, pagination.limit)
        const response = await IMIApi.GetCampaignCount(true, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON.parse(response)
          setError({ errorMsg: parse.detail })
          return setLoadingAssigned(false)
        }
        setAssignedCampaigns(response.data)
        return setLoadingAssigned(false)
      }
      fetchAssignedCampaigns()
    }
    setLoadingAssigned(false)
    return () => abortController.abort()
  }, [user.user_type_id, setError])

  return (
    <Padding>
      <SidebarRight
        mainContent={(
          <AdvertisersList
            data={allAdvertisers}
            loading={loading}
          />
        )}
        sidebarContent={(
          <>
            {user.user_type_id !== 6 && (
              <ActiveCampaignsToolbar
                active={assignedCampaigns?.total_rows || assignedCampaigns?.rows_total || 0}
                isLoading={loadingAssigned}
              />
            )}
            <div className="hidden md:block">
              <AdminToolsToolbar />
            </div>
          </>
        )}
      />
    </Padding>
  )
}

export default AdvertiserDashboardPage
