import {
  createContext, Dispatch, SetStateAction, useContext, useState,
} from 'react'
import { newUserInDb } from '../api/imi/factories'
import { IUserAdvertisers, IUserCampaigns, IUserInDb } from '../api/imi/interfaces'
import { AppContext } from './AppContext'

interface UserContextData {
  userInDb: IUserInDb
  setUserInDb: Dispatch<SetStateAction<IUserInDb>>
  userAdIds: IUserAdvertisers
  setUserAdIds: Dispatch<SetStateAction<IUserAdvertisers>>
  userCampIds: IUserCampaigns
  setUserCampIds: Dispatch<SetStateAction<IUserCampaigns>>
}

export function useUserContextFactory() {
  const appContext = useContext(AppContext)
  const [userInDb, setUserInDb] = useState<IUserInDb>(newUserInDb() as IUserInDb)
  const [userAdIds, setUserAdIds] = useState<IUserAdvertisers>()
  const [userCampIds, setUserCampIds] = useState<IUserCampaigns>()

  return {
    userInDb,
    setUserInDb,
    userAdIds,
    setUserAdIds,
    userCampIds,
    setUserCampIds,
    appContext,
  } as UserContextData
}

export const UserContext = createContext<UserContextData>({} as any)
