import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import CreateCallIndustry from '../../../components/adminTools/calls/category/CreateCallIndustry'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'

const CreateCallIndustryPage:React.FC = () => {
  const test = 1
  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <CreateCallIndustry />
        )}
      />
    </Padding>
  )
}

export default CreateCallIndustryPage
