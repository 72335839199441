import { useFormContext, Controller } from 'react-hook-form'
import Dropdown from '../Dropdown'
import { ICampaignInDb } from '../../../api/imi/interfaces'
import TextArea from '../TextArea'
import Input from '../Input'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  assignedCampaigns: ICampaignInDb[]
}

const CallInstructionsForm:React.FC<Props> = ({
  disabled,
  showButton,
  errors,
  assignedCampaigns,
}) => {
  const methods = useFormContext()
  return (
    <>
      {assignedCampaigns?.length ? (
        <Controller
          name="campaign_id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              placeholder="Select..."
              // placeholder={userInDb.id > 0 ? undefined : 'Select...'}
              label="Campaign"
              variant="admin"
              value={methods.getValues('id')}
              list={assignedCampaigns}
              error={errors.id && <span className="form-error">This field is required</span>}
              toolTipInfo="Select a campaign to create new instructions. If you're unable to find your campaign, instructions may have already been created."
              required
              campaign
              typeName={false}
            />
          )}
        />
      ) : (
        <Input
          label="Campaign"
          type="text"
          disabled
          {...methods.register('campaign.name', { required: true })}
        />
      )}
      <TextArea
        label="Instructions"
        {...methods.register('body', { required: true })}
        toolTipInfo="Call processing instructions are specific to each campaign and will appear at the top of the &quot;process calls&quot; page for that campaign. Please provide all details needed to help process that campaign's calls. Line breaks, styled lists, and other special formatting are currently unavailable."
        disabled={!showButton}
      />
    </>
  )
}

export default CallInstructionsForm
