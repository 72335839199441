// NOTE: Top portion of Edit ROI
// titled "campaign details"

import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/solid'
import ToolTip from '../../../ToolTip'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import {
  IRoiUpdate,
} from '../../../../api/imi/interfaces'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { AppContext } from '../../../../contexts/AppContext'
import LoadingSpinner from '../../../LoadingSpinner'
import Input from '../../../form/Input'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import Radio from '../../../form/Radio'

interface Props {
  loading: boolean
  edit: boolean
  setRefreshTodo: any
  roiId: number
}

const EditRoi:React.FC<Props> = ({
  loading,
  edit,
  setRefreshTodo,
  roiId,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)
  const [showButton, setShowButton] = useState<boolean>(edit)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  const methods = useForm<IRoiUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      id: campaignInDb?.id,
      roi_is_active: campaignInDb?.roi_is_active,
      // lead_types: campaignInDb?.lead_types,
    }), [campaignInDb]),
  })

  useEffect(() => {
    methods.reset({ ...campaignInDb })
  }, [methods, campaignInDb])

  const cancel = () => {
    methods.reset(campaignInDb, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      id: data.id,
      roi_is_active: data.roi_is_active,
      lead_types: campaignInDb?.lead_types,
    }
    const submitResponse = await IMIApi.CreateCampaignRoi(formData)

    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setCampaignInDb({
        ...campaignInDb,
        roi_is_active: data.roi_is_active,
        lead_types: campaignInDb?.lead_types,
      })
      setRefreshTodo(Math.random)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  const editTemplate = () => {
    setShowButton(true)
  }

  return (
    <>
      <Content
        title="roi details"
        icon={(
          <ToolTip
            size="sm"
            position="relative"
            tipId="roi-details"
          >
            Changes made to the ROI details impact current and future cycles only. If you need to make changes to past cycles, reach out to the developer.
          </ToolTip>
        )}
      >
        {loading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              noValidate
            >
              <Input
                disabled
                name="campaign"
                label="Campaign"
                type="text"
                value={`${campaignInDb?.secondary_name ? `${campaignInDb?.name}: ${campaignInDb?.secondary_name}` : campaignInDb?.name} ${campaignInDb?.specific?.name ? `- ${campaignInDb?.specific?.name}` : ''}`}
                required={false}
              />
              <Input
                disabled
                name="call_processing_is_active"
                label="Does this campaign’s current cycle have call processing?"
                type="text"
                toolTipInfo="This information is pulled from the “Call Settings” page.  To change this setting, visit the “Call Settings” page."
                value={campaignInDb?.call_processing_is_active ? 'Yes' : 'No'}
                required={false}
              />
              <LinkWrapper
                disabled={roiId < 1}
                target="_blank"
                href="/admin-tools/call-settings/edit-call-settings"
                variant="defaultAdmin"
                className="mb-6 -mt-3 block"
                onClick={() => handleLocalStorage(roiId)}
              >
                Edit Call Settings
                <Icon size="xxs" className={`${roiId < 1}` ? 'cursor-not-allowed opacity-60' : ''}>
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
              {campaignInDb?.call_processing_is_active && (
                <Input
                  disabled
                  name="campaignInDb?.category.name"
                  label="Call industry"
                  type="text"
                  toolTipInfo="The industry shown here reflects the industry selected on the the “Call Settings” page."
                  value={campaignInDb?.category.name}
                  required={false}
                />
              )}
              <Radio
                name="roi_is_active"
                label="Should the report for this campaign’s current cycle include the ROI section?"
                disabled={!showButton || !campaignInDb}
                options={[
                  {
                    id: 1,
                    label: 'Yes',
                    value: true,
                  },
                  {
                    id: 0,
                    label: 'No',
                    value: false,
                  },
                ]}
              />
              <FormButtonGroup
                isButtonLoading={isButtonLoading}
                disabled={!methods.formState.isValid || !methods.formState.isDirty}
                disabledCancel={!methods.formState.isDirty}
                onClickCancel={cancel}
                btnText={btnText}
                showButton={showButton}
                onClickEdit={editTemplate}
              />
            </form>
          </FormProvider>
        )}
      </Content>
    </>
  )
}

export default EditRoi
