import {
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useContext,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { IAdvertiser, IAdvertiserInDb, IUserCreate } from '../../../api/imi/interfaces'
import LinkWrapper from '../../Links'
import Icon from '../../Icon'
import Content from '../../layouts/Content'
import { IMIApi } from '../../../api/imi/api'
import FormButtonGroup from '../../button/FormButtonGroup'
import LoadingCheck from '../../../hoc/LoadingCheck'
import { AppContext } from '../../../contexts/AppContext'
import AdvertiserForm from '../../form/advertiser/AdvertiserForm'

// TODO: form validation
// TODO: match user id to white label and other "sections"
// TODO: is_active is returning string of "true" instead of boolean true

interface Props {
  setAdvertiserId: Dispatch<SetStateAction<number>>
}

const CreateAdvertiser:React.FC<Props> = ({ setAdvertiserId }) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)

  const methods = useForm<IAdvertiser>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      is_active: true,
    }), []),
  })

  const cancel = () => {
    methods.reset()
    setBtnText('Save')
    methods.clearErrors()
    setIsButtonLoading(false)
  }

  const onSubmit: SubmitHandler<IAdvertiser> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.CreateAdvertiser(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setAdvertiserId(submitResponse?.data?.id)
    }, 1000)
  }

  return (
    <>
      <Content title="Advertiser Details">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <AdvertiserForm
              errors={methods.formState.errors}
              showButton
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!methods.formState.isValid}
              disabledCancel={!methods.formState.isDirty}
              onClickCancel={cancel}
              btnText={btnText}
              showButton
            />
          </form>
        </FormProvider>
      </Content>
      <Content
        title="campaigns"
      >
        <p>To connect a campaign to this advertiser, save the details for this new advertiser. Then, go to “campaign settings” to create or assign a campaign, which will allow you to select an advertiser.</p>
        {/* <LinkWrapper
          href="/admin-tools/campaign-settings/create-campaign"
          variant="defaultAdmin"
          className="mb-6 inline-block mr-3"
          target="_blank"
        >
          Create New Campaign
          <Icon size="xxs">
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper> */}
        <LinkWrapper
          href="/admin-tools/campaign-settings"
          variant="defaultAdmin"
          className="mb-6 inline-block"
          target="_blank"
        >
          Assign Campaign
          <Icon size="xxs">
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper>
      </Content>
    </>
  )
}

export default LoadingCheck(CreateAdvertiser)
