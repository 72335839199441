// TODO: error state
// TODO: update label styles after design reveal
import React, { forwardRef } from 'react'
import { cls } from '../../helpers/utils'
import ToolTip from '../ToolTip'
import { ENV } from '../../env'

const styles = {
  base: 'w-full max-w-form font-base text-xs text-default rounded border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none',
  special: 'w-full max-w-form font-base text-xs text-default rounded-l border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none',
  dollar: 'w-full max-w-form font-base text-xs text-default rounded-l-0 rounded-r border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none',
  icon: 'pl-10',
  enabled: 'focus:ring-1 focus:ring-primary focus:border-transparent bg-grey-lightest',
  disabled: 'bg-white cursor-not-allowed text-disabled focus:ring-0 focus:ring-transparent',
}

// NOTE: add class name 50-split to input if you want styling to look like the dashboard
// branding page form for "Login URL" input field.

type InputRef = React.ForwardedRef<HTMLInputElement>

interface Props {
  label: string
  type: string
  name: string
  placeholder?: string
  className?: string
  width?: string
  hideLabel?: boolean
  toolTipInfo?: string | React.ReactNode
  options?: any[]
  icon?: React.ReactNode
  required?: boolean
  onChange?: (e) => void
  // React-hook-forms specific
  rest?: any
  error?: any
  ref?: any
  onBlur?: any
  disabled?: boolean
  children?: React.ReactNode
  value?: string | []
  tipId?: string
  trashIcon?: React.ReactNode
  accept?: string
  autoComplete?: string
}

const Input:React.FC<Props> = forwardRef(
  (
    {
      label,
      tipId,
      type,
      icon,
      placeholder,
      className,
      width,
      toolTipInfo,
      hideLabel = false,
      options,
      name,
      required,
      rest,
      error,
      onChange,
      onBlur,
      disabled,
      children,
      value,
      trashIcon,
      accept,
      autoComplete,
    }:Props, ref:InputRef,
  ) => (
    <div className={`mb-6 ${className}`}>
      <label htmlFor={label} className={`normal-case heading-5 font-semibold mb-1 ${hideLabel ? 'hidden' : 'block'}`}>
        {trashIcon ? (
          <div className="flex justify-between items-baseline normal-case">
            <span className="normal-case">
              {label}
            </span>
            {required ? <span className="text-primary">*</span> : null}
            {trashIcon}
          </div>
        ) : (
          <span className="normal-case">
            {label}
            {required ? <span className="text-primary">*</span> : null}
          </span>
        )}
      </label>
      <div className="relative">
        {icon ? <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">{icon}</div> : ''}
        <div className="flex items-center">
          {className === 'dollar-split' && (
            <span className="max-w-form font-base text-xs text-default rounded-l border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none border-r-0">
              <span className="text-disabled text-default text-sm">$</span>
            </span>
          )}
          <input
            readOnly={disabled}
            onBlur={onBlur}
            ref={ref}
            onChange={onChange}
            type={type}
            name={name}
            value={value}
            data-testid={label.toLowerCase().replace(/\s+/g, '-')}
            placeholder={placeholder}
            accept={accept}
            autoComplete={autoComplete}
            className={cls(`
                      ${((className === 'percent-split' || className === '50-split') && styles.special) || (className === 'dollar-split' && styles.dollar) || styles.base}
                      ${icon ? styles.icon : ''}
                      ${disabled ? styles.disabled : styles.enabled}
                      ${className}
                      `)}
            {...rest}
          />
          {className === '50-split' && (
            <span className="w-full max-w-form font-base text-xs text-default rounded-r border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none border-l-0">
              {/* <span className="text-disabled text-default text-sm">.imidashboard.com</span> */}
              <span className="text-disabled text-default text-sm">{ `.${ENV.imiURL.replace(/^https?:\/\/staging/, '')}` }</span>
            </span>
          )}
          {className === 'percent-split' && (
            <span className="max-w-form font-base text-xs text-default rounded-r border bg-transparent border-grey-light px-3 py-2 min-h-btn focus:outline-none border-l-0">
              <span className="text-disabled text-default text-sm">%</span>
            </span>
          )}
          {toolTipInfo ? <ToolTip tipId={tipId === undefined ? label : tipId} size="sm" position="relative ml-3">{toolTipInfo}</ToolTip> : null}
          {(type === 'text' && !toolTipInfo && width !== 'w-full') && (
            <span className="pr-8" />
          )}
        </div>
        {children}
        {error && <p className="form-error">{error}</p>}
      </div>
    </div>
  ),
)

Input.defaultProps = {
  icon: undefined,
  className: 'input',
  width: undefined,
  hideLabel: false,
  required: true,
  placeholder: undefined,
  toolTipInfo: undefined,
  options: undefined,
  onChange: undefined,
  rest: null,
  error: null,
  ref: null,
  onBlur: null,
  disabled: false,
  children: null,
  value: undefined,
  tipId: undefined,
  trashIcon: null,
  accept: undefined,
  autoComplete: 'off',
}

export default Input
