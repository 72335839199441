// NOTE: can't have two ROI named items, so this is the "settings" page if 
// you click on "Return on Investment (ROI) in the admin tools menu/sidebar"

import {
  useContext,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { handleFilters, getFieldByPath } from '../../../../helpers/utils'
import Search from '../../../form/Search'
import Content from '../../../layouts/Content'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Modal from '../../../modal'
import Icon from '../../../Icon'
import { roiStatusMenu, settingsStyles } from './Data'
import BadgeAlert from '../../../badge/BadgeAlert'
import Dropdown from '../../../form/Dropdown'
import EditMenu from '../../EditMenu'
import { IRoiMultiResponse, IRoiMultiResponseFormatted } from '../../../../api/imi/interfaces'
import LoadingSpinner from '../../../LoadingSpinner'
import { campaignSettingsStatus } from '../campaign_settings/Data'
import LinkWrapper from '../../../Links'

const formatLeads = (leadTypes) => leadTypes
  .map((lead, index) => {
    if (index !== leadTypes.length - 1) {
      return `${lead.name}; `
    }
    return lead.name
  })

const formatCampaigns = (campaigns) => campaigns.map((campaign) => ({
  ...campaign,
  lead_types: {
    name: campaign.roi_is_active ? formatLeads(campaign.lead_types) : null,
    types: campaign.lead_types,
  },
}))

interface Props {
  assignedCampaigns: IRoiMultiResponse[]
  refresh: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
}

const RoiSettings:React.FC<Props> = ({
  assignedCampaigns,
  refresh,
  isLoading,
}) => {
  const [errorMsg, setErrorMsg] = useState('There are no campaigns found.')
  const [filteredCampaigns, setFilteredCampaigns] = useState<IRoiMultiResponseFormatted[]>([] as any)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
    status: 0,
  })
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [listLeadTypes, setListLeadTypes] = useState<boolean>(false)

  const showLength = () => (
    setListLeadTypes(!listLeadTypes)
  )

  useEffect(() => {
    setFilteredCampaigns(formatCampaigns(assignedCampaigns))
  }, [assignedCampaigns])

  const getLeadTypes = (leadTypes) => (
    <span>
      {(!listLeadTypes && leadTypes.types.length > 6) ? (
        <>
          {leadTypes.name.slice(0, 6)}
          <LinkWrapper
            variant="defaultSm"
            href="#"
            onClick={showLength}
          >
            {(!listLeadTypes && leadTypes.types.length > 6) ? (
              ` +${leadTypes.types.length - 6}`
            ) : (
              leadTypes.name
            )}

          </LinkWrapper>
        </>
      ) : (
        leadTypes.name
      )}
    </span>
  )

  const sortBy = (a: string, b: string) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
  
    return 0
  }

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    const values = !sortDirection ? filteredCampaigns?.sort((a, b) => sortBy(getFieldByPath(a, fieldName), getFieldByPath(b, fieldName))) : filteredCampaigns?.sort((a, b) => sortBy(getFieldByPath(b, fieldName), getFieldByPath(a, fieldName)))
    if (fieldName === 'category.name') {
      if (!sortDirection) {
        return values.sort((a, b) => (a.roi_is_active > b.roi_is_active ? -1 : 1))
      }
      return values.sort((a, b) => (a.roi_is_active < b.roi_is_active ? -1 : 1))
    }
    return { values }
  }

  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const campaignStatus = status.is_active
      if (campaignStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status
    }
    const filterSearchValue = (callSettingsData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = callSettingsData.name.toLowerCase().includes(splitItems)

      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => callSettingsData.name.toLowerCase().includes(si))
        return nameFil
      }
      return nameFilter
    }

    const filterData = assignedCampaigns?.filter((cycle) => filterSearchValue(cycle) && filterStatus(cycle))
    setFilteredCampaigns(formatCampaigns(filterData))
  }, [selectedFilters, assignedCampaigns])

  useEffect(() => {
    filters()
  }, [assignedCampaigns, filters])

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  return (
    <>
      <AdminToolsMobileLink />
      <Search
        label="search"
        hideLabel
        placeholder="search..."
        className="w-full max-w-none"
        value={selectedFilters.search}
        onChange={(
          (e) => setSelectedFilters(handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters))
        )}
      />
      <Content
        title={(
          selectedFilters.status === 0 ? 'active sem campaigns' : 'inactive sem campaigns'
        )}
        padding={false}
        badge={(
          <BadgeAlert
            alert={filteredCampaigns?.length || 0}
            status={selectedFilters.status === 0}
          />
        )}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={campaignSettingsStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-2"
              mdGrid="md:grid-cols-roi"
            > 
              {roiStatusMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && settingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredCampaigns?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filteredCampaigns?.map((campaign) => (
                  <HorizontalGrid
                    key={campaign.id}
                    smGrid="grid-cols-2"
                    mdGrid="md:grid-cols-roi"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      <LinkWrapper
                        variant="defaultSm"
                        key={campaign.id}
                        onClick={() => handleLocalStorage(campaign.id)}
                        href={{
                          pathname: '/admin-tools/return-on-investment/edit-return-on-investment',
                        }}
                      >
                        {campaign.name}
                        {campaign.secondary_name && (
                          `: ${campaign.secondary_name}`
                        )}
                        {campaign.specific && (
                          `- ${campaign.specific}`
                        )}
                      </LinkWrapper>
                    </p>
                    <p className="text-xxs pr-3">
                      {campaign.roi_is_active ? <>Yes</> : <>No</> }
                    </p>
                    <p className="hidden md:block text-xxs pr-3 mr-3">
                      {campaign.roi_is_active ? (
                        <>
                          {getLeadTypes(campaign.lead_types)}
                        </>
                      ) : <>-</> }
                    </p>
                    <EditMenu
                      text="Edit ROI"
                      id={campaign.id}
                      pathname="/admin-tools/return-on-investment/edit-return-on-investment"
                      onClick={() => handleLocalStorage(campaign.id)}
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default RoiSettings
