import { 
  useState, 
  useEffect, 
  useRef, 
  useMemo,
} from 'react'

interface Props {
  children?: React.ReactNode
  title: {}
}

const StickyReportHeader:React.FC<Props> = ({
  children,
  title,
}) => {
  const [position, setPosition] = useState(0)
  const [scrollingUp, setScrollingUp] = useState(false)
  const [showSticky, setShowSticky] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<() => void>()

  useEffect(() => {
    scrollRef.current = () => {
      if (window.scrollY < position && !scrollingUp) {
        setScrollingUp(true)
      } else if (window.scrollY > position && scrollingUp) {
        setScrollingUp(false)
      }
  
      if (containerRef.current) {
        const bounds = containerRef.current.getBoundingClientRect()
        const scrolledPast = bounds.top <= 0
  
        if (scrolledPast && !showSticky) {
          setShowSticky(true)
        } else if (!scrolledPast && showSticky) {
          setShowSticky(false)
        }
      }
  
      setPosition(window.scrollY)
    }
  }, [
    position, 
    scrollingUp, 
    showSticky, 
    setPosition, 
    setScrollingUp, 
    setShowSticky,
  ])

  useEffect(() => {
    const event = () => {
      if (scrollRef.current) {
        scrollRef.current()
      }
    }

    document.addEventListener('scroll', event)

    return () => {
      document.removeEventListener('scroll', event)
    }
  }, [])

  const style = useMemo(() => {
    if (headerRef.current) {
      if (scrollingUp && showSticky) {
        return {
          top: 0,
        }
      }

      const bounds = headerRef.current.getBoundingClientRect()

      return {
        top: -bounds.height,
      }
    } 
  }, [scrollingUp, showSticky])

  return (
    <div ref={containerRef}>
      <div ref={headerRef} style={style} className="mobile-header bg-tertiary pt-6 px-6 lg:px-0">
        <div className="sm:flex bg-tertiary">
          <h2 className="text-light">
            {title}
          </h2>
          {children}
        </div>
      </div>
    </div>
  )
}

export default StickyReportHeader
