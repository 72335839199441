import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import {
  IAdvertiserInDb,
  ICampaignInDb,
  ICampaignUpliftDetail,
  ICampaignUpliftInDb,
} from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import CampaignSettings from '../../../components/adminTools/campaigns/campaign_settings/CampaignSettings'
import Uplift from '../../../components/adminTools/campaigns/uplift/Uplift'
import CreateUplift from '../../../components/adminTools/campaigns/uplift/CreateUplift'
import EditUplift from '../../../components/adminTools/campaigns/uplift/EditUplift'

const CreateUpliftPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState<number>(0)
  const [advertisers, setAdvertisers] = useState<IAdvertiserInDb[]>([] as any)
  const [advertiserId, setAdvertiserId] = useState<number>(0)
  const [campaigns, setCampaigns] = useState<ICampaignInDb[]>([] as any)
  const [campaignId, setCampaignId] = useState<number>(0)
  const [uplift, setUplift] = useState<ICampaignUpliftDetail>({} as any)
  const { setError } = useContext(AppContext)
  const [upliftId, setUpliftId] = useState<number>(0)
  const [campaignUplift, setCampaignUplift] = useState<ICampaignUpliftInDb>({} as any)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  // Get all advertisers
  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllAdvertisers = async () => {
      const response = await IMIApi.GetAllAdvertisers(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAdvertisers(response.data.data)
      return setLoading(false)
    }
    fetchAllAdvertisers()
    return () => abortController.abort()
  }, [refresh, setError])

  // After advertiser is selected, fetch campaigns
  // assigned to advertiser id
  useEffect(() => {
    const abortController = new AbortController()
    if (advertiserId > 0) {
      const fetchCampaignsForAdvertiser = async () => {
        const response = await IMIApi.GetCampaignsByAdvertiserId(advertiserId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaigns(response.data.filter((campaign) => campaign.uplift_base === 0))
        return setLoading(false)
      }
      fetchCampaignsForAdvertiser()
    }
    // if there is no advertiser id, set empty array
    // to disable form field
    setCampaigns([])
    return () => abortController.abort()
  }, [advertiserId, setError])

  // Get campaign's uplift
  useEffect(() => {
    const abortController = new AbortController()
    if (campaignId > 0) {
      const getCampaignById = async () => {
        const response = await IMIApi.GetCampaignById(campaignId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setUplift(response.data)
        return setLoading(false)
      }
      getCampaignById()
    }
    setCampaignId(0)
    return () => abortController.abort()
  }, [campaignId, setError])

  useEffect(() => {
    const abortController = new AbortController()
    if (upliftId > 0) {
      const fetchCampaignUplift = async () => {
        const response = await IMIApi.GetCampaignUpliftById(upliftId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaignUplift(response.data)
        return setLoading(false)
      }
      fetchCampaignUplift()
    }
    setCampaigns([])
    return () => abortController.abort()
  }, [upliftId, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          upliftId > 0 ? (
            <EditUplift
              edit={false}
              campaignUplift={campaignUplift}
              loading={loading}
              setRefresh={setRefresh}
            />
          ) : (
            <CreateUplift
              setUpliftId={setUpliftId}
              advertisers={advertisers}
              campaigns={campaigns}
              loading={loading}
              setAdvertiserId={setAdvertiserId}
              setCampaignId={setCampaignId}
            />
          )
        )}
      />
    </Padding>
  )
}
export default CreateUpliftPage
