import { useContext, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import Radio from '../../Radio'
import { UserContext } from '../../../../contexts/UserContext'
import Input from '../../Input'
import Dropdown from '../../Dropdown'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import { IAdvertiserInDb, ICampaignInDb, ICampaignTypeInDb } from '../../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  createdCampaigns: ICampaignInDb[]
}

const AssignCampaignForm:React.FC<Props> = ({
  showButton,
  errors,
  createdCampaigns,
}) => {
  const methods = useFormContext()
  const { campaignInDb } = useContext(CampaignContext)
  const campaignInfo = 'Selecting the parent campaign will allow the campaign being assigned to inherit its campaign details. Edit the parent campaign to make any updates.'

  return (
    <>
      {createdCampaigns && (
        <Controller
          name="campaign_id"
          control={methods.control}
          rules={{ 
            required: true,
            onChange: () => methods.trigger('campaign_id'),
          }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              // placeholder="Select..."
              placeholder={campaignInDb?.campaign_assignment_to_parent ? undefined : 'Select...'}
              label="Campaign"
              variant="admin"
              value={methods.getValues('campaign_id')}
              list={createdCampaigns && createdCampaigns?.length > 0 ? createdCampaigns : [{ id: 0, name: 'There are no created parent campaigns.' }]}
              error={errors.id && <span className="form-error">This field is required</span>}
              toolTipInfo={campaignInfo}
              required
              campaign
              typeName
            />
          )}
        />
      )}
  
    </>
  )
}

export default AssignCampaignForm
