import {
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Input from '../../Input'
import Dropdown from '../../Dropdown'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import {
  IAdvertiserInDb,
  ICampaignTypeInDb,
  IPCampaign,
} from '../../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  allAdvertisers: IAdvertiserInDb[]
  allCampaignTypes: ICampaignTypeInDb[]
  pCampaign?: IPCampaign
  hideAdvertiser?: boolean
}

// NOTE: This is the form that applies to any campaign information displayed
// in the "campaign details" section when you go to edit or assign a campaign.
// This form displays the original pcampaign information, as well as any overrides
// to the campaign advertiser name, campaign secondary name, campaign type, and
// campaign specifics.

// NOTE: There is a huge difference in functionality between SEM campaigns and
// all other campaigns. SEM campaigns do NOT follow the same flow and the pcampaign
// automatically becomes the main Campaign.
// Display and video campaigns need to follow the flow of creating a Campaign first
// to then come here to edit the pcampaign and assign it to a created Campaign. If you
// have not created a Campaign first, and you go to assign a display pcampaign, it will
// not be able to be assigned since the Campaign does not show up in the dropdown.

// NOTE: For SEM campaigns, when you edit the Campaign, it changes the main campaign and
// that's what's displayed. For display and video, you'll see both Campaign and pcampaign
// details to edit. Therefore, two put routes to edit Campaign and pcampaign are required
// based on the campaign type you're assigning or editing.

const EditCampaignForm:React.FC<Props> = ({
  showButton,
  errors,
  allAdvertisers,
  allCampaignTypes,
  pCampaign,
  hideAdvertiser,
}) => {
  const methods = useFormContext()
  const [specifics, setSpecifics] = useState<[]>([])
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const advertiserInfo = () => {
    if (campaignInDb?.id > 0) {
      if (campaignInDb?.type?.id === 2) {
        return 'Please change the advertiser in “campaign assignment” to change the name of the campaign.'
      }
      return 'Select the advertiser that will be associated with this campaign.'
    }
    if (pCampaign?.type_id === 2) {
      return 'Select the advertiser to create the default name for the campaign. This field automatically assigns the advertiser in the “campaign assignment” section. To edit this field after the initial save, you need to edit in the “campaign assignment” section.'
    }
    return 'Select the advertiser that will be associated with this campaign.'
  }
  // const advertiserInfo = campaignInDb?.id > 0 ? 'Please change the advertiser in “campaign assignment” to change the name of the campaign.' : 'Select the advertiser to create the default name for the campaign. This field automatically assigns the advertiser in the “campaign assignment” section. To edit this field after the initial save, you need to edit in the “campaign assignment” section.'
  const campaignTypInfo = 'Select the campaign type this campaign falls under.'
  const campaignSpecificsInfo = (
    <span>
      Choose from the dropdown to provide additional information about specific campaign identifiers. This is not a required field; it should be used in the instance where providing specifics about a campaign would help distinguish it from other campaigns.
      <br />
      <br />
      The “Blended” option will only apply to ReachLocal campaigns.
    </span>
  )
  const secondaryNameInfo = (
    <span>
      The secondary name provides additional name/s and/or differentiators for advertisers (example: location, specific name of campaign, etc.). This is not a required field; it should be used in the instance where differentiating between different campaigns is helpful/necessary.
      <br />
      <br />
      Ex: Dallas - Wigs
      <br />
      <br />
      Please be sure to check proper spelling. Use title case formatting.
    </span>
  )
  const campaignTypeWatch = methods.watch('type_id')
  // const watchName = methods.watch('name')
  const [nameId, setNameId] = useState<any>({ name: '', id: 0 })
  const [advertiserId, setAdvertiserId] = useState<any>()

  useEffect(() => {
    const watchName = methods.watch('advertiser_id')
    setAdvertiserId(watchName)
    // return () => watchName.unsubscribe()
  }, [methods])

  useEffect(() => {
    allAdvertisers?.filter((advertiser) => {
      if (advertiser?.name === advertiserId || advertiser?.id === advertiserId) {
        setNameId({ name: advertiser.name, id: advertiser.id })
        return methods.setValue('name', advertiser.name)
      }
      return allAdvertisers
    })
  }, [advertiserId, allAdvertisers, methods])

  useEffect(() => {
    allCampaignTypes?.filter((specific) => {
      if (specific.id === campaignTypeWatch) {
        return setSpecifics(specific.specifics)
      }
      return () => campaignTypeWatch.unsubscribe()
    })
  }, [allCampaignTypes, campaignTypeWatch])

  useEffect(() => {
    if (campaignTypeWatch === 2) {
      return methods.setValue('specific_id', null)
    }
    // return methods.setValue('specific_id', campaignInDb?.specific?.id)
  }, [campaignInDb?.type?.id, campaignTypeWatch, campaignInDb?.specific?.id, methods])

  return (
    <>
      {campaignInDb?.type?.id === 2 && campaignInDb?.pcampaign?.map((pcampaign) => (
        <div key={pcampaign?.id}>
          <Input
            name="source name"
            required={false}
            label="Source name"
            type="text"
            disabled
            value={pcampaign?.name}
          />
          <Input
            name="platform"
            required={false}
            label="Platform"
            type="text"
            disabled
            value={pcampaign?.platform?.name}
          />
          <Input
            name="platform id"
            required={false}
            label="Platform ID"
            type="text"
            disabled
            value={pcampaign?.api_id}
          />
          <Input
            name="source type"
            className="capitalize"
            required={false}
            label="Source type"
            type="text"
            disabled
            value={pcampaign?.api_type}
          />
        </div>
      ))}
      {pCampaign && (
        <>
          <Input
            name="source name"
            required={false}
            label="Source name"
            type="text"
            disabled
            value={pCampaign?.name}
          />
          <Input
            name="platform"
            required={false}
            label="Platform"
            type="text"
            disabled
            value={pCampaign?.platform?.name}
          />
          <Input
            name="platform id"
            required={false}
            label="Platform ID"
            type="text"
            disabled
            value={pCampaign?.api_id}
          />
          <Input
            name="source type"
            required={false}
            label="Source type"
            type="text"
            disabled
            className="capitalize"
            value={pCampaign?.api_type}
          />
        </>
      )}
      <>
        {(allAdvertisers?.length && !hideAdvertiser) && (
          <Controller
            name="advertiser_id"
            control={methods.control}
            rules={{ required: true }}
            render={({
              field,
            }) => (
              <Dropdown
                disabled={!showButton || (campaignInDb?.id > 0 && campaignInDb?.type?.id === 2)}
                hookFormField={field}
                placeholder={campaignInDb?.id > 0 ? undefined : 'Select...'}
                // placeholder={campaignInDb?.id > 0 ? methods.getValues('name') : 'Select...'}
                value={field.value}
                label="Advertiser"
                variant="admin"
                list={allAdvertisers}
                error={errors.id && <span className="form-error">This field is required</span>}
                toolTipInfo={(advertiserInfo())}
                required
                tipId="advertiser_name"
              />
            )}
          />
        )}
        {(!!allCampaignTypes?.length && campaignInDb?.id > 0) && (
          <Controller
            name="type_id"
            control={methods.control}
            rules={{ required: true }}
            render={({
              field,
            }) => (
              <Dropdown
                disabled={!showButton}
                hookFormField={field}
                placeholder={campaignInDb?.id > 0 ? undefined : 'Select...'}
                label="Type"
                variant="admin"
                value={field.value}
                list={allCampaignTypes}
                error={errors.id && <span className="form-error">This field is required</span>}
                toolTipInfo={campaignTypInfo}
                required
              />
            )}
          />
        )}
        {(!!allCampaignTypes?.length && campaignInDb?.id === 0) && (
          <Controller
            name="type_id"
            control={methods.control}
            rules={{ required: true }}
            render={({
              field,
            }) => (
              <Dropdown
                disabled={!showButton}
                hookFormField={field}
                placeholder={campaignInDb?.id > 0 ? undefined : 'Select...'}
                label="Type"
                variant="admin"
                value={field.value}
                list={allCampaignTypes}
                error={errors.id && <span className="form-error">This field is required</span>}
                toolTipInfo={campaignTypInfo}
                required
              />
            )}
          />
        )}
        <Input
          disabled={!showButton}
          {...methods.register('secondary_name', { required: false, onChange: () => methods.trigger('secondary_name') })}
          label="Secondary name"
          type="text"
          error={errors.secondary_name && <span className="form-error">This field is required</span>}
          toolTipInfo={secondaryNameInfo}
          required={false}
        />
        {(methods.getValues('type_id') === 1 || methods.getValues('type_id') === 3) && specifics.length > 0 && (
          <Controller
            name="specific_id"
            control={methods.control}
            rules={{ 
              required: false,
              onChange: () => methods.trigger('specific_id'), 
            }}
            render={({
              field,
            }) => (
              <Dropdown
                disabled={!showButton || specifics?.length < 1}
                hookFormField={field}
                // placeholder={undefined}
                placeholder={campaignInDb?.specific?.id > 0 ? undefined : 'Select...'}
                label="Campaign specifics"
                variant="admin"
                value={field.value}
                // list={specifics}
                list={(
                  [...specifics, { id: null, name: 'Select to remove campaign specific if assigned.' }]
                )}
                error={errors.id && <span className="form-error">This field is required</span>}
                toolTipInfo={campaignSpecificsInfo}
                required={false}
              />
            )}
          />
        )}
      </>
    </>
  )
}

export default EditCampaignForm
