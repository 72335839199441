// When to use:
// use the horizontal grid inside the Layout component to
// achieve a horizontal layout. Map over to duplicate rows.
// On mobile collapses to two columns with description on
// left and the value on right.
// smGrid is mobile, mdGrid is desktop
//
// Must wrap div around body of backgroundColor horizontal grids
// because the "header" can serve as the odd color that
// breaks the pattern.

interface Props {
  children: React.ReactNode
  button?: React.ReactNode
  grid?: boolean
  smGrid?: number,
  mdGrid?: number,
  justifyContent?: 'justify-start' | 'justify-end' | 'justify-center' | 'justify-between' | 'justify-around' | 'justify-evenly',
  alignItems?: 'items-start' | 'items-end' | 'items-center' | 'items-baseline' | 'items-stretch',
  backgroundColor?: boolean,
  border?: boolean,
  header?: boolean,
}

const ListingPageGridFlex:React.FC<Props> = ({
  children,
  button,
  smGrid,
  mdGrid,
  grid = true,
  justifyContent = 'justify-start',
  alignItems = 'items-center',
  backgroundColor = false,
  border = false,
}) => (
  <div className="relative">
    <div className="min-w-0 text-left heading-5 font-semibold m-0">
      <div className="block md:flex min-w-0 text-left heading-5 font-semibold">
        {children}
        {button}
      </div>
    </div>
  </div>
)

export default ListingPageGridFlex
