import {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import Dropdown from '../../../form/Dropdown'
import Content from '../../../layouts/Content'
import {
  campaignSettingsStatus,
  campaignSettingsMenu,
  campaignSettingsStyles,
} from './Data'
import { handleFilters, getFieldByPath } from '../../../../helpers/utils'
import BadgeAlert from '../../../badge/BadgeAlert'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import EditMenu from '../../EditMenu'
import Icon from '../../../Icon'
import LinkWrapper from '../../../Links'
import Modal from '../../../modal'
import Pagination from '../../../Pagination'
import { ICampaignInDb } from '../../../../api/imi/interfaces'
import LoadingSpinner from '../../../LoadingSpinner'
import { IMIApi } from '../../../../api/imi/api'
import { AppContext } from '../../../../contexts/AppContext'

interface Props {
  assignedCampaigns: ICampaignInDb[]
  setPagination: Dispatch<SetStateAction<any>>
  setRefresh: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  totalData: number
}

const AssignedCampaigns:React.FC<Props> = ({
  assignedCampaigns,
  setPagination,
  setRefresh,
  isLoading,
  totalData,
}) => {
  const { setError } = useContext(AppContext)
  const [showModal, setShowModal] = useState(false)
  const [currentCampaign, setCurrentCampaign] = useState<ICampaignInDb>()
  const [errorMsg, setErrorMsg] = useState('There are no campaigns found.')
  const [btnText, setBtnText] = useState('Change')
  const [cancelText, setCancelText] = useState('Cancel')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [filtered, setFiltered] = useState<ICampaignInDb[]>(assignedCampaigns)
  // holds the state of which column is to be sorted, all independent
  const [sortColumn, setSortColumn] = useState<string>('')
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState({
    status: 0,
    search: '',
  })

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    const values = !sortDirection ? filtered?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? 1 : -1)) : filtered?.sort((a, b) => (getFieldByPath(a, fieldName) > getFieldByPath(b, fieldName) ? -1 : 1))
    return { values }
  }

  const handleLoadMore = () => {
    setPagination({
      offset: currentOffset + 10,
      limit: 10,
    })
    setCurrentOffset(currentOffset + 10)
  }
  const handleReturn = () => {
    if (currentOffset > 0) {
      setPagination({
        offset: currentOffset - 10,
        limit: 10,
      })
      setCurrentOffset(currentOffset - 10)
    }
  }
  const filters = useCallback(() => {
    const filterStatus = (status) => {
      const campaignStatus = status.is_active
      if (campaignStatus) {
        return selectedFilters.status === 0
      }
      return selectedFilters.status === 1
    }
    const filterAssignedData = assignedCampaigns?.filter((campaign) => filterStatus(campaign))
    setFiltered(filterAssignedData)
  }, [selectedFilters, assignedCampaigns])

  useEffect(() => {
    filters()
  }, [filters])

  const handleLocalStorage = (id) => {
    localStorage.setItem('campaign_id', JSON.stringify(id))
  }

  const modal = (campaign) => {
    setCancelText('Cancel')
    setBtnText('Delete')
    setCurrentCampaign(campaign)
    setShowModal(true)
  }

  const deleteCampaign = async (currentId) => {
    setIsButtonLoading(true)
    setCancelText('')
    setBtnText('Saving...')
    const submitResponse = await IMIApi.DeleteCampaign(currentId)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setRefresh(true)
      return setBtnText('Saved!')
    }, 1000)
    setTimeout(() => {
      setIsButtonLoading(false)
      return setShowModal(false)
    }, 2000)
    setRefresh(false)
  }

  return (
    <>
      <Modal
        isButtonLoading={isButtonLoading}
        show={showModal}
        title="Confirm Delete"
        body={(
          `Are you sure you want to delete ${currentCampaign?.name}${currentCampaign?.secondary_name ? `: ${currentCampaign.secondary_name}` : ''}${currentCampaign?.specific ? ` - ${currentCampaign.specific}` : ''}?`
        )}
        onSubmit={() => deleteCampaign(currentCampaign?.id)}
        onClose={() => setShowModal(false)}
        closeText={cancelText}
        submitText={btnText}
      />
      <Content
        title={(
          selectedFilters.status === 0 ? 'Active Campaigns' : 'Inactive Campaigns'
        )}
        padding={false}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={campaignSettingsStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
        badge={(
          <BadgeAlert
            alert={filtered?.length || 0}
            status={selectedFilters.status === 0}
          />
        )}
      >
        {isLoading ? (
          <span className="mx-4 md:mx-6 block"><LoadingSpinner variant="primary" size="lg" /></span>
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-3"
              mdGrid="md:grid-cols-3"
            >
              {campaignSettingsMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && campaignSettingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filtered?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filtered?.map((campaign) => (
                  <HorizontalGrid
                    key={campaign.id}
                    smGrid="grid-cols-3"
                    mdGrid="md:grid-cols-3"
                    backgroundColor
                  >
                    <p className="text-xxs text-gray-500 pr-3">
                      <LinkWrapper
                        variant="defaultSm"
                        key={campaign.id}
                        onClick={() => handleLocalStorage(campaign.id)}
                        href={{
                          pathname: '/admin-tools/campaign-settings/edit-campaign',
                        }}
                      >
                        {campaign.name}
                        {campaign?.secondary_name && (
                          `: ${campaign.secondary_name}`
                        )}
                        {campaign?.specific?.name && (
                          ` - ${campaign?.specific?.name}`
                        )}
                      </LinkWrapper>
                    </p>
                    <p className="text-xxs text-gray-500">
                      {campaign.type.short_name}
                    </p>
                    <p className="text-xxs text-gray-500">
                      {campaign.id}
                    </p>
                    <EditMenu
                      text="Edit Campaign"
                      id={campaign.id}
                      pathname="/admin-tools/campaign-settings/edit-campaign"
                      onClickEdit={() => handleLocalStorage(campaign.id)}
                      onClick={() => modal(campaign)}
                      deleteText="Delete Campaign"
                    />
                  </HorizontalGrid>
                ))
              )}
            </div>
            {/* <Pagination onClick={handleLoadMore} onClickBack={handleReturn} pageLimit={10} adminLimit={10} offset={currentOffset} total={totalData} data={filtered} /> */}
          </>
        )}
      </Content>
    </>
  )
}

export default AssignedCampaigns
