import {
  createContext, Dispatch, SetStateAction, useContext, useState,
} from 'react'
import { newUserInDb } from '../api/imi/factories'
import { ICampaignInDb } from '../api/imi/interfaces'
import { AppContext } from './AppContext'

interface CampaignContextData {
  campaignInDb: ICampaignInDb
  currentCampaignId: number
  setCurrentCampaignId: Dispatch<SetStateAction<number>>
  setCampaignInDb: Dispatch<SetStateAction<ICampaignInDb>>
  // userAdIds: IUserAdvertisers
  // setUserAdIds: Dispatch<SetStateAction<IUserAdvertisers>>
  // userCampIds: IUserCampaigns
  // setUserCampIds: Dispatch<SetStateAction<IUserCampaigns>>
}

export function useCampaignContextFactory() {
  // const appContext = useContext(AppContext)
  const [campaignInDb, setCampaignInDb] = useState<ICampaignInDb>()
  const [currentCampaignId, setCurrentCampaignId] = useState<number>(0)

  return {
    campaignInDb,
    setCampaignInDb,
    currentCampaignId,
    setCurrentCampaignId,
  } as CampaignContextData
}

export const CampaignContext = createContext<CampaignContextData>({} as any)
