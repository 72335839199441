import { useFormContext } from 'react-hook-form'
import Input from '../Input'

interface Props {
  cycle: any
  errors: any
  edit: boolean
}

const CycleBudgetForm:React.FC<Props> = ({
  cycle,
  errors,
  edit,
}) => {
  const methods = useFormContext()
  const internationalNumberFormat = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
  return (
    <>
      {edit ? (
        <Input
          label="cycle budget"
          hideLabel
          type="text"
          className="dollar-split"
          {...methods.register('uplift_budget', {
            pattern: {
              value: /^-?[0-9]\d*(\.\d+)?$/,
              message: 'Budget value is not the correct format. Use numbers and/or decimal value only. If entering in a decimal, please add in at least one digit following decimal.',
            },
          })}
          error={errors.uplift_budget && <span className="form-error mt-3">{errors.uplift_budget?.message || 'This field is required.'}</span>}
        />
      ) : (
        <p className="text-xxs text-gray-500">
          {cycle?.uplift_budget > 0 ? (
            `$${internationalNumberFormat.format(cycle?.uplift_budget)}`
          ) : (
            '—'
          )}
        </p>
      )}
      <input type="number" value={cycle?.cycle_id} readOnly {...methods.register('cycle_id')} hidden />
      <input type="number" value={cycle?.id} readOnly {...methods.register('id')} hidden />
    </>
  )
}

export default CycleBudgetForm
