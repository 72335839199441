import { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ICampaignOverview } from '../api/imi/interfaces'
import { IMIApi } from '../api/imi/api'
import CampaignOverview from '../components/campaignOverview/CampaignOverview'
import { AppContext } from '../contexts/AppContext'
import Padding from '../components/layouts/Padding'
import Content from '../components/layouts/Content'

const CampaignOverviewPage:React.FC = () => {
  const { user, setError, setCampaignData } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [advertiserCampaigns, setAdvertiserCampaigns] = useState<ICampaignOverview>({} as any)
  const [advertiserId, setAdvertiserId] = useState<number>(0)
  const noCampaignsMessage = useState<string>('There are currently no campaigns assigned to this user. Please reach out to your account manager.')

  const items = localStorage.getItem('advertiser_id')
  useEffect(() => {
    if (items) {
      const parseItems = JSON.parse(items)
      setAdvertiserId(parseItems)
      return setCampaignData(parseItems)
    }
    if (user.advertisers.length === 1) {
      setAdvertiserId(user.advertisers[0].id)
      return setCampaignData(user.advertisers[0].id)
    }
    // TODO: remove local storage?
  }, [items, user.advertisers, setCampaignData])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    if (advertiserId > 0) {
      const fetchCampaignOverview = async () => {
        const response = await IMIApi.GetCampaignOverview(advertiserId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setAdvertiserCampaigns(response.data)
        return setLoading(false)
      }
      fetchCampaignOverview()
    }
    return () => abortController.abort()
  }, [setError, advertiserId])

  return (
    <Padding>
      <>
        {!loading && !advertiserCampaigns?.advertiser ? (
          <p>{noCampaignsMessage}</p>
        ) : (
          <>
            <CampaignOverview
              advertiserCampaigns={advertiserCampaigns}
              isLoading={loading}
            />
            {(user.user_type_id !== 5 && user.white_label_id < 2) && (
              <Content
                title="ready to take your marketing to the next level?"
              >
                <p>We offer fully customized marketing strategies—designed with your business’s goals in mind—that can include a Website, SEM, SEO, Citation Services, Review Generation, Display Advertising, Video Advertising, Digital Audio, Email Marketing, and more. Reach out to your account manager to take advantage of any (or all) of our marketing services today.</p>
              </Content>
            )}
          </>
        )}
      </>
    </Padding>
  )
}

export default CampaignOverviewPage
