import { useContext } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ThemeContext } from '../../contexts/ThemeContext'

interface ChartProps {
  name: string
  color: string
  data: number[]
}

interface Props {
  id: string
  chartRef?: React.Ref<HighchartsReact.RefObject>
  lines: ChartProps[]
  bars: ChartProps[]
  labels: string[]
  tooltip: (point: any) => string
}

const LineAndBarGraph: React.FC<Props> = ({
  id,
  chartRef,
  bars,
  lines,
  labels,
  tooltip,
}) => {
  const { colors } = useContext(ThemeContext)
  const chart = {
    chart: {
      height: 300,
      marginLeft: 50,
    },
    title: {
      text: '',
    },
    legend: {
      backgroundColor: '#f7f7f7',
      borderRadius: '18px',
      padding: 15,
      align: 'left',
      verticalAlign: 'top',
      enabled: false,
    },
    xAxis: {
      lineColor: '#dfe5e2',
      crosshair: {
        width: 1,
        color: '#7a8485',
        dashStyle: 'dash',
      },
      allowDecimals: false,
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
      categories: labels,
    },
    yAxis: {
      lineColor: '#dfe5e2',
      title: { text: '' },
      labels: {
        formatter() {
          const { value } = this as any
          
          return `${100 * value}%`
        },
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
    },
    tooltip: {
      useHTML: true,
      style: {
        color: '#ffffff',
      },
      formatter() {
        const { point } = this as any
        
        return tooltip(point)
      },
    },
    series: [
      ...lines.map((l) => ({
        ...l,
        type: 'spline',
      })),
      ...bars.map((b) => ({
        ...b,
        type: 'column',
      })),
    ],
    plotOptions: {
      allowPointSelect: false,
      series: {
        maxPointWidth: 10,
        clip: false,
        lineWidth: 3,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 3,
          states: {
            select: {
              fillColor: colors.primary,
              lineWidth: 0,
            },
            hover: {
              fillColor: colors.primary,
              lineWidth: 0,
            },
          },
        },
        column: {
          allowOverlap: false,
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
    },
  }

  return (
    <div className="line-and-bar-chart">
      <HighchartsReact
        id={id}
        ref={chartRef}
        highcharts={Highcharts}
        options={chart}
      />
    </div>
  )
}

export default LineAndBarGraph
