import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { IMIApi } from '../../../api/imi/api'
import { AppContext } from '../../../contexts/AppContext'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import EditEmailTemplate from '../../../components/adminTools/accounts/email_template/EditEmailTemplate'
import { IEmailTemplateInDbDetail } from '../../../api/imi/interfaces'
import LinkWrapper from '../../../components/Links'
import Icon from '../../../components/Icon'
import Content from '../../../components/layouts/Content'

const EmailTemplatePage:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplateInDbDetail>({} as any)
  const [emailTemplateId, setEmailTemplateId] = useState<number>(0)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const items = localStorage.getItem('email_template_id')
    if (items) {
      sessionStorage.setItem('email_template_id', items)
      localStorage.removeItem('email_template_id')
    }
    const getSessionItems = sessionStorage.getItem('email_template_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (parseItems) {
        setEmailTemplateId(parseItems)
      }
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    if (emailTemplateId > 0) {
      const fetchDashboardBrand = async () => {
        const response = await IMIApi.GetEmailTemplateByID(emailTemplateId, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setEmailTemplate(response.data)
        return setLoading(false)
      }
      fetchDashboardBrand()
    }
    return () => abortController.abort()
  }, [setError, refresh, emailTemplateId])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <>
            <EditEmailTemplate
              isLoading={loading}
              template={emailTemplate}
              setRefresh={setRefresh}
              edit
            />
            <Content
              title="Dashboard branding"
            >
              <p>To make changes to the dashboard brand that is associated with this email template, go to “Dashboard Branding” and find the branding option with the same name. Then, edit as needed.</p>
              <LinkWrapper
                href="/admin-tools/dashboard-branding"
                variant="defaultAdmin"
                className="mb-6 inline-block"
              >
                Go to Dashboard Branding
                <Icon size="xxs">
                  <ChevronRightIcon />
                </Icon>
              </LinkWrapper>
            </Content>
          </>
        )}
      />
    </Padding>
  )
}

export default EmailTemplatePage
