import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Radio from '../Radio'
import Dropdown from '../Dropdown'
import TextArea from '../TextArea'
import { AppContext } from '../../../contexts/AppContext'

interface Props {
  callCategories: any
  errors: any
  showButton: boolean
  callId: any
}

const ProcessCallsForm:React.FC<Props> = ({
  callCategories,
  errors,
  showButton,
  callId,
}) => {
  const methods = useFormContext()
  const { user } = useContext(AppContext)
  return (
    <>
      {(callCategories.length > 0 && (callId.category?.id > 0)) ? (
        <Controller
          name="category.id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              placeholder={methods.getValues('category.id') > 0 ? undefined : 'Select...'}
              label="Category"
              variant="admin"
              value={methods.getValues('category.id')}
              list={callCategories}
              error={errors.category_id && <span className="form-error">This field is required</span>}
              required
            />
          )}
        />
      ) : (
        <Controller
          name="category_id"
          control={methods.control}
          rules={{ required: true }}
          render={({
            field,
          }) => (
            <Dropdown
              disabled={!showButton}
              hookFormField={field}
              placeholder={methods.getValues('category_id') > 0 ? undefined : 'Select...'}
              label="Category"
              variant="admin"
              value={methods.getValues('category_id')}
              list={callCategories}
              error={errors.category_id && <span className="form-error">This field is required</span>}
              required
            />
          )}
        />
      )}
      <TextArea
        label="Notes"
        disabled={!showButton}
        tipId={`notes-${callId.id}`}
        required={false}
        toolTipInfo="Please be sure to include the name of the employee/sales person that answers the phone. If the employee does not provide their name, please indicate that in the note. Please check spelling of note."
        {...methods.register('notes', { required: false })}
      />
      <Radio
        name="is_flagged"
        label='Should this call be "flagged" as a missed opportunity?'
        disabled={!showButton}
        options={[
          {
            id: 0,
            label: 'Yes',
            value: true,
          },
          {
            id: 1,
            label: 'No',
            value: false,
          },
        ]}
      />
      {user.user_type_id !== 4 && (
        <Radio
          name="is_highlighted"
          label='Should this call be "highlighted" and featured on the IMI DIVE Report?'
          disabled={!showButton}
          options={[
            {
              id: 0,
              label: 'Yes',
              value: true,
            },
            {
              id: 1,
              label: 'No',
              value: false,
            },
          ]}
        />
      )}
      <input type="number" value={callId.id} readOnly {...methods.register('id')} hidden />
    </>
  )
}

export default ProcessCallsForm
