import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignUplift } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import Uplift from '../../../components/adminTools/campaigns/uplift/Uplift'

const CampaignUpliftPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [campaignUplift, setCampaignUplift] = useState<ICampaignUplift[]>([] as any)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  })
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchCampaignUplift = async () => {
      // const response = await IMIApi.GetAllCampaigns(pagination.offset, pagination.limit)
      const response = await IMIApi.GetCampaignUplift(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCampaignUplift(response.data.data)
      return setLoading(false)
    }
    fetchCampaignUplift()
    return () => abortController.abort()
  }, [refresh, setError, pagination])

  useEffect(() => {
    if (campaignUplift.length > 0) {
      setLoading(false)
    }
  }, [campaignUplift])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <Uplift
            campaignUplift={campaignUplift}
            loading={loading}
          />
        )}
      />
    </Padding>
  )
}
export default CampaignUpliftPage
