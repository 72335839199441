import { PDF, FontStyle, Font } from '../lib'
import { KeyMetrics } from '../data'
import * as sizes from '../sizes'

const getText = (pdf: PDF, data: KeyMetrics) => ({
  title: pdf.text.drawText('key metrics', pdf.colors.black, { style: FontStyle.Bold }),
  header: [
    pdf.text.drawText('Metric', pdf.colors.black),
    pdf.text.drawText('Value', pdf.colors.black),
  ],
  ctr: [
    pdf.text.drawText('Click-Through Rate (CTR)', pdf.colors.darkestGrey, { font: Font.Heebo }),
    pdf.text.drawText(data.ctr, pdf.colors.darkestGrey, { font: Font.Heebo }),
  ],
  ctc: [
    pdf.text.drawText('Click-To-Call Rate (CTC)', pdf.colors.darkestGrey, { font: Font.Heebo }),
    pdf.text.drawText(data.ctc, pdf.colors.darkestGrey, { font: Font.Heebo }),
  ],
  cte: [
    pdf.text.drawText('Click-To-Web-Event Rate (CTE)', pdf.colors.darkestGrey, { font: Font.Heebo }),
    pdf.text.drawText(data.cte, pdf.colors.darkestGrey, { font: Font.Heebo }),
  ],
  spent: [
    pdf.text.drawText('Spent', pdf.colors.darkestGrey, { font: Font.Heebo }),
    pdf.text.drawText(data.spent, pdf.colors.darkestGrey, { font: Font.Heebo }),
  ],
  budget: [
    pdf.text.drawText('Budget', pdf.colors.darkestGrey, { font: Font.Heebo }),
    pdf.text.drawText(data.budget, pdf.colors.darkestGrey, { font: Font.Heebo }),
  ],
})

const drawRow = (pdf: PDF, isWhite: boolean, text: { width: number, height: number, render: () => void }[]) => {
  const colWidth = (pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 3) / 2

  pdf.drawing.drawContainerRow(pdf.pages.pageWidth, text[0].height + sizes.CONTAINER_MARGIN * 2, isWhite ? pdf.colors.white : pdf.colors.grey)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  text[0].render()
  pdf.pages.moveRight(colWidth + sizes.CONTAINER_MARGIN)
  text[1].render()
  pdf.pages.moveDown(text[0].height + sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
}

export const drawKeyMetrics = (pdf: PDF, data: KeyMetrics) => {
  const text = getText(pdf, data)
  const colWidth = (pdf.pages.pageWidth - sizes.CONTAINER_MARGIN * 3) / 2

  pdf.drawing.drawContainerHeader(pdf.pages.pageWidth, text.title.height + sizes.CONTAINER_MARGIN * 2)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  text.title.render()
  pdf.pages.moveDown(text.title.height + sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()

  drawRow(pdf, true, text.header)
  drawRow(pdf, false, text.ctr)
  drawRow(pdf, true, text.ctc)
  drawRow(pdf, false, text.cte)
  drawRow(pdf, true, text.spent)

  pdf.drawing.drawContainerFooter(pdf.pages.pageWidth, text.budget[0].height + sizes.CONTAINER_MARGIN * 2, pdf.colors.grey)
  pdf.pages.moveDown(sizes.CONTAINER_MARGIN)
  pdf.pages.moveRight(sizes.CONTAINER_MARGIN)
  text.budget[0].render()
  pdf.pages.moveRight(colWidth + sizes.CONTAINER_MARGIN)
  text.budget[1].render()
  pdf.pages.moveDown(text.budget[0].height + sizes.CONTAINER_MARGIN)
  pdf.pages.resetX()
  pdf.pages.moveDown(sizes.SECTION_MARGIN)
}
