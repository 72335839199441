import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../../contexts/AppContext'
import { CampaignContext } from '../../../contexts/CampaignContext'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import CallProcess from '../../../components/adminTools/campaigns/campaign_settings/CallProcess'
import AdvertiserAssignment from '../../../components/adminTools/campaigns/campaign_settings/AdvertiserAssignment'
import EditCampaign from '../../../components/adminTools/campaigns/campaign_settings/EditCampaign'
import CampaignDetails from '../../../components/adminTools/campaigns/campaign_settings/CampaignDetails'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import {
  ICampaignInDb,
  IAdvertiserInDb,
  IPCampaign,
  ICampaignTypeInDb,
} from '../../../api/imi/interfaces'
import { newCampaignInDb } from '../../../api/imi/factories'
import CreateEditRoi from '../../../components/adminTools/campaigns/roi/CreateEditRoi'
import CampaignAssignment from '../../../components/adminTools/campaigns/campaign_settings/CampaignAssignment'

const AssignCampaignPage:React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [campaign, setCampaign] = useState<IPCampaign>(null as any)
  const [allAdvertisers, setAllAdvertisers] = useState<IAdvertiserInDb[]>([])
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [createdCampaigns, setCreatedCampaigns] = useState<ICampaignInDb[]>([] as any)
  const [allCampaignTypes, setAllCampaignTypes] = useState<ICampaignTypeInDb[]>([])
  const { setError } = useContext(AppContext)

  useEffect(() => {
    const newCampaign = setCampaignInDb(newCampaignInDb())
    return newCampaign
  }, [setCampaignInDb])

  useEffect(() => {
    const abortController = new AbortController()
    const items = localStorage.getItem('p_campaign_id')
    if (items) {
      const parseItems = JSON.parse(items)
      const fetchCampaign = async () => {
        const response = await IMIApi.GetPCampaignById(parseItems, abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setCampaign(response.data)
        return setLoading(false)
      }
      fetchCampaign()
    }
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllAdvertisers = async () => {
      const response = await IMIApi.GetAllAdvertisers(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAllAdvertisers(response.data.data)
      return setLoading(false)
    }
    fetchAllAdvertisers()
    return () => abortController.abort()
  }, [setError])

  // Get campaigns that aren't SEM because they
  // should be the only ones with an enC created campaign
  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllCreatedCampaigns = async () => {
      const response = await IMIApi.GetAllCampaigns(false, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      // TODO: loop through maybe, but SEM is type 2 now
      // and only one without a "created" campaign by enC.
      // const created = response.data.data.filter((created_c) => (
      //   created_c?.type?.id !== 2
      // ))
      setCreatedCampaigns(response?.data?.data)
      return setLoading(false)
    }
    fetchAllCreatedCampaigns()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchAllCampaignTypes = async () => {
      const response = await IMIApi.GetCampaignTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setAllCampaignTypes(response.data.data)
      return setLoading(false)
    }
    fetchAllCampaignTypes()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    if (campaign?.campaign_id) {
      history.push('/admin-tools/campaign-settings')
    }
  }, [campaign?.campaign_id, history])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          campaignInDb?.id > 0 ? (
            <>
              <EditCampaign
                allAdvertisers={allAdvertisers}
                allCampaignTypes={[]}
                isLoading={loading}
                edit={false}
              />
              {campaignInDb?.type.id === 2 ? (
                <>
                  <AdvertiserAssignment
                    allAdvertisers={allAdvertisers}
                    createdCampaigns={createdCampaigns}
                    isLoading={loading}
                  />
                  <CallProcess />
                  <CreateEditRoi
                    isLoading={loading}
                    editPage={false}
                  />
                </>
              ) : (
                <>
                  <CampaignAssignment
                    isLoading={loading}
                    createdCampaigns={createdCampaigns}
                    pCampaign={campaign}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <CampaignDetails
                allAdvertisers={allAdvertisers}
                allCampaignTypes={allCampaignTypes}
                isLoading={loading}
                pCampaign={campaign}
                setCampaign={setCampaign}
                edit
              />
              <CampaignAssignment
                allAdvertisers={allAdvertisers}
                isLoading={loading}
                createdCampaigns={createdCampaigns}
                pCampaign={campaign}
              />
              {campaign?.type_id === 2 && campaignInDb?.campaign_assignment_to_parent === false && campaignInDb?.id > 0 && (
                <>
                  <CallProcess />
                  <CreateEditRoi
                    isLoading={loading}
                    editPage={false}
                  />
                </>
              )}
            </>
          )
        )}
      />
    </Padding>
  )
}

export default AssignCampaignPage
