import {
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { handleFilters, getFieldByPath, commaNumber } from '../../../../helpers/utils'
import Search from '../../../form/Search'
import Content from '../../../layouts/Content'
import HorizontalGrid from '../../../layouts/HorizontalGrid'
import AdminToolsMobileLink from '../../AdminToolsMobileLink'
import Icon from '../../../Icon'
import { callProcessStatus, callProcessingMenu, settingsStyles } from '../Data'
import BadgeAlert from '../../../badge/BadgeAlert'
import Dropdown from '../../../form/Dropdown'
import LinkWrapper from '../../../Links'
import EditMenu from '../../EditMenu'
import { ICallProccessingSummary, ICallProcessingSummaryDetail } from '../../../../api/imi/interfaces'
import LoadingSpinner from '../../../LoadingSpinner'

interface Props {
  calls: ICallProccessingSummary[]
  isLoading: boolean
}

const CallProcessing:React.FC<Props> = ({
  calls,
  isLoading,
}) => {
  const [errorMsg, setErrorMsg] = useState('There are no processed calls found.')
  const [filteredCalls, setFilteredCalls] = useState<ICallProcessingSummaryDetail[]>()
  const [unprocessedCalls, setUnprocessedCalls] = useState<ICallProcessingSummaryDetail[]>(calls[0]?.detail)
  const [processedCalls, setProcessedCalls] = useState<ICallProcessingSummaryDetail[]>(calls[1]?.detail)
  const [callTotal, setCallTotal] = useState<any>(0)
  const [selectedFilters, setSelectedFilters] = useState({
    search: '',
    status: 0,
  })
  // true is descending (highest to lowest), false is ascending (lowest to highest)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [sortColumn, setSortColumn] = useState<string>('')

  const columnSortingInfo = (fieldName) => {
    setSortDirection(!sortDirection)
    setSortColumn(fieldName)
    // FIXME: temporary sorting, would like to update
    const sortSecondaryName = filteredCalls?.sort((a, b) => (a.advertiser.name.localeCompare(b.advertiser.name) || b.secondary_name.localeCompare(a.secondary_name)))
    const values = sortDirection ? sortSecondaryName?.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1)) : filteredCalls?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1))
    return { values }
  }

  const filters = useCallback(() => {
    const filterSearchValue = (callData) => {
      const splitItems = selectedFilters.search.split(';').filter((si) => !!si).map((item) => item.trim())
      const nameFilter = callData.name.toLowerCase().includes(splitItems)
      if (splitItems.length > 1) {
        const nameFil = splitItems.some((si) => callData.name.toLowerCase().includes(si))

        return nameFil
      }
      return nameFilter
    }
    if (selectedFilters.status === 0) {
      const filterData = unprocessedCalls?.filter((call) => filterSearchValue(call))
      return setFilteredCalls(filterData)
    }
    const filterData = processedCalls?.filter((call) => filterSearchValue(call))
    setFilteredCalls(filterData)
  }, [selectedFilters, processedCalls, unprocessedCalls])

  useEffect(() => {
    if (selectedFilters.status === 0) {
      setUnprocessedCalls(calls[0]?.detail.sort((a, b) => (a.advertiser.name.localeCompare(b.advertiser.name) || a.secondary_name.localeCompare(b.secondary_name))))
    }
    if (selectedFilters.status === 1) {
      setProcessedCalls(calls[1]?.detail.sort((a, b) => (a.advertiser.name.localeCompare(b.advertiser.name) || a.secondary_name.localeCompare(b.secondary_name))))
    }
  }, [calls, selectedFilters.status])

  useEffect(() => {
    filters()
  }, [processedCalls, unprocessedCalls, filters])

  useEffect(() => {
    if (filteredCalls) {
      const calls2 = filteredCalls?.map((calls3) => calls3?.calls_total)
      setCallTotal(calls2?.reduce((a, b) => (a + b), 0))
    }
  }, [filteredCalls])

  const handleLocalStorage = (id) => {
    localStorage.setItem('call_process_id', JSON.stringify(id))
    sessionStorage.setItem('call_process_id', JSON.stringify(id))
  }

  return (
    <>
      <AdminToolsMobileLink />
      <Search
        label="search"
        hideLabel
        placeholder="search..."
        className="w-full max-w-none"
        value={selectedFilters.search}
        onChange={(
          (e) => setSelectedFilters(handleFilters(e.target.value.toLowerCase(), 'search', selectedFilters))
        )}
      />
      <Content
        title={selectedFilters.status === 1 ? 'processed calls' : 'unprocessed calls'}
        padding={false}
        badge={(
          <BadgeAlert
            alert={commaNumber(callTotal)}
            status={selectedFilters?.status === 1}
          />
        )}
        form={(
          <Dropdown
            variant="default"
            label="Status"
            list={callProcessStatus}
            className="special"
            position="top-1.5 absolute right-0 top-0 bottom-0"
            handleFilters={(
              (e) => setSelectedFilters(handleFilters(e, 'status', selectedFilters))
            )}
          />
        )}
      >
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <HorizontalGrid
              smGrid="grid-cols-2"
              mdGrid="md:grid-cols-2"
            >
              {callProcessingMenu.map((item) => (
                item.sort ? (
                  <span
                    aria-hidden="true"
                    onClick={() => columnSortingInfo(item.fieldName)}
                    key={item.name}
                    className={(
                      `flex break-word group hover:text-secondary hover:cursor-pointer
                      ${item.mobile && settingsStyles.desktop}`
                    )}
                  >
                    {item.name}
                    <Icon
                      size="xxs"
                      className="text-default group pr-8 sm:pr-0 ml-1 mt-0.5"
                    >
                      <ChevronUpIcon
                        className="-mb-1 text-default group-hover:text-secondary"
                      />
                      <ChevronDownIcon
                        className="text-default group-hover:text-secondary"
                      />
                    </Icon>
                  </span>
                ) : <span key={item.name}>{item.name}</span>
              ))}
            </HorizontalGrid>
            <div className="items-center">
              {filteredCalls?.length === 0 ? <p className="error px-6">{errorMsg}</p> : (
                filteredCalls?.map((campaign) => (
                  <HorizontalGrid
                    key={campaign.id}
                    smGrid="grid-cols-2"
                    mdGrid="md:grid-cols-2"
                    backgroundColor
                  >
                    <p className="text-xxs pr-3">
                      {campaign.name}
                      {campaign?.secondary_name && (
                        `: ${campaign.secondary_name}`
                      )}
                      {campaign?.specific && (
                        `- ${campaign.specific.name}`
                      )}
                    </p>
                    {selectedFilters.status === 0 ? (
                      <>
                        <p className="text-xxs pr-6">
                          <LinkWrapper
                            variant="defaultSm"
                            key={campaign.id}
                            onClick={() => handleLocalStorage(campaign.id)}
                            href={{
                              pathname: '/admin-tools/call-processing/process-calls',
                            }}
                          >
                            {campaign.calls_total}
                          </LinkWrapper>
                        </p>
                        <EditMenu
                          text="View Calls"
                          id={campaign.id}
                          onClick={() => handleLocalStorage(campaign.id)}
                          pathname="/admin-tools/call-processing/process-calls"
                        />
                      </>
                    ) : (
                      <>
                        <p className="text-xxs text-gray-500">
                          <LinkWrapper
                            variant="defaultSm"
                            key={campaign.id}
                            onClick={() => handleLocalStorage(campaign.id)}
                            href={{
                              pathname: '/admin-tools/call-processing/view-processed-calls',
                            }}
                          >
                            {commaNumber(campaign.calls_total)}
                          </LinkWrapper>
                        </p>
                        <EditMenu
                          text="View Calls"
                          id={campaign.id}
                          onClick={() => handleLocalStorage(campaign.id)}
                          pathname="/admin-tools/call-processing/view-processed-calls"
                        />
                      </>
                    )}
                  </HorizontalGrid>
                ))
              )}
            </div>
          </>
        )}
      </Content>
    </>
  )
}

export default CallProcessing
