import { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../contexts/AppContext'
import Masthead from './Masthead'
import ErrorNotification from '../notification/ErrorNotification'

const MainMasthead: React.FC = () => {
  const appContext = useContext(AppContext)
  const {
    user,
    error,
    setError,
    currentPage,
    setCurrentPage,
  } = appContext
  const location = useLocation()
  const getLastUrl = (thePath) => thePath.substring(thePath.lastIndexOf('/') + 1)

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrentPage({ pageTitle: 'Advertiser Dashboard', pageUrl: '/' })
    } else {
      const urlTitle = getLastUrl(location.pathname).replace(/-/g, ' ')
      if (!urlTitle) {
        return setCurrentPage({ pageTitle: (location.pathname).replace(/-/g, ' '), pageUrl: location.pathname })
      }
      return setCurrentPage({ pageTitle: urlTitle, pageUrl: location.pathname })
    }
  }, [location.pathname, setCurrentPage])

  // scroll to error
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('error')
      return element?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }, [error?.errorMsg])

  return (
    <>
      {user.username && (
        <>
          <Masthead />
          {error && (
            <ErrorNotification />
          )}
        </>
      )}
    </>
  )
}

export default MainMasthead
