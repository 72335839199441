import { useContext, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ThemeContext } from '../../contexts/ThemeContext'

interface GraphProps {
  id: string
  name: string
  values: number[]
  tooltip: () => string
}

interface Props {
  graph1: GraphProps
  graph2: GraphProps
  labels: string[]
}

const DoubleLineGraph: React.FC<Props> = ({
  graph1,
  graph2,
  labels,
}) => {
  const { colors } = useContext(ThemeContext)
  const noDataMessage = '“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!'
  const graph1Ref = useRef<any>(null)
  const graph2Ref = useRef<any>(null)

  const graph1Config = {
    chart: {
      height: 300,
      marginLeft: 50,
    },
    title: {
      text: '',
    },
    legend: {
      backgroundColor: '#f7f7f7',
      borderRadius: '18px',
      padding: 15,
      align: 'left',
      verticalAlign: 'top',
      enabled: false,
    },
    xAxis: {
      lineColor: '#dfe5e2',
      crosshair: {
        width: 1,
        color: '#7a8485',
        dashStyle: 'dash',
      },
      allowDecimals: false,
      labels: {
        style: {
          display: 'none',
        },
      },
      categories: labels,
    },
    yAxis: {
      lineColor: '#dfe5e2',
      title: { text: '' },
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
    },
    tooltip: {
      useHTML: true,
      style: {
        color: '#ffffff',
      },
      formatter() {
        return graph1.tooltip.call(this)
      },
    },
    series: [{
      type: 'spline',
      name: graph1.name,
      color: colors.primary,
      data: graph1.values,
      point: {
        events: {
          mouseOver: (event) => {
            const series = graph2Ref.current?.chart.series[0]

            if (series) {
              const point = series.points[event.target.index]
              
              series.setState('hover')

              if (point) {
                point.setState('hover')
                point.series.chart.tooltip.refresh(point)
                point.series.chart.axes[0].drawCrosshair(null, point)
              }
            }
          },
          mouseOut: (event) => {
            const series = graph2Ref.current?.chart.series[0]

            if (series) {
              const point = series.points[event.target.index]
              
              series.setState('')

              if (point) {
                point.setState('')
                point.series.chart.tooltip.hide()
                point.series.chart.axes[0].hideCrosshair()
              }
            }
          },
        },
      },
    }],
    plotOptions: {
      allowPointSelect: false,
      series: {
        clip: false,
        lineWidth: 3,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 3,
          states: {
            select: {
              fillColor: colors.primary,
              lineWidth: 0,
            },
            hover: {
              fillColor: colors.primary,
              lineWidth: 0,
            },
          },
        },
      },
    },
  }

  const graph2Config = {
    chart: {
      height: 300,
      marginLeft: 50,
    },
    title: {
      text: '',
    },
    legend: {
      backgroundColor: '#f7f7f7',
      borderRadius: '18px',
      padding: 15,
      align: 'left',
      verticalAlign: 'top',
      enabled: false,
    },
    xAxis: {
      lineColor: '#dfe5e2',
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
      crosshair: {
        width: 1,
        color: '#7a8485',
        dashStyle: 'dash',
      },
      categories: labels,
    },
    yAxis: {
      lineColor: '#dfe5e2',
      title: {
        text: '',
      },
      labels: {
        style: {
          fontFamily: 'Heebo',
          color: 'rgba(40, 42, 57, 0.6)',
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter() {
        return graph2.tooltip.call(this)
      },
    },
    series: [
      {
        type: 'spline',
        name: graph2.name,
        color: colors.secondary,
        data: graph2.values,
        point: {
          events: {
            mouseOver: (event) => {
              const series = graph1Ref.current?.chart.series[0]

              if (series) {
                const point = series.points[event.target.index]
                
                series.setState('hover')

                if (point) {
                  point.setState('hover')
                  point.series.chart.tooltip.refresh(point)
                  point.series.chart.axes[0].drawCrosshair(null, point)
                }
              }
            },
            mouseOut: (event) => {
              const series = graph1Ref.current?.chart.series[0]

              if (series) {
                const point = series.points[event.target.index]
                
                series.setState('')

                if (point) {
                  point.setState('')
                  point.series.chart.tooltip.hide()
                  point.series.chart.axes[0].hideCrosshair()
                }
              }
            },
          },
        },
      },
    ],
    plotOptions: {
      allowPointSelect: false,
      series: {
        clip: false,
        lineWidth: 3,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 3,
          states: {
            select: {
              fillColor: colors.secondary,
              lineWidth: 0,
            },
            hover: {
              fillColor: colors.secondary,
              lineWidth: 0,
            },
          },
        },
      },
    },
  }

  return (
    <>
      <div className="pb-6">
        {graph1.values ? (
          <HighchartsReact
            ref={graph1Ref}
            id={graph1.id}
            highcharts={Highcharts}
            options={graph1Config}
          />
        ) : (
          <p>{noDataMessage}</p>
        )}
      </div>

      <div>
        {graph2.values ? (
          <HighchartsReact
            ref={graph2Ref}
            id={graph2.id}
            highcharts={Highcharts}
            options={graph2Config}
          />
        ) : (
          <p>{noDataMessage}</p>
        )}
      </div>
    </>
  )
}

export default DoubleLineGraph
