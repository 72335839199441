// Web event metrics that dispalys overview of web events
// like the name, graph, and total events and leads.

import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../api/imi/api'
import Content from '../layouts/Content'
import ToolTip from '../ToolTip'
import SidebarRight from '../layouts/SidebarRight'
import MetricsGrid from '../layouts/MetricsGrid'
import { commaNumber, fixDateObjApi, getFileDescriptor } from '../../helpers/utils'
import HorizontalGrid from '../layouts/HorizontalGrid'
import { ReactComponent as TotalWebeventsIcon } from '../../assets/icons/total-web-events-icon.svg'
import { ReactComponent as TotalWebeventLeadsIcon } from '../../assets/icons/total-web-event-leads-icon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg'
import { ISemDetail } from '../../api/imi/interfaces'
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'
import { ReportContext } from '../../contexts/ReportContext'
import { AppContext } from '../../contexts/AppContext'

// TODO: switch this out with the web events url endpoint when done

const styles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute right-6',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

const title = [
  {
    fieldName: 'web_event_name',
    name: 'Name',
    mobile: false,
    info: null,
  },
  {
    fieldName: null,
    name: '# of Events',
    mobile: false,
    info: null,
  },
]

interface Props {
  children?: React.ReactNode
  data: ISemDetail
}

const SemWebEventsReport:React.FC<Props> = ({
  data,
}) => {
  const [webEventData, setWebEventData] = useState<any>([])
  const { setError } = useContext(AppContext)
  const { parameters, reportParameters } = useContext(ReportContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [noDataMessage, setNoDataMessage] = useState<string>('“Data is gold.” Our miners are diligently digging. Check back later to see something shiny!')

  const sortSubmitted = (array) => (
    array?.sort((a, b) => {
      const nameA = a?.name.toLowerCase()
      const nameB = b?.name.toLowerCase()
      // (a.name.toLowerCase().includes('submitted') || b.name.toLowerCase().includes('submitted') ? 1 : -1))
      if (nameA > nameB) {
        if (!nameA.includes('submitted') < !nameB.includes('submitted')) {
          return -1
        }
        if (nameA.includes('submitted') > nameB.includes('submitted')) {
          return 1
        }
      }
      if (nameA < nameB) {
        if (!nameA.includes('submitted') < !nameB.includes('submitted')) {
          return -1
        }
        if (nameA.includes('submitted') > nameB.includes('submitted')) {
          return 1
        }
      }
      return 0
    }))

  const sortFunction = (webEvents) => (
    sortSubmitted(webEvents?.web_events?.data.sort((a, b) => {
      const nonLeadsA = a.non_leads
      const nonLeadsB = b.non_leads
      const leadsA = a.leads
      const leadsB = b.leads
      if (nonLeadsA > nonLeadsB) {
        return -1
      }
      if (nonLeadsA < nonLeadsB) {
        return 1
      }
      if (leadsA < leadsB) {
        return -1
      }
      if (leadsA > leadsB) {
        return 1
      }
      return 0
    }))
  )
  useEffect(() => {
    if (data) {
      setWebEventData(data)
      return setLoading(false)
    }
  }, [data])
  useEffect(() => {
    if (reportParameters.query_end_date) {
      const today = new Date(new Date().setUTCHours(0, 0, 0, 0))
      if (today > reportParameters.query_end_date) {
        return setNoDataMessage('')
      }
    }
  }, [reportParameters])

  const handleDownload = () => {
    const fetchWebEventsDownload = async () => {
      const response = await IMIApi.GetWebEventsDownload({
        id: reportParameters.id,
        start_date: fixDateObjApi(reportParameters.query_start_date),
        end_date: fixDateObjApi(reportParameters.query_end_date),
      })
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        return setError({ errorMsg: parse?.detail })
      }
      if (!response.status) {
        return setError({ errorMsg: 'Internal server error' })
      }
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      const fileDesc = getFileDescriptor(response.headers)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(
        new Blob([response.data], { type: fileDesc.contentType }),
      )

      // Use download attribute to set set desired file name
      a.setAttribute('download', fileDesc.filename)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    }
    fetchWebEventsDownload()
  }

  return (
    <SidebarRight
      mainContent={(
        <Content
          title="web events"
          minHeight="lg:min-h-metric-sidebar-2"
          padding={false}
          icon={(
            (webEventData?.web_events?.data && webEventData?.web_events.data?.length > 0) && (
              <Icon
                id="webEventDownload"
                onClick={() => handleDownload()}
              >
                <Icon size="md" colors="text-primary" className="cursor-pointer group-hover:text-secondary mt-1.5"><DownloadIcon className="text-primary hover:text-secondary" /></Icon>
              </Icon>
            )
          )}
        >
          {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
            <>
              <HorizontalGrid
                smGrid="grid-cols-2"
                mdGrid="md:grid-cols-webEvents"
              >
                {title.map((item) => (
                  <li key={item.name} className={`relative break-all group flex center-items ${item.mobile ? styles.desktop : ''}`}>
                    {item.name}
                    {item.info ? (
                      <ToolTip tipId={item.fieldName ? item.fieldName : ''} size="sm" position="relative" className="pl-1">{item.info}</ToolTip>
                    ) : null}
                  </li>
                ))}
              </HorizontalGrid>
              <div className="odd:bg-grey-lightest">
                {!webEventData?.web_events || webEventData?.web_events?.total === 0 ? <p className="px-4 md:px-6">{noDataMessage}</p> : (
                  sortFunction(webEventData).map((event) => (
                    <HorizontalGrid
                      key={event.name}
                      smGrid="grid-cols-2"
                      mdGrid="md:grid-cols-webEvents"
                      backgroundColor
                    >
                      <p className="small">
                        {event.name}
                      </p>
                      <p className="small">
                        { // todo: refactor this field to `total` and remove `non_leads` and `leads` key since its confusing (and redundant)
                          commaNumber(event.non_leads)
                        }
                      </p>
                      <div className="hidden md:block">
                        <div className="bg-grey-lighter w-full h-2 rounded-sm overflow-hidden">
                          <div style={{ width: `${(((event.non_leads) / (webEventData?.web_events?.total)) * 100)}%` }} className={`${event?.name?.toLowerCase().includes('submitted') ? 'bg-secondary' : 'bg-tertiary'} h-2 rounded-sm w-1/8`} />
                        </div>
                      </div>
                    </HorizontalGrid>
                  ))
                )}
              </div>
            </>
          )}
        </Content>
      )}
      sidebarContent={(
        <div className="grid grid-cols-auto grid-rows-auto gap-5 mb-6">
          {loading ? <div className="pb-6"><LoadingSpinner size="lg" variant="primary" /></div> : (
            <>
              <MetricsGrid
                name="Total Web Events"
                imageUrl={<TotalWebeventsIcon />}
                value={webEventData?.web_events?.total > 0 ? commaNumber(webEventData?.web_events?.total) : 0}
              />
              <MetricsGrid
                name="Total Web Event Leads"
                imageUrl={<TotalWebeventLeadsIcon />}
                value={webEventData?.web_events?.leads > 0 ? commaNumber(webEventData?.web_events?.leads) : 0}
                tooltipInfo={('Total number of "submitted" forms.')}
              />
            </>
          )}
        </div>
      )}
    />
  )
}

export default SemWebEventsReport
