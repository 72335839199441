import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import ToolTip from '../../../ToolTip'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import { ICampaignInDb, ICampaignUpdate } from '../../../../api/imi/interfaces'
import CreateCallProcess from './CreateCallProcess'
import EditCallProcess from './EditCallProcess'

const CallProcess:React.FC = () => {
  const { setError } = useContext(AppContext)
  const [callIndustries, setCallIndustries] = useState<any>([] as any)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [callCategories, setCallCategories] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCallIndustries = async () => {
      const response = await IMIApi.GetCallIndustries(abortController.signal)
      if (!response) {
        return
      }
      if (response.status && response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCallIndustries(response.data.data)
      return setLoading(false)
    }
    fetchAllCallIndustries()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCallCategories = async () => {
      // const response = await IMIApi.GetCallIndustryById(campaignInDb?.category?.id)
      const response = await IMIApi.GetCallIndustries(abortController.signal)
      if (!response) {
        return
      }
      if (response.status && response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCallCategories(response.data.data)
      return setLoading(false)
    }
    fetchAllCallCategories()
    return () => abortController.abort()
  }, [setError])

  return (
    <>
      {campaignInDb?.call_processing_is_active || campaignInDb?.category?.id > 0 ? (
        <EditCallProcess
          callCategories={callCategories}
          callIndustries={callIndustries}
          isLoading={loading}
        />
      ) : (
        <CreateCallProcess
          callIndustries={callIndustries}
          isLoading={loading}
        />
      )}
    </>
  )
}

export default CallProcess
