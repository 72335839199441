import {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react'
import { IMIApi } from '../api/imi/api'
import { AppContext } from '../contexts/AppContext'
import CampaignCentral from '../components/campaignCentral/CampaignCentral'
import { ICampaignCentralMultiResponse, ICampaignTypeMultiResponse } from '../api/imi/interfaces'

const CampaignCentralPage:React.FC = () => {
  const { user, setError } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [campaigns, setCampaigns] = useState<ICampaignCentralMultiResponse>([] as any)
  const [campaignTypes, setCampaignTypes] = useState<ICampaignTypeMultiResponse>([] as any)
  const [platforms, setPlatforms] = useState<any>([] as any)

  const daysIn = useMemo(() => (start_date) => {
    const todaysDate = new Date()
    const startD = new Date(start_date)
    const difference = todaysDate.getTime() - startD.getTime()
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
    return TotalDays
  }, [])

  const targetDailySpend = (budget) => {
    const tgtDailySpend = budget / 30
    return Math.round(tgtDailySpend)
  }

  const actualDailySpend = useMemo(() => (spend, start_date) => {
    const actSpend = spend / daysIn(start_date)
    return Math.round(actSpend)
  }, [daysIn])

  const daysLeft = useMemo(() => (budget, spend, start_date) => {
    const spendLeft = budget - spend
    const days_left = (spendLeft) / actualDailySpend(spend, start_date)
    const roundedDaysLeft = days_left
    return Math.round(roundedDaysLeft)
  }, [actualDailySpend])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchCampaigns = async () => {
      const response = await IMIApi.GetCampaignCentral(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      // all calculations here
      const addMetricsIn = response?.data.data.map((data) => (
        {
          ...data,
          daily_spend_target: targetDailySpend(data.cycle[0]?.budget),
          daily_spend_actual: actualDailySpend(data.cycle[0]?.spend, data.cycle[0]?.start_date),
          days_in: daysIn(data.cycle[0]?.start_date),
          days_left: daysLeft(data.cycle[0]?.budget, data.cycle[0]?.spend, data.cycle[0]?.start_date),
          days_total: Math.round(data.cycle[0]?.budget / actualDailySpend(data.cycle[0]?.spend, data.cycle[0]?.start_date)),
        }
      ))
      setCampaigns({ ...response.data, data: addMetricsIn })
      return setLoading(false)
    }
    fetchCampaigns()
    return () => abortController.abort()
  }, [setError, actualDailySpend, daysIn, daysLeft])

  useEffect(() => {
    const abortController = new AbortController()
    setLoading(true)
    const fetchCampaignTypes = async () => {
      const response = await IMIApi.GetCampaignTypes(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCampaignTypes(response.data)
      return setLoading(false)
    }
    fetchCampaignTypes()
    return () => abortController.abort()
  }, [setError])

  useEffect(() => {
    const abortController = new AbortController()
    if (user.user_type_id < 5) {
      setLoading(true)
      const fetchPlatforms = async () => {
        const response = await IMIApi.GetPlatforms(abortController.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response.status) {
          setError({ errorMsg: 'Internal server error.' })
          return setLoading(false)
        }
        setPlatforms(response.data)
        return setLoading(false)
      }
      fetchPlatforms()
    }
    setLoading(false)
    return () => abortController.abort()
  }, [user.user_type_id, setError])

  return (
    <CampaignCentral
      platforms={platforms}
      campaignTypes={campaignTypes?.data}
      campaigns={campaigns}
      isLoading={loading}
    />
  )
}

export default CampaignCentralPage
