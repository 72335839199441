import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../api/imi/api'
import AdminToolsMenu from '../../components/adminTools/AdminToolsMenu'
import Padding from '../../components/layouts/Padding'
import SidebarLeft from '../../components/layouts/SidebarLeft'
import { IPlatformCred, IPlatformCredListItem } from '../../api/imi/interfaces'
import { AppContext } from '../../contexts/AppContext'
import ApiSettings from '../../components/adminTools/api/ApiSettings'

const ApiSettingsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [platformCreds, setPlatformCreds] = useState<IPlatformCredListItem[]>([] as any)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 0,
  })
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAssignedCampaigns = async () => {
      const response = await IMIApi.GetPlatformCreds()
      if (response.status !== 200) {
        const parse = JSON.parse(response)
        setError({ errorMsg: parse.detail })
        return setLoading(false)
      }

      setPlatformCreds(response.data.map((cred: IPlatformCred): IPlatformCredListItem => {
        const advertisers = {}

        cred.campaigns.forEach((campaign) => {
          advertisers[campaign.advertiser.id] = campaign.advertiser.name
        })

        return {
          id: cred.id,
          status: cred.status,
          advertisers: Object.values(advertisers).sort().join(' | '),
          platform: cred.platform.name,
          isActive: cred.platform.is_active,
        }
      }).sort((a, b) => {
        if (a.advertisers < b.advertisers) {
          return -1
        }
        if (a.advertisers > b.advertisers) {
          return 1
        }
        return 0
      }))

      setLoading(false)
      return () => abortController.abort()
    }
    fetchAssignedCampaigns()
  }, [setError, pagination])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={(
          <AdminToolsMenu />
        )}
        mainContent={(
          <ApiSettings
            platformCreds={platformCreds}
            loadingApiSettings={loading}
            setPagination={setPagination}
          />
        )}
      />
    </Padding>
  )
}
export default ApiSettingsPage
