/*
  Session.ts
  Describes the possible session states and defaults for the IMI Dashboard
  Created by Spence Wetjen on 2022-12-11 for Encompass Agency
  Copyright = 2022 Encompass Agency.  All rights reserved.
 */

// States
export const REASON_NONE = 'NONE'
export const REASON_SESSION_EXPIRED = 'SESSION_EXPIRED'
export const REASON_USER_CLICKED = 'USER_CLICKED'
export const REASON_SERVICE_OFFLINE = 'SERVICE_OFFLINE'

// Defaults
export const DEFAULT_SESSION_EXPIRES = new Date(0) // Dec 31, 1969
