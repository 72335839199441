import { useContext, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/outline'
import NavLinkWrapper from './NavLink'
import { AppContext } from '../../contexts/AppContext'

const Breadcrumbs = ({ route }) => {
  const [last, setLast] = useState('')
  const { user } = useContext(AppContext)
  const getPathTokens = (pathname) => {
    const paths = ['/']
    if (pathname.pageUrl === '/advertiser-dashboard') return paths
    if (pathname.pageUrl === '/campaign-overview' && user?.advertisers?.length < 2 && user?.user_type_id === 6) return paths
    // if (pathname.pageUrl === '/' && user.user_type_id === 4) return paths
    if (pathname.pageUrl === '/admin-tools/call-processing' && user?.user_type_id === 4) return paths
    if (pathname.pageUrl === '/admin-tools/call-processing/process-calls' && user?.user_type_id === 4) return paths
    if (pathname.pageUrl === '/admin-tools/call-processing/view-processed-calls' && user?.user_type_id === 4) return paths
    if (pathname.pageUrl === '/admin-tools/flagged-calls' && user?.user_type_id === 4) return paths
    if (pathname.pageUrl === '/admin-tools/flagged-calls/flagged-call-report' && user?.user_type_id === 4) return paths
    pathname.pageUrl.split('/').reduce((prev, curr) => {
      const currPath = `${prev}/${curr}`
      paths.push(currPath)
      return currPath
    })
    return paths
  }
  const location = getPathTokens(route)
  const [lastItem] = location.slice(-1)

  const checkParentheses = (updated) => {
    if (updated === 'search engine marketing') {
      return 'search engine marketing (SEM)'
    } if (updated === 'return on investment') {
      return 'return on investment (ROI)'
    } if (updated === 'edit return on investment') {
      return 'edit return on investment (ROI)'
    }
    return updated
  }

  const mappingLocation = location.map((crumb, i) => {
    const updated = crumb.replace(/-/g, ' ').split('/').pop()
    const values = {
      key: crumb,
      name: checkParentheses(updated),
      path: crumb,
    }
    return values
  })

  return (
    <>
      {mappingLocation.map((crumb) => (
        <NavLinkWrapper
          variant="breadcrumb"
          key={crumb.key}
          to={crumb.path}
          disabled={lastItem === crumb.path}
          className={`mr-2 inline capitalize ${lastItem === crumb.path ? '' : 'text-primary'}`}
        >
          {/* {(crumb.path === '/' && user.user_type_id !== 6 && user.advertisers.length < 2) && (
            (lastItem !== crumb.path) && (
              <>
                advertiser dashboard
                <ChevronRightIcon className="ml-1 h-2 w-2 inline text-light text-opacity-40" />
              </>
            )
          )} */}
          {/* {(crumb.path === '/campaign-overview' && user.user_type_id === 6 && user.advertisers.length < 2) && (
            <>
              campaign overview
              <ChevronRightIcon className="ml-1 h-2 w-2 inline text-light text-opacity-40" />
              {crumb.name}
            </>
          )} */}
          {(user?.user_type_id === 6 && user?.advertisers?.length < 2) && (
            (crumb.path === '/' && lastItem !== crumb.path) ? 'campaign overview' : crumb.name
          )}
          {(user?.user_type_id === 6 && user?.advertisers?.length > 1) && (
            (crumb.path === '/' && lastItem !== crumb.path) ? 'advertiser dashboard' : crumb.name
          )}
          {user?.user_type_id < 4 && (
            (crumb.path === '/') ? 'advertiser dashboard' : crumb.name
          )}
          {(user?.user_type_id === 5) && (
            (crumb.path === '/' && lastItem !== crumb.path) ? 'advertiser dashboard' : crumb.name
          )}
          {/* {(user.user_type_id === 6 && user.advertisers.length < 2 && lastItem !== '/') && (
            crumb.path === '/' ? 'campaign overview' : crumb.name
          )} */}
          {/* {user.user_type_id < 4 && (
            (lastItem !== '/') && (
              crumb.path === '/' ? 'advertiser dashboard' : crumb.name
            )
          )} */}
          {(lastItem !== crumb.path) && (
            <>
              {/* {crumb.name} */}
              <ChevronRightIcon className="ml-1 h-2 w-2 inline text-light text-opacity-40" />
            </>
          )}
        </NavLinkWrapper>
      ))}
    </>
  )
}

export default Breadcrumbs
