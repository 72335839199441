import { useEffect, useState, useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import CallCategorySettings from '../../../components/adminTools/calls/category/CallCategorySettings'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICallCategoryMultiResponse } from '../../../api/imi/interfaces'

const CallCategorySettingsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [categories, setCategories] = useState<ICallCategoryMultiResponse>([] as any)
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAllCallCategories = async () => {
      const response = await IMIApi.GetCallIndustries(abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCategories(response.data)
      return setLoading(false)
    }
    fetchAllCallCategories()
    return () => abortController.abort()
  }, [refresh, setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <CallCategorySettings
            callCategories={categories.data}
          />
        )}
      />
    </Padding>
  )
}

export default CallCategorySettingsPage
