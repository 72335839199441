import {
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { ChevronRightIcon, TrashIcon } from '@heroicons/react/outline'
import Modal from '../../../modal'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import Input from '../../Input'
import Radio from '../../Radio'
import Dropdown from '../../Dropdown'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import DropdownAdmin from '../../DropdownAdmin'
import { ICampaignTypeInDb } from '../../../../api/imi/interfaces'

interface Props {
  disabled?: boolean
  showButton?: boolean
  errors: any
  editPage?: boolean
  pageLimit: number
  campaignTypes: ICampaignTypeInDb[]
  onRemove: (pcampaignId: number) => void
}

const EditPCampaignForm:React.FC<Props> = ({
  showButton,
  errors,
  editPage,
  pageLimit,
  campaignTypes,
  onRemove,
}) => {
  const methods = useFormContext()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [btnText, setBtnText] = useState('Change')
  const [currentSelection, setCurrentSelection] = useState<any>()
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)
  const [specifics, setSpecifics] = useState<[]>([])
  const campaignSpecificsInfo = (
    <span>
      Choose from the dropdown to provide additional information about specific campaign identifiers. This is not a required field; it should be used in the instance where providing specifics about a campaign would help distinguish it from other campaigns.
      <br />
      <br />
      The “Blended” option will only apply to ReachLocal campaigns.
    </span>
  )
  const secondaryNameInfo = (
    <span>
      The secondary name provides additional name/s and/or differentiators for advertisers (example: location, specific name of campaign, etc.). This is not a required field; it should be used in the instance where differentiating between different campaigns is helpful/necessary.
      <br />
      <br />
      Ex: Dallas - Wigs
      <br />
      <br />
      Please be sure to check proper spelling. Use title case formatting.
    </span>
  )

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'pcampaign',
  })

  useEffect(() => {
    campaignTypes?.filter((specific) => {
      if (specific.id === campaignInDb?.type.id) {
        return setSpecifics(specific.specifics)
      }
      return specific.id
    })
  }, [campaignTypes, campaignInDb])

  const modal = (itemIndex) => {
    setBtnText('Remove')
    setCurrentSelection(itemIndex)
    setShowModal(true)
  }

  const removeItem = () => {
    remove(currentSelection)
    onRemove(campaignInDb.pcampaign[currentSelection].id)
    return setShowModal(false)
  }
  const addNumber = (index) => {
    const number = index + 1
    if (number < 10) {
      return `0${number}`
    }
    return number
  }
  console.log(campaignInDb)
  return (
    <>
      <Modal
        isButtonLoading={!showButton}
        show={showModal}
        title="Confirm Change"
        body={(
          `Remove child campaign ${addNumber(currentSelection)}?`
        )}
        onSubmit={() => removeItem()}
        onClose={() => setShowModal(false)}
        closeText="Cancel"
        submitText={btnText}
      />
      {campaignInDb?.id > 0 && campaignInDb.pcampaign.length > 0 && (
        <>
          <ul className="pcampaign-form">
            {fields?.map((item, index) => (
              <li key={item.id} className={index >= pageLimit ? 'hidden' : ''}>
                <hr />
                <div className="flex justify-between items-baseline">
                  <h4 className="pb-7">{`child ${addNumber(index)}`}</h4>
                  <LinkWrapper
                    href="#"
                    variant="defaultAdmin"
                    className="mb-6 inline-block"
                    onClick={() => modal(index)}
                  >
                    {showButton && (
                      <Icon size="md" colors="text-primary">
                        <TrashIcon className="text-primary hover:text-secondary global-animation" />
                      </Icon>
                    )}
                  </LinkWrapper>
                </div>
                <Input
                  required={false}
                  label="Source name"
                  type="text"
                  disabled
                  {...methods.register(`pcampaign.${index}.name` as const, {
                    required: true,
                  })}
                />
                <Input
                  required={false}
                  label="Platform"
                  type="text"
                  disabled
                  {...methods.register(`pcampaign.${index}.platform.name` as const, {
                    required: true,
                  })}
                />
                <Input
                  required={false}
                  label="Platform ID"
                  type="text"
                  disabled
                  {...methods.register(`pcampaign.${index}.api_id` as const, {
                    required: true,
                  })}
                />
                <Input
                  className="capitalize"
                  required={false}
                  label="Source type"
                  type="text"
                  disabled
                  {...methods.register(`pcampaign.${index}.api_type` as const, {
                    required: true,
                  })}
                />
                <Input
                  label="Secondary name"
                  tipId={`Percent-converted-${addNumber(index)}`}
                  type="text"
                  required={false}
                  disabled={!showButton}
                  toolTipInfo={secondaryNameInfo}
                  // toolTipInfo="If you leave this blank, it will display as a dash on the report, and the return will not be calculated."
                  {...methods.register(`pcampaign.${index}.secondary_name`, { 
                    required: false, 
                    onChange: () => methods.trigger(`pcampaign.${index}.secondary_name`),
                  })}
                />
                {specifics?.length > 0 && (
                  <Controller
                    name={`pcampaign.${index}.specific_id` as const}
                    control={methods.control}
                    rules={{ 
                      required: false,
                      onChange: () => methods.trigger(`pcampaign.${index}.specific_id`),
                    }}
                    render={({
                      field,
                    }) => (
                      <Dropdown
                        disabled={!showButton || specifics?.length < 1}
                        hookFormField={field}
                        placeholder={campaignInDb?.specific?.id > 0 ? undefined : 'Select...'}
                        label="Campaign specifics"
                        variant="admin"
                        value={methods.getValues(`pcampaign.${index}.specific_id`)}
                        list={(
                          [...specifics, { id: null, name: 'Select to remove campaign specific if assigned.' }]
                        )}
                        error={errors.id && <span className="form-error">This field is required</span>}
                        toolTipInfo={campaignSpecificsInfo}
                        tipId="pcampaignspecifics"
                        required={false}
                      />
                    )}
                  />
                )}
              </li>
            ))}
          </ul>
          <br />
        </>
      )}
    </>
  )
}

export default EditPCampaignForm
