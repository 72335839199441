import { useState, useEffect, useContext } from 'react'
import { IMIApi } from '../../../api/imi/api'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { ICampaignMultiResponse, ICampaignSourceMultiResponse } from '../../../api/imi/interfaces'
import { AppContext } from '../../../contexts/AppContext'
import CampaignSettings from '../../../components/adminTools/campaigns/campaign_settings/CampaignSettings'

const CampaignSettingsPage:React.FC = () => {
  const [loadingAssigned, setLoadingAssigned] = useState(true)
  const [loadingUnassigned, setLoadingUnassigned] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [unassignedCampaigns, setUnassignedCampaigns] = useState<any[]>([] as any)
  // const [unassignedCampaigns, setUnassignedCampaigns] = useState<ICampaignSourceMultiResponse>([] as any)
  const [assignedCampaigns, setAssignedCampaigns] = useState<ICampaignMultiResponse>([] as any)
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 0,
  })
  const { setError } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchUnassignedCampaigns = async () => {
      const response = await IMIApi.GetAllPCampaigns(pagination.offset, pagination.limit, true, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoadingUnassigned(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoadingUnassigned(false)
      }
      // const onlySemUnassignedCampaigns = response?.data?.data.filter((campType) => campType.type_id === 2)
      setUnassignedCampaigns(response.data.data)
      setLoadingUnassigned(false)
    }
    fetchUnassignedCampaigns()
    return () => abortController.abort()
  }, [refresh, setError, pagination])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchAssignedCampaigns = async () => {
      const response = await IMIApi.GetAllCampaigns(false, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON.parse(response)
        setError({ errorMsg: parse.detail })
        return setLoadingAssigned(false)
      }
      setAssignedCampaigns(response.data)
      setLoadingAssigned(false)
    }
    fetchAssignedCampaigns()
    return () => abortController.abort()
  }, [refresh, setError, pagination])

  useEffect(() => {
    if (unassignedCampaigns?.length > 0) {
      return setLoadingUnassigned(false)
    }
  }, [unassignedCampaigns])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={(
          <AdminToolsMenu />
        )}
        mainContent={(
          <CampaignSettings
            unassignedCampaigns={unassignedCampaigns}
            assignedCampaigns={assignedCampaigns}
            loadingUnassigned={loadingUnassigned}
            loadingAssigned={loadingAssigned}
            refresh={setRefresh}
            setPagination={setPagination}
          />
        )}
      />
    </Padding>
  )
}
export default CampaignSettingsPage
