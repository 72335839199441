import { useState, useEffect, useContext } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { AppContext } from '../../../../contexts/AppContext'
import { IEmailTemplateUpdate, IEmailTemplateCreate } from '../../../../api/imi/interfaces'
import { IMIApi } from '../../../../api/imi/api'
import Content from '../../../layouts/Content'
import LinkWrapper from '../../../Links'
import Icon from '../../../Icon'
import Padding from '../../../layouts/Padding'
import AccountEmailTemplateForm from '../../../form/accounts/AccountEmailTemplateForm'
import FormButtonGroup from '../../../button/FormButtonGroup'

interface Props {
  setTemplateId: (val: number) => void
}

const CreateEmailTemplate:React.FC<Props> = ({
  setTemplateId,
}) => {
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { setError } = useContext(AppContext)

  const methods = useForm<IEmailTemplateUpdate>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })

  const cancel = () => {
    methods.reset({}, { keepErrors: false })
    setIsButtonLoading(false)
    setBtnText('Save')
    methods.clearErrors()
  }

  const onSubmit: SubmitHandler<IEmailTemplateCreate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const submitResponse = await IMIApi.CreateNewEmailTemplate(data)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setTemplateId(submitResponse?.data?.id)
      setIsButtonLoading(false)
      return setBtnText('Saved!')
    }, 1000)
  }

  return (
    <>
      <Content
        title="Template Details"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
          >
            <AccountEmailTemplateForm
              errors={methods.formState.errors}
              showButton
            />
            <FormButtonGroup
              isButtonLoading={isButtonLoading}
              disabled={!methods.formState.isValid}
              disabledCancel={!methods.formState.isDirty}
              onClickCancel={cancel}
              btnText={btnText}
              showButton
            />
          </form>
        </FormProvider>
      </Content>
      <Content
        title="Dashboard branding"
      >
        <p>For a consistent experience, each email template should be connected to version of our dashboard that is branded for our partner. To do this, first save the details of this new email template, then create a new dashboard brand. There, you’ll be able to select this email template’s name, tying the two together.</p>
        <LinkWrapper
          href="/admin-tools/dashboard-branding/create-dashboard-branding"
          variant="defaultAdmin"
          className="mb-6 inline-block"
          target="_blank"
        >
          Create New Dashboard Branding
          <Icon size="xxs">
            <ChevronRightIcon />
          </Icon>
        </LinkWrapper>
      </Content>
    </>
  )
}

export default CreateEmailTemplate
