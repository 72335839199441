// styles
export const accountsSettingsStyles = {
  mobile: 'font-bold md:hidden',
  desktop: 'hidden md:flex',
  icon: 'md:hidden absolute',
  expand: 'block',
  collapse: 'hidden',
  date: 'whitespace-nowrap overflow-hidden max-w-overflow md:visible md:max-w-none',
}

// data
export const accountsUserSettingsMenu = [
  {
    fieldName: 'username',
    name: 'User Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'email',
    name: 'Email',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'advertisers',
    name: 'Advertisers',
    mobile: false,
    sort: false,
  },
  {
    fieldName: 'user_type.name',
    name: 'Acct Type',
    mobile: true,
    sort: true,
  },
]

export const accountsUserSettingsStatus = [
  {
    id: 0,
    name: 'Active',
    value: true,
  },
  {
    id: 1,
    name: 'Inactive',
    value: false,
  },
]

export const emailTemplateMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'logo_url',
    name: 'Logo',
    mobile: false,
    sort: false,
  },
]

export const dashboardBrandingTemplateMenu = [
  {
    fieldName: 'name',
    name: 'Name',
    mobile: false,
    sort: true,
  },
  {
    fieldName: 'logo',
    name: 'Logo',
    mobile: false,
    sort: false,
  },
]
