import { rgb, RGB } from 'pdf-lib'

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? rgb(
    parseInt(result[1], 16) / 255,
    parseInt(result[2], 16) / 255,
    parseInt(result[3], 16) / 255,
  ) : rgb(0, 0, 0)
}

export type Theme = {
  primary: string
  primaryDark: string
  secondary: string
  tertiary: string
}

export type Colors = {
  primary: RGB
  primaryDark: RGB
  secondary: RGB
  tertiary: RGB
  purple: RGB
  red: RGB
  green: RGB
  white: RGB
  black: RGB
  grey: RGB
  darkGrey: RGB
  darkestGrey: RGB
  hexToRgb: (hex: string) => RGB
}

export const getColors = (theme: Theme) => ({
  primary: hexToRgb(theme.primary),
  primaryDark: hexToRgb(theme.primaryDark),
  secondary: hexToRgb(theme.secondary),
  tertiary: hexToRgb(theme.tertiary),
  purple: rgb(77 / 255, 70 / 255, 152 / 255),
  red: rgb(190 / 255, 39 / 255, 39 / 255),
  green: rgb(41 / 255, 180 / 255, 139 / 255),
  white: rgb(1, 1, 1),
  black: rgb(0, 0, 0),
  grey: rgb(247 / 255, 247 / 255, 247 / 255),
  darkGrey: rgb(205 / 255, 213 / 255, 209 / 255),
  darkestGrey: rgb(40 / 0.3 / 255, 40 / 0.3 / 255, 40 / 0.3 / 255),
  hexToRgb,
}) as Colors
