import {
  UserCircleIcon,
  MailIcon,
  TagIcon,
  UsersIcon,
  ChartSquareBarIcon,
  ColorSwatchIcon,
  TrendingUpIcon,
  PhoneIcon,
  VolumeUpIcon,
  ClipboardListIcon,
  FlagIcon,
  BadgeCheckIcon,
  BellIcon,
  ServerIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/outline'
import * as UT from '../../constants/UserTypes'

export const navigationMenu = [
  // Admin, Partner, Client with multiple advertisers
  {
    title: 'Advertiser Dashboard',
    url: '/advertiser-dashboard',
    access: [1, 2, 3, 5, 6],
  },
  // Admin, Partner
  {
    title: 'Campaign Central',
    url: '/campaign-central',
    access: [1, 2, 3, 5],
  },
  {
    title: 'Call Processing',
    url: '/admin-tools/call-processing',
    access: [3, 4],
  },
  {
    title: 'Flagged Calls',
    url: '/admin-tools/flagged-calls',
    access: [3],
  },
  // Admin, Partner, Client, Call Manager
  // {
  //   title: 'Campaign Overview',
  //   url: '/campaign-overview',
  // },
  // Admin
  {
    title: 'Admin Tools',
    url: '/admin-tools',
    img: '',
    access: [1, 3],
    accounts: [
      {
        title: 'Accounts',
        url: '/admin-tools',
        routes: [
          {
            title: 'User Settings',
            url: '/admin-tools/user-settings',
            img: '',
            icon: <UserCircleIcon />,
            description: 'View all users, create a new user, & edit an existing user; assign user to a dashboard brand, advertiser, & campaign',
            routes: [
              {
                title: 'Create User',
                url: '/admin-tools/create-user',
                img: '',
                icon: '',
                description: '',
              },
            ],
          },
          {
            title: 'Email Templates',
            url: '/admin-tools/email-templates',
            img: '',
            icon: <MailIcon />,
            description: 'View all email templates, create a new template, & edit an existing template (an email template determines the branding and content of automatic campaign emails sent from IMI to a user)',
          },
          {
            title: 'Dashboard Branding',
            url: '/admin-tools/dashboard-branding',
            img: '',
            icon: <TagIcon />,
            description: 'View all dashboard branding options, create new branding, & edit existing branding; assign users to a dashboard brand',
          },
        ],
      },
    ],
    advertisers: [
      {
        title: 'Advertisers',
        url: '/admin-tools',
        routes: [
          {
            title: 'Advertiser Settings',
            url: '/admin-tools/advertiser-settings',
            img: '',
            icon: <UsersIcon />,
            description: 'View all advertisers, create a new advertiser, & edit an existing advertiser; assign an advertiser to a user & campaign',
          },
        ],
      },
    ],
    campaigns: [
      {
        title: 'Campaigns',
        url: '/admin-tools',
        routes: [
          {
            title: 'Campaign Settings',
            url: '/admin-tools/campaign-settings',
            img: '',
            icon: <ChartSquareBarIcon />,
            icon_alert:
  <>
    <span className="bg-red-default h-2 w-2 rounded-circle flex items-center justify-center mr-2 absolute z-100' : ''" />
    <ChartSquareBarIcon />
  </>,
            icon_alert2:
  <>
    <span className="bg-red-default h-3 w-3 border-2 border-white rounded-circle flex items-center justify-center absolute right-0 z-100' : ''" />
    <ChartSquareBarIcon />
  </>,
            description: 'View all campaigns, create a new campaign, & edit an existing campaign; assign campaign to an advertiser & related campaign (for campaigns that require data merge), as needed',
          },
          {
            title: 'Campaign Types',
            url: '/admin-tools/campaign-types',
            img: '',
            icon: <ColorSwatchIcon />,
            icon_alert: <ColorSwatchIcon />,
            description: 'Create & edit campaign types; for super-admin/developer only',
          },
          {
            title: 'Uplift',
            url: '/admin-tools/uplift',
            img: '',
            icon: <TrendingUpIcon />,
            icon_alert: <TrendingUpIcon />,
            description: 'Create new uplift or edit an existing uplift (uplifts adjust a campaign\'s budget to include our fees)',
          },
          {
            title: 'Call Settings',
            url: '/admin-tools/call-settings',
            img: '',
            icon: <BadgeCheckIcon />,
            icon_alert: <BadgeCheckIcon />,
            description: 'View the full list of SEM campaigns, their call processing settings, & their call industry; edit the settings for a campaign',
            access: [1],
          },
          {
            title: 'Return on Investment (ROI)',
            url: '/admin-tools/return-on-investment',
            img: '',
            icon: <CurrencyDollarIcon />,
            icon_alert: <CurrencyDollarIcon />,
            description: 'View a full list of SEM campaigns & their ROI settings; edit ROI details (ROI details appear as a dedicated section on the SEM report)',
            access: [1],
          },
        ],
      },
    ],
    calls: [
      {
        title: 'Calls',
        url: '/admin-tools',
        routes: [
          {
            title: 'Call Category Settings',
            url: '/admin-tools/call-category-settings',
            img: '',
            icon: <PhoneIcon />,
            description: 'View all call categories, create a new category, & edit existing categories',
            access: [1],
          },
          {
            title: 'Call Processing',
            url: '/admin-tools/call-processing',
            img: '',
            icon: <VolumeUpIcon />,
            description: 'Listen to & categorize calls; add notes/summaries; flag calls & create notifications for campaigns, as needed',
            access: [1, 3, 4],
          },
          {
            title: 'Call Processing Instructions',
            url: '/admin-tools/call-processing-instructions',
            img: '',
            icon: <ClipboardListIcon />,
            description: 'Create campaign-specific call processing instructions & assign to a campaign',
            access: [1, 3],
          },
          {
            title: 'Flagged Calls',
            url: '/admin-tools/flagged-calls',
            img: '',
            icon: <FlagIcon />,
            description: 'View a full list of flagged calls from the last 30 days',
            access: [UT.Admin, UT.CallManager, UT.TeamViewer],
          },
        ],
      },
    ],
    api: [
      {
        title: 'API',
        url: '/admin-tools',
        routes: [
          {
            title: 'API Settings',
            url: '/admin-tools/api-settings',
            img: '',
            icon: <ServerIcon />,
            icon_alert:
  <>
    <span className="bg-red-default h-2 w-2 rounded-circle flex items-center justify-center mr-2 absolute z-100' : ''" />
    <ServerIcon />
  </>,
            icon_alert2:
  <>
    <span className="bg-red-default h-3 w-3 border-2 border-white rounded-circle flex items-center justify-center absolute right-0 z-100' : ''" />
    <ServerIcon />
  </>,
            description: 'Create or edit an API Key for Stack Adapt platform. This key should be given to you upon creation of an advertiser and allows us to access its data.',
            access: [UT.Admin],
          },
        ],
      },
    ],
    // other: [
    //   {
    //     title: 'Other',
    //     url: '/admin-tools',
    //     routes: [
    //       {
    //         title: 'Notifications',
    //         url: '/admin-tools/notifications',
    //         img: '',
    //         icon: <BellIcon />,
    //         description: 'Create a new notification; new, "unread" notifications appear in the top nav, while "read" notifications will be saved on the dedicated "notifcations" page (exception is site-wide notifications, which will not be saved)',
    //       },
    //     ],
    //   },
    // ],
  },
  // TBD on access, for now let's just do admin
  // {
  //   title: 'Resource Library',
  //   url: '/resource-library',
  //   resources: [
  //     {
  //       title: 'Quickstart Guide',
  //       url: '/resource-library/quickstart-guide',
  //     },
  //     {
  //       title: 'SEM Methodology eBook',
  //       url: '/resource-library/sem-methodology-ebook',
  //     },
  //     // Does not show if not enCOMPASS Dashboard Branding
  //     {
  //       title: 'The enCOMPASS Way',
  //       url: '/resource-library/the-encompass-way',
  //     },
  //   ],
  // },
]
