// NOTE:
// Component for just assigning campaign to advertiser, taking
// pcampaign and assigning to Campaign, but creates its own Campaign
// upon assignment. First component for SEM campaigns. Actual creation
// of Campaigns to assign multiple pcampaigns to is in the "create" folder.

import {
  useMemo,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { AppContext } from '../../../../contexts/AppContext'
import {
  IAdvertiserInDb,
  ICampaignTypeInDb,
  ICampaignInDb,
  ICampaignUpdate,
  ICampaignMultiResponse,
  IPCampaign,
  ICampaignCreate,
  IPCampaignUpdate,
} from '../../../../api/imi/interfaces'
import Content from '../../../layouts/Content'
import FormButtonGroup from '../../../button/FormButtonGroup'
import { IMIApi } from '../../../../api/imi/api'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import CampaignAssignment from './AdvertiserAssignment'
import LoadingSpinner from '../../../LoadingSpinner'
import CallProcessRoi from './CallProcess'
import EditCampaignForm from '../../../form/campaigns/campaign_settings/EditCampaignForm'
import { newCampaignInDb } from '../../../../api/imi/factories'
import CreateCampaignForm from '../../../form/campaigns/campaign_settings/CreateCampaignForm'

interface Props {
  allAdvertisers: IAdvertiserInDb[]
  allCampaignTypes: ICampaignTypeInDb[]
  isLoading: boolean
  edit: boolean
  disabled?: boolean,
  pCampaign?: IPCampaign,
  setCampaign: (campaign: IPCampaign) => void,
}

const CampaignDetails:React.FC<Props> = ({
  allAdvertisers,
  allCampaignTypes,
  isLoading,
  edit,
  pCampaign,
  setCampaign,
}) => {
  const { setError } = useContext(AppContext)
  const [btnText, setBtnText] = useState('Save')
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [showButton, setShowButton] = useState<boolean>(edit)
  const { campaignInDb, setCampaignInDb } = useContext(CampaignContext)

  useEffect(() => {
    setCampaignInDb(newCampaignInDb())
  }, [setCampaignInDb])

  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: useMemo(() => ({
      ...pCampaign,
    }), [pCampaign]),
  })

  useEffect(() => {
    methods.reset({
      ...pCampaign,
    })
  }, [pCampaign, methods])

  const cancel = () => {
    methods.reset({
      ...pCampaign,
    }, { keepDefaultValues: true })
    setIsButtonLoading(false)
    setTimeout(() => {
      setShowButton(false)
      setBtnText('Save')
    }, 10)
  }
  const editCampaign = () => {
    setShowButton(true)
  }

  const onSubmit: SubmitHandler<IPCampaignUpdate> = async (data) => {
    setIsButtonLoading(true)
    setBtnText('Saving...')
    const formData = {
      ...data,
      specific_id: data?.specific_id,
      id: data?.id,
      secondary_name: data?.secondary_name,
    }
    const submitResponse = await IMIApi.UpdatePCampaign(formData)
    setTimeout(() => {
      if (submitResponse.status !== 200) {
        if (submitResponse.status === 0) {
          setIsButtonLoading(false)
          setError({ errorMsg: 'Internal server error.' })
          return setBtnText('Save')
        }
        const parse = JSON.parse(submitResponse)
        setIsButtonLoading(false)
        setError({ errorMsg: parse.detail })
        return setBtnText('Save')
      }
      setIsButtonLoading(false)
      setBtnText('Saved!')
      setCampaign(submitResponse.data)
    }, 1000)
    setTimeout(() => {
      setShowButton(false)
      return setBtnText('Save')
    }, 2000)
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (campaignInDb?.id > 0) {
      const fetchCampaignById = async () => {
        const response = await IMIApi.GetCampaignById(campaignInDb?.id, abortController.signal)
        if (!response) {
          return
        }
        if (response.status && response.status !== 200) {
          const parse = JSON?.parse(response)
          return setError({ errorMsg: parse?.detail })
        }
        if (!response.status) {
          return setError({ errorMsg: 'Internal server error.' })
        }
        setCampaignInDb(response.data)
      }
      fetchCampaignById()
    }
    return () => abortController.abort()
  }, [campaignInDb?.id, setError, setCampaignInDb])

  return (
    <>
      <Content title="campaign details">
        {isLoading ? (
          <LoadingSpinner variant="primary" size="lg" />
        ) : (
          <>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                noValidate
              >
                <EditCampaignForm
                  errors={methods.formState.errors}
                  allAdvertisers={allAdvertisers}
                  allCampaignTypes={allCampaignTypes}
                  pCampaign={pCampaign}
                  showButton={showButton}
                  hideAdvertiser={campaignInDb?.id === 0}
                />
                <FormButtonGroup
                  isButtonLoading={isButtonLoading}
                  disabled={!methods.formState.isDirty || !methods.formState.isValid}
                  disabledCancel={!methods.formState.isDirty}
                  onClickCancel={cancel}
                  onClickEdit={editCampaign}
                  btnText={btnText}
                  showButton={showButton}
                />
              </form>
            </FormProvider>
          </>
        )}
      </Content>
    </>
  )
}

export default CampaignDetails
