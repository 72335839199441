// Must be placed around components using Content in the Layout folder.
// This sitewide padding
interface Props {
  children: React.ReactNode
}

const Padding:React.FC<Props> = ({
  children,
}) => (
  <div className="px-6 lg:px-0">
    {children}
  </div>
)

export default Padding
