import { useState, useEffect } from 'react'
import MetricsGrid from '../layouts/MetricsGrid'
import { commaNumber } from '../../helpers/utils'
import Grid from '../layouts/Grid'
import { ReactComponent as ImpressionsIcon } from '../../assets/icons/impressions-icon.svg'
import { ReactComponent as ActivitiesIcon } from '../../assets/icons/activities-icon.svg'
import { ReactComponent as CompletionRateIcon } from '../../assets/icons/completion-rate-icon.svg'
import { ReactComponent as CpcvIcon } from '../../assets/icons/cpcv-icon.svg'
import { IVideoDetails } from '../../api/imi/interfaces'

interface Props {
  data: IVideoDetails
  // totalData: SemTotals
}

// If RL is the only platform on the report, don't show sundry report fields

const DisplayTotalsReport: React.FC<Props> = ({ data }) => {
  /**
   * If true, the report only contains an RL response and the section should be hidden.
   */
  const showLimitedReport = data?.platforms.length === 1 && data.platforms[0] === 1

  const [totals, setTotals] = useState<IVideoDetails>([] as any)
  useEffect(() => {
    setTotals(data)
  }, [data])

  return (
    <Grid>
      <MetricsGrid
        name="impressions"
        imageUrl={<ImpressionsIcon />}
        value={totals?.impressions?.value && commaNumber(totals?.impressions?.value)}
        tooltipInfo="The number of times your ad was served."
      />
      {!showLimitedReport && (
        <MetricsGrid
          name="activities"
          imageUrl={<ActivitiesIcon />}
          value={totals?.activities?.value && commaNumber(totals?.activities?.value)}
          tooltipInfo="Tracked website actions taken by users who were served your ad."
        />
      )}
      <MetricsGrid
        name="completion rate"
        imageUrl={<CompletionRateIcon />}
        value={totals?.completion_rate?.value && `${totals?.completion_rate?.value.toLocaleString('en', { useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 })}%`}
        tooltipInfo="The percent of users who started your video and completed to 100%."
      />
      <MetricsGrid
        name="cpcv"
        imageUrl={<CpcvIcon />}
        value={totals?.cpcv?.value && `$${totals?.cpcv?.value.toLocaleString('en', { useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}
        tooltipInfo="Cost per completed view (CPCV) is the average cost of a user watching your video to completion."
      />
    </Grid>
  )
}

export default DisplayTotalsReport
