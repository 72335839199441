import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext'
import AuthPage from '../pages/AuthPage'
import { getDefaultHomePage } from '../helpers/utils'

interface Props {
  component: any
  contextComponent: any
  contextState: any
  userTypeIds?: number []
  [x:string]: any
}

const ProtectedContextRoute : React.FC<Props> = ({
  component: Component,
  contextComponent,
  contextState,
  userTypeIds,
  ...rest
}) => {
  const { user, isSignedIn } = useContext(AppContext)
  const { Provider } = contextComponent

  if (!isSignedIn()) {
    return <></>
  }

  // TODO: refactor to use UserType constants
  // 1: admin
  // 2: team viewer
  // 3: call manager
  // 4: call analyst
  // 5: partner
  // 6 client
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn()) {
          if ((!userTypeIds || userTypeIds.includes(user.user_type_id))) {
            return <Provider value={contextState}><Component {...props} /></Provider>
          }
          return (
            <Redirect
              to={getDefaultHomePage(user)}
            />
          )
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }}
    />
  )
}

export default ProtectedContextRoute

ProtectedContextRoute.defaultProps = {
  userTypeIds: undefined,
}
