import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import NavLinkWrapper from './NavLink'
import { navigationMenu } from './NavItems'
import Icon from '../Icon'
import { ReactComponent as AccountIcon } from '../../assets/icons/account-icon.svg'
import Logout from './Logout'
import { AppContext } from '../../contexts/AppContext'

const AccountNavigation:React.FC = () => {
  const { user } = useContext(AppContext)
  return (
    <Menu as="div">
      <Menu.Button data-testid="main-nav-btn" className="group text-primary bg-white rounded-full flex text-sm hover:text-default active:text-secondary focus:outline-none focus:text-secondary">
        <span className="sr-only">Open navigation menu</span>
        <Icon size="nav" className="cursor-pointer group-hover:text-secondary">
          <AccountIcon />
        </Icon>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="fixed left-0 z-30 origin-top-right sm:absolute mt-5 bg-white border border-grey-light focus:outline-none sm:min-w-300 w-full sm:left-auto sm:-right-6">
          <Menu.Item>
            <span
              className="mb-4 border-b border-b-1 border-grey-light flex items-center font-base pl-6 pr-6 pb-8 pt-8 text-sm text-default"
            >
              <Icon size="sm" colors="grey" className="absolute right-0 top-0 px-3 py-3">
                <XIcon />
              </Icon>
              <Icon size="xl" className="mr-2">
                <AccountIcon className="h-12 w-12" />
              </Icon>
              <div className="pl-4">
                <span className="font-display text-base block font-bold">{user.username}</span>
                <span className="font-display text-xs block text-menu">{user.email}</span>
              </div>
            </span>
          </Menu.Item>
          {user.user_type_id < 5 || user.advertisers.length > 1 ? (
            <ul className="pl-6 pr-6 pt-2">
              {navigationMenu.map((item) => (
                <Menu.Item
                  key={item.title}
                >
                  <li className="my-2">
                    {item.access?.includes(user.user_type_id) && (
                      <NavLinkWrapper
                        variant="menu"
                        to={item.url}
                      >
                        {item.title}
                      </NavLinkWrapper>
                    )}
                  </li>
                </Menu.Item>
              ))}
            </ul>
          ) : (
            null
          )}
          <Menu.Item>
            <div>
              <Logout />
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default AccountNavigation
