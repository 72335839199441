import {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import { AppContext } from '../../contexts/AppContext'
import Content from '../layouts/Content'

const ErrorNotification:React.FC = () => {
  const [show, setShow] = useState(false)
  const { error, setError } = useContext(AppContext)
  const errorRef = useRef(null)
  useEffect(() => {
    if (error.errorMsg) {
      return setShow(true)
    }
    return setShow(false)
  }, [error])

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setError({ errorMsg: '' })
      setShow(false)
    }, 9000)
    return () => {
      clearTimeout(timeId)
    }
  }, [error, setError])

  if (!show) {
    return null
  }

  return (
    <div className="px-6 max-w-site mx-auto lg:px-0 inline-block sm:block" id="error">
      <Content
        title="Error"
        variant="error"
      >
        <p className="text-red">
          {!error.errorMsg && (
            <span>Something went wrong. Please refresh the page and try again. If you still encounter an error, please contact your account representative.</span>
          )}
          {error?.errorMsg[0]?.msg ? error.errorMsg[0].msg : error.errorMsg}
        </p>
      </Content>
    </div>
  )
}

export default ErrorNotification
