import { useContext, useEffect, useState } from 'react'
import AdminToolsMenu from '../../../components/adminTools/AdminToolsMenu'
import Padding from '../../../components/layouts/Padding'
import { IMIApi } from '../../../api/imi/api'
import SidebarLeft from '../../../components/layouts/SidebarLeft'
import { AppContext } from '../../../contexts/AppContext'
import { ICallProcessByCampaign, ICallProccessingSummary } from '../../../api/imi/interfaces'
import ProcessCalls from '../../../components/adminTools/calls/process/ProcessCalls'
import { fixDateObjApi } from '../../../helpers/utils'

const ViewProcessCallsPage:React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [calls, setCalls] = useState<ICallProcessByCampaign>({} as any)
  const [categories, setCategories] = useState<any>([] as any)
  const [callInstructions, setCallInstructions] = useState<any>()
  const [campaignsWithCalls, setCampaignsWithCalls] = useState<ICallProccessingSummary>([] as any)
  const { setError } = useContext(AppContext)
  const { callProcess, setCallProcess } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    // const items = sessionStorage.getItem('call_process_id')
    // if (items) {
    //   const parse = JSON.parse(items)
    //   sessionStorage.setItem('call_process_id', JSON.stringify(parse))
    //   localStorage.removeItem('call_process_id')
    // }
    const getSessionItems = sessionStorage.getItem('call_process_id')
    if (getSessionItems) {
      const parseItems = JSON.parse(getSessionItems)
      if (!parseItems.end_date) {
        setCallProcess({ id: parseItems, start_date: fixDateObjApi(new Date(new Date().setDate(new Date().getDate() - 30))), end_date: fixDateObjApi(new Date()) })
      } else {
        setCallProcess(parseItems)
      }
    }
    return () => {
      setCallProcess({ id: 0 })
    }
  }, [setCallProcess])

  useEffect(() => {
    const abortController1 = new AbortController()
    const abortController2 = new AbortController()
    const abortController3 = new AbortController()
    if (callProcess.id > 0) {
      setLoading(true)
      // const date = new Date(callProcess.end_date)
      // const yesterday = new Date(date.getTime())
      // const utcDate = yesterday.setDate(date.getUTCDate() + 1)
      // const newEndDate = fixDateObjApi(utcDate)

      const fetchAllCallsForProcessing = async () => {
        const response = await IMIApi.GetCallsByCampaignAndStatus({
          id: callProcess.id,
          // only flagged
          only_flagged: false,
          // start date
          start_date: callProcess.start_date,
          // end date
          end_date: callProcess.end_date,
          // status of processed only
          status: 'processed',
        }, abortController1.signal)
        const response2 = await IMIApi.GetCampaignById(callProcess.id, abortController2.signal)
        const response3 = await IMIApi.GetCallInstructionsByCampaignId(callProcess.id, abortController3.signal)
        if (!response) {
          return
        }
        if (response.status !== 200) {
          if (response.status === 0) {
            setError({ errorMsg: 'Internal server error.' })
            return setLoading(false)
          }
          const parse = JSON?.parse(response)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response2) {
          return
        }
        if (response2.status !== 200) {
          if (response2.status === 0) {
            setError({ errorMsg: 'Internal server error.' })
            return setLoading(false)
          }
          const parse = JSON?.parse(response2)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        if (!response3) {
          return
        }
        if (response3.status !== 200) {
          if (response3.status === 0) {
            setError({ errorMsg: 'Internal server error.' })
            return setLoading(false)
          }
          const parse = JSON?.parse(response3)
          setError({ errorMsg: parse?.detail })
          return setLoading(false)
        }
        setCallInstructions(response3.data)
        setCategories(response2.data.category.call_category)
        setCalls(response.data)
        return setLoading(false)
      }
      fetchAllCallsForProcessing()
    }
    return () => {
      abortController1.abort()
      abortController2.abort()
      abortController3.abort()
    }
  }, [callProcess, setError])

  useEffect(() => {
    setLoading(true)
    const abortController = new AbortController()
    const fetchAllCallsForProcessing = async () => {
      const response = await IMIApi.GetAllCallProcessing(false, abortController.signal)
      if (!response) {
        return
      }
      if (response.status !== 200) {
        const parse = JSON?.parse(response)
        setError({ errorMsg: parse?.detail })
        return setLoading(false)
      }
      if (!response.status) {
        setError({ errorMsg: 'Internal server error.' })
        return setLoading(false)
      }
      setCampaignsWithCalls(response.data)
      return setLoading(false)
    }
    fetchAllCallsForProcessing()
    return () => abortController.abort()
  }, [setError])

  return (
    <Padding>
      <SidebarLeft
        sidebarContent={<AdminToolsMenu />}
        mainContent={(
          <ProcessCalls
            campaignsWithCalls={campaignsWithCalls[1]}
            calls={calls}
            isLoading={loading}
            callCategories={categories}
            dateRange
            callInstructions={callInstructions}
          />
        )}
      />
    </Padding>
  )
}

export default ViewProcessCallsPage
